import React from 'react';

import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import Button from 'Sparky/Button';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';

import { Filter, useFiltersContext } from 'components/Filters';
import { VinPathSidebar } from 'types/VinPath';

import styles from './VinPathTableFilters.module.scss';

export interface VinPathTableFiltersProps {
  isShow: boolean;
  setVinPathSidebar: React.Dispatch<React.SetStateAction<VinPathSidebar>>;
}

/**
 * VinPathTableFilters
 */
export default function VinPathTableFilters({
  isShow,
  setVinPathSidebar,
}: VinPathTableFiltersProps) {
  const { filtersState, filterConfigs, clearAll } = useFiltersContext();

  return (
    <div className={classnames([styles.vinPathFilters, isShow && styles.showFilters])}>
      <Flex
        width='100%'
        alignItems='center'
        justifyContent='space-between'
        padding='16px'
        className={styles.vinPathFiltersHeader}
      >
        <Text fontWeight={7}>Filter your results</Text>
        <IconButton
          onClick={() => setVinPathSidebar('')}
          size='sm'
          aria-label='Close filter sidebar'
        >
          <IconX size={20} />
        </IconButton>
      </Flex>
      <div className={styles.vinPathFiltersBody}>
        {filterConfigs
          .filter((filterConfig) => filterConfig.id !== 'search')
          .map((filter) => {
            if (filter.id === 'activeFilterPills' && Object.keys(filtersState).length === 0) {
              return null;
            }
            return <Filter key={filter.id} config={filter} groupId='inventory' />;
          })}
      </div>
      <Flex
        width='100%'
        justifyContent='flex-end'
        columnGap='8px'
        padding='16px'
        className={styles.vinPathFiltersFooter}
      >
        <Button variant='outline' size='sm' onClick={clearAll}>
          Clear all
        </Button>
        <Button variant='solid' size='sm' onClick={() => setVinPathSidebar('')}>
          Close
        </Button>
      </Flex>
    </div>
  );
}
