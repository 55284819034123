import { IconHeartbeat } from '@tabler/icons-react';
import Chip from 'Sparky/Chip';

import { formatNumber } from 'util/formatters';

import styles from './RiskScore.module.scss';

export interface RiskScoreProps {
  riskScore?: number;
  showScoreOnly?: boolean;
}

/**
 * RiskScore
 */
export default function RiskScore({ riskScore, showScoreOnly }: RiskScoreProps) {
  if (riskScore !== undefined && riskScore >= 0 && riskScore <= 100) {
    const score = Number(formatNumber(riskScore));
    if (score > 80) {
      return (
        <Chip
          variant='filled'
          color='red'
          leftIcon={<IconHeartbeat color='#fff' size='14' className={styles.riskScoreIconRed} />}
          tooltip={`This VIN's risk score is ${score} out of 100`}
          tooltipSide='top'
        >
          {showScoreOnly ? score : `Very high - ${score}`}
        </Chip>
      );
    } else if (score > 60) {
      return (
        <Chip
          variant='filled'
          color='orange'
          leftIcon={<IconHeartbeat color='#fff' size='14' className={styles.riskScoreIconOrange} />}
          tooltip={`This VIN's risk score is ${score} out of 100`}
          tooltipSide='top'
        >
          {showScoreOnly ? score : `High - ${score}`}
        </Chip>
      );
    } else if (score > 40) {
      return (
        <Chip
          variant='filled'
          color='yellow'
          leftIcon={<IconHeartbeat color='#fff' size='14' className={styles.riskScoreIconYellow} />}
          tooltip={`This VIN's risk score is ${score} out of 100`}
          tooltipSide='top'
        >
          {showScoreOnly ? score : `Moderate - ${score}`}
        </Chip>
      );
    } else if (score > 20) {
      return (
        <Chip
          variant='filled'
          color='lime'
          leftIcon={<IconHeartbeat color='#fff' size='14' className={styles.riskScoreIconLime} />}
          tooltip={`This VIN's risk score is ${score} out of 100`}
          tooltipSide='top'
        >
          {showScoreOnly ? score : `Low - ${score}`}
        </Chip>
      );
    } else {
      return (
        <Chip
          variant='filled'
          color='green'
          leftIcon={<IconHeartbeat color='#fff' size='14' className={styles.riskScoreIconGreen} />}
          tooltip={`This VIN's risk score is ${score} out of 100`}
          tooltipSide='top'
        >
          {showScoreOnly ? score : `Very low - ${score}`}
        </Chip>
      );
    }
  } else {
    return null;
  }
}
