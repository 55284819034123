import { useState } from 'react';

import { useAtom } from 'jotai';
import IconButton from 'Sparky/IconButton';
import Panel from 'Sparky/Panel';
import { COLOR } from 'Sparky/styles/vars';
import { ChrevronIcon } from 'Sparky/SVGs/ChevronIcon';
import { HamburgerIcon } from 'Sparky/SVGs/HamburgerIcon';
import Text from 'Sparky/Text';

import TooltipIcon from 'components/TooltipIcon';
import { LocalMarketData } from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';
import { activeDealerTypeAtom } from 'pages/Settings/LocalMarketSettings/DealerSidebar';
import { CircleIcon } from 'pages/Settings/LocalMarketSettings/icons/CircleIcon';
import { HexIcon } from 'pages/Settings/LocalMarketSettings/icons/HexIcon';
import { StarIcon } from 'pages/Settings/LocalMarketSettings/icons/StarIcon';
import { formatNumber } from 'util/formatters';

import styles from './SummaryPanel.module.scss';

export interface SummaryPanelProps {
  selectedHexs: string[];
  data: LocalMarketData | undefined;
}

export const legendItems = [
  {
    icon: (
      <div className={styles.iconPair}>
        <CircleIcon color={COLOR.SPARKY_GREEN_400} />
        <StarIcon color={COLOR.SPARKY_PURPLE_400} />
      </div>
    ),
    label: 'Key dealerships',
  },
  {
    icon: <CircleIcon color={COLOR.SPARKY_RED_300} />,
    label: 'Your dealership',
  },
  {
    icon: <CircleIcon color={COLOR.SPARKY_GREEN_300} />,
    label: 'Franchise dealership',
  },
  {
    icon: <CircleIcon color={COLOR.SPARKY_PURPLE_300} />,
    label: 'Independent dealership',
  },
  {
    icon: <HexIcon color={COLOR.SPARKY_BLUE_600} />,
    label: 'Selected In-market cells',
  },
  // {
  //   icon: <HexIcon color={COLOR.SPARKY_GREY_300} />,
  //   label: 'Minimum in-market cells',
  // },
];

/**
 * SummaryPanel
 */
export default function SummaryPanel({ selectedHexs, data }: SummaryPanelProps) {
  const [isSummaryPanelOpen, setIsSummaryPanelOpen] = useState(false);
  const [activeDealerType] = useAtom(activeDealerTypeAtom);
  if (!data) {
    return <></>;
  }

  const dataMax = Math.max(...selectedHexs.map((hex) => data?.hexData[hex]?.inMarketShoppers));

  const dataArray = selectedHexs.map((hex) => data.hexData[hex]);

  const summaryData = {
    totalDemand: {
      title: 'Total demand',
      tooltip: 'Estimate of all potential shoppers in selected PMA',
      subText: 'In-market shoppers',
      value: formatNumber(dataArray.reduce((acc, curr) => acc + (curr?.inMarketShoppers ?? 0), 0)),
    },
    totalDealerships: {
      title: 'Total dealerships',
      tooltip: 'All dealers located in selected PMA',
      subText: 'Dealers in selected cells',
      value: formatNumber(
        dataArray.reduce(
          (acc, curr) =>
            acc + (activeDealerType === 'new' ? curr?.dealerCountNew : curr?.dealerCountUsed),
          0
        )
      ),
    },
    totalListedUnits: {
      title: 'Total listed units',
      tooltip: 'All units located in selected PMA',
      subText: 'Units in selected cells',
      value: formatNumber(
        dataArray.reduce(
          (acc, curr) =>
            acc + (activeDealerType === 'new' ? curr?.newListedUnits : curr?.usedListedUnits),
          0
        )
      ),
    },
  };

  const HamburgerButton = () => (
    <IconButton
      onClick={() => setIsSummaryPanelOpen(true)}
      aria-label='toggle-control-panel'
      size='md'
      variants='outline'
    >
      <HamburgerIcon />
    </IconButton>
  );

  // CLOSED STATE
  if (!isSummaryPanelOpen) {
    return (
      <div className={styles.closedControlPanel}>
        <HamburgerButton />
        <div className={styles.compactSummaryContainer}>
          {Object.values(summaryData).map((block) => (
            <div className={styles.compactSummary} key={block.title}>
              <Text size='16' fontWeight={5} lineHeight='24px' color='secondary'>
                {block.title} <TooltipIcon side='bottom' tipText={block.tooltip} />
              </Text>
              <Text size='16' fontWeight={5} lineHeight='24px'>
                {block.value}
              </Text>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // OPEN STATE
  return (
    <Panel className={styles.summaryPanel}>
      <div className={styles.topContainer}>
        {/* CLOSE BUTTON */}
        <div className={styles.togglePanelButton}>
          <IconButton
            onClick={() => setIsSummaryPanelOpen(false)}
            aria-label='toggle-control-panel'
            size='md'
            variants='outline'
          >
            <ChrevronIcon />
          </IconButton>
        </div>

        <Text color='secondary' fontWeight={6} size='16'>
          Summary of primary market area
        </Text>

        {/* DATA SUMMARY */}
        {Object.values(summaryData).map((block) => (
          <div className={styles.summaryBlock} key={block.title}>
            <Text color='secondary' fontWeight={5} size='16' className={styles.blockTitle}>
              {block.title} <TooltipIcon side='right' tipText={block.tooltip} />
            </Text>
            <Text size='32' fontWeight={5} className={styles.blockValue}>
              {block.value}
            </Text>
            <Text fontWeight={4} size='14'>
              {block.subText}
            </Text>
          </div>
        ))}
      </div>

      <hr className={styles.divider} />

      {/* LEGEND */}
      <div className={styles.legendContainer}>
        <div className={styles.accordian}>
          <Text fontWeight={6} size='16' color='secondary' className={styles.legendTitle}>
            Legend
          </Text>
        </div>
        <div className={styles.accordianBody}>
          <div className={styles.legendItem}>
            {legendItems.map((item) => (
              <div key={item.label} className={styles.legendItemRow}>
                <div className={styles.legendIcon}>{item.icon}</div>
                <Text>{item.label}</Text>
              </div>
            ))}
          </div>
        </div>

        {/* COLOR SCALE */}
        <div className={styles.scaleLegend}>
          <Text>In-market Shoppers</Text>
          <div
            className={styles.gradiantBar}
            style={{
              background: `linear-gradient(90deg, rgba(130, 175, 247, 0.00) -3.01%, ${COLOR.SPARKY_BLUE_400} 100%)`,
            }}
          />
          <div className={styles.minMaxRow}>
            <Text size='12'>{0}</Text>
            <Text size='12'>{formatNumber(dataMax)}</Text>
          </div>
        </div>
      </div>
    </Panel>
  );
}
