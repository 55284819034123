import Button from 'Sparky/Button';
import Text from 'Sparky/Text';

import { DealerListItem } from 'components/DealerSelect/components/DealerSelectList/DealerSelectList';
import DealerSelectListItem from 'components/DealerSelect/components/DealerSelectListItem/DealerSelectListItem';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { Dealer } from 'types/Dealer';

import styles from './DealerFavorites.module.scss';

interface DealerFavoritesProps {
  favoritesList: DealerListItem[] | undefined;
  selectedIndex: number | undefined;
  onButtonClick: () => void;
}

export default function DealerFavorites({
  favoritesList,
  selectedIndex,
  onButtonClick,
}: DealerFavoritesProps) {
  const [, setSelectedDealerId] = useSelectedDealerId();
  if (!favoritesList || favoritesList.length <= 0) {
    return (
      <div className={styles.DealerNoFavorites}>
        <Text fontWeight={5} size='20' color='secondary' lineHeight='28px'>
          No starred dealers yet
        </Text>
        <Text fontWeight={4} size='14' lineHeight='20px'>
          View all dealers or prospects and star one to view them here
        </Text>
        <Button variant='outline' onClick={onButtonClick}>
          View all dealers
        </Button>
      </div>
    );
  }
  return (
    <>
      <Text className={styles.dealerHeader} type='label-01' color='secondary'>
        Starred
      </Text>
      <div className={styles.dealerList}>
        {favoritesList.map((item, index) => (
          <DealerSelectListItem
            key={item.id}
            data={item as Dealer}
            hasFocus={selectedIndex === index}
            setSelectedDealer={setSelectedDealerId}
          />
        ))}
      </div>
    </>
  );
}
