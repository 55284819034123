import React from 'react';

import ReactDOM from 'react-dom';

export interface PortalProps {
  children: React.ReactNode;
  containerRef?: React.RefObject<HTMLElement | null>;
}

/**
 * By default, Portal will render any children to the end of document.body.
 * Optionally, you can use the containerRef prop to append the Portal to an element.
 *
 * Portal is useful for rendering a natural React element hierarchy with a different DOM hierarchy to prevent parent styles from clipping or hiding content (for popovers, dropdowns, and modals).
 */
export default function Portal({ children, containerRef }: PortalProps) {
  return ReactDOM.createPortal(<>{children}</>, containerRef?.current ?? document.body);
}
