import React from 'react';

import styles from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTable.module.scss';
import {
  AcquisitionTableColumnKey,
  AcquisitionTableWithValueColumnKey,
} from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTableConfigDefs';
import Actions from 'pages/Inventory/Acquisition/AcquisitionTable/Actions';
import OrdinalScalePill from 'pages/Inventory/Acquisition/AcquisitionTable/OrdinalScalePill';
import Price from 'pages/Inventory/Acquisition/AcquisitionTable/Price';
import Sources from 'pages/Inventory/Acquisition/AcquisitionTable/Sources';
import { AcquisitionItem } from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';
import { getOrdinalScale } from 'pages/Inventory/Acquisition/utils/getOrdinalScale';
import ImageCell from 'pages/Inventory/InventoryPage/InventoryTable/ImageCell';
import { CSVDataType } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';
import VehicleSummary from 'pages/Inventory/InventoryPage/InventoryTable/VehicleSummary';
import { formatCurrency, formatNumber } from 'util/formatters';

export interface AcquisitionTableColumnDef {
  key: AcquisitionTableColumnKey;
  header: string;
  notation?: React.ReactNode;
  pillLabel: string;
  width?: string;
  textAlign?: 'left' | 'right' | 'center';
  className?: string;
  flag?: boolean;
  tooltipKey?: string;

  getter?(r: AcquisitionItem): string | number | boolean | null | undefined;

  cellRenderer(r: AcquisitionItem): React.ReactNode;

  csvRenderer(r: AcquisitionItem): string | number | undefined;
}

export interface AcquisitionTableWithValueColumnDef extends AcquisitionTableColumnDef {
  csvDataType?: CSVDataType;

  getter(r: AcquisitionItem): string | number | boolean | null | undefined;
}

export const AcquisitionTableColumnWithGetterDefs: Record<
  AcquisitionTableWithValueColumnKey,
  AcquisitionTableWithValueColumnDef
> = {
  modelYear: {
    key: 'modelYear',
    header: 'Year',
    pillLabel: 'Year',
    textAlign: 'left',
    tooltipKey: 'Live Inventory-Vehicle-Year',
    getter: (r) => r.vehicle.modelYear,
    flag: true,
    cellRenderer: (r) => r.vehicle.modelYear,
    csvDataType: 'number',
    csvRenderer: (r) => r.vehicle.modelYear,
  },
  make: {
    key: 'make',
    header: 'Make',
    pillLabel: 'Make',
    textAlign: 'left',
    tooltipKey: 'Live Inventory-Vehicle-Make',
    getter: (r) => r.vehicle.make,
    width: '10rem',
    flag: true,
    cellRenderer: (r) => r.vehicle.make,
    csvRenderer: (r) => r.vehicle.make,
  },
  model: {
    key: 'model',
    header: 'Model',
    pillLabel: 'Model',
    textAlign: 'left',
    tooltipKey: 'Live Inventory-Vehicle-Model',
    getter: (r) => r.vehicle.model,
    flag: true,
    cellRenderer: (r) => r.vehicle.model,
    csvRenderer: (r) => r.vehicle.model,
  },
  trim: {
    key: 'trim',
    header: 'Trim',
    pillLabel: 'Trim',
    textAlign: 'left',
    width: '12rem',
    tooltipKey: 'Live Inventory-Vehicle-Trim',
    getter: (r) => r.vehicle.trim,
    flag: true,
    cellRenderer: (r) => r.vehicle.trim,
    csvRenderer: (r) => r.vehicle.trim,
  },
  bodySegment: {
    key: 'bodySegment',
    header: 'Body style',
    pillLabel: 'Body style',
    textAlign: 'left',
    tooltipKey: 'Live Inventory-Vehicle-Body Style',
    getter: (r) => r.vehicle.bodySegment,
    flag: true,
    cellRenderer: (r) => r.vehicle.bodySegment?.toLocaleUpperCase(),
    csvRenderer: (r) => r.vehicle.bodySegment,
  },
  vin: {
    key: 'vin',
    header: 'VIN',
    pillLabel: 'VIN',
    textAlign: 'left',
    width: '13rem',
    tooltipKey: 'Live Inventory-Vehicle-VIN',
    getter: (r) => r.listing.vin,
    flag: true,
    cellRenderer: (r) => r.listing.vin,
    csvRenderer: (r) => r.listing.vin,
  },
  price: {
    key: 'price',
    header: 'Price',
    pillLabel: 'Price',
    textAlign: 'left',
    tooltipKey: 'Live Inventory-Price-Price',
    getter: (r) => (r.sources ? r.sources[0].price : undefined),
    flag: true,
    cellRenderer: (r) => <Price sources={r.sources} />,
    csvDataType: 'currency',
    csvRenderer: (r) => formatCurrency(r.sources ? r.sources[0].price : undefined),
  },
  // currentPrice: {
  //   key: 'currentPrice',
  //   header: 'Current\nprice',
  //   pillLabel: 'Current price',
  //   tooltipKey: 'Live Inventory-Price-Current Price',
  //   getter: (r) => r.priceMetrics?.currentPrice,
  //   textAlign: 'left',
  //   flag: true,
  //   cellRenderer: (r) => formatCurrency(r.priceMetrics?.currentPrice),
  //   csvDataType: 'currency',
  //   csvRenderer: (r) => formatCurrency(r.priceMetrics?.currentPrice),
  // },
  marketDaysSupply: {
    key: 'marketDaysSupply',
    header: 'Market\ndays supply',
    pillLabel: 'Market days supply',
    getter: (r) => r.marketMetrics?.marketDaysSupply,
    textAlign: 'left',
    flag: true,
    cellRenderer: (r) => formatNumber(r.marketMetrics?.marketDaysSupply),
    csvRenderer: (r) => formatNumber(r.marketMetrics?.marketDaysSupply),
  },
  localAvgMarketPrice: {
    key: 'localAvgMarketPrice',
    header: 'Avg market\nprice (Local)',
    pillLabel: 'Avg market price (Local)',
    tooltipKey: 'Live Inventory-Local Market-Avg Market Price (Local)',
    getter: (r) => r.priceMetrics?.localAvgMarketPrice,
    textAlign: 'left',
    flag: true,
    cellRenderer: (r) => formatCurrency(r.priceMetrics?.localAvgMarketPrice),
    csvDataType: 'currency',
    csvRenderer: (r) => formatCurrency(r.priceMetrics?.localAvgMarketPrice),
  },
  localAvgMarketSoldPrice: {
    key: 'localAvgMarketSoldPrice',
    header: 'Avg market\nsale price (Local)',
    pillLabel: 'Avg market sale price (Local)',
    tooltipKey: 'Live Inventory-Local Market-Avg Market Sale Price (Local)',
    getter: (r) => r.priceMetrics?.localAvgOnlineSoldPrice,
    textAlign: 'left',
    flag: true,
    cellRenderer: (r) => formatCurrency(r.priceMetrics?.localAvgOnlineSoldPrice),
    csvDataType: 'currency',
    csvRenderer: (r) => formatCurrency(r.priceMetrics?.localAvgOnlineSoldPrice),
  },
  demand: {
    key: 'demand',
    header: 'Demand',
    pillLabel: 'Demand',
    textAlign: 'left',
    tooltipKey: 'Live Inventory-Vehicle-Demand',
    getter: (r) => r.marketMetrics?.demand,
    width: '10rem',
    flag: true,
    cellRenderer: (r) => (
      <OrdinalScalePill
        ordinalScale={getOrdinalScale(r.marketMetrics?.demand)}
        tooltip={
          r.marketMetrics?.demand !== undefined
            ? `Shopper demand is ${getOrdinalScale(r.marketMetrics?.demand)}`
            : undefined
        }
        opposite
      />
    ),
    csvDataType: 'number',
    csvRenderer: (r) => formatNumber(r.marketMetrics?.demand),
  },
  supply: {
    key: 'supply',
    header: 'Supply',
    pillLabel: 'Supply',
    textAlign: 'left',
    tooltipKey: 'Live Inventory-Vehicle-Supply',
    getter: (r) => r.marketMetrics?.supply,
    width: '10rem',
    flag: true,
    cellRenderer: (r) => (
      <OrdinalScalePill
        ordinalScale={getOrdinalScale(r.marketMetrics?.supply)}
        tooltip={
          r.marketMetrics?.supply !== undefined
            ? `Supply is ${getOrdinalScale(r.marketMetrics?.supply)}`
            : undefined
        }
      />
    ),
    csvRenderer: (r) => formatNumber(r.marketMetrics?.supply),
  },
};

export const AcquisitionTableColumnDefs: Record<
  AcquisitionTableColumnKey,
  AcquisitionTableColumnDef
> = {
  ...AcquisitionTableColumnWithGetterDefs,
  imageCell: {
    key: 'imageCell',
    header: 'Photo',
    pillLabel: 'Photo',
    tooltipKey: 'Live Inventory-Vehicle-Photo',
    className: styles.noPadding,
    flag: true,
    cellRenderer: (r) => <ImageCell primaryImage={r.listing.primaryImage ?? ''} />,
    csvRenderer: () => undefined,
  },
  summary: {
    key: 'summary',
    header: 'Summary',
    pillLabel: 'Summary',
    tooltipKey: 'Live Inventory-Vehicle-Summary',
    textAlign: 'left',
    width: '14rem',
    className: styles.noPadding,
    flag: true,
    cellRenderer: (r) => (
      <VehicleSummary
        vin={r.listing?.vin}
        trim={r.vehicle?.trim}
        make={r.vehicle?.make}
        model={r.vehicle?.model}
        bodySegment={r.vehicle?.bodySegment}
        modelYear={r.vehicle?.modelYear}
        primaryImage={r.listing?.primaryImage}
        disableHover={true}
        disabled
      />
    ),
    csvRenderer: () => undefined,
  },
  source: {
    key: 'source',
    header: 'Source',
    pillLabel: 'Source',
    tooltipKey: 'Live Inventory-Vehicle-Source',
    width: '6rem',
    flag: true,
    className: styles.noPadding,
    cellRenderer: (r) => <Sources sources={r.sources} />,
    csvRenderer: (r) => r.sources?.toString(),
  },
  actions: {
    key: 'actions',
    header: 'Actions',
    pillLabel: 'Actions',
    tooltipKey: 'Live Inventory-Vehicle-Action',
    width: '3rem',
    flag: true,
    cellRenderer: (r) => <Actions sources={r.sources} />,
    csvRenderer: () => undefined,
  },
};
