export const SliderIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.33398 7.66671H2.22287M10.2229 7.66671H14.6673M6.22228 10C5.78276 10 5.35312 9.86319 4.98768 9.6068C4.62223 9.35041 4.33741 8.986 4.16921 8.55964C4.00102 8.13327 3.95701 7.66412 4.04275 7.2115C4.1285 6.75888 4.34015 6.34312 4.65093 6.01679C4.96171 5.69047 5.35767 5.46824 5.78874 5.37821C6.21981 5.28818 6.66663 5.33439 7.07269 5.51099C7.47874 5.68759 7.82581 5.98666 8.06999 6.37038C8.31417 6.75409 8.4445 7.20522 8.4445 7.66671C8.4445 8.28555 8.21037 8.87904 7.79363 9.31662C7.37688 9.75421 6.81165 10 6.22228 10Z'
      stroke='#0D2B41'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
