import Text from 'Sparky/Text';

import styles from './ErrorFallback.module.scss';

interface ErrorFallbackProps {
  error?: Error;
  resetErrorBoundary?(...args: Array<unknown>): void;
}

/**
 * Generic error message page, for ErrorBoundary fallback
 */
export default function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <div className={styles.errorFallback}>
      <Text as='h1' size='18' fontWeight={7}>
        Something went wrong
      </Text>
      <Text size='14' alignment='center' className={styles.details}>
        Please refresh the page. If the problem persists, please contact Lotlinx Operations with an
        internal ticket (or your dedicated Lotlinx Sales or Client Services Representative).
      </Text>
    </div>
  );
}
