import classNames from 'classnames';
import Popover from 'Sparky/Popover';
import { InfoIcon } from 'Sparky/SVGs/InfoIcon';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { tooltipJSON } from 'components/TooltipIcon/tooltipJSON';

import styles from './TooltipIcon.module.scss';

export interface ToolipIconProps {
  // for searching file tooltipJSON.ts
  textKey?: string;
  // manually set tooltip text
  tipText?: string;
  side?: 'left' | 'right' | 'top' | 'bottom';
  className?: string;
  usePopover?: boolean;
  title?: string;
}

/**
 * TooltipIcon
 */
export default function TooltipIcon({
  textKey,
  tipText,
  side = 'bottom',
  className,
  usePopover = false,
  title,
}: ToolipIconProps) {
  let text;
  if (textKey) {
    const tooltipItem = tooltipJSON.find((item) => item.key === textKey);
    text = tooltipItem?.Tooltip ?? '';
  } else {
    text = tipText ?? '';
  }

  if (usePopover) {
    return (
      <div className={classNames([styles.tooltipicon, className])}>
        <Popover
          side={side}
          verticalOffset={12}
          horizontalOffset={-25}
          showCloseButton={false}
          align='end'
          isHoverTriggered
          popupContent={
            <div className={styles.popoverContainer}>
              {title && (
                <Text lineHeight='24px' color='secondary' fontWeight={5}>
                  {title}
                </Text>
              )}
              <Text size='14'>{tipText}</Text>
            </div>
          }
        >
          <div>
            <InfoIcon />
          </div>
        </Popover>
      </div>
    );
  }

  return (
    <div className={classNames([styles.tooltipicon, className])}>
      <Tooltip side={side} content={text}>
        <div>
          <InfoIcon />
        </div>
      </Tooltip>
    </div>
  );
}

export function getTooltipDefByKey(textKey: string) {
  return tooltipJSON.find((item) => item.key === textKey)?.Description ?? '';
}
