import React from 'react';

import BaseInput, { BaseInputProps } from 'Sparky/BaseInput';

export interface Props extends BaseInputProps {
  id: string;
  value?: number | string;
  autoFocus?: boolean;
  placeholder?: string;
  autoComplete?: string;

  onBlur?(event: React.ChangeEvent<HTMLInputElement>): void;

  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;

  onClick?: React.MouseEventHandler<HTMLInputElement> | undefined;

  onClear?(): void;

  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
}

export type TextInputProps = Props & Omit<React.ComponentPropsWithoutRef<'input'>, keyof Props>;
/**
 * TextInput
 */
const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      value,
      autoFocus,
      variants = 'default',
      size = 'md',
      disabled,
      placeholder,
      leftElement,
      rightElement,
      className,
      type,
      onClear,
      onKeyDown,
      onBlur,
      onChange,
      onClick,
      autoComplete,
      ...otherProps
    },
    ref
  ) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDown) {
        onKeyDown(event);
      }

      if (event.code === 'Escape') {
        if (onClear) {
          onClear();
        }
      }
    };
    return (
      <BaseInput
        variants={variants}
        size={size}
        disabled={disabled}
        leftElement={leftElement}
        rightElement={rightElement}
        className={className}
      >
        <input
          {...otherProps}
          ref={ref}
          id={id}
          value={value}
          type={type}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          autoComplete={autoComplete}
        />
      </BaseInput>
    );
  }
);

export default TextInput;
