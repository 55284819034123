import React, { useMemo, useState } from 'react';

import * as Popover from '@radix-ui/react-popover';
import {
  IconChevronDown,
  IconNewSection,
  IconSearch,
  IconStar,
  IconStarFilled,
} from '@tabler/icons-react';
import Chip from 'Sparky/Chip';
import Divider from 'Sparky/Divider';
import Spacer from 'Sparky/Spacer';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import TextInput from 'Sparky/TextInput';
import Tooltip from 'Sparky/Tooltip';

import { ButtonSize } from 'pages/Inventory/InventoryPage';
import { InventoryConfig } from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';

import styles from './InventoryConfigSelect.module.scss';

export interface InventoryConfigSelectProps {
  inventoryConfigs: InventoryConfig[];
  currentInventoryConfig: InventoryConfig;
  onSelectInventoryConfig(inventoryConfig: InventoryConfig): void;
  onCreateNewInventoryConfig(): void;
  onFavoriteInventoryConfig(e: React.MouseEvent, id: number, name: string): void;
  buttonSize: ButtonSize;
}

/**
 * TableViewSelect
 */
export default function InventoryConfigSelect({
  inventoryConfigs,
  currentInventoryConfig,
  onSelectInventoryConfig,
  onCreateNewInventoryConfig,
  onFavoriteInventoryConfig,
  buttonSize,
}: InventoryConfigSelectProps) {
  const [searchText, setSearchText] = useState<string>('');

  const inventoryConfigsList = useMemo(
    () =>
      inventoryConfigs.filter(
        (inventoryConfig) =>
          inventoryConfig.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      ),
    [searchText, inventoryConfigs]
  );

  return (
    <Popover.Root>
      <Popover.Trigger className={styles.inventoryConfigSelect}>
        <Text size={buttonSize === 'md' ? '16' : '12'} fontWeight={7} className={styles.selectName}>
          {currentInventoryConfig.name}
        </Text>
        <IconChevronDown size={buttonSize === 'md' ? '20' : '12'} className={styles.icon} />
      </Popover.Trigger>
      <Popover.PopoverPortal>
        <Popover.Content
          align='start'
          side='bottom'
          sideOffset={6}
          className={styles.inventoryConfigSelectPopover}
        >
          <TextInput
            id='search inventory config'
            size={buttonSize}
            leftElement={<IconSearch />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder='Search for a table view'
            className={styles.searchInput}
          />
          <div className={styles.optionsContainer}>
            {inventoryConfigsList?.map((inventoryConfig) => (
              <button
                key={inventoryConfig.id}
                onClick={() => onSelectInventoryConfig(inventoryConfig)}
                className={styles.option}
              >
                <Text size={buttonSize === 'md' ? '16' : '12'}>{inventoryConfig.name}</Text>
                <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
                {!inventoryConfig.isPreset && (
                  <Chip
                    color='grey'
                    tooltip={`You created this table on ${inventoryConfig.details
                      .split(' ')
                      .pop()}`}
                    className={styles.custom}
                  >
                    Custom
                  </Chip>
                )}
                {inventoryConfig.isFavourite ? (
                  <Tooltip content='Starred'>
                    <IconStarFilled
                      color={COLOR.SPARKY_YELLOW_400}
                      size='16'
                      cursor='pointer'
                      onClick={(e) =>
                        onFavoriteInventoryConfig(e, inventoryConfig.id, inventoryConfig.name)
                      }
                    />
                  </Tooltip>
                ) : (
                  <Tooltip content='Not starred'>
                    <IconStar
                      size='16'
                      cursor='pointer'
                      onClick={(e) =>
                        onFavoriteInventoryConfig(e, inventoryConfig.id, inventoryConfig.name)
                      }
                    />
                  </Tooltip>
                )}
              </button>
            ))}
          </div>
          <Divider className={styles.divider} />
          <button className={styles.createNewTableView} onClick={onCreateNewInventoryConfig}>
            <IconNewSection size={buttonSize === 'md' ? '20' : '12'} />
            <Text size={buttonSize === 'md' ? '16' : '12'}>Create custom table</Text>
          </button>
        </Popover.Content>
      </Popover.PopoverPortal>
    </Popover.Root>
  );
}
