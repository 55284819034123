import { ReactElement, useState } from 'react';

import Breadcrumbs from 'Sparky/Breadcrumbs';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Popover from 'Sparky/Popover';

import SegmentPopper from 'components/Segments/SegmentPopper/SegmentPopper';
import SegmentsButton from 'components/Segments/SegmentsButton/SegmentsButton';

import styles from './SegmentBreadcrumb.module.scss';

interface SegmentBreadcrumbProps {
  pageTitle: string;
  pageTitle2?: string;
  buttonList?: { icon: ReactElement; onClick: () => void; aria: string }[];
}

export default function SegmentBreadcrumb({
  pageTitle,
  pageTitle2,
  buttonList,
}: SegmentBreadcrumbProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Breadcrumbs
      className={styles.segmentBreadcrumbWrapper}
      pageTitles={
        pageTitle2
          ? [{ title: 'Analytics' }, { title: pageTitle2 }, { title: pageTitle }]
          : [{ title: 'Analytics' }, { title: pageTitle }]
      }
    >
      <Flex column={false} alignItems='center'>
        {buttonList &&
          buttonList.map((button) => {
            return (
              <IconButton
                key={button.aria}
                onClick={() => button.onClick()}
                aria-label={button.aria}
                size='md'
              >
                {button.icon}
              </IconButton>
            );
          })}
        <Popover
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          showCloseButton={false}
          isHoverTriggered={false}
          popupContent={<SegmentPopper />}
          verticalOffset={8}
          avoidCollisions
          showCarrat={false}
          side='bottom'
          align='end'
        >
          <div>
            <SegmentsButton />
          </div>
        </Popover>
      </Flex>
    </Breadcrumbs>
  );
}
