import React from 'react';

import classnames from 'classnames';

import styles from './Panel.module.scss';

/**
 * Wraps children in a `div` with panel styling
 */
export default function Panel({ className, ...otherProps }: React.ComponentPropsWithoutRef<'div'>) {
  return <div className={classnames([styles.panel, className])} {...otherProps} />;
}
