import { z } from 'zod';

import { InventoryTableColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableConfig } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableTemplates';
import { SortColumn } from 'pages/Inventory/InventoryPage/InventoryTable';

const InventoryTableWithValueColumnKeySchema = z.union([
  z.literal('budget'),
  z.literal('aiRecommendedBudgetIncrease'),
  z.literal('modelYear'),
  z.literal('make'),
  z.literal('movedDate'),
  z.literal('model'),
  z.literal('trim'),
  z.literal('bodySegment'),
  z.literal('stockNumber'),
  z.literal('vin'),
  z.literal('condition'),
  z.literal('imageCount'),
  z.literal('mileage'),
  z.literal('dol'),
  z.literal('exteriorColor'),
  z.literal('status'),
  z.literal('lotStatus'),
  z.literal('isBeingPublished'),
  z.literal('spend'),
  z.literal('spendLifetime'),
  z.literal('remainingBudget'),
  z.literal('monthlyCap'),
  z.literal('lifetimeCap'),
  z.literal('shoppers'),
  z.literal('shoppersLifetime'),
  z.literal('lotlinxVDP'),
  z.literal('lotlinxVDPLifetime'),
  z.literal('totalVDP'),
  z.literal('totalVdpsSinceLastPriceChange'),
  z.literal('totalVDPLifetime'),
  z.literal('costPerVDP'),
  z.literal('costPerVDPLifetime'),
  z.literal('lotlinxVdpPct'),
  z.literal('lotlinxVdpPctLifetime'),
  z.literal('totalConvSinceLastPriceChange'),
  z.literal('conversions'),
  z.literal('conversionsLifetime'),
  z.literal('leadFormSubmissions'),
  z.literal('leadFormSubmissionsLifetime'),
  z.literal('clickToText'),
  z.literal('clickToTextLifetime'),
  z.literal('clickToChat'),
  z.literal('clickToChatLifetime'),
  z.literal('clickToCall'),
  z.literal('clickToCallLifetime'),
  z.literal('currentPrice'),
  z.literal('lastPublishedPrice'),
  z.literal('initialPrice'),
  z.literal('totalPriceChange'),
  z.literal('daysSinceLastPriceChange'),
  z.literal('lastPriceChangeDate'),
  z.literal('priceChangeNumber'),
  z.literal('avgPriceChange'),
  z.literal('currentPriceToMarketPct'),
  z.literal('lastPriceToMarketPct'),
  z.literal('initialPriceToMarketPct'),
  z.literal('avgMarketPrice'),
  z.literal('avgMarketPrice7DayPct'),
  z.literal('avgMarketSoldPrice'),
  z.literal('avgMarketSoldPrice7DayPct'),
  z.literal('avgDTS'),
  z.literal('avgDTS7DayPct'),
  z.literal('avgBuyers'),
  z.literal('avgBuyers7DayPct'),
  z.literal('dolMarket'),
  z.literal('dolMarket7DayPct'),
  z.literal('similarVins'),
  z.literal('similarVins7DayPct'),
  z.literal('localAvgMarketPrice'),
  z.literal('localAvgMarketPrice7DayPct'),
  z.literal('localAvgMarketSoldPrice'),
  z.literal('localAvgMarketSoldPrice7DayPct'),
  z.literal('localAvgDTS'),
  z.literal('localAvgDTS7DayPct'),
  z.literal('localAvgBuyers'),
  z.literal('localAvgBuyers7DayPct'),
  z.literal('localDolMarket'),
  z.literal('localDolMarket7DayPct'),
  z.literal('localSimilarVins'),
  z.literal('localSimilarVins7DayPct'),
  z.literal('riskScore'),
  z.literal('dealDate'),
  z.literal('dealNumber'),
  z.literal('soldPrice'),
  z.literal('frontendGross'),
  z.literal('lotlinxLeads'),
  z.literal('lotlinxLeadsLifetime'),
  z.literal('totalLeads'),
  z.literal('totalLeadsLifetime'),
  z.literal('avgAgeOfLeads'),
  z.literal('otherPaidCampaignDays'),
  z.literal('otherPaidVDPsLifetime'),
  z.literal('lotlinxEngagementsLifetime'),
  z.literal('otherPaidEngagementsLifetime'),
  z.literal('totalEngagementsLifetime'),
  z.literal('lotlinxPercentEngagementsLifetime'),
  z.literal('otherPaidConversionsLifetime'),
  z.literal('totalConversionsLifetime'),
  z.literal('lotlinxPercentConversionsLifetime'),
  z.literal('marketDaysSupply'),
  z.literal('supply'),
  z.literal('demand'),
  z.literal('localRolling7DayAverageActiveShoppers'),
  z.literal('localRolling7DayPercentageActiveShoppers'),
  z.literal('rolling30DayPercentageLocalShoppers'),
  z.literal('rolling7DayTotalLocalShoppersActive'),
  z.literal('rolling30DayTotalLocalShoppers'),
  z.literal('rolling30DayAverageLocalShoppers'),
  z.literal('demandTrend7dLocal'),
  z.literal('demandTrendLocal'),
]);

const InventoryTableColumnKeySchema: z.ZodSchema<InventoryTableColumnKey> = z.union([
  z.literal('imageCell'),
  z.literal('summary'),
  z.literal('totalLeadsByStatus'),
  InventoryTableWithValueColumnKeySchema,
]);

const InventoryTableConfigSchema: z.ZodSchema<InventoryTableConfig> = z.object({
  frozenColumns: z.array(InventoryTableColumnKeySchema),
  mainColumns: z.array(InventoryTableColumnKeySchema),
});

const SortByColumnSchema: z.ZodSchema<SortColumn> = z.object({
  id: InventoryTableWithValueColumnKeySchema,
  direction: z.union([z.literal('asc'), z.literal('desc')]),
});

const InventoryConfigSchema = z.object({
  id: z.number(),
  isFavourite: z.boolean(),
  isPreset: z.boolean(),
  name: z.string(),
  details: z.string(),
  inventoryTableConfig: InventoryTableConfigSchema,
  sortColumns: z.array(SortByColumnSchema),
  // zod validation has a problem with deep optional nested objects. so we have to set it to any type to ignore the filtersState validation
  filtersState: z.any(),
});

export const InventoryTableConfigResponseSchema = z.object({
  soldInventoryConfigs: z.array(InventoryConfigSchema),
  liveInventoryConfigs: z.array(InventoryConfigSchema),
});
