import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { datePeriodAtom } from 'store/datePeriodAtom';

import ENDPOINTS from 'api/utils/endpoints';
import { get } from 'api/utils/fetch';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { VDPPriceChartData } from 'pages/Inventory/VinDetailSidebar/VDPSection/VDPPriceChart';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

import { VDPPriceDataResponseSchema } from './VDPPriceChartSchema';

interface FetchVDPPriceDataProps {
  token?: string;
  dealerId?: number;
  vin: string;
  startDate?: string;
  endDate?: string;
}

export interface VDPPriceDataResponse {
  trends?: VDPPriceChartData[];
}

async function fetchVDPPriceData({
  token,
  dealerId,
  vin,
  startDate,
  endDate,
}: FetchVDPPriceDataProps) {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  const res = await get<VDPPriceDataResponse>(`${ENDPOINTS.vdpPriceData}/${dealerId}/${vin}`, {
    headers: { token },
    params: {
      startDate,
      endDate,
    },
  });
  return VDPPriceDataResponseSchema.parse(res);
}

export default function useVDPPriceData(vin: string, pageKey?: string) {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const [datePeriod] = useAtom(datePeriodAtom);
  const { VDPPriceDataKey: queryKey } = useQueryKeys({ vin, pageKey });
  let startDate: string, endDate: string;

  if (pageKey === 'sold') {
    startDate = datePeriod[0];
    endDate = datePeriod[1];
  } else {
    startDate = dayjs().subtract(1, 'month').startOf('month').format('YYYYMMDD');
    endDate = dayjs().subtract(1, 'month').endOf('month').format('YYYYMMDD');
  }

  return useQuery(queryKey, () =>
    fetchVDPPriceData({ token: user?.token, dealerId, vin, startDate, endDate })
  );
}
