import { useRef } from 'react';

import BlankDropdown from 'Sparky/BlankDropdown';
import Radio from 'Sparky/Radio';
import Text from 'Sparky/Text';

import styles from './SelectDropdown.module.scss';
interface SelectDropdownProps {
  id: string;
  options: { label: string; value: string }[];
  value: string;
  setValue: (val: string) => void;
  label?: string;
}

// This is currently only created for the Lex Chatbot
export default function SelectDropdown({
  id,
  options,
  label,
  value,
  setValue,
}: SelectDropdownProps) {
  const refArray = useRef([]);

  return (
    <div className={styles.selectDropdown}>
      <BlankDropdown
        id={id}
        variant='text'
        onOpenChange={() => {
          if (refArray.current[0]) {
            //@ts-ignore
            refArray.current[0].focus();
          }
        }}
        className={styles.dropdownWrapper}
        contentStyles={styles.selectDropdownContent}
        valueContent={
          <div className={styles.dropdownLabel}>
            <Text size={20} fontWeight={7} color='primary'>
              {label}
            </Text>
          </div>
        }
      >
        <div>
          <div className={styles.selectDropdownHeader}>
            <Text color='secondary'>Response personality</Text>
          </div>

          {options.map((opt, index) => {
            return (
              <button
                //@ts-ignore
                ref={(e) => (refArray.current[index] = e)}
                key={opt.value}
                //@ts-ignore
                onBlur={() => refArray.current[index + (1 % options.length)].focus()}
                className={styles.selectDropdownOption}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    setValue(opt.value);
                  }

                  if (e.key === 'Tab') {
                    //@ts-ignore
                    refArray.current[(index + 1) % options.length].focus();
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setValue(opt.value);
                }}
              >
                <Radio noHover checked={opt.value === value} />
                <Text>{opt.label}</Text>
              </button>
            );
          })}
        </div>
      </BlankDropdown>
    </div>
  );
}
