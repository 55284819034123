import classnames from 'classnames';
import CheckboxField from 'Sparky/CheckboxField';
import RadioField from 'Sparky/RadioField';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import styles from './SelectOptionsItem.module.scss';

export interface SelectOptionsItemProps {
  checked: boolean;
  disabled?: boolean;
  itemCountLabel?: string;
  showItemCount?: boolean;
  label: string | number;
  matchCount: number;
  optionKey: string | number;
  handleChange(opt: string | number, checked: boolean): void;
  /** Show options as radioFields instead of checkboxes */
  exclusive?: boolean;
}

/** Item in `SelectOptions` */
export default function SelectOptionsItem({
  checked,
  disabled,
  itemCountLabel,
  showItemCount,
  label,
  matchCount,
  optionKey,
  handleChange,
  exclusive,
}: SelectOptionsItemProps) {
  const Input = exclusive ? RadioField : CheckboxField;

  return (
    <Tooltip
      content={
        <span style={{ textAlign: 'center' }}>No VINs available based on your current filters</span>
      }
      disabled={matchCount !== 0}
      className={styles.tooltip}
    >
      <div className={classnames([styles.option, !matchCount && styles.muted])}>
        <Input
          smallHover={true}
          disabled={disabled}
          checked={checked}
          onChange={(e) => handleChange(optionKey, e.target.checked)}
        >
          <Text size='14' className={styles.itemLabel}>
            {label}
          </Text>
        </Input>
        {showItemCount && (
          <Text size='12' className={styles.itemCount}>
            {matchCount} {itemCountLabel}
          </Text>
        )}
      </div>
    </Tooltip>
  );
}
