import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { AcquisitionSchema } from 'pages/Inventory/Acquisition/api/useAcquisition/acquisitionSchema';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

/**
 * useAcquisition
 * @param prop
 */

interface FetchAcquisition {
  token?: string;
  dealerId?: number;
}

export interface SourceItem {
  name: string;
  price?: number;
  listingUrl: string;
}

export interface AcquisitionItem {
  listing: {
    vin?: string;
    primaryImage?: string;
    comingSoon?: boolean;
  };
  vehicle: {
    modelYear?: number;
    make?: string;
    model?: string;
    trim?: string;
    bodySegment?: string;
  };
  marketMetrics: {
    marketDaysSupply?: number;
    demand?: number;
    supply?: number;
  };
  priceMetrics?: {
    currentPrice?: number;
    localAvgMarketPrice?: number;
    localAvgOnlineSoldPrice?: number;
  };
  sources: SourceItem[];
}

export interface AcquisitionResponse {
  results: AcquisitionItem[];
}

async function fetchAcquisition({ token, dealerId }: FetchAcquisition) {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  const res = await get<AcquisitionResponse>(`${ENDPOINTS.acquisition}/${dealerId}`, {
    headers: { token },
  });
  // console.log(AcquisitionSchema.parse(res));
  return AcquisitionSchema.parse(res);
  // return res;
}

export default function useAcquisition() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { acquisitionKey } = useQueryKeys();

  return useQuery<AcquisitionResponse>(acquisitionKey, () =>
    fetchAcquisition({ token: user?.token, dealerId })
  );
}
