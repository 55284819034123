import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { post } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

interface saveSegmentFavoritesProps {
  token: string | undefined;
  dealerId: number | undefined;
  favorites: string[];
}

interface saveSegmentFavoritesRequest {
  favorites: string[];
}

/**
 * saveSegmentFavorites
 */

export async function saveSegmentFavorites({
  token,
  dealerId,
  favorites,
}: saveSegmentFavoritesProps) {
  assertIsDefined(token);
  assertIsDefined(dealerId);
  assertIsDefined(favorites);

  const res = await post<saveSegmentFavoritesRequest>(
    `${ENDPOINTS.saveSegmentFavorites}/${dealerId}`,
    {
      headers: { token },
      body: {
        favorites: favorites,
      },
    }
  );

  return res;
}

export default function useSaveSegmentFavorites() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);

  return useMutation((favorites: string[]) =>
    saveSegmentFavorites({ token: user?.token, dealerId, favorites })
  );
}
