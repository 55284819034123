import { useEffect } from 'react';

import { useAtom } from 'jotai';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { userAtom } from 'store';
import { User } from 'types/User';

/** Keeps the LD context up-to-date with the active user */
export default function useLDUserTracker() {
  const [user] = useAtom(userAtom);
  const ldClient = useLDClient();

  useEffect(() => {
    setLDIdentity(ldClient, user);
  }, [ldClient, user]);
}

function setLDIdentity(client?: LDClient, user?: User) {
  if (!client) {
    return;
  }

  if (!user) {
    client.identify({ anonymous: true });
  } else {
    client.identify({
      kind: 'user',
      key: user.id.toString(),
      firstName: user.first,
      lastName: user.last,
      email: user.email,
      // Custom attributes:
      accountType: user.accountType,
    });
  }
}
