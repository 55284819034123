import { SafeParseError } from 'zod';

/**
 * Clear Response of faulty data based on indexs proved by Zod validation.
 * Note: this will only work on response that is an object of arrays.
 * */
export function filterResponseErrors<T>(
  zodValidation: SafeParseError<unknown>,
  response: T[]
): T[] {
  const errorIndexArray = zodValidation.error.issues.map((issue) => {
    return issue.path[1];
  });

  return response.filter((_, index) => !errorIndexArray.includes(index));
}
