import { COLOR } from 'Sparky/styles/vars';

import { ProbabilityOfSaleKeys } from 'types/InventoryItem';

export function getPriceScenarioColor(
  probability: ProbabilityOfSaleKeys | undefined,
  returnHexColor?: boolean
): string {
  switch (probability) {
    case 'highlyUnlikely':
      return returnHexColor ? COLOR.SPARKY_RED_300 : 'red';
    case 'unlikely':
      return returnHexColor ? COLOR.SPARKY_ORANGE_300 : 'orange';
    case 'neutral':
      return returnHexColor ? COLOR.SPARKY_YELLOW_300 : 'yellow';
    case 'likely':
      return returnHexColor ? COLOR.SPARKY_LIME_300 : 'lime';
    case 'highlyLikely':
      return returnHexColor ? COLOR.SPARKY_GREEN_300 : 'green';
    default:
      return returnHexColor ? COLOR.TEXT_PRIMARY : 'primary';
  }
}
