import React, { ReactElement } from 'react';

import { IconCaretDownFilled } from '@tabler/icons-react';
import classnames from 'classnames';
import Popover from 'Sparky/Popover';
import Spacer from 'Sparky/Spacer';
import { SPACING } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import styles from './DropdownButton.module.scss';

export interface DropdownButtonProps {
  isOpen: boolean;
  className?: string;
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactElement;
  active?: boolean;
}

/**
 * Dropdown Button
 */
export default function DropdownButton({
  children,
  isOpen,
  title,
  setIsOpen,
  className,
  active = false,
}: DropdownButtonProps) {
  return (
    <div className={classnames([styles.dropdownButtonWrapper, className])}>
      <Popover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        showCarrat={false}
        showCloseButton={false}
        side='bottom'
        align='start'
        verticalOffset={8}
        aria-label='Dropdown Popover'
        contentClassName={styles.dropdownPopover}
        popupContent={children}
      >
        <button className={styles.dropdownButton}>
          <Text className={active ? styles.active : ''} size='16'>
            {title}
          </Text>
          <Spacer axis='horizontal' size={SPACING.SPACING_04} />
          <IconCaretDownFilled size={16} />
        </button>
      </Popover>
    </div>
  );
}
