import { IconLockFilled } from '@tabler/icons-react';
import Flex from 'Sparky/Flex';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import styles from './VMBIUpgradeCTA.module.scss';

export default function UpgradeCTA() {
  const handleLinkClick = (e: any) => {
    e.preventDefault(); // Stop the default navigation
    window.open('https://lotlinx.com/contact-us/', '_blank', 'noopener,noreferrer');
  };
  return (
    <div className={styles.upgradeCTA}>
      <div className={styles.background} />
      <div className={styles.actionPanel}>
        <div className={styles.left}>
          <Flex column alignItems='center' rowGap='12'>
            <IconLockFilled size={38} color={COLOR.SPARKY_NAVY_400} />
            <Text alignment='center' color='navy' fontWeight={7} size={32}>
              It’s time for an upgrade!
            </Text>
          </Flex>
          <Text
            className={styles.mainText}
            size={20}
            fontWeight={4}
            lineHeight='24px'
            alignment='left'
            color='navy'
          >
            Add <b>Lotlinx VIN Manager Business Intelligence</b> to your existing program to view
            this page and unlock your business’s full potential.
          </Text>
          <Text
            className={styles.mainText}
            size={20}
            fontWeight={4}
            lineHeight='24px'
            alignment='left'
            color='navy'
          >
            Get access to data like never before. Make data driven decisions to accelerate your
            business and start moving metal!
          </Text>
          <Flex column rowGap='12px' alignItems='center'>
            <button onClick={handleLinkClick} className={styles.requestButton}>
              Request an upgrade
            </button>
            <Text
              className={styles.secondaryText}
              size={14}
              lineHeight='17px'
              fontWeight={4}
              alignment='center'
              color='secondary'
            >
              Please fill out our{' '}
              <a
                href='https://lotlinx.com/contact-us/'
                target='_blank'
                className={styles.contactUs}
                rel='noreferrer'
              >
                Contact Us
              </a>{' '}
              form and specify that you’d like to upgrade. After you submit your request we will be
              in touch with next steps.
            </Text>
          </Flex>
        </div>
        <div className={styles.right}></div>
      </div>
    </div>
  );
}
