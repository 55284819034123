import { IconX } from '@tabler/icons-react';
import Chip from 'Sparky/Chip';
import Skeleton from 'Sparky/Skeleton';
import { SPACING } from 'Sparky/styles/vars';

import styles from './ColumnPill.module.scss';

export interface ColumnPillProps {
  label: string;
  isActive: boolean;
  isLoading: boolean;
  handleClick(): void;
}

/**
 * ColumnPill
 */
export default function ColumnPill({ label, isActive, isLoading, handleClick }: ColumnPillProps) {
  if (isLoading) {
    return (
      <button disabled className={styles.columnPill}>
        <Skeleton
          radius={SPACING.SPACING_12}
          height={SPACING.SPACING_24}
          width={SPACING.SPACING_64}
        />
      </button>
    );
  }

  return (
    <Chip
      variant={isActive ? 'filled' : 'outline'}
      rightIcon={isActive ? <IconX size={12} /> : undefined}
      handleClick={() => {
        if (!isActive) {
          handleClick();
        }
      }}
      shape='tag'
      handleRightIconClick={handleClick}
    >
      {label}
    </Chip>
  );
}
