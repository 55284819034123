import { InventoryTableColumnKey } from './InventoryTableConfigDefs';

export type InventoryTableConfigGroup = 'frozenColumns' | 'mainColumns';

export interface InventoryTableConfig {
  frozenColumns: InventoryTableColumnKey[];
  mainColumns: InventoryTableColumnKey[];
}

export const DefaultLiveInventoryTableConfig: InventoryTableConfig = {
  frozenColumns: ['imageCell', 'summary', 'mileage', 'dol'],
  mainColumns: [
    'stockNumber',
    'isBeingPublished',
    'budget',
    'spend',
    'remainingBudget',
    'monthlyCap',
    'shoppers',
    'lotlinxVDP',
    'totalVDP',
    'costPerVDP',
    'lotlinxVdpPct',
    'conversions',
    'leadFormSubmissions',
    'clickToText',
    'clickToCall',
    'clickToChat',
    'currentPrice',
    'lastPublishedPrice',
    'initialPrice',
    'totalPriceChange',
    'daysSinceLastPriceChange',
    'priceChangeNumber',
    'avgPriceChange',
    'currentPriceToMarketPct',
    'lastPriceToMarketPct',
    'initialPriceToMarketPct',
    'avgMarketPrice',
    'avgMarketSoldPrice',
    'avgDTS',
    'avgBuyers',
    'dolMarket',
    'dolMarket7DayPct',
    'similarVins',
    'marketDaysSupply',
    /** Disabled until post-NADA **/
    // 'dealerDaysSupply',
  ],
};

export const LotlinxPublishedView: InventoryTableConfig = {
  frozenColumns: ['imageCell', 'summary', 'dol'],
  mainColumns: [
    'monthlyCap',
    'lotlinxVDPLifetime',
    'totalVDPLifetime',
    'lotlinxVDP',
    'totalVDP',
    'totalLeadsLifetime',
    'avgAgeOfLeads',
    'totalLeads',
    'totalLeadsByStatus',
    'daysSinceLastPriceChange',
    'currentPrice',
    'currentPriceToMarketPct',
    'initialPrice',
    'initialPriceToMarketPct',
    'totalPriceChange',
    'priceChangeNumber',
    'avgPriceChange',
    'localAvgBuyers',
    'localAvgBuyers7DayPct',
  ],
};

export const MTDLeadsView: InventoryTableConfig = {
  frozenColumns: ['imageCell', 'summary', 'dol'],
  mainColumns: [
    'totalLeads',
    'avgAgeOfLeads',
    'totalLeadsByStatus',
    'totalVDP',
    'lotlinxVDP',
    'daysSinceLastPriceChange',
    'currentPriceToMarketPct',
    'monthlyCap',
    'lotlinxVDPLifetime',
    'totalVDPLifetime',
    'totalLeadsLifetime',
    'currentPrice',
    'initialPrice',
    'initialPriceToMarketPct',
    'totalPriceChange',
    'priceChangeNumber',
    'avgPriceChange',
    'localAvgBuyers',
    'localAvgBuyers7DayPct',
  ],
};

export const PriceChangeReview: InventoryTableConfig = {
  frozenColumns: ['imageCell', 'summary', 'dol'],
  mainColumns: [
    'daysSinceLastPriceChange',
    'priceChangeNumber',
    'totalPriceChange',
    'currentPriceToMarketPct',
    'totalLeads',
    'avgAgeOfLeads',
    'totalVDP',
    'lotlinxVDP',
    'monthlyCap',
    'lotlinxVDPLifetime',
    'totalVDPLifetime',
    'totalLeadsLifetime',
    'totalLeadsByStatus',
    'currentPrice',
    'initialPrice',
    'initialPriceToMarketPct',
    'avgPriceChange',
    'localAvgBuyers',
    'localAvgBuyers7DayPct',
  ],
};

export const SalesManagerView: InventoryTableConfig = {
  frozenColumns: ['imageCell', 'summary', 'dol'],
  mainColumns: [
    'monthlyCap',
    'lotlinxVDPLifetime',
    'totalVDPLifetime',
    'lotlinxVDP',
    'totalVDP',
    'totalLeadsLifetime',
    'avgAgeOfLeads',
    'totalLeads',
    'totalLeadsByStatus',
    'daysSinceLastPriceChange',
    'currentPrice',
    'currentPriceToMarketPct',
    'initialPrice',
    'initialPriceToMarketPct',
    'totalPriceChange',
    'priceChangeNumber',
    'avgPriceChange',
    'localAvgBuyers',
    'localAvgBuyers7DayPct',
  ],
};

export const MtdVdpsHighToLow: InventoryTableConfig = {
  frozenColumns: ['imageCell', 'summary', 'dol'],
  mainColumns: [
    'totalVDP',
    'lotlinxVDP',
    'totalLeads',
    'totalLeadsByStatus',
    'daysSinceLastPriceChange',
    'currentPriceToMarketPct',
    'localAvgBuyers',
    'localAvgBuyers7DayPct',
    'monthlyCap',
    'lotlinxVDPLifetime',
    'totalVDPLifetime',
    'totalLeadsLifetime',
    'avgAgeOfLeads',
    'currentPrice',
    'initialPrice',
    'initialPriceToMarketPct',
    'totalPriceChange',
    'priceChangeNumber',
    'avgPriceChange',
  ],
};

export const InvestmentReview: InventoryTableConfig = {
  frozenColumns: ['imageCell', 'summary', 'dol'],
  mainColumns: [
    'priceChangeNumber',
    'totalPriceChange',
    'spend',
    'lifetimeCap',
    'totalLeadsLifetime',
    'totalVDPLifetime',
    'lotlinxVDPLifetime',
    'daysSinceLastPriceChange',
    'currentPriceToMarketPct',
    'currentPrice',
    'initialPrice',
  ],
};

export const VsrUsed: InventoryTableConfig = {
  frozenColumns: [],
  mainColumns: [
    'imageCell',
    'riskScore',
    'vin',
    'stockNumber',
    'modelYear',
    'make',
    'model',
    'bodySegment',
    'currentPrice',
    'dol',
    'totalVDP',
    'localSimilarVins',
    'rolling7DayTotalLocalShoppersActive',
    'totalPriceChange',
    'similarVins',
  ],
};

export const VsrNew: InventoryTableConfig = {
  frozenColumns: [],
  mainColumns: [
    'imageCell',
    'riskScore',
    'vin',
    'stockNumber',
    'modelYear',
    'make',
    'model',
    'bodySegment',
    'currentPrice',
    'dol',
    'totalVDP',
    'localSimilarVins',
    'rolling7DayTotalLocalShoppersActive',
    'totalPriceChange',
  ],
};

export const VsrBasic: InventoryTableConfig = {
  frozenColumns: [],
  mainColumns: [
    'imageCell',
    'modelYear',
    'make',
    'model',
    'vin',
    'stockNumber',
    'condition',
    'bodySegment',
    'dol',
    'currentPrice',
    'totalPriceChange',
    'avgBuyers',
  ],
};

export const DefaultSoldInventoryTableConfig: InventoryTableConfig = {
  frozenColumns: ['imageCell', 'summary', 'mileage', 'dol'],
  mainColumns: [
    'trim',
    'bodySegment',
    'stockNumber',
    'vin',
    'condition',
    'imageCount',
    'modelYear',
    'make',
    'model',
    'status',
    'spendLifetime',
    'lifetimeCap',
    'shoppersLifetime',
    'lotlinxVDPLifetime',
    'totalVDPLifetime',
    'costPerVDPLifetime',
    'lotlinxVdpPctLifetime',
    'conversionsLifetime',
    'leadFormSubmissionsLifetime',
    'clickToTextLifetime',
    'clickToCallLifetime',
    'clickToChatLifetime',
    'initialPrice',
    'priceChangeNumber',
    'avgPriceChange',
    'initialPriceToMarketPct',
    'movedDate',
    'otherPaidCampaignDays',
    'otherPaidVDPsLifetime',
    'lotlinxEngagementsLifetime',
    'otherPaidEngagementsLifetime',
    // 'dealDate',
    // 'dealNumber',
    // 'soldPrice',
    // 'frontendGross',
  ],
};

export const LiveROIView: InventoryTableConfig = {
  mainColumns: [
    'imageCell',
    'modelYear',
    'make',
    'model',
    'stockNumber',
    'condition',
    'dol',
    'lotlinxVDPLifetime',
    'totalLeadsLifetime',
    'currentPriceToMarketPct',
    'budget',
    'isBeingPublished',
  ],
  frozenColumns: [],
};

export const SoldROIView: InventoryTableConfig = {
  mainColumns: [
    'imageCell',
    'modelYear',
    'make',
    'model',
    'stockNumber',
    'condition',
    'dol',
    'lotlinxVDPLifetime',
    'otherPaidVDPsLifetime',
    'totalLeadsLifetime',
    'initialPriceToMarketPct',
  ],
  frozenColumns: [],
};
