import { useAtom } from 'jotai';
import SelectInput from 'Sparky/SelectInput';
import Skeleton from 'Sparky/Skeleton';

import { useFiltersContext } from 'components/Filters/FiltersContext';
import { DropdownFilterConfig, DropdownState } from 'components/Filters/types';
import { inventoryContractListAtom } from 'pages/Inventory/SoldInventory';
import { toTitleCase } from 'util/formatters';

import styles from './DropdownOptions.module.scss';

interface DropdownOptionsProps {
  config: DropdownFilterConfig<unknown>;
}

export default function DropdownOptions({ config }: DropdownOptionsProps) {
  const { isLoading, data, setFilterState, clearFilter, getFilterState } = useFiltersContext();
  const state = getFilterState(config.id) as DropdownState | undefined;
  const [inventoryContractList] = useAtom(inventoryContractListAtom);

  if (isLoading) {
    return (
      <div className={styles.selectOptions}>
        <div className={styles.option} style={{ height: '1.25em' }}>
          <Skeleton width='10em' height='1em' />
        </div>
      </div>
    );
  }

  const handleSelectionChange = (option: string) => {
    if (option === '') {
      clearFilter(config.id);
    } else {
      setFilterState(config.id, { options: [option] });
    }
  };

  return (
    <div className={styles.dropdownOptions}>
      <SelectInput
        maxHeight='360px'
        id={config.id}
        value={state?.options && state?.options.length > 0 ? `${state?.options[0]}` : ''}
        options={
          config.id === 'liveInvCampaign' || config.id === 'soldInvCampaign'
            ? getCampaignOptionList(inventoryContractList, config)
            : getOptionList(config, data ?? [])
        }
        defaultValue={''}
        onValueChange={handleSelectionChange}
        aria-label={config.ariaLabel}
        itemClassName={styles.dropdownInnerSelectInput}
        className={styles.dropdownSelectInput}
      />
    </div>
  );
}
function getCampaignOptionList(
  contractList: { name: string; id: number }[],
  config: DropdownFilterConfig<unknown>
) {
  let optionArray = contractList.map((contract) => {
    return {
      label: contract.name.length > 80 ? `${contract.name.substring(0, 80)}...` : contract.name,
      value: `${contract.id}`,
    };
  });
  optionArray.unshift({ label: config.defaultOptionName ?? 'All', value: '' });
  return optionArray;
}

// This function does not take into account if the getter returns an array
function getOptionList(config: DropdownFilterConfig<unknown>, data: unknown[]) {
  const optionSet = new Set<string>();
  data.forEach((i) => {
    const val = config.getter(i);
    if (val !== null && val !== undefined && !Array.isArray(val)) {
      optionSet.add(val);
    }
  });
  const optionArray: { label: string; value: string }[] = Array.from(optionSet)
    .sort()
    .map((item) => {
      return { label: toTitleCase(item as string), value: item as string };
    });

  optionArray.unshift({ label: config.defaultOptionName ?? 'All', value: '' });
  return optionArray;
}
