import classnames from 'classnames';
import Text from 'Sparky/Text';

import styles from './ImageCell.module.scss';

export interface ImageCellProps {
  primaryImage: string;
  imageCount?: number;
  className?: string;
}

/**
 * ImageCell
 */
export default function ImageCell({ primaryImage, imageCount, className }: ImageCellProps) {
  return (
    <div className={classnames([styles.imageCell, className])}>
      <img
        className={classnames([process.env.REACT_APP_DEPLOY_ENV === 'demo' && styles.blur])}
        src={primaryImage}
        alt=''
      />
      {imageCount !== undefined && (
        <div className={styles.countContainer}>
          <Text size='14' className={styles.imageCount}>
            {imageCount} {imageCount !== 1 ? 'Images' : 'Image'}
          </Text>
        </div>
      )}
    </div>
  );
}
