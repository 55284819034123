import { matchRoutes, RouteObject, useLocation } from 'react-router-dom';

// Inspired by https://gist.github.com/wontondon/e8c4bdf2888875e4c755712e99279536

/**
 * Gets the current [path pattern](https://reactrouter.com/docs/en/v6/getting-started/concepts#path-pattern)
 */
export default function usePathPattern(routes: RouteObject[]) {
  const location = useLocation();

  if (routes.length === 0) {
    return location.pathname;
  }

  const matches = matchRoutes(routes, location.pathname);
  if (matches && matches.length > 0) {
    return matches
      .filter((m) => !!m.route.path)
      .map((m) => `${m.route.path?.startsWith('/') ? '' : '/'}${m.route.path}`)
      .join('')
      .replace(/\/\//, '/'); // Replace instances of "//" with a single "/"
  }

  return location.pathname;
}
