import { useQueries, useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { User } from 'types/User';
import { assertIsDefined } from 'util/assert';

interface GetStatusRequest {
  user?: User;
  dealerId?: number;
}

export interface GetStatusResponse {
  dealerId: number;
  lastRuntime: number;
  lastUpdated: string;
  status: 'COMPLETE' | 'RUNNING' | 'ERROR' | 'PENDING' | 'UNKNOWN';
}

async function fetchCacheStatus({ user, dealerId }: GetStatusRequest) {
  assertIsDefined(user?.token);
  assertIsDefined(dealerId);

  if (!user || user?.accountType !== 'OWNER' || !(user.email.indexOf('@lotlinx.com') > -1)) {
    return {
      dealerId: dealerId,
      lastRuntime: 0,
      lastUpdated: '',
      status: 'COMPLETE',
    };
  }

  return await get<GetStatusResponse>(`${ENDPOINTS.getCacheStatus}/${dealerId}`, {
    headers: { token: user?.token },
  });
}

export function useGetInitialCacheStatus() {
  const [user] = useAtom(userAtom);
  const [dealerId] = useSelectedDealerId();

  return useQuery({
    queryKey: ['initial-cache-refresh', dealerId],
    refetchOnMount: false,
    queryFn: () =>
      fetchCacheStatus({
        user,
        dealerId,
      }),
  });
}

export default function useGetCacheStatus(cacheDealerList: number[]) {
  const [user] = useAtom(userAtom);
  const { cacheStatusKey } = useQueryKeys();

  return useQueries({
    // @ts-ignore
    queries: cacheDealerList.map((dealerId) => {
      return {
        queryKey: [cacheStatusKey, dealerId],
        queryFn: () =>
          fetchCacheStatus({
            user,
            dealerId,
          }),
        refetchOnMount: 'always',
        refetchInterval: cacheDealerList.length > 0 ? 10000 : false,
      };
    }),
  });
}
