import {
  IconBulb,
  IconCar,
  IconCurrencyDollar,
  IconMapSearch,
  IconReportSearch,
  IconSlideshow,
  IconSpeakerphone,
  IconTagStarred,
} from '@tabler/icons-react';
import Button from 'Sparky/Button';
import Flex from 'Sparky/Flex';

// import styles from './RecommendationCategoryButtonGroup.module.scss';

export interface RecommendationCategoryButtonGroupProps {
  hasHistory?: boolean;
  setRecommendationKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  suggestedPrompts: string[];
  isLoading?: boolean;
}

export type LexRecommendationCategories =
  | 'reports'
  | 'promotion'
  | 'pricing'
  | 'stocking'
  | 'sales'
  | 'marketing'
  | 'merchandising';

/**
 * RecommendationCategoryButtonGroup
 */
export default function RecommendationCategoryButtonGroup({
  hasHistory,
  setRecommendationKey,
  suggestedPrompts,
  isLoading,
}: RecommendationCategoryButtonGroupProps) {
  const BUTTON_CATEGORIES_DEFS = [
    {
      icon: <IconReportSearch size={16} />,
      key: 'reports',
      title: 'Reports',
    },
    {
      icon: <IconSpeakerphone size={16} />,
      key: 'marketing',
      title: 'Promotion',
    },
    {
      icon: <IconTagStarred size={16} />,
      key: 'pricing',
      title: 'Pricing',
    },
    {
      icon: <IconCar size={16} />,
      key: 'stocking',
      title: 'Stocking',
    },
    {
      icon: <IconCurrencyDollar size={16} />,
      key: 'sales',
      title: 'Sales',
    },
    {
      icon: <IconMapSearch size={16} />,
      key: 'market',
      title: 'Market',
    },
    {
      icon: <IconSlideshow size={16} />,
      key: 'merchandising',
      title: 'Merchandising',
    },
  ];

  return (
    <Flex margin={hasHistory ? '16px auto' : '48px auto'} column rowGap='12px' alignItems='center'>
      <Flex columnGap='8px'>
        {!!suggestedPrompts.length && (
          <Button
            key={'suggested'}
            variant='outline'
            size='sm'
            leftIcon={<IconBulb size={16} />}
            onClick={() => setRecommendationKey('suggested')}
            disabled={isLoading}
          >
            People also ask
          </Button>
        )}
        {BUTTON_CATEGORIES_DEFS.map((button) => {
          return (
            <Button
              key={button.key}
              variant='outline'
              size='sm'
              leftIcon={button.icon}
              onClick={() => setRecommendationKey(button.key)}
              disabled={isLoading}
            >
              {button.title}
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
}
