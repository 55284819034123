import { ReactNode } from 'react';

import classnames from 'classnames';
import Repeat from 'Sparky/Repeat';
import Skeleton from 'Sparky/Skeleton';
import Spacer from 'Sparky/Spacer';
import SwitchField from 'Sparky/SwitchField';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { checkItemMatch } from 'components/Filters/checkItemMatch';
import { useFiltersContext } from 'components/Filters/FiltersContext';
import { FiltersState, ToggleSelectFilterConfig, ToggleState } from 'components/Filters/types';

import styles from './ToggleOptions.module.scss';

export interface ToggleOptionsProps {
  config: ToggleSelectFilterConfig<unknown>;
  allowDisabled?: boolean;
  oppositeChecked?: boolean;
}

/**
 * ToggleOptions
 */
export default function ToggleOptions({
  config,
  allowDisabled = true,
  oppositeChecked = false,
}: ToggleOptionsProps) {
  const {
    data,
    getFilterState,
    setFilterState,
    clearFilter,
    isLoading,
    filtersState,
    filterConfigs,
    itemCountLabel,
    showItemCount,
  } = useFiltersContext();

  const state = getFilterState(config.id) as ToggleState | undefined;

  const otherFiltersState: FiltersState = { ...filtersState };
  delete otherFiltersState[config.id];
  const filteredData =
    data?.slice().filter((item) => checkItemMatch(item, filterConfigs, otherFiltersState)) ?? [];

  const handleChange = (optionKey: string | number, checked: boolean) => {
    let opts: (string | number)[] = [];
    if (state && state.options?.length) {
      opts = [...state.options];
    }

    if (checked) {
      if (oppositeChecked) {
        opts.push(optionKey);
      } else {
        opts = opts.filter((i) => i !== optionKey);
      }
    } else {
      if (oppositeChecked) {
        opts = opts.filter((i) => i !== optionKey);
      } else {
        opts.push(optionKey);
      }
    }

    if (!opts.length) {
      clearFilter(config.id);
    } else {
      setFilterState(config.id, { options: opts });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.toggleOptions}>
        <Repeat times={1}>
          <div className={styles.option} style={{ height: '1.25em' }}>
            <Skeleton width='10em' height='1em' />
            <Skeleton width='2em' height='1em' />
          </div>
        </Repeat>
      </div>
    );
  }

  let options: ReactNode[] = Object.entries(config.options).map(([key, opt]) => {
    const matchCount = filteredData.filter((item) => opt.matcher(item)).length;

    const checked = oppositeChecked
      ? !state?.options?.find((i) => i === key)
      : !!state?.options?.find((i) => i === key);

    return (
      <Tooltip
        key={key}
        content={config.toolTip}
        disabled={!!matchCount || !config.toolTip}
        className={styles.tooltip}
      >
        <div className={styles.toggleOption}>
          <SwitchField
            id={opt.label}
            disabled={!matchCount && allowDisabled}
            checked={checked}
            onCheckedChange={() => handleChange(key, checked)}
            label={opt.label}
          />
          <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
          {showItemCount && (
            <Text size='12' className={classnames([styles.itemCount, !matchCount && styles.muted])}>
              {matchCount} {itemCountLabel}
            </Text>
          )}
        </div>
      </Tooltip>
    );
  });

  if (!options.filter((i) => !!i).length) {
    return (
      <div className={styles.selectOptions}>
        <Text size='14'>No options available</Text>
      </div>
    );
  }

  return <div className={styles.selectOptions}>{options}</div>;
}
