import { z } from 'zod';

import { ProbabilityOfSaleKeys } from 'types/InventoryItem';
import { MarkdownHistory, PricingScenario, VinPathPriceData } from 'types/VinPath';

export const probabilityOfSaleKeysSchema: z.ZodSchema<ProbabilityOfSaleKeys> = z.union([
  z.literal('highlyUnlikely'),
  z.literal('unlikely'),
  z.literal('neutral'),
  z.literal('likely'),
  z.literal('highlyLikely'),
]);

export const pricingScenarioSchema: z.ZodSchema<PricingScenario> = z.object({
  probabilityOfSale: probabilityOfSaleKeysSchema.optional(),
  price: z.number().optional(),
  markdown: z.number(),
});

export const markdownHistorySchema: z.ZodSchema<MarkdownHistory> = z.object({
  priceChangeHistory: z.array(
    z.object({
      price: z.number().optional(),
      probabilityOfSale: probabilityOfSaleKeysSchema.optional(),
      markdown: z.number().optional(),
      markdownDate: z.string(),
      priceToMarketPct: z.number().optional(),
    })
  ),
  totalMarkdown: z.number(),
  avgMarkdown: z.number(),
});

export const vinPathPriceDataSchema: z.ZodSchema<VinPathPriceData> = z.object({
  pricingScenarios: z.array(pricingScenarioSchema),
  markdownHistory: markdownHistorySchema,
});
