import { useMutation } from '@tanstack/react-query';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import { userSchema } from 'pages/SignInPage/api/login/userSchema';
import { User } from 'types/User';

/**
 * Checks that a token is still valid, gets user data associated with it
 */
export async function restoreSession(token: string) {
  const res = await get<Omit<User, 'token'>>(ENDPOINTS.keepalive, { headers: { token } });
  return userSchema.parse({ ...res, token });
}

export default function useRestoreSession() {
  return useMutation((token: string) => restoreSession(token));
}
