import { ChangeEvent, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { IconArrowLeft } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import Button from 'Sparky/Button';
import Text from 'Sparky/Text';
import TextInputField from 'Sparky/TextInputField';

import { APIError, ErrorCode } from 'api/utils';
import { ResetInitSchema } from 'pages/SignInPage/api/useResetInitPwd/resetInitSchema';
import useResetInitPwd, {
  ResetInitPwdData,
} from 'pages/SignInPage/api/useResetInitPwd/useResetInitPwd';
import ContactUsLink from 'pages/SignInPage/ContactUsLink';

import styles from './ResetInitPwdForm.module.scss';

export interface ResetInitPwdProps {
  handleResetPassword(): void;
}

/**
 * ResetPassword
 */
export default function ResetInitPwdForm({ handleResetPassword }: ResetInitPwdProps) {
  const [message, setMessage] = useState<string>(
    'Enter your login email to receive a password reset link'
  );
  const [error, setError] = useState<string>();
  const mutation = useResetInitPwd();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<ResetInitPwdData>({ resolver: zodResolver(ResetInitSchema) });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Without the next line, if the input field is autofilled by the browser, react-hook-form does not know it
    setValue('email', event.target.value);
    clearErrors('email');
    setError('');
  };

  const onSubmit = (data: ResetInitPwdData) => {
    mutation.mutate(data, {
      onSuccess: () => setMessage('A reset email has been sent to your email box'),
      onError: (err) => {
        if (err instanceof APIError && err.subCode === ErrorCode.ACCESS_DENIED) {
          setError('Invalid email address, please try again');
        } else {
          setError('An unknown error occurred');
        }
      },
    });
  };

  return (
    <div className={styles.resetPwdFormWrapper}>
      <Button
        variant='solid'
        size='lg'
        leftIcon={<IconArrowLeft color='#FFF' />}
        onClick={handleResetPassword}
        className={styles.backwardButton}
      >
        Back to login
      </Button>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.resetPwdForm}>
        <div className={styles.formHeader}>
          <Text fontWeight={7} size='20' color='white'>
            Reset Password
          </Text>
          <Text
            alignment='center'
            fontWeight={4}
            size='14'
            color='white'
            className={styles.message}
          >
            {message}
          </Text>
        </div>
        <TextInputField
          id='email'
          {...register('email', { required: true })}
          variants={error || errors.email ? 'error' : 'default'}
          message={error ? error : errors.email?.message ?? ' '}
          label='Email'
          labelColor='white'
          onChange={onChange}
          className={styles.textInput}
          messageClassname={styles.errorMessage}
        />
        <Button
          type='submit'
          variant='solid'
          isLoading={mutation.isLoading}
          loadingText='Sending...'
          size='lg'
          className={styles.summitButton}
        >
          Get password reset link
        </Button>
      </form>
      <ContactUsLink />
    </div>
  );
}
