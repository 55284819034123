import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { post } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

interface saveDealerFavoritesProps {
  token: string | undefined;
  favorites: number[];
}

interface saveDealerFavoritesRequest {
  favorites: number[];
}

/**
 * saveDealerFavorites
 */

export async function saveDealerFavorites({ token, favorites }: saveDealerFavoritesProps) {
  assertIsDefined(token);
  assertIsDefined(favorites);

  const res = await post<saveDealerFavoritesRequest>(`${ENDPOINTS.saveDealerFavorites}`, {
    headers: { token },
    body: {
      favorites: favorites,
    },
  });

  return res;
}

export default function useSaveDealerFavorites() {
  const [user] = useAtom(userAtom);

  return useMutation((favorites: number[]) =>
    saveDealerFavorites({ token: user?.token, favorites })
  );
}
