import { useEffect, useRef, useState } from 'react';

import { IconEraser } from '@tabler/icons-react';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Button from 'Sparky/Button';
import { v4 as uuid } from 'uuid';

import useInquiry, { FollowUp } from 'components/Chat/api/inquiry/useInquiry';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { isRepsonseAnimationReadyAtom } from 'pages/LexChat/AnimatedResponse';
import ChatBody from 'pages/LexChat/ChatBody';
import ChatHeader from 'pages/LexChat/ChatHeader';
import ChatInput from 'pages/LexChat/ChatInput';
import { injectLexPromptAtom } from 'pages/LexChat/LexChat';
import NewFeatureNotification from 'pages/LexChat/NewFeatureNotification';
import RecommendationCategoryButtonGroup from 'pages/LexChat/RecommendationCategoryButtonGroup';

import styles from './Chat.module.scss';

export interface ChatProps {
  sessionId: string;
  lexChatTone: string;
}

export interface ChatHistory {
  key: string;
  id?: string;
  prompt?: string;
  response?: string;
}

export const historyAtom = atom<ChatHistory[]>([]);
export const suggestedPromptsAtom = atom<string[]>([]);
export const promptLoadingAtom = atom<boolean>(false);

/**
 * Chat
 */

export const showLexChatMessageAtom = atomWithStorage<boolean>('show-lex-chat-message', true);

export default function Chat({ sessionId, lexChatTone }: ChatProps) {
  const [dealerId] = useSelectedDealerId();
  const [, setIsResponseAnimationReady] = useAtom(isRepsonseAnimationReadyAtom);
  const [suggestedPrompts, setSuggestedPrompts] = useAtom(suggestedPromptsAtom);
  const [history, setHistory] = useAtom(historyAtom);
  const [isLoading, setIsLoading] = useAtom(promptLoadingAtom);
  const [recommendationKey, setRecommendationKey] = useState<string | undefined>(undefined);
  const [showLexChatMessage, setShowLexChatMessage] = useAtom(showLexChatMessageAtom);
  const [followUp, setFollowUp] = useState<FollowUp | undefined>(undefined);
  const [followUpUrl, setFollowUpUrl] = useState<FollowUp | undefined>(undefined);
  const [isRepsonseAnimationReady] = useAtom(isRepsonseAnimationReadyAtom);
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const scrollBodyRef = useRef<HTMLDivElement>(null);
  const mutation = useInquiry();
  const [injectedPrompt, setInjectedPrompt] = useAtom(injectLexPromptAtom);

  const scrollDown = () =>
    scrollBodyRef.current?.scrollTo({ top: Number.MAX_SAFE_INTEGER, behavior: 'smooth' });

  const handleClearChat = () => {
    setHistory([]);
    setSuggestedPrompts([]);
  };

  useEffect(() => {
    scrollDown();
  }, [history, isLoading, followUp, isRepsonseAnimationReady]);

  // Clear chat when dealer switches
  useEffect(() => {
    handleClearChat();
  }, [dealerId]);

  // Clear chat when dealer switches
  useEffect(() => {
    handleClearChat();
  }, [dealerId]);

  useEffect(() => {
    if (injectedPrompt && injectedPrompt.length > 0) {
      handlePromptSubmit(injectedPrompt);
      setInjectedPrompt('');
    }
  }, [injectedPrompt]);

  const handlePromptSubmit = (text: string) => {
    const key = uuid();

    setHistory((prev) => [...prev, { key: key, prompt: text }]);
    setIsLoading(true);
    setSuggestedPrompts([]);
    setFollowUp(undefined);
    setFollowUpUrl(undefined);

    mutation.mutate(
      { message: text, sessionId, tone: lexChatTone },
      {
        onSuccess: (data) => {
          setIsLoading(false);
          setSuggestedPrompts(data.suggested_prompts);
          setHistory((prev) => [
            ...prev,
            { key: key, response: data.response, id: data.response_id?.toString() },
          ]);
          if (data?.follow_ups && data?.follow_ups.length) {
            data?.follow_ups.forEach((followUp) => {
              if (followUp.type === 'prompt') {
                setFollowUp(followUp);
              }
              if (followUp.type === 'url') {
                setFollowUpUrl(followUp);
              }
            });
          }
          setIsResponseAnimationReady(true);
        },
        onError: () => {
          setIsLoading(false);
          console.log(key, 'Sorry, something went wrong');
        },
      }
    );
  };

  const hasHistory = history.length > 0;

  return (
    <>
      <div className={styles.chatContainer}>
        {hasHistory ? (
          <ChatBody
            history={history}
            isLoading={isLoading}
            chatBodyRef={chatBodyRef}
            scrollBodyRef={scrollBodyRef}
            followUp={followUp}
            followUpUrl={followUpUrl}
            setFollowUpUrl={setFollowUpUrl}
            handlePromptSubmit={handlePromptSubmit}
            setFollowUp={setFollowUp}
          />
        ) : (
          <ChatHeader />
        )}

        <ChatInput
          handlePromptSubmit={handlePromptSubmit}
          isLoading={isLoading}
          recommendationKey={recommendationKey}
          setRecommendationKey={setRecommendationKey}
          hasHistory={hasHistory}
          suggestedPrompts={suggestedPrompts}
        />

        <RecommendationCategoryButtonGroup
          hasHistory={hasHistory}
          setRecommendationKey={setRecommendationKey}
          suggestedPrompts={suggestedPrompts}
          isLoading={isLoading}
        />

        <NewFeatureNotification
          onClose={() => setShowLexChatMessage(false)}
          showNotification={!hasHistory && showLexChatMessage}
        />
      </div>

      {hasHistory && (
        <div className={styles.clearChat}>
          <Button
            contentClassName={styles.clearChatButton}
            disabled={isLoading}
            rightIcon={<IconEraser />}
            onClick={handleClearChat}
          >
            Clear chat
          </Button>
        </div>
      )}
    </>
  );
}
