export type InventoryTableGroupKey =
  | 'vehicle'
  | 'status'
  | 'budget'
  | 'engagement'
  | 'price'
  | 'nationalMarket'
  | 'localMarket'
  | 'sales'
  | 'leads';

/** Valid InventoryTableColumnDef keys */
export type InventoryTableColumnKey =
  | 'imageCell'
  | 'summary'
  | 'source'
  | 'actions'
  | 'totalLeadsByStatus'
  | InventoryTableWithValueColumnKey;

export type InventoryTableAddBudgetSidebarColumnKey = 'budget' | 'aiRecommendedBudgetIncrease';

export type InventoryTableWithValueColumnKey =
  | InventoryTableAddBudgetSidebarColumnKey
  | 'modelYear'
  | 'make'
  | 'movedDate'
  | 'model'
  | 'trim'
  | 'bodySegment'
  | 'stockNumber'
  | 'vin'
  | 'riskScore'
  | 'condition'
  | 'imageCount'
  | 'mileage'
  | 'dol'
  | 'exteriorColor'
  | 'status'
  | 'lotStatus'
  | 'isBeingPublished'
  | 'spend'
  | 'spendLifetime'
  | 'remainingBudget'
  | 'monthlyCap'
  | 'lifetimeCap'
  | 'shoppers'
  | 'shoppersLifetime'
  | 'lotlinxVDP'
  | 'lotlinxVDPLifetime'
  | 'totalVDP'
  | 'totalVDPLifetime'
  | 'costPerVDP'
  | 'totalVdpsSinceLastPriceChange'
  | 'costPerVDPLifetime'
  | 'lotlinxVdpPct'
  | 'lotlinxVdpPctLifetime'
  | 'totalConvSinceLastPriceChange'
  | 'conversions'
  | 'conversionsLifetime'
  | 'leadFormSubmissions'
  | 'leadFormSubmissionsLifetime'
  | 'clickToText'
  | 'clickToTextLifetime'
  | 'clickToChat'
  | 'clickToChatLifetime'
  | 'clickToCall'
  | 'clickToCallLifetime'
  | 'currentPrice'
  | 'lastPublishedPrice'
  | 'initialPrice'
  | 'totalPriceChange'
  | 'daysSinceLastPriceChange'
  | 'lastPriceChangeDate'
  | 'priceChangeNumber'
  | 'avgPriceChange'
  | 'currentPriceToMarketPct'
  | 'lastPriceToMarketPct'
  | 'initialPriceToMarketPct'
  | 'avgMarketPrice'
  | 'avgMarketPrice7DayPct'
  | 'avgMarketSoldPrice'
  | 'avgMarketSoldPrice7DayPct'
  | 'avgDTS'
  | 'avgDTS7DayPct'
  | 'avgBuyers'
  | 'avgBuyers7DayPct'
  | 'dolMarket'
  | 'dolMarket7DayPct'
  | 'similarVins'
  | 'similarVins7DayPct'
  | 'localAvgMarketPrice'
  | 'localAvgMarketPrice7DayPct'
  | 'localAvgMarketSoldPrice'
  | 'localAvgMarketSoldPrice7DayPct'
  | 'localAvgDTS'
  | 'localAvgDTS7DayPct'
  | 'localAvgBuyers'
  | 'localAvgBuyers7DayPct'
  | 'localDolMarket'
  | 'localDolMarket7DayPct'
  | 'localSimilarVins'
  | 'localSimilarVins7DayPct'
  | 'marketDaysSupply'
  /** Disabled until post-NADA **/
  // | 'dealerDaysSupply'
  | 'dealDate'
  | 'dealNumber'
  | 'soldPrice'
  | 'frontendGross'
  | 'lotlinxLeads'
  | 'lotlinxLeadsLifetime'
  | 'totalLeads'
  | 'totalLeadsLifetime'
  | 'avgAgeOfLeads'
  | 'otherPaidCampaignDays'
  | 'otherPaidVDPsLifetime'
  | 'lotlinxEngagementsLifetime'
  | 'otherPaidEngagementsLifetime'
  | 'totalEngagementsLifetime'
  | 'lotlinxPercentEngagementsLifetime'
  | 'otherPaidConversionsLifetime'
  | 'totalConversionsLifetime'
  | 'lotlinxPercentConversionsLifetime'
  | 'supply'
  | 'demand'
  | 'price'
  | 'localRolling7DayAverageActiveShoppers'
  | 'localRolling7DayPercentageActiveShoppers'
  | 'rolling30DayPercentageLocalShoppers'
  | 'rolling7DayTotalLocalShoppersActive'
  | 'rolling30DayTotalLocalShoppers'
  | 'rolling30DayAverageLocalShoppers'
  | 'demandTrend7dLocal'
  | 'demandTrendLocal';

export const InventoryTableWithValueColumnGroups: Record<
  InventoryTableGroupKey,
  InventoryTableWithValueColumnKey[]
> = {
  vehicle: [
    'mileage',
    'dol',
    'exteriorColor',
    'trim',
    'bodySegment',
    'stockNumber',
    'vin',
    'condition',
    'imageCount',
    'modelYear',
    'make',
    'model',
    'riskScore',
  ],
  status: ['status', 'lotStatus', 'isBeingPublished', 'movedDate'],
  budget: [
    'budget',
    'aiRecommendedBudgetIncrease',
    'spend',
    'remainingBudget',
    'spendLifetime',
    'lifetimeCap',
    'monthlyCap',
  ],
  engagement: [
    'shoppers',
    'shoppersLifetime',
    'lotlinxVDP',
    'lotlinxVDPLifetime',
    'totalVDP',
    'totalVdpsSinceLastPriceChange',
    'totalVDPLifetime',
    'costPerVDP',
    'costPerVDPLifetime',
    'lotlinxVdpPct',
    'lotlinxVdpPctLifetime',
    'totalConvSinceLastPriceChange',
    'conversions',
    'conversionsLifetime',
    'leadFormSubmissions',
    'leadFormSubmissionsLifetime',
    'clickToCall',
    'clickToCallLifetime',
    'clickToChat',
    'clickToChatLifetime',
    'clickToText',
    'clickToTextLifetime',
    // 'otherPaidCampaignDays',
    // 'otherPaidVDPsLifetime',
    // 'lotlinxEngagementsLifetime',
    // 'otherPaidEngagementsLifetime',
    // 'totalEngagementsLifetime',
    // 'lotlinxPercentEngagementsLifetime',
    // 'otherPaidConversionsLifetime',
    'totalConversionsLifetime',
    // 'lotlinxPercentConversionsLifetime',
  ],
  price: [
    'currentPrice',
    'lastPublishedPrice',
    'initialPrice',
    'totalPriceChange',
    'daysSinceLastPriceChange',
    'lastPriceChangeDate',
    'priceChangeNumber',
    'avgPriceChange',
    'currentPriceToMarketPct',
    'lastPriceToMarketPct',
    'initialPriceToMarketPct',
  ],
  nationalMarket: [
    'avgMarketPrice',
    'avgMarketPrice7DayPct',
    'avgMarketSoldPrice',
    'avgMarketSoldPrice7DayPct',
    'avgDTS',
    'avgDTS7DayPct',
    'avgBuyers',
    'avgBuyers7DayPct',
    'dolMarket',
    'dolMarket7DayPct',
    'similarVins',
    'similarVins7DayPct',
    'marketDaysSupply',
    /** Disabled until post-NADA **/
    // 'dealerDaysSupply',
    // 'supply',
    'demand',
  ],
  localMarket: [
    'localAvgMarketPrice',
    'localAvgMarketPrice7DayPct',
    'localAvgMarketSoldPrice',
    'localAvgMarketSoldPrice7DayPct',
    'localAvgDTS',
    'localAvgDTS7DayPct',
    'localAvgBuyers',
    'localAvgBuyers7DayPct',
    'localDolMarket',
    'localDolMarket7DayPct',
    'localSimilarVins',
    'localSimilarVins7DayPct',
    'localRolling7DayAverageActiveShoppers',
    'localRolling7DayPercentageActiveShoppers',
    'rolling30DayPercentageLocalShoppers',
    'rolling7DayTotalLocalShoppersActive',
    'rolling30DayTotalLocalShoppers',
    'rolling30DayAverageLocalShoppers',
    'demandTrend7dLocal',
    'demandTrendLocal',
  ],
  sales: ['dealDate', 'dealNumber', 'soldPrice', 'frontendGross'],
  leads: [
    'lotlinxLeads',
    'lotlinxLeadsLifetime',
    'totalLeads',
    'totalLeadsLifetime',
    'avgAgeOfLeads',
  ],
};

export const InventoryTableConfigGroups: Record<InventoryTableGroupKey, InventoryTableColumnKey[]> =
  {
    ...InventoryTableWithValueColumnGroups,
    vehicle: ['imageCell', 'summary', ...InventoryTableWithValueColumnGroups.vehicle],
  };

export const LiveInventoryTableConfigGroups: Record<
  InventoryTableGroupKey,
  InventoryTableColumnKey[]
> = {
  ...InventoryTableWithValueColumnGroups,
  vehicle: ['imageCell', 'summary', ...InventoryTableWithValueColumnGroups.vehicle],
  budget: [
    'budget',
    'aiRecommendedBudgetIncrease',
    'spend',
    'remainingBudget',
    'lifetimeCap',
    'monthlyCap',
  ],
  sales: [],
  leads: [...InventoryTableWithValueColumnGroups.leads, 'totalLeadsByStatus'],
};

export const SoldInventoryTableConfigGroups: Record<
  InventoryTableGroupKey,
  InventoryTableColumnKey[]
> = {
  vehicle: [
    'imageCell',
    'summary',
    'mileage',
    'dol',
    'exteriorColor',
    'trim',
    'bodySegment',
    'stockNumber',
    'vin',
    'condition',
    'imageCount',
    'modelYear',
    'make',
    'model',
  ],
  status: ['status', 'movedDate'],
  budget: ['spendLifetime', 'lifetimeCap', 'otherPaidCampaignDays'],
  price: [
    'lastPublishedPrice',
    'initialPrice',
    'priceChangeNumber',
    'avgPriceChange',
    'lastPriceToMarketPct',
    'initialPriceToMarketPct',
    'totalPriceChange',
  ],
  nationalMarket: [],
  localMarket: [],
  engagement: [
    'shoppersLifetime',
    'lotlinxVDP',
    'lotlinxVDPLifetime',
    'totalVDPLifetime',
    'costPerVDPLifetime',
    'lotlinxVdpPctLifetime',
    'conversionsLifetime',
    'leadFormSubmissionsLifetime',
    'clickToCallLifetime',
    'clickToChatLifetime',
    'clickToTextLifetime',
    'otherPaidVDPsLifetime',
    'lotlinxEngagementsLifetime',
    'otherPaidEngagementsLifetime',
    'totalEngagementsLifetime',
    'lotlinxPercentEngagementsLifetime',
    'otherPaidConversionsLifetime',
    'totalConversionsLifetime',
    'lotlinxPercentConversionsLifetime',
  ],
  sales: [
    // 'dealDate', 'dealNumber', 'soldPrice', 'frontendGross'
  ],
  leads: ['lotlinxLeadsLifetime', 'totalLeadsLifetime', 'totalLeadsByStatus'],
};
