import { IconArrowRight } from '@tabler/icons-react';
import Flex from 'Sparky/Flex';
import { COLOR } from 'Sparky/styles/vars';
import Text, { TextProps } from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { getPriceScenarioColor } from 'pages/Inventory/VinPath/utils/getPriceScenarioColor';
import { getProbabilityLabel } from 'pages/Inventory/VinPath/utils/getProbabilityLabel';
import { ProbabilityOfSaleKeys } from 'types/InventoryItem';
import { PricingScenario } from 'types/VinPath';
import { formatCurrency } from 'util/formatters';

import styles from './PriceScenarios.module.scss';

export interface PriceScenariosProps {
  priceScenarios?: PricingScenario[];
  currentProbabilityOfSale?: ProbabilityOfSaleKeys;
}

const defaultPriceScenarios: PricingScenario[] = [
  {
    probabilityOfSale: 'unlikely',
    price: undefined,
    markdown: undefined,
  },
  {
    probabilityOfSale: 'neutral',
    price: undefined,
    markdown: undefined,
  },
  {
    probabilityOfSale: 'likely',
    price: undefined,
    markdown: undefined,
  },
  {
    probabilityOfSale: 'highlyLikely',
    price: undefined,
    markdown: undefined,
  },
];

/**
 * PriceScenario
 */
export default function PriceScenarios({
  priceScenarios,
  currentProbabilityOfSale,
}: PriceScenariosProps) {
  let renderPriceScenarios: PricingScenario[];

  if (priceScenarios && priceScenarios.length > 0) {
    const priceScenariosLength = priceScenarios.length;
    const index = defaultPriceScenarios.findIndex(
      (priceScenario) =>
        priceScenario.probabilityOfSale ===
        priceScenarios[priceScenariosLength - 1].probabilityOfSale
    );
    renderPriceScenarios =
      index === priceScenariosLength - 1
        ? priceScenarios
        : priceScenarios.concat(
            defaultPriceScenarios.slice(index + 1, defaultPriceScenarios.length)
          );
  } else {
    const index = defaultPriceScenarios.findIndex(
      (priceScenario) => priceScenario.probabilityOfSale === currentProbabilityOfSale
    );
    renderPriceScenarios = defaultPriceScenarios.slice(index + 1, defaultPriceScenarios.length);
  }

  return (
    <div className={styles.priceScenarios}>
      {renderPriceScenarios?.map((priceScenario, index) => (
        <Flex key={index} width='100%' alignItems='center'>
          {index !== 0 && <IconArrowRight color={COLOR.SPARKY_GREY_300} className={styles.arrow} />}
          <Tooltip
            side='left'
            disabled={!(!priceScenario.price && !priceScenario.markdown)}
            content='No price (within a reasonable range) would move this vehicle into this probability bucket'
          >
            <Flex column rowGap='6px' alignItems='center' justifyContent='center' margin='0 auto'>
              <Text
                color={
                  getPriceScenarioColor(
                    priceScenario.probabilityOfSale,
                    priceScenario.price === undefined && priceScenario.markdown === undefined
                  ) as TextProps<'div'>['color']
                }
                style={
                  priceScenario.price === undefined && priceScenario.markdown === undefined
                    ? {
                        color: getPriceScenarioColor(priceScenario.probabilityOfSale, true),
                      }
                    : undefined
                }
                textWrap='nowrap'
              >
                {getProbabilityLabel(priceScenario.probabilityOfSale)}
              </Text>
              <Text size={20} fontWeight={4} color='secondary'>
                {priceScenario.price !== undefined ? formatCurrency(priceScenario.price) : '--'}
              </Text>
              <Text size={12} fontWeight={4} color='secondary'>
                {priceScenario.markdown !== undefined
                  ? formatCurrency(priceScenario.markdown * -1)
                  : 'N/A'}
              </Text>
            </Flex>
          </Tooltip>
        </Flex>
      ))}
    </div>
  );
}
