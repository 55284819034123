import { useRef } from 'react';

import Modal from 'Sparky/Modal';

import Chat from 'components/Chat/Chat';
import ChatHeader from 'components/Chat/components/ChatHeader';
import useShowChat from 'components/Chat/useShowChat';
import DealerSelect from 'components/DealerSelect/DealerSelect';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { FeatureFlag } from 'types/FeatureFlags';

import styles from './ChatModal.module.scss';

export default function ChatModal() {
  // History isn't preserved when the modal closes,
  //   so sessionID is generated on mount
  const sessionId = useRef(getSessionId());
  const [dealerId] = useSelectedDealerId();
  const [showChat, setShowChat] = useShowChat();
  const enable = useFeatureFlag(FeatureFlag.ENABLE_CHAT);

  return (
    <Modal
      isOpen={enable && showChat}
      onDismiss={() => setShowChat(false)}
      contentClassName={styles.chatModal}
      aria-label='Lotlinx Chat'
    >
      <ChatHeader onDismiss={() => setShowChat(false)} />
      {dealerId ? <Chat sessionId={sessionId.current} /> : <DealerSelect />}
    </Modal>
  );
}

export function getSessionId() {
  try {
    // This should be available, but will throw an error if it isn't
    return window.crypto.randomUUID();
  } catch (e) {
    return Date.now().toString(16);
  }
}
