import { useEffect } from 'react';

import { RouteObject } from 'react-router-dom';

import usePathPattern from 'hooks/usePathPattern';
import useReactGA from 'hooks/useReactGA';
import useSelectedDealerId from 'hooks/useSelectedDealerId';

/** Tracks location changes for analytics tools */
export default function useRouteTracker(routes: RouteObject[]) {
  const { addReactGA } = useReactGA();
  const pattern = usePathPattern(routes);
  const dealerId = useSelectedDealerId();

  useEffect(() => {
    addReactGA('page_view', undefined, { pattern: pattern });
  }, [pattern, dealerId]);
}
