import { COLOR } from 'Sparky/styles/vars';

import ChangeArrow from 'components/ChangeArrow/ChangeArrow';
import { formatPercent } from 'util/formatters';

import styles from './PercentChangeIndicator.module.scss';

interface PercentChangeIndicatorProps {
  val: number | undefined | null;
  positiveColor?: string;
  negativeColor?: string;
  neutralColor?: string;
  lowerIsBetter?: boolean;
  decimals?: number;
}

function PercentChangeIndicator({
  val,
  positiveColor = COLOR.SPARKY_GREEN_400,
  negativeColor = COLOR.SPARKY_RED_400,
  neutralColor = COLOR.NEUTRAL_6,
  lowerIsBetter = false,
  decimals = 2,
}: PercentChangeIndicatorProps) {
  if (typeof val !== 'number' || isNaN(val)) {
    return (
      <div className={styles.layout}>
        <ChangeArrow change={0} neutralColor={neutralColor} />
        <span>
          <>&#8203;</>
        </span>
      </div>
    );
  }

  let color;
  if (val > 0) {
    color = lowerIsBetter ? negativeColor : positiveColor;
  } else if (val < 0) {
    color = lowerIsBetter ? positiveColor : negativeColor;
  } else {
    color = neutralColor;
  }

  return (
    <div className={styles.layout}>
      <ChangeArrow
        change={val}
        positiveColor={lowerIsBetter ? negativeColor : positiveColor}
        negativeColor={lowerIsBetter ? positiveColor : negativeColor}
        neutralColor={neutralColor}
      />
      <span style={{ color: `${color}` }}>{formatPercent(Math.abs(val), decimals)}</span>
    </div>
  );
}

export default PercentChangeIndicator;
