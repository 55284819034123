import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { post } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import { TableItemFormat } from 'components/Chat/components/ChatTable/ChatTable';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { assert } from 'util/assert';

export interface InquiryRequest {
  message: string;
  sessionId: string;
  tone: string;
}

export interface ColumnsResponse {
  name: string;
  type: string;
  format: TableItemFormat;
}

export interface TableResponse {
  rows: Record<string, string | number>[];
  columns: Record<string, ColumnsResponse>;
}

export interface InquiryAnswers {
  type: 'TEXT' | 'TABLE';
  tag: 'EM' | 'TABLE' | '';
  value: string | TableResponse;
}

export interface FollowUp {
  type: 'prompt' | 'url';
  text: string;
  action: string;
}

export interface InquiryResponse {
  response_id?: number;
  request_id?: number;
  inquiry: string;
  response: string;
  suggested_prompts: string[];
  follow_up?: {
    inquiry: string;
    question: string;
  };
  follow_up_url?: {
    label: string;
    url_path: string;
  };
  follow_ups?: FollowUp[];
}

interface SubmitInquiryProps {
  token?: string;
  sessionId: string;
  message: string;
  dealerId?: number;
  userId?: number;
  tone?: string;
}

async function submitInquiry({
  token,
  sessionId,
  message,
  dealerId,
  userId,
  tone,
}: SubmitInquiryProps): Promise<InquiryResponse> {
  assert(token);
  assert(dealerId);
  assert(userId);

  return post<InquiryResponse>(ENDPOINTS.inquiry, {
    headers: { token },
    params: {
      version: 'v1',
    },
    body: {
      inquiry: message,
      dealer_id: dealerId,
      session_id: sessionId,
      user_id: userId,
      source: 'vm_chat_modal',
      tone: tone,
    },
  });
}

export default function useInquiry() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);

  return useMutation(({ message, sessionId, tone }: InquiryRequest) =>
    submitInquiry({ token: user?.token, message, dealerId, userId: user?.id, sessionId, tone })
  );
}
