type WindowWithDataLayer = Window & {
  gtag: Function;
};

declare const window: WindowWithDataLayer;

type TrackerProps = {
  eventName: string;
  data: { [k: string]: string | number | undefined };
};

// this should be generalized to be used in any component
export const tracker = ({ eventName, data }: TrackerProps) => {
  window.gtag('event', eventName, data);
};
