import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
// import { Outlet } from 'react-router-dom';

import { useInventoryMTD, useInventoryPrevMonth } from 'api/useInventory/useInventory';
import Page from 'components/Page/Page';
import InventoryPage from 'pages/Inventory/InventoryPage';
import PageHeader from 'pages/Inventory/InventoryPage/PageHeader';

import styles from './LiveInventory.module.scss';

export const showInventoryHighlightsAtom = atomWithStorage<boolean>(
  'show-inventory-highlights',
  true
);
/**
 * LiveInventory
 */
export default function LiveInventory() {
  const [showInventoryHighlights, setShowInventoryHighlights] = useAtom(
    showInventoryHighlightsAtom
  );

  // Fetch Inventory Data from API
  let inventoryResponseMTD = useInventoryMTD();
  let inventoryResponsePrevMonth = useInventoryPrevMonth(
    dayjs().date() === 1 && process.env.REACT_APP_DEPLOY_ENV === 'demo'
  );

  const response =
    inventoryResponsePrevMonth !== undefined ? inventoryResponsePrevMonth : inventoryResponseMTD;

  const isLoading =
    (inventoryResponsePrevMonth !== undefined && inventoryResponsePrevMonth?.isLoading) ||
    inventoryResponseMTD?.isLoading;

  return (
    <div className={styles.liveInventory}>
      <PageHeader
        showInventoryHighlights={showInventoryHighlights}
        setShowInventoryHighlights={setShowInventoryHighlights}
        inventoryPageKey='live'
      />
      <InventoryPage
        inventoryData={response.data?.inventory.filter(
          (i) => i.status.status !== 'SOLD' && i.status.status !== 'MOVED'
        )}
        isInventoryLoading={isLoading}
        isInventoryError={inventoryResponseMTD.error}
        inventoryPageKey='live'
        contracts={response.data?.contracts}
      />
      {/* Outlet for the VINDetailModal */}
      {/*<Outlet />*/}
    </div>
  );
}

export function Component() {
  return (
    <Page requireDealer title='Live inventory'>
      <LiveInventory />
    </Page>
  );
}
Component.displayName = 'LiveInventoryPage';
