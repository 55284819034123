import React from 'react';

import * as Select from '@radix-ui/react-select';
import { SelectProps } from '@radix-ui/react-select';
import { IconCaretDownFilled, IconChevronDown } from '@tabler/icons-react';
import classnames from 'classnames';
import Flex from 'Sparky/Flex';
import Skeleton from 'Sparky/Skeleton';
import { COLOR, SPACING } from 'Sparky/styles/vars';

import styles from './BlankDropdown.module.scss';

export interface SparkyBlankDropdownProps extends SelectProps {
  id: string;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  position?: 'item-aligned' | 'popper';
  container?: HTMLElement | null | undefined;
  variant?: 'button' | 'text';
  disabled?: boolean;
  size?: 'sm' | 'md';
  isLoading?: boolean;
  className?: string;
  contentStyles?: string;
  labelClassName?: string;
  valueContent: React.ReactElement;
}

export default function BlankDropdown({
  id,
  align,
  side = 'bottom',
  sideOffset = 2,
  position = 'popper',
  disabled,
  container,
  size,
  variant = 'button',
  isLoading,
  className,
  labelClassName,
  contentStyles,
  valueContent,
  children,
  ...props
}: SparkyBlankDropdownProps) {
  if (isLoading) {
    return (
      <Skeleton height={SPACING.SPACING_16} width={SPACING.SPACING_48} className={className} />
    );
  }
  return (
    <div className={styles.blankDropdown}>
      <Select.Root {...props}>
        <Select.Trigger
          onKeyDown={(e) => {
            if (e.key === 'Esc') {
              e.preventDefault();
            }
          }}
          id={id}
          className={classnames(
            {
              [styles.selectInput]: variant === 'button',
              [styles.disabled]: disabled,
              [styles.sm]: size === 'sm',
              [styles.placeholder]: props.value === undefined,
            },
            className
          )}
          disabled={disabled}
        >
          {variant === 'button' && (
            <Flex flex='1' column={false} justifyContent='space-between' role='button'>
              <Select.Value>{valueContent}</Select.Value>

              <Flex column={false} columnGap='4px'>
                <Select.Icon className={styles.selectInputDivider}>|</Select.Icon>

                <Select.Icon className={styles.selectInputIcon}>
                  <IconChevronDown size='16' color={COLOR.TEXT_SECONDARY} />
                </Select.Icon>
              </Flex>
            </Flex>
          )}
          {variant === 'text' && (
            <Flex
              flex='1'
              column={false}
              justifyContent='space-between'
              alignItems='center'
              columnGap='2px'
              role='button'
            >
              <Select.Value>{valueContent}</Select.Value>
              <Select.Icon className={styles.selectIcon}>
                <IconCaretDownFilled size='12' />
              </Select.Icon>
            </Flex>
          )}
        </Select.Trigger>

        <Select.Portal container={container}>
          <Select.Content
            position={position}
            side={side}
            sideOffset={sideOffset}
            align={align}
            className={classnames([styles.selectInputContent, contentStyles])}
          >
            <Select.Viewport>{children}</Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
}
