import classnames from 'classnames';
import dayjs from 'dayjs';
import {
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  ComposedChart,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';
import Legend from 'Sparky/LineChart/Legend';
import NoData from 'Sparky/LineChart/NoData';
import CustomToolTip from 'Sparky/LineChart/ToolTip';
import { FONT_SIZE } from 'Sparky/styles/vars';

import { NumberFormat } from 'types/NumberFormat';
import { formatNumberFormat } from 'util/formatNumberFormat';

import styles from './LineChart.module.scss';

interface DataProps {
  date: string;
  [key: string]: string | number | null;
}

interface LineChartProps {
  // Chart Options
  showTooltips?: boolean;
  showLegend?: boolean;
  percentDecimals?: number;
  allowDecimalsOnYAxis?: boolean;
  // Chart Data
  format: NumberFormat;
  metaData: {
    [key: string]: {
      label: string;
      color: string;
      strokeDasharray?: string;
    };
  };
  data: DataProps[];
  customYDomain?: AxisDomain | undefined;
  yAxisMargin?: number;
}

interface ChartData extends DataProps {
  unixDate: number;
}

export default function LineChart({
  format,
  metaData,
  data,
  showTooltips = true,
  showLegend = true,
  percentDecimals = 0,
  allowDecimalsOnYAxis = true,
  customYDomain,
  yAxisMargin = 0,
}: LineChartProps) {
  // Add a `date` property to the data, so we can use a timescale x-axis
  const chartData: ChartData[] = data.map((i) => ({
    ...i,
    unixDate: dayjs(i.date).unix(),
  }));

  // Guarantee sorted by Date
  const sortedData = chartData.sort((a, b) => a.unixDate - b.unixDate);

  // Y tick formatter
  const formatter = (val: number | string) => {
    if (typeof val === 'number') {
      return formatNumberFormat(val, format ?? NumberFormat.INT, {
        percentDecimals: percentDecimals,
        currencyShowDecimal: false,
      });
    }
    return '-';
  };

  const filteredMetaData: {
    [key: string]: {
      label: string;
      color: string;
    };
  } = {};

  Object.keys(metaData).forEach((key) => {
    const hasKey = data.filter((val) => {
      return val[key] !== null || val[key] === undefined;
    });
    if (hasKey.length && key in data[0]) {
      filteredMetaData[key] = metaData[key];
    }
  });

  if (!Object.keys(filteredMetaData).length) {
    return <NoData />;
  }

  return (
    <div className={styles.chartContainer}>
      {/* 
        This container setup with a relative and absolute container is required
        for the resizing behaviour to work with recharts framework.
        Known bug with package.    
      */}
      <div className={classnames([styles.relativeContainer, showLegend && styles.hasLegend])}>
        <div className={styles.absoluteContainer}>
          <ResponsiveContainer
            width='100%'
            height='100%'
            className={classnames(['chromatic-ignore', styles.chartWrapper])}
          >
            <ComposedChart
              data={sortedData}
              margin={{ bottom: 10, left: yAxisMargin }}
              style={{ backgroundColor: '#FFFFFF' }}
            >
              {/* Month End vertical line */}
              {/* {chartData.map((i) => {
                const daysInMonth = dayjs(i.date).daysInMonth();
                const currentDay = dayjs(i.date).date();
                return (
                  daysInMonth === currentDay && (
                    <ReferenceLine key={i.date} x={i.unixDate} stroke='#E9E9E9' strokeWidth={1} />
                  )
                );
              })} */}
              <CartesianGrid vertical={false} stroke='#F3F3F3' />

              {Object.keys(filteredMetaData).map((key) => {
                return (
                  <Line
                    key={key}
                    type='bump'
                    dataKey={key}
                    isAnimationActive={false}
                    stroke={metaData[key].color}
                    strokeWidth={2}
                    dot={false}
                    strokeDasharray={metaData[key].strokeDasharray}
                  />
                );
              })}

              <XAxis
                scale='time'
                type='number'
                domain={['dataMin', 'dataMax']}
                dataKey='unixDate'
                tick={customizedGroupTick}
                axisLine={{ strokeWidth: 0 }}
                ticks={buildTicklist(sortedData)}
                dy={8}
                tickFormatter={(unixTime) => dayjs.unix(unixTime).format('DD')}
                tickLine={false}
              />

              <YAxis
                tick={{ fontSize: FONT_SIZE.FONT_12 }}
                tickLine={{ stroke: 'transparent' }}
                tickFormatter={formatter}
                orientation='left'
                domain={
                  customYDomain
                    ? customYDomain
                    : ['auto', (dataMax: number) => dataMax + dataMax * 0.02]
                }
                axisLine={{ strokeWidth: 0 }}
                allowDecimals={allowDecimalsOnYAxis}
              />

              {showTooltips && (
                <Tooltip
                  content={
                    <CustomToolTip
                      data={data}
                      metaData={metaData}
                      format={format}
                      percentDecimals={percentDecimals}
                    />
                  }
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      {showLegend && <Legend metaData={filteredMetaData} />}
    </div>
  );
}

/** Builds tick list with a mid monthly tick to display the Month in the x-axis */
export function buildMonthlyTicklist(
  data: {
    date: string;
    unixDate: number;
    [key: string]: string | number | null;
  }[]
) {
  const tickList: number[] = [];

  data.forEach((i) => {
    const dayOfMonth = dayjs(i.date).format('DD');

    if (dayOfMonth === '15') {
      tickList.push(i.unixDate);
    }
  });

  return tickList;
}

/** Builds tick list with a mid monthly tick to display the Month in the x-axis */
export function buildTicklist(
  chartData: {
    date: string;
    unixDate: number;
  }[]
) {
  const tickList: number[] = [];

  if (chartData.length < 10) {
    chartData.forEach((date) => {
      tickList.push(date.unixDate);
    });
  }
  if (chartData.length > 10) {
    chartData.forEach((date, i) => {
      if (!(i % 7)) {
        tickList.push(date.unixDate);
      }
    });
  }

  // chartData.forEach((date) => {
  //   tickList.push(date.unixDate);
  // });
  // chartData.forEach((date) => {
  //   const dayOfMonth = dayjs.unix(date.date).format('DD');
  //   if (dayOfMonth === '15') {
  //     tickList.push(date.date);
  //   }
  // });
  return tickList;
}

export function customizedGroupTick(props: any) {
  const { x, y, payload, index } = props;
  const showMonth = index === 0 || parseInt(dayjs.unix(props.payload.value).format('DD')) < 7;
  return (
    <g>
      <text x={x} y={y + 8} fill='#5F5F5F' fontSize={12} strokeWidth={0}>
        {dayjs.unix(payload.value).format('DD')}
      </text>
      {showMonth && (
        <text x={x} y={y + 20} fill='#5F5F5F' fontSize={12} strokeWidth={0}>
          {dayjs.unix(payload.value).format('MMM')}
        </text>
      )}
    </g>
  );
}
