import React from 'react';

import Text from 'Sparky/Text';

import { AddBudgetData } from 'pages/Inventory/InventoryPage/AddBudgetSidebar';
import VinDetails from 'pages/Inventory/InventoryPage/AddBudgetSidebar/components/VinDetails';
import { InventoryItem } from 'types/InventoryItem';
import { formatCurrency } from 'util/formatters';

import styles from './AddBudgetConfirmTable.module.scss';

export type AddBudgetTableColumnKey =
  | 'imageCell'
  | 'vinDetails'
  | 'currentBudget'
  | 'increasedBudget'
  | 'newBudget';

interface AddBudgetTableColumn {
  key: AddBudgetTableColumnKey;
  header: string;
  width?: string;
  textAlign?: 'left' | 'right' | 'center';
  className?: string;

  cellRenderer(r: InventoryItem): React.ReactNode;
}

export const addBudgetConfirmTableColumnOrder: AddBudgetTableColumnKey[] = [
  'imageCell',
  'vinDetails',
  'currentBudget',
  'increasedBudget',
  'newBudget',
];

export const addBudgetConfirmTableColumnDefs = (
  totalVinsLength: number,
  budgets: AddBudgetData
): Record<AddBudgetTableColumnKey, AddBudgetTableColumn> => ({
  imageCell: {
    key: 'imageCell',
    header: `${totalVinsLength} VIN(s)`,
    width: '7rem',
    textAlign: 'left',
    className: styles.imgCell,
    cellRenderer: (r: InventoryItem) => (
      <img src={r.listing.primaryImage} alt='' className={styles.image} />
    ),
  },
  vinDetails: {
    key: 'vinDetails',
    header: '',
    cellRenderer: (r: InventoryItem) => (
      <VinDetails
        modelYear={r.vehicle.modelYear}
        make={r.vehicle.make}
        model={r.vehicle.model}
        trim={r.vehicle.trim}
        vin={r.listing.vin}
        stockNumber={r.listing.stockNumber}
        exteriorColor={r.listing.exteriorColor}
      />
    ),
  },
  currentBudget: {
    key: 'currentBudget',
    header: 'Current',
    textAlign: 'right',
    className: styles.tableCell,
    cellRenderer: (r: InventoryItem) => formatCurrency(r.budgetMetrics.budget),
  },
  increasedBudget: {
    key: 'increasedBudget',
    header: 'Increase',
    textAlign: 'right',
    className: styles.tableCell,
    cellRenderer: (r: InventoryItem) => `+${formatCurrency(Number(budgets[r.listing.vin] ?? 0))}`,
  },
  newBudget: {
    key: 'newBudget',
    header: 'New budget',
    textAlign: 'right',
    className: styles.tableCell,
    cellRenderer: (r: InventoryItem) => (
      <Text fontWeight={7}>
        {formatCurrency(r.budgetMetrics.budget + Number(budgets[r.listing.vin] ?? 0))}
      </Text>
    ),
  },
});
