import classnames from 'classnames';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import styles from './ChartLegend.module.scss';

interface ColorPalette {
  rangeColor: string;
  borderColor: string;
}

export interface LegendList extends ColorPalette {
  key: string;
  name: string;
  type: 'rect' | 'dotLine' | 'circle';
}

export interface ChartLegendProps {
  legend: LegendList[];
}

/**
 * ChartLegend
 */
export default function ChartLegend({ legend }: ChartLegendProps) {
  return (
    <div className={styles.chartLegend}>
      {legend?.map((item) => {
        return (
          <div key={item.name} className={styles.legend}>
            <LegendIcon item={item} />
            <Text size={12} fontWeight={4} color='secondary'>
              {item.name}
            </Text>
          </div>
        );
      })}
    </div>
  );
}

export function LegendIcon({ item }: { item: LegendList }) {
  return (
    <div
      className={classnames({
        [styles.circle]: item.type === 'circle',
        [styles.rect]: item.type === 'rect',
        [styles.dotLine]: item.type === 'dotLine',
      })}
      style={{
        backgroundColor: item.type !== 'dotLine' ? item.rangeColor : undefined,
        borderColor:
          item.type === 'circle' || item.type === 'dotLine' ? item.borderColor : undefined,
      }}
    />
  );
}

type ColorKey =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'blue'
  | 'purple'
  | 'turqouise'
  | 'pink'
  | 'navy'
  | 'grey'
  | 'darkRed'
  | 'darkOrange'
  | 'darkYellow'
  | 'darkGreen'
  | 'darkTeal'
  | 'darkBlue'
  | 'darkPurple'
  | 'darkTurqouise'
  | 'darkPink'
  | 'darkNavy'
  | 'darkGrey';

export const colorPalette: Record<ColorKey, ColorPalette> = {
  purple: {
    rangeColor: COLOR.SPARKY_PURPLE_400,
    borderColor: COLOR.SPARKY_PURPLE_200,
  },
  turqouise: {
    rangeColor: '#10A0A2',
    borderColor: '#B7E2E3',
  },
  red: {
    rangeColor: COLOR.SPARKY_RED_400,
    borderColor: COLOR.SPARKY_RED_200,
  },
  orange: {
    rangeColor: COLOR.SPARKY_ORANGE_400,
    borderColor: COLOR.SPARKY_ORANGE_200,
  },
  yellow: {
    rangeColor: COLOR.SPARKY_YELLOW_400,
    borderColor: COLOR.SPARKY_YELLOW_200,
  },
  green: {
    rangeColor: COLOR.SPARKY_GREEN_400,
    borderColor: COLOR.SPARKY_GREEN_200,
  },
  teal: {
    rangeColor: COLOR.SPARKY_TEAL_400,
    borderColor: COLOR.SPARKY_TEAL_200,
  },
  blue: {
    rangeColor: COLOR.SPARKY_BLUE_400,
    borderColor: COLOR.SPARKY_BLUE_200,
  },
  pink: {
    rangeColor: COLOR.SPARKY_PINK_400,
    borderColor: COLOR.SPARKY_PINK_200,
  },
  navy: {
    rangeColor: COLOR.SPARKY_NAVY_400,
    borderColor: COLOR.SPARKY_NAVY_200,
  },
  grey: {
    rangeColor: COLOR.SPARKY_GREY_400,
    borderColor: COLOR.SPARKY_GREY_200,
  },
  darkPurple: {
    rangeColor: COLOR.SPARKY_PURPLE_700,
    borderColor: COLOR.SPARKY_PURPLE_400,
  },
  darkTurqouise: {
    rangeColor: '#095859',
    borderColor: '#87CFD1',
  },
  darkRed: {
    rangeColor: COLOR.SPARKY_RED_600,
    borderColor: COLOR.SPARKY_RED_400,
  },
  darkOrange: {
    rangeColor: COLOR.SPARKY_ORANGE_600,
    borderColor: COLOR.SPARKY_ORANGE_400,
  },
  darkYellow: {
    rangeColor: COLOR.SPARKY_YELLOW_600,
    borderColor: COLOR.SPARKY_YELLOW_400,
  },
  darkGreen: {
    rangeColor: COLOR.SPARKY_GREEN_600,
    borderColor: COLOR.SPARKY_GREEN_400,
  },
  darkTeal: {
    rangeColor: COLOR.SPARKY_TEAL_600,
    borderColor: COLOR.SPARKY_TEAL_400,
  },
  darkBlue: {
    rangeColor: COLOR.SPARKY_BLUE_600,
    borderColor: COLOR.SPARKY_BLUE_400,
  },
  darkPink: {
    rangeColor: COLOR.SPARKY_PINK_600,
    borderColor: COLOR.SPARKY_PINK_400,
  },
  darkNavy: {
    rangeColor: COLOR.SPARKY_NAVY_600,
    borderColor: COLOR.SPARKY_NAVY_400,
  },
  darkGrey: {
    rangeColor: COLOR.SPARKY_GREY_600,
    borderColor: COLOR.SPARKY_GREY_400,
  },
};
