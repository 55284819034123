import { useState } from 'react';

import classnames from 'classnames';
import dayjs from 'dayjs';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COLOR } from 'Sparky/styles/vars';

import { Lead } from 'pages/Inventory/VinDetailSidebar/api/useLeads/useLeads';
import ChartLegend, {
  colorPalette,
  LegendList,
} from 'pages/Inventory/VinDetailSidebar/components/ChartLegend';
import ChartTooltip from 'pages/Inventory/VinDetailSidebar/components/ChartTooltip';
import { formatCurrency, formatNumber } from 'util/formatters';

const priceLegend: LegendList = {
  key: 'price',
  name: 'Price',
  type: 'rect',
  rangeColor: colorPalette.purple.rangeColor,
  borderColor: colorPalette.purple.borderColor,
};

const marketPriceLegend: LegendList = {
  key: 'marketPrice',
  name: 'Market price',
  type: 'rect',
  rangeColor: colorPalette.turqouise.rangeColor,
  borderColor: colorPalette.turqouise.borderColor,
};

export interface LeadsPriceChartProps {
  legendList?: string[];
  data: Lead[];
  className?: string;
}

/**
 * LeadsPriceChart
 */
export default function LeadsPriceChart({ legendList, data, className }: LeadsPriceChartProps) {
  const [highlightedBar, setHighlightedBar] = useState<string>();

  let leadsColorPalette = Object.values(colorPalette).slice(2);

  const chartData = data.slice().sort((a, b) => dayjs(a.date).diff(dayjs(b.date), 'days'));

  const barLegend: LegendList[] = legendList
    ? legendList.map((legend, index) => {
        return {
          key: legend,
          name: legend,
          type: 'circle',
          borderColor: Object.values(leadsColorPalette)[index].borderColor,
          rangeColor: Object.values(leadsColorPalette)[index].rangeColor,
        };
      })
    : [];

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
      className={classnames(['chromatic-ignore', className])}
    >
      <ComposedChart data={chartData}>
        <CartesianGrid vertical={false} stroke={COLOR.SPARKY_GREY_200} />
        <XAxis
          dataKey='date'
          tickFormatter={(val) => dayjs(val).format('MMM D')}
          axisLine={false}
          tickLine={false}
          tick={{
            fill: COLOR.SPARKY_GREY_500,
            fontSize: 12,
            textAnchor: chartData.length > 8 ? 'end' : 'middle',
            dx: -4,
          }}
          angle={chartData.length > 8 ? -50 : 0}
        />
        <YAxis
          orientation='left'
          yAxisId='Price'
          domain={['auto', 'auto']}
          stroke={COLOR.SPARKY_GREY_200}
          tick={{ fill: COLOR.SPARKY_GREY_500, fontSize: 12 }}
          tickFormatter={(val) => formatCurrency(val)}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          orientation='right'
          yAxisId='Views'
          stroke={COLOR.SPARKY_GREY_200}
          tick={{ fill: COLOR.SPARKY_GREY_500, fontSize: 12 }}
          tickFormatter={(val) => formatNumber(val)}
          allowDecimals={false}
          tickLine={false}
          axisLine={false}
        />
        {barLegend.map((legend) => (
          <Bar
            key={legend.key}
            name={legend.name}
            dataKey={legend.key}
            stackId='stack'
            yAxisId='Views'
            isAnimationActive={false}
          >
            {chartData.map((item) => (
              <Cell
                onMouseEnter={() => setHighlightedBar(item.date)}
                onMouseLeave={() => setHighlightedBar(undefined)}
                key={item.date}
                fill={highlightedBar === item.date ? legend.rangeColor : legend.borderColor}
              />
            ))}
          </Bar>
        ))}
        <Line
          name={priceLegend.name}
          dataKey={priceLegend.key}
          yAxisId='Price'
          type='monotone'
          dot={false}
          strokeWidth='2'
          stroke={priceLegend.rangeColor}
          color={priceLegend.borderColor}
          isAnimationActive={false}
          connectNulls
        />
        <Line
          name={marketPriceLegend.name}
          dataKey={marketPriceLegend.key}
          yAxisId='Price'
          type='monotone'
          dot={false}
          strokeWidth='2'
          stroke={marketPriceLegend.rangeColor}
          color={marketPriceLegend.borderColor}
          isAnimationActive={false}
          connectNulls
        />
        <Tooltip
          content={
            <ChartTooltip
              getData={(date) => data?.find((i) => i.date === date)}
              legend={[priceLegend, marketPriceLegend, ...barLegend]}
            />
          }
        />
        <Legend
          content={<ChartLegend legend={[priceLegend, marketPriceLegend, ...barLegend]} />}
          wrapperStyle={{ padding: '0 16px', bottom: '0' }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
