import { z } from 'zod';

import { ResetCompleteData } from './useResetComplete';

export const ResetCompleteSchema: z.ZodSchema<ResetCompleteData> = z.object({
  email: z.string().min(1, 'Required').email('Please enter a valid email'),
  resetPwKey: z.string().nonempty({
    message: 'Required',
  }),
});
