import React from 'react';

import useSelectedDealer from 'hooks/useSelectedDealer';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import DealerSelectPage from 'pages/DealerSelectPage/DealerSelectPage';

interface DealerProviderProps {
  children: React.ReactNode;
}

/** Ensures that a valid dealer is selected */
export default function CheckDealer({ children }: DealerProviderProps) {
  const [dealerId] = useSelectedDealerId();
  const { dealer, isLoading } = useSelectedDealer();

  if (!dealerId || (!isLoading && !dealer)) {
    // `!dealerId` – Check that a dealerId is present in the URL search params
    // `(!isLoading && !dealer)` – Check that a valid dealer is selected, but only show DealerSelectPage if dealer list is loaded (`!isLoading`)
    return <DealerSelectPage />;
  }

  return <>{children}</>;
}
