import React from 'react';

import styles from './Skeleton.module.scss';

export interface SkeletonProps {
  /** Render as a circe (or oval). If no height is provided, automatically sets `height` equal to `width` */
  circle?: boolean;
  className?: string;
  width?: string;
  height?: string;
  radius?: string;
  style?: React.CSSProperties;
}

/**
 * Skeleton component
 */
export default function Skeleton({
  width,
  height,
  radius = 'var(--border-radius)',
  circle = false,
  style,
  className,
}: SkeletonProps) {
  return (
    <div
      className={[styles.skeleton, className].join(' ')}
      style={{
        width: width,
        height: circle && !height ? width : height,
        borderRadius: circle ? '50%' : radius,
        ...style,
      }}
    >
      <wbr />
    </div>
  );
}
