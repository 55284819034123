import React from 'react';

import { TEXT_PRESET_DEF, TextColors, TextType } from 'Sparky/Text/TextType.defs';

export interface TextProps<C extends React.ElementType> {
  type?: TextType;
  as?: C;
  children?: React.ReactNode;
  size?: (typeof sizes)[number];
  lineHeight?: string;
  fontWeight?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  color?: TextColors;
  alignment?: React.CSSProperties['textAlign'];
  textWrap?: string;
  className?: string;
  underline?: boolean;
}

type GenericTextProps<C extends React.ElementType> = TextProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof TextProps<C>>;

const sizes = [
  8,
  '8',
  '10',
  10,
  '12',
  12,
  '14',
  14,
  '16',
  16,
  '18',
  18,
  '20',
  20,
  '24',
  24,
  '32',
  32,
  '40',
  40,
  '48',
  48,
  '56',
  56,
] as const;

/**
 * Text
 */
export default function Text<C extends React.ElementType = 'span'>({
  type,
  as,
  children,
  size,
  color,
  fontWeight,
  alignment,
  textWrap,
  style,
  className,
  lineHeight,
  underline,
  ...otherProps
}: GenericTextProps<C>) {
  const Component = as || 'span';

  const styles: React.CSSProperties = type
    ? {
        color: color ? `var(--sparky-text-${color})` : undefined,
        fontSize: size ? `var(--font-${size})` : TEXT_PRESET_DEF[type].size,
        textAlign: alignment,
        fontWeight: fontWeight ? fontWeight * 100 : TEXT_PRESET_DEF[type].fontWeight,
        lineHeight: lineHeight ? `${lineHeight}` : TEXT_PRESET_DEF[type].lineHeight,
        textDecoration: underline ? 'underline' : undefined,
        textWrap: textWrap,
        ...style,
      }
    : {
        color: color ? `var(--sparky-text-${color})` : undefined,
        fontSize: size ? `var(--font-${size})` : undefined,
        textAlign: alignment,
        fontWeight: fontWeight ? fontWeight * 100 : undefined,
        lineHeight: lineHeight ? `${lineHeight}` : undefined,
        textDecoration: underline ? 'underline' : undefined,
        textWrap: textWrap,
        ...style,
      };

  return (
    <Component {...otherProps} style={styles} className={className}>
      {children}
    </Component>
  );
}
