/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { cellToBoundary, gridDisk, latLngToCell } from 'h3-js';
import { atom, useAtom, WritableAtom } from 'jotai';
import mapboxgl from 'mapbox-gl';
import Map, { Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import Button from 'Sparky/Button';
import IconButton from 'Sparky/IconButton';
import Message from 'Sparky/Message';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import TextInput from 'Sparky/TextInput';

import { sidebarAtom } from 'components/Sidebar/Sidebar';
import {
  popupBottomPositionFilter,
  popupLeftPositionFilter,
} from 'pages/Analytics/MarketOpportunity/HexMap';
import {
  DealerData,
  Hex,
  HexData,
  LocalMarketData,
} from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';
import { activeDealerTypeAtom } from 'pages/Settings/LocalMarketSettings/DealerSidebar';
import { CircleIconWithBorder } from 'pages/Settings/LocalMarketSettings/icons/CircleIcon';
import { SliderIcon } from 'pages/Settings/LocalMarketSettings/icons/SliderIcon';
import { StarIconWithBorder } from 'pages/Settings/LocalMarketSettings/icons/StarIcon';
import MapPopup from 'pages/Settings/LocalMarketSettings/MarketMap/MapPopup';
import Slider from 'pages/Settings/LocalMarketSettings/MarketMap/SliderV2';
import SummaryPanel from 'pages/Settings/LocalMarketSettings/MarketMap/SummaryPanel';

import styles from './MarketMap.module.scss';

interface HexArrayData extends HexData {
  hex: string;
}

export interface MarketMapProps {
  data?: LocalMarketData;
  protectedHexs: string[];
  newKeyDealers: number[];
  usedKeyDealers: number[];
  highlightDealer: number | undefined;
  dealerList: DealerData[];
}

const mapColor = '#0d67f8';
export const RESOLUTION = 5;

//! BUG FIX: THIS IS REQUIRED TO GET THE MAP WORKER LOADER TO RUN ON DEV
// eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires
(mapboxgl as any).workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const usedSelectedHexsAtom: WritableAtom<string[], string[]> = atom<string[]>([]);
export const newSelectedHexsAtom: WritableAtom<string[], string[]> = atom<string[]>([]);

/**
 * MarketMap
 */
export default function MarketMap({
  data,
  dealerList,
  protectedHexs,
  newKeyDealers,
  usedKeyDealers,
  highlightDealer,
}: MarketMapProps) {
  const [expanded] = useAtom(sidebarAtom);
  const [activeDealerType] = useAtom(activeDealerTypeAtom);
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(false);
  const [newSliderValue, setNewSliderValue] = useState<number[]>([30]);
  const [usedSliderValue, setUsedSliderValue] = useState<number[]>([30]);
  const [usedSelectedHexs, setUsedSelectedHexs] = useAtom(usedSelectedHexsAtom);
  const [newSelectedHexs, setNewSelectedHexs] = useAtom(newSelectedHexsAtom);

  const ref = useRef<HTMLHeadingElement>(null);
  const [mapDimensions, setMapDimensions] = useState<
    { height: number | undefined; width: number | undefined } | undefined
  >(undefined);

  const [reRenderKey, setReRenderKey] = useState(Date.now());

  const [popupData, setPopupData] = useState<HexData | undefined>(undefined);
  useEffect(() => {
    setMapDimensions({ height: ref.current?.offsetHeight, width: ref.current?.offsetWidth });
  }, [ref.current]);

  // Forces map to rerender after the sidebars animation is complete
  useEffect(() => {
    setTimeout(() => {
      setReRenderKey(Date.now());
    }, 200);
  }, [expanded]);

  // eslint-disable-next-line no-undef
  const [popupLocation, setPopupLocation] = useState<
    | {
        lat: number;
        lng: number;
        x: number;
        y: number;
      }
    | undefined
  >(undefined);

  const isNewDealer = activeDealerType === 'new';
  const sliderValue = isNewDealer ? newSliderValue : usedSliderValue;
  const setSliderValue = isNewDealer ? setNewSliderValue : setUsedSliderValue;
  const keyDealers = isNewDealer ? newKeyDealers : usedKeyDealers;
  const selectedHexs = isNewDealer ? [...newSelectedHexs] : [...usedSelectedHexs];
  const setSelectedHexs = isNewDealer ? setNewSelectedHexs : setUsedSelectedHexs;

  const dealerListWithHexs = data?.dealerList.map((dealer) => {
    const hexCode = latLngToCell(dealer.location.lat, dealer.location.lng, RESOLUTION);
    return { ...dealer, hexCode };
  });

  useEffect(() => {
    setReRenderKey(Date.now());
  }, [data]);

  // * BUILDING MAP HEX DATA
  //* Add additional properties to hexData
  let dataHexs: Hex = useMemo(() => {
    return addHexProperties(data?.hexData);
  }, [data?.hexData]);

  //* Create list of selectable hexs
  const selectableList: string[] = useMemo(() => {
    return buildSelectableList(selectedHexs, dataHexs);
  }, [selectedHexs, dataHexs]);

  //* Create Data for each Layer of Hexs
  const chunkedBaseHexArray = useMemo(() => {
    const fullData = Object.keys(dataHexs)
      .map((hex) => {
        const opacity = dataHexs[hex].opacity ?? 0.1;
        return {
          hex,
          ...dataHexs[hex],
          borderColor: '#fff',
          borderOpacity: 0.2,
          opacity: 0.1 + 0.2 * opacity,
        };
      })
      .filter((hex) => !selectedHexs.includes(hex.hex));

    const chunkedData: HexArrayData[][] = [];

    while (fullData.length) {
      const chunk = fullData.splice(0, 100);
      chunkedData.push(chunk);
    }

    return chunkedData;
  }, [selectedHexs]);

  const highlightedDealerCell = useMemo(() => {
    const highlightedCell: HexArrayData[] = [];
    const dealerData = dealerList?.find((dealer) => dealer.dealerId === highlightDealer);
    const dealerHexCode = latLngToCell(
      dealerData?.location.lat ?? 0,
      dealerData?.location.lng ?? 0,
      RESOLUTION
    );

    const dealerHex = data?.hexData[dealerHexCode];
    if (dealerHex) {
      highlightedCell.push({
        ...dealerHex,
        hex: dealerHexCode,
        borderColor: '#f00',
        borderOpacity: 1,
      });
    }

    return returnGeoJSON(highlightedCell);
  }, [highlightDealer, data?.hexData]);

  const protectedHexArray = useMemo(() => {
    let portectedHexsData: HexArrayData[] = [];
    Object.keys(dataHexs)?.forEach((hex, i) => {
      if (protectedHexs.includes(hex) && data?.hexData[hex]) {
        let opacity = dataHexs[hex].opacity ?? 0.1;
        portectedHexsData.push({
          ...data?.hexData[hex],
          hex,
          borderColor: '#333',
          borderOpacity: 1,
          opacity: 0.2 + 0.6 * opacity,
        });
      }
    });
    return returnGeoJSON(portectedHexsData);
  }, [dataHexs]);

  const selectedHexArray = useMemo(() => {
    let selectedHexsData: HexArrayData[] = [];
    Object.keys(dataHexs)?.forEach((hex, i) => {
      if (data?.hexData[hex] && selectedHexs.includes(hex) && !protectedHexs.includes(hex)) {
        const opacity = dataHexs[hex].opacity ?? 0.1;
        selectedHexsData.push({
          ...data?.hexData[hex],
          hex,
          borderColor: '#333',
          borderOpacity: 1,
          opacity: 0.2 + 0.6 * opacity,
        });
      }
    });
    return returnGeoJSON(selectedHexsData);
  }, [selectedHexs]);

  // * ON CLICK HANDLER
  const onClickMapHandler = (event: mapboxgl.MapLayerMouseEvent) => {
    const {
      lngLat: { lat, lng },
    } = event;

    const hexCode = latLngToCell(lat, lng, RESOLUTION);

    if (selectableList.includes(hexCode)) {
      setSelectedHexs([...selectedHexs, hexCode]);
    }

    const isSafeToDeselect = !protectedHexs.includes(hexCode);
    if (selectedHexs.includes(hexCode) && isSafeToDeselect) {
      setSelectedHexs(selectedHexs.filter((hex) => hex !== hexCode));
    }
  };

  //* IF OVER A HEX SET SHOW THE POPUP WITH THAT HEXS DATA
  useEffect(() => {
    if (popupLocation) {
      const hexCode = latLngToCell(popupLocation.lat, popupLocation.lng, RESOLUTION);
      const activeHex = data?.hexData[hexCode];
      const dealersInHex = dealerListWithHexs?.filter((dealer) => dealer.hexCode === hexCode);
      const isSelectable = selectableList.includes(hexCode);
      const isProtected = protectedHexs.includes(hexCode);

      const returnHexStatus = () => {
        if (isProtected) {
          return 'Cell is within minimum radius, cannot be deselected';
        } else if (isSelectable) {
          return 'Click connecting cells to add/remove';
        } else if (selectedHexs.includes(hexCode)) {
          return 'Click connecting cells to add/remove';
        }
        return 'Cell is unavailable to select';
      };

      // Collect dealer data for active hex

      if (activeHex && popupLocation.x !== 0 && popupLocation.y !== 0) {
        setPopupData({ ...activeHex, dealersInHex: dealersInHex, hexStatus: returnHexStatus() });
      } else {
        setPopupData(undefined);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupLocation]);

  //  SET POPUP TO CURRENT MOUSE LOCATION LOCATION
  const onHover = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const {
      lngLat: { lat, lng },
      point: { x, y },
    } = event;

    setPopupLocation({
      lat,
      lng,
      x,
      y,
    });
  }, []);

  const dealerPins = useMemo(() => {
    const pins = dealerList.map((dealer) => {
      return (
        <Marker
          key={dealer.dealerId}
          longitude={dealer.location.lng}
          latitude={dealer.location.lat}
          anchor='center'
        >
          {' '}
          {!keyDealers.includes(dealer.dealerId) ? (
            <CircleIconWithBorder
              color={
                dealer.dealerType === 'franchise' ? COLOR.SPARKY_GREEN_300 : COLOR.SPARKY_PURPLE_300
              }
            />
          ) : (
            <StarIconWithBorder
              color={
                dealer.dealerType === 'franchise' ? COLOR.SPARKY_GREEN_400 : COLOR.SPARKY_PURPLE_400
              }
            />
          )}
        </Marker>
      );
    });

    const mainDealer = (
      <Marker
        key={'primary dealer'}
        longitude={data?.mapCenter.lng ?? 0}
        latitude={data?.mapCenter.lat ?? 0}
        anchor='center'
      >
        <CircleIconWithBorder color={COLOR.SPARKY_RED_300} />
      </Marker>
    );

    return (
      <>
        {pins}
        {mainDealer}
      </>
    );
  }, [keyDealers, dealerList]);

  //* HEX LAYERS
  const baseHexLayer = useMemo(() => {
    return chunkedBaseHexArray.map((chunk, i) => {
      return returnHexLayer(returnGeoJSON(chunk), `local-market-map-${i}`);
    });
  }, [chunkedBaseHexArray]);

  const protectedHexsLayer = useMemo(() => {
    return returnHexLayer(protectedHexArray, 'protected-hexs');
  }, [protectedHexArray]);

  const selectedHexLayer = useMemo(() => {
    return returnHexLayer(selectedHexArray, 'selected-hexs');
  }, [selectedHexArray]);

  const highlightedHexLayer = useMemo(() => {
    return returnHexLayer(highlightedDealerCell, 'highlighted-hexs');
  }, [highlightedDealerCell]);

  const sliderHandler = () => {
    if (data?.hexData) {
      const inRadiusCells = Object.keys(data?.hexData).filter(
        (hex) => data?.hexData[hex].inclusionRadius <= sliderValue[0] || protectedHexs.includes(hex)
      );
      setSelectedHexs(inRadiusCells);
    }
  };

  return (
    <div className={styles.marketmap}>
      <div className={styles.mapContainer} ref={ref}>
        {/* CUSTOM OVERLAY */}
        <div className={styles.mapOverlay}>
          <SummaryPanel selectedHexs={selectedHexs} data={data} />
          <div className={classnames([styles.sliderToggleButton, isSliderOpen && styles.open])}>
            <IconButton
              variants='outline'
              aria-label='toggle-radius-slider'
              onClick={() => setIsSliderOpen(!isSliderOpen)}
            >
              <SliderIcon />
            </IconButton>
          </div>
          {isSliderOpen && (
            <div className={styles.sliderPopover}>
              <Text color='tertiary' size='16' fontWeight={6}>
                In-market range{' '}
              </Text>
              <Text color='tertiary' size='12'>
                Adjust slider to add/remove cells
              </Text>
              <div className={styles.sliderRow}>
                <TextInput
                  id='radius-input'
                  className={styles.textInput}
                  value={sliderValue[0]}
                  onChange={() => null}
                  disabled
                />
                <div className={styles.sliderContainer}>
                  <Text size='14'>10 mi</Text>
                  <div className={styles.slider}>
                    <Slider
                      value={sliderValue}
                      setValue={setSliderValue}
                      max={data?.defaultMarketRadius}
                    />
                  </div>
                  <Text size='14'>{data?.defaultMarketRadius} mi</Text>
                </div>
              </div>
              <Button onClick={sliderHandler} variant='outline' size='sm'>
                Apply
              </Button>
            </div>
          )}
          <div className={styles.infoMessageContainer}>
            <Message
              category='info'
              heading='Interact with the map'
              children={
                <div className={styles.pointList}>
                  <Text className={styles.point}>
                    <span className={styles.bullet} />
                    Hover to view cell details
                  </Text>
                  <Text className={styles.point}>
                    <span className={styles.bullet} />
                    Click on contiguous cells to add or remove them from your PMA
                  </Text>
                  <Text className={styles.point}>
                    <span className={styles.bullet} />
                    Edit the in-market radius using the range slider located at the top right of the
                    map
                  </Text>
                </div>
              }
            />
          </div>
        </div>

        {/* MAP */}

        <Map
          key={reRenderKey}
          initialViewState={{
            latitude: data?.mapCenter.lat,
            longitude: data?.mapCenter.lng,
            zoom: 8.5,
          }}
          mapStyle='mapbox://styles/mapbox/light-v9'
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
          // This is a style hack to make the map act responsively
          style={{
            position: 'relative',
            height: 'inherit',
            width: 'inherit',
          }}
          onMouseMove={(e) => (e ? onHover(e) : null)}
          onClick={onClickMapHandler}
          onDrag={() => setPopupData(undefined)}
          onMouseOut={() => setPopupData(undefined)}
          reuseMaps
        >
          <NavigationControl />

          {/* POPOUP */}
          {popupData && (
            <div
              className={styles.popupContainer}
              style={{
                left: popupLeftPositionFilter(popupLocation?.x, mapDimensions?.width),
                top: popupBottomPositionFilter(popupLocation?.y, mapDimensions?.height),
              }}
            >
              <MapPopup popupData={popupData} />
            </div>
          )}
          {dealerPins}
          {protectedHexsLayer}
          {baseHexLayer}
          {selectedHexLayer}
          {highlightedHexLayer}
        </Map>
      </div>
    </div>
  );
}

//* Convert Data to GeoJSON
function returnGeoJSON(data: HexArrayData[]) {
  const geoData = data.map((hex) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [cellToBoundary(hex.hex, true)],
      },
      properties: { ...hex },
    };
  });

  return {
    type: 'FeatureCollection',
    features: geoData,
  } as FeatureCollection<Geometry, GeoJsonProperties>;
}

//* Perform both addSelectedProperty and addOpacityProperty
function addHexProperties(data: Hex | undefined) {
  const workingData: Hex = { ...data };
  addOpacityProperty(workingData);
  return workingData;
}

//* Add opacity property to hexData
function addOpacityProperty(data: Hex | undefined) {
  let dataHexs: Hex = { ...data };

  Object.keys(dataHexs).forEach((hex) => {
    //* Remove zeros from data averaging calculation
    const nonZeroLength = Object.keys(dataHexs).filter(
      (hex) => dataHexs[hex].inMarketShoppers > 0
    ).length;

    const averageShoppers =
      Object.keys(dataHexs).reduce((acc, hex) => {
        return acc + dataHexs[hex].inMarketShoppers;
      }, 0) / nonZeroLength;

    // Sets opacity based on inMarketShoppers as a multiple of averageShoppers
    switch (true) {
      case dataHexs[hex].inMarketShoppers > averageShoppers * 10:
        dataHexs[hex].opacity = 0.8;
        break;
      case dataHexs[hex].inMarketShoppers > averageShoppers * 7:
        dataHexs[hex].opacity = 0.7;
        break;
      case dataHexs[hex].inMarketShoppers > averageShoppers * 5:
        dataHexs[hex].opacity = 0.6;
        break;
      case dataHexs[hex].inMarketShoppers > averageShoppers * 3:
        dataHexs[hex].opacity = 0.5;
        break;
      case dataHexs[hex].inMarketShoppers > averageShoppers * 2:
        dataHexs[hex].opacity = 0.4;
        break;
      case dataHexs[hex].inMarketShoppers > averageShoppers:
        dataHexs[hex].opacity = 0.3;
        break;
      case dataHexs[hex].inMarketShoppers < averageShoppers / 5:
        dataHexs[hex].opacity = 0.1;
        break;
      case dataHexs[hex].inMarketShoppers < averageShoppers / 3:
        dataHexs[hex].opacity = 0.15;
        break;
      case dataHexs[hex].inMarketShoppers < averageShoppers / 2:
        dataHexs[hex].opacity = 0.2;
        break;
      case dataHexs[hex].inMarketShoppers < averageShoppers:
        dataHexs[hex].opacity = 0.25;
        break;
      default:
        dataHexs[hex].opacity = 0.1;
        break;
    }
    return dataHexs;
  });
}

//* build list of selectable Hexs
function buildSelectableList(selectedHexs: string[], dataHexs: Hex) {
  const selectableList: string[] = [];
  selectedHexs.forEach((hex) => {
    gridDisk(hex, 1).forEach((cell) => {
      const cellExists = Object.keys(dataHexs).includes(cell);
      if (!selectableList.includes(cell) && !selectedHexs.includes(cell) && cellExists) {
        selectableList.push(cell);
      }
    });
  });

  return selectableList;
}

function returnHexLayer(data: FeatureCollection<Geometry, GeoJsonProperties>, slug: string) {
  return (
    <Source id={slug} key={slug} type='geojson' data={data}>
      {/* HEX FILL */}
      <Layer
        type='fill'
        paint={{
          'fill-color': ['interpolate', ['linear'], ['get', 'inMarketShoppers'], 0, mapColor],
          'fill-opacity': {
            type: 'identity',
            property: 'opacity',
          },
        }}
      />

      {/* HEX OUTLINE */}
      <Layer
        {...{
          type: 'line',
          paint: {
            'line-color': {
              type: 'identity',
              property: 'borderColor',
            },
            'line-width': 2,
            'line-opacity': {
              type: 'identity',
              property: 'borderOpacity',
            },
          },
        }}
      />
    </Source>
  );
}
