import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { InventoryResponse } from 'api/useInventory/useInventory';
import ENDPOINTS from 'api/utils/endpoints';
import { post } from 'api/utils/fetch';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

interface AddBudgetRequest {
  token?: string;
  dealerId?: number;
  vins: AddBudgetData[];
}

interface AddBudgetData {
  budget: number;
  vin: string;
}

export interface AddBudgetResponse {
  inactiveVins?: string[];
  result?: AddBudgetResult[];
}

export interface AddBudgetResult {
  contractName: string;
  budget: number;
  vinBudget: number;
  vins: string[];
  contractId: number;
  errorMsg?: string;
  errors?: string[];
}

async function addBudget({ token, dealerId, vins }: AddBudgetRequest) {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  // Only pass through VINs that have a budget
  const vinDetails = vins.filter((i) => i.budget > 0);

  return post<AddBudgetResponse>(`${ENDPOINTS.addBudget}/${dealerId}`, {
    headers: { token },
    body: { vinDetails },
  });
}

export default function useAddBudget() {
  const queryClient = useQueryClient();
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  // Only live inventory VINs can be added budget
  const { inventoryMTDKey: queryKey } = useQueryKeys();

  return useMutation((vins: AddBudgetData[]) => addBudget({ token: user?.token, dealerId, vins }), {
    onSuccess: (data) => {
      // Update cached inventory with budget changes
      queryClient.setQueryData<InventoryResponse | undefined>(queryKey, (prevInventoryData) => {
        if (!prevInventoryData?.inventory) {
          return undefined;
        }

        let inv = prevInventoryData.inventory;
        // Update budgets for items that are in successfully created campaigns
        data.result?.forEach((contract) => {
          if (!contract.errorMsg) {
            contract.vins.forEach((vin) => {
              let item = inv.find((i) => i.listing.vin === vin);
              if (item) {
                item.budgetMetrics.budget += contract.vinBudget;
                // update `hasExistingAddBudget` to true to show the VIN has added budget recently
                item.budgetMetrics.hasExistingAddBudget = true;
              }
            });
          }
        });
        // Update the status for items that were marked as inactive
        data.inactiveVins?.forEach((vin) => {
          let item = inv.find((i) => i.listing.vin === vin);
          if (item) {
            item.status.status = 'PENDING';
          }
        });
        return { ...prevInventoryData, inventory: inv };
      });
    },
  });
}
