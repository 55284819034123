import { IconArrowRight } from '@tabler/icons-react';
import Text from 'Sparky/Text';

import styles from './InquirySuggestion.module.scss';

export interface InquirySuggestionProps {
  text: string;
  onClick(): void;
}

/**
 * InquirySuggestion
 */
export default function InquirySuggestion({ text, onClick }: InquirySuggestionProps) {
  return (
    <button className={styles.inquirySuggestion} onClick={onClick}>
      <Text alignment='left'>{text}</Text>
      <IconArrowRight className={styles.arrow} size='16' />
    </button>
  );
}
