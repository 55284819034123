import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import classnames from 'classnames';
import Button from 'Sparky/Button';
import IconButton from 'Sparky/IconButton';
import Spacer from 'Sparky/Spacer';
import { SPACING } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import styles from 'components/Filters/components/Filter/Filter.module.scss';
import RangeOptions from 'components/Filters/components/RangeOptions/RangeOptions';
import SearchOptions from 'components/Filters/components/SearchOptions/SearchOptions';
import SelectOptions from 'components/Filters/components/SelectOptions/SelectOptions';
import SelectRangeOptions from 'components/Filters/components/SelectRangeOptions/SelectRangeOptions';
import { useFiltersContext } from 'components/Filters/FiltersContext';
import {
  DropdownFilterConfig,
  DynamicMultiSelectFilterConfig,
  DynamicSelectFilterConfig,
  FilterConfig,
  FilterType,
  RangeFilterConfig,
  SelectRangeFilterConfig,
  StaticSelectFilterConfig,
  ToggleSelectFilterConfig,
} from 'components/Filters/types';
import usePersistentState from 'hooks/usePersistentState';
import ActiveFilterPills from 'pages/Inventory/InventoryPage/components/ActiveFilterPills';

import DropdownOptions from '../DropdownOptions/DropdownOptions';
import ToggleOptions from '../ToggleOptions';

export interface FilterProps {
  config: FilterConfig<unknown>;
  groupId: string;
  className?: string;
  size?: 'sm' | 'md';
}
/**
 * A single filter, including the header and controls
 */
export default function Filter({ config, groupId, className, size = 'md' }: FilterProps) {
  const { name, type, id, initCollapsed, hideHeader, hideClear } = config;
  const { clearAll, clearFilter, isLoading } = useFiltersContext();
  const storageId = `${groupId}-${id}`;
  // If `hideHeader` is true, ignore initCollapsed or nothing will render
  const [isExpanded, setIsExpanded] = usePersistentState(storageId, !initCollapsed || !!hideHeader);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      data-testid={`filter-${id}`}
      className={classnames([
        styles.filter,
        type === FilterType.SEARCH && styles.search,
        className,
      ])}
    >
      {!hideHeader && (
        <div
          className={styles.filterHeader}
          onClick={() => {
            if (!hideClear) {
              toggleExpanded();
            }
          }}
        >
          <Text size='14' fontWeight={5} color='secondary'>
            {name}
          </Text>
          {!hideClear && (
            <>
              <Button
                size='sm'
                className={styles.contextButton}
                onClick={(e) => {
                  e.stopPropagation();
                  type === FilterType.ACTIVE_PILLS ? clearAll() : clearFilter(id);
                }}
                disabled={isLoading}
                aria-label={`Clear ${config.name} filter`}
              >
                {type === FilterType.ACTIVE_PILLS ? 'Clear all' : 'Clear'}
              </Button>
              <IconButton
                aria-label={`${isExpanded ? 'Collapse' : 'Expand'} ${name}`}
                onClick={toggleExpanded}
                size='sm'
              >
                {isExpanded ? <IconChevronUp /> : <IconChevronDown />}
              </IconButton>{' '}
            </>
          )}
        </div>
      )}
      {!hideHeader && isExpanded && <Spacer axis='vertical' size={SPACING.SPACING_12} />}
      <div style={{ display: isExpanded ? undefined : 'none' }}>
        {type === FilterType.SEARCH && (
          <SearchOptions groupId={groupId} filterConfigId={id} size={size} />
        )}
        {(type === FilterType.DYNAMIC_SELECT ||
          type === FilterType.STATIC_SELECT ||
          type === FilterType.DYNAMIC_MULTI_SELECT) && (
          <SelectOptions
            config={
              config as
                | DynamicSelectFilterConfig<unknown>
                | StaticSelectFilterConfig<unknown>
                | DynamicMultiSelectFilterConfig<unknown>
            }
          />
        )}
        {type === FilterType.STATIC_SELECT_RANGE && (
          <SelectRangeOptions config={config as SelectRangeFilterConfig<unknown>} />
        )}
        {type === FilterType.DROPDOWN && (
          <DropdownOptions config={config as DropdownFilterConfig<unknown>} />
        )}
        {type === FilterType.RANGE && (
          <RangeOptions config={config as RangeFilterConfig<unknown>} />
        )}
        {type === FilterType.TOGGLE_SELECT && (
          <ToggleOptions config={config as ToggleSelectFilterConfig<unknown>} />
        )}
        {type === FilterType.ACTIVE_PILLS && <ActiveFilterPills />}
      </div>
    </div>
  );
}
