import Accordion from 'Sparky/Accordion';
import Button from 'Sparky/Button';

import ColumnPill from 'pages/Inventory/InventoryPage/components/ColumnPill';
import {
  InventoryTableColumnDef,
  InventoryTableGroupDefs,
} from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';

import styles from './ColumnOptions.module.scss';
import {
  InventoryTableColumnKey,
  InventoryTableConfigGroups,
  InventoryTableGroupKey,
} from '../InventoryTableConfigDefs';

export interface ColumnOptionsProps<T> {
  isLoading: boolean;
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>;
  groupColumns: Record<InventoryTableGroupKey, T[]>;
  activeColumns: Set<T>;

  toggleActiveColumn(col: T, isActive: boolean): void;

  clearGroupColumns(group: InventoryTableGroupKey): void;
}

/**
 * ColumnOptions
 */
export default function ColumnOptions<T>({
  isLoading,
  groupColumns,
  inventoryTableColumnDefs,
  activeColumns,
  toggleActiveColumn,
  clearGroupColumns,
}: ColumnOptionsProps<T>) {
  return (
    <div className={styles.columnOptions}>
      {Object.keys(InventoryTableConfigGroups).map((group) => {
        if (groupColumns[group as InventoryTableGroupKey].length > 0) {
          return (
            <Accordion
              isLoading={isLoading}
              key={group}
              title={InventoryTableGroupDefs[group as InventoryTableGroupKey]}
              titleSize='14'
              defaultOpen
              isOpenByRow
              rightElement={
                <Button
                  size='sm'
                  onClick={(e) => {
                    e.stopPropagation();
                    clearGroupColumns(group as InventoryTableGroupKey);
                  }}
                >
                  Clear
                </Button>
              }
            >
              <div className={styles.columnSectionContent}>
                {groupColumns[group as InventoryTableGroupKey].map((col) => (
                  <ColumnPill
                    key={col as unknown as string}
                    isLoading={isLoading}
                    label={
                      inventoryTableColumnDefs[col as unknown as InventoryTableColumnKey].pillLabel
                    }
                    isActive={activeColumns.has(col as T)}
                    handleClick={() => toggleActiveColumn(col as T, activeColumns.has(col as T))}
                  />
                ))}
              </div>
            </Accordion>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}
