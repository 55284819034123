export enum FilterType {
  SEARCH = 'SEARCH',
  RANGE = 'RANGE',
  DROPDOWN = 'DROPDOWN',
  /** Select filter with pre-defined options */
  STATIC_SELECT = 'STATIC_SELECT',
  STATIC_SELECT_RANGE = 'STATIC_SELECT_RANGE',
  /** Select filter with data-driven options */
  DYNAMIC_SELECT = 'DYNAMIC_SELECT',
  DYNAMIC_MULTI_SELECT = 'DYNAMIC_MULTI_SELECT',
  TOGGLE_SELECT = 'TOGGLE_SELECT',
  ACTIVE_PILLS = 'ACTIVE_PILLS',
}

export type FilterConfig<T> =
  | SearchFilterConfig<T>
  | RangeFilterConfig<T>
  | DropdownFilterConfig<T>
  | DynamicSelectFilterConfig<T>
  | DynamicMultiSelectFilterConfig<T>
  | StaticSelectFilterConfig<T>
  | ToggleSelectFilterConfig<T>
  | SelectRangeFilterConfig<T>
  | ActiveFilterPills;

interface BaseFilterConfig {
  name?: string;
  /** Unique filter ID */
  id: string;
  type: FilterType;
  /** Collapse the filter initially in the Filters panel */
  initCollapsed?: boolean;
  /** Don't show the filter header (collapse button, name, etc.) */
  hideHeader?: boolean;
  hideClear?: boolean;
  excludeFromPage?: string;
}

export interface SearchFilterConfig<T> extends BaseFilterConfig {
  type: FilterType.SEARCH;

  /** Function to get the string to search for each item */
  getter(item: T): string | null | undefined;
}

export interface RangeFilterConfig<T> extends BaseFilterConfig {
  type: FilterType.RANGE;
  rangePrefix?: string;
  rangeSuffix?: string;

  /** Function to get the value corresponding to the filter from each item */
  getter(item: T): number | null | undefined;
}

export interface SelectFilterConfig {
  /** Show options with no matching items */
  showEmptyOptions?: boolean;
  /** Use radio buttons instead of checkboxes */
  exclusiveOptions?: boolean;
}

export interface SelectRangeFilterConfig<T> extends BaseFilterConfig {
  type: FilterType.STATIC_SELECT_RANGE;
  /** Function to get the value corresponding to the filter from each item */
  options: Record<string, SelectRangeFilterOption<T>>;
  rangeLimit?: number[];
  showToggleOptions?: boolean;
  rangePrefix?: string;
  rangeSuffix?: string;

  getter(item: T): number | null | undefined;
}

export interface DynamicSelectFilterConfig<T> extends BaseFilterConfig, SelectFilterConfig {
  type: FilterType.DYNAMIC_SELECT;
  sortOrder?: 'asc' | 'desc';

  /** Function to get the value corresponding to the filter from each item */
  getter(item: T): string | number | null | undefined;
}

export interface DynamicMultiSelectFilterConfig<T> extends BaseFilterConfig, SelectFilterConfig {
  type: FilterType.DYNAMIC_MULTI_SELECT;
  sortOrder?: 'asc' | 'desc';

  /** Function to get the value corresponding to the filter from each item */
  getter(item: T): string[] | number[] | null | undefined;
}

export interface DropdownFilterConfig<T> extends BaseFilterConfig {
  type: FilterType.DROPDOWN;

  /** Function to get the value corresponding to the filter from each item */
  getter(item: T): string | string[];

  defaultOptionName?: string;
  ariaLabel?: string;
}

export interface StaticSelectFilterConfig<T> extends BaseFilterConfig, SelectFilterConfig {
  type: FilterType.STATIC_SELECT;
  options: Record<string, StaticSelectFilterOption<T>>;
}

export interface ToggleSelectFilterConfig<T> extends BaseFilterConfig, SelectFilterConfig {
  type: FilterType.TOGGLE_SELECT;
  options: Record<string, StaticSelectFilterOption<T>>;
  toolTip: string;
}

export interface StaticSelectFilterOption<T> {
  label: string;

  /** Does the item match this option? */
  matcher(item: T): boolean;
}

export interface SelectRangeFilterOption<T> {
  label: string;
  maxValue: number;
  minValue: number;

  /** Does the item match this option? */
  matcher(item: T): boolean;
}

export interface ActiveFilterPills extends BaseFilterConfig {
  type: FilterType.ACTIVE_PILLS;
  label: string;
}

export type FiltersState = Record<string, FilterState>;

export type FilterState =
  | RangeState
  | SelectState
  | SearchState
  | ToggleState
  | DropdownState
  | SelectRangeState;

export interface RangeState {
  min?: number;
  max?: number;
}

export interface SelectState {
  options?: (string | number)[];
}

export interface SearchState {
  search?: string;
}

export interface ToggleState {
  options?: (string | number)[];
}

export interface DropdownState {
  options?: (string | number)[];
}

export interface PillsState {
  id: string;
  key: string;
  value: SearchState | RangeState | string | number;
  type: string;
  label: string;
}

export interface SelectRangeState {
  options?: (string | number)[];
  min?: number;
  max?: number;
}
