import { z } from 'zod';

import {
  AcquisitionItem,
  AcquisitionResponse,
  SourceItem,
} from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';

export const SourceItemSchema: z.ZodSchema<SourceItem> = z.object({
  name: z.string(),
  price: z.number().optional(),
  listingUrl: z.string(),
});

export const AcquisitionItemSchema: z.ZodSchema<AcquisitionItem> = z.object({
  listing: z.object({
    vin: z.string().optional(),
    primaryImage: z.string().optional(),
    comingSoon: z.boolean().optional(),
  }),
  vehicle: z.object({
    modelYear: z.number().optional(),
    make: z.string().optional(),
    model: z.string().optional(),
    trim: z.string().optional(),
    bodySegment: z.string().optional(),
  }),
  marketMetrics: z.object({
    marketDaysSupply: z.number().optional(),
    demand: z.number().optional(),
    supply: z.number().optional(),
  }),
  priceMetrics: z
    .object({
      currentPrice: z.number().optional(),
      localAvgMarketPrice: z.number().optional(),
      localAvgOnlineSoldPrice: z.number().optional(),
    })
    .optional(),
  sources: z.array(SourceItemSchema),
});

export const AcquisitionSchema: z.ZodSchema<AcquisitionResponse> = z.object({
  results: z.array(AcquisitionItemSchema),
});
