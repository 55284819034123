import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useQueryKeys from 'api/utils/useQueryKeys';
import { TooltipData } from 'pages/Settings/TooltipSettings/TooltipSettings';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

interface fetchTooltipsProps {
  token?: string;
}

/**
 * useTooltipSettings
 * @param prop
 */

async function fetchTooltips({ token }: fetchTooltipsProps) {
  assertIsDefined(token);

  const res = await get<TooltipData>(`${ENDPOINTS.tooltips}`, {
    headers: { token },
  });
  return res;
}

export default function useTooltips() {
  const [user] = useAtom(userAtom);
  const { tooltips } = useQueryKeys();

  return useQuery<TooltipData, Error>(tooltips, () => fetchTooltips({ token: user?.token }));
}
