import React, { useState } from 'react';

import { FixedSizeList } from 'react-window';
import Divider from 'Sparky/Divider';
import Repeat from 'Sparky/Repeat';
import Spacer from 'Sparky/Spacer';
import Text from 'Sparky/Text';

import DealerSelectListItem, {
  DealerSelectListItemSkeleton,
} from 'components/DealerSelect/components/DealerSelectListItem/DealerSelectListItem';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { Dealer } from 'types/Dealer';

import styles from './DealerSelectList.module.scss';

export type DealerListItem =
  | {
      name: string;
      isHeader: true;
      id?: never;
    }
  | Dealer;

interface DealerSelectListProps {
  dealerList: DealerListItem[];
  searchText?: string;
  listRef?: any;
  selectedIndex?: number;
  isLoading?: boolean;
  isError?: boolean;
  containerRef?: any;
}

const itemSize = 45;

/**
 * DealerSelectList
 */
export default function DealerSelectList({
  dealerList,
  searchText,
  selectedIndex,
  listRef,
  isLoading,
  isError,
  containerRef,
}: DealerSelectListProps) {
  const [, setSelectedDealerId] = useSelectedDealerId();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(1000);

  React.useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.getBoundingClientRect().width - 24);
      setHeight(containerRef.current.getBoundingClientRect().height);
    }
  }, [containerRef.current]);

  let content: React.ReactNode;
  if (isError) {
    content = (
      <div className={styles.error}>
        <Text size='14' fontWeight={7}>
          Something went wrong
        </Text>
        <Text size='12'>Failed to fetch dealer data</Text>
      </div>
    );
  } else if (isLoading) {
    content = (
      <Repeat times={12}>
        <DealerSelectListItemSkeleton itemSize={itemSize} />
      </Repeat>
    );
  } else if (dealerList.length === 0) {
    content = (
      <Text as='div' size='16' className={styles.placeholder} fontWeight={5} alignment={'center'}>
        No matching dealers found
      </Text>
    );
  } else {
    content = (
      <div className={styles.dealerList}>
        <FixedSizeList
          height={height ?? 500}
          width={width ?? 430}
          itemData={dealerList}
          itemSize={itemSize}
          itemCount={dealerList?.length}
          layout={'vertical'}
          ref={listRef}
        >
          {({ ...props }) => {
            const item = props.data[props.index];

            if ('isHeader' in item) {
              return (
                <div style={props.style}>
                  {props.index !== 0 && <Divider />}
                  <Spacer size={12} />
                  <Text className={styles.dealerHeader} type='label-01' color='secondary'>
                    {item.name}
                  </Text>
                </div>
              );
            } else {
              return (
                <DealerSelectListItem
                  style={props.style}
                  data={item}
                  searchText={searchText}
                  hasFocus={selectedIndex === props.index}
                  setSelectedDealer={setSelectedDealerId}
                />
              );
            }
          }}
        </FixedSizeList>
      </div>
    );
  }

  return <div className={styles.wrapper}>{content}</div>;
}
