import React, { useEffect, useState } from 'react';

import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import BaseInput, { BaseInputProps } from 'Sparky/BaseInput';

import { addition, clamp, remainder, subtraction } from 'util/Math';

export interface Props extends BaseInputProps {
  id: string;
  value?: number | string;
  max?: number;
  min?: number;
  autoFocus?: boolean;
  placeholder?: string;
  divisibleBy?: number;
  step?: number;

  onBlur?(event: React.ChangeEvent<HTMLDivElement>): void;

  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;

  onClick?(): void;

  onClear?(): void;

  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
}

export type NumberInputProps = Props &
  NumberFormatPropsBase<any> &
  Omit<React.ComponentPropsWithoutRef<'input'>, keyof Props>;
/**
 * NumberInput
 */
const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      id,
      value,
      max = Infinity,
      min = -Infinity,
      autoFocus,
      variants = 'default',
      size = 'md',
      disabled,
      placeholder,
      leftElement,
      rightElement,
      className,
      type,
      divisibleBy,
      step,
      onClear,
      onKeyDown,
      onBlur,
      onChange,
      onClick,
      showNumberCaret,
      ...otherProps
    },
    ref
  ) => {
    const [numberValue, setNumberValue] = useState<number | undefined>(
      isNaN(Number(value)) ? undefined : clamp(Number(value), Number(min), Number(max))
    );

    useEffect(() => {
      if (numberValue !== Number(value)) {
        setNumberValue(
          isNaN(Number(value)) ? undefined : clamp(Number(value), Number(min), Number(max))
        );
      }
    }, [value]);

    const handleOnBlur = (event: React.ChangeEvent<HTMLDivElement>) => {
      onBlur && onBlur(event);
      if (numberValue !== undefined && numberValue !== clamp(numberValue, min, max)) {
        setNumberValue(clamp(numberValue, min, max));
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDown) {
        onKeyDown(event);
      }

      if (event.code === 'Escape') {
        if (onClear) {
          onClear();
        }
      }
    };

    const onNumberIncrease = () => {
      if (divisibleBy && divisibleBy > 0) {
        setNumberValue(
          clamp(
            subtraction(
              addition(numberValue ?? 0, divisibleBy),
              remainder(numberValue ?? 0, divisibleBy)
            ),
            Number(min),
            Number(max)
          )
        );
      } else if (step && step > 0) {
        setNumberValue(clamp((numberValue ?? 0) + step, Number(min), Number(max)));
      } else {
        setNumberValue(clamp((numberValue ?? 0) + 1, Number(min), Number(max)));
      }
    };

    const onNumberDecrease = () => {
      if (divisibleBy && divisibleBy > 0) {
        if (remainder(numberValue ?? 0, divisibleBy) === 0) {
          if (otherProps.allowNegative) {
            setNumberValue(
              clamp(subtraction(numberValue ?? 0, divisibleBy), Number(min), Number(max))
            );
          } else {
            setNumberValue(
              clamp(
                Math.max(subtraction(numberValue ?? 0, divisibleBy), 0),
                Number(min),
                Number(max)
              )
            );
          }
        } else {
          if (otherProps.allowNegative) {
            setNumberValue(
              clamp(
                subtraction(numberValue ?? 0, remainder(numberValue ?? 0, divisibleBy)),
                Number(min),
                Number(max)
              )
            );
          } else {
            setNumberValue(
              clamp(
                Math.max(
                  subtraction(numberValue ?? 0, remainder(numberValue ?? 0, divisibleBy)),
                  0
                ),
                Number(min),
                Number(max)
              )
            );
          }
        }
      } else if (step && step > 0) {
        if (otherProps.allowNegative) {
          setNumberValue(clamp((numberValue ?? 0) - step, Number(min), Number(max)));
        } else {
          setNumberValue(clamp(Math.max((numberValue ?? 0) - step, 0), Number(min), Number(max)));
        }
      } else {
        if (otherProps.allowNegative) {
          setNumberValue(clamp((numberValue ?? 0) - 1, Number(min), Number(max)));
        } else {
          setNumberValue(clamp(Math.max((numberValue ?? 0) - 1, 0), Number(min), Number(max)));
        }
      }
    };

    return (
      <BaseInput
        variants={variants}
        size={size}
        disabled={disabled}
        leftElement={leftElement}
        rightElement={rightElement}
        onBlur={handleOnBlur}
        showNumberCaret={showNumberCaret}
        onNumberIncrease={onNumberIncrease}
        onNumberDecrease={onNumberDecrease}
        className={className}
      >
        <NumberFormat
          getInputRef={ref}
          id={id}
          value={numberValue}
          type={type}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e);
            setNumberValue(formatNumbers(e.target.value));
          }}
          onClick={onClick}
          decimalScale={otherProps.decimalScale}
          fixedDecimalScale={otherProps.fixedDecimalScale}
          allowNegative={otherProps.allowNegative}
          {...otherProps}
        />
      </BaseInput>
    );
  }
);

export default NumberInput;

function formatNumbers(value: string) {
  // Define the regex pattern to match anything except digits and dots
  const pattern = /[^0-9.]/g;
  // Use replace() to replace characters not matching the pattern with ''
  return Number(value.replace(pattern, ''));
}
