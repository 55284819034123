import { InventoryItem } from 'types/InventoryItem';

export function getTotalMarkdowns(
  data: InventoryItem[] | undefined,
  visibleSelected: string[]
): number {
  if (!data) {
    return 0;
  }

  const selectedVins = data.filter((item) => visibleSelected.includes(item.listing.vin));
  let totalMarkdowns = 0;

  selectedVins.forEach((item) => {
    totalMarkdowns += item.vinPath?.pricingOptions
      ? item.vinPath?.pricingOptions[0].markdown ?? 0
      : 0;
  });

  return totalMarkdowns;
}
