import { forwardRef } from 'react';

import { IconGripVertical } from '@tabler/icons-react';

import styles from './DragHandle.module.scss';

export const DragHandle = forwardRef<HTMLButtonElement>((props, ref) => {
  return (
    <button className={styles.dragHandle} ref={ref} {...props}>
      <IconGripVertical size={14} />
    </button>
  );
});
