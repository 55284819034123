import {
  Link,
  LinkProps,
  Navigate,
  NavigateOptions,
  NavigateProps,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

interface NavigateKeepParamsProps extends Omit<NavigateProps, 'to'> {
  to: string;
}

interface LinkPreserveParamsProps extends Omit<LinkProps, 'to'> {
  to: string;
}

/** Builds a 'to' string with the provided params */
export function toWithParams(to: string, params: URLSearchParams) {
  return `${to.split('?')[0]}?${params.toString()}`;
}

/** Navigates using react-router's `Navigate`, but preserves the URL query string */
export function NavigateKeepParams({ to, ...otherProps }: NavigateKeepParamsProps) {
  const [params] = useSearchParams();
  return <Navigate to={toWithParams(to, params)} {...otherProps} />;
}

/** Renders a react-router `Link` that preserves the URL query string */
export function LinkKeepParams({ to, ...otherProps }: LinkPreserveParamsProps) {
  const [params] = useSearchParams();
  return <Link to={toWithParams(to, params)} {...otherProps} />;
}

/** Returns a react-router `navigate` function that preserves the URL query string */
export function useNavigateKeepParams() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  return (to: string, options?: NavigateOptions) => navigate(toWithParams(to, params), options);
}
