import { IconUsersGroup } from '@tabler/icons-react';
import classnames from 'classnames';
import Accordion from 'Sparky/Accordion';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import { COLOR } from 'Sparky/styles/vars';
import { DeclineIcon } from 'Sparky/SVGs/DeclineIcon';
import { IncreaseIcon } from 'Sparky/SVGs/IncreaseIcon';
import { NeutralIcon } from 'Sparky/SVGs/NeutralIcon';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import useMarketDemandData from 'pages/Inventory/VinDetailSidebar/api/useMarketDemandData/useMarketDemandData';
import LoadingState from 'pages/Inventory/VinDetailSidebar/components/LoadingState';
import NoDataAvailable from 'pages/Inventory/VinDetailSidebar/components/NoDataAvailable';
import MarketDemandChart from 'pages/Inventory/VinDetailSidebar/ShopperDemandSection/MarketDemandChart';
import { useVinDetailContext } from 'pages/Inventory/VinDetailSidebar/utils/VinDetailContext';

import styles from './ShopperDemandSection.module.scss';

/**
 * ShopperDemandSection
 */
export default function ShopperDemandSection() {
  const { item, openSection, onOpenSection } = useVinDetailContext();

  const { data, isLoading, isError } = useMarketDemandData(item.listing.vin);

  return (
    <Accordion
      className={classnames([
        styles.marketSection,
        openSection.includes('shopperDemand') && styles.openState,
      ])}
      isExpanded={openSection.includes('shopperDemand')}
      handleClick={() => onOpenSection('shopperDemand')}
      title='Shopper demand'
      titleSize='14'
      hightlightTitleOnOpen
      rightElement={
        data?.slope === undefined || data.slope === null ? (
          <Text size={14} fontWeight={5}>
            -
          </Text>
        ) : data.slope > 0 ? (
          <Tooltip content='Demand trend is increasing' side='top'>
            <div className={styles.trendIcon}>
              <IncreaseIcon />
            </div>
          </Tooltip>
        ) : data.slope < 0 ? (
          <Tooltip content='Demand trend is decreasing' side='top'>
            <div className={styles.trendIcon}>
              <DeclineIcon />
            </div>
          </Tooltip>
        ) : (
          <Tooltip content='Demand trend is neutral' side='top'>
            <div className={styles.trendIcon}>
              <NeutralIcon />
            </div>
          </Tooltip>
        )
      }
      formatName={false}
      iconBefore={
        <IconUsersGroup
          size={18}
          color={
            openSection.includes('shopperDemand') ? COLOR.SPARKY_NAVY_400 : COLOR.SPARKY_GREY_400
          }
        />
      }
      isOpenByRow
    >
      <Flex flex='none' width='100%' column rowGap='16px' padding='0 16px 16px'>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <NoDataAvailable sectionName='Shopper demand' />
        ) : (
          <>
            <Panel className={styles.panel}>
              <div className={styles.topSection}>
                <Text size={16} fontWeight={5} color='secondary'>
                  Total shoppers 30d
                </Text>

                <Text size={16} fontWeight={5} color='secondary'>
                  Avg daily shoppers 30d
                </Text>

                <Flex flex='none' width='100%' columnGap='8px' alignItems='center'>
                  <Text size={24} fontWeight={5}>
                    {data?.totalShoppers30d}
                  </Text>

                  {data?.slope === undefined || data.slope === null ? (
                    <Text size={14} fontWeight={5}>
                      -
                    </Text>
                  ) : data.slope > 0 ? (
                    <Tooltip content='Increasing demand trend' side='right'>
                      <div className={styles.trendIcon}>
                        <IncreaseIcon />
                      </div>
                    </Tooltip>
                  ) : data.slope < 0 ? (
                    <Tooltip content='Decreasing demand trend' side='right'>
                      <div className={styles.trendIcon}>
                        <DeclineIcon />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip content='Neutral demand trend' side='right'>
                      <div className={styles.trendIcon}>
                        <NeutralIcon />
                      </div>
                    </Tooltip>
                  )}
                </Flex>
                <Text size={24} fontWeight={5}>
                  {data?.avgShoppers30d}
                </Text>
              </div>
            </Panel>
            <Panel className={styles.panel}>
              <Text size={16} fontWeight={5} color='secondary' className={styles.chartTitle}>
                Daily Shoppers
              </Text>

              {!data?.data?.length ? (
                <NoDataAvailable sectionName='daily shoppers' />
              ) : (
                <Flex flex='none' width='100%' height='200px'>
                  <MarketDemandChart data={data?.data} high={data?.high} low={data?.low} />
                </Flex>
              )}
            </Panel>
          </>
        )}
      </Flex>
    </Accordion>
  );
}
