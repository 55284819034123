import { z } from 'zod';

import { VDPPriceDataResponse } from 'pages/Inventory/VinDetailSidebar/api/useVDPPriceData/useVDPPriceData';

export const VDPPriceChartDataSchema = z.object({
  date: z.string(),
  totalVDP: z.number().optional(),
  otherVDP: z.number().optional(),
  lotlinxVDP: z.number().optional(),
  dailyPrice: z.number().optional(),
  conversions: z.number().optional(),
  markdown: z.number().optional(),
  marketPrice: z.number().optional(),
  priceToMarketPct: z.number().optional(),
});

export const VDPPriceDataResponseSchema: z.ZodSchema<VDPPriceDataResponse> = z.object({
  trends: z.array(VDPPriceChartDataSchema).optional(),
});
