import * as React from 'react';

import {
  Content,
  List,
  Root,
  Trigger,
  TabsContentProps,
  TabsListProps,
  TabsProps,
  TabsTriggerProps,
} from '@radix-ui/react-tabs';
import classnames from 'classnames';

import styles from './Tabs.module.scss';

/**
 * Tabs - The wrapper for the Sparky TabsList and TabContent component
 */
export interface TabsFontSizeProps {
  fontSize?: 'default' | 'sm';
}

export function Tabs({
  fontSize = 'default',
  children,
  className,
  orientation = 'horizontal',
  ...otherProps
}: TabsProps & React.RefAttributes<HTMLDivElement> & TabsFontSizeProps) {
  return (
    <Root
      {...otherProps}
      data-orientation={orientation}
      className={classnames([
        { [styles.tabs]: true, [styles.smallFontSize]: fontSize === 'sm' },
        className,
      ])}
      style={
        { '--orientation': orientation === 'horizontal' ? 'column' : 'row' } as React.CSSProperties
      }
    >
      {children}
    </Root>
  );
}

export function TabsList({
  children,
  className,
  ...otherProps
}: TabsListProps & React.RefAttributes<HTMLDivElement>) {
  return (
    <List {...otherProps} className={classnames([styles.tabsList, className])}>
      {children}
    </List>
  );
}

export function Tab({
  children,
  className,
  value,
  ...otherProps
}: TabsTriggerProps & React.RefAttributes<HTMLButtonElement>) {
  return (
    <Trigger
      {...otherProps}
      aria-label={value}
      value={value}
      className={classnames([styles.tab, className])}
    >
      <div className={styles.tabText}>{children}</div>
      <div className={styles.tabTextHover}>{children}</div>
    </Trigger>
  );
}

export function TabContent({
  children,
  className,
  ...otherProps
}: TabsContentProps & React.RefAttributes<HTMLDivElement>) {
  return (
    <Content {...otherProps} className={classnames([styles.tabContent, className])}>
      {children}
    </Content>
  );
}
