import React, { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { produce } from 'immer';
import { useForm } from 'react-hook-form';
import Button from 'Sparky/Button';
import Text from 'Sparky/Text';
import TextInputField from 'Sparky/TextInputField';

import { APIError, ErrorCode } from 'api/utils';
import { ResetCompleteSchema } from 'pages/SignInPage/api/useResetComplete/resetCompleteSchema';
import useResetComplete, {
  ResetCompleteData,
} from 'pages/SignInPage/api/useResetComplete/useResetComplete';
import ContactUsLink from 'pages/SignInPage/ContactUsLink';

import styles from './ResetCompletePwdForm.module.scss';

interface ResetCompletePwdProps {
  defaultEmail: string | null;
  defaultResetPwKey: string | null;
}

/**
 * ResetCompletePwd
 */
export default function ResetCompletePwdForm({
  defaultEmail,
  defaultResetPwKey,
}: ResetCompletePwdProps) {
  const [inputData, setInputData] = useState<ResetCompleteData>({
    email: defaultEmail ?? '',
    resetPwKey: defaultResetPwKey ?? '',
  });
  const [message, setMessage] = useState<string>(
    'Please check your email for your password reset link.'
  );
  const [error, setError] = useState<string>();
  const mutation = useResetComplete();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<ResetCompleteData>({ resolver: zodResolver(ResetCompleteSchema) });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: 'email' | 'resetPwKey') => {
    // Without the next line, if the input field is autofilled by the browser, react-hook-form does not know it
    setValue(key, event.target.value);
    clearErrors(key);
    setInputData(
      produce(inputData, (draft) => {
        draft[key] = event.target.value;
        return draft;
      })
    );
  };

  const onSubmit = (data: ResetCompleteData) => {
    mutation.mutate(data, {
      onSuccess: () => setMessage('Please check your email for your temporary password.'),
      onError: (err) => {
        if (err instanceof APIError && err.subCode === ErrorCode.ACCESS_DENIED) {
          setError('Incorrect email or reset password key. Please try again.');
        } else {
          setError('An unknown error occurred');
        }
      },
    });
  };

  return (
    <div className={styles.resetCompletePwdWrapper}>
      <Text fontWeight={7} size='20' color='white' className={styles.header}>
        Complete Password Reset
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.resetCompletePwdForm}>
        <Text alignment='center' fontWeight={4} size='14' color='white' className={styles.message}>
          {message}
        </Text>
        <div className={styles.textInputWrapper}>
          <TextInputField
            id='email'
            value={inputData.email}
            {...register('email', { required: true })}
            variants={error || errors.email ? 'error' : 'default'}
            message={errors.email?.message ?? ' '}
            label='Email'
            labelColor='white'
            onChange={(event) => onChange(event, 'email')}
            className={styles.textInput}
            messageClassname={styles.errorMessage}
          />
          <TextInputField
            id='resetPwKey'
            value={inputData.resetPwKey}
            {...register('resetPwKey', { required: true })}
            variants={error || errors.resetPwKey ? 'error' : 'default'}
            message={error ? error : errors.resetPwKey?.message ?? ' '}
            label='Reset password key'
            labelColor='white'
            onChange={(event) => onChange(event, 'resetPwKey')}
            className={styles.textInput}
            messageClassname={styles.errorMessage}
          />
        </div>
        <Button
          type='submit'
          variant='solid'
          isLoading={mutation.isLoading}
          loadingText='Logging In...'
          size='lg'
          className={styles.summitButton}
        >
          Complete password reset
        </Button>
      </form>
      <ContactUsLink />
    </div>
  );
}
