import { ReactElement, useState } from 'react';

import { Link, Location, useLocation } from 'react-router-dom';
import Text from 'Sparky/Text';

import DropdownButton from 'components/DropdownButton/DropdownButton';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { FeatureFlag } from 'types/FeatureFlags';

import styles from './SegmentNavDropdown.module.scss';

/**
 * SegmentNavDropdown
 */
export default function SegmentNavDropdown() {
  const isActive = (location: Location) => location.pathname.includes('analytics');
  const location = useLocation();
  const [openDropdownPopover, setOpenDropdownPopover] = useState(false);
  const showBeforeAfter = useFeatureFlag(FeatureFlag.ENABLE_BEFORE_AND_AFTER);
  const showMarketOpportunity = useFeatureFlag(FeatureFlag.ENABLE_MARKET_OPPORTUNITY);
  const showRiskAnalysis = useFeatureFlag(FeatureFlag.ENABLE_RISK_ANALYSIS);
  const showRiskOverview = useFeatureFlag(FeatureFlag.ENABLE_RISK_OVERVIEW);
  const showMarketInsightsPage = useFeatureFlag(FeatureFlag.ENABLE_MARKET_INSIGHTS_PAGE);

  return (
    <div className={styles.segmentNavDropdownWrapper}>
      <DropdownButton
        active={isActive(location)}
        title='Analytics'
        isOpen={openDropdownPopover}
        setIsOpen={setOpenDropdownPopover}
      >
        <div className={styles.segmentNavDropdown}>
          {showMarketInsightsPage && (
            <DropdownLink
              title='Market Insights'
              location='market_insights'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}

          {showRiskOverview && (
            <DropdownLink
              title='Risk'
              location='risk_overview'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}
          {/* If risk overview is disabled, risk analysis will still available in menu */}
          {showRiskAnalysis && !showRiskOverview && (
            <DropdownLink
              title='Risk'
              location='risk_analysis'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}

          {showMarketOpportunity && (
            <DropdownLink
              title='Market Opportunity'
              location='market_opportunity'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}
          {showBeforeAfter && (
            <DropdownLink
              title='Before & After'
              location='before_after'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}
        </div>
      </DropdownButton>
    </div>
  );
}

interface DropdownLinkProps {
  title: string;
  location: string;

  onLinkClick(): void;
}

function DropdownLink({ title, location, onLinkClick }: DropdownLinkProps): ReactElement {
  const [dealerId] = useSelectedDealerId();
  return (
    <Link
      className={styles.segmentLink}
      to={`analytics/${location}${dealerId ? `?dealerId=${dealerId}` : ''}`}
      onClick={() => {
        onLinkClick();
      }}
    >
      <Text
        className={styles.segmentLinkText}
        fontWeight={window.location.toString().includes(location) ? 7 : 4}
      >
        {title}
      </Text>
    </Link>
  );
}
