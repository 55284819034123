import classnames from 'classnames';
import { useAtom } from 'jotai';
import IconButton from 'Sparky/IconButton';
import Skeleton from 'Sparky/Skeleton';
import { BlackOutlineStarIcon } from 'Sparky/SVGs/BlackOutlineStarIcon';
import { GoldStarIcon } from 'Sparky/SVGs/GoldStarIcon';

import useSaveDealerFavorites from 'components/DealerSelect/api/useSaveFavorites/useSaveDealerFavorites';
import { DEALER_TYPES, starredDealerListAtom } from 'components/DealerSelect/DealerSelect';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { Dealer } from 'types/Dealer';

import styles from './DealerSelectListItem.module.scss';

interface DealerSelectListItemProps {
  data: Dealer;
  setSelectedDealer(id: number): void;
  searchText?: string;
  style?: object;
  hasFocus?: boolean;
}

/**
 * DealerSelectListItem
 */
export default function DealerSelectListItem({
  data,
  searchText,
  setSelectedDealer,
  style,
  hasFocus,
}: DealerSelectListItemProps) {
  const [dealerId] = useSelectedDealerId();
  let iconType = DEALER_TYPES[data.type.toLowerCase()];
  const setDealerFavorites = useSaveDealerFavorites();

  const [starredDealerList, setStarredDealerList] = useAtom(starredDealerListAtom);

  const isStarred = starredDealerList.includes(data.id);

  const toggleStarred = () => {
    let tempStarredList = [...starredDealerList];
    if (isStarred) {
      tempStarredList = tempStarredList.filter((id) => id !== data.id);
    } else {
      tempStarredList = [...tempStarredList, data.id];
    }
    setDealerFavorites.mutate(tempStarredList);
    setStarredDealerList(tempStarredList);
  };
  const isActiveDealer = dealerId === data.id;

  return (
    <div
      id={`dealer-opt-${data.id}`}
      style={style}
      onClick={() => setSelectedDealer(data.id)}
      className={classnames([
        styles.dealerSelectListItem,
        hasFocus && styles.focus,
        isActiveDealer && styles.activeDealer,
      ])}
      role='menuitem'
    >
      <div
        className={classnames([
          styles.dealerTypeIcon,
          data.type === 'Prospect' && styles.prospect,
          data.type === 'None' && styles.none,
        ])}
        style={{ backgroundColor: iconType.color }}
        aria-hidden
      >
        {iconType.iconChar}
      </div>
      <HighlightMatch text={data.name} search={searchText} />
      <HighlightMatch text={data.id.toString()} search={searchText} />
      <IconButton
        size='md'
        onClick={(e) => {
          e.stopPropagation();
          toggleStarred();
        }}
        tooltip='Favorite'
        aria-label='dealer select star'
        tooltipSide='top'
        children={isStarred ? <GoldStarIcon /> : <BlackOutlineStarIcon />}
      />
    </div>
  );
}

interface HighlightMatchProps {
  text: string;
  search?: string;
}

/** If any text in the `text` prop matches `search`, makes matching text red */
export function HighlightMatch({ text, search }: HighlightMatchProps): JSX.Element {
  if (search && search.length) {
    const index = text.toLowerCase().indexOf(search.toLowerCase());
    if (index !== -1) {
      return (
        <span>
          <span>{text.substr(0, index)}</span>
          <span className={styles.highlight}>{text.substr(index, search.length)}</span>
          <span>{text.substring(index + search.length, text.length)}</span>
        </span>
      );
    }
  }

  return <span>{text}</span>;
}

export function DealerSelectListItemSkeleton({ itemSize }: { itemSize: number }) {
  return (
    <div className={styles.dealerSelectListItem} style={{ height: itemSize }}>
      <Skeleton circle width='1.25rem' height='1.25rem' />
      <Skeleton width='12em' height='1em' />
      <Skeleton width='4em' height='1em' />
      <BlackOutlineStarIcon />
    </div>
  );
}
