import { useEffect, useState } from 'react';

import { useAtom } from 'jotai';
import Modal from 'Sparky/Modal';
import useToast from 'Sparky/Toast';

import useKeepAlive from 'api/useKeepAlive/useKeepAlive';
import SignIn from 'components/SignIn/SignIn';
import { userAtom } from 'store';
import { REFRESH_TOKEN } from 'types/Events';

import styles from './KeepAlive.module.scss';

/**
 * Ensures that user token remains valid, display a sign in modal if the token is invalid
 */
export default function KeepAlive() {
  const [revalidate, setRevalidate] = useState(false);
  const { addToast } = useToast();
  const [user, setUser] = useAtom(userAtom);
  useKeepAlive();

  // If the `REFRESH_TOKEN` event is triggered, display a modal prompting the user to sign back in
  useEffect(() => {
    const handleReauth = () => {
      if (user) {
        setRevalidate(true);
        addToast({
          status: 'warning',
          duration: 10000,
          description: 'Session expired, please sign in again',
        });
      }
    };
    window.addEventListener(REFRESH_TOKEN, handleReauth);
    return () => window.removeEventListener(REFRESH_TOKEN, handleReauth);
  }, [user, setUser, addToast]);

  return (
    <Modal aria-label='Sign in' isOpen={revalidate} overlayClassName={styles.signInModal}>
      <SignIn onSuccess={() => setRevalidate(false)} />
    </Modal>
  );
}
