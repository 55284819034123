import Text from 'Sparky/Text';

import styles from './NoData.module.scss';

/**
 * NoData
 */
export default function NoData() {
  return (
    <div className={styles.noData}>
      <div className={styles.lines}>
        <div className={styles.ruler}>
          <div className={styles.zero}>
            <Text color='secondary' size='12'>
              0
            </Text>
          </div>
        </div>
        <div className={styles.ruler}>
          <div className={styles.zero}>
            <Text color='secondary' size='12'>
              0
            </Text>
          </div>
        </div>
        <div className={styles.ruler}>
          <div className={styles.zero}>
            <Text color='secondary' size='12'>
              0
            </Text>
            <div className={styles.noDataText}>
              <Text size='14'>No Data Available</Text>
            </div>
          </div>
        </div>
        <div className={styles.ruler}>
          <div className={styles.zero}>
            <Text color='secondary' size='12'>
              0
            </Text>
          </div>
        </div>
        <div className={styles.ruler}>
          <div className={styles.zero}>
            <Text color='secondary' size='12'>
              0
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
