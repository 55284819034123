import Chip from 'Sparky/Chip';

import styles from './OrdinalScalePill.module.scss';

export interface OrdinalScalePillProps {
  ordinalScale?: 'low' | 'medium' | 'high';
  tooltip?: string;
  opposite?: boolean;
}

/**
 * OrdinalScalePill
 */
export default function OrdinalScalePill({
  ordinalScale,
  tooltip,
  opposite,
}: OrdinalScalePillProps) {
  if (ordinalScale === 'low') {
    return (
      <Chip
        variant='filled'
        color={opposite ? 'red' : 'green'}
        tooltip={tooltip}
        tooltipSide='top'
        className={styles.ordinalScalePill}
      >
        Low
      </Chip>
    );
  } else if (ordinalScale === 'medium') {
    return (
      <Chip
        variant='filled'
        color='orange'
        tooltip={tooltip}
        tooltipSide='top'
        className={styles.ordinalScalePill}
      >
        Medium
      </Chip>
    );
  } else if (ordinalScale === 'high') {
    return (
      <Chip
        variant='filled'
        color={opposite ? 'green' : 'red'}
        tooltip={tooltip}
        tooltipSide='top'
        className={styles.ordinalScalePill}
      >
        High
      </Chip>
    );
  } else {
    return <>-</>;
  }
}
