import React from 'react';

import { IconSearch, IconX } from '@tabler/icons-react';
import IconButton from 'Sparky/IconButton';
import TextInput, { TextInputProps } from 'Sparky/TextInput';

import styles from './SearchField.module.scss';

export interface SearchFieldProps extends Omit<TextInputProps, 'leftElement' | 'rightElement'> {
  showCloseButton?: boolean;
}

/**
 * SearchField
 */
const SearchField = React.forwardRef<HTMLInputElement, SearchFieldProps>(
  ({ showCloseButton = true, autoComplete = 'off', ...props }, ref) => {
    return (
      <TextInput
        ref={ref}
        leftElement={<IconSearch size={props.size === 'md' ? 20 : 12} />}
        rightElement={
          showCloseButton ? (
            <IconButton
              aria-label='clear search'
              className={styles.closeButton}
              onClick={(e) => {
                e.stopPropagation();
                props.onClear && props.onClear();
              }}
            >
              <IconX size={props.size === 'md' ? 20 : 14} />
            </IconButton>
          ) : undefined
        }
        autoComplete={autoComplete}
        {...props}
      />
    );
  }
);

export default SearchField;
