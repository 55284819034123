import { useRef } from 'react';

import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Modal from 'Sparky/Modal';
import Text from 'Sparky/Text';

import Chat from 'pages/LexChat/Chat';
import ModalHeader from 'pages/LexChat/ModalHeader';

import styles from './LexChat.module.scss';

export const showLexChatAtom = atom(false);
export const injectLexPromptAtom = atom<string>('');
export const lexChatToneAtom = atomWithStorage('lex_chat_tone', 'normal');

export default function LexChat() {
  const sessionId = useRef(getSessionId());
  const [showLexChat, setShowLexChat] = useAtom(showLexChatAtom);
  const [lexChatTone, setLexChatTone] = useAtom(lexChatToneAtom);

  return showLexChat ? (
    <Modal
      isOpen={showLexChat}
      onDismiss={() => setShowLexChat(false)}
      aria-label='Lex Chat'
      overlayStyle={{
        padding: 0,
        alignItems: 'flex-end',
        background: 'none',
        // pointerEvents: 'none',
      }}
    >
      <div className={styles.lexChatContainer}>
        <ModalHeader
          setLexChatTone={setLexChatTone}
          setShowLexChat={setShowLexChat}
          lexChatTone={lexChatTone}
        />

        <Chat sessionId={sessionId.current} lexChatTone={lexChatTone} />

        <div className={styles.footer}>
          <Text color='tertiary' size='12' fontWeight={4} alignment='center'>
            The Lotlinx ChatBot (Lex) can make mistakes. Always double check important information.
          </Text>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
}

export function getSessionId() {
  try {
    // This should be available, but will throw an error if it isn't
    return window.crypto.randomUUID();
  } catch (e) {
    return Date.now().toString(16);
  }
}
