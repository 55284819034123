import React, { useCallback, useEffect, useRef, useState } from 'react';

import { IconArrowNarrowLeft, IconChevronLeft, IconMenu2, IconX } from '@tabler/icons-react';
import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { cellToBoundary, latLngToCell, getResolution } from 'h3-js';
import { useAtom } from 'jotai';
import mapboxgl from 'mapbox-gl';
import Map, { Layer, Source, NavigationControl, Marker } from 'react-map-gl';
import Button from 'Sparky/Button';
import IconButton from 'Sparky/IconButton';
import Panel from 'Sparky/Panel';
import RadioField from 'Sparky/RadioField';
import Skeleton from 'Sparky/Skeleton';
import Spacer from 'Sparky/Spacer';
import { COLOR, SPACING } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { sidebarAtom } from 'components/Sidebar/Sidebar';
import {
  MapDataPoint,
  MarketOpportunityMetricNames,
  MarketOpportunityData,
  MapData,
} from 'pages/Analytics/MarketOpportunity/api/useMarketOpportunity/useMarketOpportunity';
import { ExpandIcon } from 'pages/Analytics/MarketOpportunity/HexMap/expandIcon';
import { tabs } from 'pages/Analytics/MarketOpportunity/MarketOpportunity';
import { LocalMarketData } from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';
import { CircleIconWithBorder } from 'pages/Settings/LocalMarketSettings/icons/CircleIcon';
import { legendItems } from 'pages/Settings/LocalMarketSettings/MarketMap/SummaryPanel';
import { formatNumber } from 'util/formatters';

import styles from './HexMap.module.scss';

//! BUG FIX: THIS IS REQUIRED TO GET THE MAP WORKER LOADER TO  RUN ON DEV
// eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires
(mapboxgl as any).workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export interface HexMapProps {
  marketOpportunityData?: MarketOpportunityData;
  localMarketData?: LocalMarketData;
  setIsMapFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
  isMapFullscreen: boolean;
  activeMapMetric: string;
  setActiveMapMetric: React.Dispatch<React.SetStateAction<string>>;
  isMarketOpportunityDataLoading: boolean;
  isLocalMarketDataLoading: boolean;
}

const mapMetricDefs: Record<MarketOpportunityMetricNames, { color: string; title: string }> = {
  inMarketShoppers: { color: '#0d67f8', title: 'In-market shoppers' },
  salesVolume: { color: '#0d67f8', title: 'Sales volume' },
  listedUnits: { color: '#0d67f8', title: 'Listed units' },
  marketDaysSupply: { color: '#0d67f8', title: 'Market days supply' },
};

export default function HexMap({
  marketOpportunityData,
  localMarketData,
  isMapFullscreen,
  setIsMapFullscreen,
  activeMapMetric,
  setActiveMapMetric,
  isMarketOpportunityDataLoading,
  isLocalMarketDataLoading,
}: HexMapProps) {
  // eslint-disable-next-line no-undef
  const [popupLocation, setPopupLocation] = useState<
    | {
        lat: number;
        lng: number;
        x: number;
        y: number;
      }
    | undefined
  >(undefined);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showControlPanel, setShowControlPanel] = useState<boolean>(true);
  const [popupData, setPopupData] = useState<MapDataPoint | undefined>(undefined);
  const [activeData, setActiveData] = useState<MapData | undefined>(
    marketOpportunityData?.mapData[activeMapMetric as MarketOpportunityMetricNames]
  );
  const [mapColor] = useState(
    mapMetricDefs[activeMapMetric as MarketOpportunityMetricNames]?.color
  );
  const [expanded] = useAtom(sidebarAtom);
  const [reRenderKey, setReRenderKey] = useState(Date.now());
  const ref = useRef<HTMLHeadingElement>(null);
  const [mapDimensions, setMapDimensions] = useState<
    { height: number | undefined; width: number | undefined } | undefined
  >(undefined);
  useEffect(() => {
    setMapDimensions({ height: ref.current?.offsetHeight, width: ref.current?.offsetWidth });
  }, [ref.current]);

  // Forces map to rerender after the sidebars animation is complete
  useEffect(() => {
    setTimeout(() => {
      setReRenderKey(Date.now());
    }, 200);
  }, [expanded]);

  const currentZoom = activeData?.data?.length ? getResolution(activeData?.data[0].hex) : 8;

  useEffect(() => {
    setActiveData(marketOpportunityData?.mapData[activeMapMetric as MarketOpportunityMetricNames]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMapMetric]);

  //  SET POPUP TO CURRENT MOUSE LOCATION LOCATION
  const onHover = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const {
      lngLat: { lat, lng },
      point: { x, y },
    } = event;

    setPopupLocation({
      lat,
      lng,
      x,
      y,
    });
  }, []);

  // IF OVER A HEX SET SHOW THE POPUP WITH THAT HEXS DATA
  useEffect(() => {
    if (popupLocation) {
      const hexCode = latLngToCell(popupLocation.lat, popupLocation.lng, currentZoom);
      const activeHex = activeData?.data?.find((hex) => hex.hex === hexCode);

      if (activeHex && popupLocation.x !== 0 && popupLocation.y !== 0) {
        setShowPopup(true);
        setPopupData(activeHex);
      } else {
        setShowPopup(false);
        setPopupData(undefined);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupLocation]);

  const handleExpandToggle = () => {
    setIsMapFullscreen(!isMapFullscreen);
  };

  const handleMetricToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveMapMetric(e.target.value);
  };

  const dataMin = activeData?.data?.reduce((a, b) => Math.min(a, b.value), 0);
  const dataMax = activeData?.data?.reduce((a, b) => Math.max(a, b.value), 0);

  if (isMarketOpportunityDataLoading || isLocalMarketDataLoading) {
    return <Skeleton height='100%' width='100%' />;
  }

  if (!activeData || !marketOpportunityData) {
    return <></>;
  }

  const legend = (
    <Panel className={styles.legend}>
      {legendItems[1] && (
        <div className={styles.legendItemRow}>
          <div className={styles.legendIcon}>{legendItems[1].icon}</div>
          <Text>{legendItems[1].label}</Text>
        </div>
      )}
      <Spacer size={SPACING.SPACING_16} axis='vertical' />
      <Text>{mapMetricDefs[activeMapMetric as MarketOpportunityMetricNames].title}</Text>
      <div
        className={styles.gradiantBar}
        style={{
          background: `linear-gradient(90deg, rgba(130, 175, 247, 0.00) -3.01%, ${mapColor} 100%)`,
        }}
      />
      <div className={styles.minMaxRow}>
        <Text size='10'>{formatNumber(dataMin)}</Text>
        <Text size='10'>{formatNumber(dataMax)}</Text>
      </div>
    </Panel>
  );

  const layerIntervals = returnLayerIntervals(
    marketOpportunityData.mapData[activeMapMetric as MarketOpportunityMetricNames]
  );

  return (
    <div className={styles.mapContainer} ref={ref}>
      {/* CUSTOM OVERLAY FOR MAP CONTROLS */}
      <div className={styles.mapOverlay}>
        {/* LEGEND - NON FULLSCREEN */}
        <div className={styles.legendAbsoluteContainer}>{!isMapFullscreen && legend}</div>

        {/* EXPAND BUTTON - NON FULLSCREEN */}
        {!isMapFullscreen && (
          <div className={styles.expandButton}>
            <IconButton
              onClick={handleExpandToggle}
              aria-label='expand-map'
              size='md'
              variants='outline'
            >
              <ExpandIcon />
            </IconButton>
          </div>
        )}

        {/* FULLSCREEN CONTROLS */}
        {isMapFullscreen && showControlPanel && (
          <Panel className={styles.fullScreenControls}>
            <div className={styles.topControles}>
              <div className={styles.togglePanelButton}>
                <IconButton
                  onClick={() => setShowControlPanel(false)}
                  aria-label='toggle-control-panel'
                  size='md'
                  variants='outline'
                >
                  <IconChevronLeft />
                </IconButton>
              </div>
              <div className={styles.metricRadioButtons}>
                {Object.values(tabs).map((tab) => {
                  const radioButtons = tab.metrics.map((metric) => {
                    return (
                      <RadioField
                        key={metric}
                        smallHover={true}
                        value={metric}
                        checked={activeMapMetric === metric}
                        onChange={(e) => handleMetricToggle(e)}
                      >
                        {mapMetricDefs[metric as MarketOpportunityMetricNames].title}
                      </RadioField>
                    );
                  });

                  return [
                    <Text key={tab.label} color='secondary'>
                      {tab.label}
                    </Text>,
                    radioButtons,
                  ];
                })}
              </div>
              {legend}
            </div>

            {/*  BACK BUTTON */}
            <div className={styles.backButton}>
              <Button onClick={() => setIsMapFullscreen(false)}>
                <IconArrowNarrowLeft size={20} />
                <Text fontWeight={5}>Go Back</Text>
              </Button>
            </div>
          </Panel>
        )}

        {/* HAMBURGER BUTTON - FULLSCREEN */}
        {isMapFullscreen && !showControlPanel && (
          <div className={styles.closedControlPanel}>
            <Tooltip side='bottom' content='Change map metric' textAlign='center'>
              <IconButton
                onClick={() => setShowControlPanel(true)}
                aria-label='toggle-control-panel'
                size='md'
                variants='outline'
              >
                <IconMenu2 />
              </IconButton>
            </Tooltip>
            <Text fontWeight={7} size='24'>
              {mapMetricDefs[activeMapMetric as MarketOpportunityMetricNames].title}
            </Text>
          </div>
        )}

        {/* MAP CLOSE BUTTON - FULLSCREEN */}
        {isMapFullscreen && (
          <div className={styles.closeIcon}>
            <IconButton
              onClick={handleExpandToggle}
              aria-label='toggle-expanded-map'
              size='md'
              variants='outline'
            >
              <IconX />
            </IconButton>
          </div>
        )}
      </div>

      {/* MAP */}
      <Map
        key={reRenderKey}
        initialViewState={{
          latitude: marketOpportunityData?.mapCenter.lat,
          longitude: marketOpportunityData?.mapCenter.lng,
          zoom: 8,
        }}
        mapStyle='mapbox://styles/mapbox/light-v9'
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
        // This is a style hack to make the map act responsively
        style={{
          position: 'relative',
          height: 'inherit',
          width: 'inherit',
        }}
        onDrag={() => setShowPopup(false)}
        onMouseMove={(e) => (e ? onHover(e) : null)}
        onMouseOut={() => setShowPopup(false)}
      >
        <NavigationControl />

        {/* POPOUP */}
        {showPopup && !isMarketOpportunityDataLoading && (
          <div
            className={styles.popup}
            style={{
              left: popupLeftPositionFilter(popupLocation?.x, mapDimensions?.width),
              top: popupBottomPositionFilter(popupLocation?.y, mapDimensions?.height),
            }}
          >
            <div className={styles.popupContent}>
              <div className={styles.tooltipRow}>
                <Text size='14' color='secondary'>
                  {mapMetricDefs[activeMapMetric as MarketOpportunityMetricNames].title}
                </Text>
                <Text size='14'>{formatNumber(popupData?.value)}</Text>
              </div>
            </div>
          </div>
        )}

        {/* PRIMARY DEALER */}
        {!isLocalMarketDataLoading && (
          <Marker
            key='primary-dealer'
            longitude={localMarketData?.mapCenter.lng ?? 0}
            latitude={localMarketData?.mapCenter.lat ?? 0}
            anchor='center'
          >
            <CircleIconWithBorder color={COLOR.SPARKY_RED_300} />
          </Marker>
        )}

        {/* HEX LAYERS */}
        {!isMarketOpportunityDataLoading && (
          <Source
            id={activeMapMetric}
            key={activeMapMetric}
            type='geojson'
            data={returnGeoJSON(
              marketOpportunityData.mapData[activeMapMetric as MarketOpportunityMetricNames]
            )}
          >
            {/* OUTLINE */}
            <Layer
              {...{
                type: 'line',
                paint: {
                  'line-color': mapColor,
                  'line-width': 1,
                  'line-opacity': {
                    type: 'interval',
                    property: 'value',
                    stops: [
                      [layerIntervals[0], 0.2],
                      [layerIntervals[1], 0.3],
                      [layerIntervals[2], 0.4],
                      [layerIntervals[3], 0.5],
                      [layerIntervals[4], 0.75],
                      [layerIntervals[5], 0.8],
                    ],
                  },
                },
              }}
            />
            {/* FILL */}
            <Layer
              {...{
                type: 'fill',
                paint: {
                  'fill-color': mapColor,
                  'fill-opacity': {
                    type: 'interval',
                    property: 'value',
                    stops: [
                      [layerIntervals[0], 0.15],
                      [layerIntervals[1], 0.25],
                      [layerIntervals[2], 0.35],
                      [layerIntervals[3], 0.45],
                      [layerIntervals[4], 0.55],
                      [layerIntervals[5], 0.6],
                    ],
                  },
                },
              }}
            />
          </Source>
        )}
      </Map>
    </div>
  );
}

export function returnLayerIntervals(data: MapData) {
  const average = data.data.reduce((a, b) => a + b.value, 0) / data.data.length;
  return [average / 4, average / 2, average, average * 2, average * 4, average * 10];
}

export function returnGeoJSON(data: MapData) {
  const geoData = data.data.map((hex) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [cellToBoundary(hex.hex, true)],
      },
      properties: { value: hex.value },
    };
  });

  return {
    type: 'FeatureCollection',
    features: geoData,
  } as FeatureCollection<Geometry, GeoJsonProperties>;
}

/* Popoever no goes static in x when approaching the right side */
export function popupLeftPositionFilter(x: number | undefined, xMax: number | undefined) {
  if (!x || !xMax) {
    return 0;
  }
  if (x > xMax - 230) {
    return xMax - 225;
  }
  return x;
}

/* Popoever no goes static in Y when approaching the bottom */
export function popupBottomPositionFilter(y: number | undefined, yMax: number | undefined) {
  if (!y || !yMax) {
    return 0;
  }
  if (y > yMax - 38) {
    return yMax - 35;
  }
  return y;
}
