import { useEffect, useMemo, useRef, useState } from 'react';

import {
  IconArrowLeft,
  IconCarGarage,
  IconInfoCircleFilled,
  IconStar,
  IconThumbUp,
} from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { capitalize } from 'lodash';
import Card from 'Sparky/Card';
import Chip from 'Sparky/Chip';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Spacer from 'Sparky/Spacer';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import TextToggle from 'Sparky/TextToggle';
import Tooltip from 'Sparky/Tooltip';
import {
  selectedOptionAtom,
  selectedSegmentAtom,
  starredSegmentListAtom,
} from 'store/selectSegmentAtom';

import useMarketSegments, {
  MarketSegmentsResponse,
  SegmentData,
} from 'components/Segments/api/useMarketSegments/useMarketSegments';
import segmentDeserializer from 'components/Segments/segmentDeserializer';
import SegmentsSearch from 'components/Segments/SegmentsSearch';
import SegmentsTile from 'components/Segments/SegmentsTile';
import { getVehicleSegments } from 'pages/Analytics/MarketOpportunity/api/useMarketOpportunity/useMarketOpportunity';

import styles from './SegmentPopper.module.scss';

type SegmentFilterOption = 'recommended' | 'favorites' | 'make' | 'model' | 'bodystyle' | '';

export default function SegmentPopper() {
  const [selectedSegment, setSelectedSegment] = useAtom(selectedSegmentAtom);
  const [selectedOption, setSelectedOption] = useAtom(selectedOptionAtom);
  const currentSegmentBreakdown = getVehicleSegments(selectedOption, selectedSegment);
  const { data } = useMarketSegments();
  const [toggle, setToggle] = useState<string>();
  const [filterOption, setFilterOption] = useState<SegmentFilterOption>('recommended');
  const [searchText, setSearchText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SegmentData[]>([]);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const { flattenedSegments, segments } = segmentDeserializer(data as MarketSegmentsResponse);
  const recommendedSegments = flattenedSegments.slice(0, 10);
  const [favouritedSegmentsList, setFavouritedSegmentsList] = useAtom(starredSegmentListAtom);
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(window.innerWidth);

  const favouritedSegments = flattenedSegments.filter((segment) => segment.favorite);

  const currentSegment = useMemo(() => {
    return flattenedSegments.find((seg) => seg.segment === selectedSegment);
  }, [selectedSegment, flattenedSegments]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (
      favouritedSegmentsList.join() !== favouritedSegments.map((segment) => segment.segment).join()
    ) {
      setFavouritedSegmentsList(favouritedSegments.map((segment) => segment.segment));
    }
  }, [favouritedSegments]);

  useEffect(() => {
    if (searchText.length < 1) {
      setShowSearchResults(false);
    }
  }, [searchText]);

  useEffect(() => {
    if (currentSegmentBreakdown.condition !== toggle) {
      setToggle(currentSegmentBreakdown.condition);
    }
  }, [currentSegmentBreakdown]);

  const filterArray = useMemo(() => {
    if (filterOption === 'make') {
      return segments['CONDITION|MAKE'].filter((seg) => seg.segment.split('|')[0] === toggle);
    }
    if (filterOption === 'model') {
      return segments['CONDITION|MAKE|MODEL'].filter((seg) => seg.segment.split('|')[0] === toggle);
    }
    if (filterOption === 'bodystyle') {
      return segments['CONDITION|BODYSTYLE'].filter((seg) => seg.segment.split('|')[0] === toggle);
    }
    return [];
  }, [filterOption, segments, toggle]);

  const handleSegmentTileClick = (segment: SegmentData) => {
    ref.current?.scrollTo(0, 0);
    setSelectedOption(segment.option || 'CONDITION');
    setSelectedSegment(segment.segment || 'NEW');
  };

  return (
    <div className={styles.segmentPopperWrapper}>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        columnGap='8px'
        padding='16px 16px 12px'
      >
        <Flex columnGap='8px' alignItems='center'>
          <IconCarGarage size={24} />
          <Text type='heading-03' textWrap='nowrap'>
            Segment selection
          </Text>
        </Flex>
        <Flex alignItems='center' columnGap='8px'>
          {width > 728 && <Text type='label-03'>Condition</Text>}
          <TextToggle
            items={[
              {
                label: 'New',
                value: 'NEW',
              },
              {
                label: 'Used',
                value: 'USED',
              },
            ]}
            booleanToggle
            value={toggle}
            setValue={(val) => {
              setSelectedOption('CONDITION');
              setSelectedSegment(val);
              setToggle(val);
            }}
          />
        </Flex>
      </Flex>
      <div ref={ref} className={styles.segmentContent}>
        <Flex column rowGap='12px'>
          {selectedOption !== 'CONDITION' ? (
            <Flex columnGap='12px' alignItems='center' padding='0 0 12px'>
              <Text type='body-compact-01' color='secondary' textWrap='nowrap'>
                Currently applied:
              </Text>
              <Chip
                variant='filled'
                handleDismiss={() => {
                  setSelectedOption('CONDITION');
                  setSelectedSegment(currentSegmentBreakdown.condition || 'NEW');
                }}
              >
                {currentSegment?.segment.replaceAll('|', ', ')}
              </Chip>
            </Flex>
          ) : (
            <Text type='body-compact-01' color='secondary' className={styles.segmentSelectionText}>
              Find and select a segment of your inventory to analyze
            </Text>
          )}
          <SegmentsSearch
            className={styles.searchField}
            searchText={searchText}
            placeholder={
              width <= 728
                ? 'Search by make, model or bodystyle'
                : 'Search by a specific, make, model or bodystyle'
            }
            setSearchText={setSearchText}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            setShowSearchResults={setShowSearchResults}
          />
          <Flex>
            <Flex
              className={styles.rightDivider}
              columnGap='4px'
              margin='0 8px 0 0'
              padding='0 8px 0 0'
            >
              <Chip
                variant={filterOption === 'make' && !showSearchResults ? 'selected' : 'notSelected'}
                shape='tag'
                color='grey'
                handleClick={() => {
                  setFilterOption('make');
                  setSearchText('');
                  setShowSearchResults(false);
                }}
              >
                Makes
              </Chip>
              <Chip
                variant={
                  filterOption === 'model' && !showSearchResults ? 'selected' : 'notSelected'
                }
                shape='tag'
                color='grey'
                handleClick={() => {
                  setFilterOption('model');
                  setSearchText('');
                  setShowSearchResults(false);
                }}
              >
                Models
              </Chip>
              <Chip
                variant={
                  filterOption === 'bodystyle' && !showSearchResults ? 'selected' : 'notSelected'
                }
                shape='tag'
                color='grey'
                handleClick={() => {
                  setFilterOption('bodystyle');
                  setSearchText('');
                  setShowSearchResults(false);
                }}
              >
                Body styles
              </Chip>
            </Flex>
            <Flex columnGap='4px'>
              <Chip
                variant={
                  filterOption === 'recommended' && !showSearchResults ? 'selected' : 'notSelected'
                }
                leftIcon={<IconThumbUp size={16} />}
                handleClick={() => {
                  setFilterOption('recommended');
                  setSearchText('');
                  setShowSearchResults(false);
                }}
                shape='tag'
                color='grey'
                tooltip={width <= 728 ? 'Recommended' : undefined}
              >
                {width <= 728 ? undefined : 'Recommended'}
              </Chip>
              <Chip
                variant={
                  filterOption === 'favorites' && !showSearchResults ? 'selected' : 'notSelected'
                }
                leftIcon={<IconStar size={16} />}
                handleClick={() => {
                  setFilterOption('favorites');
                  setSearchText('');
                  setShowSearchResults(false);
                }}
                shape='tag'
                color='grey'
                tooltip={width <= 728 ? 'Favorites' : undefined}
              >
                {width <= 728 ? undefined : 'Favorites'}
              </Chip>
            </Flex>
          </Flex>
        </Flex>
        {showSearchResults ? (
          <Card
            heading={
              <Flex width='100%' column={false} columnGap='4px' alignItems='center'>
                <IconButton
                  aria-label='clear search results'
                  onClick={() => {
                    setSearchText('');
                    setShowSearchResults(false);
                  }}
                >
                  <IconArrowLeft size={16} />
                </IconButton>
                <Text textWrap='nowrap'>
                  {searchResults.length} {searchResults.length === 1 ? 'Result' : 'Results'}
                </Text>
              </Flex>
            }
            parent='popover'
          >
            {searchResults.length > 0 ? (
              <div className={styles.segmentTileSection}>
                {searchResults.map((segment) => (
                  <SegmentsTile
                    key={segment.segment}
                    segment={segment.segment}
                    riskScore={segment.riskScore as number}
                    numberOfVins={segment.totalUnits}
                    favorited={segment.favorite || false}
                    handleClick={() => handleSegmentTileClick(segment)}
                  />
                ))}
              </div>
            ) : (
              <Flex width='100%' justifyContent='center'>
                <Text color='tertiary'>No search results for "{searchText}"</Text>
              </Flex>
            )}
          </Card>
        ) : (
          <>
            {/* Make | Model | Bodystyle */}
            {['make', 'model', 'bodystyle'].includes(filterOption) && (
              <Card
                heading={`${capitalize(toggle)}, ${capitalize(filterOption)} (${
                  filterArray.length
                })`}
                parent='popover'
              >
                <div className={styles.segmentTileSection}>
                  {filterArray.map((segment: SegmentData) => (
                    <SegmentsTile
                      key={segment.segment}
                      segment={segment.segment}
                      riskScore={segment.riskScore as number}
                      numberOfVins={segment.totalUnits}
                      favorited={segment.favorite || false}
                      handleClick={() => handleSegmentTileClick(segment)}
                    />
                  ))}
                </div>
              </Card>
            )}
            {/* Favorites */}
            {filterOption === 'favorites' && (
              <Card heading={`Favorites (${favouritedSegments.length})`} parent='popover'>
                <div className={styles.segmentTileSection}>
                  {favouritedSegments.map((segment) => (
                    <SegmentsTile
                      key={segment.segment}
                      segment={segment.segment}
                      riskScore={segment.riskScore as number}
                      numberOfVins={segment.totalUnits}
                      favorited={segment.favorite || false}
                      handleClick={() => handleSegmentTileClick(segment)}
                    />
                  ))}
                </div>
              </Card>
            )}
            {/* Recommended */}
            {filterOption === 'recommended' && (
              <Card
                heading={
                  <>
                    <Text>Recommended ({recommendedSegments.length})</Text>
                    <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
                    <Tooltip
                      side='left'
                      content='We’ve identified that these segments are of interest and should be reviewed'
                    >
                      <Flex alignItems='center' justifyContent='center'>
                        <IconInfoCircleFilled color={COLOR.SPARKY_GREY_400} size={16} />
                      </Flex>
                    </Tooltip>
                  </>
                }
                parent='popover'
              >
                <div className={styles.segmentTileSection}>
                  {recommendedSegments.map((segment) => (
                    <SegmentsTile
                      key={segment.segment}
                      segment={segment.segment}
                      riskScore={segment.riskScore as number}
                      numberOfVins={segment.totalUnits}
                      favorited={segment.favorite || false}
                      handleClick={() => handleSegmentTileClick(segment)}
                    />
                  ))}
                </div>
              </Card>
            )}
          </>
        )}
      </div>
    </div>
  );
}
