export function convertESTToLocal(
  estTime: string | undefined,
  isHour12: boolean,
  showSeconds?: boolean
) {
  if (!estTime) {
    return undefined;
  }
  // Create a Date object with the EST time (assume EST is UTC-5)
  const estDate = new Date(`${estTime} GMT-0500`);

  const targetTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Options for formatting the time in the target timezone (e.g., "10 PM")
  const timeOptions: Intl.DateTimeFormatOptions = {
    second: showSeconds ? '2-digit' : undefined, // Second (e.g., "00")
    hour: 'numeric', // 1-12
    minute: 'numeric', // 00-59
    hour12: isHour12, // Use 12-hour format with AM/PM
    timeZone: targetTimezone, // Target timezone
  };

  // Options for formatting the date in the target timezone (e.g., "February 27")
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'long', // Full month name (e.g., "February")
    day: 'numeric', // Day of the month (e.g., "27")
    timeZone: targetTimezone, // Target timezone
  };

  // Format the time in the target timezone (e.g., "10 PM" or "10:00:00")
  const timeString = new Intl.DateTimeFormat('en-US', timeOptions).format(estDate);

  // Format the date in the target timezone (e.g., "February 27")
  const dateString = new Intl.DateTimeFormat('en-US', dateOptions).format(estDate);

  // Add the ordinal suffix to the day (e.g., "27" -> "27th")
  const day = new Date(dateString).getDate();
  const ordinalSuffix = getOrdinalSuffix(day);
  const dateWithOrdinal = dateString.replace(day.toString(), `${day}${ordinalSuffix}`);

  // Get the timezone abbreviation (e.g., "PST", "IST")
  const timezoneAbbr = getTimezoneAbbreviation(targetTimezone);

  // return the time, timezone abbreviation, and date
  return {
    dateWithOrdinal,
    timeString,
    timezoneAbbr,
  };
}

// Helper function to get the ordinal suffix (e.g., "th", "st", "nd", "rd")
function getOrdinalSuffix(day: number): string {
  if (day > 3 && day < 21) {
    return 'th';
  } // 11th, 12th, 13th, etc.
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

// Helper function to get the timezone abbreviation (e.g., "PST", "IST")
function getTimezoneAbbreviation(timezone: string) {
  const date = new Date();
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    timeZoneName: 'short',
  });
  const parts = formatter.formatToParts(date);
  const timezonePart = parts.find((part) => part.type === 'timeZoneName');
  return timezonePart ? timezonePart.value : 'Unknown';
}
