import React, { CSSProperties, useState } from 'react';

import { animated, useSpring } from '@react-spring/web';
import classnames from 'classnames';
import { springConfigs } from 'Sparky/springConfigs';
import Tooltip from 'Sparky/Tooltip';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'type'> {
  width?: string;
  variants?: 'default' | 'error';
  indeterminate?: boolean;
  smallHover?: boolean;
  tooltip?: string;
}

/**
 * Checkbox
 */
const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      width,
      variants = 'default',
      disabled = false,
      indeterminate = false,
      smallHover = false,
      checked,
      onChange,
      onKeyDown,
      className,
      defaultChecked,
      tooltip,
      ...inputProps
    },
    ref
  ) => {
    // If the checkbox is controlled (the `checked` prop is passed in), we can use that
    // Otherwise, store the checked state internally, and get updated values from the input element
    const isControlled = typeof checked === 'boolean';
    const [isCheckedInternal, setIsCheckedInternal] = useState(defaultChecked);
    const isChecked = isControlled ? checked : isCheckedInternal;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isControlled) {
        setIsCheckedInternal(e.target.checked);
      }

      if (onChange) {
        onChange(e);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isControlled && e.code === 'Enter') {
        setIsCheckedInternal(!isCheckedInternal);
      }

      if (onKeyDown) {
        onKeyDown(e);
      }
    };

    const { x } = useSpring({
      x: isChecked || indeterminate ? 1 : 0,
      config: springConfigs.quick,
    });

    return (
      <div
        className={classnames({
          [styles.checkboxContainer]: true,
          [styles.defaultHoverState]: !smallHover,
          [styles.smallHoverState]: smallHover,
          [styles.error]: !disabled && !checked && variants === 'error',
        })}
        style={{ '--checkbox-width': width } as CSSProperties}
        data-disabled={disabled}
      >
        <Tooltip content={tooltip} disabled={!tooltip}>
          <input
            ref={ref}
            disabled={disabled}
            type='checkbox'
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => handleChange(e)}
            checked={indeterminate ? false : checked}
            aria-checked={indeterminate ? 'mixed' : isChecked}
            defaultChecked={defaultChecked}
            {...inputProps}
          />
        </Tooltip>
        <div className={styles.iconWrapper}>
          <svg
            className={classnames(
              {
                [styles.checkbox]: true,
                [styles.active]: isChecked || indeterminate,
                [styles.inactive]: !(isChecked || indeterminate),
                [styles.disabed]: disabled && (isChecked || indeterminate),
              },
              className
            )}
            viewBox='-2 0 19 10'
            fill='none'
            aria-hidden
          >
            {!indeterminate && isChecked && (
              <animated.path
                data-testid='checkmark'
                d='M1 4.5L5 9L14 1'
                strokeDasharray={20}
                strokeDashoffset={x.to((x) => (1 - x) * 20)}
              />
            )}

            {indeterminate && (
              <animated.line
                data-testid='dash'
                x1='2'
                y1='5'
                x2='13'
                y2='5'
                strokeDasharray={11}
                strokeDashoffset={x.to((x) => (1 - x) * 11)}
              />
            )}
          </svg>
        </div>
      </div>
    );
  }
);

export default Checkbox;
