import React, { useRef, useState } from 'react';

import { IconSend2 } from '@tabler/icons-react';
import { produce } from 'immer';
import Spacer from 'Sparky/Spacer';
import Spinner from 'Sparky/Spinner';
import { COLOR } from 'Sparky/styles/vars';
import TextInput from 'Sparky/TextInput';

import useInquiry from 'components/Chat/api/inquiry/useInquiry';
import ChatBubble from 'components/Chat/components/ChatBubble';
import Examples from 'components/Chat/components/Examples';
import SuggestionsPanel from 'components/Chat/components/SuggestionsPanel';

import styles from './Chat.module.scss';

interface ChatEntry {
  key: string;
  question: string;
  responseId?: number;
  answer?: string;
}

export interface ChatProps {
  sessionId: string;
  storybook?: boolean;
}

/** Vinny chat interface */
export default function Chat({ sessionId, storybook }: ChatProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const chatPageRef = useRef<HTMLDivElement>(null);
  const [history, setHistory] = useState<ChatEntry[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>();
  const mutation = useInquiry();

  const scrollDown = () =>
    chatPageRef.current?.scrollTo({ top: Number.MAX_SAFE_INTEGER, behavior: 'smooth' });

  const addChatEntry = (entry: ChatEntry) => {
    setHistory(
      produce((draft) => {
        draft.push(entry);
      })
    );
  };

  const addResponse = (key: string, response: ChatEntry) => {
    setHistory(
      produce((draft) => {
        let msg = draft.find((item) => item.key === key);
        if (msg) {
          // If the user's question matches the question in the response, show the response in the same ChatBubble
          // Otherwise, add a new ChatBubble for the interpreted question and response
          if (msg.question === response.question) {
            Object.assign(msg, response);
          } else {
            Object.assign(msg, {
              answer: [
                {
                  type: 'TEXT',
                  format: 'NONE',
                  value: "Good question, I’m not sure about that. But here's a similar question...",
                },
              ],
            });
            draft.push({ ...response, key: getKey() });
          }
        }
      })
    );
    scrollDown();
  };

  const addError = (key: string, message: string) => {
    setHistory(
      produce((draft) => {
        let msg = draft.find((item) => item.key === key);
        if (msg) {
          Object.assign(msg, { answer: message });
        }
      })
    );
    scrollDown();
  };

  const submitMessage = (text: string) => {
    // Reset the last submit suggested questions
    setSuggestions(undefined);

    const key = getKey();
    addChatEntry({
      key,
      question: text,
    });
    mutation.mutate(
      { message: text, sessionId, tone: 'normal' },
      {
        onSuccess: (data) => {
          addResponse(key, {
            key,
            question: data.inquiry,
            responseId: data.response_id,
            answer: data.response,
          });
          if (data.suggested_prompts.length) {
            setSuggestions(data.suggested_prompts);
          }
        },
        onError: () => {
          addError(key, 'Sorry, something went wrong');
        },
      }
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (mutation.isLoading) {
      return;
    }
    if (inputRef.current?.value) {
      const text = inputRef.current.value;
      inputRef.current.value = '';
      submitMessage(text);
    }
  };

  return (
    <div className={styles.chatPage} ref={chatPageRef}>
      <div className={styles.centreContent}>
        <div className={styles.chat}>
          {history.map((message) => (
            <ChatBubble
              key={message.key}
              question={message.question}
              answer={message.answer}
              responseId={message.responseId}
              onDoneTyping={() => scrollDown()}
            />
          ))}
          {suggestions && (
            <SuggestionsPanel
              list={suggestions}
              onSuggestionClicked={(val) => {
                setSuggestions(undefined);
                submitMessage(val);
              }}
              onDismiss={() => setSuggestions(undefined)}
            />
          )}
          {history.length === 0 && (
            <Examples storybook={storybook} onClick={(text) => submitMessage(text)} />
          )}
        </div>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <TextInput
          ref={inputRef}
          className={styles.input}
          placeholder='Send a message...'
          autoFocus
          rightElement={
            mutation.isLoading ? (
              <Spinner className={styles.sendIcon} color={COLOR.SPARKY_GREY_400} />
            ) : (
              <span />
            )
          }
          id='search message'
        />
        <Spacer axis='horizontal' size='var(--spacing-16)' />
        <button aria-label='Submit message' type='submit' className={styles.submitButton}>
          <IconSend2 size='24' />
        </button>
      </form>
    </div>
  );
}

function getKey() {
  return Date.now().toString(16);
}
