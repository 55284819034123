import {
  IconChevronLeft,
  IconChevronLeftPipe,
  IconChevronRight,
  IconChevronRightPipe,
} from '@tabler/icons-react';
import classnames from 'classnames';
import IconButton from 'Sparky/IconButton';
import SelectInput from 'Sparky/SelectInput';
import Spacer from 'Sparky/Spacer';
import { COLOR, SPACING } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import styles from './TablePagination.module.scss';
import { useTableContext } from '../TableContext';

export interface TablePaginationProps {
  showRowPerPageSelection?: boolean;
  rowsPerPageOptions?: (number | { value: number; label: string })[];
  className?: string;
  size?: 'sm' | 'md';
}

/**
 * Defines the options in the "Rows per page" dropdown.
 * `-1` is used to show all options on one page.
 * eg: `{ value: -1, label: 'All' }`
 */
const defaultPageLengths = [10, 25, 50, 100, 250];

/**
 * TablePagination
 */
export default function TablePagination({
  showRowPerPageSelection = true,
  rowsPerPageOptions = defaultPageLengths,
  className,
  size = 'md',
}: TablePaginationProps) {
  const {
    allData,
    currentPage,
    nextPage,
    prevPage,
    firstPage,
    lastPage,
    pageSize,
    setPageSize,
    disablePrev,
    disableNext,
  } = useTableContext();

  const firstItem = pageSize * currentPage + 1;
  const lastItem =
    pageSize === -1
      ? allData.length
      : Math.min(pageSize * currentPage + pageSize, allData.length ?? 0);

  return (
    <div className={classnames([styles.tablePagination, className])}>
      {showRowPerPageSelection && (
        <>
          <Text id='page-size-label' size={size === 'sm' ? '14' : '16'}>
            Rows per page
          </Text>
          <SelectInput
            aria-labelledby='page-size-label'
            value={pageSize.toString()}
            onValueChange={(key) => setPageSize(parseInt(key as string))}
            options={rowsPerPageOptions.map((i) => ({ value: i.toString(), label: i.toString() }))}
            id='page-size-label'
            className={styles.pageSelectInput}
            size={size}
          />
          <Spacer axis='horizontal' size={SPACING.SPACING_32} />
        </>
      )}

      {allData.length && (
        <Text fontWeight={7} key={`${new Date()}`} size={size === 'sm' ? '14' : '16'}>
          Rows {firstItem}–{lastItem} of {allData.length}
        </Text>
      )}
      <Spacer axis='horizontal' size={SPACING.SPACING_32} />
      <IconButton aria-label='First page' disabled={disablePrev} onClick={firstPage} size={size}>
        <IconChevronLeftPipe
          size={16}
          color={disablePrev ? COLOR.SPARKY_GREY_400 : COLOR.SPARKY_NAVY_400}
        />
      </IconButton>
      <IconButton aria-label='Previous page' disabled={disablePrev} onClick={prevPage} size={size}>
        <IconChevronLeft
          size={16}
          color={disablePrev ? COLOR.SPARKY_GREY_400 : COLOR.SPARKY_NAVY_400}
        />
      </IconButton>
      <IconButton aria-label='Next page' disabled={disableNext} onClick={nextPage} size={size}>
        <IconChevronRight
          size={16}
          color={disableNext ? COLOR.SPARKY_GREY_400 : COLOR.SPARKY_NAVY_400}
        />
      </IconButton>
      <IconButton aria-label='Last page' disabled={disableNext} onClick={lastPage} size={size}>
        <IconChevronRightPipe
          size={16}
          color={disableNext ? COLOR.SPARKY_GREY_400 : COLOR.SPARKY_NAVY_400}
        />
      </IconButton>
    </div>
  );
}
