import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Skeleton from 'Sparky/Skeleton';
import Text from 'Sparky/Text';

import { NumberFormat } from 'types/NumberFormat';
import { formatNumberFormat } from 'util/formatNumberFormat';

import styles from './DoughnutChart.module.scss';

interface DonutChartProps {
  percent?: number;
  segment?: string;
  isLoading: boolean;
}

const COLORS = ['#e7f0fe', '#0D67F8'];

/** DonutChart */
export default function DonutChart({ percent, segment, isLoading }: DonutChartProps) {
  if (isLoading) {
    return <Skeleton height='100%' width='100%' />;
  }

  if (percent === undefined || !segment) {
    return <></>;
  }

  const data = [
    { value: 100 - percent * 100, name: 'inverse' },
    { value: percent * 100, name: '% of VINs viewed' },
  ];

  return (
    <div className={styles.doughnutChartContainer}>
      <div className={styles.doughnutChart}>
        <div className={styles.centeredText}>
          <Text fontWeight={5} size='32'>
            {formatNumberFormat(percent, NumberFormat.PERCENT, { percentDecimals: 0 })}
          </Text>
        </div>
        <ResponsiveContainer width='100%' height='100%' className='chromatic-ignore'>
          <PieChart>
            <Pie
              data={data}
              dataKey='value'
              nameKey='name'
              outerRadius='95%'
              innerRadius='75%'
              isAnimationActive={false}
              startAngle={-90}
              endAngle={270}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.legendContainer}>
        <div className={styles.legend}>
          <span className={styles.circle} />
          <div className={styles.legendText}>
            <Text size='12' color='secondary'>
              Your {segment} viewed
            </Text>
            <Text fontWeight={5}>
              {formatNumberFormat(percent, NumberFormat.PERCENT, { percentDecimals: 0 })}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
