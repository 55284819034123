import { atom } from 'jotai';

import { User } from 'types/User';
import * as LocalStorage from 'util/LocalStorage';

/** Use `userAtom` instead – this atom should only be used in tests, to set an initial value */
export const userAtom_INTERNAL = atom<User | undefined>(undefined);

/** The logged in user's data, undefined if not authenticated */
export const userAtom = atom<User | undefined, User | undefined>(
  (get) => get(userAtom_INTERNAL),
  (get, set, newData) => {
    set(userAtom_INTERNAL, newData);
    LocalStorage.setToken(newData?.token);
  }
);
