import classnames from 'classnames';
import dayjs from 'dayjs';
import {
  Bar,
  CartesianGrid,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { buildTicklist, customizedGroupTick } from 'Sparky/LineChart';
import Skeleton from 'Sparky/Skeleton';
import { FONT_SIZE } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import { NumberFormat } from 'types/NumberFormat';
import { formatNumberFormat } from 'util/formatNumberFormat';

import styles from './BarChart.module.scss';

export interface BarChartProps {
  format: NumberFormat;
  metaData: {
    [key: string]: {
      label: string;
      color: string;
    };
  };
  data?: {
    date: string;
    [key: string]: string | number | null;
  }[];
  isLoading: boolean;
}

/**
 * BarChart
 */
export default function BarChart({ data, format, metaData, isLoading }: BarChartProps) {
  if (isLoading) {
    return <Skeleton height='100%' width='100%' />;
  }
  if (!data) {
    return <></>;
  }
  // Add a `date` property to the data, so we can use a timescale x-axis
  const chartData = data.map((i) => ({
    ...i,
    unixDate: dayjs(i.date).unix(),
  }));

  // Guarentee sorted by Date
  const sortedData = chartData.sort((a, b) => a.unixDate - b.unixDate);

  const formatter = (val: number | string) => {
    if (typeof val === 'number') {
      return formatNumberFormat(val, format ?? NumberFormat.INT, {
        percentDecimals: 0,
        currencyShowDecimal: false,
      });
    }
    return '-';
  };

  const filteredMetaData: {
    [key: string]: {
      label: string;
      color: string;
    };
  } = {};

  Object.keys(metaData).forEach((key) => {
    const hasKey = data.filter((val) => {
      return val[key] !== null || val[key] === undefined;
    });
    if (hasKey.length && key in data[0]) {
      filteredMetaData[key] = metaData[key];
    }
  });

  if (!Object.keys(filteredMetaData).length) {
    return <></>;
  }

  return (
    <div className={styles.chartContainer}>
      {/* 
        This container setup with a relative and absolute container is required
        for the resizing behaviour to work with with recharts framework. 
        Known bug with package.    
      */}
      <div className={classnames([styles.relativeContainer])}>
        <div className={styles.absoluteContainer}>
          <ResponsiveContainer className={'chromatic-ignore'}>
            <RechartsBarChart style={{ backgroundColor: '#FFFFFF' }} data={sortedData}>
              <CartesianGrid vertical={false} stroke='#F3F3F3' />

              {Object.keys(metaData).map((key) => {
                return (
                  <Bar
                    key={key}
                    type='linear'
                    dataKey={key}
                    isAnimationActive={false}
                    fill={metaData[key].color}
                  />
                );
              })}

              <XAxis
                scale='time'
                type='number'
                domain={['dataMin', 'dataMax']}
                dataKey='unixDate'
                tick={customizedGroupTick}
                axisLine={{ strokeWidth: 0 }}
                ticks={buildTicklist(sortedData)}
                tickFormatter={(unixTime) => dayjs.unix(unixTime).format('DD')}
                tickLine={false}
              />

              <YAxis
                tick={{ fontSize: FONT_SIZE.FONT_12 }}
                tickLine={{ stroke: 'transparent' }}
                tickFormatter={formatter}
                orientation='left'
                domain={['auto', 'auto']}
                axisLine={{ strokeWidth: 0 }}
              />

              <Tooltip
                cursor={false}
                content={(payload) => {
                  if (!payload?.payload || !payload?.payload.length) {
                    return <></>;
                  }

                  return (
                    <div className={styles.tooltipContainer}>
                      <Text
                        className={classnames([styles.title, 'chromatic-ignore'])}
                        color='secondary'
                        size='14'
                      >
                        {dayjs(payload?.payload[0].payload.date).format('MMMM DD, YYYY')}
                      </Text>

                      {payload?.payload.map((tip, i) => {
                        const formattedValue = formatNumberFormat(
                          tip.value as number,
                          format ?? NumberFormat.INT
                        );
                        return (
                          <div key={i} className={styles.tooltipRow}>
                            <div className={styles.flexRow}>
                              <div
                                className={styles.colorCircle}
                                style={{ backgroundColor: tip.color }}
                              />
                              <Text size='14' className={styles.metricLabel} color='secondary'>
                                {metaData[tip.name as string].label}
                              </Text>
                            </div>
                            <Text size='14'>{formattedValue}</Text>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </RechartsBarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
