import { useState, useEffect } from 'react';

/** Like useState, but persists the value across sessions using LocalStorage */
export default function usePersistentState<T>(key: string, defaultValue: T): [T, (val: T) => void] {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key) ?? '';
    return safeParse(storedValue, defaultValue);
  });

  // Keep localStorage up-to-date with changes to the state
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  // Update state if the value is changed in another tab or window
  useEffect(() => {
    const handleStorageUpdate = (event: StorageEvent) => {
      if (event.key === key && event.newValue !== value) {
        const parsedValue = safeParse(event.newValue ?? '', defaultValue);
        setValue(parsedValue);
      }
    };

    window.addEventListener('storage', handleStorageUpdate);
    return () => window.removeEventListener('storage', handleStorageUpdate);
  }, [key, value, defaultValue]);
  return [value, setValue];
}

/**
 * Attempts to parse a JSON string, returns a default value instead of throwing an error if parsing fails
 * @param value - String to parse
 * @param defaultValue - Value to return if parsing fails
 */
function safeParse<T>(value: string, defaultValue: T) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
}
