import { ReactElement, useState } from 'react';

import { Link, Location, useLocation } from 'react-router-dom';
import Text from 'Sparky/Text';

import DropdownButton from 'components/DropdownButton/DropdownButton';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { FeatureFlag } from 'types/FeatureFlags';

import styles from './CampaignNavDropdown.module.scss';

/**
 * CampaignNavDropdown
 */
export default function CampaignNavDropdown() {
  const isActive = (location: Location) => location.pathname.includes('campaigns');
  const location = useLocation();
  const [openDropdownPopover, setOpenDropdownPopover] = useState(false);
  const showConversionReport = useFeatureFlag(FeatureFlag.ENABLE_CONVERSION_REPORT);
  const showCampaignDashboard = useFeatureFlag(FeatureFlag.ENABLE_DEALER_LANDING_PAGE);
  const showROIReport = useFeatureFlag(FeatureFlag.ENABLE_ROI_REPORT);

  return (
    <div className={styles.campaignNavDropdownWrapper}>
      <DropdownButton
        active={isActive(location)}
        title='Campaigns'
        isOpen={openDropdownPopover}
        setIsOpen={setOpenDropdownPopover}
      >
        <div className={styles.campaignNavDropdown}>
          {showCampaignDashboard && (
            <DropdownLink
              title='Overview'
              location='overview'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}
          {showConversionReport && (
            <DropdownLink
              title='Conversions'
              location='conversions'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}
          {showROIReport && (
            <DropdownLink
              title='ROI Report'
              location='roi_report'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}
        </div>
      </DropdownButton>
    </div>
  );
}

interface DropdownLinkProps {
  title: string;
  location: string;

  onLinkClick(): void;
}

function DropdownLink({ title, location, onLinkClick }: DropdownLinkProps): ReactElement {
  const [dealerId] = useSelectedDealerId();
  return (
    <Link
      className={styles.campaignLink}
      to={`campaigns/${location}${dealerId ? `?dealerId=${dealerId}` : ''}`}
      onClick={() => {
        onLinkClick();
      }}
    >
      <Text
        className={styles.campaignLinkText}
        fontWeight={window.location.toString().includes(location) ? 7 : 4}
      >
        {title}
      </Text>
    </Link>
  );
}
