import React from 'react';

export interface TableHeaderProps extends React.ComponentPropsWithRef<'th'> {
  children?: React.ReactNode;
  textAlign?: 'left' | 'right' | 'center';
}

/**
 * TableHeader
 */
export default function TableHeader({
  children,
  className,
  textAlign,
  style,
  ...otherProps
}: TableHeaderProps) {
  return (
    <th className={className} style={{ textAlign, ...style }} {...otherProps}>
      {children}
    </th>
  );
}
