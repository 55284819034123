import { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useAtom } from 'jotai';
import Markdown from 'react-markdown';
import { TypeAnimation } from 'react-type-animation';
import remarkGfm from 'remark-gfm';
import Button from 'Sparky/Button';
import Flex from 'Sparky/Flex';
import { LXBrainV2 } from 'Sparky/SVGs/LXBrainV2';
import Text from 'Sparky/Text';
import { v4 as uuid } from 'uuid';

import { FollowUp } from 'components/Chat/api/inquiry/useInquiry';
import { LinkKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import useFeatureFlag from 'hooks/useFeatureFlag';
import AnimatedResponse, { isRepsonseAnimationReadyAtom } from 'pages/LexChat/AnimatedResponse';
import { ChatHistory } from 'pages/LexChat/Chat/Chat';
import { FeedbackBar } from 'pages/LexChat/FeedbackBar/FeedbackBar';
import { userAtom } from 'store';
import { FeatureFlag } from 'types/FeatureFlags';

import styles from './ChatBody.module.scss';

export interface ChatBodyProps {
  history?: ChatHistory[];
  isLoading?: boolean;
  chatBodyRef: React.RefObject<HTMLDivElement>;
  scrollBodyRef: React.RefObject<HTMLDivElement>;
  handlePromptSubmit: (text: string) => void;
  followUp?: FollowUp | undefined;
  setFollowUp: React.Dispatch<React.SetStateAction<FollowUp | undefined>>;
  followUpUrl: FollowUp | undefined;

  setFollowUpUrl: React.Dispatch<React.SetStateAction<FollowUp | undefined>>;
}

/**
 * ChatBody
 */
export default function ChatBody({
  history,
  isLoading,
  chatBodyRef,
  scrollBodyRef,
  handlePromptSubmit,
  followUp,
  followUpUrl,
  setFollowUpUrl,
  setFollowUp,
}: ChatBodyProps) {
  const [user] = useAtom(userAtom);
  const enableAnimationFlag = useFeatureFlag(FeatureFlag.ENABLE_LEX_TYPING_EFFECT);
  const [isRepsonseAnimationReady] = useAtom(isRepsonseAnimationReadyAtom);
  // const [showFade, setShowFade] = useState(false);

  const UserPrompt = ({ prompt }: { prompt: ChatHistory }) => {
    return (
      <Flex justifyContent='flex-end'>
        <div className={styles.prompt}>
          <div className={styles.userIcon}>
            <span>{user && `${user.first.charAt(0)}${user.last.charAt(0)}`.toUpperCase()}</span>
          </div>
          <Flex margin='2px 0 0' flex='1'>
            <Text color='white'>{prompt?.prompt}</Text>
          </Flex>
        </div>
      </Flex>
    );
  };

  const Response = ({ resp }: { resp: ChatHistory }) => {
    return (
      <Flex column>
        <div className={styles.response}>
          <div className={styles.lexIcon}>
            <LXBrainV2 />
          </div>
          <div className={styles.markdownContainer}>
            {resp.response && <Markdown remarkPlugins={[remarkGfm]}>{resp.response}</Markdown>}
          </div>
        </div>
        <FeedbackBar responseId={resp.id || ''} response={resp.response} />
      </Flex>
    );
  };

  const FollowUpQuestion = () => {
    if (followUp && !isRepsonseAnimationReady) {
      return (
        <div className={classnames([styles.response, styles.followUp])}>
          <div className={styles.lexIcon}>
            <LXBrainV2 />
          </div>
          <Flex columnGap='8px' flex='1' alignItems='center'>
            <div>
              <Text fontWeight={4}>{followUp?.text}</Text>
            </div>
            <Button size='sm' variant='outline' onClick={() => setFollowUp(undefined)}>
              No
            </Button>
            <Button size='sm' variant='solid' onClick={() => handlePromptSubmit(followUp?.action)}>
              Yes
            </Button>
          </Flex>
        </div>
      );
    }
    return null;
  };

  const FollowUpUrl = () => {
    if (followUpUrl && !isRepsonseAnimationReady) {
      return (
        <div className={classnames([styles.response, styles.followUp])}>
          <div className={styles.lexIcon}>
            <LXBrainV2 />
          </div>
          <Flex columnGap='8px' flex='1' alignItems='center'>
            <div>
              <Text fontWeight={4}>{followUpUrl?.text}</Text>
            </div>
            <Button size='sm' variant='outline' onClick={() => setFollowUpUrl(undefined)}>
              No
            </Button>
            <LinkKeepParams to={followUpUrl?.action} target='_blank'>
              <Button size='sm' variant='solid'>
                Yes
              </Button>
            </LinkKeepParams>
          </Flex>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (FollowUpUrl !== null && followUpUrl) {
      scrollBodyRef.current?.scrollTo({ top: Number.MAX_SAFE_INTEGER, behavior: 'smooth' });
    }
  }, [isRepsonseAnimationReady]);

  const handleScroll = () => {
    // TODO: this causes a refresh on scroll that rerenders all children
    // setShowFade(!!(scrollBodyRef.current?.scrollTop && scrollBodyRef.current?.scrollTop > 10));
  };

  return (
    <div className={styles.chatBody} ref={chatBodyRef}>
      <div className={styles.innerChatBody} ref={scrollBodyRef} onScrollCapture={handleScroll}>
        {history?.map((hist, i) => {
          if (hist.prompt) {
            return <UserPrompt key={uuid()} prompt={hist} />;
          }
          if (hist.response) {
            if (i === history.length - 1 && isRepsonseAnimationReady && enableAnimationFlag) {
              return [<AnimatedResponse key={uuid()} resp={hist} />];
            } else {
              return <Response key={hist?.id} resp={hist} />;
            }
          }
          return <></>;
        })}
        {isLoading && <Thinking />}
        {followUp && <FollowUpQuestion />}
        {followUpUrl && <FollowUpUrl />}
      </div>
    </div>
  );
}

export function Thinking() {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  return (
    <div className={styles.response}>
      <div className={styles.lexIcon}>
        <div className={styles.circle} />
        <div className={styles.circle} />
        <div className={styles.circle} />
        <LXBrainV2 />
      </div>
      <div className={styles.animationContainer}>
        <TypeAnimation
          sequence={['Let me think about that', () => setIsAnimationComplete(true)]}
          speed={75}
          wrapper='span'
          cursor={false}
        />
        {isAnimationComplete && (
          <div className={styles.loader}>
            <span className={styles.loaderDot}>.</span>
            <span className={styles.loaderDot}>.</span>
            <span className={styles.loaderDot}>.</span>
          </div>
        )}
      </div>
    </div>
  );
}
