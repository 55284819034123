import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconLockFilled, IconLockOpen } from '@tabler/icons-react';
import classnames from 'classnames';
import IconButton from 'Sparky/IconButton';
import Spacer from 'Sparky/Spacer';
import Text from 'Sparky/Text';

import { DragHandle } from 'pages/Inventory/InventoryPage/components/DragHandle';
import { InventoryTableColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableColumnDef } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';

import styles from './DraggableColumn.module.scss';

export interface DraggableColumnProps {
  column: InventoryTableColumnKey;
  isFrozen: boolean;
  dragOverlay?: boolean;
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>;

  handleFreeze?(): void;
}

/**
 * SortableColumn
 */
export default function DraggableColumn({
  column,
  isFrozen,
  dragOverlay,
  inventoryTableColumnDefs,
  handleFreeze,
}: DraggableColumnProps) {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: column });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={classnames([
        styles.draggableColumn,
        isDragging && styles.isDragging,
        dragOverlay && styles.dragOverlay,
      ])}
    >
      <DragHandle aria-label={column} ref={setActivatorNodeRef} {...attributes} {...listeners} />
      <Text size='14' className={styles.label}>
        {inventoryTableColumnDefs[column].pillLabel}
      </Text>
      <Spacer axis='horizontal' style={{ margin: '0 auto' }} />

      {isFrozen ? (
        <IconButton aria-label='lock' size='md' tooltip='Unlock' onClick={handleFreeze}>
          <IconLockFilled />
        </IconButton>
      ) : (
        <IconButton aria-label='unlock' size='md' tooltip='Lock' onClick={handleFreeze}>
          <IconLockOpen />
        </IconButton>
      )}
    </div>
  );
}
