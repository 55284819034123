import React, { CSSProperties, useState } from 'react';

import classnames from 'classnames';

import styles from './Radio.module.scss';

export interface RadioProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'type'> {
  children?: React.ReactNode;
  value?: string;
  smallHover?: boolean;
  noHover?: boolean;
  width?: string;
}

/**
 * Radio
 */
const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      disabled = false,
      checked,
      onChange,
      onKeyPress,
      className,
      defaultChecked,
      name,
      value,
      smallHover = false,
      noHover = false,
      width,
      ...inputProps
    },
    ref
  ) => {
    const isControlled = typeof checked === 'boolean';
    const [isCheckedInternal, setIsCheckedInternal] = useState(defaultChecked);
    const isChecked = isControlled ? checked : isCheckedInternal;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isControlled) {
        setIsCheckedInternal(e.target.checked);
      }

      if (onChange) {
        onChange(e);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isControlled && e.code === 'Enter') {
        setIsCheckedInternal(!isCheckedInternal);
      }

      if (onKeyPress) {
        onKeyPress(e);
      }
    };

    return (
      <input
        type='radio'
        className={classnames([
          {
            [styles.radio]: true,
            [styles.smallHover]: smallHover,
            [styles.noHover]: noHover,
            [styles.disabled]: disabled,
            [styles.active]: isChecked,
          },
          className,
        ])}
        style={{ '--radio-width': width } as CSSProperties}
        ref={ref}
        disabled={disabled}
        name={name}
        value={value}
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        checked={checked}
        defaultChecked={defaultChecked}
        {...inputProps}
      />
    );
  }
);
export default Radio;
