import unfetch from 'unfetch';

import { ErrorResponse, APIError } from 'api/utils/index';

interface FetchOptions {
  headers?: Record<string, string>;
  body?: object;
  /** URL query params (`?foo=bar`) */
  params?: Record<string, string | number | undefined | boolean>;
}

/**
 * Make a POST request
 */
export async function post<T>(url: string, options?: FetchOptions): Promise<T> {
  return fetch<T>('POST', url, options);
}

/**
 * Make a PATCH request
 */
export async function patch<T>(url: string, options?: FetchOptions): Promise<T> {
  return fetch<T>('PATCH', url, options);
}

/**
 * Make a GET request
 */
export async function get<T>(url: string, options?: FetchOptions): Promise<T> {
  return fetch<T>('GET', url, options);
}

export async function fetch<T>(method: string, url: string, options?: FetchOptions): Promise<T> {
  let fullURL = url;
  if (options?.params) {
    const paramString = Object.entries(options.params)
      .filter(([, val]) => val !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    fullURL += `?${paramString}`;
  }
  let res = null;
  // If the body is Formdata then don't stringify and remove Content-Type header
  if (options?.body instanceof FormData) {
    res = await unfetch(fullURL, {
      method,
      headers: {
        appId: 'Turn4',
        ...options?.headers,
      },
      body: options?.body ? options.body : undefined,
    });
  } else {
    res = await unfetch(fullURL, {
      method,
      headers: {
        appId: 'Turn4',
        'Content-Type': 'application/json',
        ...options?.headers,
      },
      body: options?.body ? JSON.stringify({ ...options.body }) : undefined,
    });
  }
  if (!res.ok) {
    let errorData: ErrorResponse | undefined;
    try {
      errorData = await res.json();
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (errorData) {
      const subCodeText = errorData.subCode ? ` (Error ${errorData.subCode})` : '';
      throw new APIError(`${errorData.message}${subCodeText}`, errorData.subCode);
    }

    const message = res.statusText ? `: ${res.statusText}` : '';
    throw new Error(`Request failed (${res.status}${message})`);
  }

  return res.json();
}
