import { Key } from 'react';

import Button from 'Sparky/Button';
import Skeleton from 'Sparky/Skeleton';
import Text from 'Sparky/Text';

import { CTAFilterConfig } from 'pages/Inventory/InventoryPage/HighlightsToggle/CTAFilters/useCTAFilters';

import styles from './CTAFilter.module.scss';

export interface CTAFilterProps {
  config: CTAFilterConfig<unknown>;
  matchCount?: number;
  handleChange(key: Key): void;
  active: boolean;
  isLoading: boolean;
}

/** CTA Filter */
export default function CTAFilter({
  config,
  matchCount,
  handleChange,
  active,
  isLoading,
}: CTAFilterProps) {
  return (
    <div className={styles.CTAFilter}>
      <Text size='18' fontWeight={7}>
        {isLoading ? <Skeleton height='1em' width='3em' /> : matchCount}
      </Text>
      <Text size='18' fontWeight={7} className={styles.filterLabel}>
        {config.label}
      </Text>
      <Button
        disabled={isLoading}
        onClick={() => handleChange(config.id)}
        variant={active ? 'outline' : 'solid'}
      >
        {active ? 'Clear' : config.buttonLabel}
      </Button>
    </div>
  );
}
