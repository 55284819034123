import { z } from 'zod';

export const segmentDataSchema = z.object({
  segment: z.string(),
  totalUnits: z.number(),
  pctOfInventory: z.number(),
  pctInCampaign: z.number(),
  pctOfMediaRisk: z.number(),
  highMediaRiskFlag: z.boolean().optional(),
  riskScore: z.number(),
});

export const marketSegmentsResponseSchema = z
  .record(z.union([z.array(segmentDataSchema), z.array(z.string())]))
  .and(
    z.object({
      favorites: z.array(z.string()),
    })
  );
