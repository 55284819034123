import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import produce from 'immer';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { cloneDeep } from 'lodash';

import {
  FiltersState,
  FilterType,
  SelectState,
  StaticSelectFilterConfig,
} from 'components/Filters';
import { InventoryFilterConfigs } from 'pages/Inventory/InventoryPage/InventoryFilters';
import { InventoryItem } from 'types/InventoryItem';

/** Stores the state of Inventory Filters for each dealerId */
export const inventoryFilterAtom = atomWithStorage<
  Record<number | string, FiltersState | undefined>
>('inventoryFilter', {});

/** Get/Set the value of `inventoryFilterAtom` for a single dealer */
export function useInventoryFilterAtom(
  dealerId?: number | string
): [FiltersState | undefined, Dispatch<SetStateAction<FiltersState>>] {
  const [atomValue, setAtomValue] = useAtom(inventoryFilterAtom);

  const filteredValue = !dealerId ? undefined : filterDisabledSelectOptions(atomValue[dealerId]);

  useEffect(() => {
    if (JSON.stringify(filteredValue) !== JSON.stringify(atomValue) && dealerId) {
      setAtomValue(
        produce(atomValue, (draft) => {
          draft[dealerId] = filteredValue;

          return draft;
        })
      );
    }
  }, [dealerId]);

  const value = filteredValue;
  const setter = useCallback(
    (setStateAction: SetStateAction<FiltersState>) => {
      if (!dealerId) {
        return;
      }

      // `setStateAction` is either a value (the new state) or a setState function (takes previous value, returns new value)
      // The value from `setStateAction` should only be applied for the active dealerId
      setAtomValue(
        produce((draft) => {
          // Get the new state for the active dealer
          const newState =
            typeof setStateAction === 'function'
              ? setStateAction(draft[dealerId] ?? {})
              : setStateAction;

          // Update `inventoryFilterAtom`, only overwriting the value for the current dealer
          return { ...atomValue, [dealerId]: newState };
        })
      );
    },
    [atomValue, dealerId, setAtomValue]
  );

  return [value, setter];
}

function filterDisabledSelectOptions(filtersState?: FiltersState) {
  let copyFiltersState = cloneDeep(filtersState);
  const staticSelectFilters = InventoryFilterConfigs([]).filter(
    (config) => config.type === FilterType.STATIC_SELECT
  );
  for (let filterKey in copyFiltersState) {
    const config = staticSelectFilters.find((filter) => filter.id === filterKey);
    if (config) {
      (copyFiltersState[filterKey] as SelectState).options = (
        copyFiltersState[filterKey] as SelectState
      ).options?.filter((option) =>
        Object.keys((config as StaticSelectFilterConfig<InventoryItem>).options).includes(
          option.toString()
        )
      );
    }
  }
  return copyFiltersState;
}
