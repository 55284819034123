import React, { useState } from 'react';

import {
  IconAlertTriangleFilled,
  IconCaretDownFilled,
  IconCaretUpFilled,
  IconCircleCheckFilled,
} from '@tabler/icons-react';
import classnames from 'classnames';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import { COLOR } from 'Sparky/styles/vars';

import styles from './BaseInput.module.scss';

interface Props {
  children: React.ReactElement<
    React.ComponentPropsWithoutRef<'input'> | NumberFormatPropsBase<any>
  >;
}

export interface BaseInputProps {
  size?: 'sm' | 'md';
  variants?: 'default' | 'success' | 'error' | 'warning';
  disabled?: boolean;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  showNumberCaret?: boolean;

  onBlur?(event: React.ChangeEvent<HTMLDivElement>): void;

  onNumberIncrease?(): void;

  onNumberDecrease?(): void;

  className?: string;
}

/**
 * BaseInput
 */
const BaseInput = ({
  children,
  variants = 'default',
  size = 'md',
  disabled,
  leftElement,
  rightElement,
  showNumberCaret,
  onBlur,
  onNumberIncrease,
  onNumberDecrease,
  className,
}: BaseInputProps & Props) => {
  const [hoverIncrement, setHoverIncrement] = useState(false);
  const [hoverDecrement, setHoverDecrement] = useState(false);
  const child = React.Children.only(children);
  if (child.type !== NumberFormat && child.type !== 'input') {
    throw new Error('Invalid child: expected input or NumberFormat');
  }

  return (
    <div
      onBlur={onBlur}
      onClick={(e) => e.stopPropagation()}
      className={classnames(
        {
          [styles.baseInput]: true,
          [styles.sm]: size === 'sm',
          [styles.md]: size === 'md',
          [styles.default]: variants === 'default' && !disabled,
          [styles.success]: variants === 'success' && !disabled,
          [styles.warning]: variants === 'warning' && !disabled,
          [styles.error]: variants === 'error' && !disabled,
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      {leftElement}
      <span className={styles.inputWrapper}>
        {children}
        {!disabled && variants === 'success' && (
          <IconCircleCheckFilled color={COLOR.SPARKY_GREEN_400} size={size === 'md' ? 20 : 12} />
        )}
        {!disabled && variants === 'warning' && (
          <IconAlertTriangleFilled color={COLOR.SPARKY_ORANGE_400} size={size === 'md' ? 20 : 12} />
        )}
        {!disabled && variants === 'error' && (
          <IconAlertTriangleFilled color={COLOR.SPARKY_RED_400} size={size === 'md' ? 20 : 12} />
        )}
        {showNumberCaret && child.type === NumberFormat && (
          <div className={styles.buttonGroup}>
            <button
              disabled={disabled}
              onClick={onNumberIncrease}
              onMouseEnter={() => setHoverIncrement(true)}
              onMouseLeave={() => setHoverIncrement(false)}
              className={styles.increaseButton}
            >
              <IconCaretUpFilled
                height={12}
                width={16}
                color={
                  disabled
                    ? COLOR.SPARKY_GREY_200
                    : hoverIncrement
                    ? COLOR.SPARKY_NAVY_400
                    : COLOR.SPARKY_NAVY_200
                }
              />
            </button>
            <button
              disabled={disabled}
              onClick={onNumberDecrease}
              onMouseEnter={() => setHoverDecrement(true)}
              onMouseLeave={() => setHoverDecrement(false)}
              className={styles.decreaseButton}
            >
              <IconCaretDownFilled
                height={12}
                width={16}
                color={
                  disabled
                    ? COLOR.SPARKY_GREY_200
                    : hoverDecrement
                    ? COLOR.SPARKY_NAVY_400
                    : COLOR.SPARKY_NAVY_200
                }
              />
            </button>
          </div>
        )}
      </span>
      {rightElement}
    </div>
  );
};

export default BaseInput;
