import { FeatureFlag } from 'types/FeatureFlags';

export const MOCK_FEATURE_FLAGS: Record<FeatureFlag, any> = {
  [FeatureFlag.SHOW_COMING_SOON]: false,
  [FeatureFlag.ENABLE_MARKETPLACE_APPS]: true,
  [FeatureFlag.ENABLE_MARKETPLACE_CHANNELS]: true,
  [FeatureFlag.ENABLE_DASHBOARD]: true,
  [FeatureFlag.ENABLE_INVENTORY_PAGE_TEMPLATES]: true,
  [FeatureFlag.ENABLE_VVO]: true,
  [FeatureFlag.ENABLE_INVENTORY_TABLE_SORT_PREFERENCES]: true,
  [FeatureFlag.ENABLE_INVENTORY_PREFERENCES]: true,
  [FeatureFlag.WIDGET_SELLRATE_COMPARISON]: true,
  [FeatureFlag.WIDGET_AVG_PUBLISHED_DAYS_COMPARISON]: true,
  [FeatureFlag.WIDGET_AVG_SOLD_VIEWS_COMPARISON]: true,
  [FeatureFlag.WIDGET_EFFECTIVE_VDP_COST_COMPARISON]: true,
  [FeatureFlag.WIDGET_TOTAL_SHOPPERS_COMPARISON]: true,
  [FeatureFlag.WIDGET_TOTAL_VDP_VIEWS_COMPARISON]: true,
  [FeatureFlag.WIDGET_VINS_PUBLISHED_COMPARISON]: true,
  [FeatureFlag.WIDGET_VINS_SOLD_COMPARISON]: true,
  [FeatureFlag.WIDGET_VDP_VIEWS_BAR]: true,
  [FeatureFlag.WIDGET_DOL_BAR]: true,
  [FeatureFlag.WIDGET_CONVERSIONS_BAR]: true,
  [FeatureFlag.WIDGET_INV_COUNT_DONUT]: true,
  [FeatureFlag.WIDGET_PRICE_HISTORY_LINE]: true,
  [FeatureFlag.WIDGET_BUDGET_SPEND_GAUGE]: true,
  [FeatureFlag.ENABLE_EXPORT_DASHBOARD_CONFIG]: true,
  [FeatureFlag.ENABLE_EXPORT_INVENTORY_CONFIG]: true,
  [FeatureFlag.ENABLE_CHAT]: true,
  [FeatureFlag.ENABLE_DASHBOARD_TEMPLATES]: true,
  [FeatureFlag.ENABLE_DASHBOARD_ADMIN]: true,
  [FeatureFlag.ENABLE_REPORTS]: true,
  [FeatureFlag.ENABLE_REDESIGNED_INVENTORY_PAGE]: true,
  [FeatureFlag.ENABLE_BEFORE_AND_AFTER]: true,
  [FeatureFlag.ENABLE_MARKET_INSIGHTS]: true,
  [FeatureFlag.ENABLE_DEALER_LANDING_PAGE]: true,
  [FeatureFlag.ENABLE_NAVBAR_ANALYTICS]: true,
  [FeatureFlag.BA_METRIC_PRICE_TO_MARKET]: true,
  [FeatureFlag.BA_METRIC_STOCK_SALE]: true,
  [FeatureFlag.BA_METRIC_TURN_RATE]: true,
  [FeatureFlag.BA_METRIC_CONVERSIONS]: true,
  [FeatureFlag.BA_METRIC_NET_NEW_SHOPPERS]: true,
  [FeatureFlag.BA_METRIC_VINS_VIEWED_PCT]: true,
  [FeatureFlag.BA_METRIC_VINS_PUBLISHED_PCT]: true,
  [FeatureFlag.BA_METRIC_PVR]: true,
  [FeatureFlag.BA_METRIC_SELL_DOWN_RATE]: true,
  [FeatureFlag.BA_METRIC_AVG_MARKDOWN]: true,
  [FeatureFlag.BA_METRIC_DAYS_TO_SALE]: true,
  [FeatureFlag.BA_METRIC_FRONT_END_GROSS]: true,
  [FeatureFlag.BA_METRIC_VOLUME]: true,
  [FeatureFlag.BA_METRIC_MARKET_SHARE]: true,
  [FeatureFlag.BA_METRIC_LEADS]: true,
  [FeatureFlag.BA_METRIC_SOLD_TO_HOLD]: true,
  [FeatureFlag.BA_METRIC_DAYS_SUPPLY]: true,
  [FeatureFlag.BA_METRIC_AVG_MARKDOWN_PCT]: true,
  [FeatureFlag.ENABLE_VIN_DETAIL_LEAD_TAB]: true,
  [FeatureFlag.ENABLE_MARKET_OPPORTUNITY]: true,
  [FeatureFlag.INVENTORY_TABLE_AI_RECOMMENDED_BUDGET_INCREASE_COLUMN]: true,
  [FeatureFlag.ENABLE_LOCAL_MARKET_SETTINGS]: true,
  [FeatureFlag.ENABLE_CAMPAIGN_CREATION]: true,
  [FeatureFlag.ENABLE_GEO_CONFIG_SETTING]: true,
  [FeatureFlag.ENABLE_SHOWROOM]: true,
  [FeatureFlag.ENABLE_LX_VIDEO_CONFIG_SETTING]: true,
  [FeatureFlag.ENABLE_PHOTO_AI_SETTING]: true,
  [FeatureFlag.ENABLE_VMX_ACCESS]: true,
  [FeatureFlag.ENABLE_TOOLTIP_CONFIG_PAGE]: true,
  [FeatureFlag.ENABLE_MARKET_MAPS]: true,
  [FeatureFlag.ENABLE_SENTINEL_CAMPAIGN_FOR_SPECIFIC_USERS]: true,
  [FeatureFlag.ENABLE_CONVERSION_REPORT]: true,
  [FeatureFlag.ENABLE_RISK_ANALYSIS]: true,
  [FeatureFlag.ENABLE_ROI_REPORT]: true,
  [FeatureFlag.ENABLE_MARKET_INSIGHTS_PAGE]: true,
  [FeatureFlag.ENABLE_ADMIN_ACTIVITY]: true,
  [FeatureFlag.ENABLE_USER_LOGIN]: true,
  [FeatureFlag.ENABLE_VIN_PATH]: true,
  [FeatureFlag.ENABLE_RISK_OVERVIEW]: true,
  [FeatureFlag.ENABLE_ACQUISITION_PAGE]: true,
  [FeatureFlag.ENABLE_LEX_CHAT]: true,
  [FeatureFlag.DISABLE_VIN_UPGRADE_PANEL]: true,
  [FeatureFlag.ENABLE_VIN_UPGRADE_PANEL]: true,
  [FeatureFlag.ENABLE_LEX_TYPING_EFFECT]: true,
  [FeatureFlag.ENABLE_LOGIN_PAGE_DOWNTIME_NOTIFICATION]: {},
  [FeatureFlag.ENABLE_CACHE_REBUILD]: true,
};
