/**
 * @param val - The number to format
 * @param showDecimal
 * @param placeholder - String to display if value can't be formatted (`NaN`, `undefined`, `null`, etc)
 */
export function formatCurrency(
  val: number | undefined | null,
  showDecimal = false,
  placeholder = '-'
): string {
  if (typeof val !== 'number' || !isFinite(val)) {
    return placeholder;
  }

  return val.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showDecimal ? 2 : 0,
    maximumFractionDigits: showDecimal ? 2 : 0,
  });
}

/**
 * @param val - The number to format, as a decimal (0.2 = 20%)
 * @param decimals - Number of decimal places
 * @param placeholder - String to display if value can't be formatted (`NaN`, `undefined`, `null`, etc)
 */
export function formatPercent(val: number | undefined | null, decimals = 0, placeholder = '-') {
  if (typeof val !== 'number' || !isFinite(val)) {
    return placeholder;
  }

  const num = val * 100;
  const mult = Math.pow(10, decimals);
  return (Math.round(num * mult) / mult).toFixed(decimals) + '%';
}

/**
 * Formats a number with comma thousands separator
 * @param val - The number to format
 * @param decimals - Number of decimal places
 * @param placeholder - String to display if value can't be formatted (`NaN`, `undefined`, `null`, etc)
 */
export function formatNumber(val: number | undefined | null, decimals = 0, placeholder = '-') {
  if (typeof val !== 'number' || !isFinite(val)) {
    return placeholder;
  }

  return val.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

/**
 * Takes a number of minutes and formats as HH:MM
 * @param minutes - Number of minutes
 * @param placeholder - String to display if value can't be formatted (`NaN`, `undefined`, `null`, etc)
 */
export function formatDuration(minutes: number | undefined | null, placeholder = '-') {
  if (typeof minutes !== 'number' || !isFinite(minutes)) {
    return placeholder;
  }

  return `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`;
}

/**
 * Thousands abbreviation (1000 -> 1k)
 * @param val - The number to format
 * @param decimals - Number of decimal places
 * @param placeholder - String to display if value can't be formatted (`NaN`, `undefined`, `null`, etc)
 */
export function formatThousands(val: number | undefined | null, decimals = 0, placeholder = '-') {
  if (typeof val !== 'number' || !isFinite(val)) {
    return placeholder;
  } else if (Math.abs(val) < 1000) {
    return formatNumber(val);
  }

  return `${formatNumber(val / 1000, decimals)}K`;
}

export function toTitleCase(str: string) {
  return str.replace(/\w\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function formatDate(date: string | undefined | null): string {
  if (!date) {
    return '-';
  }
  return date;
}

export function formatNumberWithCommas(val: number | undefined | null, placeholder = '-') {
  if (typeof val !== 'number' || !isFinite(val)) {
    return placeholder;
  }
  return val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}
