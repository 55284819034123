import React from 'react';

import classnames from 'classnames';

import styles from './Flex.module.scss';

interface FlexProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
  justifyContent?: string;
  alignItems?: string;
  columnGap?: string;
  rowGap?: string;
  column?: boolean;
  flex?: string;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  className?: string;
  minWidth?: string;
  maxWidth?: string;
}

const Flex = React.forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      children,
      justifyContent = 'flex-start',
      alignItems = 'flex-start',
      columnGap = '0',
      rowGap = '0',
      column = false,
      flex = '0',
      margin = '0',
      padding = '0',
      minWidth = 'none',
      maxWidth = 'none',
      width,
      height,
      className,
      style,
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        {...props}
        style={{
          display: 'flex',
          flex: flex,
          flexDirection: column ? 'column' : 'row',
          justifyContent: justifyContent,
          alignItems: alignItems,
          columnGap: columnGap,
          rowGap: rowGap,
          margin: margin,
          padding: padding,
          width: width,
          height: height,
          minWidth: minWidth,
          maxWidth: maxWidth,
          ...style,
        }}
        className={classnames([className, flex === '1' && styles.flex1])}
      >
        {children}
      </div>
    );
  }
);

export default Flex;
