import classnames from 'classnames';
import dayjs from 'dayjs';
import { TooltipProps } from 'recharts';
import Flex from 'Sparky/Flex';
import Text from 'Sparky/Text';

import TooltipWrapper from 'components/sharedCharts/TooltipWrapper';
import { LegendList } from 'pages/Inventory/VinDetailSidebar/components/ChartLegend';
import { formatCurrency, formatNumber } from 'util/formatters';

import styles from './ChartTooltip.module.scss';

export interface ChartTooltipProps<T> extends TooltipProps<string | number, string> {
  getData(date: string): T | undefined;

  legend: LegendList[];
}

/**
 * ChartTooltip
 */
export default function ChartTooltip({ getData, legend, active, label }: ChartTooltipProps<any>) {
  if (!active) {
    return null;
  }
  const data = getData(label);

  return (
    <TooltipWrapper className={styles.chartTooltip}>
      <Text fontWeight={5} size={16} className={styles.chartTooltipItem}>
        {dayjs(label).format('MMM D, YYYY')}
      </Text>
      {!data && <Text fontWeight={4}>No data</Text>}
      {data &&
        legend.map(
          (item) =>
            data[item.key] > 0 && (
              <TooltipItem
                key={item.key}
                item={item}
                value={
                  item.key === 'dailyPrice' || item.key === 'marketPrice'
                    ? formatCurrency(Number(data[item.key]))
                    : formatNumber(Number(data[item.key]))
                }
              />
            )
        )}
    </TooltipWrapper>
  );
}

interface TooltipItemProps {
  item: LegendList;
  value: string;
}

function TooltipItem({ item, value }: TooltipItemProps) {
  return (
    <div className={styles.chartTooltipItem}>
      <Flex flex='none' alignItems='center' justifyContent='center' width='14px'>
        <div
          className={classnames({
            [styles.circle]: item.type === 'circle',
            [styles.rect]: item.type === 'rect',
            [styles.dotLine]: item.type === 'dotLine',
          })}
          style={{
            backgroundColor: item.type !== 'dotLine' ? item.rangeColor : undefined,
            borderColor:
              item.type === 'circle' || item.type === 'dotLine' ? item.borderColor : undefined,
          }}
        />
      </Flex>
      <Text fontWeight={4} size={16} color='secondary'>
        {item.name}:
      </Text>
      <Text fontWeight={4} size={16} className={styles.value}>
        {value}
      </Text>
    </div>
  );
}
