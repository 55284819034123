import classnames from 'classnames';
import dayjs from 'dayjs';
import { TooltipProps } from 'recharts';
import Text from 'Sparky/Text';

import { NumberFormat } from 'types/NumberFormat';
import { formatNumberFormat } from 'util/formatNumberFormat';

import styles from './ToolTip.module.scss';
interface ToolTipProps extends TooltipProps<string | number, string> {
  format: NumberFormat;
  percentDecimals: number;
  metaData: {
    [key: string]: {
      label: string;
      color: string;
      strokeDasharray?: string;
    };
  };
  data?: {
    date: string;
    [key: string]: string | number | null;
  }[];
}

export default function ToolTip({ format, metaData, payload, percentDecimals }: ToolTipProps) {
  if (!payload) {
    return <></>;
  }

  return (
    <div className={styles.tooltipContainer}>
      <Text size='14' className={classnames([styles.title, 'chromatic-ignore'])} color='secondary'>
        {dayjs(payload[0]?.payload?.date).format('MMMM DD, YYYY')}
      </Text>
      {payload.map((tip, i) => {
        const numberValue = () => {
          if (typeof tip.value === 'string') {
            return parseFloat(tip.value);
          }
          return tip.value;
        };
        const formattedValue = formatNumberFormat(numberValue(), format ?? NumberFormat.INT, {
          percentDecimals: percentDecimals,
          currencyShowDecimal: false,
        });
        const value = {
          label: metaData[tip.name as string].label,
          color: metaData[tip.name as string].color,
          strokeDasharray: metaData[tip.name as string].strokeDasharray,
        };
        const isDashed = value.strokeDasharray;
        return (
          <div key={i} className={styles.tooltipRow}>
            <div className={styles.flexRow}>
              {isDashed ? (
                <>
                  <div
                    className={classnames([styles.colorDash, styles.dashedLine])}
                    style={{ backgroundColor: value.color }}
                  />
                  <div
                    className={classnames([styles.colorDash, isDashed && styles.dashedLine])}
                    style={{ backgroundColor: value.color }}
                  />
                </>
              ) : (
                <div className={styles.colorCircled} style={{ backgroundColor: value.color }} />
              )}
              <Text size='14' className={styles.metricLabel} color='secondary'>
                {value.label}
              </Text>
            </div>
            <Text size='14'>{formattedValue}</Text>
          </div>
        );
      })}
    </div>
  );
}
