import { z } from 'zod';

import { AccountType, User } from 'types/User';

export const accountTypeSchema = z.nativeEnum(AccountType);

export const userSchema: z.ZodSchema<User> = z.object({
  accountType: accountTypeSchema,
  email: z.string(),
  first: z.string(),
  forcePwChange: z.boolean(),
  id: z.number(),
  last: z.string(),
  token: z.string(),
});
