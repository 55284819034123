import React, { ReactNode, useEffect, useRef } from 'react';

import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import { atom, useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';

import { useNavigateKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import LoadingState from 'pages/Inventory/VinDetailSidebar/components/LoadingState';
import { VINDetailSections } from 'pages/Inventory/VinDetailSidebar/utils/useVinDetail';
import {
  useVinDetailContext,
  VinDetailProvider,
} from 'pages/Inventory/VinDetailSidebar/utils/VinDetailContext';
import VINDetailHeader from 'pages/Inventory/VinDetailSidebar/VINDetailHeader';
import { VVOInventoryItem } from 'pages/Inventory/VVO/VVOChart/types';
import { InventoryItem } from 'types/InventoryItem';

import styles from './VinDetailSidebar.module.scss';

interface VINDetailSidebarProps {
  vin?: string;
  inventoryData: InventoryItem[] | VVOInventoryItem[];
  isLoading: boolean;
  style?: React.CSSProperties;
  className?: string;
  children: ReactNode;
  scrollLimit?: number;
  defaultOpenSection?: VINDetailSections[];
  defaultYPosition?: number;
}

const scrollTopAtom = atom<boolean>(true);

export default function VinDetailSidebar({
  vin = '',
  inventoryData,
  isLoading,
  style,
  className,
  children,
  scrollLimit = 184,
  defaultOpenSection,
  defaultYPosition,
}: VINDetailSidebarProps) {
  return (
    <VinDetailProvider inventory={inventoryData} vin={vin} defaultOpenSection={defaultOpenSection}>
      <VINDetailSidebarContent
        vin={vin}
        inventoryData={inventoryData}
        isLoading={isLoading}
        className={className}
        scrollLimit={scrollLimit}
        style={style}
        defaultYPosition={defaultYPosition}
      >
        {children}
      </VINDetailSidebarContent>
    </VinDetailProvider>
  );
}

function VINDetailSidebarContent({
  vin,
  inventoryData,
  isLoading,
  className,
  scrollLimit,
  style,
  defaultYPosition,
  children,
}: VINDetailSidebarProps) {
  const location = useLocation();
  const navigate = useNavigateKeepParams();
  const ref = useRef<HTMLDivElement | null>(null);

  const [isScrollTop, setIsScrollTop] = useAtom(scrollTopAtom);
  const { item, vinIndex } = useVinDetailContext();
  const totalVINsAmount = inventoryData?.length;

  const pathArray = location.pathname.split('/');

  const onClose = () => {
    const backPathArray = pathArray.slice();
    backPathArray.pop();
    navigate(backPathArray.join('/'));
  };

  useEffect(() => {
    if (!isLoading && !inventoryData.find((i) => i.listing.vin === vin)) {
      onClose();
    }
  }, [vin, inventoryData]);

  useEffect(() => {
    defaultYPosition !== undefined && ref.current?.scrollTo(0, defaultYPosition);
  }, [defaultYPosition]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    // @ts-ignore
    if (e.target.scrollTop === 0) {
      setIsScrollTop(true);
      // @ts-ignore
    } else if (e.target.scrollTop > scrollLimit) {
      setIsScrollTop(false);
    }
  };

  if (isLoading) {
    return (
      <Flex
        flex='none'
        width='50%'
        padding='16px'
        justifyContent='center'
        alignItems='center'
        style={style}
        className={classnames([styles.loadingState, className])}
      >
        <LoadingState />
        <IconButton
          aria-label='close VIN detail sidebar'
          onClick={onClose}
          className={styles.closeBtn}
        >
          <IconX />
        </IconButton>
      </Flex>
    );
  } else if (!item || vinIndex < 0) {
    return (
      <Flex
        flex='none'
        width='50%'
        padding='16px'
        justifyContent='center'
        alignItems='center'
        rowGap='8px'
        column
        style={style}
        className={classnames([styles.errorState, className])}
      >
        <Text size='16' fontWeight={7}>
          Oops! Something went wrong, we couldn't retrieve the VIN data.
        </Text>
        <Text size='14'>Please try refreshing the page.</Text>
        <IconButton
          aria-label='close VIN detail sidebar'
          onClick={onClose}
          className={styles.closeBtn}
        >
          <IconX />
        </IconButton>
      </Flex>
    );
  } else {
    const prevVin = inventoryData[vinIndex - 1]?.listing.vin;
    const nextVIN = inventoryData[vinIndex + 1]?.listing.vin;

    const onPrevClick = () => {
      pathArray.splice(pathArray.length - 1, 1, prevVin);
      navigate(pathArray.join('/'));
    };

    const onNextClick = () => {
      pathArray.splice(pathArray.length - 1, 1, nextVIN);
      navigate(pathArray.join('/'));
    };

    return (
      <Flex
        flex='none'
        width='50%'
        column
        style={style}
        className={classnames([styles.vinDetailSidebar, className])}
      >
        <VINDetailHeader
          item={item}
          totalVINsAmount={totalVINsAmount}
          vinIndex={vinIndex}
          isScrollTop={isScrollTop}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          onClose={onClose}
        />
        <Flex
          ref={ref}
          width='100%'
          height={isScrollTop ? 'calc(100% - 180px)' : 'calc(100% - 50px)'}
          flex='none'
          column
          padding='0 16px'
          onScrollCapture={(e) => handleScroll(e)}
          className={styles.vinDetailContentContainer}
        >
          {children}
        </Flex>
      </Flex>
    );
  }
}
