import { z } from 'zod';

import { GetMarketDemandDataResponse } from 'pages/Inventory/VinDetailSidebar/api/useMarketDemandData/useMarketDemandData';

export const marketDemandDataResponseSchema: z.ZodSchema<GetMarketDemandDataResponse> = z.object({
  data: z
    .array(
      z.object({
        date: z.string(),
        value: z.number(),
      })
    )
    .optional(),
  high: z.number().optional(),
  low: z.number().optional(),
  totalShoppers30d: z.number().optional(),
  avgShoppers30d: z.number().optional(),
  slope: z.number().optional().nullable(),
  intercept: z.number().optional().nullable(),
});
