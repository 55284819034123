export const AddIconNavy = () => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.6667 7.88346H8.66675V3.88346C8.66675 3.70665 8.59651 3.53708 8.47149 3.41206C8.34646 3.28703 8.17689 3.2168 8.00008 3.2168C7.82327 3.2168 7.6537 3.28703 7.52868 3.41206C7.40365 3.53708 7.33341 3.70665 7.33341 3.88346V7.88346H3.33341C3.1566 7.88346 2.98703 7.9537 2.86201 8.07873C2.73699 8.20375 2.66675 8.37332 2.66675 8.55013C2.66675 8.72694 2.73699 8.89651 2.86201 9.02153C2.98703 9.14656 3.1566 9.2168 3.33341 9.2168H7.33341V13.2168C7.33341 13.3936 7.40365 13.5632 7.52868 13.6882C7.6537 13.8132 7.82327 13.8835 8.00008 13.8835C8.17689 13.8835 8.34646 13.8132 8.47149 13.6882C8.59651 13.5632 8.66675 13.3936 8.66675 13.2168V9.2168H12.6667C12.8436 9.2168 13.0131 9.14656 13.1382 9.02153C13.2632 8.89651 13.3334 8.72694 13.3334 8.55013C13.3334 8.37332 13.2632 8.20375 13.1382 8.07873C13.0131 7.9537 12.8436 7.88346 12.6667 7.88346Z'
      fill='#0D2B41'
    />
  </svg>
);

export const AddIconDisabled = () => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.6667 7.68351H8.66675V3.68351C8.66675 3.5067 8.59651 3.33713 8.47149 3.21211C8.34646 3.08708 8.17689 3.01685 8.00008 3.01685C7.82327 3.01685 7.6537 3.08708 7.52868 3.21211C7.40365 3.33713 7.33341 3.5067 7.33341 3.68351V7.68351H3.33341C3.1566 7.68351 2.98703 7.75375 2.86201 7.87877C2.73699 8.0038 2.66675 8.17337 2.66675 8.35018C2.66675 8.52699 2.73699 8.69656 2.86201 8.82158C2.98703 8.94661 3.1566 9.01685 3.33341 9.01685H7.33341V13.0168C7.33341 13.1937 7.40365 13.3632 7.52868 13.4882C7.6537 13.6133 7.82327 13.6835 8.00008 13.6835C8.17689 13.6835 8.34646 13.6133 8.47149 13.4882C8.59651 13.3632 8.66675 13.1937 8.66675 13.0168V9.01685H12.6667C12.8436 9.01685 13.0131 8.94661 13.1382 8.82158C13.2632 8.69656 13.3334 8.52699 13.3334 8.35018C13.3334 8.17337 13.2632 8.0038 13.1382 7.87877C13.0131 7.75375 12.8436 7.68351 12.6667 7.68351Z'
      fill='#C3C3C3'
    />
  </svg>
);
