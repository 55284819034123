import { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { atom, useAtom } from 'jotai';
import Chip from 'Sparky/Chip';
import IconButton from 'Sparky/IconButton';
import Modal from 'Sparky/Modal';
import { CloseIcon } from 'Sparky/SVGs/CloseIcon';
import { LotlinxXIcon } from 'Sparky/SVGs/LotlinxXIcon';
import Text from 'Sparky/Text';

import DealerSelectButton from 'components/DealerSelectButton';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import ControlPanel from 'pages/MarketMaps/ControlPanel';
import HexMarketMap from 'pages/MarketMaps/HexMarketMap';
import InStoreShoppersMap from 'pages/MarketMaps/InStoreShoppersMap';

import styles from './MarketMaps.module.scss';

export type MarketMapsKeys =
  | 'inStoreTraffic'
  | 'inMarketShoppers'
  | 'salesVolume'
  | 'listedUnits'
  | 'marketDaysSupply';

type MarketMapsDefs = Record<
  MarketMapsKeys,
  {
    title: string;
    message: string;
  }
>;

export const MARKET_MAPS_DEFS: MarketMapsDefs = {
  inStoreTraffic: {
    title: 'In-store shopper traffic',
    message: 'The number of new customers that visited a dealership in the last 30 days',
  },
  inMarketShoppers: {
    title: 'In-market shoppers',
    message: 'Shoppers that are actively looking to purchase a vehicle over the last 30 days.',
  },
  salesVolume: {
    title: 'Sales volume',
    message: 'The number of vehicles sold in the last 30 days',
  },
  listedUnits: {
    title: 'Listed units',
    message:
      'The inventory of vehicles available for purchase within the market in the last 30 days.',
  },
  marketDaysSupply: {
    title: 'Market days supply',
    message:
      'The estimated number of days it would take to sell the current inventory of vehicles at the current sales rate without adding new stock.',
  },
};

export const showMarketMapsAtom = atom(false);

/**
 * MarketMaps
 */
export default function MarketMaps() {
  const [showMarketMaps, setShowMarketMaps] = useAtom(showMarketMapsAtom);
  const [activeMetric, setActiveMetric] = useState<MarketMapsKeys>('inStoreTraffic');
  const [dealerId] = useSelectedDealerId();
  const formRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    formRef?.current?.click();
  }, [formRef, dealerId]);

  const MetricSelectors = () => {
    return (
      <div className={styles.metricSelectors}>
        {Object.keys(MARKET_MAPS_DEFS).map((key) => {
          return (
            <button
              key={key}
              onClick={() => {
                setActiveMetric(key as MarketMapsKeys);
              }}
            >
              <Chip
                className={classnames([
                  styles.metricSelectorsButton,
                  key === activeMetric ? styles.active : styles.inactive,
                ])}
                variant='filled'
              >
                {MARKET_MAPS_DEFS[key as MarketMapsKeys].title}
              </Chip>
            </button>
          );
        })}
      </div>
    );
  };

  const CloseButton = () => {
    return (
      <IconButton
        aria-label='close market maps modal button'
        onClick={() => setShowMarketMaps(false)}
        className={styles.closeButton}
        size='md'
      >
        <CloseIcon />
      </IconButton>
    );
  };

  return (
    <Modal
      isOpen={showMarketMaps}
      onDismiss={() => setShowMarketMaps(false)}
      aria-label='Lexica Market Maps'
      overlayStyle={{
        padding: 0,
        alignItems: 'flex-end',
        background: 'none',
        // pointerEvents: 'none',
      }}
    >
      <div className={styles.marketmaps}>
        <div className={styles.titleContainer}>
          <LotlinxXIcon />
          <Text size='20' fontWeight={7} color='navy'>
            Market Maps
          </Text>
        </div>
        <MetricSelectors />
        <ControlPanel activeMetric={activeMetric} />
        <DealerSelectButton isMarketMap align='end' className={styles.dealerSelectContainer} />

        <CloseButton />
        {activeMetric === 'inStoreTraffic' ? (
          <InStoreShoppersMap />
        ) : (
          <HexMarketMap activeMetric={activeMetric} />
        )}
      </div>
    </Modal>
  );
}
