/**
 * Compare function for unknown types:
 * - Handles numbers correctly, unlike JavaScript's default sort function
 * - If types don't match, compares values as strings
 */
export function compare(a: unknown, b: unknown) {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  } else if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  } else if (typeof a === 'boolean' && typeof b === 'boolean') {
    return Number(a) - Number(b);
  } else {
    if (a === undefined) {
      return 1;
    } else if (b === undefined) {
      return -1;
    }
    return String(a).localeCompare(String(b));
  }
}
