import { useState } from 'react';

import produce from 'immer';

import { VVOInventoryItem } from 'pages/Inventory/VVO/VVOChart/types';
import { InventoryItem } from 'types/InventoryItem';

import { VinDetailContextInterface } from './VinDetailContext';

interface UseVinDetailProps {
  vin: string;
  inventory: InventoryItem[] | VVOInventoryItem[];
  defaultOpenSection?: VINDetailSections[];
}

export type VINDetailSections =
  | 'summary'
  | 'vdp'
  | 'leads'
  | 'market'
  | 'insights'
  | 'price'
  | 'spend'
  | 'shopperDemand';

export const useVinDetail = ({
  vin,
  inventory,
  defaultOpenSection = ['summary'],
}: UseVinDetailProps): VinDetailContextInterface => {
  const [openSection, setOpenSection] = useState<VINDetailSections[]>(defaultOpenSection);
  const vinIndex = inventory?.findIndex((inventoryItem) => inventoryItem.listing.vin === vin);
  const item = inventory[vinIndex];

  const onOpenSection = (section: VINDetailSections) => {
    if (openSection.includes(section)) {
      setOpenSection(
        produce(openSection, (draft) => {
          draft = draft.filter((value) => value !== section);
          return draft;
        })
      );
    } else {
      setOpenSection(
        produce(openSection, (draft) => {
          draft.push(section);
          return draft;
        })
      );
    }
  };

  return {
    item,
    vinIndex,
    openSection,
    onOpenSection,
  };
};
