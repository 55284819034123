import Text from 'Sparky/Text';

import styles from './VinDetails.module.scss';

export interface VinDetailsProps {
  modelYear: number;
  make: string;
  model: string;
  trim?: string;
  vin: string;
  stockNumber?: string;
  exteriorColor?: string;
}

/**
 * VinDetails
 */
export default function VinDetails({
  modelYear,
  make,
  model,
  trim,
  vin,
  stockNumber,
  exteriorColor,
}: VinDetailsProps) {
  return (
    <div className={styles.vinDetails}>
      <Text size='14' color='primary' fontWeight={7} className={styles.text}>
        {modelYear} {make} {model} {trim}
      </Text>
      <Text size='14' color='secondary' className={styles.text}>
        VIN: {vin}
      </Text>
      <Text size='14' color='secondary' className={styles.text}>
        Stock #: {stockNumber}
      </Text>
      <Text size='14' color='secondary' className={styles.text}>
        {exteriorColor}
      </Text>
    </div>
  );
}
