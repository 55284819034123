import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';

import ENDPOINTS from 'api/utils/endpoints';
import { get } from 'api/utils/fetch';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { MarketDemandChartData } from 'pages/Inventory/VinDetailSidebar/ShopperDemandSection/MarketDemandChart';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

import { marketDemandDataResponseSchema } from './marketDemandDataSchema';

interface FetchMarketDemandDataProps {
  token?: string;
  dealerId?: number;
  vin: string;
  startDate?: string;
  endDate?: string;
}

/** `marketDemandData` endpoint response */
export interface GetMarketDemandDataResponse {
  data?: {
    date: string;
    value: number;
  }[];
  high?: number;
  low?: number;
  totalShoppers30d?: number;
  avgShoppers30d?: number;
  slope?: number | null;
  intercept?: number | null;
}

/** useMarketDemandData query data */
export interface MarketDemandData {
  data?: MarketDemandChartData[];
  high?: number;
  low?: number;
  totalShoppers30d?: number;
  avgShoppers30d?: number;
  slope?: number | null;
  intercept?: number | null;
}

async function fetchMarketDemandData({
  token,
  dealerId,
  vin,
  startDate,
  endDate,
}: FetchMarketDemandDataProps): Promise<MarketDemandData> {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  const res = await get<GetMarketDemandDataResponse>(
    `${ENDPOINTS.marketDemandData}/${dealerId}/${vin}`,
    {
      headers: { token },
      params: {
        startDate,
        endDate,
      },
    }
  );

  const { data, high, low, totalShoppers30d, avgShoppers30d, slope, intercept } =
    marketDemandDataResponseSchema.parse(res);

  return {
    data: transformMarketDemandData(data),
    high,
    low,
    totalShoppers30d,
    avgShoppers30d,
    slope,
    intercept,
  };
}

/** Transforms GetMarketDemandDataResponse data into MarketDemandChartData */
export function transformMarketDemandData(
  data: GetMarketDemandDataResponse['data']
): MarketDemandChartData[] | undefined {
  if (!data) {
    return undefined;
  } else if (!data?.length) {
    return [];
  }

  let sorted = data.sort((a, b) => dayjs(a.date).diff(b.date));
  const startDate = dayjs(data[0].date);
  return sorted.map((i) => ({
    date: i.date,
    x: dayjs(i.date).diff(startDate, 'days'),
    y: i.value,
  }));
}

export default function useMarketDemandData(vin: string) {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { marketDemandDataKey: queryKey } = useQueryKeys({ vin });
  const endDate = dayjs().format('YYYYMMDD');
  const startDate = dayjs().subtract(60, 'days').format('YYYYMMDD');

  return useQuery(queryKey, () =>
    fetchMarketDemandData({ token: user?.token, dealerId, vin, startDate, endDate })
  );
}
