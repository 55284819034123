import dayjs from 'dayjs';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COLOR } from 'Sparky/styles/vars';

import ChartLegend, {
  colorPalette,
  LegendList,
} from 'pages/Inventory/VinDetailSidebar/components/ChartLegend';
import ChartTooltip from 'pages/Inventory/VinDetailSidebar/components/ChartTooltip';
import { formatNumber } from 'util/formatters';

export interface MarketDemandChartProps {
  data: MarketDemandChartData[];
  high?: number;
  low?: number;
}

export interface MarketDemandChartData {
  date: string;
  x: number;
  y: number;
}

const legend: LegendList[] = [
  {
    key: 'high',
    name: 'High',
    type: 'rect',
    borderColor: colorPalette.red.rangeColor,
    rangeColor: colorPalette.red.borderColor,
  },
  {
    key: 'low',
    name: 'Low',
    type: 'rect',
    borderColor: colorPalette.blue.rangeColor,
    rangeColor: colorPalette.blue.borderColor,
  },
  {
    key: 'y',
    name: 'Shoppers',
    type: 'rect',
    borderColor: colorPalette.turqouise.borderColor,
    rangeColor: colorPalette.turqouise.rangeColor,
  },
  {
    key: 'trend',
    name: 'Trend',
    type: 'dotLine',
    borderColor: colorPalette.navy.rangeColor,
    rangeColor: colorPalette.navy.borderColor,
  },
];

export default function MarketDemandChart({ data, high, low }: MarketDemandChartProps) {
  let chartData: (MarketDemandChartData & { fit?: number })[] = data.sort((a, b) =>
    dayjs(a.date).diff(b.date)
  );
  // Add fit line points
  const fit = leastSquaresFit(data);
  if (fit) {
    chartData.forEach((i) => {
      const point = fit.slope * i.x + fit.intercept;
      // Skip any points below zero, to prevent the Y Axis going into negatives
      i.fit = point >= 0 ? point : undefined;
    });
  }

  return (
    <ResponsiveContainer width='100%' height='100%' className='chromatic-ignore'>
      <LineChart data={chartData} margin={{ top: 5, right: 0, left: -15, bottom: 5 }}>
        <CartesianGrid vertical={false} stroke={COLOR.NEUTRAL_3} />
        <XAxis
          dataKey='date'
          tickFormatter={(val) => dayjs(val).format('MMM D')}
          tick={{
            fill: COLOR.SPARKY_GREY_500,
            fontSize: 12,
            textAnchor: chartData.length > 8 ? 'end' : 'middle',
            dx: -4,
          }}
          angle={chartData.length > 8 ? -50 : 0}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          tickLine={false}
          domain={['auto', 'auto']}
          tick={{ fill: COLOR.SPARKY_GREY_500, fontSize: 12 }}
          tickFormatter={(val) => formatNumber(val)}
          axisLine={{ stroke: COLOR.NEUTRAL_3 }}
        />
        {/* Adds a border on the right edge of the chart */}
        <ReferenceLine x={chartData[chartData.length - 1].x} stroke={COLOR.SPARKY_GREY_100} />
        <Tooltip
          content={
            <ChartTooltip getData={(date) => data?.find((i) => i.date === date)} legend={legend} />
          }
        />
        {high && <ReferenceLine y={high} stroke={COLOR.SPARKY_RED_300} />}
        {low && <ReferenceLine y={low} stroke={COLOR.SPARKY_BLUE_300} />}
        <Line
          name='Markets'
          dataKey='y'
          type='monotone'
          stroke='#10A0A2'
          dot={false}
          isAnimationActive={false}
        />
        <Line
          name='Fit'
          dataKey='fit'
          stroke={COLOR.SPARKY_NAVY_400}
          dot={false}
          isAnimationActive={false}
          strokeDasharray='2 2'
          legendType='none'
          activeDot={false}
        />
        <Legend
          content={<ChartLegend legend={legend} />}
          wrapperStyle={{ padding: '0 32px', bottom: '0' }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export interface BestFitLine {
  slope: number;
  intercept: number;
}

/** Finds the linear line of best fit using the least squares method */
export function leastSquaresFit(data?: { x: number; y: number }[]) {
  if (!data || data.length <= 1) {
    return undefined;
  }

  let sumX = 0;
  let sumY = 0;
  let sumXX = 0;
  let sumXY = 0;
  let count = data.length;

  data.forEach((point) => {
    sumX += point.x;
    sumY += point.y;
    sumXX += point.x * point.x;
    sumXY += point.x * point.y;
  });

  let slope = (count * sumXY - sumX * sumY) / (count * sumXX - sumX * sumX);
  let intercept = (sumY - slope * sumX) / count;

  return {
    slope,
    intercept,
  } as BestFitLine;
}
