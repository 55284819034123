import {
  MarketSegmentsResponse,
  SegmentData,
} from 'components/Segments/api/useMarketSegments/useMarketSegments';

// Takes the segment response and splits it into favorites, the standard segment map, and a flattened segment array
// The flattened array also has a favorite boolean, and the option
export default function segmentDeserializer(data: MarketSegmentsResponse) {
  if (data) {
    let favorites = data.favorites;
    let segments: { [keys: string]: SegmentData[] } = { ...data } as {
      [keys: string]: SegmentData[];
    };
    delete segments.favorites;
    delete segments.CONDITION;

    // Omit is removing the favorites property
    let flattenedSegments: SegmentData[] = flattenSegments({ ...segments }, favorites).sort(
      (a, b) => b.totalUnits - a.totalUnits || b.riskScore - a.riskScore
    );

    return { favorites, segments, flattenedSegments };
  }
  return { favorites: [], segments: {}, flattenedSegments: [] };
}

// Takes the segment option map and converts it into a flat array
function flattenSegments(
  data: { [keys: string]: SegmentData[] },
  favorites: string[]
): SegmentData[] {
  if (data) {
    return Object.keys(data).reduce((acc, key) => {
      // Set the option and favorite flag for each element of flattened array
      data[key].forEach((item) => {
        item.option = key;
        item.favorite = favorites.includes(item.segment);
      });
      return [...acc, ...data[key]];
    }, [] as SegmentData[]);
  }
  return [];
}
