export const FONT_SIZE = {
  FONT_6: 'var(--font-6)',
  FONT_8: 'var(--font-8)',
  FONT_10: 'var(--font-10)',
  FONT_12: 'var(--font-12)',
  FONT_14: 'var(--font-14)',
  FONT_16: 'var(--font-16)',
  FONT_18: 'var(--font-18)',
  FONT_20: 'var(--font-20)',
  FONT_24: 'var(--font-24)',
  FONT_32: 'var(--font-32)',
  FONT_40: 'var(--font-40)',
  FONT_48: 'var(--font-48)',
  FONT_56: 'var(--font-56)',
};

export const SPACING = {
  SPACING_02: 'var(--spacing-02)',
  SPACING_04: 'var(--spacing-04)',
  SPACING_08: 'var(--spacing-08)',
  SPACING_12: 'var(--spacing-12)',
  SPACING_16: 'var(--spacing-16)',
  SPACING_24: 'var(--spacing-24)',
  SPACING_32: 'var(--spacing-32)',
  SPACING_40: 'var(--spacing-40)',
  SPACING_48: 'var(--spacing-48)',
  SPACING_56: 'var(--spacing-56)',
  SPACING_64: 'var(--spacing-64)',
};

export const COLOR = {
  // OLD PALETTE
  TEXT_PRIMARY: 'var(--color-text-primary)',
  TEXT_SECONDARY: 'var(--color-text-secondary)',
  TEXT_BRAND: 'var(--color-text-brand)',
  TEXT_LIGHT: 'var(--color-text-light)',
  NEUTRAL_1: 'var(--color-neutral-1)',
  NEUTRAL_2: 'var(--color-neutral-2)',
  NEUTRAL_3: 'var(--color-neutral-3)',
  NEUTRAL_4: 'var(--color-neutral-4)',
  NEUTRAL_5: 'var(--color-neutral-5)',
  NEUTRAL_6: 'var(--color-neutral-6)',
  BLUE_1: 'var(--color-blue-1)',
  BLUE_2: 'var(--color-blue-2)',
  BLUE_3: 'var(--color-blue-3)',
  BLUE_4: 'var(--color-blue-4)',
  RED_1: 'var(--color-red-1)',
  RED_2: 'var(--color-red-2)',
  CHART_BLUE_1: 'var(--color-chart-blue-1)',
  CHART_BLUE_2: 'var(--color-chart-blue-2)',
  CHART_GREEN_1: 'var(--color-chart-green-1)',
  CHART_ORANGE_1: 'var(--color-chart-orange-1)',
  CHART_ORANGE_2: 'var(--color-chart-orange-2)',
  CHART_RED_1: 'var(--color-chart-red-1)',
  CHART_RED_2: 'var(--color-chart-red-2)',
  CHART_PINK_1: 'var(--color-chart-pink-1)',
  CHART_PURPLE_1: 'var(--color-chart-purple-1)',

  // SPARKY COLORS
  SPARKY_RED_100: 'var(--sparky-red-100)',
  SPARKY_RED_200: 'var(--sparky-red-200)',
  SPARKY_RED_300: 'var(--sparky-red-300)',
  SPARKY_RED_400: 'var(--sparky-red-400)',
  SPARKY_RED_500: 'var(--sparky-red-500)',
  SPARKY_RED_600: 'var(--sparky-red-600)',
  SPARKY_RED_700: 'var(--sparky-red-700)',

  SPARKY_ORANGE_100: 'var(--sparky-orange-100)',
  SPARKY_ORANGE_200: 'var(--sparky-orange-200)',
  SPARKY_ORANGE_300: 'var(--sparky-orange-300)',
  SPARKY_ORANGE_400: 'var(--sparky-orange-400)',
  SPARKY_ORANGE_500: 'var(--sparky-orange-500)',
  SPARKY_ORANGE_600: 'var(--sparky-orange-600)',
  SPARKY_ORANGE_700: 'var(--sparky-orange-700)',

  SPARKY_YELLOW_100: 'var(--sparky-yellow-100)',
  SPARKY_YELLOW_200: 'var(--sparky-yellow-200)',
  SPARKY_YELLOW_300: 'var(--sparky-yellow-300)',
  SPARKY_YELLOW_400: 'var(--sparky-yellow-400)',
  SPARKY_YELLOW_500: 'var(--sparky-yellow-500)',
  SPARKY_YELLOW_600: 'var(--sparky-yellow-600)',
  SPARKY_YELLOW_700: 'var(--sparky-yellow-700)',

  SPARKY_GREEN_100: 'var(--sparky-green-100)',
  SPARKY_GREEN_200: 'var(--sparky-green-200)',
  SPARKY_GREEN_300: 'var(--sparky-green-300)',
  SPARKY_GREEN_400: 'var(--sparky-green-400)',
  SPARKY_GREEN_500: 'var(--sparky-green-500)',
  SPARKY_GREEN_600: 'var(--sparky-green-600)',
  SPARKY_GREEN_700: 'var(--sparky-green-700)',

  SPARKY_LIME_100: 'var(--sparky-lime-100)',
  SPARKY_LIME_200: 'var(--sparky-lime-200)',
  SPARKY_LIME_300: 'var(--sparky-lime-300)',
  SPARKY_LIME_400: 'var(--sparky-lime-400)',
  SPARKY_LIME_500: 'var(--sparky-lime-500)',
  SPARKY_LIME_600: 'var(--sparky-lime-600)',
  SPARKY_LIME_700: 'var(--sparky-lime-700)',

  SPARKY_TURQUOISE_100: 'var(--sparky-turquise-100)',
  SPARKY_TURQUOISE_200: 'var(--sparky-turquise-200)',
  SPARKY_TURQUOISE_300: 'var(--sparky-turquise-300)',
  SPARKY_TURQUOISE_400: 'var(--sparky-turquise-400)',
  SPARKY_TURQUOISE_500: 'var(--sparky-turquise-500)',
  SPARKY_TURQUOISE_600: 'var(--sparky-turquise-600)',
  SPARKY_TURQUOISE_700: 'var(--sparky-turquise-700)',

  SPARKY_TEAL_100: 'var(--sparky-teal-100)',
  SPARKY_TEAL_200: 'var(--sparky-teal-200)',
  SPARKY_TEAL_300: 'var(--sparky-teal-300)',
  SPARKY_TEAL_400: 'var(--sparky-teal-400)',
  SPARKY_TEAL_500: 'var(--sparky-teal-500)',
  SPARKY_TEAL_600: 'var(--sparky-teal-600)',
  SPARKY_TEAL_700: 'var(--sparky-teal-700)',

  SPARKY_BLUE_100: 'var(--sparky-blue-100)',
  SPARKY_BLUE_200: 'var(--sparky-blue-200)',
  SPARKY_BLUE_300: 'var(--sparky-blue-300)',
  SPARKY_BLUE_400: 'var(--sparky-blue-400)',
  SPARKY_BLUE_500: 'var(--sparky-blue-500)',
  SPARKY_BLUE_600: 'var(--sparky-blue-600)',
  SPARKY_BLUE_700: 'var(--sparky-blue-700)',

  SPARKY_NAVY_100: 'var(--sparky-navy-100)',
  SPARKY_NAVY_200: 'var(--sparky-navy-200)',
  SPARKY_NAVY_300: 'var(--sparky-navy-300)',
  SPARKY_NAVY_400: 'var(--sparky-navy-400)',
  SPARKY_NAVY_500: 'var(--sparky-navy-500)',
  SPARKY_NAVY_600: 'var(--sparky-navy-600)',
  SPARKY_NAVY_700: 'var(--sparky-navy-700)',

  SPARKY_PURPLE_100: 'var(--sparky-purple-100)',
  SPARKY_PURPLE_200: 'var(--sparky-purple-200)',
  SPARKY_PURPLE_300: 'var(--sparky-purple-300)',
  SPARKY_PURPLE_400: 'var(--sparky-purple-400)',
  SPARKY_PURPLE_500: 'var(--sparky-purple-500)',
  SPARKY_PURPLE_600: 'var(--sparky-purple-600)',
  SPARKY_PURPLE_700: 'var(--sparky-purple-700)',

  SPARKY_PINK_100: 'var(--sparky-pink-100)',
  SPARKY_PINK_200: 'var(--sparky-pink-200)',
  SPARKY_PINK_300: 'var(--sparky-pink-300)',
  SPARKY_PINK_400: 'var(--sparky-pink-400)',
  SPARKY_PINK_500: 'var(--sparky-pink-500)',
  SPARKY_PINK_600: 'var(--sparky-pink-600)',
  SPARKY_PINK_700: 'var(--sparky-pink-700)',

  SPARKY_GREY_100: 'var(--sparky-grey-100)',
  SPARKY_GREY_200: 'var(--sparky-grey-200)',
  SPARKY_GREY_300: 'var(--sparky-grey-300)',
  SPARKY_GREY_400: 'var(--sparky-grey-400)',
  SPARKY_GREY_500: 'var(--sparky-grey-500)',
  SPARKY_GREY_600: 'var(--sparky-grey-600)',
  SPARKY_GREY_700: 'var(--sparky-grey-700)',
};

export const ELEVATION = {
  ELEVATION_1: 'var(--elevation-1)',
  ELEVATION_2: 'var(--elevation-2)',
  ELEVATION_3: 'var(--elevation-3)',
  ELEVATION_4: 'var(--elevation-4)',
};
