import classnames from 'classnames';
import Cell, { CellProps } from 'Sparky/Table/Cell';

import { RiskTableColumnKey } from 'pages/Analytics/RiskAnalysis/RiskTable/RiskTableColumnConfigDefs';
import { AddBudgetTableColumnKey } from 'pages/Inventory/InventoryPage/AddBudgetSidebar/components/AddBudgetConfirmTable/AddBudgetConfirmTableColumnDefs';
import { InventoryTableColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';

import styles from './InventoryTableCell.module.scss';

interface InventoryTableCellProps extends CellProps {
  col: InventoryTableColumnKey | AddBudgetTableColumnKey | RiskTableColumnKey;
  width?: string;
}

/** Cell component with a fixed width */
export default function InventoryTableCell({
  col,
  width,
  className,
  children,
  ...cellProps
}: InventoryTableCellProps) {
  return (
    <Cell
      className={classnames([className, col !== 'summary' && styles.staticWidthCell])}
      style={{ minWidth: width, maxWidth: width }}
      {...cellProps}
    >
      {children}
    </Cell>
  );
}
