import { ReactNode } from 'react';

import Repeat from 'Sparky/Repeat';
import Skeleton from 'Sparky/Skeleton';
import Text from 'Sparky/Text';

import { checkItemMatch } from 'components/Filters/checkItemMatch';
import SelectOptionsItem from 'components/Filters/components/SelectOptions/SelectOptionsItem';
import { useFiltersContext } from 'components/Filters/FiltersContext';
import {
  DynamicMultiSelectFilterConfig,
  DynamicSelectFilterConfig,
  FiltersState,
  FilterType,
  SelectState,
  StaticSelectFilterConfig,
} from 'components/Filters/types';

import styles from './SelectOptions.module.scss';
import optionStyles from './SelectOptionsItem/SelectOptionsItem.module.scss';

interface SelectOptionsProps {
  config:
    | DynamicSelectFilterConfig<unknown>
    | StaticSelectFilterConfig<unknown>
    | DynamicMultiSelectFilterConfig<unknown>;
}

/**
 * Checkboxes for Select filters
 */
export default function SelectOptions({ config }: SelectOptionsProps) {
  const {
    data,
    getFilterState,
    setFilterState,
    clearFilter,
    isLoading,
    filtersState,
    filterConfigs,
    itemCountLabel,
    showItemCount,
  } = useFiltersContext();

  const state = getFilterState(config.id) as SelectState | undefined;

  /*
    To get the count of matching items for each option:
     - Filter the data based on every other active filter, but not the filter that the option belongs to
     - Count the number of items that match the option value
    See the "Filters – Item Counts" documentation for more explanation – https://lotlinx.atlassian.net/wiki/spaces/LLD/pages/10355090/Filters

    `otherFiltersState` and `filteredData` are used to get those counts
   */
  const otherFiltersState: FiltersState = { ...filtersState };
  delete otherFiltersState[config.id];
  const filteredData =
    data?.slice().filter((item) => checkItemMatch(item, filterConfigs, otherFiltersState)) ?? [];

  const handleChange = (optionKey: string | number, checked: boolean) => {
    let opts: (string | number)[] = [];
    if (state && state.options?.length) {
      opts = [...state.options];
    }

    if (checked && config.exclusiveOptions) {
      opts = [optionKey];
    } else if (checked) {
      opts.push(optionKey);
    } else {
      opts = opts.filter((i) => i !== optionKey);
    }

    if (!opts.length) {
      clearFilter(config.id);
    } else {
      setFilterState(config.id, { options: opts });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.selectOptions}>
        <Repeat times={4}>
          <div className={optionStyles.option} style={{ height: '1.25em' }}>
            <Skeleton width='10em' height='1em' />
            <Skeleton width='2em' height='1em' />
          </div>
        </Repeat>
      </div>
    );
  }

  let options: ReactNode[];
  if (config.type === FilterType.STATIC_SELECT) {
    options = Object.entries(config.options).map(([key, opt]) => {
      const matchCount = filteredData.filter((item) => opt.matcher(item)).length;
      if (!matchCount && !config.showEmptyOptions) {
        return null;
      }
      return (
        <SelectOptionsItem
          key={key}
          optionKey={key}
          checked={!!state?.options?.find((i) => i === key)}
          disabled={!matchCount}
          label={opt.label}
          handleChange={handleChange}
          matchCount={matchCount}
          itemCountLabel={itemCountLabel}
          showItemCount={showItemCount}
          exclusive={config.exclusiveOptions}
        />
      );
    });
  } else if (config.type === FilterType.DYNAMIC_SELECT) {
    // DYNAMIC_SELECT
    options = getOptionList(config, data ?? []).map((opt) => {
      const matchCount = filteredData.filter((item) => config.getter(item) === opt).length;
      if (!matchCount && !config.showEmptyOptions) {
        return null;
      }
      return (
        <SelectOptionsItem
          key={opt}
          optionKey={opt}
          checked={!!state?.options?.find((i) => i === opt)}
          disabled={!matchCount}
          label={opt}
          handleChange={handleChange}
          matchCount={matchCount}
          itemCountLabel={itemCountLabel}
          showItemCount={showItemCount}
          exclusive={config.exclusiveOptions}
        />
      );
    });
  } else {
    options = getDynamicMultiOptionList(config, data ?? []).map((opt) => {
      const matchCount =
        typeof opt === 'number'
          ? filteredData.filter((item) => (config.getter(item) as number[])?.includes(opt)).length
          : filteredData.filter((item) => (config.getter(item) as string[])?.includes(opt)).length;
      if (!matchCount && !config.showEmptyOptions) {
        return null;
      }
      return (
        <SelectOptionsItem
          key={opt}
          optionKey={opt}
          checked={!!state?.options?.find((i) => i === opt)}
          disabled={!matchCount}
          label={opt}
          handleChange={handleChange}
          matchCount={matchCount}
          itemCountLabel={itemCountLabel}
          showItemCount={showItemCount}
          exclusive={config.exclusiveOptions}
        />
      );
    });
  }

  // If no options are available, display a placeholder
  if (!options.filter((i) => !!i).length) {
    return (
      <div className={styles.selectOptions}>
        <Text size='14'>No options available</Text>
      </div>
    );
  }

  return <div className={styles.selectOptions}>{options}</div>;
}

/** Get option list for DYNAMIC_SELECT filters */
export function getOptionList(config: DynamicSelectFilterConfig<unknown>, data: unknown[]) {
  const optionSet = new Set<string | number>();
  data.forEach((i) => {
    const val = config.getter(i);
    if (val !== null && val !== undefined) {
      optionSet.add(val);
    }
  });

  if (config.sortOrder) {
    return Array.from(optionSet).sort((a, b) =>
      config.sortOrder === 'asc' ? Number(a) - Number(b) : Number(b) - Number(a)
    );
  } else {
    return Array.from(optionSet).sort();
  }
}

/** Get option list for DYNAMIC_MULTI_SELECT filters */
export function getDynamicMultiOptionList(
  config: DynamicMultiSelectFilterConfig<unknown>,
  data: unknown[]
) {
  const optionSet = new Set<string | number>();
  data.forEach((i) => {
    const val = config.getter(i);
    if (val !== null && val !== undefined) {
      val.forEach((item) => optionSet.add(item));
    }
  });

  if (config.sortOrder) {
    return Array.from(optionSet).sort((a, b) =>
      config.sortOrder === 'desc' ? Number(a) - Number(b) : Number(b) - Number(a)
    );
  } else {
    return Array.from(optionSet).sort();
  }
}
