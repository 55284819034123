export function IncreaseIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 8.5C13.4477 8.5 13 8.05228 13 7.5C13 6.94772 13.4477 6.5 14 6.5H21C21.5523 6.5 22 6.94772 22 7.5V14.5C22 15.0523 21.5523 15.5 21 15.5C20.4477 15.5 20 15.0523 20 14.5V9.91421L13.7071 16.2071C13.3166 16.5976 12.6834 16.5976 12.2929 16.2071L9 12.9142L3.70711 18.2071C3.31658 18.5976 2.68342 18.5976 2.29289 18.2071C1.90237 17.8166 1.90237 17.1834 2.29289 16.7929L8.29289 10.7929C8.68342 10.4024 9.31658 10.4024 9.70711 10.7929L13 14.0858L18.5858 8.5H14Z'
        fill='#189F51'
      />
    </svg>
  );
}
