import { useEffect, useMemo, useRef, useState } from 'react';

import * as Popover from '@radix-ui/react-popover';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import Flex from 'Sparky/Flex';
import SearchField from 'Sparky/SearchField';
import { BlackOutlineStarIcon } from 'Sparky/SVGs/BlackOutlineStarIcon';
import { GoldStarIcon } from 'Sparky/SVGs/GoldStarIcon';
import Text from 'Sparky/Text';
import useToast from 'Sparky/Toast';
import {
  segmentSearchOptionAtom,
  selectedOptionAtom,
  selectedSegmentAtom,
  starredSegmentListAtom,
} from 'store/selectSegmentAtom';

import useSaveSegmentFavorites from 'components/Segments/api/saveSegmentFavorites/saveSegmentFavorites';
import useMarketSegments, {
  MarketSegmentsResponse,
  SegmentData,
} from 'components/Segments/api/useMarketSegments/useMarketSegments';
import segmentDeserializer from 'components/Segments/segmentDeserializer';

import styles from './SegmentsSearch.module.scss';

export interface SegmentsSearchProps {
  className?: string;
  searchResults: SegmentData[];
  placeholder: string;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setSearchResults: React.Dispatch<React.SetStateAction<SegmentData[]>>;
  setShowSearchResults: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * SegmentsSearch
 */
export default function SegmentsSearch({
  searchResults,
  searchText,
  placeholder,
  setSearchText,
  setSearchResults,
  setShowSearchResults,
  className,
}: SegmentsSearchProps) {
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const anchorRef = useRef<HTMLInputElement>(null);

  const [selectedSegment, setSelectedSegment] = useAtom(selectedSegmentAtom);
  const [, setSelectedOption] = useAtom(selectedOptionAtom);
  const [searching, setSearching] = useAtom(segmentSearchOptionAtom);
  const { data, isLoading } = useMarketSegments();
  const flattenedSegments = useMemo(
    () => segmentDeserializer(data as MarketSegmentsResponse)?.flattenedSegments,
    [data, isLoading]
  );
  const [favouritedSegmentsList, setFavouritedSegmentsList] = useAtom(starredSegmentListAtom);
  const mutation = useSaveSegmentFavorites();
  const { refetch } = useMarketSegments();
  const { addToast } = useToast();

  const favouritedSegments = flattenedSegments
    .filter((segment) => segment.favorite)
    .sort((a, b) => b.totalUnits - a.totalUnits);

  useEffect(() => {
    if (
      favouritedSegmentsList.join() !== favouritedSegments.map((segment) => segment.segment).join()
    ) {
      setFavouritedSegmentsList(favouritedSegments.map((segment) => segment.segment));
    }
  }, [favouritedSegments]);

  useEffect(() => {
    let searchArray = flattenedSegments
      .filter((seg) => seg.segment.split('|')[0] === selectedSegment.split('|')[0])
      .filter((item) => {
        return item.segment.toLowerCase().replace('|', ' ').includes(searchText.toLowerCase());
      });

    setSearchResults(searchArray);
  }, [searchText, flattenedSegments, selectedSegment]);

  function handleSearchClick(e: React.MouseEvent<HTMLButtonElement>, item: string) {
    e.stopPropagation();
    let searchSegment = flattenedSegments.find((seg) => {
      return seg.segment.replaceAll('|', ' ') === item;
    });

    if (searchSegment) {
      setSelectedOption(searchSegment.option || '');
      setSelectedSegment(searchSegment.segment);
    }
    setSearchText('');
    setSearching(false);
    anchorRef.current?.blur();
    setOpenSearchDropdown(false);
  }

  function handleClick(e: React.MouseEvent<HTMLDivElement>, item: string) {
    e.stopPropagation();
    let segment = flattenedSegments.find((seg) => seg.segment.replaceAll('|', ' ') === item);
    if (segment) {
      let index = favouritedSegmentsList.indexOf(segment.segment);
      let newFavorites = [...favouritedSegmentsList];
      if (index > -1) {
        newFavorites.splice(index, 1);
      } else {
        newFavorites.push(segment.segment);
      }
      mutation.mutate(newFavorites, {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          addToast({ status: 'error', description: 'Something went wrong' });
        },
      });
    }
  }

  useEffect(() => {
    if (searching) {
      anchorRef.current?.focus();
      setOpenSearchDropdown(true);
      setSearching(false);
    }
  }, [searching]);

  return (
    <Popover.Root
      open={openSearchDropdown}
      onOpenChange={setOpenSearchDropdown}
      defaultOpen={false}
    >
      <div className={classnames([styles.segmentsSearch, className])}>
        <Popover.Anchor className={className}>
          <SearchField
            ref={anchorRef}
            autoComplete='off'
            id={'search'}
            className={styles.search}
            aria-label='Search'
            value={searchText}
            onFocus={() => setOpenSearchDropdown(true)}
            onClick={() => setOpenSearchDropdown(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShowSearchResults(true);
                setOpenSearchDropdown(false);
              }

              if (e.key === 'Escape') {
                setOpenSearchDropdown(false);
              }
            }}
            placeholder={placeholder}
            size='md'
            disabled={isLoading}
            onChange={(e) => {
              setSearchText(e.target.value);
              setOpenSearchDropdown(true);
            }}
            onClear={() => {
              setShowSearchResults(false);
              setSearchText('');
              anchorRef.current?.focus();
            }}
          />
        </Popover.Anchor>
        <Popover.Portal>
          <Popover.Content
            align='center'
            className={styles.popoverContent}
            sideOffset={4}
            // Avoid focusing element in the popover when open it
            onOpenAutoFocus={(e) => e.preventDefault()}
            onCloseAutoFocus={(e) => e.preventDefault()}
          >
            <div>
              {searchText.length === 0 ? (
                <div className={styles.searchPopover}>
                  <Text color='secondary' size='14'>
                    Search for a segment by condition, make, model, and/or bodystyle
                  </Text>
                </div>
              ) : searchText.length > 0 && searchResults.length === 0 ? (
                <div className={styles.searchPopover}>
                  <Text className={styles.emptySearchResults} size='14'>
                    No segments match your search criteria
                  </Text>
                </div>
              ) : (
                <div className={styles.searchPopover}>
                  {/* <Text
                    size={14}
                    fontWeight={4}
                    lineHeight='17px'
                    className={styles.emptySearchResults}
                  >
                    {searchResults.length} results
                  </Text> */}
                  {searchResults.slice(0, 5).map((item) => {
                    return (
                      <button
                        key={item.segment}
                        className={styles.searchItem}
                        onClick={(e) => {
                          handleSearchClick(e, item.segment.replaceAll('|', ' '));
                        }}
                      >
                        <HighlightMatch
                          text={item.segment.replaceAll('|', ' ')}
                          search={searchText}
                        />
                        <div onClick={(e) => handleClick(e, item.segment.replaceAll('|', ' '))}>
                          {flattenedSegments.find((seg) => seg.segment === item.segment)
                            ?.favorite ? (
                            <GoldStarIcon />
                          ) : (
                            <BlackOutlineStarIcon />
                          )}
                        </div>
                      </button>
                    );
                  })}
                  <Flex
                    className={styles.searchPrompt}
                    column={false}
                    alignItems='center'
                    columnGap='4px'
                  >
                    <Text textWrap='nowrap' size={16} fontWeight={4} lineHeight='24px'>
                      {searchResults.length} results{' '}
                      <div className={styles.searchPromptDivider}>—</div>
                    </Text>
                    <Text
                      textWrap='nowrap'
                      size={16}
                      fontWeight={4}
                      lineHeight='24px'
                      color='tertiary'
                    >
                      Hit ‘Enter’ for all results
                    </Text>
                  </Flex>
                </div>
              )}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </div>
    </Popover.Root>
  );
}

interface HighlightMatchProps {
  text: string;
  search?: string;
}

/** If any text in the `text` prop matches `search`, highlights matching text with blue background */
export function HighlightMatch({ text, search }: HighlightMatchProps): JSX.Element {
  if (search && search.length) {
    const index = text.toLowerCase().indexOf(search.toLowerCase());
    if (index !== -1) {
      return (
        <span>
          <span>{text.substr(0, index)}</span>
          <span className={styles.highlight}>{text.substr(index, search.length)}</span>
          <span>{text.substring(index + search.length, text.length)}</span>
        </span>
      );
    }
  }

  return <span>{text}</span>;
}
