import React from 'react';

import classnames from 'classnames';

import styles from './Divider.module.scss';

export interface DividerProps extends React.ComponentPropsWithoutRef<'div'> {
  className?: string;
  style?: React.CSSProperties;
}

/**
 * A horizontal seperator
 */
export default function Divider({ className, style }: DividerProps) {
  return <hr className={classnames([styles.divider, className])} style={style} />;
}
