import React, { useContext } from 'react';

import {
  useVinDetail,
  VINDetailSections,
} from 'pages/Inventory/VinDetailSidebar/utils/useVinDetail';
import { VVOInventoryItem } from 'pages/Inventory/VVO/VVOChart/types';
import { InventoryItem } from 'types/InventoryItem';

export interface VinDetailContextInterface {
  item: InventoryItem | VVOInventoryItem;
  vinIndex: number;
  openSection: VINDetailSections[];

  onOpenSection(section: VINDetailSections): void;
}

export interface VinDetailProviderProps {
  children: React.ReactNode;
  vin: string;
  inventory: InventoryItem[] | VVOInventoryItem[];
  defaultOpenSection?: VINDetailSections[];
}

const VinDetailContext = React.createContext<VinDetailContextInterface | undefined>(undefined);

export const VinDetailProvider = ({
  children,
  vin,
  inventory,
  defaultOpenSection,
}: VinDetailProviderProps) => {
  const value = useVinDetail({ vin, inventory, defaultOpenSection });
  return <VinDetailContext.Provider value={value}>{children}</VinDetailContext.Provider>;
};

export function useVinDetailContext() {
  const context = useContext(VinDetailContext);
  if (context === undefined) {
    throw new Error('useVinDetailContext must be used within a VinDetailProvider');
  }
  return context;
}
