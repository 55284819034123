import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import Button from 'Sparky/Button';
import Text from 'Sparky/Text';
import TextInputField from 'Sparky/TextInputField';

import { APIError, ErrorCode } from 'api/utils';
import { ChangePwdSchema } from 'pages/SignInPage/api/useChangePwd/changePwdSchema';
import useChangePwd, { ChangePwdData } from 'pages/SignInPage/api/useChangePwd/useChangePwd';
import ContactUsLink from 'pages/SignInPage/ContactUsLink';

import styles from './ChangePwdForm.module.scss';

/**
 * ChangePwd
 */
export default function ChangePwdForm() {
  const [error, setError] = useState<string>();
  const mutation = useChangePwd();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePwdData>({ resolver: zodResolver(ChangePwdSchema) });

  const onSubmit = (data: ChangePwdData) => {
    mutation.mutate(data, {
      onError: (err) => {
        if (err instanceof APIError && err.subCode === ErrorCode.ACCESS_DENIED) {
          setError('Incorrect password. Please try again.');
        } else {
          setError('An unknown error occurred');
        }
      },
    });
  };

  return (
    <div className={styles.changePwdWrapper}>
      <Text fontWeight={7} size='20' color='white' className={styles.header}>
        Change Password
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.changePwdForm}>
        <Text alignment='center' fontWeight={4} size='14' color='white' className={styles.message}>
          Please choose a password{' '}
          <Text fontWeight={4} size='14' className={styles.emphasize}>
            at least 6 characters
          </Text>{' '}
          in length, with{' '}
          <Text fontWeight={4} size='14' className={styles.emphasize}>
            at least 1 letter
          </Text>
          , and{' '}
          <Text fontWeight={4} size='14' className={styles.emphasize}>
            at least 1 number
          </Text>
          .
        </Text>
        <TextInputField
          id='pwd'
          data-testid='pwd'
          {...register('pwd', { required: true })}
          variants={error || errors.pwd ? 'error' : 'default'}
          message={error ? error : errors.pwd?.message ?? ' '}
          label='Password'
          labelColor='white'
          type='password'
          className={styles.textInput}
          messageClassname={styles.errorMessage}
        />
        <TextInputField
          id='newPassword1'
          data-testid='newPassword1'
          {...register('newPassword1', { required: true })}
          variants={errors.newPassword1 ? 'error' : 'default'}
          message={errors.newPassword1?.message ?? ' '}
          label='New password'
          labelColor='white'
          type='password'
          className={styles.textInput}
          messageClassname={styles.errorMessage}
        />
        <TextInputField
          id='newPassword2'
          data-testid='newPassword2'
          {...register('newPassword2', { required: true })}
          variants={errors.newPassword2 ? 'error' : 'default'}
          message={errors.newPassword2?.message ?? ' '}
          label='New password confirmation'
          labelColor='white'
          type='password'
          className={styles.textInput}
          messageClassname={styles.errorMessage}
        />
        <Button
          type='submit'
          variant='solid'
          isLoading={mutation.isLoading}
          loadingText='Changing...'
          size='lg'
          className={styles.changePwdButton}
        >
          Change password
        </Button>
      </form>
      <ContactUsLink />
    </div>
  );
}
