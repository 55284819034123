import { ReactNode, useState } from 'react';

import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import classnames from 'classnames';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Skeleton from 'Sparky/Skeleton';
import Spacer from 'Sparky/Spacer';
import { SPACING } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import TooltipIcon from 'components/TooltipIcon';
import { toTitleCase } from 'util/formatters';

import styles from './Accordion.module.scss';

export interface AccordionProps {
  title: string;
  children: ReactNode;
  iconBefore?: JSX.Element;
  className?: string;
  contentClassName?: string;
  formatName?: boolean;
  rightElement?: JSX.Element;
  isExpanded?: boolean;
  isOpenByRow?: boolean;
  handleClick?: () => void;
  titleSize?: '12' | '14' | '16' | '20';
  isLoading?: boolean;
  showDivider?: boolean;
  defaultOpen?: boolean;
  tooltipText?: string;
  hightlightTitleOnOpen?: boolean;
}

export default function Accordion({
  children,
  iconBefore,
  className,
  contentClassName,
  title,
  titleSize = '16',
  formatName = true,
  // the element beside the toggle
  rightElement,
  isExpanded,
  isOpenByRow,
  handleClick,
  isLoading,
  showDivider = true,
  defaultOpen,
  tooltipText,
  hightlightTitleOnOpen,
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(!!defaultOpen);
  const open = isExpanded !== undefined ? isExpanded : isOpen;

  const openToggle = () => setIsOpen((prev) => !prev);

  const clickHandler = handleClick ? handleClick : openToggle;

  return (
    <div className={classnames([styles.accordion, showDivider && styles.divider, className])}>
      <div onClick={() => isOpenByRow && clickHandler()} className={styles.accordionHeader}>
        {iconBefore && iconBefore}
        {isLoading ? (
          <Skeleton width={SPACING.SPACING_48} />
        ) : (
          <Text
            fontWeight={5}
            color={hightlightTitleOnOpen && open ? 'navy' : 'secondary'}
            size={titleSize}
          >
            <Flex>
              {formatName ? toTitleCase(title) : title}
              {tooltipText && <TooltipIcon side='right' tipText={tooltipText} />}
            </Flex>
          </Text>
        )}
        <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
        {rightElement && rightElement}
        <IconButton
          size='sm'
          aria-label={isExpanded ? 'Expanded' : 'Collapsed'}
          disabled={isLoading}
          onClick={() => clickHandler()}
        >
          {open ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
        </IconButton>
      </div>
      {open && (
        <div className={classnames([styles.childrenWrapper, contentClassName])}>{children}</div>
      )}
    </div>
  );
}
