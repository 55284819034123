import React from 'react';

import classnames from 'classnames';
import Tooltip, { TooltipProps } from 'Sparky/Tooltip';

import styles from './IconButton.module.scss';

interface AdditionalProps {
  children: React.ReactChild;
  'aria-label': string;
  variants?: 'outline' | 'filled' | 'iconOnly';
  size?: 'sm' | 'md' | 'lg';
  tooltip?: string;
  tooltipSide?: TooltipProps['side'];
  className?: string;
  iconClassName?: string;
  style?: React.CSSProperties;
}

export type IconButtonProps = AdditionalProps &
  Omit<React.ComponentPropsWithoutRef<'button'>, keyof AdditionalProps>;

/**
 * IconButton
 */
const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      'aria-label': ariaLabel,
      variants = 'iconOnly',
      size = 'sm',
      tooltip,
      tooltipSide,
      className,
      iconClassName,
      style,
      ...otherProps
    },
    forwardedRef
  ) => (
    <Tooltip content={tooltip} disabled={!tooltip} side={tooltipSide}>
      <button
        ref={forwardedRef}
        aria-label={ariaLabel}
        className={classnames([
          {
            [styles.iconButton]: true,
            [styles.sm]: size === 'sm',
            [styles.md]: size === 'md',
            [styles.lg]: size === 'lg',
            [styles.iconOnly]: variants === 'iconOnly',
            [styles.outline]: variants === 'outline',
            [styles.filled]: variants === 'filled',
          },
          className,
        ])}
        {...otherProps}
      >
        <span
          data-disabled={!!otherProps.disabled}
          className={classnames([styles.icon, iconClassName])}
        >
          {children}
        </span>
      </button>
    </Tooltip>
  )
);
export default IconButton;
