import { z } from 'zod';

import { LeadsResponse } from './useLeads';

export const leadsResponseSchema: z.ZodSchema<LeadsResponse> = z.object({
  leads: z.array(
    z.record(z.union([z.string(), z.number()])).and(
      z.object({
        date: z.string(),
      })
    )
  ),
  recentLeads: z
    .array(
      z.record(z.union([z.string(), z.number()])).and(
        z.object({
          date: z.string(),
        })
      )
    )
    .optional(),
  leadStatus: z.record(z.record(z.number())).optional(),
});
