import { IconCopy } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import { Link } from 'react-router-dom';
import IconButton from 'Sparky/IconButton';
import Popover from 'Sparky/Popover';
import Text from 'Sparky/Text';
import useToast from 'Sparky/Toast';
import { showCompactInventoryViewAtom } from 'store/inventoryItemStore';

import { InventoryCondition } from 'types/InventoryItem';

import styles from './VehicleSummary.module.scss';

export interface VehicleSummaryProps {
  condition?: InventoryCondition | string;
  vin?: string;
  stockNumber?: string;
  trim?: string;
  model?: string;
  bodySegment?: string;
  modelYear?: number;
  make?: string;
  primaryImage?: string;
  imageCount?: number;
  url?: string;
  disableHover?: boolean;
  disabled?: boolean;
  showVin?: boolean;
}

/**
 * VehicleSummary
 */
export default function VehicleSummary({
  condition,
  vin,
  stockNumber,
  trim,
  model,
  bodySegment,
  modelYear,
  make,
  primaryImage,
  imageCount,
  url,
  disableHover = false,
  disabled = false,
  showVin = false,
}: VehicleSummaryProps) {
  const [showCompactInventoryView] = useAtom(showCompactInventoryViewAtom);
  const { addToast } = useToast();

  return (
    <Popover
      showCarrat={false}
      isHoverTriggered={!(disableHover || disabled)}
      disabled={disabled}
      showCloseButton={false}
      side='bottom'
      align='start'
      popupContent={
        <div className={styles.vehicleSummaryContainer}>
          <div className={styles.vehicleImage}>
            <img
              className={classnames([process.env.REACT_APP_DEPLOY_ENV === 'demo' && styles.blur])}
              src={primaryImage}
              alt=''
            />
            {imageCount !== undefined && (
              <div className={styles.countContainer}>
                <Text size='14' className={styles.imageCount}>
                  {imageCount} {imageCount !== 1 ? 'Images' : 'Image'}
                </Text>
              </div>
            )}
          </div>
          <div className={styles.vehicleInfo}>
            <Text size='16' fontWeight={7} textWrap='nowrap'>
              {modelYear} {make} {model}
            </Text>
            <Text size='14' color='secondary' textWrap='nowrap'>
              {trim} {bodySegment?.toLocaleUpperCase()}
            </Text>
            {condition && (
              <Text size='12' fontWeight={7} textWrap='nowrap' className={styles.condition}>
                {condition}
              </Text>
            )}
            {vin && (
              <div className={styles.vin}>
                <Text color='secondary' size='14'>
                  {'VIN: '}
                </Text>
                {url ? (
                  <Link
                    to={url}
                    target='_blank'
                    onClick={(e) => e.stopPropagation()}
                    className={styles.link}
                  >
                    <Text size='14' fontWeight={7}>
                      {vin}
                    </Text>
                  </Link>
                ) : (
                  <Text size='14' fontWeight={7}>
                    {vin}
                  </Text>
                )}
                <IconButton
                  aria-label='copyVin'
                  size='sm'
                  onClick={(event) => {
                    event.stopPropagation();
                    navigator.clipboard
                      .writeText(vin)
                      .then(() => addToast({ status: 'success', description: 'VIN copied' }));
                  }}
                >
                  <IconCopy size={14} />
                </IconButton>
              </div>
            )}
            <div className={styles.stockNumber}>
              <Text size='14' color='secondary'>
                {stockNumber ? 'Stock #:' : ''}
              </Text>
              <Text size='14' fontWeight={7}>
                {stockNumber}
              </Text>
              {stockNumber && (
                <IconButton
                  aria-label='copyStockNumber'
                  size='sm'
                  onClick={(event) => {
                    event.stopPropagation();
                    navigator.clipboard
                      .writeText(stockNumber)
                      .then(() => addToast({ status: 'success', description: 'Stock # copied!' }));
                  }}
                >
                  <IconCopy size={14} />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      }
      className={styles.vehicleSummaryPopover}
    >
      <div
        className={classnames([styles.vehicleSummary, showCompactInventoryView && styles.compact])}
      >
        <Text fontWeight={7} className={styles.vehicleSummaryText}>
          {modelYear} {make} {model}
        </Text>
        <Text className={styles.vehicleSummaryText}>
          {trim} {bodySegment?.toLocaleUpperCase()}
        </Text>
        {!showCompactInventoryView && url && vin ? (
          <Link to={url} target='_blank' onClick={(e) => e.stopPropagation()}>
            <Text className={styles.vehicleSummaryText} size='12' color='blue'>
              VIN: {vin}
            </Text>
          </Link>
        ) : (
          showVin &&
          vin && (
            <Text className={styles.vehicleSummaryText} size='12'>
              VIN: {vin}
            </Text>
          )
        )}
        {!showCompactInventoryView && stockNumber && (
          <Text className={styles.vehicleSummaryText} color='secondary' size='12'>
            Stock: {stockNumber}
          </Text>
        )}
      </div>
    </Popover>
  );
}
