import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';

import { Filter, useFiltersContext } from 'components/Filters';

import styles from './AcquisitionFilters.module.scss';

export interface AcquisitionFiltersProps {
  isShow: boolean;

  handleClose(): void;
}

/**
 * AcquisitionFilters
 */
export default function AcquisitionFilters({ isShow, handleClose }: AcquisitionFiltersProps) {
  const { filtersState, filterConfigs } = useFiltersContext();

  return (
    <div className={classnames([styles.acquisitionFilters, isShow && styles.showFilters])}>
      <div className={styles.filterHeader}>
        <Text fontWeight={7}>Filter private seller listings</Text>
        <IconButton onClick={handleClose} size='sm' aria-label='Close filter sidebar'>
          <IconX size={20} />
        </IconButton>
      </div>
      <div className={styles.filtersBody}>
        {filterConfigs
          .filter((filterConfig) => filterConfig.id !== 'search')
          .map((filter) => {
            if (filter.id === 'activeFilterPills' && Object.keys(filtersState).length === 0) {
              return null;
            }
            return <Filter key={filter.id} config={filter} groupId='inventory' />;
          })}
      </div>
    </div>
  );
}
