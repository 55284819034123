import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import Text from 'Sparky/Text';

import { formatCurrency } from 'util/formatters';

import styles from './MarkDown.module.scss';

export interface MarkDownProps {
  markdown?: number;
}

/**
 * MarkDown
 */
export default function MarkDown({ markdown }: MarkDownProps) {
  return (
    <Panel className={styles.markdown}>
      <Text fontWeight={5} color='secondary'>
        Markdown
      </Text>
      <Flex column flex='none' rowGap='12px' margin='0 0 auto'>
        <Text size={12} color='secondary'>
          Reduce the current price by
        </Text>
        <Text size={32} fontWeight={5}>
          {formatCurrency(markdown)}
        </Text>
      </Flex>
    </Panel>
  );
}
