import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import ENDPOINTS from 'api/utils/endpoints';
import { get } from 'api/utils/fetch';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

import { leadsResponseSchema } from './leadsSchema';

interface FetchLeadsProps {
  token?: string;
  dealerId?: number;
  vin: string;
  startDate?: string;
  endDate?: string;
}

export interface RecentLead {
  date: string;
  [key: string]: string | number;
}

export interface Lead {
  date: string;
  [key: string]: string | number;
}

export interface LeadsResponse {
  leads?: Lead[];
  recentLeads?: RecentLead[];
  leadStatus?: Record<string, Record<string, number>>;
}

async function fetchLeads({ token, dealerId, vin, startDate, endDate }: FetchLeadsProps) {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  let res = await get<LeadsResponse>(`${ENDPOINTS.leads}/${dealerId}/${vin}`, {
    headers: { token },
    params: {
      startDate,
      endDate,
    },
  });

  return leadsResponseSchema.parse(res);
}

export default function useLeads(vin: string, startDate?: string, endDate?: string) {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { leadsKey: queryKey } = useQueryKeys({ vin });

  return useQuery(queryKey, () =>
    fetchLeads({ token: user?.token, dealerId, vin, startDate, endDate })
  );
}
