import Message from 'Sparky/Message';

import styles from './NewFeatureNotification.module.scss';

export interface NewFeatureNotificationProps {
  showNotification?: boolean;
  onClose: () => void;
}

/**
 * NewFeatureNotification
 */
export default function NewFeatureNotification({
  showNotification,
  onClose,
}: NewFeatureNotificationProps) {
  return showNotification ? (
    <div className={styles.newReportMessage}>
      <Message
        variant='muted'
        category='info'
        heading={'New feature: 3-2-1 reports!'}
        onCloseClick={onClose}
      >
        Ask Lex to generate a 3-2-1 report for you to gain AI driven information about your
        business. You'll receive 3 facts, 2 insights, and 1 recommendation based on the current
        state of your business in the market.
      </Message>
    </div>
  ) : (
    <></>
  );
}
