import React from 'react';

import classnames from 'classnames';
import Spinner from 'Sparky/Spinner';
import Tooltip, { TooltipProps } from 'Sparky/Tooltip';

import styles from './Button.module.scss';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  category?: 'default' | 'danger';
  variant?: 'outline' | 'solid' | 'text' | 'transparent';
  size?: 'sm' | 'md' | 'lg';
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  isLoading?: boolean;
  loadingText?: string;
  contentClassName?: string;
  tooltip?: string;
  tooltipSide?: TooltipProps['side'];
}

/**
 * Button
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      category = 'default',
      leftIcon,
      rightIcon,
      size = 'md',
      variant = 'text',
      isLoading = false,
      loadingText,
      disabled = false,
      contentClassName,
      tooltip,
      tooltipSide,
      ...buttonProps
    },
    forwardedRef
  ) => (
    <Tooltip content={tooltip} disabled={!tooltip} side={tooltipSide}>
      <button
        ref={forwardedRef}
        className={classnames([
          {
            [styles.button]: true,
            [styles.sm]: size === 'sm',
            [styles.md]: size === 'md',
            [styles.lg]: size === 'lg',
            [styles.solid]: variant === 'solid',
            [styles.outline]: variant === 'outline',
            [styles.text]: variant === 'text',
            [styles.transparent]: variant === 'transparent',
            [styles.default]: category === 'default',
            [styles.danger]: category === 'danger',
            [styles.disabled]: disabled,
          },
          className,
        ])}
        disabled={disabled || isLoading}
        data-disabled={disabled || isLoading}
        {...buttonProps}
      >
        {isLoading ? (
          <span className={styles.loadingContainer}>
            <Spinner size={size === 'sm' ? 'sm' : 'md'} />
            <span>{loadingText}</span>
          </span>
        ) : (
          <span className={classnames([styles.content, contentClassName])}>
            {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
            <span className={styles.child} data-disabled={disabled}>
              <span className={styles.hoverChild}>{children}</span>
              <span className={styles.normalChild}>{children}</span>
            </span>
            {rightIcon && <span className={styles.rightIcon}>{rightIcon}</span>}
          </span>
        )}
      </button>
    </Tooltip>
  )
);

export default Button;
