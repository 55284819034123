import React from 'react';

import { COLOR } from 'Sparky/styles/vars';

interface ChangeArrowProps {
  change: number;
  /** Reverse positive/negative colours */
  reverseColor?: boolean;
  positiveColor?: string;
  negativeColor?: string;
  neutralColor?: string;
  size?: string;
  className?: string;
}

/**
 * Arrow indicating the direction of a change (positive or negative)
 */
export default function ChangeArrow({
  change,
  reverseColor = false,
  positiveColor = '#01DD12',
  negativeColor = COLOR.RED_1,
  neutralColor = COLOR.NEUTRAL_6,
  size = 'inherit',
  className,
}: ChangeArrowProps) {
  if (change > 0) {
    const color = reverseColor ? negativeColor : positiveColor;
    return <Arrow style={{ color }} size={size} className={className} />;
  } else if (change < 0) {
    const color = reverseColor ? positiveColor : negativeColor;
    return <Arrow style={{ color, transform: 'scaleY(-1)' }} size={size} className={className} />;
  } else {
    return <Dash style={{ color: neutralColor }} size={size} className={className} />;
  }
}

interface SVGIconProps {
  style?: React.CSSProperties;
  size: string;
  className?: string;
}

const Arrow = ({ style, size, className }: SVGIconProps) => (
  <svg
    data-testid='change-arrow--arrow'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    fontSize={size}
    height='1em'
    width='1em'
    style={{ ...style, fill: 'currentcolor', stroke: 'currentcolor' }}
    className={className}
  >
    <path
      d='M11.562 11H4.438a.438.373 0 0 1-.38-.559l3.563-5.255a.438.373 0 0 1 .758 0l3.562 5.255a.373.438 90 0 1-.379.559z'
      style={{
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        paintOrder: 'markers stroke fill',
      }}
    />
  </svg>
);

const Dash = ({ style, size, className }: SVGIconProps) => (
  <svg
    data-testid='change-arrow--dash'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    fontSize={size}
    height='1em'
    width='1em'
    style={{ ...style, fill: 'currentcolor', stroke: 'currentcolor' }}
    className={className}
  >
    <path d='M4 8h8' strokeWidth='2' />
  </svg>
);
