export const InventoryTableTooltipsDefs = {
  aiRecommendedBudgetIncrease: {
    tableHeader: 'Lexaca Intelligence recommends you increase your budget for optimal results',
    changed: 'Click to add this recommendation',
    noChanged: 'VIN has appropriate budget allocated, no change is recommended',
    disabled: "Can't change budget because an increase has already occurred this month",
  },
  budget: {
    recentlyChanged: 'This budget has been changed this month',
  },
};
