import { IconGasStationOff } from '@tabler/icons-react';
import classnames from 'classnames';
import Flex from 'Sparky/Flex';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import styles from './NoDataAvailable.module.scss';

export interface NoDataAvailableProps {
  sectionName: string;
  className?: string;
}

/**
 * NoDataAvailable
 */
export default function NoDataAvailable({ sectionName, className }: NoDataAvailableProps) {
  return (
    <Flex
      width='100%'
      height='320px'
      rowGap='24px'
      alignItems='center'
      justifyContent='center'
      column
      flex='none'
      className={classnames([styles.noDataAvailable, className])}
    >
      <IconGasStationOff size={44} color={COLOR.SPARKY_NAVY_400} />
      <Flex flex='none' column rowGap='16px' alignItems='center'>
        <Text size={20} fontWeight={7} color='navy'>
          No {sectionName} data available
        </Text>
        <Text
          size={16}
          fontWeight={4}
          color='secondary'
          alignment='center'
          className={styles.noDataAvailableText}
        >
          Try refreshing the page. If you still require assistance, please contact your performance
          manager.
        </Text>
      </Flex>
    </Flex>
  );
}
