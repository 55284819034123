import React from 'react';

export interface RepeatProps {
  children: React.ReactElement;
  times: number;
}

/**
 * Repeat takes a child component and a number, and render the component that number of times
 */
export default function Repeat({ times, children }: RepeatProps) {
  return (
    <>
      {Array(times)
        .fill(0)
        .map((n: number, index: number) => React.cloneElement(children, { key: index }))}
    </>
  );
}
