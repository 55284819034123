import React from 'react';

import { useTableContext } from '../TableContext';

export interface TableBodyProps<T> {
  className?: string;
  children: (row: T, idx?: number) => React.ReactNode;
}

/**
 * TableBody
 */
export default function TableBody<T>({ children, className, ...otherProps }: TableBodyProps<T>) {
  const { data } = useTableContext();

  return (
    <tbody className={className} {...otherProps}>
      {data.map((row, idx) => children(row, idx))}
    </tbody>
  );
}
