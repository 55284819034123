import React, { useEffect } from 'react';

import { ErrorBoundary } from '@sentry/react';
import { useAtom } from 'jotai';

import ErrorFallback from 'components/ErrorFallback/ErrorFallback';
import CheckDealer from 'components/Page/CheckDealer';
import useFeatureFlag from 'hooks/useFeatureFlag';
import NotFound from 'pages/NotFound/NotFound';
import { pageTitleAtom } from 'store';
import { FeatureFlag } from 'types/FeatureFlags';

interface PageProps {
  children: React.ReactNode;
  /** Require a dealer to be selected, shows `DealerSelectPage` if selected dealer is undefined */
  requireDealer?: boolean;
  title?: string;
  /** Control page access with a feature flag */
  flag?: FeatureFlag;
}

/**
 * Utility wrapper:
 *  - Sets the page title
 *  - Optionally ensures that a dealer is selected
 *  - Wraps pages in generic error boundary
 */
export default function Page({ children, requireDealer = false, title, flag }: PageProps) {
  const showPage = useFeatureFlag(flag);
  const [, setPageTitle] = useAtom(pageTitleAtom);

  useEffect(() => {
    if (title) {
      setPageTitle(title);
    }
  }, [title, setPageTitle]);

  if (flag && !showPage) {
    return <NotFound />;
  }

  const Wrapper = requireDealer ? CheckDealer : React.Fragment;

  return (
    <ErrorBoundary fallback={ErrorFallback} key={title}>
      <Wrapper>{children}</Wrapper>
    </ErrorBoundary>
  );
}
