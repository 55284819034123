import { produce } from 'immer';
import { useAtom } from 'jotai';
import Button from 'Sparky/Button';
import { AddIconNavy, AddIconDisabled } from 'Sparky/SVGs/AddIcon';
import Tooltip from 'Sparky/Tooltip';
import { selectedAtom, showInventoryAddBudgetSidebarAtom } from 'store/inventoryItemStore';

import useSelectedDealer from 'hooks/useSelectedDealer';
import { budgetOptions, budgetsAtom } from 'pages/Inventory/InventoryPage/AddBudgetSidebar';
import { tracker } from 'pages/Inventory/InventoryPage/InventoryTable/AIRecommendedBudgetIncrease/gaTracker.helper';
import { InventoryTableTooltipsDefs } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableTooltipsDefs';
import { userAtom } from 'store';
import { formatCurrency } from 'util/formatters';

export interface AIRecommendedBudgetIncreaseProps {
  vin: string;
  aiRecommendedBudgetIncrease?: number;
  hasExistingAddBudget?: boolean;
}

/**
 * AIRecommendedBudgetIncrease
 */
export default function AIRecommendedBudgetIncrease({
  vin,
  aiRecommendedBudgetIncrease,
  hasExistingAddBudget,
}: AIRecommendedBudgetIncreaseProps) {
  const [user] = useAtom(userAtom);
  const { dealer } = useSelectedDealer();
  const [selected, setSelected] = useAtom(selectedAtom);
  const [budgets, setBudgets] = useAtom(budgetsAtom);
  const [, setShowAddBudgetSidebar] = useAtom(showInventoryAddBudgetSidebarAtom);

  const handleClick = () => {
    if (!hasExistingAddBudget) {
      setShowAddBudgetSidebar(true);
      setSelected(
        produce(selected, (draft) => {
          if (draft.indexOf(vin) === -1) {
            draft.push(vin);
          }
          return draft;
        })
      );
      setBudgets(
        produce(budgets, (draft) => {
          if (aiRecommendedBudgetIncrease === undefined || aiRecommendedBudgetIncrease === 0) {
            draft[vin] = draft[vin] ?? budgetOptions[0].value;
          } else {
            draft[vin] = aiRecommendedBudgetIncrease?.toString();
          }
          return draft;
        })
      );
    }
  };

  const changed = !(aiRecommendedBudgetIncrease === undefined || aiRecommendedBudgetIncrease === 0);

  const trackGA = () => {
    tracker({
      eventName: 'ai_rec_budget_increase_click',
      data: {
        dealerId: dealer?.id,
        timeStamp: new Date().toISOString(),
        vin: vin,
        budgetIncrease: aiRecommendedBudgetIncrease ?? 'no change',
        user: user?.id,
      },
    });
  };

  return (
    <Tooltip
      content={
        hasExistingAddBudget
          ? InventoryTableTooltipsDefs.aiRecommendedBudgetIncrease.disabled
          : changed
          ? InventoryTableTooltipsDefs.aiRecommendedBudgetIncrease.changed
          : InventoryTableTooltipsDefs.aiRecommendedBudgetIncrease.noChanged
      }
    >
      <Button
        variant='outline'
        size='sm'
        id='ai-recommended-budget-increase-button'
        leftIcon={
          changed ? (
            hasExistingAddBudget || !dealer?.isVMS ? (
              <AddIconDisabled />
            ) : (
              <AddIconNavy />
            )
          ) : undefined
        }
        disabled={hasExistingAddBudget || !dealer?.isVMS}
        onClick={(e) => {
          trackGA();
          e.stopPropagation();
          handleClick();
        }}
      >
        {changed ? formatCurrency(aiRecommendedBudgetIncrease, true) : 'No change'}
      </Button>
    </Tooltip>
  );
}
