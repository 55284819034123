import { FilterConfig, FilterType } from 'components/Filters';
import { VVOInventoryItem } from 'pages/Inventory/VVO/VVOChart/types';
import { InventoryItem } from 'types/InventoryItem';

export const VinPathFilterConfigs = <T extends InventoryItem | VVOInventoryItem>(
  markdonRangeLimit: number[],
  milageRangeLimit: number[]
): FilterConfig<T>[] => [
  {
    name: 'Days on lot',
    id: 'dol',
    initCollapsed: true,
    type: FilterType.STATIC_SELECT,
    options: {
      '1-30': {
        label: '1-30 days',
        matcher: (i) => !!i.listing.dol && i.listing.dol > 0 && i.listing.dol <= 30,
      },
      '31-60': {
        label: '31-60 days',
        matcher: (i) => !!i.listing.dol && i.listing.dol > 30 && i.listing.dol <= 60,
      },
      '61-100': {
        label: '61-100 days',
        matcher: (i) => !!i.listing.dol && i.listing.dol > 60 && i.listing.dol <= 100,
      },
      '>100 days': {
        label: '>100 days',
        matcher: (i) => !!i.listing.dol && i.listing.dol >= 101,
      },
    },
  },
  {
    name: 'Markdown',
    id: 'markdown',
    initCollapsed: true,
    type: FilterType.STATIC_SELECT_RANGE,
    getter: (i) => (i.vinPath?.pricingOptions ? i.vinPath?.pricingOptions[0].markdown : undefined),
    excludeFromPage: 'sold',
    showToggleOptions: true,
    rangePrefix: '$',
    rangeLimit: markdonRangeLimit,
    options: {
      '<$500': {
        label: '<$500',
        minValue: 0,
        maxValue: 499,
        matcher: (i) =>
          !!i.vinPath?.pricingOptions &&
          !!i.vinPath?.pricingOptions[0].markdown &&
          i.vinPath?.pricingOptions[0].markdown > 0 &&
          i.vinPath?.pricingOptions[0].markdown < 500,
      },
      '$500 - $999': {
        label: '$500 - $999',
        minValue: 500,
        maxValue: 999,
        matcher: (i) =>
          !!i.vinPath?.pricingOptions &&
          !!i.vinPath?.pricingOptions[0].markdown &&
          i.vinPath?.pricingOptions[0].markdown >= 500 &&
          i.vinPath?.pricingOptions[0].markdown <= 999,
      },
      '$1,000 - $1,499': {
        label: '$1,000 - $1,499',
        minValue: 1000,
        maxValue: 1499,
        matcher: (i) =>
          !!i.vinPath?.pricingOptions &&
          !!i.vinPath?.pricingOptions[0].markdown &&
          i.vinPath?.pricingOptions[0].markdown >= 1000 &&
          i.vinPath?.pricingOptions[0].markdown <= 1499,
      },
      '$1,500 - $1,999': {
        label: '$1,500 - $1,999',
        minValue: 1500,
        maxValue: 1999,
        matcher: (i) =>
          !!i.vinPath?.pricingOptions &&
          !!i.vinPath?.pricingOptions[0].markdown &&
          i.vinPath?.pricingOptions[0].markdown >= 1500 &&
          i.vinPath?.pricingOptions[0].markdown <= 1999,
      },
      '>$1,999': {
        label: '>$1,999',
        minValue: 2000,
        maxValue: Infinity,
        matcher: (i) =>
          !!i.vinPath?.pricingOptions &&
          !!i.vinPath?.pricingOptions[0].markdown &&
          i.vinPath?.pricingOptions[0].markdown > 1999,
      },
    },
  },
  {
    name: 'Mileage',
    id: 'mileage',
    initCollapsed: true,
    type: FilterType.STATIC_SELECT_RANGE,
    getter: (i) => i.listing.mileage,
    showToggleOptions: true,
    rangeSuffix: ' mi',
    rangeLimit: milageRangeLimit,
    options: {
      '<5,000': {
        label: '<5,000',
        minValue: 0,
        maxValue: 4999,
        matcher: (i) => !!i.listing.mileage && i.listing.mileage > 0 && i.listing.mileage <= 4999,
      },
      '5,000 - 49,999': {
        label: '5,000 - 49,999',
        minValue: 5000,
        maxValue: 49999,
        matcher: (i) =>
          !!i.listing.mileage && i.listing.mileage >= 5000 && i.listing.mileage <= 49999,
      },
      '50,000 - 99,999': {
        label: '50,000 - 99,999',
        minValue: 50000,
        maxValue: 99999,
        matcher: (i) =>
          !!i.listing.mileage && i.listing.mileage >= 50000 && i.listing.mileage <= 99999,
      },
      '100,000 - 150,000': {
        label: '100,000 - 150,000',
        minValue: 100000,
        maxValue: 150000,
        matcher: (i) =>
          !!i.listing.mileage && i.listing.mileage >= 100000 && i.listing.mileage <= 150000,
      },
      '>150,000': {
        label: '>150,000',
        minValue: 150001,
        maxValue: Infinity,
        matcher: (i) => !!i.listing.mileage && i.listing.mileage > 150000,
      },
    },
  },
];
