import React, { ReactNode } from 'react';

import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import Spacer from 'Sparky/Spacer';
import Text from 'Sparky/Text';
import Tooltip, { TooltipProps } from 'Sparky/Tooltip';

import styles from './Chip.module.scss';

export type ChipColors =
  | 'blue'
  | 'purple'
  | 'pink'
  | 'lime'
  | 'teal'
  | 'grey'
  | 'black'
  | 'orange'
  | 'green'
  | 'red'
  | 'yellow'
  | 'navy';

export interface ChipProps extends React.ComponentPropsWithoutRef<'div'> {
  /** Text content to render */
  children?: ReactNode;
  variant?: 'outline' | 'filled' | 'text' | 'notSelected' | 'selected';
  color?: ChipColors;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  shape?: 'pill' | 'tag';

  handleClick?(): void;

  handleRightIconClick?(): void;

  handleLeftIconClick?(): void;

  handleDismiss?(): void;

  tooltip?: string;
  tooltipSide?: TooltipProps['side'];
  openTooltip?: boolean;
  className?: string;
  size?: 'sm' | 'md';
}

/**
 * Pill
 */
const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      children,
      variant = 'outline',
      color = 'blue',
      leftIcon,
      rightIcon,
      shape = 'pill',
      handleClick,
      handleRightIconClick,
      handleLeftIconClick,
      handleDismiss,
      className,
      tooltip,
      tooltipSide,
      openTooltip,
      size = 'md',
    },
    ref
  ) => {
    if (variant === 'selected' || variant === 'notSelected') {
      return (
        <div
          onClick={handleClick}
          className={classnames(
            {
              [styles.chip]: true,
              [styles.small]: size === 'sm',
              [styles.tag]: shape === 'tag',
              [styles.textOnly]: !(leftIcon || rightIcon),
              [styles.chipNavy]: true,
              [styles.notSelected]: variant === 'notSelected',
              [styles.selected]: variant === 'selected',
            },
            className
          )}
        >
          <Tooltip content={tooltip} disabled={!tooltip} open={openTooltip} side={tooltipSide}>
            <div className={styles.content}>
              {leftIcon && <div className={styles.leftIconWrap}>{leftIcon}</div>}
              {children && (
                <>
                  <Spacer size={2} axis='horizontal' />
                  <Text
                    type='helper-text-01'
                    color={variant === 'selected' ? 'white' : 'navy'}
                    textWrap='nowrap'
                    className={styles.children}
                  >
                    {children}
                  </Text>
                  <Spacer size={2} axis='horizontal' />
                </>
              )}
              {rightIcon && <div className={styles.rightIconWrap}>{rightIcon}</div>}
              {variant === 'selected' && handleDismiss && (
                <IconX size={12} onClick={handleDismiss} />
              )}
            </div>
          </Tooltip>
        </div>
      );
    }

    return (
      <div
        ref={ref}
        onClick={handleClick}
        className={classnames(
          {
            [styles.chip]: true,
            [styles.small]: size === 'sm',
            [styles.pill]: shape === 'pill',
            [styles.tag]: shape === 'tag',
            [styles.textOnly]: !(leftIcon || rightIcon),
            [styles.chipBlue]: color === 'blue',
            [styles.chipPurple]: color === 'purple',
            [styles.chipPink]: color === 'pink',
            [styles.chipTeal]: color === 'teal',
            [styles.chipGrey]: color === 'grey',
            [styles.chipBlack]: color === 'black',
            [styles.chipOrange]: color === 'orange',
            [styles.chipLime]: color === 'lime',
            [styles.chipGreen]: color === 'green',
            [styles.chipRed]: color === 'red',
            [styles.chipYellow]: color === 'yellow',
            [styles.chipNavy]: color === 'navy',
            [styles.text]: variant === 'text',
            [styles.outline]: variant === 'outline',
            [styles.filled]: variant === 'filled',
            [styles.handleClick]: handleClick !== undefined,
          },
          className
        )}
      >
        <Tooltip content={tooltip} disabled={!tooltip} open={openTooltip} side={tooltipSide}>
          <div className={styles.content}>
            {leftIcon &&
              (variant === 'text' ? (
                <div className={styles.textIcon} />
              ) : (
                <div
                  onClick={(e) => {
                    if (handleLeftIconClick) {
                      e.stopPropagation();
                      handleLeftIconClick();
                    }
                  }}
                  className={classnames({
                    [styles.leftIconWrap]: true,
                    [styles.handleLeftIconClick]: handleLeftIconClick !== undefined,
                  })}
                >
                  {leftIcon}
                </div>
              ))}
            {children && (
              <>
                <Spacer size={2} axis='horizontal' />
                <Text
                  type='helper-text-01'
                  className={classnames([styles.children, size === 'sm' && styles.small])}
                >
                  {children}
                </Text>
                <Spacer size={2} axis='horizontal' />{' '}
              </>
            )}
            {rightIcon && (
              <div
                onClick={(e) => {
                  if (handleRightIconClick) {
                    e.stopPropagation();
                    handleRightIconClick();
                  }
                }}
                className={classnames({
                  [styles.rightIconWrap]: true,
                  [styles.rightIconWrapBorderRadius]: shape === 'pill',
                  [styles.handleRightIconClick]: handleRightIconClick !== undefined,
                })}
              >
                {rightIcon}
              </div>
            )}
            {handleDismiss && (
              <div
                className={classnames({
                  [styles.dismissWrap]: true,
                  [styles.dismissWrapBorderRadius]: shape === 'pill',
                })}
                onClick={handleDismiss}
              >
                <IconX />
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    );
  }
);

export default Chip;
