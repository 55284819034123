import { useMutation } from '@tanstack/react-query';

import ENDPOINTS from 'api/utils/endpoints';
import { post } from 'api/utils/fetch';
import { userSchema } from 'pages/SignInPage/api/login/userSchema';
import { User } from 'types/User';

export interface LoginData {
  email: string;
  password: string;
}

export async function login(data: LoginData) {
  const res = await post<User>(ENDPOINTS.login, {
    body: {
      user: data.email,
      pwd: data.password,
    },
  });

  return userSchema.parse(res);
}

export default function useLogin() {
  return useMutation((data: LoginData) => login(data));
}
