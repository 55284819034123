import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { post } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import { TableItemFormat } from 'components/Chat/components/ChatTable/ChatTable';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { assert } from 'util/assert';

export interface InquiryRequest {
  vin: string;
  sessionId: string;
}

export interface ColumnsResponse {
  name: string;
  type: string;
  format: TableItemFormat;
}

export interface TableResponse {
  rows: Record<string, string | number>[];
  columns: Record<string, ColumnsResponse>;
}

export interface InquiryAnswers {
  type: 'TEXT' | 'TABLE';
  tag: 'EM' | 'TABLE' | '';
  value: string | TableResponse;
}

export interface InquiryResponse {
  response_id: number;
  inquiry: string;
  response: string;
  suggested_prompts: string[];
}

interface SubmitInquiryProps {
  token?: string;
  sessionId: string;
  vin: string;
  dealerId?: number;
  userId?: number;
}

async function submitInsight({
  token,
  sessionId,
  vin,
  dealerId,
  userId,
}: SubmitInquiryProps): Promise<InquiryResponse> {
  assert(token);
  assert(dealerId);
  assert(userId);

  return post<InquiryResponse>(ENDPOINTS.insights, {
    headers: { token },
    params: {
      version: 'v1',
    },
    body: {
      vehicle_id: vin,
      dealer_id: dealerId,
      session_id: sessionId,
      user_id: userId,
      source: 'vm_vin_details_modal',
    },
  });
}

export default function useInsight() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);

  return useMutation(({ vin, sessionId }: InquiryRequest) =>
    submitInsight({ token: user?.token, vin, dealerId, userId: user?.id, sessionId })
  );
}
