import React from 'react';

import classnames from 'classnames';

import styles from './Table.module.scss';

/**
 * Table
 */
export default function Table({
  className,
  ...otherProps
}: React.ComponentPropsWithoutRef<'table'>) {
  return <table className={classnames([styles.table, className])} {...otherProps} />;
}
