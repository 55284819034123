import { z } from 'zod';

import { SimilarVINsResponse } from 'pages/Inventory/VinDetailSidebar/api/useSimilarVins/useSimilarVINs';
import { SimilarVINsData } from 'pages/Inventory/VinDetailSidebar/MarketSection/SimilarVINsChart';

export const similarVINsDataSchema: z.ZodSchema<SimilarVINsData> = z.object({
  lastPrice: z.number().optional(),
  mileage: z.number().optional(),
  dol: z.number().optional(),
  vin: z.string(),
  dealerId: z.number(),
  modelYear: z.number(),
  make: z.string(),
  model: z.string(),
  trim: z.string().optional(),
  bodySegment: z.string().optional(),
  firstPrice: z.number().optional(),
});

export const similarVINsResponseSchema: z.ZodSchema<SimilarVINsResponse> = z.object({
  positionToMarket: z.number().optional(),
  marketCount: z.number().optional(),
  avgMarketMileage: z.number().optional(),
  avgMarketMileageSold: z.number().optional(),
  avgMarketDol: z.number().optional(),
  avgMarketDolSold: z.number().optional(),
  avgMarketPrice: z.number().optional(),
  lastOnlinePrice: z.number().optional(),
  soldSimilarMakeModel: z.number().optional(),
  inventory: z.array(similarVINsDataSchema).optional(),
});
