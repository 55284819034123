import { useState } from 'react';

import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import Accordion from 'Sparky/Accordion';
import Button from 'Sparky/Button';
import IconButton from 'Sparky/IconButton';
import Message from 'Sparky/Message';
import Panel from 'Sparky/Panel';
import Popover from 'Sparky/Popover';
import { COLOR } from 'Sparky/styles/vars';
import { ChrevronIcon } from 'Sparky/SVGs/ChevronIcon';
import { HamburgerIcon } from 'Sparky/SVGs/HamburgerIcon';
import Text from 'Sparky/Text';

import { useNavigateKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import SegmentPopper from 'components/Segments/SegmentPopper/SegmentPopper';
import SegmentsButton from 'components/Segments/SegmentsButton/SegmentsButton';
import { defaultMetricAtom } from 'pages/Analytics/MarketOpportunity';
import { MarketOpportunityMetricNames } from 'pages/Analytics/MarketOpportunity/api/useMarketOpportunity/useMarketOpportunity';
import { mapColor } from 'pages/MarketMaps/HexMarketMap';
import { MARKET_MAPS_DEFS, MarketMapsKeys, showMarketMapsAtom } from 'pages/MarketMaps/MarketMaps';
import { CircleIcon } from 'pages/Settings/LocalMarketSettings/icons/CircleIcon';
import { formatNumber } from 'util/formatters';

import styles from './ControlPanel.module.scss';

export interface ControlPanelProps {
  activeMetric: MarketMapsKeys;
}

/**
 * ControlPanel
 */
export default function ControlPanel({ activeMetric }: ControlPanelProps) {
  const [, setDefaultMetric] = useAtom(defaultMetricAtom);
  const [, setShowMarketMaps] = useAtom(showMarketMapsAtom);
  const [showControlPanel, setShowControlPanel] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigateKeepParams();

  const Legend = () => {
    return (
      <Accordion title='Legend' defaultOpen>
        <div className={styles.legend}>
          <div className={styles.legendItemRow}>
            <div className={styles.legendIcon}>
              <CircleIcon color={COLOR.SPARKY_RED_300} />
            </div>
            <Text>Your dealership</Text>
          </div>
          <Text>{MARKET_MAPS_DEFS[activeMetric as MarketOpportunityMetricNames].title}</Text>
          <div
            className={styles.gradiantBar}
            style={{
              background: `linear-gradient(90deg, rgba(130, 175, 247, 0.00) -3.01%, ${mapColor} 100%)`,
            }}
          />
          <div className={styles.minMaxRow}>
            <Text size='10'>{formatNumber(0)}</Text>
            <Text size='10'>{formatNumber(100)}</Text>
          </div>
        </div>
      </Accordion>
    );
  };

  return (
    <div className={styles.segmentSelectorOverlay}>
      {showControlPanel ? (
        <Panel className={styles.segmentPanel}>
          <div className={styles.togglePanelButton}>
            <IconButton
              onClick={() => setShowControlPanel(false)}
              aria-label='toggle-control-panel'
              size='md'
              variants='outline'
            >
              <ChrevronIcon />
            </IconButton>
          </div>
          <Text color='secondary' size='20' fontWeight={7}>
            {MARKET_MAPS_DEFS[activeMetric].title}
          </Text>

          {(activeMetric as MarketMapsKeys) !== 'inStoreTraffic' && (
            <Popover
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              showCloseButton
              isHoverTriggered={false}
              popupContent={<SegmentPopper />}
              contentClassName={styles.segmentPopoverConent}
              verticalOffset={8}
              avoidCollisions
              showCarrat={false}
              side='bottom'
              align='start'
            >
              <div>
                <SegmentsButton numberOfCharacters={11} />
              </div>
            </Popover>
          )}
          <Message children={MARKET_MAPS_DEFS[activeMetric].message} />

          {(activeMetric as MarketMapsKeys) !== 'inStoreTraffic' && (
            <>
              <Legend />
              <div className={styles.moreDataRow}>
                <Button
                  onClick={() => {
                    setDefaultMetric(() => {
                      switch (activeMetric) {
                        case 'inMarketShoppers':
                          return 'demand';
                        case 'salesVolume':
                          return 'sales';
                        default:
                          return activeMetric;
                      }
                    });
                    navigate('analytics/market_opportunity');
                    setShowMarketMaps(false);
                  }}
                >
                  View more data {'   '}
                  <IconArrowNarrowRight />
                </Button>
              </div>
            </>
          )}
        </Panel>
      ) : (
        <IconButton
          onClick={() => setShowControlPanel(true)}
          aria-label='toggle-control-panel'
          size='md'
          variants='outline'
        >
          <HamburgerIcon />
        </IconButton>
      )}
    </div>
  );
}
