import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

import { marketSegmentsResponseSchema } from './marketSegmentsSchema';

interface FetchMarketSegmentsDataProps {
  token?: string;
  dealerId?: number;
}

export interface SegmentData {
  segment: string;
  totalUnits: number;
  pctOfInventory: number;
  pctInCampaign: number;
  pctOfMediaRisk: number;
  highMediaRiskFlag?: boolean;
  riskScore: number;
  option?: string;
  favorite?: boolean;
}

export interface MarketSegmentsResponse {
  favorites: string[];
  [keys: string]: SegmentData[] | string[];
}

async function fetchMarketSegmentsData({ token, dealerId }: FetchMarketSegmentsDataProps) {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  const res = await get<MarketSegmentsResponse>(`${ENDPOINTS.market_segments}/${dealerId}`, {
    headers: { token },
  });

  return marketSegmentsResponseSchema.parse(res);
}

export default function useMarketSegments() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { marketSegmentsKey: queryKey } = useQueryKeys();

  return useQuery(queryKey, () => fetchMarketSegmentsData({ token: user?.token, dealerId }));
}
