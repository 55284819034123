import { useMutation } from '@tanstack/react-query';

import ENDPOINTS from 'api/utils/endpoints';
import { post } from 'api/utils/fetch';
import { assertIsDefined } from 'util/assert';

export interface ResetInitPwdData {
  email: string;
}

/**
 * postResetInitPwd
 * @email string
 * @feEnv enum 'production' | 'demo' | 'staging' | 'development' | 'local'
 */
async function postResetInitPwd(email: string) {
  assertIsDefined(email);

  return post(ENDPOINTS.resetInit, {
    params: {
      feEnv: process.env.REACT_APP_DEPLOY_ENV,
      email: email,
    },
  });
}

export default function useResetInitPwd() {
  return useMutation((data: ResetInitPwdData) => postResetInitPwd(data.email));
}
