import DealerSelect from 'components/DealerSelect/DealerSelect';

import styles from './DealerSelectPage.module.scss';

export default function DealerSelectPage() {
  return (
    <div className={styles.dealerSelectPage}>
      <div className={styles.panel}>
        <DealerSelect />
      </div>
    </div>
  );
}
