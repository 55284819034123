import React from 'react';

import * as Select from '@radix-ui/react-select';
import { SelectItemProps, SelectProps } from '@radix-ui/react-select';
import {
  IconAlertTriangleFilled,
  IconCaretDownFilled,
  IconChevronDown,
  IconCircleCheckFilled,
} from '@tabler/icons-react';
import classnames from 'classnames';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import styles from './SelectInput.module.scss';

export type SelectInputOptions = {
  label: string;
  value: string;
}[];

export interface SelectInputProps extends SelectProps {
  id: string;
  options: SelectInputOptions;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  position?: 'item-aligned' | 'popper';
  variants?: 'default' | 'success' | 'error' | 'warning' | 'no-border';
  disabled?: boolean;
  className?: string;
  itemClassName?: string;
  dropDownClassName?: string;
  size?: 'sm' | 'md';
  container?: HTMLElement | null | undefined;
  placeholder?: string;
  maxHeight?: string;
  prefix?: string;
}

/**
 * SelectInput
 */
export default function SelectInput({
  id,
  options,
  align = 'start',
  side = 'bottom',
  sideOffset = 2,
  position = 'popper',
  variants = 'default',
  disabled = false,
  className,
  itemClassName,
  dropDownClassName,
  size = 'md',
  container,
  placeholder,
  maxHeight,
  prefix,
  name,
  ...props
}: SelectInputProps) {
  return (
    <Select.Root {...props}>
      <Select.Trigger
        id={id}
        className={classnames(
          {
            [styles.selectInput]: true,
            [styles.default]: variants === 'default' && !disabled,
            [styles.success]: variants === 'success' && !disabled,
            [styles.warning]: variants === 'warning' && !disabled,
            [styles.noBorder]: variants === 'no-border' && !disabled,
            [styles.error]: variants === 'error' && !disabled,
            [styles.disabled]: disabled,
            [styles.sm]: size === 'sm',
          },
          className
        )}
        disabled={disabled}
        aria-label={id}
      >
        {prefix && <Text>{prefix}</Text>}
        <Select.Value placeholder={placeholder} />
        <Select.Icon className={styles.variantIcon}>
          {!disabled && variants === 'success' && (
            <IconCircleCheckFilled color={COLOR.SPARKY_GREEN_400} size={18} />
          )}
          {!disabled && variants === 'warning' && (
            <IconAlertTriangleFilled color={COLOR.SPARKY_ORANGE_400} size={18} />
          )}
          {!disabled && variants === 'error' && (
            <IconAlertTriangleFilled color={COLOR.SPARKY_RED_400} size={18} />
          )}
          {variants !== 'no-border' && (
            <Select.Icon className={styles.selectInputDivider}>|</Select.Icon>
          )}
        </Select.Icon>

        <Select.Icon className={styles.selectInputIcon}>
          {variants === 'no-border' ? (
            <IconCaretDownFilled size='16' color={COLOR.SPARKY_GREY_400} />
          ) : (
            <IconChevronDown size='16' color={COLOR.SPARKY_GREY_400} />
          )}
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal style={{ maxHeight: maxHeight }} container={container}>
        <Select.Content
          position={position}
          side={side}
          sideOffset={sideOffset}
          align={align}
          className={classnames([styles.selectInputContent, dropDownClassName])}
        >
          <Select.Viewport>
            {options.map((option) => (
              <SelectItem key={option.value} value={option.value} className={itemClassName}>
                {option.label}
              </SelectItem>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}

interface ItemProps extends SelectItemProps {
  value: string;
  children: React.ReactNode;
  className?: string;
}

const SelectItem = React.forwardRef(
  (
    { children, className, ...props }: ItemProps,
    forwardedRef: React.Ref<HTMLDivElement> | undefined
  ) => {
    return (
      <Select.Item
        className={classnames([styles.selectItem, className])}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
      </Select.Item>
    );
  }
);
