import { useAtom } from 'jotai';
import { datePeriodAtom } from 'store/datePeriodAtom';

import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';

type QueryKey = (string | number | boolean | null | undefined)[];

interface QueryKeys {
  inventoryKey: QueryKey;
  inventoryMTDKey: QueryKey;
  inventoryItemKey: QueryKey;
  dealerListKey: QueryKey;
  marketDemandDataKey: QueryKey;
  marketplaceAppsKey: QueryKey;
  marketplaceChannelsKey: QueryKey;
  keepAliveKey: QueryKey;
  invTableConfigKey: QueryKey;
  similarVinsKey: QueryKey;
  VDPPriceDataKey: QueryKey;
  VVODataKey: QueryKey;
  VVOSpendDataKey: QueryKey;
  DashboardLayoutsKey: QueryKey;
  marketSegmentsKey: QueryKey;
  leadsKey: QueryKey;
  geoConfigKey: QueryKey;
  lxVideoConfigKey: QueryKey;
  vmxAccess: QueryKey;
  permissionRequests: QueryKey;
  showroomKey: QueryKey;
  photoAiKey: QueryKey;
  campaignDefaults: QueryKey;
  campaignPromos: QueryKey;
  campaignAuthorizedList: QueryKey;
  campaignConversionReportKey: QueryKey;
  tooltips: QueryKey;
  eventsKey: QueryKey;
  inventoryBreakdownKey: QueryKey;
  acquisitionKey: QueryKey;
  carryoverChartBreakdownKey: QueryKey;
  vinPathPriceKey: QueryKey;
  cacheStatusKey: QueryKey;
}

/** Hook that provides the query key for all queries/mutations */

export default function useQueryKeys(params?: {
  vin?: string;
  enable?: boolean;
  VVOStartDate?: string;
  VVOEndDate?: string;
  status?: string;
  isMTD?: boolean;
  pageKey?: string;
}): QueryKeys {
  const [user] = useAtom(userAtom);
  const [dealerId] = useSelectedDealerId();
  const [datePeriod] = useAtom(datePeriodAtom);
  const [startDate, endDate] = datePeriod;

  const token = user?.token ?? '';

  return {
    inventoryKey: ['inventory', dealerId, startDate, endDate],
    inventoryMTDKey: ['inventory', dealerId],
    inventoryItemKey: ['inventoryItem', dealerId, params?.vin, startDate, endDate],
    dealerListKey: ['dealerList', user?.id],
    marketDemandDataKey: ['marketDemandData', dealerId, params?.vin, params?.pageKey],
    marketplaceAppsKey: ['marketplaceApps', dealerId, params?.enable],
    marketplaceChannelsKey: ['marketplaceApps', dealerId, params?.enable],
    keepAliveKey: ['keepalive', token],
    invTableConfigKey: ['inventoryTableConfig', user?.id],
    similarVinsKey: ['priceScatterplotData', dealerId, params?.vin, params?.pageKey],
    VDPPriceDataKey: ['vdpPriceData', dealerId, params?.vin, params?.pageKey],
    VVODataKey: ['vvoData', dealerId, params?.VVOStartDate, params?.VVOEndDate, params?.status],
    VVOSpendDataKey: ['vvoSpendData', dealerId, params?.VVOStartDate, params?.VVOEndDate],
    DashboardLayoutsKey: ['dashboardLayouts', dealerId, user?.id],
    marketSegmentsKey: ['marketSegments', dealerId, user?.id],
    leadsKey: ['leads', dealerId, params?.vin, params?.pageKey],
    geoConfigKey: ['geoConfig', dealerId, user?.id],
    lxVideoConfigKey: ['lxVideoConfig', dealerId, user?.id],
    vmxAccess: ['vmxAccess', dealerId, user?.id],
    permissionRequests: ['permissionRequests', dealerId, user?.id],
    showroomKey: ['showroomConfig', dealerId, user?.id],
    photoAiKey: ['photoAi', dealerId, user?.id],
    campaignDefaults: ['campaignDefaults', dealerId, user?.id],
    campaignPromos: ['campaignPromos', user?.id],
    campaignAuthorizedList: ['campaignAuthorizedList', dealerId, user?.id],
    campaignConversionReportKey: ['campaignConversionReport', dealerId],
    tooltips: ['tooltips', token],
    eventsKey: ['events', dealerId],
    inventoryBreakdownKey: ['inventoryBreakdown', dealerId],
    acquisitionKey: ['acquisition', dealerId],
    carryoverChartBreakdownKey: ['carryoverChartBreakdown', dealerId],
    vinPathPriceKey: ['vinPathPrice', dealerId, params?.vin],
    cacheStatusKey: ['cacheStatus', dealerId],
  };
}
