import { useSearchParams } from 'react-router-dom';

/** Returns the current Dealer (or undefined), and a function to switch dealers */
export default function useSelectedDealerId(): [number | undefined, (id: number) => void] {
  const [params, setParams] = useSearchParams();
  const idParam = params.get('dealerId');
  const dealerId = idParam ? parseInt(idParam) : undefined;

  return [
    dealerId,
    (id: number) => {
      params.set('dealerId', id.toString());
      setParams(params);
    },
  ];
}
