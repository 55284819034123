import { useScreenSize, SIZE } from 'Sparky/hooks';
import Text from 'Sparky/Text';

import Page from 'components/Page/Page';

import styles from './NotFound.module.scss';

export default function NotFound() {
  const screenSize = useScreenSize();
  return (
    <div className={styles.container}>
      <Text size={screenSize < SIZE.SM ? '48' : '56'} fontWeight={7} className={styles.text}>
        404
      </Text>
      <Text size='20' fontWeight={7}>
        Page not found
      </Text>
    </div>
  );
}

export function Component() {
  return (
    <Page title='404'>
      <NotFound />
    </Page>
  );
}
Component.displayName = 'NotFoundPage';
