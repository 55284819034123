import { CSSProperties, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import styles from 'App.module.scss';
import { useAtom } from 'jotai';
import throttle from 'lodash/throttle';
import { Outlet } from 'react-router-dom';
import ROUTES from 'routes';
import { ToastProvider } from 'Sparky/Toast';
import { tooltipAtom } from 'store/tooltipAtom';

import { APIError, ErrorCode } from 'api/utils';
import Authenticator from 'components/Authenticator/Authenticator';
import ChatModal from 'components/Chat/ChatModal';
import ErrorFallback from 'components/ErrorFallback/ErrorFallback';
import KeepAlive from 'components/KeepAlive/KeepAlive';
import Navbar from 'components/Navbar';
import Page from 'components/Page/Page';
import Sidebar, { sidebarAtom, widthCollapsed, widthExpanded } from 'components/Sidebar/Sidebar';
import useLDUserTracker from 'hooks/useLDUserTracker';
import useRouteTitle from 'hooks/useRouteTitle';
import LexChat from 'pages/LexChat';
import MarketMaps from 'pages/MarketMaps';
import useTooltips from 'pages/Settings/TooltipSettings/api/useTooltipSettings/useTooltipSettings';
import SignInPage from 'pages/SignInPage/SignInPage';
import { userAtom } from 'store';
import { REFRESH_TOKEN } from 'types/Events';

const dispatchRefreshEvent = throttle(() => window.dispatchEvent(new Event(REFRESH_TOKEN)), 10000);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: process.env.NODE_ENV === 'production' ? 3 : false,
      onError: (err) => {
        // If any query fails due to an invalid token, we need to sign in again to get a new token
        // Since `setUser` is returned by a hook, we can't access it here,
        //  so dispatch an event and let `Authenticator` handle it
        if ((err as APIError).subCode === ErrorCode.ACCESS_DENIED) {
          dispatchRefreshEvent();
        } else if ((err as APIError).subCode === ErrorCode.DEALER_ACCESS_DENIED) {
          // Don't record DEALER_ACCESS_DENIED errors
        } else {
          // Send any other error to Sentry
          Sentry.captureException(err);
        }
      },
    },
  },
});

export default function App() {
  const [user] = useAtom(userAtom);
  const title = useRouteTitle(ROUTES);

  useLDUserTracker();

  function returnUserInfo() {
    return {
      visitor: { id: user?.id, email: user?.email },
    };
  }

  //* Make the account information available in the inspector console to be brought into data layer for GTM
  useEffect(() => {
    if (user) {
      /* @ts-ignore */
      window.getVisitorInfo = returnUserInfo();
    }
  }, [user]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <ReactQueryDevtools position='bottom-right' />
          <KeepAlive />
          <Authenticator>
            {user && !user.forcePwChange ? (
              <AuthenticatedApp />
            ) : (
              <Page title='Sign In'>
                <SignInPage />
              </Page>
            )}
          </Authenticator>
        </ToastProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

/**
 * Routes for authenticated users
 */
function AuthenticatedApp() {
  const [expanded] = useAtom(sidebarAtom);
  const { isLoading, data } = useTooltips();
  const [, setTooltipAtom] = useAtom(tooltipAtom);
  const [user] = useAtom(userAtom);

  useEffect(() => {
    if (!isLoading && data) {
      setTooltipAtom(data);
    }
  }, [data, isLoading]);

  return (
    <div className={styles.main}>
      <div className={styles.nav}>
        <Navbar />
      </div>
      <div className={styles.content}>
        <Sidebar />
        <div
          className={styles.page}
          style={{ '--sidebar-width': expanded ? widthExpanded : widthCollapsed } as CSSProperties}
        >
          {' '}
          <Outlet key={user?.token} />
        </div>
      </div>
      <ChatModal />
      <MarketMaps />
      <LexChat />
    </div>
  );
}
