import { useState } from 'react';

import {
  IconCopy,
  IconMessageDots,
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled,
} from '@tabler/icons-react';
import Button from 'Sparky/Button';
import IconButton from 'Sparky/IconButton';
import Popover from 'Sparky/Popover';
import Text from 'Sparky/Text';
import useToast from 'Sparky/Toast';

import useSubmitFeedback from 'pages/LexChat/FeedbackBar/api/useSubmitFedback/useSubmitFedback';

import styles from './FeedbackBar.module.scss';

interface FeedbackBarProps {
  responseId: string;
  response: string | undefined;
}

export function FeedbackBar({ responseId, response }: FeedbackBarProps) {
  const [isPositive, setIsPositive] = useState<boolean | null>(null);
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [feedbackText, setFeedbackText] = useState<string>('');
  const { addToast } = useToast();
  const mutation = useSubmitFeedback();

  const submitFeedback = (
    <div className={styles.feedback}>
      <Text
        className={styles.feedbackTitle}
        color='secondary'
        size={16}
        lineHeight='24px'
        fontWeight={5}
      >
        Give Lex some feedback
      </Text>
      <textarea
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.currentTarget.value)}
        placeholder='Submit your feedback directly to our development team so Lex can be continuously improved upon.'
      ></textarea>
      <Button
        onClick={() => {
          if (responseId) {
            mutation.mutate({
              responseId: Number.parseInt(responseId),
              text: feedbackText,
              isPositive: isPositive,
            });
            setIsSubmitted(true);
            setFeedbackText('');
            setIsPositive(null);
          }
        }}
        className={styles.feedbackButton}
        size='sm'
        variant='solid'
      >
        Submit
      </Button>
    </div>
  );

  const feedbackRecieved = (
    <div className={styles.feedback}>
      <Text size={16} fontWeight={5} lineHeight='24px' color='secondary'>
        Thank you for your feedback!
      </Text>
      <Text className={styles.responseText} size={14} fontWeight={4} lineHeight='17px'>
        Your message has been sent to our development team and will be used to improve upon Lex and
        the quality of it’s responses in the future.
      </Text>
      <Button
        onClick={() => {
          setIsSubmitted(false);
          setFeedbackText('');
        }}
        className={styles.feedbackButton}
        size='sm'
        variant='solid'
      >
        Send more feedback
      </Button>
    </div>
  );
  return (
    <div className={styles.feedbackBar}>
      <IconButton
        aria-label='thumbs-up'
        tooltip='Good response'
        className={styles.icon}
        onClick={() => {
          if (isPositive !== null && isPositive) {
            setIsPositive(null);
          } else {
            setIsPositive(true);
            setIsPopperOpen(true);
          }
        }}
      >
        {isPositive !== null && isPositive ? (
          <IconThumbUpFilled size={16} />
        ) : (
          <IconThumbUp size={16} />
        )}
      </IconButton>
      <IconButton
        aria-label='thumbs-down'
        tooltip='Bad response'
        className={styles.icon}
        onClick={() => {
          if (isPositive !== null && !isPositive) {
            setIsPositive(null);
          } else {
            setIsPositive(false);
            setIsPopperOpen(true);
          }
        }}
      >
        {isPositive !== null && !isPositive ? (
          <IconThumbDownFilled size={16} />
        ) : (
          <IconThumbDown size={16} />
        )}
      </IconButton>
      <Popover
        isOpen={isPopperOpen}
        setIsOpen={setIsPopperOpen}
        verticalOffset={20}
        horizontalOffset={-22}
        side='bottom'
        contentClassName={styles.popoverContent}
        popupContent={isSubmitted ? feedbackRecieved : submitFeedback}
      >
        <IconButton aria-label='leave-feedback' className={styles.icon} tooltip='Leave feedback'>
          <IconMessageDots
            size={16}
            onClick={() => {
              setIsPopperOpen(!isPopperOpen);
              setIsSubmitted(false);
            }}
          />
        </IconButton>
      </Popover>
      <IconButton
        aria-label='copy'
        className={styles.icon}
        tooltip='Copy response'
        onClick={() => {
          navigator.clipboard.writeText(response || '');
          addToast({
            status: 'success',
            duration: 2500,
            description: 'Copied to clipboard',
          });
        }}
      >
        <IconCopy size={16} />
      </IconButton>
    </div>
  );
}
