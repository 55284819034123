import React from 'react';

import Button from 'Sparky/Button';
import CheckboxDropdownField from 'Sparky/CheckboxDropdownField';
import CheckboxField from 'Sparky/CheckboxField';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import Text from 'Sparky/Text';

import TooltipIcon from 'components/TooltipIcon';
import {
  defaultCurrentProbabilityOfSale,
  defaultMarkdownRange,
  defaultProbabilityAfterPriceChange,
} from 'pages/Inventory/VinPath';
import RangeSlider from 'pages/Inventory/VinPath/VinPathSearchBox/RangeSlider';
import { ProbabilityOfSaleKeys } from 'types/InventoryItem';
import { ProbabilityInterface } from 'types/VinPath';

import styles from './VinPathSearchBox.module.scss';

export interface VinPathSearchBoxProps {
  currentProbabilityCounts: Record<ProbabilityOfSaleKeys, number>;
  currentProbabilityOfSale: ProbabilityInterface;
  setCurrentProbabilityOfSale: React.Dispatch<React.SetStateAction<ProbabilityInterface>>;
  probabilityAfterPriceChange: ProbabilityInterface;
  setProbabilityAfterPriceChange: React.Dispatch<React.SetStateAction<ProbabilityInterface>>;
  markdownTolerance: boolean;
  setMarkdownTolerance: React.Dispatch<React.SetStateAction<boolean>>;
  markdownRangeLimit: number[];
  markdownRange: number[];
  setMarkdownRange: React.Dispatch<React.SetStateAction<number[]>>;
}

/**
 * VinPathSearchBox
 */
export default function VinPathSearchBox({
  currentProbabilityCounts,
  currentProbabilityOfSale,
  setCurrentProbabilityOfSale,
  probabilityAfterPriceChange,
  setProbabilityAfterPriceChange,
  markdownTolerance,
  setMarkdownTolerance,
  markdownRangeLimit,
  markdownRange,
  setMarkdownRange,
}: VinPathSearchBoxProps) {
  const onReset = () => {
    setCurrentProbabilityOfSale(defaultCurrentProbabilityOfSale);
    setProbabilityAfterPriceChange(defaultProbabilityAfterPriceChange);
    setMarkdownTolerance(false);
    setMarkdownRange(markdownRangeLimit);
  };

  const onSetMarkdownTolerance = () => {
    setMarkdownRange(markdownRangeLimit);
    setMarkdownTolerance(!markdownTolerance);
  };

  return (
    <Panel className={styles.vinPathSearchBox}>
      <Text size={20} fontWeight={7} color='secondary'>
        Find your next best price
      </Text>
      <Flex alignItems='flex-end' columnGap='12px' width='100%'>
        <CheckboxDropdownField
          options={[
            {
              label: 'Highly likely',
              labelInDropdown: (
                <Flex columnGap='4px' alignItems='center'>
                  <Text>Highly Likely</Text>
                  <Text
                    size='12'
                    color={currentProbabilityCounts.highlyLikely === 0 ? 'disabled' : 'secondary'}
                  >
                    ({currentProbabilityCounts.highlyLikely} VIN
                    {currentProbabilityCounts.highlyLikely === 1 ? '' : 's'})
                  </Text>
                </Flex>
              ),
              value: 'highlyLikely',
              disabled: currentProbabilityCounts.highlyLikely === 0,
            },
            {
              label: 'Likely',
              labelInDropdown: (
                <Flex columnGap='4px' alignItems='center'>
                  <Text>Likely</Text>
                  <Text
                    size='12'
                    color={currentProbabilityCounts.likely === 0 ? 'disabled' : 'secondary'}
                  >
                    ({currentProbabilityCounts.likely} VIN
                    {currentProbabilityCounts.likely === 1 ? '' : 's'})
                  </Text>
                </Flex>
              ),
              value: 'likely',
              disabled: currentProbabilityCounts.likely === 0,
            },
            {
              label: 'Neutral',
              labelInDropdown: (
                <Flex columnGap='4px' alignItems='center'>
                  <Text>Neutral</Text>
                  <Text
                    size='12'
                    color={currentProbabilityCounts.neutral === 0 ? 'disabled' : 'secondary'}
                  >
                    ({currentProbabilityCounts.neutral} VIN
                    {currentProbabilityCounts.neutral === 1 ? '' : 's'})
                  </Text>
                </Flex>
              ),
              value: 'neutral',
              disabled: currentProbabilityCounts.neutral === 0,
            },
            {
              label: 'Unlikely',
              labelInDropdown: (
                <Flex columnGap='4px' alignItems='center'>
                  <Text>Unlikely</Text>
                  <Text
                    size='12'
                    color={currentProbabilityCounts.unlikely === 0 ? 'disabled' : 'secondary'}
                  >
                    ({currentProbabilityCounts.unlikely} VIN
                    {currentProbabilityCounts.unlikely === 1 ? '' : 's'})
                  </Text>
                </Flex>
              ),
              value: 'unlikely',
              disabled: currentProbabilityCounts.unlikely === 0,
            },
            {
              label: 'Highly unlikely',
              labelInDropdown: (
                <Flex columnGap='4px' alignItems='center'>
                  <Text>Highly unlikely</Text>
                  <Text
                    size='12'
                    color={currentProbabilityCounts.highlyUnlikely === 0 ? 'disabled' : 'secondary'}
                  >
                    ({currentProbabilityCounts.highlyUnlikely} VIN
                    {currentProbabilityCounts.highlyUnlikely === 1 ? '' : 's'})
                  </Text>
                </Flex>
              ),
              value: 'highlyUnlikely',
              disabled: currentProbabilityCounts.highlyUnlikely === 0,
            },
          ]}
          checkedMap={currentProbabilityOfSale}
          setCheckedMap={setCurrentProbabilityOfSale}
          label='Current probability of sale'
          id='Current probability of sale'
        />
        <CheckboxDropdownField
          options={[
            { label: 'Highly Likely', labelInDropdown: 'Highly Likely', value: 'highlyLikely' },
            { label: 'Likely', labelInDropdown: 'Likely', value: 'likely' },
            { label: 'Neutral', labelInDropdown: 'Neutral', value: 'neutral' },
            {
              label: 'Unlikely',
              labelInDropdown: (
                <Text>
                  Unlikely{' '}
                  <Text size='12' color='tertiary'>
                    *not recommended
                  </Text>
                </Text>
              ),
              value: 'unlikely',
            },
          ]}
          checkedMap={probabilityAfterPriceChange}
          setCheckedMap={setProbabilityAfterPriceChange}
          label='Probability after price change'
          id='Probability after price change'
        />
        <Button
          onClick={onReset}
          disabled={
            JSON.stringify(currentProbabilityOfSale) ===
              JSON.stringify(defaultCurrentProbabilityOfSale) &&
            JSON.stringify(probabilityAfterPriceChange) ===
              JSON.stringify(defaultProbabilityAfterPriceChange) &&
            !markdownTolerance &&
            (markdownRange === defaultMarkdownRange || markdownRange === markdownRangeLimit)
          }
        >
          Reset to default
        </Button>
      </Flex>
      <CheckboxField checked={markdownTolerance} onClick={onSetMarkdownTolerance}>
        Set markdown tolerance{' '}
        <TooltipIcon
          tipText='The minimum and maximum dollar amount that you are willing to markdown your vehicles by'
          side='top'
          className={styles.tootipIcon}
        />
      </CheckboxField>
      {markdownTolerance && (
        <RangeSlider
          min={markdownRangeLimit[0]}
          max={markdownRangeLimit[1]}
          rangeValue={markdownRange}
          setRangeValue={setMarkdownRange}
        />
      )}
    </Panel>
  );
}
