import classnames from 'classnames';
import Chip from 'Sparky/Chip';
import SelectInput from 'Sparky/SelectInput';

import { budgetOptions } from 'pages/Inventory/InventoryPage/AddBudgetSidebar';
import VinDetails from 'pages/Inventory/InventoryPage/AddBudgetSidebar/components/VinDetails';
import { InventoryItem, InventoryStatus } from 'types/InventoryItem';
import { toTitleCase } from 'util/formatters';

import styles from './AddBudgetItem.module.scss';

const statusColors = (status: AddBudgetItemStatus) => {
  if (status === 'SOLD' || status === 'PENDING') {
    return 'red';
  } else if (status === 'UPDATED') {
    return 'green';
  } else if (status === 'INACTIVE') {
    return 'grey';
  } else {
    return 'blue';
  }
};

export type AddBudgetItemStatus = InventoryStatus | 'UPDATED' | 'INACTIVE';

export interface AddBudgetItemProps {
  item: InventoryItem;
  status: AddBudgetItemStatus;
  budget: string;
  onUpdateBudget(vin: string, budget: string): void;
}

export default function AddBudgetItem({
  item,
  status,
  budget,
  onUpdateBudget,
}: AddBudgetItemProps) {
  const isActive = status === 'ACTIVE';
  return (
    <div className={classnames([styles.addBudgetItem, !isActive && styles.disabled])}>
      <img
        src={item.listing.primaryImage}
        alt=''
        key={item.listing.vin}
        className={classnames([
          styles.image,
          process.env.REACT_APP_DEPLOY_ENV === 'demo' && styles.blur,
        ])}
      />
      <VinDetails
        modelYear={item.vehicle.modelYear}
        make={item.vehicle.make}
        model={item.vehicle.model}
        trim={item.vehicle.trim}
        vin={item.listing.vin}
        stockNumber={item.listing.stockNumber}
        exteriorColor={item.listing.exteriorColor}
      />
      <div className={styles.status}>
        {!isActive ? (
          <Chip variant='filled' color={statusColors(status)}>
            {toTitleCase(status)}
          </Chip>
        ) : (
          <SelectInput
            id={item.listing.vin}
            data-testid={item.listing.vin}
            aria-label={`Increase budget for VIN # ${item.listing.vin}`}
            value={budget}
            className={styles.selectInput}
            onValueChange={(e) => onUpdateBudget(item.listing.vin, e.toString())}
            options={budgetOptions}
          />
        )}
      </div>
    </div>
  );
}
