import React from 'react';

import classnames from 'classnames';
import Spacer from 'Sparky/Spacer';

import styles from './TextInputField.module.scss';
import TextInput, { TextInputProps } from '../TextInput';

export interface TextInputFieldProps extends TextInputProps {
  label: string;
  labelColor?: string;
  info?: string;
  onInfoClick?(): void;
  message?: string;
  messageClassname?: string;
  required?: boolean;
}

/**
 * TextInputField
 */
const TextInputField = React.forwardRef<HTMLInputElement, TextInputFieldProps>(
  (
    {
      label,
      labelColor,
      info,
      onInfoClick,
      message,
      variants,
      disabled,
      messageClassname,
      required = false,
      ...otherProps
    },
    ref
  ) => {
    return (
      <div className={classnames({ [styles.textInputField]: true }, otherProps.className)}>
        <div className={styles.labelField}>
          <label htmlFor={otherProps.id} className={styles.label} style={{ color: labelColor }}>
            {label}
            {required && <span className={styles.requiredIndicator}> *</span>}
          </label>
          <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
          {info && (
            <span onClick={onInfoClick} className={styles.info}>
              {info}
            </span>
          )}
        </div>
        <TextInput
          ref={ref}
          {...otherProps}
          disabled={disabled}
          variants={variants}
          size='md'
          className={styles.textInput}
        />
        {message && (
          <div
            className={classnames(
              {
                [styles.message]: true,
                [styles.default]: variants === 'default' && !disabled,
                [styles.success]: variants === 'success' && !disabled,
                [styles.warning]: variants === 'warning' && !disabled,
                [styles.error]: variants === 'error' && !disabled,
                [styles.disabled]: disabled,
              },
              messageClassname
            )}
          >
            {message}
          </div>
        )}
      </div>
    );
  }
);

export default TextInputField;
