import { useMemo } from 'react';

import * as Popover from '@radix-ui/react-popover';
import { IconChevronDown, IconStarFilled } from '@tabler/icons-react';
import classnames from 'classnames';
import Chip from 'Sparky/Chip';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import useWindowSize from 'hooks/useWindowSize';
import { InventoryConfig } from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';
import { ButtonSize } from 'pages/Inventory/InventoryPage/InventoryPage';

import styles from './FavouritesSelect.module.scss';

export interface FavouritesSelectProps {
  inventoryConfigs: InventoryConfig[];
  currentInventoryConfig: InventoryConfig;
  onSelectInventoryConfig(inventoryConfig: InventoryConfig): void;
  buttonSize: ButtonSize;
  isVinSelected: boolean;
}

/**
 * FavouritesSelect
 */
export default function FavouritesSelect({
  inventoryConfigs,
  currentInventoryConfig,
  onSelectInventoryConfig,
  buttonSize,
  isVinSelected,
}: FavouritesSelectProps) {
  const { width } = useWindowSize();

  let showFirstXFavouriteInventoryConfig: number;
  if (width >= 2400) {
    showFirstXFavouriteInventoryConfig = 5;
  } else if (width < 2400 && width >= 2100) {
    showFirstXFavouriteInventoryConfig = isVinSelected ? 4 : 5;
  } else if (width < 2100 && width >= 1900) {
    showFirstXFavouriteInventoryConfig = isVinSelected ? 3 : 5;
  } else if (width < 1900 && width >= 1700) {
    showFirstXFavouriteInventoryConfig = isVinSelected ? 2 : 4;
  } else {
    showFirstXFavouriteInventoryConfig = isVinSelected ? 1 : 3;
  }

  const favouriteInventoryConfigList = useMemo(
    () => inventoryConfigs.filter((inventoryConfig) => inventoryConfig.isFavourite),
    [inventoryConfigs]
  );

  const firstXFavouriteInventoryConfigList = useMemo(
    () => favouriteInventoryConfigList.slice(0, showFirstXFavouriteInventoryConfig),
    [showFirstXFavouriteInventoryConfig, favouriteInventoryConfigList]
  );

  const moreFavouriteInventoryConfigList = useMemo(
    () => favouriteInventoryConfigList.slice(showFirstXFavouriteInventoryConfig),
    [showFirstXFavouriteInventoryConfig, favouriteInventoryConfigList]
  );

  return (
    <div className={styles.favouritesSelect}>
      {firstXFavouriteInventoryConfigList.map((inventoryConfig, index) => (
        <Chip
          key={`inventoryConfig.id-${index}`}
          shape='tag'
          variant={currentInventoryConfig.id === inventoryConfig.id ? 'filled' : 'outline'}
          leftIcon={<IconStarFilled color={COLOR.SPARKY_YELLOW_400} size='16' />}
          color={currentInventoryConfig.id === inventoryConfig.id ? 'blue' : 'grey'}
          handleClick={() => onSelectInventoryConfig(inventoryConfig)}
        >
          {inventoryConfig.name}
        </Chip>
      ))}

      {moreFavouriteInventoryConfigList.length > 0 && (
        <Popover.Root>
          <Popover.Trigger className={styles.moreFavouriteInventoryConfigs}>
            <Text size='12' fontWeight={5} color='secondary' className={styles.moreFavourites}>
              + {moreFavouriteInventoryConfigList.length}
            </Text>
            <IconChevronDown
              size={buttonSize === 'md' ? '20' : '12'}
              color={COLOR.TEXT_SECONDARY}
              className={styles.icon}
            />
          </Popover.Trigger>
          <Popover.PopoverPortal>
            <Popover.Content
              align='end'
              side='bottom'
              sideOffset={6}
              className={styles.moreFavouriteInventoryConfigsPopover}
            >
              {moreFavouriteInventoryConfigList.map((inventoryConfig) => (
                <button
                  key={inventoryConfig.id}
                  onClick={() => onSelectInventoryConfig(inventoryConfig)}
                  className={classnames([
                    styles.option,
                    currentInventoryConfig.id === inventoryConfig.id && styles.selectedOption,
                  ])}
                >
                  <Text size={buttonSize === 'md' ? '16' : '12'}>{inventoryConfig.name}</Text>
                  <IconStarFilled color={COLOR.SPARKY_YELLOW_400} size='16' />
                </button>
              ))}
            </Popover.Content>
          </Popover.PopoverPortal>
        </Popover.Root>
      )}
    </div>
  );
}
