import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { post } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

interface submitFedbackProps {
  token?: string;
  responseId: number;
  text: string;
  isPositive: boolean | null;
}

/**
 * useSubmitFedback
 * @param prop
 */

async function submitFeedback({ token, responseId, text, isPositive }: submitFedbackProps) {
  assertIsDefined(token);
  const body =
    isPositive !== null
      ? {
          response_id: responseId,
          text,
          isPositive,
        }
      : { response_id: responseId, text };

  return post(ENDPOINTS.lexSubmitFeedback, {
    headers: { token },
    body: body,
  });
}

export default function useSubmitFeedback() {
  const [user] = useAtom(userAtom);
  return useMutation(({ responseId, isPositive, text }: submitFedbackProps) =>
    submitFeedback({ token: user?.token, responseId, isPositive, text })
  );
}
