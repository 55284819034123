export interface ColumnData<T> {
  header: string;
  cell: (item: T) => string | number;
}

/**
 * @param data - Input data
 * @param columns - Column header and function.
 */
export function JSONToCSV<T>(data: T[], columns: ColumnData<T>[]) {
  let output = '';
  //Append headers to csv output and store their respective functions.
  let currentRow: string[] = [];
  columns.forEach((item) => {
    currentRow.push(item.header);
  });
  output += currentRow.join() + '\n';

  //Add all data to csv.
  data.forEach((row) => {
    const currentRow = columns.map((column) => {
      let cellFunction = column.cell; // Current cell's function

      // Surround with quotes to handle commas
      return `"${cellFunction(row)?.toString().replace(/"/g, '""') ?? ''}"`;
    });
    output += currentRow.join() + '\n';
  });

  return output;
}
/**
 * @param csv - downloads a .csv file give a string.
 * @param fileName - name of the file to be downloaded.
 */
export function downloadCSV(csv: string, fileName: string) {
  const csvFile = new Blob([csv], { type: 'text/csv' });

  let downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  downloadLink.className = fileName;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
/**
 * @param data - Input data
 * @param columns - Column header and function.
 * @param fileName - name of the file to be downloaded.
 */
export function exportCSV<T>(data: T[], columns: ColumnData<T>[], fileName: string) {
  const csv = JSONToCSV(data, columns);
  downloadCSV(csv, fileName);
}
