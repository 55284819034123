import { useState } from 'react';

import {
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled,
} from '@tabler/icons-react';
import classnames from 'classnames';
import Markdown from 'react-markdown';
import { TypeAnimation } from 'react-type-animation';
import remarkGfm from 'remark-gfm';
import Divider from 'Sparky/Divider';
import IconButton from 'Sparky/IconButton';
import Spacer from 'Sparky/Spacer';
import Spinner from 'Sparky/Spinner';
import { SPACING } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

// import { InquiryAnswers, TableResponse } from 'components/Chat/api/inquiry/useInquiry';
import useSendRating from 'components/Chat/api/rating/useSendRating';
// import ChatTable from 'components/Chat/components/ChatTable';

import styles from './ChatBubble.module.scss';

export interface ChatBubbleProps {
  question: string;
  responseId?: number;
  answer?: string;
  onDoneTyping?(): void;
}

export default function ChatBubble({
  question,
  responseId,
  answer,
  onDoneTyping,
}: ChatBubbleProps) {
  return (
    <div className={styles.responseWrapper}>
      <div className={styles.chatBubble}>
        <Text fontWeight={7}>{question}</Text>
        <Divider className={styles.divider} />
        {!answer && <ThinkingMessage />}
        {answer && <Markdown remarkPlugins={[remarkGfm]}>{answer}</Markdown>}
      </div>
      {answer && responseId && <Feedback responseId={responseId} />}
    </div>
  );
}

function ThinkingMessage() {
  return (
    <div className={styles.thinking}>
      <span>
        <TypeAnimation
          sequence={[
            'Let me think about that...',
            10000,
            'Working on it...',
            10000,
            'Still deep in thought...',
          ]}
          speed={75}
          wrapper='span'
          cursor={false}
        />
        <span className={styles.cursor} />
      </span>
      <Spinner size='md' />
    </div>
  );
}

interface FeedbackProps {
  responseId: number;
}

function Feedback({ responseId }: FeedbackProps) {
  const [feedback, setFeedback] = useState<undefined | 'up' | 'down'>();
  const mutation = useSendRating();

  const handleClick = (thumbsUp: boolean) => {
    mutation.mutate({ responseId, thumbsUp });
  };

  return (
    <span className={styles.feedbackButtons}>
      <Text>Was this helpful?</Text>
      <Spacer axis='horizontal' size={SPACING.SPACING_08} />
      <IconButton
        size='sm'
        className={classnames([styles.button, feedback === 'up' && styles.selected])}
        aria-label='Like this response'
        disabled={feedback === 'up'}
        onClick={() => {
          setFeedback('up');
          handleClick(true);
        }}
      >
        {feedback === 'up' ? <IconThumbUpFilled size='16' /> : <IconThumbUp size='16' />}
      </IconButton>
      <IconButton
        size='sm'
        className={classnames([styles.button, feedback === 'down' && styles.selected])}
        aria-label='Dislike this response'
        disabled={feedback === 'down'}
        onClick={() => {
          setFeedback('down');
          handleClick(false);
        }}
      >
        {feedback === 'down' ? <IconThumbDownFilled size='16' /> : <IconThumbDown size='16' />}
      </IconButton>
    </span>
  );
}
