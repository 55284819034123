import { useAtom } from 'jotai';
import ReactGA from 'react-ga4';

import useSelectedDealer from 'hooks/useSelectedDealer';
import { userAtom } from 'store';

export default function useReactGA() {
  const { dealer } = useSelectedDealer();
  const [user] = useAtom(userAtom);

  function addReactGA(
    event_name?: string,
    options?: { [k: string]: string | number | undefined },
    extraOptions?: { [k: string]: string | number | undefined }
  ) {
    if (options) {
      ReactGA.gtag('event', event_name, {
        event_name: event_name,
        ...options,
        ...extraOptions,
      });
    } else {
      ReactGA.gtag('event', event_name, {
        event_name: event_name,
        vm_visitor_id: user?.id,
        vm_visitor_email: user?.email,
        vm_account_dealerid: dealer?.id,
        vm_account_name: dealer?.name,
        ...extraOptions,
      });
    }
  }

  return { addReactGA };
}
