import React, { useCallback, useState } from 'react';

import { BaseToastProps, ToastProps } from './Toast';
import ToastContainer from './ToastContainer';

type ToastUtils = {
  addToast(props: BaseToastProps): void;
  removeToast(id: number): void;
};

export const ToastContext = React.createContext<ToastUtils | undefined>(undefined);

let id = 0;

interface ToastProviderProps {
  children: React.ReactNode;
}
/**
 * Place ToastProvider up in the App tree to make { addToast, removeToast } available to the whole tree
 * */
export default function ToastProvider({ children }: ToastProviderProps) {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = useCallback(
    (props: any) => {
      setToasts((prevToasts: ToastProps[]) => [...prevToasts, { id: id++, ...props }]);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (removeId: any) => {
      setToasts((prevToasts: ToastProps[]) => prevToasts.filter((toast) => toast.id !== removeId));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
}
