import { useCallback, useEffect, useRef, useState } from 'react';

import { getResolution, latLngToCell } from 'h3-js';
import { useAtom } from 'jotai';
import Map, { Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import Message from 'Sparky/Message';
import Skeleton from 'Sparky/Skeleton';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import { selectedOptionAtom, selectedSegmentAtom } from 'store/selectSegmentAtom';

import useMarketOpportunity, {
  MapData,
  MapDataPoint,
  MarketOpportunityMetricNames,
} from 'pages/Analytics/MarketOpportunity/api/useMarketOpportunity/useMarketOpportunity';
import {
  popupBottomPositionFilter,
  popupLeftPositionFilter,
  returnGeoJSON,
  returnLayerIntervals,
} from 'pages/Analytics/MarketOpportunity/HexMap';
import { MARKET_MAPS_DEFS, MarketMapsKeys } from 'pages/MarketMaps/MarketMaps';
import useLocalMarketSettings from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';
import { CircleIconWithBorder } from 'pages/Settings/LocalMarketSettings/icons/CircleIcon';
import { formatNumber } from 'util/formatters';

import styles from './HexMarketMap.module.scss';

export interface HexMarketMapProps {
  activeMetric?: MarketMapsKeys;
}

export const mapColor = '#0d67f8';

/**
 * HexMarketMap
 */
export default function HexMarketMap({ activeMetric }: HexMarketMapProps) {
  // eslint-disable-next-line no-undef
  const [popupLocation, setPopupLocation] = useState<
    | {
        lat: number;
        lng: number;
        x: number;
        y: number;
      }
    | undefined
  >(undefined);
  const [selectedSegment] = useAtom(selectedSegmentAtom);
  const [selectedOption] = useAtom(selectedOptionAtom);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<MapDataPoint | undefined>(undefined);
  const [reRenderKey] = useState(Date.now());
  const [activeData, setActiveData] = useState<MapData | undefined>(undefined);
  const ref = useRef<HTMLHeadingElement>(null);
  const [mapDimensions, setMapDimensions] = useState<
    { height: number | undefined; width: number | undefined } | undefined
  >(undefined);

  useEffect(() => {
    setMapDimensions({ height: ref.current?.offsetHeight, width: ref.current?.offsetWidth });
  }, [ref.current]);

  const { data: localMarketData, isLoading: isLocalMarketDataLoading } = useLocalMarketSettings();

  const { data: marketOpportunityData, isLoading: isMarketOpportunityDataLoading } =
    useMarketOpportunity({
      selectedOption: selectedOption,
      selectedSegment: selectedSegment,
    });

  useEffect(() => {
    setActiveData(marketOpportunityData?.mapData[activeMetric as MarketOpportunityMetricNames]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMetric, marketOpportunityData]);

  const currentZoom = activeData?.data?.length ? getResolution(activeData?.data[0].hex) : 8;

  //  SET POPUP TO CURRENT MOUSE LOCATION LOCATION
  const onHover = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const {
      lngLat: { lat, lng },
      point: { x, y },
    } = event;

    setPopupLocation({
      lat,
      lng,
      x,
      y,
    });
  }, []);

  // IF OVER A HEX SET SHOW THE POPUP WITH THAT HEXS DATA
  useEffect(() => {
    if (popupLocation) {
      const hexCode = latLngToCell(popupLocation.lat, popupLocation.lng, currentZoom);
      const activeHex = activeData?.data?.find((hex) => hex.hex === hexCode);

      if (activeHex && popupLocation.x !== 0 && popupLocation.y !== 0) {
        setShowPopup(true);
        setPopupData(activeHex);
      } else {
        setShowPopup(false);
        setPopupData(undefined);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupLocation]);

  if (isMarketOpportunityDataLoading || isLocalMarketDataLoading) {
    return <Skeleton height='100%' width='100%' />;
  }

  if (!activeData || !marketOpportunityData) {
    return <></>;
  }

  const layerIntervals = returnLayerIntervals(
    marketOpportunityData.mapData[activeMetric as MarketOpportunityMetricNames]
  );

  return (
    <div className={styles.mapContainer} ref={ref}>
      {/* CUSTOM OVERLAY FOR MAP CONTROLS */}
      <div className={styles.mapOverlay}></div>

      {/* MAP */}
      <Map
        key={reRenderKey}
        initialViewState={{
          latitude: marketOpportunityData?.mapCenter.lat,
          longitude: marketOpportunityData?.mapCenter.lng,
          zoom: 9.2,
        }}
        mapStyle='mapbox://styles/mapbox/light-v9'
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
        // This is a style hack to make the map act responsively
        style={{
          position: 'relative',
          height: 'inherit',
          width: 'inherit',
        }}
        onDrag={() => setShowPopup(false)}
        onMouseMove={(e) => (e ? onHover(e) : null)}
        onMouseOut={() => setShowPopup(false)}
      >
        <NavigationControl style={{ margin: '80px 30px 0 0' }} />

        <div className={styles.mapMessage}>
          <Message heading='Interact with the map' category='info'>
            <ul>
              <li>Select a segment from the panel to view its shoppers</li>

              <li>Hover over a cell on the map to view shoppers in that area</li>
            </ul>
          </Message>
        </div>

        {/* POPOUP */}
        {showPopup && !isMarketOpportunityDataLoading && (
          <div
            className={styles.popup}
            style={{
              left: popupLeftPositionFilter(popupLocation?.x, mapDimensions?.width),
              top: popupBottomPositionFilter(popupLocation?.y, mapDimensions?.height),
            }}
          >
            <div className={styles.popupContent}>
              <div className={styles.tooltipRow}>
                <Text size='14' color='secondary'>
                  {MARKET_MAPS_DEFS[activeMetric as MarketOpportunityMetricNames].title}
                </Text>
                <Text size='14'>{formatNumber(popupData?.value)}</Text>
              </div>
            </div>
          </div>
        )}

        {/* PRIMARY DEALER */}
        {!isLocalMarketDataLoading && (
          <Marker
            key='primary-dealer'
            longitude={localMarketData?.mapCenter.lng ?? 0}
            latitude={localMarketData?.mapCenter.lat ?? 0}
            anchor='center'
          >
            <CircleIconWithBorder color={COLOR.SPARKY_RED_300} />
          </Marker>
        )}

        {/* HEX LAYERS */}
        {!isMarketOpportunityDataLoading && (
          <Source
            id={activeMetric}
            key={activeMetric}
            type='geojson'
            data={returnGeoJSON(
              marketOpportunityData.mapData[activeMetric as MarketOpportunityMetricNames]
            )}
          >
            {/* OUTLINE */}
            <Layer
              {...{
                type: 'line',
                paint: {
                  'line-color': mapColor,
                  'line-width': 1,
                  'line-opacity': {
                    type: 'interval',
                    property: 'value',
                    stops: [
                      [layerIntervals[0], 0.2],
                      [layerIntervals[1], 0.3],
                      [layerIntervals[2], 0.4],
                      [layerIntervals[3], 0.5],
                      [layerIntervals[4], 0.75],
                      [layerIntervals[5], 0.8],
                    ],
                  },
                },
              }}
            />
            {/* FILL */}
            <Layer
              {...{
                type: 'fill',
                paint: {
                  'fill-color': mapColor,
                  'fill-opacity': {
                    type: 'interval',
                    property: 'value',
                    stops: [
                      [layerIntervals[0], 0.15],
                      [layerIntervals[1], 0.25],
                      [layerIntervals[2], 0.35],
                      [layerIntervals[3], 0.45],
                      [layerIntervals[4], 0.55],
                      [layerIntervals[5], 0.6],
                    ],
                  },
                },
              }}
            />
          </Source>
        )}
      </Map>
    </div>
  );
}
