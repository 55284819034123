import React, { ReactNode, useLayoutEffect, useRef } from 'react';

import * as Slider from '@radix-ui/react-slider';
import Flex from 'Sparky/Flex';
import { NumberInputField, NumberInputFieldProps } from 'Sparky/NumberInputField';
import RadioField from 'Sparky/RadioField';
import Repeat from 'Sparky/Repeat';
import Skeleton from 'Sparky/Skeleton';
import Text from 'Sparky/Text';

import { checkItemMatch } from 'components/Filters/checkItemMatch';
import SelectOptionsItem from 'components/Filters/components/SelectOptions/SelectOptionsItem';
import { useFiltersContext } from 'components/Filters/FiltersContext';
import { FiltersState, SelectRangeFilterConfig, SelectRangeState } from 'components/Filters/types';

import styles from './SelectRangeOptions.module.scss';

interface SelectRangeOptionProps {
  config: SelectRangeFilterConfig<unknown>;
}

export default function SelectRangeOptions({ config }: SelectRangeOptionProps) {
  const [isRangeChecked, setIsRangeChecked] = React.useState(true);
  const {
    getFilterState,
    filtersState,
    filterConfigs,
    data,
    clearFilter,
    setFilterState,
    isLoading,
    showItemCount,
    itemCountLabel,
  } = useFiltersContext();
  const selectFilterState = getFilterState(config.id) as SelectRangeState | undefined;
  const [minState, setMinState] = React.useState<string | undefined>(
    typeof selectFilterState?.min === 'number' ? selectFilterState?.min.toString() : undefined
  );
  const [maxState, setMaxState] = React.useState<string | undefined>(
    typeof selectFilterState?.min === 'number' ? selectFilterState?.min.toString() : undefined
  );
  const trackingRef = useRef<'min' | 'max' | null>(null);

  useLayoutEffect(() => {
    setMinState(
      typeof selectFilterState?.min === 'number' ? selectFilterState?.min.toString() : undefined
    );
    setMaxState(
      typeof selectFilterState?.max === 'number' ? selectFilterState?.max.toString() : undefined
    );
  }, [selectFilterState]);
  /*
    To get the count of matching items for each option:
     - Filter the data based on every other active filter, but not the filter that the option belongs to
     - Count the number of items that match the option value
    See the "Filters – Item Counts" documentation for more explanation – https://lotlinx.atlassian.net/wiki/spaces/LLD/pages/10355090/Filters

    `otherFiltersState` and `filteredData` are used to get those counts
   */
  const otherFiltersState: FiltersState = { ...filtersState };
  delete otherFiltersState[config.id];
  const filteredData =
    data?.slice().filter((item) => checkItemMatch(item, filterConfigs, otherFiltersState)) ?? [];

  const handleChange = (optionKey: string | number, checked: boolean) => {
    let opts: (string | number)[] = [];
    let max: number | null = null;
    let min: number | null = null;
    if (selectFilterState && selectFilterState.options?.length) {
      opts = [...selectFilterState.options];
    }
    if (checked) {
      opts.push(optionKey);
    } else {
      opts = opts.filter((i) => i !== optionKey);
    }

    opts.forEach((opt) => {
      if (max === null || (maxState && config.options[opt].maxValue > max)) {
        max = config.options[opt].maxValue;
      }
      if (min === null || (minState && config.options[opt].minValue < min)) {
        min = config.options[opt].minValue;
      }
    });
    // This if is just for typescript null checking
    if (max !== null && min !== null) {
      if (max < min) {
        let temp = max;
        max = min;
        min = temp;
      }

      if (!opts.length) {
        clearFilter(config.id);
      } else {
        setFilterState(config.id, { options: opts, max, min });
      }
    } else {
      clearFilter(config.id);
    }
  };

  const updateFilterValue = () => {
    let opts: (string | number)[] = Object.keys(config.options);
    let min = parseValue(minState);
    let max = parseValue(maxState);

    const options: (string | number)[] = [];
    opts.forEach((opt) => {
      if (
        (min !== undefined &&
          min <= config.options[opt].maxValue &&
          max !== undefined &&
          max >= config.options[opt].minValue) ||
        (min !== undefined && max === undefined && min <= config.options[opt].maxValue) ||
        (max !== undefined && min === undefined && max >= config.options[opt].minValue)
      ) {
        options.push(opt);
      }
    });
    if (min === undefined && max === undefined) {
      clearFilter(config.id);
    } else if (!min || !max || min <= max) {
      setFilterState(config.id, { options, min: min, max: max });
    } else {
      // If min is larger than max, switch places
      setFilterState(config.id, { options, min: max, max: min });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.selectOptions}>
        <Repeat times={4}>
          <div className={styles.option} style={{ height: '1.25em' }}>
            <Skeleton width='10em' height='1em' />
            <Skeleton width='2em' height='1em' />
          </div>
        </Repeat>
      </div>
    );
  }

  const commonProps: Omit<NumberInputFieldProps, 'id' | 'label'> = {
    disabled: isLoading,
    size: 'sm',
    onBlur: () => updateFilterValue(),
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        updateFilterValue();
      }
    },
  };

  let options: ReactNode[];
  options = Object.entries(config.options).map(([key, opt]) => {
    const matchCount = filteredData.filter((item) => opt.matcher(item)).length;
    if (!matchCount) {
      return null;
    }
    return (
      <SelectOptionsItem
        key={key}
        optionKey={key}
        checked={!!selectFilterState?.options?.find((i) => i === key)}
        disabled={!matchCount}
        label={opt.label}
        handleChange={handleChange}
        matchCount={matchCount}
        itemCountLabel={itemCountLabel}
        showItemCount={showItemCount}
      />
    );
  });

  // If no options are available, display a placeholder
  if (!options.filter((i) => !!i).length) {
    return (
      <div className={styles.selectOptions}>
        <Text size='14'>No options available</Text>
      </div>
    );
  }

  return (
    <div className={styles.selectRangeOptions}>
      {config.showToggleOptions && (
        <Flex alignItems='center' columnGap='16px'>
          <RadioField
            smallHover
            checked={isRangeChecked}
            onClick={() => setIsRangeChecked(!isRangeChecked)}
          >
            Ranges
          </RadioField>
          <RadioField
            smallHover
            checked={!isRangeChecked}
            onClick={() => setIsRangeChecked(!isRangeChecked)}
          >
            Min/max
          </RadioField>
        </Flex>
      )}
      {(!config.showToggleOptions || isRangeChecked) && (
        <div className={styles.selectOptions}>{options}</div>
      )}
      {(!config.showToggleOptions || !isRangeChecked) && (
        <div className={styles.rangeOptions}>
          <NumberInputField
            id={`${config.id}-min`}
            label='Min.'
            placeholder='Min'
            name='min'
            showNumberCaret={true}
            thousandSeparator={true}
            value={minState}
            onValueChange={(value) => setMinState(value.value)}
            prefix={config.rangePrefix}
            suffix={config.rangeSuffix}
            className={styles.minInput}
            {...commonProps}
          />
          <Text color='tertiary' className={styles.connector}>
            -
          </Text>
          <NumberInputField
            id={`${config.id}-max`}
            label='Max.'
            placeholder='Max'
            name='max'
            showNumberCaret={true}
            thousandSeparator={true}
            value={maxState}
            onValueChange={(value) => setMaxState(value.value)}
            prefix={config.rangePrefix}
            suffix={config.rangeSuffix}
            className={styles.maxInput}
            {...commonProps}
          />
        </div>
      )}
      {(!config.showToggleOptions || !isRangeChecked) && config.rangeLimit && (
        <form>
          <Slider.Root
            className={styles.sliderRoot}
            min={config.rangeLimit[0]}
            max={config.rangeLimit[1]}
            step={1}
            value={[parseValue(minState) ?? -Infinity, parseValue(maxState) ?? Infinity]}
            onValueChange={(newValues) => {
              if (trackingRef.current !== null) {
                if (trackingRef.current === 'min') {
                  if (newValues[0] >= (parseValue(maxState) ?? Infinity)) {
                    return;
                  }
                  setMinState(newValues[0].toString());
                } else {
                  if (newValues[1] <= (parseValue(minState) ?? -Infinity)) {
                    return;
                  }
                  setMaxState(newValues[1].toString());
                }
              } else {
                setMinState(newValues[0].toString());
                setMaxState(newValues[1].toString());
              }
            }}
            onValueCommit={updateFilterValue}
          >
            <Slider.Track className={styles.sliderTrack}>
              <Slider.Range className={styles.sliderRange} />
            </Slider.Track>
            <Slider.Thumb
              className={styles.sliderThumb}
              aria-label='min'
              onPointerDown={() => (trackingRef.current = 'min')}
              onPointerUp={() => (trackingRef.current = null)}
            />
            <Slider.Thumb
              className={styles.sliderThumb}
              aria-label='max'
              onPointerDown={() => (trackingRef.current = 'max')}
              onPointerUp={() => (trackingRef.current = null)}
            />
          </Slider.Root>
        </form>
      )}
    </div>
  );
}

function parseValue(val?: string) {
  if (!val) {
    return undefined;
  }

  const parsed = parseFloat(val);
  return isNaN(parsed) ? 0 : parsed;
}
