import React, { useRef, useState } from 'react';

import * as RadixPopover from '@radix-ui/react-popover';
import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import classNames from 'classnames';

import styles from './Popover.module.scss';

export interface ButtonPopoverProps {
  children?: React.ReactNode;
  popupContent?: React.ReactNode;
  showCloseButton?: boolean;
  showCarrat?: boolean;
  contentPadding?: boolean;
  isOpen?: boolean;
  onOpenAutoFocus?: (e: Event) => void;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  verticalOffset?: number;
  avoidCollisions?: boolean;
  horizontalOffset?: number;
  hideWhenDetached?: boolean;

  onClick?(): void;

  disabled?: boolean;
  isHoverTriggered?: boolean;
  isClickTriggered?: boolean;
  side?: 'bottom' | 'right' | 'top' | 'left' | undefined;
  align?: 'end' | 'center' | 'start' | undefined;
  contentClassName?: string;
  className?: string;
}

// const PopupoverCarrat = ({ className }: { className: string }) => (
//   <div className={className}>
//     <svg xmlns='http://www.w3.org/2000/svg' width='28' height='14' viewBox='0 0 28 14' fill='none'>
//       <path
//         fillRule='evenodd'
//         clipRule='evenodd'
//         d='M27.5 14L13.5 0L0 14H1.5L13.5 1.5L26 14H27.5Z'
//         fill='#D0D0D0'
//       />
//       <path d='M26 14L13.5 1.5L1.5 14H26Z' fill='white' />
//     </svg>
//   </div>
// );

/**
 * ButtonPopover
 */
export default function Popover({
  children,
  popupContent,
  isOpen,
  setIsOpen,
  showCloseButton = true,
  showCarrat = true,
  avoidCollisions = true,
  hideWhenDetached = true,
  verticalOffset = 2,
  horizontalOffset = 0,
  isHoverTriggered,
  onOpenAutoFocus,
  isClickTriggered = true,
  side,
  contentPadding = true,
  align = 'start',
  contentClassName,
  className,
  disabled = false,
}: ButtonPopoverProps) {
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const triggerRef = useRef<any>(null);

  const open = isOpen ? isOpen : isOpenInternal;
  const setOpen = setIsOpen ? setIsOpen : setIsOpenInternal;

  return (
    <RadixPopover.Root open={open}>
      <RadixPopover.Trigger
        asChild
        disabled={disabled}
        ref={triggerRef}
        onClick={() => {
          !isHoverTriggered && isClickTriggered && setOpen(!open);
        }}
        onMouseEnter={() => isHoverTriggered && setOpen(true)}
        onMouseLeave={() => isHoverTriggered && setOpen(false)}
        className={classNames([className])}
      >
        {children}
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        {!disabled && (
          <RadixPopover.Content
            onPointerDownOutside={(e) => {
              if (triggerRef?.current && !triggerRef.current.contains(e.target)) {
                !isHoverTriggered && setOpen(false);
              }
            }}
            className={classnames([
              styles.popoverContent,
              contentPadding && styles.padded,
              contentClassName,
            ])}
            align={align}
            onOpenAutoFocus={onOpenAutoFocus}
            alignOffset={horizontalOffset}
            sideOffset={verticalOffset}
            avoidCollisions={avoidCollisions}
            hideWhenDetached={hideWhenDetached}
            side={side}
          >
            {showCloseButton && (
              <RadixPopover.Close
                className={styles.closeButton}
                aria-label='Close'
                onClick={() => setOpen(false)}
              >
                <IconX size={12} strokeWidth={3} color={'#888888'} />
              </RadixPopover.Close>
            )}
            {showCarrat && <RadixPopover.Arrow height={10} width={20} className={styles.arrow} />}
            {popupContent}
          </RadixPopover.Content>
        )}
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
