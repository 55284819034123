import { CSSProperties, ReactNode } from 'react';

import classnames from 'classnames';
import Radio, { RadioProps } from 'Sparky/Radio';
import Text from 'Sparky/Text';

import styles from './RadioField.module.scss';

export interface RadioFieldProps extends RadioProps {
  children: ReactNode;
  helpText?: string;
  containerClassNames?: string;
}

/**
 * RadioField
 */
export default function RadioField({
  children,
  helpText,
  disabled,
  containerClassNames,
  ...radioProps
}: RadioFieldProps) {
  return (
    <div
      className={classnames(
        { [styles.radioField]: true, [styles.disabled]: disabled },
        containerClassNames
      )}
      style={{ '--radio-width': radioProps.width } as CSSProperties}
    >
      <label className={styles.textField}>
        <Radio {...radioProps} disabled={disabled} />
        {children && <span className={styles.label}>{children}</span>}
      </label>
      {helpText && (
        <Text color='secondary' size='12' className={styles.helpText}>
          {helpText}
        </Text>
      )}
    </div>
  );
}
