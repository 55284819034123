import produce from 'immer';
import { atom, useAtom } from 'jotai';
import Flex from 'Sparky/Flex';
import { COLOR } from 'Sparky/styles/vars';
import Tooltip from 'Sparky/Tooltip';

import { Adesa } from 'pages/Inventory/Acquisition/AcquisitionTable/SourceIcons/Adesa';
import { ATC } from 'pages/Inventory/Acquisition/AcquisitionTable/SourceIcons/ATC';
import { CarGurus } from 'pages/Inventory/Acquisition/AcquisitionTable/SourceIcons/CarGurus';
import { Cars } from 'pages/Inventory/Acquisition/AcquisitionTable/SourceIcons/Cars';
import { CraigsList } from 'pages/Inventory/Acquisition/AcquisitionTable/SourceIcons/CraigsList';
import { Facebook } from 'pages/Inventory/Acquisition/AcquisitionTable/SourceIcons/Facebook';
import { SourceItem } from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';

import styles from './Sources.module.scss';

export interface SourcesProps {
  sources?: SourceItem[];
}

interface SourceColor {
  sourceName: string;
  color: string;
}

export const sourceColorsAtom = atom<SourceColor[]>([]);

/**
 * Sources
 */
export default function Sources({ sources }: SourcesProps) {
  const [sourceColors, setSourceColors] = useAtom(sourceColorsAtom);
  const iconCounts = 3;

  if (!sources || sources.length === 0) {
    return null;
  } else if (sources.length <= iconCounts) {
    const newSources = sources.slice(0, iconCounts);
    return (
      <Flex height='100%' width='100%' alignItems='center' padding='8px'>
        {newSources.map((source, index) => {
          if (
            source.name !== 'Facebook' &&
            source.name !== 'Cars.com' &&
            source.name !== 'Adesa' &&
            source.name !== 'Autotrader' &&
            source.name !== 'Craigslist' &&
            source.name !== 'Cargurus' &&
            !sourceColors.find((sourceColor) => sourceColor.sourceName === source.name)
          ) {
            setSourceColors(
              produce(sourceColors, (draft) => {
                draft.push({
                  sourceName: source.name,
                  color: colors[draft.length],
                });
              })
            );
          }
          return (
            <Tooltip key={index} content={source.name} side='top'>
              <div
                style={{
                  zIndex: index * 0.01,
                  transform: `translateX(-${13 * index}px)`,
                }}
                className={styles.iconWrapper}
              >
                <SourceIcon
                  source={source}
                  color={
                    source.name.charAt(0) === '+'
                      ? COLOR.SPARKY_RED_400
                      : sourceColors.find((sourceColor) => sourceColor.sourceName === source.name)
                          ?.color
                  }
                />
              </div>
            </Tooltip>
          );
        })}
      </Flex>
    );
  } else {
    const restSourcesCounts = sources.length - iconCounts;
    const restSources = sources.slice(iconCounts, sources.length + 1);
    const newSources = sources.slice();
    newSources.splice(iconCounts, restSourcesCounts, {
      name: `+${restSourcesCounts}`,
      price: 0,
      listingUrl: '',
    }) as SourceItem[];

    return (
      <Flex height='100%' width='100%' alignItems='center' padding='8px'>
        {newSources.map((source, index) => {
          if (
            source.name !== 'Facebook' &&
            source.name !== 'Cars.com' &&
            source.name !== 'Adesa' &&
            source.name !== 'Autotrader' &&
            source.name !== 'Craigslist' &&
            source.name !== 'Cargurus' &&
            !sourceColors.find((sourceColor) => sourceColor.sourceName === source.name)
          ) {
            setSourceColors(
              produce(sourceColors, (draft) => {
                draft.push({
                  sourceName: source.name,
                  color: colors[draft.length],
                });
              })
            );
          }

          return (
            <Tooltip
              key={index}
              content={
                index === iconCounts
                  ? restSources.map((restSource) => restSource.name).join(', ')
                  : source.name
              }
              side={index === iconCounts ? 'bottom' : 'top'}
            >
              <div
                style={{
                  zIndex: index * 0.01,
                  transform: `translateX(-${13 * index}px)`,
                }}
                className={styles.iconWrapper}
              >
                <SourceIcon
                  source={source}
                  color={
                    source.name.charAt(0) === '+'
                      ? COLOR.SPARKY_RED_400
                      : sourceColors.find((sourceColor) => sourceColor.sourceName === source.name)
                          ?.color
                  }
                />
              </div>
            </Tooltip>
          );
        })}
      </Flex>
    );
  }
}

interface CustomSourceBadgeProps {
  name: string;
  size?: number;
  color?: string;
}

function CustomSourceBadge({ name, size = 32, color }: CustomSourceBadgeProps) {
  return (
    <div style={{ width: `${size}px`, height: `${size}px` }} className={styles.badgeWrapper}>
      <div
        style={{
          backgroundColor: color,
          width: `${size * 0.8}px`,
          height: `${size * 0.8}px`,
          fontSize: size > 24 ? '10px' : '8px',
        }}
        className={styles.badge}
      >
        {name}
      </div>
    </div>
  );
}

interface SourceIconProps {
  source: SourceItem;
  size?: number;
  color?: string;
}

export function SourceIcon({ source, size, color }: SourceIconProps) {
  switch (source.name) {
    case 'Facebook':
      return <Facebook size={size} />;
    case 'Cars.com':
      return <Cars size={size} />;
    case 'Adesa':
      return <Adesa size={size} />;
    case 'Autotrader':
      return <ATC size={size} />;
    case 'Craigslist':
      return <CraigsList size={size} />;
    case 'Cargurus':
      return <CarGurus size={size} />;
    case 'Carsforsale':
      return <CustomSourceBadge name='CS' size={size} color={color} />;
    case 'Edmunds':
      return <CustomSourceBadge name='ED' size={size} color={color} />;
    case 'Carfax':
      return <CustomSourceBadge name='CF' size={size} color={color} />;
    case 'Ebay':
      return <CustomSourceBadge name='EB' size={size} color={color} />;
    default:
      return (
        <CustomSourceBadge
          name={
            source.name.charAt(0) === '+'
              ? source.name
              : `${source.name.charAt(0).toUpperCase()}${firstUppercaseLetter(
                  source.name.slice(1)
                )}`
          }
          size={size}
          color={color}
        />
      );
  }
}

function firstUppercaseLetter(str: string): string {
  for (let char of str) {
    if (char >= 'A' && char <= 'Z') {
      return char;
    }
  }
  return '';
}

const colors = [
  COLOR.SPARKY_YELLOW_400,
  COLOR.SPARKY_RED_400,
  COLOR.SPARKY_BLUE_400,
  COLOR.SPARKY_GREEN_400,
  COLOR.SPARKY_ORANGE_400,
  COLOR.SPARKY_LIME_400,
  COLOR.SPARKY_PURPLE_400,
  COLOR.SPARKY_PINK_400,
  COLOR.SPARKY_GREY_400,
];
