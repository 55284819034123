import { CSSProperties, ReactNode } from 'react';

import classnames from 'classnames';
import classNames from 'classnames';
import Checkbox, { CheckboxProps } from 'Sparky/Checkbox';

import styles from './CheckboxField.module.scss';

export interface CheckboxFieldProps extends CheckboxProps {
  children: ReactNode;
  helpText?: ReactNode;
  align?: 'top' | 'center' | 'bottom';
}

/**
 * CheckboxField
 */
export default function CheckboxField({
  children,
  helpText,
  disabled = false,
  className,
  align,
  ...checkboxProps
}: CheckboxFieldProps) {
  return (
    <div
      className={classnames(
        { [styles.checkboxField]: true, [styles.disabled]: disabled },
        className
      )}
      style={{ '--checkbox-width': checkboxProps.width } as CSSProperties}
    >
      <label data-align={align} className={styles.textField}>
        <Checkbox {...checkboxProps} disabled={disabled} />
        {children && (
          <span
            className={classNames([
              styles.label,
              align === 'top' && styles.checkboxLabelTop,
              align === 'bottom' && styles.checkboxLabelBottom,
            ])}
          >
            {children}
          </span>
        )}
      </label>
      {helpText && <span className={styles.helpText}>{helpText}</span>}
    </div>
  );
}
