import { InventoryItem } from 'types/InventoryItem';
import { VinPathSortBy } from 'types/VinPath';

export function vinPathTableSortBy(data: InventoryItem[] | undefined, sortBy: VinPathSortBy) {
  if (!data) {
    return data;
  }

  switch (sortBy) {
    case 'daysOnLotHighToLow':
      return data.sort((a, b) => (b.listing.dol ?? 0) - (a.listing.dol ?? 0));
    case 'daysOnLotLowToHigh':
      return data.sort((a, b) => (a.listing.dol ?? 0) - (b.listing.dol ?? 0));
    case 'markdownHighToLow':
      return data.sort(
        (a, b) =>
          (b.vinPath?.pricingOptions ? b.vinPath?.pricingOptions[0].markdown ?? 0 : 0) -
          (a.vinPath?.pricingOptions ? a.vinPath?.pricingOptions[0].markdown ?? 0 : 0)
      );
    case 'markdownLowToHigh':
      return data.sort(
        (a, b) =>
          (a.vinPath?.pricingOptions ? a.vinPath?.pricingOptions[0].markdown ?? 0 : 0) -
          (b.vinPath?.pricingOptions ? b.vinPath?.pricingOptions[0].markdown ?? 0 : 0)
      );
    case 'mileageHighToLow':
      return data.sort((a, b) => b.listing.mileage - a.listing.mileage);
    case 'mileageLowToHigh':
      return data.sort((a, b) => a.listing.mileage - b.listing.mileage);
    default:
      return data;
  }
}
