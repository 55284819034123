const url = process.env.REACT_APP_API_URL ?? '';

/** Map of valid API endpoints */
const ENDPOINTS = {
  dealers: `${url}/dealers`,
  keepalive: `${url}/auth/keepalive`,
  login: `${url}/auth/login`,
  logout: `${url}/auth/logout`,
  resetInit: `${url}/auth/resetInit`,
  resetComplete: `${url}/auth/resetComplete`,
  change: `${url}/auth/change`,
  dashboardConfig: `${url}/dashboardConfig`,
  dashboardTemplates: `${url}/dashboardTemplates`,
  comparisonWidget: `${url}/comparisonWidget`,
  simpleChartWidget: `${url}/simpleChartWidget`,
  comboChartWidget: `${url}/comboChartWidget`,
  gaugeChartWidget: `${url}/gaugeChartWidget`,
  inventory: `${url}/inventory`,
  vdpPriceData: `${url}/vdpPriceData`,
  similarVins: `${url}/similarVins`,
  marketDemandData: `${url}/marketDemandData`,
  inventoryTableConfig: `${url}/inventoryTableConfig`,
  addBudget: `${url}/addBudget`,
  marketplaceApps: `${url}/marketplace/apps`,
  marketplaceChannels: `${url}/marketplace/channels`,
  vvo: `${url}/vvo`,
  vvoSpend: `${url}/vvo/spend`,
  vvoConfigureInvestment: `${url}/vvo/configureInvestment`,
  inquiry: `${url}/inquiry`,
  insights: `${url}/insights`,
  rating: `${url}/rating`,
  beforeAndAfter_trendMetrics: `${url}/beforeAndAfter/trendMetrics`,
  beforeAndAfter_trendFilters: `${url}/beforeAndAfter/trendFilters`,
  campaignData: `${url}/campaign/list`,
  campaign_performance: `${url}/campaign/performance`,
  campaign_defaults: `${url}/campaign/defaults`,
  campaign_promos: `${url}/campaign/promos`,
  campaign_authorized_list: `${url}/campaign/authorizedList`,
  campaign_validation: `${url}/campaign/validation`,
  campaign_creation: `${url}/campaign/creation`,
  campaign_conversion_report: `${url}/campaign/reports/conversions`,
  segment_performance: `${url}/segment/performance`,
  market_opportunity: `${url}/market/performance`,
  market_segments: `${url}/market/segments`,
  leads: `${url}/leads`,
  local_market_settings: `${url}/settings/localMarket`,
  geoConfigSettings: `${url}/settings/geoConfig`,
  vinTvConfigSettings: `${url}/settings/vinTv`,
  showroom: `${url}/settings/showroom`,
  vmxAccessAccounts: `${url}/settings/extension/accounts/byDealer`,
  // vmxAccessAccountsById: `${url}/settings/extension/accounts/byAccount`,
  vmxPermissionRequests: `${url}/settings/extension/permissions`,
  vmxSavePermissionsRequest: `${url}/settings/extension/permission`,
  vmxUpdateAccountRequest: `${url}/settings/extension/account`,
  vmxCreateInvite: `${url}/settings/extension/createInvite`,
  vmxApproveAccount: `${url}/settings/extension/approveAccount`,
  permissionRequests: `${url}/extension/permissionRequests`,
  photoAi: `${url}/settings/photoAI`,
  tooltipConfig: `${url}/tooltipConfig`,
  campaignName: `${url}/campaign/name`,
  tooltips: `${url}/tooltips`,
  riskReport: `${url}/reports/risk`,
  riskOverview: `${url}/reports/risk/overview`,
  roiReport: `${url}/reports/roi`,
  roiSimulate: `${url}/reports/roi/simulate`,
  saveSegmentFavorites: `${url}/market/segments/favorites`,
  saveDealerFavorites: `${url}/dealers/favorites`,
  marketInsights: `${url}/market/insights`,
  events: `${url}/eventsV2`,
  inventoryBreakdown: `${url}/inventory/breakdown`,
  carryoverBreakdown: `${url}/inventory/breakdown/chart`,
  acquisition: `${url}/acquisition`,
  vinPathPriceData: `${url}/vinPathPriceData`,
  lexSubmitFeedback: `${url}/storeLexResponseFeedback`,
  getCacheStatus: `${url}/cache/status`,
  initiateCacheRebuild: `${url}/cache/rebuild`,
} as const;
export default ENDPOINTS;
