import { useState } from 'react';

import classnames from 'classnames';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COLOR } from 'Sparky/styles/vars';

import ChartLegend, {
  colorPalette,
  LegendList,
} from 'pages/Inventory/VinDetailSidebar/components/ChartLegend';
import styles from 'pages/Inventory/VinDetailSidebar/MarketSection/SimilarVINsChart/SimilarVINsChart.module.scss';
import SimilarVINsChartTooltip from 'pages/Inventory/VinDetailSidebar/MarketSection/SimilarVINsChart/SimilarVINsChartTooltip';
import { formatCurrency } from 'util/formatters';

export interface SimilarVINsChartProps {
  xAxisConfig: SimilarVINsChartXAxisConfig;
  data: SimilarVINsData[];
  yAvg?: number;
  xAvg?: number;
  vin: string;
}

export interface SimilarVINsChartXAxisConfig {
  key: 'dol' | 'mileage';
  label: string;
  shortLabel: string;
}

export interface SimilarVINsData {
  lastPrice?: number;
  mileage?: number;
  dol?: number;
  vin: string;
  dealerId: number;
  modelYear: number;
  make: string;
  model: string;
  trim?: string;
  bodySegment?: string;
  firstPrice?: number;
}

interface SimilarVINsScatterplotData {
  x: number;
  y: number;
  data: SimilarVINsData;
}

const legend: LegendList[] = [
  {
    key: 'totalVDP',
    name: 'This VIN',
    type: 'circle',
    borderColor: colorPalette.red.borderColor,
    rangeColor: colorPalette.red.rangeColor,
  },
  {
    key: 'lotlinxVDP',
    name: 'Other VINs in market',
    type: 'circle',
    borderColor: colorPalette.blue.borderColor,
    rangeColor: colorPalette.blue.rangeColor,
  },
];

/** Scatterplot showing DOL/Mileage vs Price of similar VINs in the market */
export default function SimilarVINsChart({
  data,
  xAvg,
  yAvg,
  vin,
  xAxisConfig,
}: SimilarVINsChartProps) {
  const [hoverScatter, setHoverScatter] = useState<string | null>(null);

  // Move selected VIN to last item, to ensure that its dot is visible
  let sortedData = data.slice();
  const index = sortedData.findIndex((i) => i.vin === vin);
  sortedData.push(sortedData.splice(index, 1)[0]);

  // Get x/y values for scatterplot
  let chartData = sortedData
    .map((i) => ({ x: i[xAxisConfig.key], y: i.lastPrice, data: i }))
    // Use type predicate to tell Typescript that x and y will be defined after filtering
    .filter((i): i is SimilarVINsScatterplotData => !!i.x && !!i.y);

  // Remove outliers
  chartData = chartData.filter((i) => !(xAvg && i.x > 3 * xAvg) && !(yAvg && i.y > 3 * yAvg));

  return (
    <ResponsiveContainer width='100%' height='100%' className='chromatic-ignore'>
      <ScatterChart>
        <CartesianGrid stroke={COLOR.SPARKY_GREY_100} />
        <XAxis
          type='number'
          dataKey='x'
          tickFormatter={(val) => (xAxisConfig.key === 'dol' ? val : `${val} mi`)}
          axisLine={false}
          tickLine={false}
          tick={{
            fill: COLOR.SPARKY_GREY_400,
            fontSize: 12,
          }}
          domain={['auto', 'auto']}
        />
        <YAxis
          type='number'
          dataKey='y'
          axisLine={false}
          tick={{ fill: COLOR.SPARKY_GREY_400, fontSize: 12 }}
          tickFormatter={(val) => formatCurrency(val)}
          tickLine={false}
          domain={['auto', 'auto']}
        />

        {hoverScatter && (
          <Tooltip
            active={!!hoverScatter}
            content={
              <SimilarVINsChartTooltip
                avgMarketPrice={formatCurrency(yAvg)}
                xAxis={xAxisConfig.key}
              />
            }
            isAnimationActive={false}
            cursor={{ strokeDasharray: '3 3' }}
          />
        )}
        {/*{xAvg !== undefined && (*/}
        {/*  <ReferenceLine stroke={COLOR.CHART_BLUE_2} strokeWidth={2} x={xAvg}>*/}
        {/*    <Label*/}
        {/*      position='top'*/}
        {/*      fontSize={10}*/}
        {/*      fontWeight={600}*/}
        {/*      dy={-4}*/}
        {/*      fill={COLOR.CHART_BLUE_2}*/}
        {/*    >*/}
        {/*      {`AVG ${xAxisConfig.shortLabel}: ${formatNumber(xAvg)}`}*/}
        {/*    </Label>*/}
        {/*  </ReferenceLine>*/}
        {/*)}*/}
        {/*{yAvg !== undefined && (*/}
        {/*  <ReferenceLine stroke={COLOR.NEUTRAL_4} strokeDasharray='2 2' strokeWidth={1} y={yAvg}>*/}
        {/*    <Label position='right' fontSize={10} dy={-6}>*/}
        {/*      AVG Market*/}
        {/*    </Label>*/}
        {/*    <Label position='right' fontSize={10} dy={6}>*/}
        {/*      {`Price: ${formatCurrency(yAvg)}`}*/}
        {/*    </Label>*/}
        {/*  </ReferenceLine>*/}
        {/*)}*/}
        <Scatter
          name='Price'
          data={chartData}
          isAnimationActive={false}
          fill={COLOR.SPARKY_BLUE_400}
          shape={(i: any) => (
            <circle
              cx={i.cx}
              cy={i.cy}
              r={i.data.vin === hoverScatter ? 4.5 : 3.5}
              onMouseEnter={() => setHoverScatter(i.data.vin)}
              onMouseLeave={() => setHoverScatter(null)}
              onClick={() => {
                window.open(
                  `https://turnapi-production.lotlinx.com/urlredir.jsp?vin=${i.data.vin}&id=${i.data.dealerId}`
                );
              }}
              className={classnames([styles.dot, i.data.vin === vin && styles.currentVin])}
            />
          )}
        />
        <Legend
          content={<ChartLegend legend={legend} />}
          wrapperStyle={{ padding: '0 12px', bottom: '0' }}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
}
