import React from 'react';

import { IconDownload, IconFilter, IconRocket } from '@tabler/icons-react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import Button from 'Sparky/Button';
import ButtonGroup, { ItemProps } from 'Sparky/ButtonGroup';
import Checkbox from 'Sparky/Checkbox';
import Flex from 'Sparky/Flex';
import SelectInput, { SelectInputOptions } from 'Sparky/SelectInput';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';
import { selectedAtom } from 'store/inventoryItemStore';

import { useFiltersContext } from 'components/Filters';
import { useNavigateKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import useSelectedDealer from 'hooks/useSelectedDealer';
import { campaignCreationAtom } from 'pages/Inventory/InventoryPage/InventoryHeader';
import { formatVinPathCSVHeaders, selectedVinPathData } from 'pages/Inventory/VinPath/utils';
import { InventoryItem } from 'types/InventoryItem';
import { VinPathSidebar, VinPathSortBy } from 'types/VinPath';
import { exportCSV } from 'util/exportCSV';
import { formatCurrency } from 'util/formatters';

import styles from './VinPathTableHeader.module.scss';
import ActiveFilterPills from '../../../InventoryPage/components/ActiveFilterPills';

export interface VinPathTableHeaderProps {
  sortBy: VinPathSortBy;
  setSortBy: React.Dispatch<React.SetStateAction<VinPathSortBy>>;
  filteredData?: InventoryItem[];
  visibleSelected: string[];

  toggleAllSelected(): void;

  totalMarkDowns: number;
  vinPathSidebar: VinPathSidebar;
  setVinPathSidebar: React.Dispatch<React.SetStateAction<VinPathSidebar>>;
}

const sortByOptions: SelectInputOptions = [
  {
    value: 'daysOnLotHighToLow',
    label: 'Days on lot (high to low)',
  },
  {
    value: 'daysOnLotLowToHigh',
    label: 'Days on lot (low to high)',
  },
  {
    value: 'markdownLowToHigh',
    label: 'Markdown low to high',
  },
  {
    value: 'markdownHighToLow',
    label: 'Markdown high to low',
  },
  {
    value: 'mileageLowToHigh',
    label: 'Mileage low to high',
  },
  {
    value: 'mileageHighToLow',
    label: 'Mileage high to low',
  },
];

/**
 * VinPathTableHeader
 */
export default function VinPathTableHeader({
  sortBy,
  setSortBy,
  filteredData,
  visibleSelected,
  toggleAllSelected,
  totalMarkDowns,
  vinPathSidebar,
  setVinPathSidebar,
}: VinPathTableHeaderProps) {
  const navigate = useNavigateKeepParams();
  const [, setSelected] = useAtom(selectedAtom);
  const [, setCampaignCreationState] = useAtom(campaignCreationAtom);
  const { activeFilterPills } = useFiltersContext();
  const { dealer } = useSelectedDealer();

  const markdownInfo =
    visibleSelected.length > 0 ? `${formatCurrency(totalMarkDowns)} of markdowns` : '';

  const allSelected = filteredData && visibleSelected.length === filteredData.length;

  const buttonGroups: ItemProps[] = [
    {
      value: 'Boost selected',
      label: `Boost selected`,
      disabled: !visibleSelected.length,
      leftIcon: <IconRocket size={20} />,
      onClick: () => {
        setCampaignCreationState({
          vins: visibleSelected,
          filters: { condition: { options: ['CPO', 'NEW', 'USED'] } },
          from: 'vinPath',
          campaignType: 'BOOST',
        });
        navigate('/campaign_creation');
      },
    },
    {
      value: 'Export selected VINs as CSV',
      label: 'Export to CSV',
      disabled: !visibleSelected.length,
      leftIcon: <IconDownload size={20} />,
      onClick: () => {
        if (filteredData) {
          exportCSV(
            selectedVinPathData(filteredData, visibleSelected),
            formatVinPathCSVHeaders(),
            `${dealer?.name.replace(/\./g, '')}_${dealer?.id}_VinPath_${dayjs().format('YYYYMMDD')}`
          );
        }
      },
    },
  ];

  const navToInventory = () => {
    setSelected(visibleSelected);
    navigate('/inventory/live');
  };

  const headerMenu =
    visibleSelected.length > 0 ? (
      <ButtonGroup
        toggleable={true}
        multiActives={true}
        items={buttonGroups}
        boxShadow={true}
        size='md'
      />
    ) : (
      <Flex alignItems='center' columnGap='12px'>
        <Button
          leftIcon={<IconFilter size={20} />}
          rightIcon={
            activeFilterPills.length > 0 ? (
              <Text className={styles.pillNumber} size='12'>
                {activeFilterPills.length}
              </Text>
            ) : undefined
          }
          size='md'
          variant='outline'
          onClick={() => {
            if (vinPathSidebar !== 'filter') {
              setVinPathSidebar('filter');
            } else {
              setVinPathSidebar('');
            }
          }}
        >
          Filter
        </Button>
        <SelectInput
          id='sort by'
          value={sortBy}
          onValueChange={(val) => setSortBy(val as VinPathSortBy)}
          options={sortByOptions}
          prefix='Sort by: '
        />
      </Flex>
    );

  return (
    <Flex padding='16px 12px' width='100%' column className={styles.vinPathTableHeader}>
      <Flex width='100%' alignItems='center' columnGap='12px'>
        {filteredData && filteredData?.length > 0 && (
          <Checkbox
            smallHover
            indeterminate={visibleSelected.length > 0 && !allSelected}
            checked={!!allSelected}
            onClick={toggleAllSelected}
            onKeyDown={toggleAllSelected}
          />
        )}
        {!filteredData || filteredData?.length === 0 ? (
          <Text size={20} fontWeight={7} color='secondary'>
            0 results
          </Text>
        ) : visibleSelected.length > 0 ? (
          <Tooltip side='right' content='View in inventory page'>
            <div
              onClick={navToInventory}
              className={classNames([styles.checkboxLabel, styles.hoverCheckboxLabel])}
            >
              {visibleSelected.length} vehicles selected
            </div>
          </Tooltip>
        ) : (
          <Text size={20} fontWeight={7} color='blue' className={styles.checkboxLabel}>
            Results for {filteredData?.length} vehicles
          </Text>
        )}
        {markdownInfo && (
          <Flex
            alignItems='center'
            padding='0 12px 0 0'
            height='44px'
            className={styles.markdownInfo}
          >
            <Text textWrap='nowrap' color='secondary'>
              {markdownInfo}
            </Text>
          </Flex>
        )}
        {filteredData && filteredData?.length > 0 && headerMenu}
      </Flex>
      {activeFilterPills && (
        <ActiveFilterPills chipProps={{ shape: 'tag', variant: 'filled' }} showClearAll />
      )}
    </Flex>
  );
}
