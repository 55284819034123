import { Key, useState } from 'react';

export interface UseQuickFiltersProps<T> {
  data?: T[];
  ctaConfigs: CTAFilterConfig<T>[];
}

export interface CTAFilterConfig<T> {
  id: Key;
  label: string | number;
  buttonLabel: string;
  matcher(item: T): boolean;
}

/**
 * "Call To Action" filters narrow the inventory view down to sets of VINs that we want to bring attention to.
 * `useCTAFilters` handles the CTA logic, including the panel state and inventory filtering
 */
export default function useCTAFilters<T>({ data, ctaConfigs }: UseQuickFiltersProps<T>) {
  const [activeCTA, setActiveCTA] = useState<Key | undefined>();

  const toggleCTAFilter = (val: Key) => {
    setActiveCTA((prevState) => {
      if (prevState === val) {
        return undefined;
      }
      return val;
    });
  };

  let filteredData = data?.slice();
  if (activeCTA) {
    let config = ctaConfigs.find((i) => i.id === activeCTA);
    if (config) {
      const temp = config; // TypeScript forgets that `config` is defined for some reason
      filteredData = filteredData?.filter((i) => temp.matcher(i));
    }
  }

  return {
    activeCTA,
    filteredData,
    toggleCTAFilter,
  };
}
