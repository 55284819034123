import { IconCarFilled } from '@tabler/icons-react';
import classnames from 'classnames';
import AspectRatio from 'Sparky/AspectRatio';
import Flex from 'Sparky/Flex';
import { COLOR } from 'Sparky/styles/vars';
import Text, { TextProps } from 'Sparky/Text';

import { getPriceScenarioColor } from 'pages/Inventory/VinPath/utils/getPriceScenarioColor';
import { getProbabilityLabel } from 'pages/Inventory/VinPath/utils/getProbabilityLabel';
import { InventoryItem, ProbabilityOfSaleKeys } from 'types/InventoryItem';
import { formatCurrency, formatPercent } from 'util/formatters';

import styles from './VinInfo.module.scss';

export interface VinInfoProps {
  inventoryItem: InventoryItem;
}

/**
 * VinDetails
 */
export default function VinInfo({ inventoryItem }: VinInfoProps) {
  const { primaryImage, dol, mileage } = inventoryItem.listing;
  const { make, model, modelYear, bodySegment, trim } = inventoryItem.vehicle;

  return (
    <Flex column flex='none' rowGap='12px' width='442px'>
      <Flex columnGap='8px'>
        <AspectRatio ratio={4 / 3} className={styles.image}>
          <img
            className={classnames([process.env.REACT_APP_DEPLOY_ENV === 'demo' && styles.blur])}
            src={primaryImage}
            alt=''
          />
        </AspectRatio>
        <Flex column rowGap='4px' width='fit-content'>
          <Text fontWeight={5} textWrap='nowrap'>
            {modelYear} {make} {model}
          </Text>
          <Text size={12} color='secondary' textWrap='nowrap'>
            {trim} {bodySegment}
          </Text>
          <Text size={12} color='secondary' textWrap='nowrap'>
            {dol} days on lot • {mileage} mi
          </Text>
        </Flex>
      </Flex>
      <Flex column rowGap='8px' width='100%'>
        <Flex alignItems='center' justifyContent='space-between' columnGap='15px' width='100%'>
          <ProbabilityOfSale probabilityOfSale={inventoryItem.vinPath?.currentProbabilityOfSale} />
          <Flex alignItems='center' flex='0 1 auto' columnGap='2px' width='100%'>
            <IconCarFilled size={12} color={COLOR.SPARKY_NAVY_400} />
            <div className={styles.dotline} />
          </Flex>
          <ProbabilityOfSale
            probabilityOfSale={
              inventoryItem.vinPath?.pricingOptions
                ? inventoryItem.vinPath?.pricingOptions[0]?.probabilityOfSale
                : undefined
            }
            className={styles.probabilityOfSale}
          />
        </Flex>
        <Flex width='100%' justifyContent='space-between'>
          <PriceToMarket
            price={inventoryItem.priceMetrics?.currentPrice}
            priceToMarketPct={inventoryItem.priceMetrics?.currentPriceToMarketPct}
          />
          <PriceToMarket
            price={
              inventoryItem.vinPath?.pricingOptions
                ? inventoryItem.vinPath?.pricingOptions[0]?.priceAfterMarkdown
                : undefined
            }
            priceToMarketPct={
              inventoryItem.vinPath?.pricingOptions
                ? inventoryItem.vinPath?.pricingOptions[0]?.priceToMarketPct
                : undefined
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

interface PriceToMarketProps {
  price?: number;
  priceToMarketPct?: number;
}

function PriceToMarket({ price, priceToMarketPct }: PriceToMarketProps): JSX.Element {
  return (
    <Flex column flex='none' rowGap='8px' width='100px'>
      <Text color='secondary' size={20}>
        {price ? formatCurrency(price) : ''}
      </Text>
      <Text textWrap='nowrap' color='secondary' size={12}>
        {priceToMarketPct ? `${formatPercent(priceToMarketPct)} to market` : ''}
      </Text>
    </Flex>
  );
}

interface ProbabilityOfSaleProps {
  probabilityOfSale?: ProbabilityOfSaleKeys;
  className?: string;
}

function ProbabilityOfSale({ probabilityOfSale, className }: ProbabilityOfSaleProps) {
  return (
    <Text
      size={14}
      color={getPriceScenarioColor(probabilityOfSale) as TextProps<'div'>['color']}
      fontWeight={5}
      as='div'
      textWrap='nowrap'
      className={className}
    >
      {getProbabilityLabel(probabilityOfSale)}
    </Text>
  );
}
