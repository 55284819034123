import { useMutation } from '@tanstack/react-query';
import { produce } from 'immer';
import { useAtom } from 'jotai';

import ENDPOINTS from 'api/utils/endpoints';
import { post } from 'api/utils/fetch';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

export interface ChangePwdData {
  pwd: string;
  newPassword1: string;
  newPassword2: string;
}

interface PostChangePwdProps extends ChangePwdData {
  token?: string;
}

/**
 * useChangePwd
 */

async function postChangePwd({ token, pwd, newPassword1, newPassword2 }: PostChangePwdProps) {
  assertIsDefined(pwd);
  assertIsDefined(newPassword1);
  assertIsDefined(newPassword2);
  assertIsDefined(token);

  return post(ENDPOINTS.change, {
    headers: { token },
    params: {
      pwd: pwd,
      newpassword1: newPassword1,
      newpassword2: newPassword2,
    },
  });
}

export default function useChangePwd() {
  const [user, setUser] = useAtom(userAtom);
  return useMutation(
    ({ pwd, newPassword1, newPassword2 }: ChangePwdData) =>
      postChangePwd({ token: user?.token, pwd, newPassword1, newPassword2 }),
    {
      onSuccess: () =>
        setUser(
          produce(user, (draft) => {
            if (draft) {
              draft.forcePwChange = false;
            }
            return draft;
          })
        ),
    }
  );
}
