export const SegmentsPanelIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.614 18.7475C15.0984 18.7475 15.4911 18.3519 15.4911 17.8638C15.4911 17.3757 15.0984 16.9801 14.614 16.9801C14.1295 16.9801 13.7368 17.3757 13.7368 17.8638C13.7368 18.3519 14.1295 18.7475 14.614 18.7475ZM21.6315 18.7475C22.116 18.7475 22.5087 18.3519 22.5087 17.8638C22.5087 17.3757 22.116 16.9801 21.6315 16.9801C21.147 16.9801 20.7543 17.3757 20.7543 17.8638C20.7543 18.3519 21.147 18.7475 21.6315 18.7475Z'
      fill='#4B4B4B'
    />
    <path
      d='M6.30986 17.5692H7.47945C7.73286 17.5692 8.21629 17.4868 8.12273 17.1568V15.7429C8.08374 15.5661 7.9005 15.2126 7.47945 15.2126H6.30986C6.17341 15.2323 5.87711 15.3658 5.78354 15.7429V17.1568C5.80304 17.2943 5.93559 17.5692 6.30986 17.5692Z'
      fill='#4B4B4B'
    />
    <path
      d='M9.3508 20.515V22.2825H4.38004C3.77575 22.2236 2.52039 21.7876 2.33325 20.515V6.84662C2.37224 6.15927 2.83618 4.76101 4.38004 4.66675H13.035C13.7173 4.68639 15.1052 5.14985 15.1987 6.84662V9.4389C14.9453 9.4389 14.2397 9.54495 13.4444 9.96914V7.02337C13.4444 6.82698 13.3274 6.42243 12.8596 6.3753H4.61395C4.45801 6.35566 4.13442 6.42243 4.08764 6.84662V19.9848C4.08764 20.1419 4.16951 20.4679 4.49699 20.515H9.3508Z'
      fill='#4B4B4B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.2748 21.1042H23.9122V22.8127C23.9902 23.068 24.2865 23.555 24.8479 23.4608C25.4093 23.3665 25.6276 22.9895 25.6666 22.8127V17.746C25.6081 17.1372 25.2221 15.7782 24.1461 15.2126L23.035 11.9723C22.8206 11.5009 22.0642 10.5465 20.7543 10.4994H15.3157C14.7894 10.5387 13.6198 10.8882 13.152 11.9723L11.9824 15.2126C11.4756 15.5661 10.4736 16.5677 10.5204 17.746V22.8127C10.5789 23.0287 10.8245 23.4608 11.3391 23.4608C11.8537 23.4608 12.1773 23.0287 12.2748 22.8127V21.1042ZM12.2748 17.746V19.3367H23.9122V17.746C23.8654 16.9919 22.957 16.5284 22.5087 16.3909H13.7368C12.8011 16.4852 12.3722 17.3336 12.2748 17.746ZM22.1578 14.6235H14.0876C14.2748 12.8324 14.9843 12.3061 15.3157 12.2668H20.7543C21.5496 12.2668 22.0214 13.8379 22.1578 14.6235Z'
      fill='#4B4B4B'
    />
    <path
      d='M6.30986 14.0932H7.47945C7.73286 14.0932 8.21629 14.0107 8.12273 13.6808V12.2668C8.08374 12.0901 7.9005 11.7366 7.47945 11.7366H6.30986C6.17341 11.7562 5.87711 11.8898 5.78354 12.2668V13.6808C5.80304 13.8183 5.93559 14.0932 6.30986 14.0932Z'
      fill='#4B4B4B'
    />
    <path
      d='M6.30986 10.4994H7.47945C7.73286 10.4994 8.21629 10.4169 8.12273 10.087V8.673C8.08374 8.49625 7.9005 8.14276 7.47945 8.14276H6.30986C6.17341 8.1624 5.87711 8.29594 5.78354 8.673V10.087C5.80304 10.2244 5.93559 10.4994 6.30986 10.4994Z'
      fill='#4B4B4B'
    />
    <path
      d='M9.87711 10.4994H11.0467C11.3001 10.4994 11.7835 10.4169 11.69 10.087V8.673C11.651 8.49625 11.4678 8.14276 11.0467 8.14276H9.87711C9.74066 8.1624 9.44436 8.29594 9.3508 8.673V10.087C9.37029 10.2244 9.50284 10.4994 9.87711 10.4994Z'
      fill='#4B4B4B'
    />
    <path
      d='M9.87711 14.0932H11.0467C11.3001 14.0932 11.7835 14.0107 11.69 13.6808V12.2668C11.651 12.0901 11.4678 11.7366 11.0467 11.7366H9.87711C9.74066 11.7562 9.44436 11.8898 9.3508 12.2668V13.6808C9.37029 13.8183 9.50284 14.0932 9.87711 14.0932Z'
      fill='#4B4B4B'
    />
  </svg>
);
