import classnames from 'classnames';
import { useAtom } from 'jotai';
import Breadcrumbs from 'Sparky/Breadcrumbs';
import SwitchField from 'Sparky/SwitchField';
import { showCompactInventoryViewAtom, showSwitchFieldAtom } from 'store/inventoryItemStore';

import { InventoryPageKey } from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';
import { toTitleCase } from 'util/formatters';

import styles from './PageHeader.module.scss';

export interface PageHeaderProps {
  showInventoryHighlights?: boolean;
  showSoldInventorySummary?: boolean;
  setShowInventoryHighlights?(val: boolean): void;
  setShowSoldInventorySummary?(val: boolean): void;
  inventoryPageKey: InventoryPageKey;
}

/**
 * PageHeader
 */
export default function PageHeader({
  showInventoryHighlights,
  setShowInventoryHighlights,
  inventoryPageKey,
  showSoldInventorySummary,
  setShowSoldInventorySummary,
}: PageHeaderProps) {
  const [showCompactInventoryView] = useAtom(showCompactInventoryViewAtom);
  const [showSwitchField] = useAtom(showSwitchFieldAtom);
  return (
    <Breadcrumbs
      pageTitles={[
        { title: 'Inventory' },
        { title: toTitleCase(inventoryPageKey === 'live' ? 'Live inventory' : 'Sold inventory') },
      ]}
      className={classnames([styles.pageHeader, showCompactInventoryView && styles.compact])}
    >
      {inventoryPageKey === 'live' && showSwitchField && (
        <SwitchField
          id='highlights'
          position='left'
          label='Highlights'
          checked={showInventoryHighlights}
          onCheckedChange={() =>
            setShowInventoryHighlights && setShowInventoryHighlights(!showInventoryHighlights)
          }
        />
      )}
      {inventoryPageKey === 'sold' && showSwitchField && (
        <SwitchField
          id='summary'
          position='right'
          label='Summary'
          checked={showSoldInventorySummary}
          onCheckedChange={() =>
            setShowSoldInventorySummary && setShowSoldInventorySummary(!showSoldInventorySummary)
          }
        />
      )}
    </Breadcrumbs>
  );
}
