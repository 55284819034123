import Flex from 'Sparky/Flex';
import Spinner from 'Sparky/Spinner';
import Text from 'Sparky/Text';

interface LoadingStateProps {
  classnames?: string;
}
/**
 * LoadingState
 */
export default function LoadingState({ classnames }: LoadingStateProps) {
  return (
    <Flex
      flex='none'
      width='100%'
      height='600px'
      justifyContent='center'
      alignItems='center'
      rowGap='24px'
      column
      className={classnames}
    >
      <Spinner size='lg' />
      <Text size='16' fontWeight={4}>
        Data is loading...
      </Text>
    </Flex>
  );
}
