import React from 'react';

import classnames from 'classnames';

import styles from './Spinner.module.scss';

export interface SpinnerProps {
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Spinners provide a visual cue that an action is either processing, awaiting a course of change or a result.
 */
export default function Spinner({
  color = 'inherit',
  size = 'md',
  className,
  style,
}: SpinnerProps) {
  return (
    <div
      data-testid='spinner'
      className={classnames([styles.spinner, `spinner__${size}`, className])}
      style={{ color: color, ...style }}
    ></div>
  );
}
