import { LXBrainV2 } from 'Sparky/SVGs/LXBrainV2';
import Text from 'Sparky/Text';

import styles from './ChatHeader.module.scss';

export interface ChatHeaderProps {
  prop?: any;
}

/**
 * ChatHeader
 */
export default function ChatHeader({ prop }: ChatHeaderProps) {
  return (
    <div className={styles.brainChatHeader}>
      <div className={styles.brainCircle}>
        <LXBrainV2 />
      </div>
      <Text size='24' fontWeight={7} color='secondary' alignment='center'>
        Learn more about your business with the power of AI
      </Text>
    </div>
  );
}
