import { InventoryItem } from 'types/InventoryItem';

import { CTAFilterConfig } from './useCTAFilters';

/** CTA Filter configuration for the Inventory page */
export const CTAFilterConfigs: CTAFilterConfig<InventoryItem>[] = [
  {
    id: 'capped',
    label: 'VINs have reached budget',
    buttonLabel: 'See all capped VINs',
    matcher: (i: InventoryItem) =>
      i.budgetMetrics.budget ? i.budgetMetrics.spend >= i.budgetMetrics.budget : false,
  },
  {
    id: 'overMarket',
    label: 'VINs priced above market average',
    buttonLabel: 'See all over market VINs',
    matcher: (i: InventoryItem) =>
      i.priceMetrics?.currentPriceToMarketPct
        ? i.priceMetrics?.currentPriceToMarketPct > 1.02
        : false,
  },
  {
    id: 'belowMarket',
    label: 'VINs priced below market average',
    buttonLabel: 'See all under market VINs',
    matcher: (i: InventoryItem) =>
      i.priceMetrics?.currentPriceToMarketPct
        ? i.priceMetrics?.currentPriceToMarketPct <= 0.98
        : false,
  },
  {
    id: 'riskVINs',
    label: 'Risk VINs',
    buttonLabel: 'See all Risk VINs',
    matcher: (i: InventoryItem) => i.scores?.riskScore !== undefined && i.scores.riskScore > 60,
  },
];
