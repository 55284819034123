import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { marketOpportunityDataSchema } from 'pages/Analytics/MarketOpportunity/api/useMarketOpportunity/marketOpportunitySchema';
import { userAtom } from 'store';
import { NumberFormat } from 'types/NumberFormat';
import { assertIsDefined } from 'util/assert';

interface MarketOpportunityDef {
  label: string;
  format: NumberFormat;
}

export const marketOpportunityDefs: Record<string, MarketOpportunityDef> = {
  inMarketShoppers: {
    label: 'The market',
    format: NumberFormat.INT,
  },
  inMarketShoppersTrend: {
    label: 'The market',
    format: NumberFormat.INT,
  },
  marketSalesVolumeTrend: {
    label: 'The market',
    format: NumberFormat.INT,
  },
  dealerSalesVolumeTrend: {
    label: 'Your dealership',
    format: NumberFormat.INT,
  },
  marketDaysSupplyTrend: {
    label: 'Market days supply',
    format: NumberFormat.INT,
  },
  listedUnits: {
    label: 'Listed units',
    format: NumberFormat.INT,
  },
  pctVinsViewedTrend: {
    label: '% of VINs viewed trend',
    format: NumberFormat.PERCENT,
  },
  demandPace: {
    label: 'Views per day',
    format: NumberFormat.INT,
  },
  marketShareTrend: {
    label: 'Your market share',
    format: NumberFormat.PERCENT,
  },
  inventory: {
    label: 'Units',
    format: NumberFormat.INT,
  },
  riskInventoryTrend: {
    label: `Your at risk used SUV's`,
    format: NumberFormat.PERCENT,
  },
  priceToMarketTrend: {
    label: 'Your price to market',
    format: NumberFormat.PERCENT,
  },
  dealerListedUnitsTrend: {
    label: 'Units',
    format: NumberFormat.INT,
  },
  netNewShoppersTrend: {
    label: '% net new shoppers',
    format: NumberFormat.PERCENT,
  },
  marketListedTrend: {
    label: 'Units',
    format: NumberFormat.INT,
  },
};

export type MarketOpportunityMetricNames =
  | 'inMarketShoppers'
  | 'salesVolume'
  | 'marketDaysSupply'
  | 'listedUnits';

export interface MapDataPoint {
  hex: string;
  value: number;
  dealerCount: number;
}
export interface MapData {
  data: MapDataPoint[];
}

export interface ChartData {
  value?: number;
  data: ChartDataPoint[];
}

export interface ChartDataPoint {
  date: string;
  [k: string]: number | string | null;
}

export interface MarketOpportunityData {
  mapCenter: {
    lat: number;
    lng: number;
  };
  mapData: Record<MarketOpportunityMetricNames, MapData>;
  metricData?: Record<string, ChartData>;
  marketOppVinsViewed?: {
    percent: number;
    numberOfVins?: number;
  };
  marketOppAvgDaysToSale?: {
    dealer?: number;
    market?: number;
    percent?: number;
  };
}

export interface MarketOpportunityResponse {
  data: MarketOpportunityData;
}

export interface MarketOpportunityProps {
  selectedOption: string;
  selectedSegment: string;
}

export interface SegmentBreakdown {
  make: string | undefined;
  model: string | undefined;
  bodySegment: string | undefined;
  condition: string | undefined;
}

/**
 * useMarketOpportunity
 * @param prop
 */

export default function useMarketOpportunity({
  selectedOption,
  selectedSegment,
}: MarketOpportunityProps) {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const queryKey = ['marketOpportunity', dealerId, user, selectedSegment];

  async function fetchMarketOpportunity(
    selectedOption: string,
    selectedSegment: string,
    token?: string,
    dealerId?: number
  ) {
    assertIsDefined(token);
    assertIsDefined(dealerId);

    const endDate = dayjs().format('YYYYMMDD');

    const { condition, make, model, bodySegment } = getVehicleSegments(
      selectedOption,
      selectedSegment
    );

    const res = await get<MarketOpportunityData>(`${ENDPOINTS.market_opportunity}/${dealerId}`, {
      headers: { token },
      params: {
        metric:
          'inMarketShoppers,salesVolume,marketDaysSupply,listedUnits,pctVinsViewedTrend,avgDaysToSaleTrend,marketShareTrend,dealerSalesVolumeTrend,marketSalesVolumeTrend,dealerListedUnitsTrend,marketDaysSupplyTrend,netNewShoppersTrend,riskInventoryTrend,priceToMarketTrend,inMarketShoppersTrend,marketListedTrend',
        condition,
        make,
        model,
        bodySegment,
        startDate: dayjs(endDate).subtract(30, 'days').format('YYYYMMDD'),
        endDate,
        raw: true,
      },
    });

    return marketOpportunityDataSchema.parse(res);
  }
  return useQuery<MarketOpportunityData, Error>({
    queryKey,
    queryFn: () => fetchMarketOpportunity(selectedOption, selectedSegment, user?.token, dealerId),
  });
}

export function getVehicleSegments(
  selectedOption: string,
  selectedSegment: string
): SegmentBreakdown {
  const selectedSplit = selectedOption.split('|');
  const segmentsSplit = selectedSegment.split('|');

  return {
    condition:
      selectedSplit.indexOf('CONDITION') > -1
        ? segmentsSplit[selectedSplit.indexOf('CONDITION')]
        : undefined,
    make:
      selectedSplit.indexOf('MAKE') > -1 ? segmentsSplit[selectedSplit.indexOf('MAKE')] : undefined,
    model:
      selectedSplit.indexOf('MODEL') > -1
        ? segmentsSplit[selectedSplit.indexOf('MODEL')]
        : undefined,
    bodySegment:
      selectedSplit.indexOf('BODYSTYLE') > -1
        ? segmentsSplit[selectedSplit.indexOf('BODYSTYLE')]
        : undefined,
  };
}
