import React from 'react';

import classnames from 'classnames';
import NumberInput, { NumberInputProps } from 'Sparky/NumberInput';
import Spacer from 'Sparky/Spacer';

import styles from './NumberInputField.module.scss';

export interface NumberInputFieldProps extends NumberInputProps {
  label: string;
  info?: string;
  message?: string;
  labelClassName?: string;

  onInfoClick?(): void;
}

/**
 * NumberInputField
 */
export const NumberInputField = React.forwardRef<HTMLInputElement, NumberInputFieldProps>(
  (
    { label, info, onInfoClick, message, variants, disabled, labelClassName, ...otherProps },
    ref
  ) => {
    return (
      <div className={classnames({ [styles.numberInputField]: true }, otherProps.className)}>
        <div className={styles.labelField}>
          <label htmlFor={otherProps.id} className={classnames([styles.label, labelClassName])}>
            {label}
          </label>
          <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
          {info && (
            <span onClick={onInfoClick} className={styles.info}>
              {info}
            </span>
          )}
        </div>
        <NumberInput
          {...otherProps}
          ref={ref}
          disabled={disabled}
          variants={variants}
          className={styles.selectInput}
        />
        {message && (
          <div
            className={classnames({
              [styles.message]: true,
              [styles.default]: variants === 'default' && !disabled,
              [styles.success]: variants === 'success' && !disabled,
              [styles.warning]: variants === 'warning' && !disabled,
              [styles.error]: variants === 'error' && !disabled,
              [styles.disabled]: disabled,
            })}
          >
            {message}
          </div>
        )}
      </div>
    );
  }
);

export default NumberInputField;
