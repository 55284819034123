export const LXBrainV2 = ({ size = 72 }: { size?: number }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 73 72'
    fill='none'
  >
    <path d='M8.571 32.6862L6.42456 34.795L13.0145 41.3096H17.2321L8.571 32.6862Z' fill='#F41424' />
    <path
      d='M8.571 24.0251L6.42456 26.2092L24.2363 43.9833H28.5292L8.571 24.0251Z'
      fill='#F41424'
    />
    <path
      d='M13.0145 19.7322L10.7174 21.9916L30.0731 41.3096L36.5877 34.795L19.3409 17.6611H15.0856L32.2949 34.795L30.0731 36.9414L13.0145 19.7322Z'
      fill='#F41424'
    />
    <path
      d='M25.8179 15.477L23.6338 17.6611L30.0354 24.0251L45.2112 9H40.843L30.0354 19.7322L25.8179 15.477Z'
      fill='#F41424'
    />
    <path
      d='M30.0354 15.477L27.9267 13.3305L30.0354 11.1841H34.4036L30.0354 15.477Z'
      fill='#F41424'
    />
    <path
      d='M32.2572 47.7113L30.0731 45.5649L40.843 34.795L32.2572 26.2469L47.32 11.1841L49.4664 13.3305L36.5501 26.2469L45.1359 34.795L32.2572 47.7113Z'
      fill='#F41424'
    />
    <path
      d='M55.8681 15.477L53.7593 13.3305L40.843 26.2469L49.4664 34.795L34.4413 49.8201L39.638 55.1297H44.0061L38.7342 49.8201L53.7593 34.795L45.1359 26.2469L55.8681 15.477Z'
      fill='#F41424'
    />
    <path
      d='M60.1986 19.7699L58.0898 17.6611L49.4664 26.2469L55.8681 32.7238L62.3827 26.2469L60.1986 24.0628L55.8681 28.3933L53.7593 26.2469L60.1986 19.7699Z'
      fill='#F41424'
    />
    <path
      d='M66.6756 30.5397L64.5292 28.3933L42.9894 49.8201L56.094 63V58.5941L47.32 49.8201L66.6756 30.5397Z'
      fill='#F41424'
    />
    <path d='M64.5292 41.3096V37.0167L51.7258 49.8201H56.0564L64.5292 41.3096Z' fill='#F41424' />
  </svg>
);
