import { ReactNode } from 'react';

import * as Select from '@radix-ui/react-select';
import { SelectProps } from '@radix-ui/react-select';
import { IconCaretDownFilled } from '@tabler/icons-react';
import classnames from 'classnames';
import { SelectInputOptions } from 'Sparky/SelectInput';
import Skeleton from 'Sparky/Skeleton';
import { SPACING } from 'Sparky/styles/vars';

import styles from './Select.module.scss';

export interface SparkySelectProps extends SelectProps {
  options: SelectInputOptions;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  position?: 'item-aligned' | 'popper';
  container?: HTMLElement | null | undefined;
  isLoading?: boolean;
  className?: string;
  labelClassName?: string;
}

const SelectItem = ({ children, value }: { children: ReactNode; value: string }) => {
  return (
    <Select.Item className={styles.selectItem} value={value}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className='SelectItemIndicator'></Select.ItemIndicator>
    </Select.Item>
  );
};

export default function SparkySelect({
  options,
  align,
  side = 'bottom',
  sideOffset = 2,
  position = 'popper',
  container,
  isLoading,
  className,
  ...otherProps
}: SparkySelectProps) {
  if (isLoading) {
    return (
      <Skeleton height={SPACING.SPACING_16} width={SPACING.SPACING_48} className={className} />
    );
  }

  return (
    <Select.Root {...otherProps}>
      <Select.Trigger
        className={classnames([styles.selectTrigger, className])}
        aria-label='select-dropdown-trigger'
      >
        <Select.Value className={classnames([styles.value])} />
        <Select.Icon className={styles.selectIcon}>
          <IconCaretDownFilled size={12} />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal container={container}>
        <Select.Content
          position={position}
          side={side}
          sideOffset={sideOffset}
          align={align}
          className={styles.selectContent}
        >
          <Select.Viewport className='SelectViewport'>
            {options.map((i) => (
              <SelectItem key={i.value} value={i.value}>
                {i.label}
              </SelectItem>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
