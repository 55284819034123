import React, { Key, useEffect, useMemo, useState } from 'react';

import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import { produce } from 'immer';
import { useAtom } from 'jotai';
import { cloneDeep, isEqual } from 'lodash';
import { unstable_useBlocker as useBlocker } from 'react-router';
import { useParams } from 'react-router-dom';
import Button from 'Sparky/Button';
import CheckboxField from 'Sparky/CheckboxField';
import IconButton from 'Sparky/IconButton';
import Modal from 'Sparky/Modal';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import useToast from 'Sparky/Toast';
import { showInventoryAddBudgetSidebarAtom } from 'store/addBudgetAtom';
import {
  defaultFirstInventoryConfigId,
  selectedAtom,
  showCompactInventoryViewAtom,
  showInventorySideBarAtom,
  showSwitchFieldAtom,
  showUnsavedAlertAtom,
} from 'store/inventoryItemStore';

import { Contracts } from 'api/useInventory/useInventory';
import ErrorFallback from 'components/ErrorFallback/ErrorFallback';
import { FiltersProvider, FiltersState, useFilters } from 'components/Filters';
import { useNavigateKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import useFeatureFlag from 'hooks/useFeatureFlag';
import usePersistentState from 'hooks/usePersistentState';
import useSelectedDealer from 'hooks/useSelectedDealer';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import AddBudgetSidebar from 'pages/Inventory/InventoryPage/AddBudgetSidebar';
import {
  defaultLiveInventoryConfig,
  defaultSoldInventoryConfig,
} from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/mocks/mockInventoryTableConfigData';
import useInventoryTableConfig, {
  InventoryConfig,
} from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';
import useSaveInventoryTableConfig, {
  InventoryPageKey,
} from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';
import CustomizeSidebar from 'pages/Inventory/InventoryPage/CustomizeSidebar/CustomizeSidebar';
import {
  InventoryTableColumnKey,
  InventoryTableWithValueColumnKey,
} from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import HighlightsToggle from 'pages/Inventory/InventoryPage/HighlightsToggle';
import {
  CTAFilterConfigs,
  useCTAFilters,
} from 'pages/Inventory/InventoryPage/HighlightsToggle/CTAFilters';
import InventoryFilters, {
  InventoryFilterConfigs,
} from 'pages/Inventory/InventoryPage/InventoryFilters';
import InventoryHeader from 'pages/Inventory/InventoryPage/InventoryHeader';
import InventoryTable, {
  SortColumn,
  sortMultiColumns,
} from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTable';
import {
  InventoryTableAddBudgetSidebarColumnDefs,
  InventoryTableColumnDefs,
  InventoryTableColumnWithGetterDefs,
} from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';
import SortInventory from 'pages/Inventory/InventoryPage/SortInventory';
import { inventoryContractListAtom } from 'pages/Inventory/SoldInventory';
import SoldInventorySummary from 'pages/Inventory/SoldInventory/SoldInventorySummary/SoldInventorySummary';
import VinDetailSidebar from 'pages/Inventory/VinDetailSidebar';
import InsightsSection from 'pages/Inventory/VinDetailSidebar/InsightsSection';
import LeadsSection from 'pages/Inventory/VinDetailSidebar/LeadsSection';
import MarketSection from 'pages/Inventory/VinDetailSidebar/MarketSection';
import ShopperDemandSection from 'pages/Inventory/VinDetailSidebar/ShopperDemandSection';
import SummarySection from 'pages/Inventory/VinDetailSidebar/SummarySection';
import VDPSection from 'pages/Inventory/VinDetailSidebar/VDPSection';
import VinPathPriceSection from 'pages/Inventory/VinDetailSidebar/VinPathPriceSection';
import { FeatureFlag } from 'types/FeatureFlags';
import { InventoryItem } from 'types/InventoryItem';

import styles from './InventoryPage.module.scss';

export interface InventoryPageProps {
  inventoryData?: InventoryItem[];
  isInventoryLoading: boolean;
  isInventoryError: Error | null;
  showSoldInventorySummary?: boolean;
  inventoryPageKey: InventoryPageKey;
  contracts?: Contracts;
}

export type ButtonSize = 'sm' | 'md';
export type CustomizeInventory = '' | 'editing' | 'creating' | 'fromScratch';
export type ShowInventorySideBar = '' | 'sort' | 'filter' | 'arrange' | 'columns';

/**
 * InventoryPage
 */
export default function InventoryPage({
  inventoryData,
  isInventoryLoading,
  isInventoryError,
  showSoldInventorySummary = false,
  inventoryPageKey,
  contracts,
}: InventoryPageProps) {
  const { vin } = useParams();
  const navigate = useNavigateKeepParams();
  const { dealer } = useSelectedDealer();
  const [, setInventoryContractList] = useAtom(inventoryContractListAtom);

  const defaultCreatingInventoryConfig: InventoryConfig = {
    id: 0,
    isFavourite: false,
    isPreset: false,
    name: '',
    details: `Custom view created on ${new Date().toLocaleDateString()}`,
    inventoryTableConfig: {
      frozenColumns: [],
      mainColumns: [],
    },
    sortColumns: [],
    filtersState: {},
  };

  useEffect(() => {
    setInventoryContractList(
      inventoryPageKey === 'live'
        ? contracts?.liveInvContracts ?? []
        : contracts?.soldInvContracts ?? []
    );
  }, [contracts, inventoryPageKey]);

  const AIRecommendedBudgetIncrease = useFeatureFlag(
    FeatureFlag.INVENTORY_TABLE_AI_RECOMMENDED_BUDGET_INCREASE_COLUMN
  );

  const columnFlags = {
    aiRecommendedBudgetIncrease: AIRecommendedBudgetIncrease,
  };

  // Fetch Inventory table view data from API
  const {
    data: inventoryConfigData,
    isLoading: inventoryConfigIsLoading,
    error: inventoryConfigError,
  } = useInventoryTableConfig();

  const inventoryConfigs = useMemo(
    () =>
      inventoryPageKey === 'live'
        ? inventoryConfigData?.liveInventoryConfigs.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [defaultLiveInventoryConfig]
        : inventoryConfigData?.soldInventoryConfigs.sort((a, b) =>
            a.name.localeCompare(b.name)
          ) ?? [defaultSoldInventoryConfig],
    [inventoryConfigData, inventoryPageKey]
  );

  // Post inventory table view
  const mutation = useSaveInventoryTableConfig();

  const [selected, setSelected] = useAtom(selectedAtom);
  // Inventory table view states
  const [, setShowSwitchField] = useAtom(showSwitchFieldAtom);
  const [showTableViewNameError, setShowTableViewNameError] = useState<boolean>(false);
  const [isCustomizing, setIsCustomizing] = useState<CustomizeInventory>('');
  const [dealerId] = useSelectedDealerId();
  const [creatingFromScratch, setCreatingFromScratch] = useState<boolean>(false);
  const [currentInventoryConfig, setCurrentInventoryConfig] = usePersistentState<InventoryConfig>(
    `${inventoryPageKey}-current-inventory-config-${dealerId}`,
    inventoryPageKey === 'live' ? defaultLiveInventoryConfig : defaultSoldInventoryConfig
  );

  const [lastTimeSelectedInventoryConfig, setLastTimeSelectedInventoryConfig] =
    useState<InventoryConfig>(currentInventoryConfig);

  const isInventoryConfigChanged = useMemo(
    () => !isEqual(currentInventoryConfig, lastTimeSelectedInventoryConfig),
    [currentInventoryConfig, lastTimeSelectedInventoryConfig]
  );
  // Function of updating sortColumns and FiltersStates
  const updateSortColumns = (sortColumns: SortColumn[]) => {
    setCurrentInventoryConfig(
      produce(currentInventoryConfig, (draft) => {
        draft.sortColumns = sortColumns;
        return draft;
      })
    );
  };

  const updateFiltersStates = (filtersState: FiltersState) => {
    setCurrentInventoryConfig(
      produce(currentInventoryConfig, (draft) => {
        draft.filtersState = filtersState;
        return draft;
      })
    );
  };
  // Budget applied VINs state
  const [budgetAppliedVins, setBudgetAppliedVins] = useState<string[]>([]);
  // Show inventory page sidebar or modal states
  const [showInventorySidebar, setShowInventorySidebar] = useAtom(showInventorySideBarAtom);
  const [showAddBudgetSidebar, setShowAddBudgetSidebar] = useAtom(
    showInventoryAddBudgetSidebarAtom
  );
  const [showCompactInventoryView, setShowCompactInventoryView] = useAtom(
    showCompactInventoryViewAtom
  );
  const [showUnsavedAlert, setShowUnsavedAlert] = useAtom(showUnsavedAlertAtom);
  const [showBudgetModal, setShowBudgetModal] = useState<boolean>(false);

  const blocker = useBlocker(({ nextLocation }) => {
    if (
      !showUnsavedAlert &&
      !nextLocation.pathname.includes(`inventory/${inventoryPageKey}`) &&
      isInventoryConfigChanged
    ) {
      // initialize the customizing state
      setIsCustomizing('');
      setCurrentInventoryConfig(lastTimeSelectedInventoryConfig);
    }
    return (
      !nextLocation.pathname.includes(`inventory/${inventoryPageKey}`) &&
      isInventoryConfigChanged &&
      showUnsavedAlert
    );
  });

  const onStay = () => {
    if (blocker.reset) {
      blocker.reset();
    }
  };

  const onLeave = () => {
    if (blocker.proceed) {
      setIsCustomizing('');
      setCurrentInventoryConfig(lastTimeSelectedInventoryConfig);
      blocker.proceed();
    }
  };

  const { addToast } = useToast();

  // Inventory table column defs
  const inventoryTableColumnWithGetterDefs = useMemo(
    () => InventoryTableColumnWithGetterDefs(inventoryPageKey, columnFlags),
    [AIRecommendedBudgetIncrease, dealer?.id]
  );

  const inventoryTableAddBudgetSidebarColumnDefs = useMemo(
    () => InventoryTableAddBudgetSidebarColumnDefs(columnFlags),
    [AIRecommendedBudgetIncrease, dealer?.id]
  );

  const inventoryTableColumnDefs = useMemo(
    () => InventoryTableColumnDefs(inventoryPageKey, columnFlags),
    [AIRecommendedBudgetIncrease, dealer?.id]
  );

  const isLoading = inventoryConfigIsLoading || isInventoryLoading;

  const filterConfigs = InventoryFilterConfigs<InventoryItem>(selected);

  const {
    activeCTA,
    filteredData: ctaData,
    toggleCTAFilter,
  } = useCTAFilters({ data: inventoryData, ctaConfigs: CTAFilterConfigs });

  const { filteredData, ...filtersProviderProps } = useFilters({
    data: ctaData,
    filterConfigs: filterConfigs,
    filtersState: currentInventoryConfig.filtersState ?? {},
    setFiltersState: updateFiltersStates,
  });

  /** "Active" selected VINs – these are the selected VINs that match the active filters */
  const visibleSelected = selected.filter((vin) =>
    filteredData?.find((i) => i.listing.vin === vin)
  );

  const buttonSize: ButtonSize = showCompactInventoryView ? 'sm' : 'md';

  const sortedTableData = useMemo(() => {
    return sortMultiColumns(
      filteredData,
      currentInventoryConfig.sortColumns,
      inventoryTableColumnWithGetterDefs
    );
  }, [currentInventoryConfig.sortColumns, filteredData, inventoryTableColumnWithGetterDefs]);

  useEffect(() => {
    setBudgetAppliedVins([]);
    setShowAddBudgetSidebar(false);
  }, [dealer]);

  useEffect(() => {
    !inventoryData && setSelected([]);
  }, [inventoryData]);

  useEffect(() => {
    let newId = defaultFirstInventoryConfigId;
    inventoryConfigs.forEach((inventoryConfig) => {
      if (inventoryConfig.id >= newId) {
        newId = inventoryConfig.id + 1;
      }
    });

    setShowTableViewNameError(false);
    if (isCustomizing === 'editing') {
      setShowSwitchField(false);
      setCurrentInventoryConfig(
        produce(currentInventoryConfig, (draft) => {
          draft.isFavourite = false;
          draft.isPreset = false;
          draft.details = `Custom view created on ${new Date().toLocaleDateString()}`;
          return draft;
        })
      );
    } else if (isCustomizing === 'creating') {
      setShowSwitchField(false);
      setCurrentInventoryConfig(
        produce(currentInventoryConfig, (draft) => {
          draft.id = newId;
          draft.isFavourite = false;
          draft.isPreset = false;
          draft.name = '';
          draft.details = `Custom view created on ${new Date().toLocaleDateString()}`;
          return draft;
        })
      );
    } else if (isCustomizing === 'fromScratch') {
      setShowSwitchField(false);
      setCurrentInventoryConfig({
        ...defaultCreatingInventoryConfig,
        id: newId,
      });
    } else {
      setShowSwitchField(true);
      setCurrentInventoryConfig(lastTimeSelectedInventoryConfig);
    }
  }, [isCustomizing]);

  useEffect(() => {
    // Find selected inventory config by order: currentInventoryConfig > first favourite inventory config> first inventory config or preset
    let selectedInventoryConfig = inventoryConfigs.find(
      (config) => config.id === currentInventoryConfig.id
    )
      ? currentInventoryConfig
      : inventoryConfigs.find((config) => config.isFavourite) ?? inventoryConfigs[0];

    setCurrentInventoryConfig(selectedInventoryConfig);
    setLastTimeSelectedInventoryConfig(selectedInventoryConfig);
  }, [inventoryConfigData, isLoading]);

  // filter inactive columns from 'sortColumns' if 'columnFlags' changed
  useEffect(() => {
    updateSortColumns(
      produce(currentInventoryConfig.sortColumns, (draft) => {
        draft = draft.filter((col) => !!inventoryTableColumnDefs[col.id]?.flag);
        return draft;
      })
    );
  }, []);

  useEffect(() => {
    // If no VINs are selected, close the add budget modal and add budget sidebar
    // If no VINs are selected and the "selected" filter is active, deactivate it
    if (!visibleSelected.length) {
      if (filtersProviderProps.getFilterState('selected')) {
        filtersProviderProps.clearFilter('selected');
      }
      setShowAddBudgetSidebar(false);
      setShowBudgetModal(false);
    }
  }, [filtersProviderProps, visibleSelected]);

  if (inventoryConfigError || isInventoryError) {
    return <ErrorFallback />;
  }

  const toggleCTA = (id: Key) => {
    toggleCTAFilter(id);
    if (activeCTA !== id) {
      // If a new CTA filter is being applied, clear other filters
      filtersProviderProps.clearAll();
    }
  };

  const toggleSelected = (vin: string, hasExistingAddBudget: boolean) => {
    setSelected(
      produce(selected, (draft) => {
        if (draft.indexOf(vin) === -1) {
          return [...draft, vin];
        } else {
          return draft.slice().filter((i) => i !== vin);
        }
      })
    );
    if (hasExistingAddBudget) {
      setBudgetAppliedVins(
        produce(budgetAppliedVins, (draft) => {
          if (draft.indexOf(vin) === -1) {
            draft = [...draft, vin];
          } else {
            draft = draft.slice().filter((i) => i !== vin);
          }
          return draft;
        })
      );
    }
  };

  const toggleAllSelected = () => {
    if (visibleSelected.length === filteredData?.length) {
      setSelected([]);
      setBudgetAppliedVins([]);
    } else {
      setSelected(filteredData?.map((i) => i.listing.vin) ?? []);
      setBudgetAppliedVins(
        filteredData
          ?.filter((i) => i.budgetMetrics.hasExistingAddBudget)
          ?.map((i) => i.listing.vin) ?? []
      );
    }
  };

  const onCloseCustomizeInventoryConfig = () => {
    setIsCustomizing('');
    if (showInventorySidebar === 'arrange' || showInventorySidebar === 'columns') {
      setShowInventorySidebar('');
    }
  };

  const onFavoriteInventoryConfig = (e: React.MouseEvent, id: number, name: string) => {
    e.stopPropagation();
    if (inventoryConfigData && !mutation.isLoading) {
      const newInventoryConfigData = cloneDeep(inventoryConfigData);

      if (inventoryPageKey === 'live') {
        const index = newInventoryConfigData.liveInventoryConfigs.findIndex(
          (inventoryConfig) => inventoryConfig.id === id
        );
        newInventoryConfigData.liveInventoryConfigs[index].isFavourite =
          !inventoryConfigData.liveInventoryConfigs[index].isFavourite;
      } else {
        const index = newInventoryConfigData.soldInventoryConfigs.findIndex(
          (inventoryConfig) => inventoryConfig.id === id
        );
        newInventoryConfigData.soldInventoryConfigs[index].isFavourite =
          !inventoryConfigData.soldInventoryConfigs[index].isFavourite;
      }

      mutation.mutate(newInventoryConfigData, {
        onError: () => {
          addToast({
            status: 'error',
            description: `Failed to star ${name} – please try again`,
          });
        },
      });
    }
  };

  const onUpdateInventoryTableConfig = () => {
    if (currentInventoryConfig.name.length === 0) {
      setShowTableViewNameError(true);
    } else if (inventoryConfigData) {
      const newInventoryConfigData = cloneDeep(inventoryConfigData);

      if (isCustomizing === 'fromScratch' || isCustomizing === 'creating') {
        if (inventoryPageKey === 'live') {
          newInventoryConfigData.liveInventoryConfigs = [
            ...newInventoryConfigData.liveInventoryConfigs,
            currentInventoryConfig,
          ];
        } else {
          newInventoryConfigData.soldInventoryConfigs = [
            ...newInventoryConfigData.soldInventoryConfigs,
            currentInventoryConfig,
          ];
        }
      } else {
        if (inventoryPageKey === 'live') {
          const index = newInventoryConfigData.liveInventoryConfigs.findIndex(
            (inventoryConfig) => inventoryConfig.id === currentInventoryConfig.id
          );
          newInventoryConfigData.liveInventoryConfigs[index] = currentInventoryConfig;
        } else {
          const index = newInventoryConfigData.soldInventoryConfigs.findIndex(
            (inventoryConfig) => inventoryConfig.id === currentInventoryConfig.id
          );
          newInventoryConfigData.soldInventoryConfigs[index] = currentInventoryConfig;
        }
      }

      mutation.mutate(newInventoryConfigData, {
        onSuccess: () => {
          setLastTimeSelectedInventoryConfig(currentInventoryConfig);
          addToast({
            status: 'success',
            description: `${currentInventoryConfig.name} ${
              isCustomizing === 'fromScratch' || isCustomizing === 'creating'
                ? 'created'
                : 'updated'
            }`,
          });
          onCloseCustomizeInventoryConfig();
        },
        onError: () => {
          addToast({
            status: 'error',
            description: 'Failed to apply changes – please try again',
          });
        },
      });
    }
  };

  const onDeleteTableView = () => {
    if (inventoryConfigData) {
      const newInventoryConfigData = cloneDeep(inventoryConfigData);

      if (inventoryPageKey === 'live') {
        newInventoryConfigData.liveInventoryConfigs =
          newInventoryConfigData.liveInventoryConfigs.filter(
            (inventoryConfig) => inventoryConfig.id !== currentInventoryConfig.id
          );
      } else {
        newInventoryConfigData.soldInventoryConfigs =
          newInventoryConfigData.soldInventoryConfigs.filter(
            (inventoryConfig) => inventoryConfig.id !== currentInventoryConfig.id
          );
      }

      mutation.mutate(newInventoryConfigData, {
        onSuccess: () => {
          onCloseCustomizeInventoryConfig();
          addToast({
            status: 'success',
            description: `Deleted ${currentInventoryConfig.name}`,
          });
        },
        onError: () =>
          addToast({
            status: 'error',
            description: `Failed to delete ${currentInventoryConfig.name} table view, please try again later.`,
          }),
      });
    }
  };

  const handleSortAsc = (column: InventoryTableColumnKey) => {
    if (inventoryTableColumnDefs[column].getter) {
      updateSortColumns(
        produce(currentInventoryConfig.sortColumns, (draft) => {
          if (
            draft.findIndex(
              (sortColumn) => sortColumn.id === column && sortColumn.direction === 'asc'
            ) > -1
          ) {
            draft = draft.filter((sortColumn) => sortColumn.id !== column);
          } else {
            draft = draft.filter((sortColumn) => sortColumn.id !== column);
            draft.unshift({ id: column as InventoryTableWithValueColumnKey, direction: 'asc' });
          }

          return draft;
        })
      );
    }
  };

  const handleSortDesc = (column: InventoryTableColumnKey) => {
    if (inventoryTableColumnDefs[column].getter) {
      updateSortColumns(
        produce(currentInventoryConfig.sortColumns, (draft) => {
          if (
            draft.findIndex(
              (sortColumn) => sortColumn.id === column && sortColumn.direction === 'desc'
            ) > -1
          ) {
            draft = draft.filter((sortColumn) => sortColumn.id !== column);
          } else {
            draft = draft.filter((sortColumn) => sortColumn.id !== column);
            draft.unshift({ id: column as InventoryTableWithValueColumnKey, direction: 'desc' });
          }

          return draft;
        })
      );
    }
  };

  const toggleColumnSort = (col: SortColumn) => {
    const index = currentInventoryConfig.sortColumns.findIndex(
      (sortColumn) => sortColumn.id === col.id
    );
    if (index > -1) {
      updateSortColumns(
        produce(currentInventoryConfig.sortColumns, (draft) => {
          if (col.direction === 'asc') {
            draft[index].direction = 'desc';
          } else {
            draft[index].direction = 'asc';
          }
          return draft;
        })
      );
    }
  };

  return (
    <FiltersProvider
      filterConfigs={filterConfigs}
      itemCountLabel='VINs'
      showItemCount={true}
      isLoading={isLoading}
      data={ctaData}
      {...filtersProviderProps}
    >
      <HighlightsToggle
        data={inventoryData}
        inventoryPageKey={inventoryPageKey}
        toggleCTA={toggleCTA}
        activeCTA={activeCTA}
        isLoading={isLoading}
      />
      {inventoryPageKey === 'sold' && (
        <SoldInventorySummary
          inventoryData={filteredData}
          showSoldInventorySummary={showSoldInventorySummary}
          isInventoryLoading={isLoading}
        />
      )}
      <InventoryHeader
        inventoryPageKey={inventoryPageKey}
        inventoryConfigs={inventoryConfigs}
        isInventoryConfigChanged={isInventoryConfigChanged}
        buttonSize={buttonSize}
        isCustomizing={isCustomizing}
        setIsCustomizing={setIsCustomizing}
        showTableViewNameError={showTableViewNameError}
        setShowTableViewNameError={setShowTableViewNameError}
        filteredData={filteredData}
        visibleSelected={visibleSelected}
        budgetAppliedVins={budgetAppliedVins}
        inventoryTableColumnDefs={inventoryTableColumnDefs}
        onFavoriteInventoryConfig={onFavoriteInventoryConfig}
        onDeleteTableView={onDeleteTableView}
        creatingFromScratch={creatingFromScratch}
        setCreatingFromScratch={setCreatingFromScratch}
        currentInventoryConfig={currentInventoryConfig}
        setCurrentInventoryConfig={setCurrentInventoryConfig}
        lastTimeSelectedInventoryConfig={lastTimeSelectedInventoryConfig}
        setLastTimeSelectedInventoryConfig={setLastTimeSelectedInventoryConfig}
        setShowAddBudgetSidebar={setShowAddBudgetSidebar}
        showInventorySidebar={showInventorySidebar}
        setShowInventorySidebar={setShowInventorySidebar}
        setShowCompactInventoryView={setShowCompactInventoryView}
        showCompactInventoryView={showCompactInventoryView}
        onCloseCustomizeInventoryConfig={onCloseCustomizeInventoryConfig}
        onUpdateInventoryTableConfig={onUpdateInventoryTableConfig}
      />
      <div className={styles.inventoryPage}>
        <div
          className={classnames([
            styles.inventoryContent,
            showCompactInventoryView && styles.compact,
            vin && styles.withVINDetailSidebar,
          ])}
        >
          <InventoryTable
            data={filteredData}
            sortedTableData={sortedTableData}
            isLoading={isLoading}
            inventoryPageKey={inventoryPageKey}
            buttonSize={buttonSize}
            isCustomizing={isCustomizing}
            setIsCustomizing={setIsCustomizing}
            isInventoryConfigChanged={isInventoryConfigChanged}
            inventoryTableAddBudgetSidebarColumnDefs={inventoryTableAddBudgetSidebarColumnDefs}
            inventoryTableColumnDefs={inventoryTableColumnDefs}
            handleSortAsc={handleSortAsc}
            handleSortDesc={handleSortDesc}
            currentInventoryConfig={currentInventoryConfig}
            setCurrentInventoryConfig={setCurrentInventoryConfig}
            selected={visibleSelected}
            budgetAppliedVins={budgetAppliedVins}
            showInventorySidebar={showInventorySidebar}
            setShowInventorySidebar={setShowInventorySidebar}
            showAddBudgetSidebar={showAddBudgetSidebar}
            showBudgetModal={showBudgetModal}
            onUpdateInventoryTableConfig={onUpdateInventoryTableConfig}
            toggleSelected={toggleSelected}
            toggleAllSelected={toggleAllSelected}
            onOpenDetailsSidebar={(i) => navigate(`${i.listing.vin}`)}
            showCompactInventoryView={showCompactInventoryView}
          />
          <InventoryFilters
            inventoryPageKey={inventoryPageKey}
            handleClose={() => setShowInventorySidebar('')}
            isShow={showInventorySidebar === 'filter'}
            title='Filter Inventory'
          />
          <SortInventory
            isShow={showInventorySidebar === 'sort'}
            isLoading={isLoading}
            inventoryTableColumnWithGetterDefs={inventoryTableColumnWithGetterDefs}
            inventoryTableColumnDefs={inventoryTableColumnDefs}
            inventoryTableConfig={currentInventoryConfig.inventoryTableConfig}
            sortColumns={currentInventoryConfig.sortColumns}
            updateSortColumns={updateSortColumns}
            toggleColumnSort={toggleColumnSort}
            handleClose={() => setShowInventorySidebar('')}
          />
          <CustomizeSidebar
            isLoading={isLoading}
            showInventorySidebar={showInventorySidebar}
            setShowInventorySidebar={setShowInventorySidebar}
            inventoryTableColumnDefs={inventoryTableColumnDefs}
            currentInventoryConfig={currentInventoryConfig}
            setCurrentInventoryConfig={setCurrentInventoryConfig}
            inventoryPageKey={inventoryPageKey}
          />
          {visibleSelected.length - budgetAppliedVins.length > 0 && (
            <AddBudgetSidebar
              vins={inventoryData?.filter(
                (i) =>
                  visibleSelected.includes(i.listing.vin) &&
                  !budgetAppliedVins.includes(i.listing.vin)
              )}
              inventoryTableAddBudgetSidebarColumnDefs={inventoryTableAddBudgetSidebarColumnDefs}
              budgetAppliedVins={budgetAppliedVins}
              setBudgetAppliedVins={setBudgetAppliedVins}
              showAddBudgetSidebar={showAddBudgetSidebar}
              setShowAddBudgetSidebar={setShowAddBudgetSidebar}
              showBudgetModal={showBudgetModal}
              setShowBudgetModal={setShowBudgetModal}
            />
          )}
        </div>
        {vin && (
          <VinDetailSidebar
            vin={vin}
            inventoryData={sortedTableData ?? []}
            isLoading={isInventoryLoading}
            className={styles.inventoryVINDetail}
          >
            {inventoryPageKey === 'live' ? (
              <>
                <SummarySection />
                <InsightsSection />
                <VDPSection />
                <LeadsSection />
                <VinPathPriceSection />
                <MarketSection />
                <ShopperDemandSection />
              </>
            ) : (
              <>
                <SummarySection />
                <VDPSection />
              </>
            )}
          </VinDetailSidebar>
        )}
      </div>
      <Modal isOpen={blocker.state === 'blocked'} aria-label='Unsaved table view modal'>
        <div className={styles.unsavedTableViewModal}>
          <Text size='20' fontWeight={7} color='navy'>
            How would you like to create your new table view?
          </Text>
          <Text size='12' fontWeight={4} color='tertiary' alignment='center'>
            You've modified this view by changing either the filters, sorts or columns. If you leave
            this page now these changes will be lost
          </Text>
          <div className={styles.buttonContainer}>
            <CheckboxField
              checked={!showUnsavedAlert}
              onClick={() => setShowUnsavedAlert(!showUnsavedAlert)}
            >
              Don't show me this again
            </CheckboxField>
            <Button variant='outline' onClick={onStay}>
              Stay
            </Button>
            <Button variant='solid' onClick={onLeave}>
              Leave and discard changes
            </Button>
          </div>
          <IconButton
            aria-label='close creating modal'
            onClick={onStay}
            size='md'
            className={styles.closeModalButton}
          >
            <IconX size='16' color={COLOR.SPARKY_GREY_400} />
          </IconButton>
        </div>
      </Modal>
    </FiltersProvider>
  );
}

// function getInventoryTableConfig(
//   inventoryTableConfig: InventoryTableConfig,
//   inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>
// ): InventoryTableConfig {
//   let filteredInventoryTableConfig = inventoryTableConfig;
//   filteredInventoryTableConfig.mainColumns = filteredInventoryTableConfig.mainColumns.filter(
//     (col) => inventoryTableColumnDefs[col].flag
//   );
//   filteredInventoryTableConfig.frozenColumns = filteredInventoryTableConfig.frozenColumns.filter(
//     (col) => inventoryTableColumnDefs[col].flag
//   );
//   return filteredInventoryTableConfig;
// }
