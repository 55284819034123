import { useState } from 'react';

import classnames from 'classnames';
import Button from 'Sparky/Button';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { DealerData } from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';
import { CircleIcon } from 'pages/Settings/LocalMarketSettings/icons/CircleIcon';
import { StarIcon } from 'pages/Settings/LocalMarketSettings/icons/StarIcon';

import styles from './DealerListItem.module.scss';

export interface DealerListItemProps {
  isKeyDealer: boolean;
  dealer: DealerData;
  setHighlightDealer: (cell: number | undefined) => void;
}

/**
 * DealerListItem
 */
export default function DealerListItem({
  isKeyDealer,
  dealer,
  setHighlightDealer,
}: DealerListItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const isFranchise = dealer.dealerType === 'franchise';

  const handleHightlight = (dealerId: number) => {
    setHighlightDealer(dealerId);
  };

  return (
    <div
      key={dealer.dealerId}
      className={classnames([styles.dealerListItem, isHovered && styles.hovered])}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isKeyDealer ? (
        <StarIcon color={isFranchise ? COLOR.SPARKY_GREEN_400 : COLOR.SPARKY_PURPLE_400} />
      ) : (
        <CircleIcon color={isFranchise ? COLOR.SPARKY_GREEN_300 : COLOR.SPARKY_PURPLE_300} />
      )}
      <Text
        color='secondary'
        size='14'
        fontWeight={isHovered ? 5 : 4}
        className={classnames([styles.dealerName, isHovered && styles.hovered])}
      >
        {isHovered ? dealer.dealerName : limitCharacters(dealer.dealerName)}
      </Text>
      {isHovered && (
        <Tooltip side='right' content='Click to highlight dealer on the map'>
          <Button
            variant='text'
            size='sm'
            className={styles.highlightButton}
            onClick={() => handleHightlight(dealer.dealerId)}
          >
            Highlight
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

function limitCharacters(val: string) {
  if (val.length < 27) {
    return val;
  }
  return `${val.substring(0, 27)}...`;
}
