export function NeutralIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 12.5C4 11.9477 4.44772 11.5 5 11.5H7C7.55228 11.5 8 11.9477 8 12.5C8 13.0523 7.55228 13.5 7 13.5H5C4.44772 13.5 4 13.0523 4 12.5ZM10 12.5C10 11.9477 10.4477 11.5 11 11.5H13C13.5523 11.5 14 11.9477 14 12.5C14 13.0523 13.5523 13.5 13 13.5H11C10.4477 13.5 10 13.0523 10 12.5ZM16 12.5C16 11.9477 16.4477 11.5 17 11.5H19C19.5523 11.5 20 11.9477 20 12.5C20 13.0523 19.5523 13.5 19 13.5H17C16.4477 13.5 16 13.0523 16 12.5Z'
        fill='#5F5F5F'
      />
    </svg>
  );
}
