import { useEffect, useState } from 'react';

import classnames from 'classnames';
import dayjs from 'dayjs';
import { atom, useAtom } from 'jotai';
import Chip from 'Sparky/Chip';
import LineChart from 'Sparky/LineChart';
import Panel from 'Sparky/Panel';
import RadioField from 'Sparky/RadioField';
import Skeleton from 'Sparky/Skeleton';
import { Tab, TabContent, Tabs, TabsList } from 'Sparky/Tabs';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';
import { selectedOptionAtom, selectedSegmentAtom } from 'store/selectSegmentAtom';

import Page from 'components/Page/Page';
import PercentChangeIndicator from 'components/PercentChangeIndicator';
import SegmentBreadcrumb from 'components/Segments/SegmentBreadcrumb/SegmentBreadcrumb';
import { sidebarAtom } from 'components/Sidebar/Sidebar';
import TooltipIcon from 'components/TooltipIcon';
import useMarketOpportunity, {
  ChartDataPoint,
  marketOpportunityDefs,
} from 'pages/Analytics/MarketOpportunity/api/useMarketOpportunity/useMarketOpportunity';
import BarChart from 'pages/Analytics/MarketOpportunity/BarChart';
import DoughnutChart from 'pages/Analytics/MarketOpportunity/DoughnutChart';
import HexMap from 'pages/Analytics/MarketOpportunity/HexMap';
import VerticalBarChart from 'pages/Analytics/MarketOpportunity/VerticalBarChart';
import useLocalMarketSettings from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';
import { FeatureFlag } from 'types/FeatureFlags';

import styles from './MarketOpportunity.module.scss';

interface metricDef {
  key: string;
  label: string;
  metrics: string[];
}

export const tabs: Record<string, metricDef> = {
  demand: { key: 'demand', label: 'Demand', metrics: ['inMarketShoppers'] },
  sales: { key: 'sales', label: 'Sales', metrics: ['salesVolume'] },
  stock: {
    key: 'stock',
    label: 'Stock',
    metrics: ['listedUnits', 'marketDaysSupply'],
  },
};

export const defaultMetricAtom = atom<string | undefined>(undefined);

/**
 * MarketOpportunity
 */
export default function MarketOpportunity() {
  const [expanded] = useAtom(sidebarAtom);
  const [selectedSegment] = useAtom(selectedSegmentAtom);
  const [selectedOption] = useAtom(selectedOptionAtom);
  const [isMapFullscreen, setIsMapFullscreen] = useState<boolean>(false);
  const [activeMapMetric, setActiveMapMetric] = useState<string>('');
  const [defaultMetric, setDefaultMetric] = useAtom(defaultMetricAtom);
  const [tab, setTab] = useState<string>('demand');

  // onLoad set the default by checking the defaultMetricAtom
  useEffect(() => {
    if (!defaultMetric) {
      return;
    }
    if (defaultMetric === 'listedUnits' || defaultMetric === 'marketDaysSupply') {
      setTab('stock');
      setActiveMapMetric(defaultMetric);
      return;
    }
    setDefaultMetric(undefined);
    setTab(defaultMetric);
  }, [defaultMetric]);

  useEffect(() => {
    if (defaultMetric === 'marketDaysSupply') {
      setActiveMapMetric('marketDaysSupply');
      setDefaultMetric(undefined);
    } else {
      setActiveMapMetric(tabs[tab]?.metrics[0]);
    }
    setDefaultMetric(undefined);
  }, [tab, isMapFullscreen]);

  const { data: localMarketData, isLoading: isLocalMarketDataLoading } = useLocalMarketSettings();

  const { data: marketOpportunityData, isLoading: isMarketOpportunityDataLoading } =
    useMarketOpportunity({ selectedOption, selectedSegment });

  if (!marketOpportunityData && !isMarketOpportunityDataLoading) {
    return <></>;
  }

  let segmentText = '';
  selectedSegment.split('|').forEach((text, index) => {
    segmentText += `${index > 0 ? ', ' : ''}${text}`;
  });

  const Map = () => {
    // Safeguard for data loaded but data is empty object
    const noKeys = !isMarketOpportunityDataLoading && !Object.keys(marketOpportunityData).length;
    if (noKeys) {
      return <></>;
    }

    return (
      <HexMap
        marketOpportunityData={marketOpportunityData}
        localMarketData={localMarketData}
        setIsMapFullscreen={setIsMapFullscreen}
        isMapFullscreen={isMapFullscreen}
        activeMapMetric={activeMapMetric}
        setActiveMapMetric={setActiveMapMetric}
        isMarketOpportunityDataLoading={isMarketOpportunityDataLoading}
        isLocalMarketDataLoading={isLocalMarketDataLoading}
      />
    );
  };

  // DATE RANGE SELECTOR -> WILL BE BROUGHT BACK POST MVP
  // const DateRangeSelector = () => {
  //   return (
  //     <div className={styles.dateRangeSelector}>
  //       <ButtonGroup
  //         defaultValue={startDate}
  //         items={[
  //           {
  //             label: '1M',
  //             value: '1 MONTH',
  //           },
  //           {
  //             label: '3M',
  //             value: '3 MONTHS',
  //           },
  //           {
  //             label: '6M',
  //             value: '6 MONTHS',
  //           },
  //         ]}
  //         onValueChange={(e: unknown) => setStartDate(e as string)}
  //         value={startDate}
  //         toggleable
  //       />
  //     </div>
  //   );
  // };

  const TitleRow = () => {
    return (
      <div className={styles.titleRow}>
        <Text fontWeight={6} color='tertiary'>
          THE MARKET - LAST 30 DAYS
        </Text>
      </div>
    );
  };

  const BottomRowTitle = () => {
    return (
      <div className={styles.bottomRowTitle}>
        <Text color='tertiary' fontWeight={6}>
          YOUR DEALERSHIP - LAST 30 DAYS
        </Text>
      </div>
    );
  };

  const ColTitle = ({
    title,
    noMargin,
    jsonKey,
  }: {
    title: string;
    noMargin?: boolean;
    jsonKey?: string;
  }) => {
    return (
      <div className={classnames([styles.colTitle, noMargin && styles.noMargin])}>
        <Text color='secondary' fontWeight={5}>
          {title}
          {jsonKey && <TooltipIcon textKey={jsonKey} />}
        </Text>
      </div>
    );
  };

  const LineChartElement = ({
    data,
    defKey,
    cropData,
  }: {
    data: ChartDataPoint[] | undefined;
    defKey: string;
    cropData?: boolean;
  }) => {
    if (isMarketOpportunityDataLoading) {
      return <Skeleton height='100%' width='100%' />;
    }

    if (cropData && data) {
      data = cropZeroData(data);
    }

    let label = marketOpportunityDefs[defKey].label;
    if (defKey === 'riskInventoryTrend') {
      label = `Your at risk ${segmentText}`;
    }

    return data ? (
      <div className={styles.lineChartContainer}>
        <LineChart
          format={marketOpportunityDefs[defKey].format}
          data={data}
          percentDecimals={defKey === 'marketShareTrend' ? 2 : 0}
          metaData={{
            value: {
              label: label,
              color: '#0D67F8',
            },
          }}
        />
      </div>
    ) : (
      <></>
    );
  };

  const handleMetricToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveMapMetric(e.target.value);
  };

  if (isMapFullscreen) {
    return (
      <div className={styles.fullScreenMap}>
        <Map />
      </div>
    );
  }

  return (
    <div className={styles.marketopportunity}>
      <div className={classnames([styles.pageContainer])}>
        <SegmentBreadcrumb pageTitle={'Market Opportunity'} />
        <div className={styles.panelContainer}>
          <Panel>
            <Tabs value={tab} onValueChange={setTab} className={styles.tabs}>
              <TabsList style={{ width: '100%' }} className={styles.tabList}>
                {Object.values(tabs).map((tab) => {
                  return (
                    <Tab value={tab.key} key={tab.key}>
                      {tab.label}
                    </Tab>
                  );
                })}
              </TabsList>

              {/* DEMAND TAB */}
              <TabContent key={'demand'} value={'demand'} className={styles.content}>
                <TitleRow />

                <div className={classnames([styles.topRow, expanded && styles.sidebarOpen])}>
                  <div className={styles.col}>
                    <ColTitle
                      title={'In market shoppers'}
                      jsonKey='Market Opportunity-Demand-In-market shoppers'
                    />
                    <LineChartElement
                      data={marketOpportunityData?.metricData?.inMarketShoppersTrend?.data}
                      defKey='inMarketShoppersTrend'
                      cropData
                    />
                  </div>
                  <div className={styles.mapContainer}>
                    <Map />
                  </div>
                </div>

                <BottomRowTitle />
                <div className={classnames([styles.bottomRow, expanded && styles.sidebarOpen])}>
                  <div className={styles.col}>
                    <ColTitle
                      title={'% of VINs viewed'}
                      noMargin
                      jsonKey='Market Opportunity-Demand-% of VINs viewed'
                    />
                    <Text color='tertiary' size='12'>
                      On {dayjs().format('MMMM DD, YYYY')}
                    </Text>
                    <DoughnutChart
                      percent={marketOpportunityData?.marketOppVinsViewed?.percent}
                      segment={segmentText}
                      isLoading={isMarketOpportunityDataLoading}
                    />
                  </div>

                  <div className={styles.col}>
                    <ColTitle
                      title={'% of VINs viewed'}
                      jsonKey='Market Opportunity-Demand-Trend of % VINs viewed'
                    />
                    <LineChartElement
                      data={marketOpportunityData?.metricData?.pctVinsViewedTrend?.data}
                      defKey='pctVinsViewedTrend'
                      cropData
                    />
                  </div>

                  <div className={styles.col}>
                    <ColTitle
                      title={'% net new shoppers'}
                      jsonKey='Market Opportunity-Demand-Demand Pace'
                    />
                    <LineChartElement
                      data={marketOpportunityData?.metricData?.netNewShoppersTrend?.data}
                      defKey='netNewShoppersTrend'
                      cropData
                    />
                  </div>
                </div>
              </TabContent>

              {/* SALES TAB */}
              <TabContent key={'sales'} value={'sales'} className={styles.content}>
                <TitleRow />
                <div className={classnames([styles.topRow, expanded && styles.sidebarOpen])}>
                  <div className={styles.col}>
                    <ColTitle
                      title={'Sales volume'}
                      jsonKey='Market Opportunity-Sales-Sales Volume'
                    />
                    <LineChartElement
                      data={marketOpportunityData?.metricData?.marketSalesVolumeTrend?.data}
                      defKey='marketSalesVolumeTrend'
                      cropData
                    />
                  </div>
                  <div className={styles.mapContainer}>
                    <Map />
                  </div>
                </div>

                <BottomRowTitle />
                <div className={classnames([styles.bottomRow, expanded && styles.sidebarOpen])}>
                  <div className={styles.col}>
                    <ColTitle
                      title={'Sales volume - your dealership'}
                      jsonKey='Market Opportunity-Sales-Sales volume (last month)'
                    />
                    <LineChartElement
                      data={marketOpportunityData?.metricData?.dealerSalesVolumeTrend?.data}
                      defKey='dealerSalesVolumeTrend'
                      cropData
                    />
                  </div>
                  <div className={styles.col}>
                    <div className={classnames([styles.colTitle, styles.noMargin])}>
                      <Text color='secondary'>
                        Average days to sale
                        <TooltipIcon textKey='Market Opportunity-Sales-Average Days to Sale' />
                      </Text>
                      {marketOpportunityData?.marketOppAvgDaysToSale?.percent !== undefined && (
                        <Tooltip
                          side='bottom'
                          content='Your percent variance to the market'
                          textAlign='center'
                        >
                          <div>
                            <Chip
                              color={
                                marketOpportunityData?.marketOppAvgDaysToSale?.percent <= 0
                                  ? 'green'
                                  : 'red'
                              }
                              variant='filled'
                            >
                              <PercentChangeIndicator
                                val={marketOpportunityData?.marketOppAvgDaysToSale?.percent}
                                lowerIsBetter={true}
                                decimals={0}
                              />
                            </Chip>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <Text color='tertiary' size='12'>
                      On {dayjs().format('MMMM DD, YYYY')}
                    </Text>
                    <div className={styles.barChartContainer}>
                      <VerticalBarChart
                        dealerValue={marketOpportunityData?.marketOppAvgDaysToSale?.dealer}
                        marketValue={marketOpportunityData?.marketOppAvgDaysToSale?.market}
                        metaData={{
                          dealer: {
                            label: 'Your dealership',
                            color: '#0D67F8',
                          },
                          market: {
                            label: 'The market',
                            color: '#A90DF8',
                          },
                        }}
                        isLoading={isMarketOpportunityDataLoading}
                      />
                    </div>
                  </div>
                  <div className={styles.col}>
                    <ColTitle
                      title={'Market share'}
                      jsonKey='Market Opportunity-Sales-Market Share'
                    />
                    <LineChartElement
                      data={marketOpportunityData?.metricData?.marketShareTrend?.data}
                      defKey='marketShareTrend'
                      cropData
                    />
                  </div>
                </div>
              </TabContent>

              {/* STOCK TAB */}
              <TabContent key={'stock'} value={'stock'} className={styles.content}>
                <TitleRow />
                <div className={classnames([styles.topRow, expanded && styles.sidebarOpen])}>
                  <div className={styles.col}>
                    <div className={styles.radioButtons}>
                      <RadioField
                        key={'listedUnits'}
                        smallHover={true}
                        value={'listedUnits'}
                        checked={activeMapMetric === 'listedUnits'}
                        onChange={(e) => handleMetricToggle(e)}
                      >
                        Listed units
                        <TooltipIcon textKey='Market Opportunity-Stock-Listed Units' />
                      </RadioField>
                      <RadioField
                        key={'marketDaysSupply'}
                        smallHover={true}
                        value={'marketDaysSupply'}
                        checked={activeMapMetric === 'marketDaysSupply'}
                        onChange={(e) => handleMetricToggle(e)}
                      >
                        Market days supply
                        <TooltipIcon textKey='Market Opportunity-Stock-Market Days Supply' />
                      </RadioField>
                    </div>
                    <div className={styles.lineChartContainer}>
                      {activeMapMetric === 'listedUnits' && (
                        <LineChartElement
                          data={marketOpportunityData?.metricData?.marketListedTrend?.data}
                          defKey='marketListedTrend'
                          cropData
                        />
                      )}
                      {activeMapMetric === 'marketDaysSupply' && (
                        <LineChartElement
                          data={marketOpportunityData?.metricData?.marketDaysSupplyTrend?.data}
                          defKey='marketDaysSupplyTrend'
                          cropData
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.mapContainer}>
                    <Map />
                  </div>
                </div>

                <BottomRowTitle />
                <div className={classnames([styles.bottomRow, expanded && styles.sidebarOpen])}>
                  <div className={styles.col}>
                    <ColTitle
                      title={`Your ${segmentText} inventory`}
                      noMargin
                      jsonKey='Market Opportunity-Stock-Segment unit total'
                    />
                    <div className={styles.barChartContainer}>
                      <BarChart
                        format={marketOpportunityDefs.dealerListedUnitsTrend.format}
                        data={marketOpportunityData?.metricData?.dealerListedUnitsTrend?.data}
                        metaData={{
                          value: {
                            label: marketOpportunityDefs.dealerListedUnitsTrend.label,
                            color: '#0D67F8',
                          },
                        }}
                        isLoading={isMarketOpportunityDataLoading}
                      />
                    </div>
                  </div>
                  <div className={styles.col}>
                    <ColTitle
                      title={'Media risk inventory'}
                      jsonKey='Market Opportunity-Stock-Media Risk Inventory'
                    />
                    <LineChartElement
                      data={marketOpportunityData?.metricData?.riskInventoryTrend?.data}
                      defKey='riskInventoryTrend'
                      cropData
                    />
                  </div>
                  <div className={styles.col}>
                    <ColTitle
                      title={'Price to market'}
                      jsonKey='Market Opportunity-Stock-Price to Market'
                    />
                    <LineChartElement
                      data={marketOpportunityData?.metricData?.priceToMarketTrend?.data}
                      defKey='priceToMarketTrend'
                      cropData
                    />
                  </div>
                </div>
              </TabContent>
            </Tabs>
          </Panel>
        </div>
      </div>
    </div>
  );
}

export function Component() {
  return (
    <Page requireDealer title={'Market Opportunity'} flag={FeatureFlag.ENABLE_MARKET_OPPORTUNITY}>
      <MarketOpportunity />
    </Page>
  );
}

Component.displayName = 'MarketOpportunityPage';

export function cropZeroData(data?: ChartDataPoint[]) {
  if (!data) {
    return data;
  }
  return data.filter((item, index) => !(item.value === 0 && index > data.length - 5));
}
