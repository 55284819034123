import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import ENDPOINTS from 'api/utils/endpoints';
import { post } from 'api/utils/fetch';
import useQueryKeys from 'api/utils/useQueryKeys';
import { InventoryTableConfigResponse } from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

interface InventoryTableConfigRequest {
  token?: string;
  dealerId?: number;
  inventoryConfig: InventoryTableConfigResponse;
}

export type InventoryPageKey = 'sold' | 'live';

async function postInventoryTableConfig({ token, inventoryConfig }: InventoryTableConfigRequest) {
  assertIsDefined(token);
  assertIsDefined(inventoryConfig);

  /** If the id is 0, it means this inventory config is a new creation config
   * If the config only contains id and inventoryPageKey, it means delete the specific config
   * Otherwise, replace the specific config with passed config */

  return post(`${ENDPOINTS.inventoryTableConfig}`, {
    headers: { token },
    params: { preset: false },
    body: inventoryConfig,
  });
}

export default function useSaveInventoryTableConfig() {
  const queryClient = useQueryClient();
  const { invTableConfigKey: queryKey } = useQueryKeys();
  const [user] = useAtom(userAtom);

  return useMutation(
    (inventoryConfig: InventoryTableConfigResponse) =>
      postInventoryTableConfig({
        token: user?.token,
        inventoryConfig,
      }),
    {
      onSuccess: (res, inventoryConfig) => {
        queryClient.setQueryData<InventoryTableConfigResponse>(queryKey, () => {
          return inventoryConfig;
        });
      },
    }
  );
}
