import { useEffect, useRef, useState } from 'react';

import { IconArrowRight } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import Popover from 'Sparky/Popover';
import Text from 'Sparky/Text';
import TextInput from 'Sparky/TextInput';
import { v4 as uuid } from 'uuid';

import { isRepsonseAnimationReadyAtom } from 'pages/LexChat/AnimatedResponse';
import { LEX_RECOMMENDATION_DEFS, LexRecommendation } from 'pages/LexChat/Recommendations.def';

import styles from './ChatInput.module.scss';

export interface ChatInputProps {
  handlePromptSubmit: (text: string) => void;
  recommendationKey: string | undefined;
  setRecommendationKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  isLoading: boolean;
  hasHistory: boolean;
  suggestedPrompts: string[];
}

/**
 * ChatInput
 */
export default function ChatInput({
  handlePromptSubmit,
  recommendationKey,
  setRecommendationKey,
  isLoading,
  hasHistory,
  suggestedPrompts,
}: ChatInputProps) {
  const [, setIsResponseAnimationReady] = useAtom(isRepsonseAnimationReadyAtom);
  const [promptText, setPromptText] = useState<string>('');
  const [showPopover, setShowPopover] = useState(false);
  const textRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (recommendationKey) {
      setIsResponseAnimationReady(false);
      setShowPopover(true);
    }
  }, [recommendationKey]);

  useEffect(() => {
    if (!showPopover) {
      setRecommendationKey('');
    }
  }, [showPopover]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsResponseAnimationReady(false);
    setPromptText(e.target.value);
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isLoading) {
      return;
    }
    if (promptText && e.key === 'Enter') {
      handlePromptSubmit(promptText);
      setPromptText('');
    }
  };

  let recommendations: LexRecommendation[] = [];

  if (recommendationKey) {
    if (recommendationKey === 'suggested') {
      recommendations = suggestedPrompts.map((prompt) => {
        return {
          prompt: prompt,
          title: prompt,
          category: 'suggested',
        };
      });
    } else {
      recommendations = LEX_RECOMMENDATION_DEFS.filter((def) => def.category === recommendationKey);
    }
  }

  const handleRecommendationClick = (prompt: string) => {
    setShowPopover(false);
    handlePromptSubmit(prompt);
    textRef.current?.focus();
  };

  return (
    <Popover
      showCarrat={false}
      isHoverTriggered={false}
      isClickTriggered={false}
      showCloseButton={false}
      popupContent={
        <>
          {recommendations.map((rec) => {
            return (
              <div
                key={uuid()}
                className={classnames([styles.promptSuggestionRow])}
                onClick={() => handleRecommendationClick(rec.prompt)}
              >
                <Text>{rec.title}</Text>
                <IconArrowRight size='16' />
              </div>
            );
          })}
        </>
      }
      contentClassName={classnames([
        styles.popoverContent,
        hasHistory && styles.chatOpen,
        recommendations.length > 3 && hasHistory && styles.showScroll,
      ])}
      isOpen={showPopover}
      setIsOpen={setShowPopover}
    >
      <div className={styles.textContainer}>
        <TextInput
          ref={textRef}
          id='dealer-filter'
          className={styles.chatInput}
          placeholder='Chat with Lex'
          value={promptText}
          onChange={(e) => handleInputChange(e)}
          onKeyDown={(e) => handleSubmit(e)}
          autoComplete='off'
          onFocus={() => {
            setShowPopover(false);
            setRecommendationKey('');
          }}
          autoFocus
          disabled={isLoading}
        />
      </div>
    </Popover>
  );
}
