import { useState } from 'react';

import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
  IconCopy,
  IconCurrencyDollar,
  IconExternalLink,
  IconGauge,
  IconPhoto,
  IconX,
} from '@tabler/icons-react';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import AspectRatio from 'Sparky/AspectRatio';
import Button from 'Sparky/Button';
import Chip from 'Sparky/Chip';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';
import useToast from 'Sparky/Toast';
import Tooltip from 'Sparky/Tooltip';

import RiskScore from 'pages/Inventory/InventoryPage/components/RiskScore';
import { injectLexPromptAtom, showLexChatAtom } from 'pages/LexChat';
import { InventoryItem } from 'types/InventoryItem';
import { formatCurrency, formatNumber } from 'util/formatters';

import styles from './VINDetailHeader.module.scss';

export interface VINDetailHeaderProps {
  item: InventoryItem;
  totalVINsAmount: number;
  vinIndex: number;
  isScrollTop: boolean;

  onPrevClick(): void;

  onNextClick(): void;

  onClose(): void;
}

/**
 * VINDetailHeader
 */
export default function VINDetailHeader({
  item,
  totalVINsAmount,
  vinIndex,
  isScrollTop,
  onPrevClick,
  onNextClick,
  onClose,
}: VINDetailHeaderProps) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const { addToast } = useToast();
  const { modelYear, make, model, trim, bodySegment } = item.vehicle;
  const { primaryImage, imageCount, vin, stockNumber, mileage, dol, url } = item.listing;
  const [, setShowLexChat] = useAtom(showLexChatAtom);
  const [, setInjectedLexPrompt] = useAtom(injectLexPromptAtom);

  const vinTitle = `${modelYear} ${make} ${model}`;

  const triggerSmallHeader = !(isScrollTop || isHover);

  const vdpHeight = vinTitle.length > 24 ? '152px' : '124px';

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <Flex
      flex='none'
      width='100%'
      column
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classnames([
        styles.vinDetailHeader,
        triggerSmallHeader && styles.smallVinDetailHeader,
      ])}
    >
      <Flex flex='none' columnGap='4px' alignItems='center' width='100%' padding='12px 16px'>
        <Flex flex='none' alignItems='center'>
          <IconButton aria-label='prev' disabled={vinIndex === 0} onClick={onPrevClick}>
            <IconChevronLeft />
          </IconButton>
          <IconButton
            aria-label='next'
            disabled={vinIndex === totalVINsAmount - 1}
            onClick={onNextClick}
          >
            <IconChevronRight />
          </IconButton>
        </Flex>
        <Text textWrap='nowrap'>
          {vinIndex + 1} of {totalVINsAmount}
        </Text>
        {triggerSmallHeader && (
          <Flex columnGap='8px' margin='0 auto' alignItems='center'>
            <Text
              size='14'
              fontWeight={7}
              textWrap='nowrap'
              color='blue'
              className={styles.headerVinTitle}
            >
              {vinTitle}
            </Text>
            {bodySegment && (
              <Text size='14' fontWeight={4} color='blue' textWrap='nowrap'>
                {trim} {bodySegment}
              </Text>
            )}
            <RiskScore riskScore={item.scores?.riskScore} showScoreOnly />
          </Flex>
        )}
        <IconButton
          aria-label='close VIN detail sidebar'
          onClick={onClose}
          className={classnames([styles.closeBtn, triggerSmallHeader && styles.smallCloseBtn])}
        >
          <IconX />
        </IconButton>
      </Flex>
      <Flex
        columnGap='16px'
        padding={triggerSmallHeader ? '0' : '0 16px 12px'}
        flex='none'
        height={triggerSmallHeader ? '0' : vdpHeight}
        width='100%'
        className={classnames([styles.infoSummary, triggerSmallHeader && styles.hideInfoSummary])}
      >
        <AspectRatio ratio={4 / 3} className={styles.image}>
          <img
            className={classnames([process.env.REACT_APP_DEPLOY_ENV === 'demo' && styles.blur])}
            src={primaryImage}
            alt=''
          />
        </AspectRatio>
        <Flex flex='none' column rowGap='8px' width={`calc(100% - ${vdpHeight})`}>
          <Flex flex='none' column rowGap='4px' width='100%' className={styles.titleContainer}>
            <Flex
              flex='none'
              columnGap='8px'
              alignItems={vinTitle.length > 24 ? 'flex-start' : 'center'}
              width='100%'
            >
              <Text
                size='20'
                fontWeight={7}
                textWrap={vinTitle.length > 24 ? 'wrap' : 'nowrap'}
                as='div'
              >
                {vinTitle}
              </Text>

              <RiskScore riskScore={item.scores?.riskScore} showScoreOnly />

              {url && (
                <Button
                  onClick={() => window.open(url, '_blank', 'noreferrer')}
                  rightIcon={<IconExternalLink size={15} />}
                  tooltip='View the vehicle detail page'
                  tooltipSide='top'
                  variant='text'
                  size='sm'
                  className={styles.viewVdpBtn}
                >
                  View VDP
                </Button>
              )}
              <Button
                onClick={() => {
                  setInjectedLexPrompt(`Rate my vin: ${item.listing.vin}`);
                  setShowLexChat(true);
                }}
                rightIcon={<IconExternalLink size={15} />}
                tooltip='Ask Lex to rate this vehicle'
                tooltipSide='top'
                variant='text'
                size='sm'
                className={styles.viewVdpBtn}
              >
                Rate VDP
              </Button>
            </Flex>

            {bodySegment && (
              <Text size='14' fontWeight={4}>
                {trim} {bodySegment}
              </Text>
            )}
          </Flex>

          <Flex column rowGap='8px'>
            <Flex flex='none' columnGap='8px'>
              <Chip
                key={vin}
                shape='tag'
                variant='filled'
                color='grey'
                rightIcon={
                  <Tooltip content='Copy vehicle identification number' side='top'>
                    <IconCopy size={15} />
                  </Tooltip>
                }
                handleRightIconClick={() => {
                  navigator.clipboard
                    .writeText(vin)
                    .then(() => addToast({ status: 'success', description: 'VIN copied!' }));
                }}
              >
                VIN: {vin}
              </Chip>
              <Chip
                shape='tag'
                variant='filled'
                color='grey'
                rightIcon={
                  stockNumber ? (
                    <Tooltip content='Copy stock number' side='top'>
                      <IconCopy size={15} />
                    </Tooltip>
                  ) : undefined
                }
                handleRightIconClick={() => {
                  stockNumber &&
                    navigator.clipboard
                      .writeText(stockNumber)
                      .then(() => addToast({ status: 'success', description: 'Stock # copied!' }));
                }}
              >
                Stock #: {stockNumber}
              </Chip>
            </Flex>

            <Flex flex='none' columnGap='8px'>
              <Chip shape='tag' variant='filled' leftIcon={<IconCurrencyDollar size={15} />}>
                {formatCurrency(item.priceMetrics?.currentPrice)}
              </Chip>
              <Chip shape='tag' variant='filled' leftIcon={<IconCalendar size={15} />}>
                {dol} DOL
              </Chip>
              <Chip shape='tag' variant='filled' leftIcon={<IconGauge size={15} />}>
                {formatNumber(mileage)} mi
              </Chip>
              <Chip shape='tag' variant='filled' leftIcon={<IconPhoto size={15} />}>
                {imageCount} photo{`${imageCount !== 1 ? 's' : ''}`}
              </Chip>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
