import React from 'react';

export interface SpacerProps {
  size?: string | number;
  axis?: 'vertical' | 'horizontal';
  style?: React.CSSProperties;
}

/**
 * A simple utility component to add a space between two elements - inspired by [this article](https://www.joshwcomeau.com/react/modern-spacer-gif/)
 */

export default function Spacer({ size, axis, style }: SpacerProps) {
  const width = axis === 'vertical' ? 1 : size;
  const height = axis === 'horizontal' ? 1 : size;

  return (
    <span
      data-testid='spacer'
      style={{
        display: 'block',
        width,
        minWidth: width,
        height,
        minHeight: height,
        ...style,
      }}
    />
  );
}
