import { ReactNode } from 'react';

import * as Toggle from '@radix-ui/react-toggle';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import classnames from 'classnames';
import buttonStyles from 'Sparky/Button/Button.module.scss';
import Spinner from 'Sparky/Spinner';

import styles from './ButtonGroup.module.scss';
import { ButtonProps } from '../Button';

export interface ItemProps {
  disabled?: boolean;
  onClick?(): void;
  value: string;
  label: ReactNode;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  className?: string;
}

export interface ButtonGroupProps extends Omit<ButtonProps, 'category' | 'variant'> {
  onValueChange?(key: unknown): void;
  defaultValue?: string;
  boxShadow?: boolean;
  toggleable?: boolean;
  multiActives?: boolean;
  items: ItemProps[];
}

export default function ButtonGroup({
  onValueChange,
  size = 'md',
  isLoading,
  loadingText,
  defaultValue,
  boxShadow = false,
  toggleable = true,
  multiActives = false,
  items,
}: ButtonGroupProps) {
  const Items = multiActives ? Toggle.Root : ToggleGroup.Item;

  return (
    <ToggleGroup.Root
      type='single'
      value={defaultValue}
      onValueChange={onValueChange}
      className={classnames({
        [styles.buttonGroup]: true,
        [styles.boxShadow]: boxShadow,
      })}
    >
      {items.map((item) => (
        <Items
          key={item.value}
          value={item.value}
          onClick={item.onClick}
          disabled={item.disabled}
          data-toggleable={toggleable}
          className={classnames(
            {
              [buttonStyles.button]: true,
              [buttonStyles.sm]: size === 'sm',
              [buttonStyles.md]: size === 'md',
              [buttonStyles.lg]: size === 'lg',
              [buttonStyles.outline]: true,
              [buttonStyles.default]: true,
              [styles.button]: true,
              [styles.toggleable]: toggleable,
            },
            item.className
          )}
        >
          {isLoading ? (
            <>
              <Spinner className={buttonStyles.icon} size={size === 'sm' ? 'sm' : 'md'} />{' '}
              {loadingText}
            </>
          ) : (
            <span className={buttonStyles.content}>
              {item.leftIcon && <span className={buttonStyles.leftIcon}>{item.leftIcon}</span>}
              <span className={buttonStyles.child} data-disabled={item.disabled}>
                <span className={buttonStyles.hoverChild}>{item.label}</span>
                <span className={buttonStyles.normalChild}>{item.label}</span>
              </span>
              {item.rightIcon && <span className={buttonStyles.rightIcon}>{item.rightIcon}</span>}
            </span>
          )}
        </Items>
      ))}
    </ToggleGroup.Root>
  );
}
