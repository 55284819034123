import { useState } from 'react';

import dayjs from 'dayjs';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COLOR } from 'Sparky/styles/vars';

import ChartLegend, {
  colorPalette,
  LegendList,
} from 'pages/Inventory/VinDetailSidebar/components/ChartLegend';
import ChartTooltip from 'pages/Inventory/VinDetailSidebar/components/ChartTooltip';
import { formatCurrency, formatNumber } from 'util/formatters';

export interface VDPPriceChartProps {
  data: VDPPriceChartData[];
  isLoading?: boolean;
  isError?: boolean;
}

export interface VDPPriceChartData {
  date: string;
  totalVDP?: number;
  otherVDP?: number;
  lotlinxVDP?: number;
  dailyPrice?: number;
  conversions?: number;
  markdown?: number;
  marketPrice?: number;
  priceToMarketPct?: number;
}

const legend: LegendList[] = [
  {
    key: 'dailyPrice',
    name: 'Price',
    type: 'rect',
    borderColor: colorPalette.purple.borderColor,
    rangeColor: colorPalette.purple.rangeColor,
  },
  {
    key: 'marketPrice',
    name: 'Market price',
    type: 'rect',
    borderColor: colorPalette.turqouise.borderColor,
    rangeColor: colorPalette.turqouise.rangeColor,
  },
  {
    key: 'otherVDP',
    name: 'VDP views',
    type: 'circle',
    borderColor: colorPalette.blue.borderColor,
    rangeColor: colorPalette.blue.rangeColor,
  },
  {
    key: 'lotlinx',
    name: 'Lotlinx VDP views',
    type: 'circle',
    borderColor: colorPalette.red.borderColor,
    rangeColor: colorPalette.red.rangeColor,
  },
];

const tooltipLegend: LegendList[] = [
  {
    key: 'dailyPrice',
    name: 'Price',
    type: 'rect',
    borderColor: colorPalette.purple.borderColor,
    rangeColor: colorPalette.purple.rangeColor,
  },
  {
    key: 'marketPrice',
    name: 'Market price',
    type: 'rect',
    borderColor: colorPalette.turqouise.borderColor,
    rangeColor: colorPalette.turqouise.rangeColor,
  },
  {
    key: 'totalVDP',
    name: 'VDP views',
    type: 'circle',
    borderColor: colorPalette.blue.borderColor,
    rangeColor: colorPalette.blue.rangeColor,
  },
  {
    key: 'lotlinxVDP',
    name: 'Lotlinx VDP views',
    type: 'circle',
    borderColor: colorPalette.red.borderColor,
    rangeColor: colorPalette.red.rangeColor,
  },
];

export default function VDPPriceChart({ data }: VDPPriceChartProps) {
  const [highlightedBar, setHighlightedBar] = useState<string>();

  const chartData = data.slice().sort((a, b) => dayjs(a.date).diff(dayjs(b.date), 'days'));
  return (
    <ResponsiveContainer width='100%' height='100%' className='chromatic-ignore'>
      <ComposedChart data={chartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
        <CartesianGrid vertical={false} stroke={COLOR.SPARKY_GREY_100} />
        <XAxis
          dataKey='date'
          tickFormatter={(val) => dayjs(val).format('MMM D')}
          axisLine={false}
          tickLine={false}
          angle={chartData.length > 8 ? -50 : 0}
          tick={{
            fill: COLOR.SPARKY_GREY_500,
            fontSize: 12,
            textAnchor: chartData.length > 8 ? 'end' : 'middle',
            dx: -4,
          }}
        />
        <YAxis
          orientation='left'
          yAxisId='views'
          axisLine={false}
          tick={{ fill: COLOR.SPARKY_GREY_500, fontSize: 12 }}
          tickFormatter={(val) => formatNumber(val)}
          allowDecimals={false}
          tickLine={false}
        />
        <YAxis
          orientation='right'
          yAxisId='price'
          domain={['auto', 'auto']}
          axisLine={false}
          tick={{ fill: COLOR.SPARKY_GREY_500, fontSize: 12 }}
          tickFormatter={(val) => formatCurrency(val)}
          tickLine={false}
        />
        <Bar
          name='VDP Views'
          dataKey='otherVDP'
          stackId='stack'
          yAxisId='views'
          fill={COLOR.SPARKY_BLUE_200}
          isAnimationActive={false}
        >
          {chartData.map((item) => (
            <Cell
              onMouseEnter={() => setHighlightedBar(item.date)}
              onMouseLeave={() => setHighlightedBar(undefined)}
              key={item.date}
              fill={highlightedBar === item.date ? COLOR.SPARKY_BLUE_400 : COLOR.SPARKY_BLUE_200}
            />
          ))}
        </Bar>
        <Bar
          name='Lotlinx VDP views'
          dataKey='lotlinxVDP'
          stackId='stack'
          yAxisId='views'
          fill={COLOR.SPARKY_RED_200}
          isAnimationActive={false}
        >
          {chartData.map((item) => (
            <Cell
              onMouseEnter={() => setHighlightedBar(item.date)}
              onMouseLeave={() => setHighlightedBar(undefined)}
              key={item.date}
              fill={highlightedBar === item.date ? COLOR.SPARKY_RED_400 : COLOR.SPARKY_RED_200}
            />
          ))}
        </Bar>
        <Line
          name='Price'
          dataKey='dailyPrice'
          yAxisId='price'
          type='step'
          dot={false}
          strokeWidth='2'
          stroke={COLOR.SPARKY_PURPLE_400}
          isAnimationActive={false}
        />
        <Line
          name='Market Price'
          dataKey='marketPrice'
          yAxisId='price'
          type='step'
          dot={false}
          strokeWidth='2'
          stroke='#10A0A2'
          isAnimationActive={false}
          connectNulls
        />
        <Tooltip
          content={
            <ChartTooltip
              getData={(date) => data?.find((i) => i.date === date)}
              legend={tooltipLegend}
            />
          }
        />
        <Legend
          content={<ChartLegend legend={legend} />}
          wrapperStyle={{ padding: '0 12px', bottom: '0' }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
