import { Dispatch, SetStateAction, useEffect } from 'react';

import { produce } from 'immer';

import { checkItemMatch } from 'components/Filters/checkItemMatch';
import {
  DropdownState,
  FilterConfig,
  FiltersState,
  FilterState,
  FilterType,
  PillsState,
  RangeState,
  SearchState,
  SelectState,
  StaticSelectFilterConfig,
  ToggleState,
} from 'components/Filters/types';
import { formatCurrency, formatNumber, formatPercent } from 'util/formatters';

export interface UseFiltersProps<T> {
  data?: T[];
  filterConfigs: FilterConfig<T>[];
  filtersState: FiltersState;
  requiredFiltersState?: FiltersState;
  setFiltersState: (filtersState: FiltersState) => void | Dispatch<SetStateAction<FiltersState>>;
}

/**
 * Provides helper functions for Filter state, filters data
 * See [Confluence docs](https://confluence.lotlinx.com/display/LLD/Filters) for some definitions.
 */
export default function useFilters<T>({
  data,
  filterConfigs,
  filtersState,
  requiredFiltersState,
  setFiltersState,
}: UseFiltersProps<T>) {
  useEffect(() => {
    requiredFiltersState &&
      setFiltersState(
        produce(filtersState, (draft) => {
          Object.entries(requiredFiltersState).forEach((filter) => {
            const [filterKey, filterState] = filter;
            const config = filterConfigs.find((c) => c.id === filterKey);
            if (config) {
              if (!draft[filterKey]) {
                draft[filterKey] = filterState;
              } else if (
                'search' in filterState &&
                filterState.search !== (draft[filterKey] as SearchState).search
              ) {
                draft[filterKey] = filterState;
              } else if ('min' in filterState || 'max' in filterState) {
                if (
                  filterState.min !== undefined &&
                  filterState.min >= 0 &&
                  filterState.max !== undefined &&
                  filterState.max >= 0
                ) {
                  (draft[filterKey] as RangeState).min = Math.max(
                    filterState.min,
                    (draft[filterKey] as RangeState)?.min ?? 0
                  );
                  (draft[filterKey] as RangeState).max = Math.min(
                    filterState.max,
                    (draft[filterKey] as RangeState)?.max ?? Infinity
                  );
                } else if (
                  filterState.min !== undefined &&
                  filterState.min >= 0 &&
                  (filterState.max === undefined || filterState.max < 0)
                ) {
                  (draft[filterKey] as RangeState).min = Math.max(
                    filterState.min,
                    (draft[filterKey] as RangeState)?.min ?? 0
                  );
                  if (
                    ((draft[filterKey] as RangeState).min ?? 0) >
                    ((draft[filterKey] as RangeState).max ?? Infinity)
                  ) {
                    delete (draft[filterKey] as RangeState).max;
                  }
                } else if (
                  filterState.max !== undefined &&
                  filterState.max >= 0 &&
                  (filterState.min === undefined || filterState.min < 0)
                ) {
                  (draft[filterKey] as RangeState).max = Math.min(
                    filterState.max,
                    (draft[filterKey] as RangeState)?.max ?? Infinity
                  );
                  if (
                    ((draft[filterKey] as RangeState).min ?? 0) >
                    ((draft[filterKey] as RangeState).max ?? Infinity)
                  ) {
                    delete (draft[filterKey] as RangeState).min;
                  }
                }
              } else if ('options' in filterState && filterState.options && draft[filterKey]) {
                filterState.options.forEach((option) => {
                  if (
                    !(
                      draft[filterKey] as SelectState | ToggleState | DropdownState
                    ).options?.includes(option)
                  ) {
                    (draft[filterKey] as SelectState | ToggleState | DropdownState).options?.push(
                      option
                    );
                  }
                });
              }
            }
          });
          return draft;
        })
      );
  }, [data, filterConfigs, filtersState]);

  const activeFilterPills: PillsState[] = Object.entries(filtersState).flatMap((filter) => {
    const [filterKey, filterState] = filter;
    const config = filterConfigs.find((c) => c.id === filterKey);
    if (!config) {
      return [];
    }

    if ('search' in filterState) {
      return {
        id: filterKey,
        key: filterKey,
        value: filterState,
        type: 'search',
        label: `Search: "${filterState.search}"`,
      };
    } else if ('min' in filterState || 'max' in filterState) {
      if ((filterState.min === undefined || filterState.min < 0) && filterState.max !== undefined) {
        return {
          id: filterKey,
          key: filterKey,
          value: filterState,
          type: 'range',
          label: `${config.name}: <${formatLabel(filterKey, filterState.max)}`,
        };
      } else if (
        filterState.min !== undefined &&
        filterState.min >= 0 &&
        filterState.max === undefined
      ) {
        return {
          id: filterKey,
          key: filterKey,
          value: filterState,
          type: 'range',
          label: `${config.name}: ${formatLabel(filterKey, filterState.min)}+`,
        };
      } else if (
        filterState.min !== undefined &&
        filterState.min >= 0 &&
        filterState.max !== undefined
      ) {
        return {
          id: filterKey,
          key: filterKey,
          value: filterState,
          type: 'range',
          label: `${config.name}: ${formatLabel(filterKey, filterState.min)}–${formatLabel(
            filterKey,
            filterState.max
          )}`,
        };
      } else {
        return [];
      }
    } else if ('options' in filterState && filterState.options) {
      return filterState.options.map((option) => ({
        id: filterKey,
        key: filterKey + option,
        value: option,
        type: 'options',
        label: `${config.name}: ${
          config.type === FilterType.STATIC_SELECT
            ? (config as StaticSelectFilterConfig<unknown>).options[option].label
            : config.type === FilterType.TOGGLE_SELECT
            ? 'selected'
            : option
        }`,
      })) as PillsState[];
    } else {
      return [];
    }
  });

  const setFilterState = (id: string, state: FilterState) => {
    setFiltersState(
      produce(filtersState, (draft) => {
        draft[id] = state;
        return draft;
      })
    );
  };

  const getFilterState = (id: string) => {
    return filtersState[id];
  };

  const clearFilter = (id: string) => {
    setFiltersState(
      produce(filtersState, (draft) => {
        if (draft[id]) {
          delete draft[id];
        }
        return draft;
      })
    );
  };

  const clearSelectFilter = (id: string, option: string | number) => {
    const config = filterConfigs.find((config) => config.id === id);
    if (config?.type === FilterType.STATIC_SELECT || config?.type === FilterType.DYNAMIC_SELECT) {
      setFiltersState(
        produce(filtersState, (draft) => {
          if (draft[id]) {
            (draft[id] as SelectState).options = (draft[id] as SelectState).options?.filter(
              (item) => item !== option
            );
          }
          if (!(draft[id] as SelectState).options?.length) {
            delete draft[id];
          }
          return draft;
        })
      );
    }
  };

  const clearRangeFilter = (id: string, isMin: boolean) => {
    const config = filterConfigs.find((config) => config.id === id);
    if (config?.type === FilterType.RANGE) {
      setFiltersState(
        produce(filtersState, (draft) => {
          if (draft[id]) {
            isMin ? delete (draft[id] as RangeState).min : delete (draft[id] as RangeState).max;
            if ((draft[id] as RangeState).min === undefined) {
              delete (draft[id] as RangeState).min;
            }
            if ((draft[id] as RangeState).max === undefined) {
              delete (draft[id] as RangeState).max;
            }
          }
          if (Object.keys(draft[id]).length === 0) {
            delete draft[id];
          }
          return draft;
        })
      );
    }
  };

  const clearAll = () => {
    setFiltersState({});
  };

  const filteredData = data?.filter((item) => checkItemMatch(item, filterConfigs, filtersState));
  return {
    filtersState,
    requiredFiltersState,
    getFilterState,
    setFilterState,
    clearFilter,
    clearSelectFilter,
    clearRangeFilter,
    clearAll,
    filteredData,
    activeFilterPills,
  };
}

function formatLabel(filterKey: string, value: number) {
  switch (filterKey) {
    case 'price':
    case 'markdown':
      return formatCurrency(value);
    case 'current-price-to-market':
      return formatPercent(value);
    default:
      return formatNumber(value);
  }
}
