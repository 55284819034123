import React from 'react';

import styles from './SplitOnNewline.module.scss';

export interface BreakNewlinesProps {
  text?: string;
  notation?: React.ReactNode;
}

/** Splits a string on newlines (`\n`), and returns each part as a separate `p` element */
export default function SplitOnNewline({ text, notation }: BreakNewlinesProps) {
  return (
    <div className={styles.splitOnNewline}>
      <div>
        {text?.split(/\n|\\n/i).map((i, idx) => (
          <p key={idx}>{i}</p>
        ))}
      </div>
      {notation}
    </div>
  );
}
