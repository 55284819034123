import { useContext } from 'react';

import { ToastContext } from './ToastProvider';

/**
 Hook used to show alerts on top of an overlay. The toast component is used to give feedback to users after an action has taken place.
 Each new Toast will close itself when the close button is clicked or after a timeout — the default is 5 seconds.
 When a user interacts with a Toast, its timeout is cleared and restarted after the mouse leaves that Toast.
 */
export default function UseToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToastContext must be used within a ToastProvider');
  }
  return context;
}
