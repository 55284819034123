import React from 'react';

import {
  DialogContent,
  DialogContentProps,
  DialogOverlay,
  DialogOverlayProps,
} from '@reach/dialog';
import { animated, useTransition } from '@react-spring/web';
import classnames from 'classnames';
import { usePrefersReducedMotion } from 'Sparky/hooks';
import { springConfigs } from 'Sparky/springConfigs';

import './Modal.scss';
interface Props {
  children: React.ReactNode;
  'aria-label'?: string;
  overlayClassName?: string;
  overlayStyle?: React.CSSProperties;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
}

export type ModalProps = Props & DialogOverlayProps & DialogContentProps;

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

export default function Modal({
  'aria-label': ariaLabel,
  children,
  overlayClassName,
  overlayStyle,
  contentClassName,
  contentStyle,
  ...otherProps
}: ModalProps) {
  const prefersReducedMotion = usePrefersReducedMotion();
  const transitions = useTransition(otherProps.isOpen, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -10 },
    config: springConfigs.quick,
    immediate: prefersReducedMotion,
  });

  return (
    <>
      {transitions(
        ({ opacity, y }, item) =>
          item && (
            <AnimatedDialogOverlay
              onDismiss={otherProps.onDismiss}
              style={{ opacity, ...overlayStyle }}
              className={overlayClassName}
            >
              <AnimatedDialogContent
                aria-label={ariaLabel}
                style={{
                  transform: y.to((value) => `translate3d(0px, ${value}px, 0px`),
                }}
              >
                <div
                  className={classnames(['modal__content', contentClassName])}
                  style={contentStyle}
                >
                  {children}
                </div>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </>
  );
}
