import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { post } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

interface SendRatingRequest {
  token?: string;
  responseId: number;
  thumbsUp: boolean;
}

async function sendRating({ token, responseId, thumbsUp }: SendRatingRequest): Promise<unknown> {
  assertIsDefined(token);

  return post(ENDPOINTS.rating, {
    headers: { token },
    body: { response_id: responseId, thumbs_up: thumbsUp },
  });
}

export default function useSendRating() {
  const [user] = useAtom(userAtom);
  return useMutation(({ responseId, thumbsUp }: { responseId: number; thumbsUp: boolean }) =>
    sendRating({ token: user?.token, responseId, thumbsUp })
  );
}
