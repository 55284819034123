import { useState } from 'react';

import { IconAdjustments, IconFilter, IconSwitchVertical } from '@tabler/icons-react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import Button from 'Sparky/Button';
import ButtonGroup, { ItemProps } from 'Sparky/ButtonGroup';
import IconButton from 'Sparky/IconButton';
import Popover from 'Sparky/Popover';
import RadioField from 'Sparky/RadioField';
import SparkySelect from 'Sparky/Select';
import { SelectInputOptions } from 'Sparky/SelectInput';
import Spacer from 'Sparky/Spacer';
import Text from 'Sparky/Text';
import { showCompactInventoryViewAtom } from 'store/inventoryItemStore';

import { Filter, INVENTORY_FILTERS, useFiltersContext } from 'components/Filters';
import useSelectedDealer from 'hooks/useSelectedDealer';
import {
  AcquisitionConfig,
  selectOptionAtom,
  ShowAcquisitionSideBar,
} from 'pages/Inventory/Acquisition';
import { AcquisitionTableColumnDefs } from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTableColumnDefs';
import { AcquisitionItem } from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';
import { ButtonSize } from 'pages/Inventory/InventoryPage';
import { ColumnData, exportCSV } from 'util/exportCSV';

import styles from './AcquisitionHeader.module.scss';

export interface AcquisitionHeaderProps {
  filteredData?: AcquisitionItem[];
  visibleSelected: string[];
  acquisitionConfig: AcquisitionConfig;
  showAcquisitionSidebar: ShowAcquisitionSideBar;

  setShowAcquisitionSidebar(value: ShowAcquisitionSideBar): void;
}

export enum AcquisitionOptionType {
  ALL_VINS = 'allVins',
  FAVOURITE_VINS = 'favoriteVins',
}

export const selectOptions: SelectInputOptions = [
  {
    label: 'All VINs',
    value: AcquisitionOptionType.ALL_VINS,
  },
  {
    label: 'Starred VINs',
    value: AcquisitionOptionType.FAVOURITE_VINS,
  },
];

/**
 * AcquisitionHeader
 */
export default function AcquisitionHeader({
  filteredData,
  visibleSelected,
  acquisitionConfig,
  showAcquisitionSidebar,
  setShowAcquisitionSidebar,
}: AcquisitionHeaderProps) {
  const [showCompactAcquisitionView, setShowCompactAcquisitionView] = useAtom(
    showCompactInventoryViewAtom
  );
  const [selectOption, setSelectOption] = useAtom(selectOptionAtom);
  const [showDensityPopover, setShowDensityPopover] = useState(false);
  const { dealer } = useSelectedDealer();

  const { activeFilterPills } = useFiltersContext();
  const { sortColumns } = acquisitionConfig;

  const buttonSize: ButtonSize = showCompactAcquisitionView ? 'sm' : 'md';

  const buttonGroups: ItemProps[] = [
    {
      value: 'Selected VINs number',
      label: `${visibleSelected.length} selected`,
      disabled: true,
      className: styles.selectedVin,
    },
    {
      value: 'Export selected VINs as CSV',
      label: 'Export to CSV',
      disabled: !visibleSelected.length,
      onClick: () => {
        if (filteredData) {
          exportCSV(
            selectedFilteredData(filteredData, visibleSelected),
            formatCSVHeaders(acquisitionConfig),
            `${dealer?.name.replace(/\./g, '')}_${dealer?.id}_ProspectVins_${dayjs().format(
              'YYYYMMDD'
            )}`
          );
        }
      },
    },
  ];

  /** Returns visible selected filtered inventory data only */
  function selectedFilteredData(filteredData: AcquisitionItem[], visibleSelected: string[]) {
    return filteredData.filter((data) => visibleSelected.includes(data.sources[0].listingUrl));
  }

  function formatCSVHeaders(acquisitionTableConfig: AcquisitionConfig) {
    const columns: ColumnData<AcquisitionItem>[] = [];

    acquisitionTableConfig.acquisitionTableConfig.forEach((item) => {
      // Summary Column is broken down into it's base data points for the CSV
      if (item === 'summary') {
        const summaryData = [
          AcquisitionTableColumnDefs.modelYear,
          AcquisitionTableColumnDefs.make,
          AcquisitionTableColumnDefs.model,
          AcquisitionTableColumnDefs.trim,
          AcquisitionTableColumnDefs.bodySegment,
          AcquisitionTableColumnDefs.vin,
        ];
        summaryData.forEach((item) => {
          const data = {
            header: item.header,
            cell: item.csvRenderer || item.getter,
          } as ColumnData<AcquisitionItem>;
          columns.push(data);
        });
        // ignore imageCell as it's not applicable to csv
      } else if (item !== 'imageCell') {
        let header = AcquisitionTableColumnDefs[item].header.replace(/[\n\r]/g, ' ');
        const data = {
          header: header,
          cell:
            AcquisitionTableColumnDefs[item].csvRenderer || AcquisitionTableColumnDefs[item].getter,
        } as ColumnData<AcquisitionItem>;
        columns.push(data);
      }
    });
    return columns;
  }

  return (
    <div
      className={classnames([
        styles.acquisitionHeader,
        showCompactAcquisitionView && styles.compact,
      ])}
    >
      <SparkySelect
        options={selectOptions}
        value={selectOption}
        onValueChange={(value) => setSelectOption(value)}
      />
      <Spacer axis='horizontal' style={{ margin: '0 auto' }} />

      <Filter
        config={INVENTORY_FILTERS.SEARCH}
        groupId='inventory'
        className={styles.searchBar}
        size={showCompactAcquisitionView ? 'sm' : 'md'}
      />
      <Button
        leftIcon={<IconFilter size={buttonSize === 'sm' ? 12 : 20} />}
        rightIcon={
          activeFilterPills.length > 0 ? (
            <Text className={styles.pillNumber} size='12'>
              {activeFilterPills.length}
            </Text>
          ) : undefined
        }
        size={buttonSize}
        variant='outline'
        onClick={() => {
          if (showAcquisitionSidebar !== 'filter') {
            setShowAcquisitionSidebar('filter');
          } else {
            setShowAcquisitionSidebar('');
          }
        }}
      >
        Filter
      </Button>
      <Button
        leftIcon={<IconSwitchVertical size={buttonSize === 'sm' ? 12 : 20} />}
        rightIcon={
          sortColumns.length > 0 ? (
            <Text className={styles.pillNumber} size='12'>
              {sortColumns.length}
            </Text>
          ) : undefined
        }
        size={buttonSize}
        variant='outline'
        onClick={() => {
          if (showAcquisitionSidebar !== 'sort') {
            setShowAcquisitionSidebar('sort');
          } else {
            setShowAcquisitionSidebar('');
          }
        }}
      >
        Sort
      </Button>
      {visibleSelected.length > 0 ? (
        <ButtonGroup
          toggleable={true}
          multiActives={true}
          items={buttonGroups}
          boxShadow={true}
          size={buttonSize}
        />
      ) : (
        <Popover
          isOpen={showDensityPopover}
          setIsOpen={setShowDensityPopover}
          showCarrat={false}
          showCloseButton={false}
          side='bottom'
          align='end'
          popupContent={
            <div className={styles.densityPopoverContainer}>
              <Text fontWeight={5} className={styles.popoverRow}>
                Density
              </Text>
              <RadioField
                smallHover
                checked={!showCompactAcquisitionView}
                onClick={() => {
                  setShowCompactAcquisitionView(false);
                  setShowDensityPopover(false);
                }}
                containerClassNames={styles.popoverRow}
              >
                Default
              </RadioField>
              <RadioField
                smallHover
                checked={showCompactAcquisitionView}
                onClick={() => {
                  setShowCompactAcquisitionView(true);
                  setShowDensityPopover(false);
                }}
                containerClassNames={styles.popoverRow}
              >
                Compact
              </RadioField>
            </div>
          }
        >
          <IconButton
            aria-label='density dropdown toggler'
            variants='outline'
            className={classnames([buttonSize === 'sm' && styles.densityButton])}
            size={buttonSize}
            children={<IconAdjustments size={buttonSize === 'sm' ? 12 : 20} />}
            onClick={() => setShowDensityPopover(!showDensityPopover)}
          />
        </Popover>
      )}
    </div>
  );
}
