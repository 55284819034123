import React, { useEffect, useState } from 'react';

import { useAtom } from 'jotai';
import Spinner from 'Sparky/Spinner';

import useRestoreSession from 'api/restoreSession/restoreSession';
import { userAtom } from 'store';
import * as LocalStorage from 'util/LocalStorage';

import styles from './Authenticator.module.scss';
import logo from './logo-3d.png';

interface AuthProviderProps {
  children: React.ReactNode;
}

/**
 * Loads and validates user data from localStorage
 */
export default function Authenticator({ children }: AuthProviderProps) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const mutation = useRestoreSession();

  // If the userStore is empty, check the stored token, then populate the userStore from localStorage
  // If there's no data in localStorage, the user isn't authenticated
  useEffect(() => {
    const token = LocalStorage.getToken();
    if (user === undefined && !!token) {
      // Show a loading screen while we wait for `restoreSession`
      setLoading(true);

      mutation.mutate(token, {
        onSuccess: (data) => {
          setUser(data);
        },
        onError: () => {
          setUser();
        },
        onSettled: () => {
          setLoading(false);
        },
      });
    }

    // This should only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className={styles.authenticator}>
        <div className={styles.logoContainer} style={{ backgroundImage: `url(${logo})` }}>
          <Spinner size='lg' className={styles.header} />
        </div>
      </div>
    );
  } else {
    return <>{children}</>;
  }
}
