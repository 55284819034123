import { IconExternalLink } from '@tabler/icons-react';
import { TooltipProps } from 'recharts';
import Flex from 'Sparky/Flex';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import TooltipWrapper from 'components/sharedCharts/TooltipWrapper';
import { SimilarVINsData } from 'pages/Inventory/VinDetailSidebar/MarketSection/SimilarVINsChart/SimilarVINsChart';
import { formatCurrency, formatNumber } from 'util/formatters';

import styles from './SimilarVINsChartTooltip.module.scss';

interface SimilarVINsChartTooltipProps extends TooltipProps<string | number, string> {
  avgMarketPrice: string;
  xAxis: 'dol' | 'mileage';
}

export default function SimilarVINsChartTooltip({
  active,
  payload,
  avgMarketPrice,
  xAxis,
}: SimilarVINsChartTooltipProps) {
  const data = payload?.[0]?.payload.data as SimilarVINsData;
  if (!active || !data) {
    return null;
  }

  return (
    <TooltipWrapper key={data.vin} className={styles.tooltip}>
      <img src={`https://imgcdn.lotlinx.com/lookup/${data.dealerId}/${data.vin}`} alt='' />
      <Text
        size={14}
        fontWeight={7}
        style={{ gridArea: 'title', display: 'flex', flexDirection: 'column' }}
      >
        <Text>
          {data.modelYear} {data.make} {data.model}
        </Text>
        <Text size={12} fontWeight={4}>
          {data.trim} {data.bodySegment}
        </Text>
      </Text>

      <Text size={10} fontWeight={4} style={{ gridArea: 'vin' }}>
        VIN: {data.vin}
      </Text>

      <Flex
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        padding='8px'
        columnGap='16px'
        style={{ gridArea: 'details' }}
        className={styles.details}
      >
        <Text size={12}>
          <Text as='p' fontWeight={4} color='secondary'>
            {xAxis === 'dol' ? 'DOL' : 'Mileage'}
          </Text>
          <Text as='p' fontWeight={5}>
            {xAxis === 'dol' ? formatNumber(data.dol) : `${formatNumber(data.mileage)} mi`}
          </Text>
        </Text>
        <Text size={12}>
          <Text as='p' fontWeight={4} color='secondary'>
            Current $
          </Text>
          <Text as='p' fontWeight={5}>
            {formatCurrency(data.lastPrice)}
          </Text>
        </Text>
        <Text size={12}>
          <Text as='p' fontWeight={4} color='secondary'>
            Avg market $
          </Text>
          <Text as='p' fontWeight={5}>
            {avgMarketPrice}
          </Text>
        </Text>
      </Flex>

      <Text
        color='blue'
        style={{ gridArea: 'click', display: 'flex', alignItems: 'center', padding: '4px 0' }}
        fontWeight={5}
        size={12}
      >
        Click point to view VDP <IconExternalLink color={COLOR.SPARKY_BLUE_500} size={18} />
      </Text>
    </TooltipWrapper>
  );
}
