import { useEffect } from 'react';

import { IconSparkles } from '@tabler/icons-react';
import classnames from 'classnames';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Accordion from 'Sparky/Accordion';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import { COLOR } from 'Sparky/styles/vars';
import { LXBrainV2 } from 'Sparky/SVGs/LXBrainV2';
import Text from 'Sparky/Text';

import { getSessionId } from 'components/Chat/ChatModal';
import useInsight from 'pages/Inventory/VinDetailSidebar/api/useInsights/useInsights';
import LoadingState from 'pages/Inventory/VinDetailSidebar/components/LoadingState';
import NoDataAvailable from 'pages/Inventory/VinDetailSidebar/components/NoDataAvailable';
import { useVinDetailContext } from 'pages/Inventory/VinDetailSidebar/utils/VinDetailContext';

import styles from './InsightsSection.module.scss';

/**
 * InsightsSection
 */
export default function InsightsSection() {
  const { item, openSection, onOpenSection } = useVinDetailContext();

  const mutation = useInsight();

  useEffect(
    () =>
      mutation.mutate({
        vin: `${item.listing.vin}`,
        sessionId: getSessionId(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item.listing.vin]
  );

  return (
    <Accordion
      className={classnames([
        styles.insightsSection,
        openSection.includes('insights') && styles.openState,
      ])}
      isExpanded={openSection.includes('insights')}
      handleClick={() => onOpenSection('insights')}
      rightElement={
        <Text fontWeight={4} size='14' className={styles.subTitle}>
          <LXBrainV2 size={16} />
          Powered by Lex
        </Text>
      }
      title='Intelligence'
      titleSize='14'
      hightlightTitleOnOpen
      formatName={false}
      iconBefore={
        <IconSparkles
          size={18}
          color={openSection.includes('insights') ? COLOR.SPARKY_NAVY_400 : COLOR.SPARKY_GREY_400}
        />
      }
      isOpenByRow
    >
      <Flex flex='none' padding='0 16px 16px'>
        <Panel className={styles.panel}>
          {mutation.isSuccess && mutation.data.response ? (
            <Flex flex='none' column rowGap='4px'>
              <div className={styles.markdown}>
                <Markdown remarkPlugins={[remarkGfm]}>{mutation.data.response}</Markdown>
              </div>
            </Flex>
          ) : mutation.isLoading ? (
            <LoadingState />
          ) : (
            <NoDataAvailable sectionName='intelligence' />
          )}
        </Panel>
      </Flex>
    </Accordion>
  );
}
