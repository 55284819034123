import { useMemo } from 'react';

import { atom, useAtom } from 'jotai';
import { compiler } from 'markdown-to-jsx';
import Markdown from 'react-markdown';
import { TypeAnimation } from 'react-type-animation';
import remarkGfm from 'remark-gfm';
import { LXBrainV2 } from 'Sparky/SVGs/LXBrainV2';
import { v4 as uuid } from 'uuid';

import { ChatHistory } from 'pages/LexChat/Chat';

import styles from './AnimatedResponse.module.scss';
import {
  AnimatedResponseContextInterface,
  AnimatedResponseProvider,
  useAnimatedResponseContext,
} from './AnimatedResponseContext';

export interface AnimatedResponseProps {
  resp?: ChatHistory;
}

export interface ChatElement {
  id: string;
  type: string;
  text?: string;
  children?: ChatElement[];
}

export const isRepsonseAnimationReadyAtom = atom(false);
/**
 * AnimatedResponse
 */
export default function AnimatedResponse({ resp }: AnimatedResponseProps) {
  const [isRepsonseAnimationReady] = useAtom(isRepsonseAnimationReadyAtom);

  //* collect table data to use for rendering table later and avoid it being animated
  let tableMarkdown = getTableFromMarkdown(resp?.response ?? '');

  //* Markdown-to-JSX internal compiler to extract nested data structure
  const compliedData = useMemo(() => {
    //TODO: Adding new line and special character to force proper children wrapping on strings. This should be changed
    return compiler(resp?.response + '\n- ‎ ', {
      overrides: {},
    });
  }, [resp?.response]);

  //* Custom data structure that better suites our rendering methods
  const customData = useMemo(() => {
    return refactorData(compliedData) as ChatElement[] | undefined;
  }, [compliedData]);

  /**
   * Generates order array to know the animation sequence, must be done before rending as the the order is unreliable
   * at the point of rendering due to recursive render function
   * */
  const orderArray: string[] = [];

  function getOrder(input: ChatElement[] | undefined) {
    input?.forEach((child: ChatElement) => {
      orderArray.push(child.id);
      if (child.children && child.type !== 'br' && child.type !== 'NA' && child.type !== 'table') {
        getOrder(child.children);
      }
    });
  }
  useMemo(() => {
    //TODO: This is to pop off the last entry which is the special character added above to trick the renderer
    getOrder(customData?.slice(0, customData.length - 1));
  }, [customData]);

  const OutputMemoized = useMemo(() => {
    //* Display Error messages or other string with no hmtl structure
    if (typeof compliedData.props.children === 'string') {
      if (isRepsonseAnimationReady) {
        return (
          <div className={styles.response}>
            <div className={styles.lexIcon}>
              <LXBrainV2 />
            </div>
            <div className={styles.markdownContainer} id={'markdown-container'}>
              {/* tslint:disable-next-line  */}
              <TypeAnimation
                sequence={[compliedData.props.children]}
                speed={80}
                wrapper='span'
                cursor={false}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.response}>
            <div className={styles.lexIcon}>
              <LXBrainV2 />
            </div>
            <div className={styles.markdownContainer} id={'markdown-container'}>
              {compliedData.props.children}
            </div>
          </div>
        );
      }
    }

    return (
      <AnimatedResponseProvider orderArray={orderArray} tableMarkdown={tableMarkdown}>
        <div className={styles.response}>
          <div className={styles.lexIcon}>
            <LXBrainV2 />
          </div>
          <div className={styles.markdownContainer} id={'markdown-container'}>
            {/* tslint:disable-next-line  */}
            <RenderElements key={uuid()} elements={customData} />
          </div>
        </div>
      </AnimatedResponseProvider>
    );
  }, [resp]);

  return OutputMemoized;
}

/** Recursive rendering component - note: do not pass extra props, use the provider */
export function RenderElements({ elements }: { elements: ChatElement[] | undefined }) {
  const [isRepsonseAnimationReady] = useAtom(isRepsonseAnimationReadyAtom);
  const { activeOrderArray, incrementIndex, tableMarkdown } =
    useAnimatedResponseContext() as AnimatedResponseContextInterface;
  let tableIndex = -1;
  if (!elements || !elements.length) {
    return <></>;
  }
  return (
    <>
      {elements?.map((child: ChatElement) => {
        const id = child.id;
        const idIndex = activeOrderArray.indexOf(id);

        if (idIndex === -1) {
          return <span key={uuid()}></span>;
        }

        // NEW ANIMATING ELEMENT
        if (idIndex === activeOrderArray.length - 1) {
          if (child.type === 'string' && child?.text && isRepsonseAnimationReady) {
            return (
              <span key={uuid()}>
                <TypeAnimation
                  sequence={[child?.text, () => incrementIndex(10)]}
                  speed={80}
                  wrapper='span'
                  cursor={false}
                />
                <span key={uuid()}>|</span>
              </span>
            );
          } else {
            incrementIndex(10);
          }
        }

        // EXISTING ELEMENTS
        if (child.type === 'string') {
          return <span key={uuid()}>{child.text}</span>;
        }

        const renderChildren = child.children && checkChildrenFormat(child) && (
          <span key={uuid()}>
            <RenderElements key={uuid()} elements={child.children} />
          </span>
        );
        // Provide pricing and campaign recommendations for new vehicles.
        if (child.type === 'p') {
          return <p key={uuid()}>{renderChildren}</p>;
        }

        if (child.type === 'a') {
          return <span key={uuid()}>{renderChildren}</span>;
        }
        if (child.type === 'ol') {
          return <ol key={uuid()}>{renderChildren}</ol>;
        }
        if (child.type === 'ul') {
          return <ul key={uuid()}>{renderChildren}</ul>;
        }
        if (child.type === 'li') {
          return <li key={uuid()}>{renderChildren}</li>;
        }

        if (child.type === 'strong') {
          return <strong key={uuid()}>{renderChildren}</strong>;
        }

        if (child.type === 'em') {
          return <em key={uuid()}>{renderChildren}</em>;
        }

        if (child.type === 'NA') {
          <strong key={uuid()}>{renderChildren}</strong>;
        }

        if (child.type === 'br') {
          return <br key={uuid()} />;
        }

        //* Table are directly injected
        if (child.type === 'table') {
          tableIndex++;
          return (
            <span key={uuid()}>
              <Markdown remarkPlugins={[remarkGfm]}>{tableMarkdown[tableIndex] as string}</Markdown>
            </span>
          );
        }

        return <span key={uuid()}></span>;
      })}
    </>
  );
}

/** Check that all children have type property, the complier was outputing empty children and this removes that fromt he compiled data */
function checkChildrenFormat(child: ChatElement) {
  let testPass = true;
  child?.children?.forEach((el) => {
    if (el.type === 'none') {
      testPass = false;
    }
  });
  return testPass;
}

/** Extract table from markdown to prevent it from being animated, note: this only works for a single table... will break with multiple */
function getTableFromMarkdown(response: string) {
  const escapeElement = '⠀';
  const tableArray = [];
  const element = '|';
  let startingIndex = response?.indexOf(element);
  let idx = response?.indexOf(escapeElement);

  while (idx !== -1 && startingIndex !== -1) {
    tableArray.push(response?.slice(startingIndex, idx));
    startingIndex = response?.indexOf(element, idx + 1);
    idx = response?.indexOf(escapeElement, startingIndex + 1);
  }
  return tableArray;
}

//* Transform the data into the desired format ChatElement
function refactorData(compliedData: JSX.Element) {
  let alteredData = [...compliedData.props.children] as JSX.Element[];

  function formatLayer(layer: JSX.Element[] | string[]): ChatElement[] | undefined {
    if (layer?.length) {
      return layer.map((child) => {
        //* change strings to objects with ids
        if (typeof child === 'string') {
          return {
            key: uuid(),
            type: 'string',
            text: child,
            id: uuid(),
          } as ChatElement;
        }

        if (!child) {
          return {
            key: uuid(),
            type: 'none',
            id: uuid(),
          } as ChatElement;
        }

        //* Case: Thead/Tbody have children in an object instead of an array
        if (!Array.isArray(child.props.children)) {
          return {
            key: child.key,
            id: uuid(),
            type: child.type,
            children: formatLayer([child.props.children]),
          } as ChatElement;
        }

        //* add ids and children to new format
        if (child.props.children) {
          return {
            key: child.key,
            id: uuid(),
            type: child.type,
            children: formatLayer(child.props.children),
          } as ChatElement;
        } else {
          return {
            key: child.key,
            id: uuid(),
            type: child.type,
          } as ChatElement;
        }
      });
    }
    return undefined;
  }

  if (alteredData.length) {
    const refactoredData = alteredData.map((child) => {
      if (!child.props) {
        return {};
      }
      if (child.props.children) {
        return {
          key: child.key,
          id: uuid(),
          type: child.type ?? 'string',
          children: formatLayer(child.props.children as JSX.Element[] | string[]),
        };
      } else {
        return {
          key: child.key,
          id: uuid(),
          type: child.type ?? 'string',
        };
      }
    });

    return refactoredData;
  } else {
    return undefined;
  }
}
