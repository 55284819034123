import { AddBudgetData } from 'pages/Inventory/InventoryPage/AddBudgetSidebar';
import AddBudgetItem, {
  AddBudgetItemStatus,
} from 'pages/Inventory/InventoryPage/AddBudgetSidebar/components/AddBudgetItem';
import { InventoryItem } from 'types/InventoryItem';

import styles from './AddBudgetList.module.scss';

export interface AddBudgetListProps {
  /** List of items to display */
  vins?: InventoryItem[];
  /** VINs that should show the "updated" status */
  updatedVINs: string[];
  /** VINs that should show the "Inactive" status */
  inactiveVINs: string[];
  /** Current budget selection for active VINs */
  budgets: AddBudgetData;
  /** Update the budget for a VIN */
  onUpdateBudget(vin: string, budget: string): void;
}

export default function AddBudgetList({
  vins,
  updatedVINs,
  inactiveVINs,
  budgets,
  onUpdateBudget,
}: AddBudgetListProps) {
  /** Numeric value for sorting by status */
  const getStatusValue = (item: InventoryItem) => {
    if (updatedVINs.indexOf(item.listing.vin) !== -1) {
      return 1;
    } else if (inactiveVINs.indexOf(item.listing.vin) !== -1) {
      return 2;
    } else if (item.status.status === 'ACTIVE') {
      // ACTIVE items come first in the list, but only if they don't appear in the `updatedVINs` or `inactiveVINs` lists
      return 0;
    } else if (item.status.status === 'PENDING') {
      return 3;
    }
    return 4;
  };

  /* Sort:
   * - First, show active VINs that are not in the `updatedVINs` list
   * - Next, display VINs that have been successfully updated (in the `updatedVINs` list)
   * - Next, display VINs where the `useAddBudget` response has flagged as inactive
   * - Last, display PENDING and SOLD VINs
   */
  const listVins = vins?.slice().sort((a, b) => getStatusValue(a) - getStatusValue(b));
  return (
    <div className={styles.addBudgetList}>
      {listVins?.map((item) => {
        let status: AddBudgetItemStatus = item.status.status;
        if (updatedVINs?.includes(item.listing.vin)) {
          status = 'UPDATED';
        } else if (inactiveVINs?.includes(item.listing.vin)) {
          status = 'INACTIVE';
        }
        return (
          <AddBudgetItem
            key={item.listing.vin}
            budget={budgets[item.listing.vin]}
            item={item}
            status={status}
            onUpdateBudget={onUpdateBudget}
          />
        );
      })}
    </div>
  );
}
