import React from 'react';

import classnames from 'classnames';

import styles from './AspectRatio.module.scss';

export interface AspectRatioProps {
  ratio: number;
  children: React.ReactChild;
  style?: React.CSSProperties;
  className?: string;
}

/**
 * Provides a container with a locked aspect ratio. Useful for embedding maps, videos, and images.
 */
const AspectRatio = React.forwardRef<HTMLDivElement, AspectRatioProps>(
  ({ ratio, children, style, className }, ref) => {
    return (
      <div
        ref={ref}
        className={classnames([styles.aspectRatioBox, className])}
        style={{ ...style, ['--aspect-ratio' as string]: ratio }}
      >
        {children}
      </div>
    );
  }
);
export default AspectRatio;
