import ReactGA from 'react-ga4';
import { useSearchParams } from 'react-router-dom';

const enabledString = 'show';

/** Get/set the ChatModal state */
export default function useShowChat(): [boolean, (show: boolean) => void] {
  const [params, setParams] = useSearchParams();
  const showChat = params.get('chat') === enabledString;
  const setShowChat = (show: boolean) => {
    if (show) {
      params.set('chat', enabledString);
      ReactGA.event({ category: 'VINdaloo', action: 'Opened chat modal' });
    } else {
      params.delete('chat');
      ReactGA.event({ category: 'VINdaloo', action: 'Closed chat modal' });
    }
    setParams(params);
  };

  return [showChat, setShowChat];
}
