import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { ShowInventorySideBar } from 'pages/Inventory/InventoryPage';

export const selectedAtom = atom<string[]>([]);
export const showSwitchFieldAtom = atom<boolean>(true);
export const showInventorySideBarAtom = atomWithStorage<ShowInventorySideBar>(
  'show-inventory-sidebar',
  ''
);
export const showInventoryAddBudgetSidebarAtom = atomWithStorage<boolean>(
  'show-inventory-add-budget-sidebar',
  false
);
export const showCompactInventoryViewAtom = atomWithStorage<boolean>(
  'show-compact-inventory-view',
  false
);
export const showUnsavedAlertAtom = atomWithStorage<boolean>('show-unsaved-alert', true);

export const defaultFirstInventoryConfigId = 1000;
