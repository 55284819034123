import React from 'react';

import classnames from 'classnames';
import Text from 'Sparky/Text';

import styles from './InteractiveRow.module.scss';

export interface InteractiveRowProps {
  border?: boolean;
  size?: 'default' | 'large';
  hover?: boolean;
  textFlexDirection?: 'horizontal' | 'vertical';
  label: string;
  subText?: string;
  iconSuffix?: React.ReactNode;
  iconPrefix?: React.ReactNode;

  handleClick?(): void;

  className?: string;
}

/**
 * InteractiveRow
 */
export default function InteractiveRow({
  label,
  subText,
  size = 'default',
  textFlexDirection = 'horizontal',
  hover = true,
  border = false,
  iconPrefix,
  iconSuffix,
  handleClick,
  className,
}: InteractiveRowProps) {
  return (
    <div
      className={classnames(
        {
          [styles.interactiveRow]: true,
          [styles.largeSize]: size === 'large',
        },
        className
      )}
    >
      <div
        onClick={handleClick}
        className={classnames({
          [styles.hoverContainer]: true,
          [styles.hoverWithBorder]: hover && border,
          [styles.hoverWithoutBorder]: hover && !border,
          [styles.border]: border,
        })}
      >
        {iconPrefix && (
          <div
            className={classnames({
              [styles.iconContainer]: true,
              [styles.largeIconContainer]: size === 'large',
            })}
          >
            {iconPrefix}
          </div>
        )}
        {subText ? (
          <div
            className={classnames({
              [styles.textHorizontalContainer]: textFlexDirection === 'horizontal',
              [styles.textVerticalContainer]: textFlexDirection === 'vertical',
            })}
          >
            <Text
              color='primary'
              type={size === 'large' ? 'body-02' : 'body-compact-01'}
              textWrap='nowrap'
              className={styles.label}
            >
              {label}
            </Text>
            <Text color='secondary' type={'helper-text-02'} textWrap='nowrap'>
              {subText}
            </Text>
          </div>
        ) : (
          <Text
            color='primary'
            type={size === 'large' ? 'body-02' : 'body-compact-01'}
            textWrap='nowrap'
            className={styles.label}
          >
            {label}
          </Text>
        )}
        {iconSuffix && (
          <div
            className={classnames({
              [styles.iconContainer]: true,
              [styles.largeIconContainer]: size === 'large',
            })}
          >
            {iconSuffix}
          </div>
        )}
      </div>
    </div>
  );
}
