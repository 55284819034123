import { useState } from 'react';

import { IconChartScatter } from '@tabler/icons-react';
import classnames from 'classnames';
import Accordion from 'Sparky/Accordion';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import Select from 'Sparky/Select';
import { SelectInputOptions } from 'Sparky/SelectInput';
import Skeleton from 'Sparky/Skeleton';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import useSimilarVINs from 'pages/Inventory/VinDetailSidebar/api/useSimilarVins/useSimilarVINs';
import LoadingState from 'pages/Inventory/VinDetailSidebar/components/LoadingState';
import NoDataAvailable from 'pages/Inventory/VinDetailSidebar/components/NoDataAvailable';
import SimilarVINsChart, {
  SimilarVINsChartXAxisConfig,
} from 'pages/Inventory/VinDetailSidebar/MarketSection/SimilarVINsChart';
import { useVinDetailContext } from 'pages/Inventory/VinDetailSidebar/utils/VinDetailContext';
import { formatCurrency, formatNumber } from 'util/formatters';

import styles from './MarketSection.module.scss';

const configs: Record<string, SimilarVINsChartXAxisConfig> = {
  dol: {
    key: 'dol',
    label: 'DOL',
    shortLabel: 'DOL',
  },
  mileage: {
    key: 'mileage',
    label: 'Mileage',
    shortLabel: 'MI',
  },
};

const selectInputOptions: SelectInputOptions = [
  { label: 'Average market price vs mileage', value: 'mileage' },
  { label: 'Average market price vs days on lot', value: 'dol' },
];

/**
 * MarketSection
 */
export default function MarketSection() {
  const { item, openSection, onOpenSection } = useVinDetailContext();
  const enableMileage = item.listing.inventoryCondition !== 'NEW';
  const [selectedConfig, setSelectedConfig] = useState(
    enableMileage ? configs.mileage : configs.dol
  );

  const { data, isLoading, isError } = useSimilarVINs(item.listing.vin);

  return (
    <Accordion
      className={classnames([
        styles.marketSection,
        openSection.includes('market') && styles.openState,
      ])}
      isExpanded={openSection.includes('market')}
      handleClick={() => onOpenSection('market')}
      rightElement={
        <Tooltip content='Average market price' side='top'>
          <div>
            {isLoading ? (
              <Skeleton width='64px' height='100%' radius='12px' />
            ) : (
              <Text as='span' color='primary' size={14} fontWeight={5}>
                {formatCurrency(item.marketMetrics.avgMarketPrice)}
              </Text>
            )}
          </div>
        </Tooltip>
      }
      title='Market'
      titleSize='14'
      hightlightTitleOnOpen
      formatName={false}
      iconBefore={
        <IconChartScatter
          size={18}
          color={openSection.includes('market') ? COLOR.SPARKY_NAVY_400 : COLOR.SPARKY_GREY_400}
        />
      }
      isOpenByRow
    >
      <Flex flex='none' column rowGap='16px' padding='0 16px 16px'>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <NoDataAvailable sectionName='Shopper demand' />
        ) : (
          <>
            <Panel className={styles.panel}>
              <div className={styles.topSection}>
                <Text color='secondary' fontWeight={5} size={16}>
                  Average market price
                </Text>
                <Text color='secondary' fontWeight={5} size={16}>
                  Current price
                </Text>
                <Text size={24} fontWeight={5}>
                  {formatCurrency(item.marketMetrics.avgMarketPrice)}
                </Text>
                <Text size={24} fontWeight={5}>
                  {formatCurrency(item.priceMetrics?.currentPrice)}
                </Text>
              </div>
              <div className={styles.midSection}>
                <Text color='secondary' fontWeight={5} size={16}>
                  Mileage
                </Text>
                <Text color='secondary' fontWeight={5} size={16}>
                  Days on lot
                </Text>
                <Text size={24} fontWeight={5}>
                  {formatNumber(item.listing.mileage)}
                </Text>
                <Text size={24} fontWeight={5}>
                  {formatNumber(item.listing.dol)}
                </Text>
                <Flex flex='none' width='100%' alignItems='center' columnGap='4px'>
                  <Text size={14} fontWeight={4} color='secondary'>
                    Average market mileage:
                  </Text>
                  <Text size={14} fontWeight={5}>
                    {formatNumber(data?.avgMarketMileage)}
                  </Text>
                </Flex>
                <Flex flex='none' width='100%' alignItems='center' columnGap='4px'>
                  <Text size={14} fontWeight={4} color='secondary'>
                    Average market DOL:
                  </Text>
                  <Text size={14} fontWeight={5}>
                    {formatNumber(data?.avgMarketDol)}
                  </Text>
                </Flex>
              </div>
            </Panel>
            <Panel className={styles.panel}>
              <Select
                value={selectedConfig.key}
                options={selectInputOptions}
                onValueChange={(key) => setSelectedConfig(configs[key])}
              />

              {!data?.inventory?.length ? (
                <NoDataAvailable sectionName='market' />
              ) : (
                <Flex flex='none' width='100%' height='250px'>
                  <SimilarVINsChart
                    data={data?.inventory}
                    yAvg={data?.avgMarketPrice}
                    xAvg={
                      selectedConfig.key === 'dol' ? data?.avgMarketDol : data?.avgMarketMileage
                    }
                    xAxisConfig={selectedConfig}
                    vin={item.listing.vin}
                  />
                </Flex>
              )}
            </Panel>
          </>
        )}
      </Flex>
    </Accordion>
  );
}
