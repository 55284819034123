import classnames from 'classnames';
import Flex from 'Sparky/Flex';
import Text from 'Sparky/Text';

import styles from './TextToggle.module.scss';

interface TextToggleProps {
  className?: string;
  label?: string;
  items: { label: string; value: string }[];
  booleanToggle?: boolean;
  value: string | undefined;

  setValue(value: string): void;
}

export default function TextToggle({
  label,
  booleanToggle,
  items,
  value,
  setValue,
  className,
}: TextToggleProps) {
  return (
    <Flex className={className} column={false} columnGap='8px' alignItems='center'>
      {label && (
        <Text type='label-03' color='secondary'>
          {label}
        </Text>
      )}
      <div className={styles.textToggle}>
        {items &&
          items.map((item) => {
            return (
              <div
                key={item.value}
                onClick={() => {
                  if (booleanToggle) {
                    setValue(items.filter((item) => item.value !== value)[0].value);
                  } else {
                    setValue(item.value);
                  }
                }}
                className={classnames([
                  styles.textToggleOption,
                  value === item.value ? styles.textToggleActive : '',
                ])}
              >
                <Text type='button-sm' className={styles.label}>
                  {item.label}
                </Text>
              </div>
            );
          })}
      </div>
    </Flex>
  );
}
