import React, { memo, SetStateAction, useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import { produce } from 'immer';
import { atom, useAtom } from 'jotai';
import Button from 'Sparky/Button';
import ButtonGroup, { ItemProps } from 'Sparky/ButtonGroup';
import IconButton from 'Sparky/IconButton';
import Message from 'Sparky/Message';
import Modal from 'Sparky/Modal';
import Text from 'Sparky/Text';
import useToast from 'Sparky/Toast';

import AddBudgetList from 'pages/Inventory/InventoryPage/AddBudgetSidebar/components/AddBudgetList';
import useAddBudget from 'pages/Inventory/InventoryPage/api/useAddBudget/useAddBudget';
import { InventoryItem } from 'types/InventoryItem';
import { formatCurrency } from 'util/formatters';

import styles from './AddBudgetSidebar.module.scss';
import AddBudgetConfirmTable from './components/AddBudgetConfirmTable';
import { InventoryTableAddBudgetSidebarColumnKey } from '../CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableWithValueColumnDef } from '../InventoryTable/InventoryTableColumnDefs';

export interface AddBudgetSidebarProps {
  vins?: InventoryItem[];
  // List of VINs that were successfully updated
  budgetAppliedVins: string[];
  setBudgetAppliedVins: React.Dispatch<SetStateAction<string[]>>;
  inventoryTableAddBudgetSidebarColumnDefs: Record<
    InventoryTableAddBudgetSidebarColumnKey,
    InventoryTableWithValueColumnDef
  >;
  showAddBudgetSidebar: boolean;
  setShowAddBudgetSidebar: React.Dispatch<SetStateAction<boolean>>;
  showBudgetModal: boolean;
  setShowBudgetModal: React.Dispatch<SetStateAction<boolean>>;
}

export const budgetOptions = [
  { value: '50', label: '$50' },
  { value: '75', label: '$75' },
  { value: '100', label: '$100' },
];

export type AddBudgetData = Record<string, string>;

interface MessageProps {
  text: string;
  severity: 'error' | 'message';
}

export const budgetsAtom = atom<AddBudgetData>({});

/**
 * AddBudgetSidebar
 */
const AddBudgetSidebar = ({
  vins,
  budgetAppliedVins,
  setBudgetAppliedVins,
  inventoryTableAddBudgetSidebarColumnDefs,
  showAddBudgetSidebar,
  setShowAddBudgetSidebar,
  showBudgetModal,
  setShowBudgetModal,
}: AddBudgetSidebarProps) => {
  const [message, setMessage] = useState<MessageProps>();
  // Map of VINs and their corresponding budget selections
  const [budgets, setBudgets] = useAtom(budgetsAtom);
  // List of VINs that are inactive – updated after calling the mutation, after the API runs additional validation
  const [inactiveVINs, setInactiveVINs] = useState<string[]>([]);
  const totalIncreasedBudget = Object.values(budgets).reduce(
    (partialSum, budget) => partialSum + Number(budget),
    0
  );

  const mutation = useAddBudget();
  const { addToast } = useToast();

  // If the `vins` array changes, update the `budgets` state
  useEffect(() => {
    setBudgets(
      produce(budgets, (draft = {}) => {
        vins
          ?.filter((i) => i.status.status === 'ACTIVE')
          .forEach((i) => {
            if (budgets[i.listing.vin]) {
              draft[i.listing.vin] = budgets[i.listing.vin];
            } else if (
              Number(
                inventoryTableAddBudgetSidebarColumnDefs?.aiRecommendedBudgetIncrease.getter(i)
              )
            ) {
              draft[i.listing.vin] =
                inventoryTableAddBudgetSidebarColumnDefs?.aiRecommendedBudgetIncrease
                  .getter(i)
                  ?.toString() ?? budgetOptions[0].value;
            } else {
              draft[i.listing.vin] = budgetOptions[0].value;
            }
          });
        return draft;
      })
    );
  }, [vins]);

  /** Update the budget amount for a VIN */
  const handleUpdateBudget = (vin: string, budget: string) => {
    setBudgets(
      produce(budgets, (draft) => {
        draft[vin] = budget;
      })
    );
  };

  /** Apply a budget change amount to all VINs */
  const handleApplyAll = (val: string) => {
    setBudgets(
      produce(budgets, (draft) => {
        vins
          ?.filter((i) => i.status.status === 'ACTIVE')
          .forEach((i) => {
            draft[i.listing.vin] = val;
          });
      })
    );
  };

  /** Remove VINs from the budgets map, but not from the modal */
  const removeVINBudgets = (vins: string[]) => {
    setBudgets(
      produce(budgets, (draft) => {
        for (let vin of vins) {
          delete draft[vin];
        }
      })
    );
  };

  const closeBudgetModal = () => {
    setMessage(undefined);
    setShowBudgetModal(false);
  };
  /** Close add budget review modal **/
  const onDismiss = () => {
    closeBudgetModal();
    setShowAddBudgetSidebar(true);
  };

  const handleSubmit = () => {
    const json = Object.entries(budgets)
      .map(([vin, value]) => ({
        vin,
        budget: value ? parseFloat(value) : 0,
      }))
      .filter((i) => i.budget);

    mutation.mutate(json, {
      onSuccess: (data) => {
        let failedVins: string[] = [];
        let successfulVins: string[] = [];
        data.result?.forEach((contract) => {
          if (contract.errorMsg) {
            failedVins = failedVins.concat(contract.vins);
          } else {
            successfulVins = successfulVins.concat(contract.vins);
          }
        });

        setBudgetAppliedVins(
          produce(budgetAppliedVins, (draft) => {
            draft = draft.concat(successfulVins);
            return draft;
          })
        );
        // Remove successful VINs from `budgets`
        removeVINBudgets(successfulVins);

        if (failedVins.length) {
          setBudgetAppliedVins(successfulVins);
          removeVINBudgets(successfulVins);
          setMessage({
            text: 'Failed to apply budget changes to remaining VINs',
            severity: 'error',
          });

          console.error('Failed to update VINS:', failedVins);
          Sentry.captureMessage('addBudget partial failure');
        } else if (data.inactiveVins) {
          setInactiveVINs(data.inactiveVins);
          // Remove inactive VINs from `budgets`
          removeVINBudgets(data.inactiveVins);
          setMessage({
            text: 'Some VINs are pending sale. All valid budget changes have been applied, no further action is required.',
            severity: 'message',
          });
        } else {
          // If all campaigns were created successfully, show a toast and close the modal
          addToast({ status: 'success', description: 'Budget changes applied' });
          closeBudgetModal();
          setShowAddBudgetSidebar(false);
        }
      },
      onError: () => setMessage({ text: 'Failed to apply budget changes', severity: 'error' }),
    });
  };

  const disableControls = Object.keys(budgets).length === 0;

  const quickBudgetSelectOptions = budgetOptions.map((option) => {
    let quickBudgetSelectOption: ItemProps = { ...option };
    quickBudgetSelectOption.disabled = disableControls;
    quickBudgetSelectOption.onClick = () => handleApplyAll(option.value);
    return quickBudgetSelectOption;
  });

  if (showBudgetModal) {
    return (
      <Modal isOpen={showBudgetModal} onDismiss={onDismiss} aria-label='Add Budget Modal'>
        <div className={styles.addBudgetModal}>
          <IconButton
            aria-label='close add budget sidebar'
            size='md'
            className={styles.closeButton}
            onClick={onDismiss}
          >
            <IconX size={20} />
          </IconButton>
          <Text fontWeight={7} color='secondary'>
            Add budget to {vins?.length ?? 0} VIN(s)
          </Text>
          <div className={styles.messageBox}>
            <Message
              category='warning'
              heading='One budget change each month per VIN'
              variant='muted'
              dismissable={false}
            >
              You can only add budget once per VIN, per month. The changes cannot be modified once
              applied.
            </Message>
          </div>
          <AddBudgetConfirmTable budgets={budgets} vins={vins} />
          <div className={styles.summaryBox}>
            <Text fontWeight={7}>
              Total budget increase - {Object.keys(budgets).length ?? 0} VIN(s)
            </Text>
            <Text fontWeight={7}>{formatCurrency(totalIncreasedBudget)}</Text>
          </div>
          <div className={styles.footer}>
            {message && (
              <Text
                color={message.severity === 'error' ? 'tertiary' : 'primary'}
                fontWeight={message.severity === 'error' ? 7 : 4}
                className={styles.message}
              >
                {message.text}
              </Text>
            )}
            <Button variant='outline' onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant='solid' onClick={handleSubmit}>
              Apply changes
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div
      className={classnames({
        [styles.addBudgetSidebar]: true,
        [styles.showAddBudgetSidebar]: showAddBudgetSidebar,
      })}
    >
      <div className={styles.sidebarHeader}>
        <Text fontWeight={7} color='secondary'>
          Add budget to {vins?.length} VIN(s)
        </Text>
        <IconButton
          size='md'
          onClick={() => {
            closeBudgetModal();
            setShowAddBudgetSidebar(false);
          }}
          aria-label='close add budget sidebar'
        >
          <IconX size={20} />
        </IconButton>
      </div>
      <div className={styles.body}>
        <div className={styles.messageBox}>
          <Message
            category='warning'
            heading='One budget change each month per VIN'
            variant='muted'
            dismissable={false}
          >
            You can only add budget once per VIN, per month. The changes cannot be modified once
            applied.
          </Message>
          {budgetAppliedVins?.length > 0 && (
            <Message category='info' variant='muted'>
              {budgetAppliedVins.length} VIN(s) wasn't included because it has already had budget
              applied this month.
            </Message>
          )}
        </div>
        <div className={styles.quickSelect}>
          <Text color='secondary'>Apply to all</Text>
          <ButtonGroup toggleable={true} items={quickBudgetSelectOptions} />
        </div>
        <div className={styles.addBudgetList}>
          <AddBudgetList
            vins={vins}
            updatedVINs={budgetAppliedVins}
            inactiveVINs={inactiveVINs}
            budgets={budgets}
            onUpdateBudget={handleUpdateBudget}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          variant='outline'
          onClick={() => {
            closeBudgetModal();
            setShowAddBudgetSidebar(false);
          }}
        >
          Close
        </Button>
        <Button
          variant='solid'
          onClick={() => {
            setShowBudgetModal(true);
            setShowAddBudgetSidebar(false);
          }}
        >
          Review changes
        </Button>
      </div>
    </div>
  );
};

export default memo(AddBudgetSidebar);
