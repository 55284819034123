export function getOrdinalScale(val?: number): 'low' | 'medium' | 'high' | undefined {
  if (val !== undefined && val > 80) {
    return 'high';
  } else if (val !== undefined && val > 20) {
    return 'medium';
  } else if (val !== undefined && val >= 0) {
    return 'low';
  } else {
    return undefined;
  }
}
