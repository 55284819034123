import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { localMarketDataSchema } from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/localMarketSchema';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

export interface DealerData {
  dealerName: string;
  dealerId: number;
  dealerType: 'franchise' | 'independent';
  isNewKeyDealer?: boolean;
  isUsedKeyDealer?: boolean;
  location: {
    lat: number;
    lng: number;
  };
}

export interface HexData {
  inMarketShoppers: number;
  usedListedUnits: number;
  newListedUnits: number;
  inclusionRadius: number;
  isUsedLocalMarket: boolean;
  isNewLocalMarket: boolean;
  isRequired: boolean;
  dealerCountNew: number;
  dealerCountUsed: number;

  // following values are added dynamically in the component
  opacity?: number;
  borderColor?: string;
  borderOpacity?: number;
  dealersInHex?: DealerData[];
  hexStatus?: string;
}
export interface Hex {
  [hex: string]: HexData;
}

export interface LocalMarketData {
  mapCenter: {
    lat: number;
    lng: number;
  };

  // Every Hex in the 150 mile Radius
  hexData: Hex;

  // every dealer on the map
  dealerList: DealerData[];

  defaultMarketRadius: number;
}

// Endpoint for saving Dealer Settings could be 1 or 2 endpoints

export interface keyDealerships {
  usedKeyDealers: number[];
  newKeyDealers: number[];
}

export interface LocalMarketHexArea {
  hexValues: number[];
}

/**
 * useLocalMarket
 */

export default function useLocalMarketSettings() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const queryKey = ['useLocalMarketSetting', dealerId, user];

  async function fetchLocalMarketSettings(token?: string) {
    assertIsDefined(token);
    assertIsDefined(dealerId);
    const res = await get<LocalMarketData>(`${ENDPOINTS.local_market_settings}/${dealerId}`, {
      headers: { token },
      params: {
        resolution: 5,
      },
    });

    // Fill missing Properties from hexData
    Object.keys(res.hexData).forEach((hex) => {
      const data = res.hexData[hex];

      if (!data.isNewLocalMarket) {
        res.hexData[hex].isNewLocalMarket = false;
      }
      if (!data.isUsedLocalMarket) {
        res.hexData[hex].isUsedLocalMarket = false;
      }
      if (!data.inMarketShoppers) {
        res.hexData[hex].inMarketShoppers = 0;
      }
      if (!data.dealerCountNew) {
        res.hexData[hex].dealerCountNew = 0;
      }
      if (!data.dealerCountUsed) {
        res.hexData[hex].dealerCountUsed = 0;
      }
      if (!data.newListedUnits) {
        res.hexData[hex].newListedUnits = 0;
      }
      if (!data.usedListedUnits) {
        res.hexData[hex].usedListedUnits = 0;
      }
      if (!data.isRequired) {
        res.hexData[hex].isRequired = false;
      }
    });

    const zodValidation = localMarketDataSchema.safeParse(res);

    if (!zodValidation.success) {
      Sentry.captureException(zodValidation.error);
    }

    return res;
  }

  return useQuery<LocalMarketData, Error>({
    queryKey,
    queryFn: () => fetchLocalMarketSettings(user?.token),
  });
}
