import React from 'react';

import { IconConfetti, IconGasStationOff } from '@tabler/icons-react';
import classnames from 'classnames';
import Flex from 'Sparky/Flex';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import styles from './NoDataAvailable.module.scss';

export interface NoDataAvailableProps {
  noFeedOrNoUsedInventory: boolean;
  feedAndUsedInventoryButNoRecommendation: boolean;
  className?: any;
}

/**
 * NoDataAvailable
 */
export default function NoDataAvailable({
  noFeedOrNoUsedInventory,
  feedAndUsedInventoryButNoRecommendation,
  className,
}: NoDataAvailableProps) {
  let content: React.ReactNode;

  if (noFeedOrNoUsedInventory) {
    content = (
      <>
        <IconGasStationOff size={44} color={COLOR.SPARKY_NAVY_400} />
        <Flex flex='none' column rowGap='16px' alignItems='left'>
          <Text size={20} fontWeight={7} color='navy' alignment='center'>
            No VIN Path price recommendations available
          </Text>
          <Flex column height='24px' justifyContent='space-between'>
            <Text size={16} fontWeight={4} color='secondary'>
              We can’t generate pricing recommendations for your dealership. This may be due to any
              or all of the following:
            </Text>
            <Text size={16} fontWeight={4} color='secondary'>
              <Text fontWeight={7} color='secondary'>
                • No feed data:
              </Text>{' '}
              <Text
                as='a'
                target='_blank'
                href='https://lotlinx.atlassian.net/servicedesk/customer/portal/4/group/31/create/221'
                rel='noreferrer'
                className={styles.externalLink}
              >
                contact your performance manager
              </Text>{' '}
              to connect your feed
            </Text>
            <Text size={16} fontWeight={4} color='secondary'>
              <Text fontWeight={7} color='secondary' alignment='left'>
                • You don’t have any USED vehicles in stock:
              </Text>{' '}
              our AI model can only make predictions on used vehicles currently
            </Text>
          </Flex>
        </Flex>
      </>
    );
  } else if (feedAndUsedInventoryButNoRecommendation) {
    content = (
      <>
        <IconConfetti size={44} color={COLOR.SPARKY_NAVY_400} />
        <Flex flex='none' column rowGap='16px' alignItems='center'>
          <Text size={20} fontWeight={7} color='navy'>
            Your vehicles are all priced well!
          </Text>
          <Text size={16} fontWeight={4} color='secondary' alignment='center'>
            We don’t have any price recommendations for you as all of your USED vehicles are either
            likely or highly likely to sell in the next 10 days. Keep up the great work!
          </Text>
        </Flex>
      </>
    );
  }

  return (
    <Flex
      width='1000px'
      height='320px'
      rowGap='24px'
      alignItems='center'
      justifyContent='center'
      column
      flex='none'
      margin='36px 0 0'
      padding='64px'
      className={classnames([styles.noDataAvailable, className])}
    >
      {content}
    </Flex>
  );
}
