import { IconDots } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Popover from 'Sparky/Popover';
import Spacer from 'Sparky/Spacer';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { sourceColorsAtom, SourceIcon } from 'pages/Inventory/Acquisition/AcquisitionTable/Sources';
import { SourceItem } from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';
import { formatCurrency } from 'util/formatters';

export interface PriceProps {
  sources?: SourceItem[];
}

/**
 * Price
 */
export default function Price({ sources }: PriceProps) {
  const [sourceColors] = useAtom(sourceColorsAtom);

  if (!sources) {
    return null;
  }
  const priceSet = new Set();
  sources.forEach((source) => {
    priceSet.add(source.price);
  });

  return (
    <Flex
      columnGap='8px'
      alignItems='center'
      justifyContent='space-between'
      margin='auto'
      width='100%'
    >
      <Text>{formatCurrency(sources[0].price)}</Text>
      {priceSet.size > 1 && (
        <Popover
          side='bottom'
          horizontalOffset={-20}
          verticalOffset={20}
          showCloseButton={false}
          popupContent={
            <Flex column width='100%' columnGap='8px'>
              {sources?.map((source, index) => (
                <Flex key={index} width='100%' alignItems='center' columnGap='8px' padding='8px'>
                  <SourceIcon
                    source={source}
                    size={22}
                    color={
                      sourceColors.find((sourceColor) => sourceColor.sourceName === source.name)
                        ?.color
                    }
                  />
                  <Text color={index === 0 ? 'navy' : 'primary'} fontWeight={index === 0 ? 7 : 4}>
                    {source.name}
                  </Text>
                  <Spacer axis='horizontal' style={{ width: '8px', margin: '0 auto' }} />
                  <Tooltip side='top' content='Current price shown'>
                    <div>
                      <Text
                        color={index === 0 ? 'navy' : 'primary'}
                        fontWeight={index === 0 ? 7 : 4}
                      >
                        {formatCurrency(source.price)}
                      </Text>
                    </div>
                  </Tooltip>
                </Flex>
              ))}
            </Flex>
          }
        >
          <IconButton aria-label='source price' tooltip='Prices on other sources' tooltipSide='top'>
            <IconDots size={16} />
          </IconButton>
        </Popover>
      )}
    </Flex>
  );
}
