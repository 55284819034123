import 'Sparky/styles/reset.scss';
import React from 'react';

import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { MockedRequest } from 'msw';
import { Container, createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import './index.scss';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import ROUTES from 'routes';

// import TraceProvider from './opentelemtry';
import reportWebVitals from './reportWebVitals';

// Set up Mock Service Worker
// This should only be used locally, NEVER in production
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MOCK) {
  /* Origins that should not be handled by MSW */
  const MSWAllowList = [
    'localhost',
    'www.google-analytics.com',
    'www.googletagmanager.com',
    'img.lotlinx.com',
  ];

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('api/mocks/browser');
  worker.start({
    onUnhandledRequest(req: MockedRequest) {
      if (MSWAllowList.indexOf(req.url.hostname) === -1) {
        console.warn(
          `Warning: captured a request without a matching request handler:` +
            `\n\n\t• ${req.method} ${req.url.href}` +
            `\n\nIf you want to intercept this unhandled request, create a request handler for it. Otherwise, add the hostname (${req.url.hostname}) to 'MSWAllowList'.`
        );
      } else {
        console.groupCollapsed(
          `[MSW] ${dayjs().format('HH:mm:ss')} ${req.method} ${req.url.origin}${
            req.url.pathname
          } (Unhandled)`
        );
        console.log('Request', req);
        console.groupEnd();
      }
    },
  });
}

// Google Tag Manager *** Dev Only ***
// if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_USE_MOCK) {
// const tagManagerArgs = {
//   gtmId: 'GTM-K4P3SG65',
// };
// Prod GTM
// const tagManagerArgs = {
//   gtmId: 'GTM-NG5GDSRW',
// };
// TagManager.initialize(tagManagerArgs);
// }

// Setup Sentry
// Only runs if the app was built in production mode (for deployment), and a deployment environment is defined
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_DEPLOY_ENV) {
  Sentry.init({
    dsn: 'https://4091e6da69c24d579d814f6c976274b4@o1421320.ingest.sentry.io/6767175',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_DEPLOY_ENV,
    release: process.env.REACT_APP_VERSION,
  });
}

// For production deployment only – set up GA and Microsoft Clarity
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_DEPLOY_ENV === 'production') {
  ReactGA.initialize('G-KVDHV24C57');

  // Prod GTM
  const tagManagerArgs = {
    gtmId: 'GTM-NG5GDSRW',
  };

  TagManager.initialize(tagManagerArgs);
  const script = document.createElement('script');
  script.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "g9l30u34mm");
  `;
  document.head.appendChild(script);
} else {
  // Dev GA, for testing
  ReactGA.initialize('G-HWW2D8X7DK');

  // Dev GTM
  const tagManagerArgs = {
    gtmId: 'GTM-K4P3SG65',
  };

  TagManager.initialize(tagManagerArgs);
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_ID ?? '',
    options: {
      application: { id: 'turn4', version: process.env.REACT_APP_VERSION },
      diagnosticOptOut: true,
      bootstrap: 'localStorage',
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  const router = createBrowserRouter(ROUTES);

  const root = createRoot(document.getElementById('root') as Container);
  root.render(
    <React.StrictMode>
      {/* <TraceProvider> */}
      <LDProvider>
        <RouterProvider router={router} />
      </LDProvider>
      <div
        style={{ display: 'none' }}
        aria-hidden
        data-version={`${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_DEPLOY_ENV}`}
      />
      {/* </TraceProvider> */}
    </React.StrictMode>
  );
})();

// TODO: VMS-32
reportWebVitals();
