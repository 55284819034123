import { useSearchParams } from 'react-router-dom';

import useDealerList from 'api/useDealerList/useDealerList';

/** Returns the current Dealer, or undefined if missing or invalid */
export default function useSelectedDealer() {
  const [params] = useSearchParams();
  const idParam = params.get('dealerId');
  const dealerId = idParam ? parseInt(idParam) : undefined;

  const res = useDealerList();
  const dealer = res.data?.dealers?.find((d) => d.id === dealerId);

  return {
    isLoading: res.isLoading,
    isError: res.isError,
    dealer,
  };
}
