import { useState } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';
import Message from 'Sparky/Message';

import useFeatureFlag from 'hooks/useFeatureFlag';
import useTimeTracker from 'pages/SignInPage/components/useTimeTracker';
import OutageForm from 'pages/SignInPage/OutageForm';
import ResetCompletePwdForm from 'pages/SignInPage/ResetCompletePwdForm';
import ResetInitPwdForm from 'pages/SignInPage/ResetInitPwdForm';
import SignInForm from 'pages/SignInPage/SignInForm';
import { convertESTToLocal } from 'pages/SignInPage/utils/convertESTToLocal';
import { isCurrentDateBeforeESTRange } from 'pages/SignInPage/utils/isCurrentDateBeforeESTRange';
import { isCurrentDateInESTRange } from 'pages/SignInPage/utils/isCurrentDateInESTRange';
import { Downtime } from 'types/Downtime';
import { FeatureFlag } from 'types/FeatureFlags';

import styles from './SignIn.module.scss';
import SignInFormImg from './SignInImg.jpg';

export interface SignInProps {
  onSuccess?(): void;
}

/**
 * SignInForm
 */
export default function SignIn({ onSuccess }: SignInProps) {
  const { pathname } = useLocation();
  const [queryParameters] = useSearchParams();
  const downtimeState: Downtime = useFeatureFlag(
    FeatureFlag.ENABLE_LOGIN_PAGE_DOWNTIME_NOTIFICATION
  );
  const [timeTracker, setTimeTracker] = useState<number>(0);

  useTimeTracker({
    startDate: downtimeState?.shutdownTime ?? '',
    endDate: downtimeState?.recoverTime ?? '',
    timeTracker,
    setTimeTracker,
  });

  const isDowntimeActive = downtimeState?.shutdownTime && downtimeState?.recoverTime;

  const localShutdownTime = convertESTToLocal(downtimeState.shutdownTime, true);
  const localRecoverTime = convertESTToLocal(downtimeState.recoverTime, true);
  const outageFormLocalRecoverTime = convertESTToLocal(downtimeState.recoverTime, false, true);

  const downtimeRangeNotificationText =
    localShutdownTime?.dateWithOrdinal !== localRecoverTime?.dateWithOrdinal
      ? `from ${localShutdownTime?.timeString} ${localShutdownTime?.timezoneAbbr} on ${localShutdownTime?.dateWithOrdinal} to ${localRecoverTime?.timeString} ${localRecoverTime?.timezoneAbbr} on ${localRecoverTime?.dateWithOrdinal}`
      : `from ${localShutdownTime?.timeString} to ${localRecoverTime?.timeString} ${localRecoverTime?.timezoneAbbr} on ${localRecoverTime?.dateWithOrdinal}`;

  const isShowAlert = isDowntimeActive && isCurrentDateBeforeESTRange(downtimeState?.shutdownTime);
  const isShutdown =
    isDowntimeActive &&
    isCurrentDateInESTRange(downtimeState?.shutdownTime, downtimeState?.recoverTime);

  const email = queryParameters.get('email'),
    resetPwKey = queryParameters.get('resetPwKey');

  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);

  const handleResetPassword = () => {
    setIsResetPassword(!isResetPassword);
  };

  return (
    <div className={styles.signInWrapper}>
      <div style={{ backgroundImage: SignInFormImg }} className={styles.signInImg} />
      {isShutdown ? (
        <OutageForm
          recoverTime={`${outageFormLocalRecoverTime?.dateWithOrdinal} ${outageFormLocalRecoverTime?.timeString} ${outageFormLocalRecoverTime?.timezoneAbbr}`}
        />
      ) : pathname === '/reset_pwd' ? (
        <ResetCompletePwdForm defaultEmail={email} defaultResetPwKey={resetPwKey} />
      ) : isResetPassword ? (
        <ResetInitPwdForm handleResetPassword={handleResetPassword} />
      ) : (
        <SignInForm onSuccess={onSuccess} handleResetPassword={handleResetPassword} />
      )}
      {isShowAlert && (
        <Message
          category='warning'
          variant='muted'
          dismissable={false}
          heading='Notice for scheduled outage'
          className={styles.alertMessage}
        >{`Scheduled VIN Manager maintenance will occur ${downtimeRangeNotificationText}. During this time, VIN Manager will be unavailable. We apologize for any inconvenience`}</Message>
      )}
    </div>
  );
}
