import * as SwitchComponent from '@radix-ui/react-switch';

import styles from './Switch.module.scss';

export interface SwitchProps {
  id: string;
  icon?: JSX.Element;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onCheckedChange?(): void;
}

export default function Switch({
  id,
  icon,
  checked,
  defaultChecked = true,
  disabled = false,
  onCheckedChange,
}: SwitchProps) {
  return (
    <SwitchComponent.Root
      onCheckedChange={onCheckedChange}
      className={styles.SwitchRoot}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      id={id}
    >
      <SwitchComponent.Thumb aria-disabled={disabled} className={styles.SwitchThumb}>
        {icon}
      </SwitchComponent.Thumb>
    </SwitchComponent.Root>
  );
}
