import { IconX } from '@tabler/icons-react';
import IconButton from 'Sparky/IconButton';
import Spacer from 'Sparky/Spacer';
import { SPACING } from 'Sparky/styles/vars';
import { LXBrainV2 } from 'Sparky/SVGs/LXBrainV2';

import styles from './ChatHeader.module.scss';

export interface ChatHeaderProps {
  onDismiss(): void;
}

export default function ChatHeader({ onDismiss }: ChatHeaderProps) {
  return (
    <div className={styles.chatHeader}>
      <Spacer axis='horizontal' size={SPACING.SPACING_04} />
      <div className={styles.brainLogo}>
        <LXBrainV2 />
      </div>
      <Spacer axis='horizontal' size={SPACING.SPACING_04} />
      <div className={styles.betaTag}>BETA</div>
      <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
      <IconButton aria-label='Close Lotlinx Chat' onClick={onDismiss}>
        <IconX color='#000' />
      </IconButton>
    </div>
  );
}
