import { IconBulb, IconX } from '@tabler/icons-react';
import ReactGA from 'react-ga4';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';

import InquirySuggestion from 'components/Chat/components/InquirySuggestion';

import styles from './SuggestionsPanel.module.scss';

export interface SuggestionsPanelProps {
  list: string[];
  onSuggestionClicked(inquiry: string): void;
  onDismiss(): void;
}

/**
 * Suggestions
 */
export default function SuggestionsPanel({
  list,
  onSuggestionClicked,
  onDismiss,
}: SuggestionsPanelProps) {
  return (
    <div className={styles.suggestionsPanel}>
      <span className={styles.header}>
        <IconBulb />
        <Text fontWeight={7}>Based on your last question, you could also ask...</Text>
        <IconButton
          aria-label='Dismiss suggestions'
          className={styles.dismissButton}
          onClick={onDismiss}
        >
          <IconX size='16' />
        </IconButton>
      </span>
      <div className={styles.suggestionList}>
        {list.map((i, idx) => (
          <InquirySuggestion
            key={idx}
            text={i}
            onClick={() => {
              onSuggestionClicked(i);
              ReactGA.event({ category: 'VINdaloo', action: 'Clicked suggestion' });
            }}
          />
        ))}
      </div>
    </div>
  );
}
