import { defaultMarkdownRange } from 'pages/Inventory/VinPath';
import { InventoryItem, ProbabilityOfSaleKeys } from 'types/InventoryItem';
import { ProbabilityInterface } from 'types/VinPath';

export function filterVinPathByProbabilityAndMarkdown(
  currentProbabilityOfSaleStates: ProbabilityInterface,
  probabilityOfSaleAfterMarkdownStates: ProbabilityInterface,
  markdownRange: number[],
  data: InventoryItem[] | undefined
) {
  if (!data) {
    return data;
  } else {
    let filteredData: InventoryItem[];

    filteredData = filterByProbabilityOfSale(
      filterByProbabilityOfSale(data, currentProbabilityOfSaleStates, false),
      probabilityOfSaleAfterMarkdownStates,
      true
    );

    if (JSON.stringify(markdownRange) !== JSON.stringify(defaultMarkdownRange)) {
      filteredData = filteredData.filter((item) =>
        item.vinPath?.pricingOptions && item.vinPath?.pricingOptions[0].markdown
          ? item.vinPath.pricingOptions[0].markdown <= markdownRange[1] &&
            item.vinPath.pricingOptions[0].markdown >= markdownRange[0]
          : false
      );
    }

    return filteredData;
  }
}

function filterByProbabilityOfSale(
  data: InventoryItem[],
  probabilityOfSale: ProbabilityInterface,
  isAfterMarkdown: boolean
): InventoryItem[] {
  if (probabilityOfSale.all) {
    return data
      .map((inventoryItem) => ({
        ...inventoryItem,
        vinPath: {
          ...inventoryItem.vinPath,
          pricingOptions:
            inventoryItem.vinPath?.pricingOptions
              ?.sort((a, b) => (b?.probabilityOfSaleVal ?? 0) - (a?.probabilityOfSaleVal ?? 0))
              .slice(0, 1) ?? [],
        },
      }))
      .filter(
        (inventoryItem) =>
          inventoryItem.vinPath.currentProbabilityOfSale &&
          inventoryItem.vinPath.pricingOptions.length > 0
      );
  } else {
    const probabilityOfSaleArray: ProbabilityOfSaleKeys[] = [];
    Object.entries(probabilityOfSale).forEach(([key, value]) => {
      if (value) {
        probabilityOfSaleArray.push(key as ProbabilityOfSaleKeys);
      }
    });

    return isAfterMarkdown
      ? getAfterMarkdownVins(data, probabilityOfSale)
      : data.filter(
          (item) =>
            item.vinPath?.currentProbabilityOfSale &&
            probabilityOfSaleArray.includes(item.vinPath?.currentProbabilityOfSale)
        );
  }
}

function getAfterMarkdownVins(
  data: InventoryItem[],
  probabilityOfSale: ProbabilityInterface
): InventoryItem[] {
  const probabilityOfSaleArray: ProbabilityOfSaleKeys[] = [];
  Object.entries(probabilityOfSale).forEach(([key, value]) => {
    if (value) {
      probabilityOfSaleArray.push(key as ProbabilityOfSaleKeys);
    }
  });

  let tempInventory = data
    .map((inventoryItem) => {
      const sortedPricingOptions = inventoryItem.vinPath?.pricingOptions?.sort(
        (a, b) => (b?.probabilityOfSaleVal ?? 0) - (a?.probabilityOfSaleVal ?? 0)
      );

      const highestProbability = sortedPricingOptions?.find(
        (item) => item.probabilityOfSale && probabilityOfSaleArray.includes(item.probabilityOfSale)
      );

      return highestProbability !== undefined
        ? {
            ...inventoryItem,
            vinPath: {
              ...inventoryItem.vinPath,
              pricingOptions: [highestProbability],
            },
          }
        : undefined;
    })
    .filter((item) => item !== undefined);

  return tempInventory as InventoryItem[];
}
