import { ChangeEvent, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Button from 'Sparky/Button';
import Text from 'Sparky/Text';
import TextInputField from 'Sparky/TextInputField';

import { APIError, ErrorCode } from 'api/utils';
import { SignInProps } from 'components/SignIn/SignIn';
import useReactGA from 'hooks/useReactGA';
import useSelectedDealer from 'hooks/useSelectedDealer';
import useLogin, { LoginData } from 'pages/SignInPage/api/login/login';
import { LoginSchema } from 'pages/SignInPage/api/login/loginSchema';
import ChangePwdForm from 'pages/SignInPage/ChangePwdForm';
import ContactUsLink from 'pages/SignInPage/ContactUsLink';
import { userAtom } from 'store';

import LotlinxLogo from './lotlinx_logo.png';
import styles from './SignInForm.module.scss';

interface SignInFormProps extends SignInProps {
  handleResetPassword(): void;
}

/**
 * SignInForm
 */
export default function SignInForm({ onSuccess, handleResetPassword }: SignInFormProps) {
  const [user, setUser] = useAtom(userAtom);
  const [params] = useSearchParams();
  const idParam = params.get('dealerId');
  const { dealer } = useSelectedDealer();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const mutation = useLogin();
  const ldClient = useLDClient();
  const { addReactGA } = useReactGA();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<LoginData>({ resolver: zodResolver(LoginSchema) });

  const handleChange = (event: ChangeEvent<HTMLInputElement>, key: 'email' | 'password') => {
    // Without the next line, if the input field is autofilled by the browser, react-hook-form does not know it
    setValue(key, event.target.value);
    clearErrors(key);
    setError('');
  };

  const onSubmit = (data: LoginData) => {
    setLoading(true);

    mutation.mutate(data, {
      onSuccess: async (user) => {
        addReactGA('vm_login_success', {
          vm_visitor_id: user?.id,
          vm_visitor_email: user?.email,
          vm_account_dealerid: dealer?.id ?? idParam ?? undefined,
          vm_account_name: dealer?.name,
        });
        // Wait for LD to get updated flags before completing the login
        await ldClient?.identify({
          kind: 'user',
          key: user.id.toString(),
          firstName: user.first,
          lastName: user.last,
          email: user.email,
          // Custom attributes:
          accountType: user.accountType,
        });

        setLoading(false);
        setError(undefined);
        setUser(user);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (err) => {
        if (err instanceof APIError && err.subCode === ErrorCode.ACCESS_DENIED) {
          setError('Incorrect email or password. Please try again.');
        } else {
          setError('An unknown error occurred');
        }
        setLoading(false);
      },
    });
  };

  return user?.forcePwChange ? (
    <ChangePwdForm />
  ) : (
    <div className={styles.signInFormWrapper}>
      <form className={styles.signInForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.logoContainer}>
          <img src={LotlinxLogo} alt='' className={styles.logo} />
          <Text fontWeight={7} size='20'>
            Welcome back to
          </Text>
          <Text fontWeight={7} size='32'>
            VIN Manager
          </Text>
        </div>
        <TextInputField
          id='email'
          {...register('email', { required: true })}
          variants={error || errors.email ? 'error' : 'default'}
          message={errors.email?.message ?? ' '}
          label='Email'
          labelColor='white'
          onChange={(e) => handleChange(e, 'email')}
          className={styles.textInput}
          messageClassname={styles.errorMessage}
        />
        <TextInputField
          id='password'
          {...register('password', { required: true })}
          variants={error || errors.password ? 'error' : 'default'}
          message={error ? error : errors.password?.message ?? ' '}
          label='Password'
          labelColor='white'
          type='password'
          onChange={(e) => handleChange(e, 'password')}
          className={styles.textInput}
          messageClassname={styles.errorMessage}
        />
        <Button
          type='submit'
          variant='outline'
          isLoading={loading}
          loadingText='Logging In...'
          size='lg'
          className={styles.signInButton}
        >
          Login
        </Button>
      </form>
      <Button variant='solid' size='lg' onClick={handleResetPassword} className={styles.resetPwd}>
        Reset password
      </Button>
      <ContactUsLink />
    </div>
  );
}
