import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import ENDPOINTS from 'api/utils/endpoints';
import { get } from 'api/utils/fetch';
import { filterResponseErrors } from 'api/utils/filterResponseErrors';
import useQueryKeys from 'api/utils/useQueryKeys';
import { userAtom } from 'store';
import { Dealer } from 'types/Dealer';
import { assertIsDefined } from 'util/assert';

import { dealerListDataSchema } from './dealerListSchema';

export interface DealerListData {
  dealers: Dealer[];
  favorites: number[];
}

async function fetchDealerList(token?: string) {
  if (!token) {
    return { dealers: [], favorites: [] };
  }
  assertIsDefined(token);
  const res = await get<DealerListData>(ENDPOINTS.dealers, { headers: { token } });

  // Validate response
  const zodValidation = dealerListDataSchema.safeParse(res);

  if (token && !zodValidation.success) {
    Sentry.captureException(zodValidation.error);
    res.dealers = filterResponseErrors(zodValidation, res.dealers);
  }

  return res;
}

export default function useDealerList() {
  const [user] = useAtom(userAtom);
  const { dealerListKey: queryKey } = useQueryKeys();

  return useQuery<DealerListData, Error>(queryKey, () => fetchDealerList(user?.token));
}
