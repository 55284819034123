import { IconMagnet } from '@tabler/icons-react';
import classnames from 'classnames';
import Accordion from 'Sparky/Accordion';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import Skeleton from 'Sparky/Skeleton';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import useLeads, { Lead } from 'pages/Inventory/VinDetailSidebar/api/useLeads/useLeads';
import LoadingState from 'pages/Inventory/VinDetailSidebar/components/LoadingState';
import NoDataAvailable from 'pages/Inventory/VinDetailSidebar/components/NoDataAvailable';
import LeadsPriceChart from 'pages/Inventory/VinDetailSidebar/LeadsSection/LeadsPriceChart';
import RecentLeads from 'pages/Inventory/VinDetailSidebar/LeadsSection/RecentLeads';
import Source from 'pages/Inventory/VinDetailSidebar/LeadsSection/Source';
import { useVinDetailContext } from 'pages/Inventory/VinDetailSidebar/utils/VinDetailContext';
import { formatNumber } from 'util/formatters';

import styles from './LeadsSection.module.scss';

/**
 * LeadsSection
 */
export default function LeadsSection() {
  const { item, openSection, onOpenSection } = useVinDetailContext();

  const { data, isLoading } = useLeads(item.listing.vin);

  const legendList = getLegendList(data?.leads);

  return (
    <Accordion
      className={classnames([
        styles.leadsSection,
        openSection.includes('leads') && styles.openState,
      ])}
      isExpanded={openSection.includes('leads')}
      handleClick={() => onOpenSection('leads')}
      rightElement={
        <Tooltip content='Total leads' side='top'>
          <div>
            {isLoading ? (
              <Skeleton width='64px' height='100%' radius='12px' />
            ) : (
              <Text as='span' color='primary' size={14} fontWeight={5}>
                {formatNumber(item.leadMetrics?.totalLeads)}
              </Text>
            )}
          </div>
        </Tooltip>
      }
      title='Leads'
      titleSize='14'
      hightlightTitleOnOpen
      formatName={false}
      iconBefore={
        <IconMagnet
          size={18}
          color={openSection.includes('leads') ? COLOR.SPARKY_NAVY_400 : COLOR.SPARKY_GREY_400}
        />
      }
      isOpenByRow
    >
      <Flex column padding='0 16px 16px' width='100%' rowGap='16px'>
        {isLoading ? (
          <LoadingState />
        ) : (
          <>
            <RecentLeads data={data?.recentLeads} />
            <Source leadStatus={data?.leadStatus} />
            <Panel className={styles.panel}>
              <Flex column width='100%' rowGap='12px'>
                <Text size={16} fontWeight={5} color='secondary'>
                  Leads to date by source
                </Text>
                {!data?.leads?.length ? (
                  <NoDataAvailable sectionName='leads to date by source' />
                ) : (
                  <Flex flex='none' height='200px' width='100%'>
                    <LeadsPriceChart legendList={legendList} data={data.leads} />
                  </Flex>
                )}
              </Flex>
            </Panel>
          </>
        )}
      </Flex>
    </Accordion>
  );
}

export function getLegendList(leads: Lead[] | undefined): string[] {
  const set = new Set<string>(
    leads?.flatMap((lead) => {
      return Object.keys(lead);
    })
  );
  set.delete('date');
  set.delete('price');
  set.delete('marketPrice');

  const legendList = Array.from(set);
  const lotlinxIndex = legendList.findIndex((val) => val === 'Lotlinx');
  if (lotlinxIndex > 0) {
    legendList.splice(lotlinxIndex, 1);
    legendList.unshift('Lotlinx');
  }

  return legendList;
}
