import { useEffect, useRef, useState } from 'react';

import Divider from 'Sparky/Divider';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import Select from 'Sparky/Select';
import Text from 'Sparky/Text';

import NoDataAvailable from 'pages/Inventory/VinDetailSidebar/components/NoDataAvailable';
import { formatPercent } from 'util/formatters';

import styles from './Source.module.scss';

export interface SourceProps {
  leadStatus?: Record<string, Record<string, number>>;
}

/**
 * Source
 */
export default function Source({ leadStatus }: SourceProps) {
  const [leadOption, setLeadOption] = useState<string>('');
  const leadsOptions = useRef<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (leadStatus) {
      leadsOptions.current = Object.keys(leadStatus).map((status) => {
        return { label: status, value: status };
      });
      setLeadOption(leadsOptions.current[0]?.value);
    } else {
      leadsOptions.current = [];
    }
  }, [leadStatus]);

  let content: JSX.Element;

  if (!leadStatus || (leadStatus && Object.keys(leadStatus).length < 1)) {
    content = <NoDataAvailable sectionName='leads source' />;
  } else {
    let totalLeads = 0;

    leadOption &&
      Object.values(leadStatus[leadOption])?.forEach((source) => (totalLeads += source));

    content = (
      <>
        <Divider />
        <div className={styles.sourceItems}>
          {leadOption &&
            Object.keys(leadStatus[leadOption])
              .sort((key1, key2) => leadStatus[leadOption][key2] - leadStatus[leadOption][key1])
              .map((source, index) => {
                const formattedPercentage = formatPercent(
                  leadStatus[leadOption][source] / totalLeads
                );

                return (
                  <div key={index} className={styles.sourceItem}>
                    <div className={styles.sourceTitle}>
                      <Text size='14' fontWeight={5}>
                        {source}
                      </Text>
                      <Text size='14' fontWeight={5}>
                        {leadStatus[leadOption][source]}/{totalLeads}
                      </Text>
                    </div>
                    <div className={styles.progressBar}>
                      <span style={{ width: formattedPercentage }} />
                    </div>
                  </div>
                );
              })}
        </div>
      </>
    );
  }

  return (
    <Panel className={styles.panel}>
      <div className={styles.source}>
        <div className={styles.chartTitle} id='source-chart-title'>
          <Text fontWeight={5} color='secondary'>
            Leads source
          </Text>
          <Flex>
            {leadOption && leadsOptions.current.length > 0 && (
              <>
                <Text size={16} fontWeight={7} color='secondary' className={styles.selectPrefix}>
                  Leads:
                </Text>
                <Select
                  options={leadsOptions.current}
                  value={leadOption}
                  onValueChange={setLeadOption}
                  align='end'
                  container={document.getElementById('source-chart-title')}
                />
              </>
            )}
          </Flex>
        </div>
        {content}
      </div>{' '}
    </Panel>
  );
}
