import React from 'react';

export type TableRowProps = React.ComponentPropsWithoutRef<'tr'>;

/**
 * TableRow
 */
export default function TableRow({ className, ...otherProps }: TableRowProps) {
  return <tr className={className} {...otherProps} />;
}
