import { Dispatch, SetStateAction, useState } from 'react';

import { DragEndEvent, DragStartEvent } from '@dnd-kit/core/dist/types';
import { arrayMove } from '@dnd-kit/sortable';
import { IconPlus } from '@tabler/icons-react';
import { produce } from 'immer';
import Accordion from 'Sparky/Accordion';
import Button from 'Sparky/Button';
import Message from 'Sparky/Message';

import { ShowInventorySideBar } from 'pages/Inventory/InventoryPage';
import { InventoryConfig } from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';
import { InventoryTableColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableColumnDef } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';

import styles from './TableColumns.module.scss';
import TableColumnsDndPanel from './TableColumnsDndPanel';

export interface TableColumnsProps {
  isLoading: boolean;
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>;
  currentInventoryConfig: InventoryConfig;

  setCurrentInventoryConfig(val: InventoryConfig): void;

  setShowInventorySidebar: Dispatch<SetStateAction<ShowInventorySideBar>>;
}

/**
 * TableColumns
 */
export default function TableColumns({
  isLoading,
  inventoryTableColumnDefs,
  currentInventoryConfig,
  setCurrentInventoryConfig,
  setShowInventorySidebar,
}: TableColumnsProps) {
  const [activeFrozenColumn, setActiveFrozenColumn] = useState<InventoryTableColumnKey | null>(
    null
  );
  const [activeMainColumn, setActiveMainColumn] = useState<InventoryTableColumnKey | null>(null);

  if (
    [
      ...currentInventoryConfig.inventoryTableConfig.frozenColumns,
      ...currentInventoryConfig.inventoryTableConfig.mainColumns,
    ].length === 0
  ) {
    return (
      <div className={styles.noCustomizedColumns}>
        <Message variant='muted' category='info' collapsible={false} dismissable={false}>
          You need to add columns before your can arrange them
        </Message>
        <Button
          variant='solid'
          leftIcon={<IconPlus size='20' />}
          onClick={() => setShowInventorySidebar('columns')}
        >
          Add Columns
        </Button>
      </div>
    );
  }

  const handleFrozenColumnsDragStart = (event: DragStartEvent): void => {
    setActiveFrozenColumn(event.active.id.toString() as InventoryTableColumnKey);
  };

  const handleFrozenColumnsDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (over?.id && active.id !== over?.id) {
      setCurrentInventoryConfig(
        produce(currentInventoryConfig, (draft) => {
          const oldIndex = draft.inventoryTableConfig.frozenColumns.indexOf(
            active.id as InventoryTableColumnKey
          );
          const newIndex = draft.inventoryTableConfig.frozenColumns.indexOf(
            over.id as InventoryTableColumnKey
          );
          draft.inventoryTableConfig.frozenColumns = arrayMove(
            [...draft.inventoryTableConfig.frozenColumns],
            oldIndex,
            newIndex
          );

          return draft;
        })
      );
    }
  };

  const handleMainColumnsDragStart = (event: DragStartEvent): void => {
    setActiveMainColumn(event.active.id.toString() as InventoryTableColumnKey);
  };

  const handleMainColumnsDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (over?.id && active.id !== over?.id) {
      setCurrentInventoryConfig(
        produce(currentInventoryConfig, (draft) => {
          const oldIndex = draft.inventoryTableConfig.mainColumns.indexOf(
            active.id as InventoryTableColumnKey
          );
          const newIndex = draft.inventoryTableConfig.mainColumns.indexOf(
            over.id as InventoryTableColumnKey
          );
          draft.inventoryTableConfig.mainColumns = arrayMove(
            [...draft.inventoryTableConfig.mainColumns],
            oldIndex,
            newIndex
          );

          return draft;
        })
      );
    }
  };

  const toggleFrozenState = (isFrozen: boolean, column: InventoryTableColumnKey) => {
    if (isFrozen) {
      setCurrentInventoryConfig(
        produce(currentInventoryConfig, (draft) => {
          draft.inventoryTableConfig.frozenColumns =
            draft.inventoryTableConfig.frozenColumns.filter((col) => col !== column);
          draft.inventoryTableConfig.mainColumns.unshift(column);

          return draft;
        })
      );
    } else {
      setCurrentInventoryConfig(
        produce(currentInventoryConfig, (draft) => {
          draft.inventoryTableConfig.mainColumns = draft.inventoryTableConfig.mainColumns.filter(
            (col) => col !== column
          );
          draft.inventoryTableConfig.frozenColumns.push(column);

          return draft;
        })
      );
    }
  };

  return (
    <div className={styles.tableColumns}>
      {/*Frozen columns drag and drop panel*/}
      {currentInventoryConfig.inventoryTableConfig.frozenColumns.length > 0 && (
        <Accordion isLoading={isLoading} title='locked' titleSize='14'>
          <TableColumnsDndPanel
            isLoading={isLoading}
            inventoryTableColumnDefs={inventoryTableColumnDefs}
            activeColumn={activeFrozenColumn}
            columns={currentInventoryConfig.inventoryTableConfig.frozenColumns}
            toggleFrozenState={toggleFrozenState}
            isFrozen={true}
            onDragStart={handleFrozenColumnsDragStart}
            onDragEnd={handleFrozenColumnsDragEnd}
          />
        </Accordion>
      )}

      {/*Main columns drag and drop panel*/}
      {currentInventoryConfig.inventoryTableConfig.mainColumns.length > 0 && (
        <Accordion isLoading={isLoading} title='unlocked' titleSize='14'>
          <TableColumnsDndPanel
            isLoading={isLoading}
            inventoryTableColumnDefs={inventoryTableColumnDefs}
            onDragStart={handleMainColumnsDragStart}
            onDragEnd={handleMainColumnsDragEnd}
            columns={currentInventoryConfig.inventoryTableConfig.mainColumns}
            toggleFrozenState={toggleFrozenState}
            activeColumn={activeMainColumn}
            isFrozen={false}
          />
        </Accordion>
      )}
    </div>
  );
}
