import { useMutation } from '@tanstack/react-query';

import ENDPOINTS from 'api/utils/endpoints';
import { post } from 'api/utils/fetch';
import { assertIsDefined } from 'util/assert';

export interface ResetCompleteData {
  email: string;
  resetPwKey: string;
}

/**
 * useResetComplete
 * @email string
 * @feEnv enum 'production' | 'demo' | 'staging' | 'development' | 'local'
 * @resetPwKey string
 */

async function postResetComplete({ email, resetPwKey }: ResetCompleteData) {
  assertIsDefined(email);
  assertIsDefined(resetPwKey);

  return post(ENDPOINTS.resetComplete, {
    params: {
      email: email,
      feEnv: process.env.REACT_APP_DEPLOY_ENV,
      resetPwKey: resetPwKey,
    },
  });
}

export default function useResetComplete() {
  return useMutation((data: ResetCompleteData) => postResetComplete(data));
}
