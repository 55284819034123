import React from 'react';

import classnames from 'classnames';

import styles from './Cell.module.scss';

export interface CellProps extends React.ComponentPropsWithoutRef<'td'> {
  children?: React.ReactNode;
  textAlign?: 'left' | 'right' | 'center';
}

/**
 * Cell
 */
export default function Cell({ children, className, style, textAlign, ...otherProps }: CellProps) {
  return (
    <td
      className={classnames([styles.cell, className])}
      style={{ textAlign, ...style }}
      {...otherProps}
    >
      {children}
    </td>
  );
}
