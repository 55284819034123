import React from 'react';

import { IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import produce from 'immer';
import Button from 'Sparky/Button';
import Checkbox from 'Sparky/Checkbox';
import Flex from 'Sparky/Flex';

import { useNavigateKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import MarkDown from 'pages/Inventory/VinPath/VinPathTable/MarkDown';
import VinInfo, { VinInfoProps } from 'pages/Inventory/VinPath/VinPathTable/VinInfo';

import styles from './VinPathTableItem.module.scss';

export interface VinPathTableItemProps extends VinInfoProps {
  isOpenVinDetail: boolean;
  visibleSelected: string[];
  setSelectedVins: React.Dispatch<React.SetStateAction<string[]>>;
}

/**
 * VinPathTableItem
 */
export default function VinPathTableItem({
  inventoryItem,
  isOpenVinDetail,
  visibleSelected,
  setSelectedVins,
}: VinPathTableItemProps) {
  const navigate = useNavigateKeepParams();

  const checked = visibleSelected.indexOf(inventoryItem.listing.vin) !== -1;
  const onSelectChange = () =>
    setSelectedVins(
      produce(visibleSelected, (draft) => {
        if (checked) {
          draft = draft.filter((i) => i !== inventoryItem.listing.vin);
        } else {
          draft.push(inventoryItem.listing.vin);
        }
        return draft;
      })
    );

  return (
    <Flex
      columnGap='24px'
      padding='16px 12px'
      width='100%'
      className={classNames([
        styles.vinPathTableItem,
        (checked || isOpenVinDetail) && styles.checked,
      ])}
    >
      <Checkbox
        smallHover={true}
        checked={checked}
        onClick={onSelectChange}
        onKeyDown={onSelectChange}
      />
      <VinInfo inventoryItem={inventoryItem} />
      <MarkDown markdown={(inventoryItem.vinPath?.pricingOptions ?? [])[0]?.markdown} />
      <Button
        variant='outline'
        rightIcon={<IconChevronRight size={16} />}
        onClick={() => navigate(`${inventoryItem.listing.vin}`)}
        className={styles.vinDetailBtn}
      >
        VIN details
      </Button>
    </Flex>
  );
}
