import { useMutation } from '@tanstack/react-query';

import ENDPOINTS from 'api/utils/endpoints';
import { post } from 'api/utils/fetch';
import { assertIsDefined } from 'util/assert';

export async function logout(token?: string) {
  assertIsDefined(token);
  return post(ENDPOINTS.logout, {
    headers: { token },
  });
}

export default function useSignOut() {
  return useMutation((token: string) => logout(token));
}
