import chroma from 'chroma-js';
import { StylesConfig } from 'react-select';
export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly id?: number;
}

export const colourStyles: StylesConfig<ColourOption, true> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isSelected ? data.color : isFocused ? color.alpha(0.1).css() : undefined,
      color: '#000',
      fontSize: '14px',
      margin: '8px',
      ':hover': {
        backgroundColor: '#f5f5f5',
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};
