import { ReactNode } from 'react';

import * as TooltipComponent from '@radix-ui/react-tooltip';
import classnames from 'classnames';

import styles from './Tooltip.module.scss';

export interface TooltipProps {
  children: ReactNode;
  content?: ReactNode;
  disabled?: boolean;
  defaultOpen?: TooltipComponent.TooltipProps['defaultOpen'];
  open?: TooltipComponent.TooltipProps['open'];
  onOpenChange?: TooltipComponent.TooltipProps['onOpenChange'];
  side?: TooltipComponent.TooltipContentProps['side'];
  sideOffset?: TooltipComponent.TooltipContentProps['sideOffset'];
  className?: string;
  textAlign?: 'left' | 'center' | 'right';
  container?: HTMLElement | null;
  maxWidth?: string;
}

export default function Tooltip({
  children,
  content,
  disabled = false,
  defaultOpen = false,
  open = undefined,
  onOpenChange = undefined,
  side = 'bottom',
  sideOffset = 5,
  textAlign = 'center',
  className,
  container = document.body,
  maxWidth,
}: TooltipProps) {
  const uncontrolled = (
    <TooltipComponent.Root defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <TooltipComponent.Trigger asChild>{children}</TooltipComponent.Trigger>
      {!disabled && (
        <TooltipComponent.Portal container={container}>
          <TooltipComponent.Content
            side={side}
            sideOffset={sideOffset}
            style={{
              maxWidth: maxWidth
                ? maxWidth
                : side === 'right' || side === 'left'
                ? '142px'
                : '150px',
              zIndex: 10,
              textAlign: textAlign,
            }}
            className={classnames([styles.content, className])}
          >
            <TooltipComponent.Arrow width={12} height={8} />
            {content}
          </TooltipComponent.Content>
        </TooltipComponent.Portal>
      )}
    </TooltipComponent.Root>
  );

  const controlled = (
    <TooltipComponent.Root open={open}>
      <TooltipComponent.Trigger asChild>{children}</TooltipComponent.Trigger>
      {!disabled && (
        <TooltipComponent.Portal container={container}>
          <TooltipComponent.Content
            side={side}
            sideOffset={sideOffset}
            style={{
              maxWidth: maxWidth
                ? maxWidth
                : side === 'right' || side === 'left'
                ? '142px'
                : '150px',
              zIndex: 10,
              textAlign: textAlign,
            }}
            className={classnames([styles.content, className])}
          >
            <TooltipComponent.Arrow width={12} height={8} />
            {content}
          </TooltipComponent.Content>
        </TooltipComponent.Portal>
      )}
    </TooltipComponent.Root>
  );

  return (
    <TooltipComponent.Provider>
      {open === undefined ? uncontrolled : controlled}
    </TooltipComponent.Provider>
  );
}
