import { z } from 'zod';

import { MarketOpportunityData } from 'pages/Analytics/MarketOpportunity/api/useMarketOpportunity/useMarketOpportunity';

export const marketOpportunityMetricNamesSchema = z.union([
  z.literal('inMarketShoppers'),
  z.literal('salesVolume'),
  z.literal('marketDaysSupply'),
  z.literal('listedUnits'),
]);

export const mapDataPointSchema = z.object({
  hex: z.string(),
  value: z.number(),
  dealerCount: z.number(),
});

export const mapDataSchema = z.object({
  data: z.array(mapDataPointSchema),
});

export const chartDataPointSchema = z.record(z.union([z.number(), z.string()]).nullable()).and(
  z.object({
    date: z.string(),
  })
);

export const chartDataSchema = z.object({
  value: z.number().optional(),
  data: z.array(chartDataPointSchema),
});

export const marketOpportunityDataSchema: z.ZodSchema<MarketOpportunityData> = z.object({
  mapCenter: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
  mapData: z.record(mapDataSchema),
  metricData: z.record(chartDataSchema).optional(),
  marketOppVinsViewed: z
    .object({
      percent: z.number(),
      numberOfVins: z.number().optional(),
    })
    .optional(),
  marketOppAvgDaysToSale: z
    .object({
      dealer: z.number().optional(),
      market: z.number().optional(),
      percent: z.number().optional(),
    })
    .optional(),
});
