/**
 * See [turn4api.yaml](https://gitlab.com/lotlinx/core/turn4-api/-/blob/dev/src/main/resources/spec/turn4api.yaml)
 * for details
 * */
export enum ErrorCode {
  SYSTEM_ERROR = '-1',
  INVALID_REQUEST = '-2',
  NO_METHOD_SPECIFIED = '-3',
  INVALID_METHOD = '-4',
  PARAMETER_ERROR = '-5',
  ACCESS_DENIED = '-100',
  NO_DATA_AVAILABLE = '-200',
  DEALER_ACCESS_DENIED = '-300',
}
