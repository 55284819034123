export type LexRecommendationCategories =
  | 'reports'
  | 'marketing'
  | 'pricing'
  | 'stocking'
  | 'sales'
  | 'market'
  | 'merchandising'
  | 'suggested';

export interface LexRecommendation {
  prompt: string;
  title: string;
  description?: string;
  category: LexRecommendationCategories;
}

export const LEX_RECOMMENDATION_DEFS: LexRecommendation[] = [
  //  REPORTS
  {
    prompt: 'Build me a 3-2-1 report',
    title: 'Build my 3-2-1 Report',
    category: 'reports',
  },
  {
    prompt: `Lex, generate an ‘Aging Inventory Report’ that identifies all new carryover vehicles. Include each VIN’s carryover status, days on lot, and suggested actions (markdown, promotion, etc.).`,
    title: 'Run my Aging Inventory Report',
    category: 'reports',
  },
  {
    prompt: `Lex, generate an ‘Under-Marketed Inventory Report’ showing vehicles with low VDP View Performance or no leads in the last 30 days.`,
    title: 'Run my Under-Marketed Inventory Report',
    category: 'reports',
  },
  {
    prompt: `Lex, generate a 'Lead Conversion Report' that shows how many total leads each VIN has (including internet and walk-ins) and the average age of those leads?`,
    title: 'Run my Lead Conversion Report',
    category: 'reports',
  },

  // MARKETING
  {
    prompt: `Which vehicles have the lowest VDP View Performance, and how can I boost their visibility?`,
    title: `Which vehicles have the lowest VDP View Performance, and how can I boost their visibility?`,
    category: 'marketing',
  },
  {
    prompt: 'Are there specific vehicle segments that are performing poorly in terms of VDP views?',
    title: 'Are there specific vehicle segments that are performing poorly in terms of VDP views?',
    category: 'marketing',
  },
  {
    prompt: 'What is a VDP view and why is it important?',
    title: 'What is a VDP view and why is it important?',
    category: 'marketing',
  },

  // PRICING
  {
    prompt:
      'Which vehicles are priced above the market average, and how does that affect their lead volume?',
    title:
      'Which vehicles are priced above the market average, and how does that affect their lead volume?',
    category: 'pricing',
  },
  {
    prompt: 'Can you show me which vehicles have been marked down the most yet still haven’t sold?',
    title: 'Can you show me which vehicles have been marked down the most yet still haven’t sold?',
    category: 'pricing',
  },
  {
    prompt: 'How does Lotlinx define an overpriced vehicle?',
    title: 'How does Lotlinx define an overpriced vehicle?',
    category: 'pricing',
  },

  // STOCKING
  {
    prompt:
      'Which vehicles have exceeded their Carryover threshold (30 days used / 45 days new) and need attention?',
    title:
      'Which vehicles have exceeded their Carryover threshold (30 days used / 45 days new) and need attention?',
    category: 'stocking',
  },
  {
    prompt: 'Can you show me my Sell-Down (Sell-Through) Rate for both new and used this month?',
    title: 'Can you show me my Sell-Down (Sell-Through) Rate for both new and used this month?',
    category: 'stocking',
  },
  {
    prompt: 'Do I have any overstocked segments? Separate by new and used.',
    title: 'Do I have any overstocked segments? Separate by new and used.',
    category: 'stocking',
  },

  // SALES
  {
    prompt:
      'Which VINs have generated the fewest Sales Opportunities, and do they need a marketing or pricing boost?',
    title:
      'Which VINs have generated the fewest Sales Opportunities, and do they need a marketing or pricing boost?',
    category: 'sales',
  },
  {
    prompt: 'How many sales opportunities do I get on average for my new and used trucks?',
    title: 'How many sales opportunities do I get on average for my new and used trucks?',
    category: 'sales',
  },
  {
    prompt: 'Which vehicles have generated a lot of sales opportunities but have still not sold?',
    title: 'Which vehicles have generated a lot of sales opportunities but have still not sold?',
    category: 'sales',
  },

  // MERCHANDISING
  {
    prompt: 'Which vehicles do not have at least 7 total photos, and how can I improve that?',
    title: 'Which vehicles do not have at least 7 total photos, and how can I improve that?',
    category: 'merchandising',
  },
  {
    prompt: 'On average, how many photos do my used vehicles have?',
    title: 'On average, how many photos do my used vehicles have?',
    category: 'merchandising',
  },
  {
    prompt: 'Why is it important to include photos on my VDP?',
    title: 'Why is it important to include photos on my VDP?',
    category: 'merchandising',
  },

  // MARKET
  {
    prompt: 'Which SUVs have the largest gap between my sales and my local market sales?',
    title: 'Which SUVs have the largest gap between my sales and my local market sales?',
    category: 'market',
  },
  {
    prompt: 'What are the top selling new trucks in my market within the last 30 days?',
    title: 'What are the top selling new trucks in my market within the last 30 days?',
    category: 'market',
  },
  {
    prompt:
      'What are the fastest selling used sedans in my market within the last 90 days. Only show sedans that have been sold at least 10 times.',
    title:
      'What are the fastest selling used sedans in my market within the last 90 days. Only show sedans that have been sold at least 10 times.',
    category: 'market',
  },
];
