import SignIn from 'components/SignIn/SignIn';

import styles from './SignInPage.module.scss';

/**
 * Sign in page
 */
export default function SignInPage() {
  return (
    <div className={styles.signInPage}>
      <div className={styles.background} />
      <SignIn />
    </div>
  );
}
