import { SetStateAction } from 'react';

import { useAtom } from 'jotai';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import SelectDropdown from 'Sparky/SelectDropdown/SelectDropdown';
import { CloseIcon } from 'Sparky/SVGs/CloseIcon';
import { LXBrainV2 } from 'Sparky/SVGs/LXBrainV2';

import DealerSelectButton from 'components/DealerSelectButton';
import { historyAtom, promptLoadingAtom, suggestedPromptsAtom } from 'pages/LexChat/Chat';

import styles from './ModalHeader.module.scss';

export interface ModalHeaderProps {
  setShowLexChat: (update: SetStateAction<boolean>) => void;
  lexChatTone: string;
  setLexChatTone: (update: SetStateAction<string>) => void;
}

/**
 * ModalHeader
 */
export default function ModalHeader({
  setShowLexChat,
  lexChatTone,
  setLexChatTone,
}: ModalHeaderProps) {
  const [isLoading] = useAtom(promptLoadingAtom);
  const [, setSuggestedPrompts] = useAtom(suggestedPromptsAtom);
  const [, setHistory] = useAtom(historyAtom);

  const handleClearChat = () => {
    setHistory([]);
    setSuggestedPrompts([]);
  };

  const CloseButton = () => {
    return (
      <IconButton
        aria-label='close lex chat modal'
        onClick={() => setShowLexChat(false)}
        className={styles.closeButton}
        size='md'
      >
        <CloseIcon />
      </IconButton>
    );
  };

  return (
    <Flex width='100%' justifyContent='space-between'>
      <div className={styles.titleContainer}>
        <button
          className={styles.lexIcon}
          onClick={() => handleClearChat()}
          aria-label='brain-logo-chat-reset-button'
          disabled={isLoading}
        >
          <LXBrainV2 />
        </button>
        <SelectDropdown
          id='personality-selector'
          label='Ask Lex'
          value={lexChatTone}
          setValue={setLexChatTone}
          options={[
            { label: 'Gentle snowflake', value: 'gentle' },
            { label: 'Normal', value: 'normal' },
            { label: 'Assertive', value: 'assertive' },
            { label: 'Drill sergeant', value: 'drill_sergeant' },
          ]}
        />
      </div>

      <Flex alignItems='center' flex='1' justifyContent='flex-end'>
        <div className={styles.dealerSelectContainer}>
          <DealerSelectButton align='end' isMarketMap disabled={isLoading} />
        </div>
        <CloseButton />
      </Flex>
    </Flex>
  );
}
