import { useEffect, useState } from 'react';

export const MEDIA_QUERIES = {
  MIN_WIDTH_XS: '(min-width: 0)',
  MIN_WIDTH_SM: '(min-width: 36em)',
  MIN_WIDTH_MD: '(min-width: 48em)',
  MIN_WIDTH_LG: '(min-width: 62em)',
  MIN_WIDTH_XL: '(min-width: 75em)',
  MIN_WIDTH_XXL: '(min-width: 100em)',
  ORIENTATION_PORTRAIT: '(orientation: portrait)',
  ORIENTATION_LANDSCAPE: '(orientation: landscape)',
} as const;

/**
 * Lightweight hook to make a media query, and detect changes
 */
export default function useMediaQuery(query: string) {
  const [isMatching, setIsMatching] = useState(false);

  useEffect(() => {
    const update = (e: MediaQueryListEvent) => setIsMatching(e.matches);

    const matcher = window.matchMedia(query);

    setIsMatching(matcher.matches);

    matcher.addEventListener('change', update);

    return () => {
      matcher.removeEventListener('change', update);
    };
  }, [query]);

  return isMatching;
}
