import React, { useEffect, useState } from 'react';

import { IconMap, IconRefresh, IconSettings, IconShieldLock } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Location } from 'react-router-dom';
import ROUTES from 'routes';
import Chip from 'Sparky/Chip';
import Divider from 'Sparky/Divider';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Popover from 'Sparky/Popover';
import Spacer from 'Sparky/Spacer';
import Spinner from 'Sparky/Spinner';
import { LXBrainV2 } from 'Sparky/SVGs/LXBrainV2';
import Text from 'Sparky/Text';
import useToast from 'Sparky/Toast';

import useSignOut from 'api/logout/logout';
import useShowChat from 'components/Chat/useShowChat';
import DealerSelectButton from 'components/DealerSelectButton';
import { LinkKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import CampaignNavDropdown from 'components/Navbar/CampaignNavDropdown/CampaignNavDropdown';
import InventoryNavDropdown from 'components/Navbar/InventoryNavDropdown/InventoryNavDropdown';
import SegmentNavDropdown from 'components/Segments/SegmentNavDropdown';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useRouteTracker from 'hooks/useRouteTracker';
import useSelectedDealer from 'hooks/useSelectedDealer';
import { showLexChatAtom } from 'pages/LexChat';
import { showMarketMapsAtom } from 'pages/MarketMaps';
import useGetCacheStatus, {
  GetStatusResponse,
  useGetInitialCacheStatus,
} from 'pages/Settings/CacheRebuild/api/useGetCacheStatus/useGetCacheStatus';
import useInitiateCacheRebuild from 'pages/Settings/CacheRebuild/api/useInitiateCacheRebuild/useInitiateCacheRebuild';
import { userAtom } from 'store';
import { FeatureFlag } from 'types/FeatureFlags';

import VMLogo from './Lotlinx_logo.svg';
import styles from './Navbar.module.scss';
import NavbarLink from './NavbarLink';

export const cachedDealerListAtom = atomWithStorage<number[]>('cached-dealer-list', []);

/**
 * Navbar
 */
export default function Navbar() {
  const [isOpenUserProfile, setIsOpenUserProfile] = useState<boolean>(false);
  const [isOpenSettings, setIsOpenSettings] = useState<boolean>(false);
  const [isOpenAdmin, setIsOpenAdmin] = useState<boolean>(false);

  const { dealer } = useSelectedDealer();

  const [, setShowMarketMaps] = useAtom(showMarketMapsAtom);
  const [, setLexChat] = useAtom(showLexChatAtom);
  const [cacheDealerList, setCacheDealerList] = useAtom(cachedDealerListAtom);
  const [user, setUser] = useAtom(userAtom);
  const mutation = useSignOut();
  const showCampaigns = useFeatureFlag(FeatureFlag.ENABLE_DEALER_LANDING_PAGE);
  const showLocalMarketSettings = useFeatureFlag(FeatureFlag.ENABLE_LOCAL_MARKET_SETTINGS);
  const showAnalytics = useFeatureFlag(FeatureFlag.ENABLE_NAVBAR_ANALYTICS);
  const showRedesignedInventory = useFeatureFlag(FeatureFlag.ENABLE_REDESIGNED_INVENTORY_PAGE);
  const showGeoConfig = useFeatureFlag(FeatureFlag.ENABLE_GEO_CONFIG_SETTING);
  const showShowroom = useFeatureFlag(FeatureFlag.ENABLE_SHOWROOM);
  const showLXVideoConfigSettings = useFeatureFlag(FeatureFlag.ENABLE_LX_VIDEO_CONFIG_SETTING);
  const showPhotoAISettings = useFeatureFlag(FeatureFlag.ENABLE_PHOTO_AI_SETTING);
  const showVMXAccess = useFeatureFlag(FeatureFlag.ENABLE_VMX_ACCESS);
  const showTooltipConfig = useFeatureFlag(FeatureFlag.ENABLE_TOOLTIP_CONFIG_PAGE);
  const showMarketMaps = useFeatureFlag(FeatureFlag.ENABLE_MARKET_MAPS);
  const showAdminActivityFlag = useFeatureFlag(FeatureFlag.ENABLE_ADMIN_ACTIVITY);
  const showAdminActivity = user?.accountType === 'OWNER' && showAdminActivityFlag;
  const showLexChat = useFeatureFlag(FeatureFlag.ENABLE_LEX_CHAT);
  const enableChat = useFeatureFlag(FeatureFlag.ENABLE_CHAT); // old chat
  const enableCacheRebuild = useFeatureFlag(FeatureFlag.ENABLE_CACHE_REBUILD);
  const [lastCacheUpdate, setLastCacheUpdate] = useState('');
  const [isCacheRefreshing, setIsCacheRefreshing] = useState<boolean>(false);
  const { addToast } = useToast();

  const isCacheRebuildEnabled =
    user?.accountType === 'OWNER' && enableCacheRebuild && user.email.indexOf('@lotlinx.com') > -1;

  const response = useGetCacheStatus(cacheDealerList);
  const initialResponse = useGetInitialCacheStatus();

  useEffect(() => {
    if (!initialResponse.isLoading && initialResponse?.data?.lastUpdated) {
      setLastCacheUpdate(initialResponse?.data?.lastUpdated);
    }
  }, [initialResponse, initialResponse.isLoading]);

  // Reset flag on dealer change
  useEffect(() => {
    if (isCacheRebuildEnabled) {
      setIsCacheRefreshing(false);
    }
  }, [dealer?.id]);

  const checkCacheStatus = () => {
    if (dealer?.id && response.length > 0) {
      let lastCacheEntry =
        //@ts-ignore
        response.find((r) => r?.data?.dealerId === dealer?.id)?.data?.lastUpdated;
      if (lastCacheEntry) {
        setLastCacheUpdate(lastCacheEntry);
      }
      if (cacheDealerList.length > 0) {
        let cacheList = [...cacheDealerList];
        response.forEach((entry) => {
          if (!entry.isLoading && !entry.isRefetching) {
            let data: GetStatusResponse = entry.data as GetStatusResponse;
            if (data?.status === 'RUNNING' || data?.status === 'PENDING') {
              // If the cacheRefreshing flag isn't active, but the current active dealer refresh is running, set flag to true
              if (data.dealerId === dealer.id && !isCacheRefreshing) {
                setIsCacheRefreshing(true);
              }
            }

            if (data?.status === 'COMPLETE') {
              // If the flag is true, and data is current dealer, set flag false
              if (data.dealerId === dealer.id && isCacheRefreshing) {
                setIsCacheRefreshing(false);
              }
              // Give toast notifying the refresh is complete, and remove from atom
              addToast({
                status: 'success',
                description: `Cache reset for dealer id ${data?.dealerId}`,
                duration: 30000,
              });
              cacheList = cacheDealerList.filter((e) => e !== data.dealerId);
            }

            if (data?.status === 'ERROR' || data?.status === 'UNKNOWN') {
              // If the flag is true, and data is current dealer, set flag false
              if (data.dealerId === dealer.id && isCacheRefreshing) {
                setIsCacheRefreshing(false);
              }

              addToast({
                status: 'error',
                description: `There was an issue with the cache reset for dealer id ${data?.dealerId}`,
                duration: 30000,
              });
              cacheList = cacheDealerList.filter((e) => e !== data.dealerId);
            }
          }
        });
        if (cacheList.toString() !== cacheDealerList.toString()) {
          setCacheDealerList(cacheList);
        }
      }
    }
  };

  useEffect(() => {
    if (isCacheRebuildEnabled) {
      checkCacheStatus();
    }
  }, [dealer, response]);

  const cacheMutation = useInitiateCacheRebuild();

  const [, setShowChat] = useShowChat();

  useRouteTracker(ROUTES);

  function returnAccountInfo() {
    return {
      visitor: { id: user?.id, email: user?.email },
      account: { dealerId: dealer?.id, accountName: dealer?.name, payingStatus: dealer?.type },
    };
  }

  //* Make the account information available in the inspector console to be brought into data layer for GTM
  useEffect(() => {
    if (dealer) {
      /* @ts-ignore */
      window.getAccountInfo = returnAccountInfo();
    }
  }, [dealer]);

  useEffect(() => {
    setIsOpenUserProfile(false);
  }, [user]);

  const handleSignOut = () => {
    setUser();
    if (user) {
      mutation.mutate(user.token);
    }
  };

  const handleCacheRefreshClick = () => {
    if (dealer?.id) {
      cacheMutation.mutate(undefined, {
        onSuccess: () => {
          if (cacheDealerList.findIndex((r) => r === dealer?.id) === -1) {
            setCacheDealerList([dealer?.id, ...cacheDealerList]);
          }
          setIsCacheRefreshing(true);
        },
        onError: (err) => {
          if (
            `${err}` === 'Error: A cache build is already in process for this dealer (Error -1)'
          ) {
            setIsCacheRefreshing(true);
            if (cacheDealerList.findIndex((r) => r === dealer?.id) === -1) {
              setCacheDealerList([dealer?.id, ...cacheDealerList]);
            }
            addToast({
              status: 'error',
              description: 'A cache build has already been started',
            });
          }
        },
      });
    } else {
      addToast({
        status: 'error',
        description: 'There was an error starting the refresh, Please check a dealer is selected',
      });
    }
  };

  const DropdownLink = ({
    to,
    label,
    onClick,
  }: {
    to: string;
    label: string;
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
  }) => {
    const isActive = window.location.pathname.includes(to);
    return (
      <button>
        <LinkKeepParams tabIndex={-1} to={to} className={styles.link} onClick={(e) => onClick(e)}>
          <Text className={classnames([styles.userProfileList, isActive && styles.active])}>
            {label}
          </Text>
        </LinkKeepParams>
      </button>
    );
  };

  return (
    <header className={styles.navbar}>
      <LinkKeepParams className={styles.logoWrapper} to={'/analytics/market_insights'}>
        <img className={styles.logo} src={VMLogo} alt='' />
      </LinkKeepParams>
      <div className={styles.linksContainer}>
        {showAnalytics && <SegmentNavDropdown />}
        {showRedesignedInventory && <InventoryNavDropdown />}
        <NavbarLink
          label='VVO'
          to='vvo'
          customIsActive={(location: Location) => location.pathname.includes('vvo')}
        />
        {showCampaigns && <CampaignNavDropdown />}
      </div>
      <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
      {isCacheRefreshing && isCacheRebuildEnabled && (
        <Chip size='md' leftIcon={<IconRefresh size={12} />} variant='filled' color='grey'>
          Cache refresh in progress
        </Chip>
      )}
      {/* Hide Market maps on Demo due to token issue */}
      {process.env.REACT_APP_DEPLOY_ENV !== 'demo' && showMarketMaps && (
        <IconButton
          aria-label='market maps button'
          tooltip='Market maps'
          onClick={() => setShowMarketMaps((prev) => !prev)}
        >
          <IconMap color='black' size='16' />
        </IconButton>
      )}
      <Popover
        isOpen={isOpenSettings}
        setIsOpen={setIsOpenSettings}
        showCarrat={false}
        showCloseButton={false}
        side='bottom'
        align='end'
        verticalOffset={18}
        contentClassName={styles.userProfileLists}
        popupContent={
          <div className={styles.navbarSettings}>
            <Text
              color='secondary'
              className={classnames([styles.userProfileList, styles.disabled])}
            >
              Dealer settings
            </Text>
            {showLocalMarketSettings && (
              <DropdownLink
                to='settings/local_market'
                label='Local Market'
                onClick={() => setIsOpenSettings(false)}
              />
            )}
            {showGeoConfig && (
              <DropdownLink
                to='settings/geo_config'
                label='Geo Configuration'
                onClick={() => setIsOpenSettings(false)}
              />
            )}
            {showShowroom && (
              <DropdownLink
                label='Showroom'
                to='/settings/Showroom'
                onClick={() => setIsOpenSettings(false)}
              />
            )}
            {showLXVideoConfigSettings && (
              <DropdownLink
                label='LX Video'
                to='/settings/lx_video_config'
                onClick={() => setIsOpenSettings(false)}
              />
            )}
            {showPhotoAISettings && (
              <DropdownLink
                label='Photo AI'
                to='/settings/photo_ai_config'
                onClick={() => setIsOpenSettings(false)}
              />
            )}
            {showVMXAccess && (
              <DropdownLink
                label='VMX Access'
                to='/settings/vmx_config'
                onClick={() => setIsOpenSettings(false)}
              />
            )}
            {showTooltipConfig && (
              <DropdownLink
                label='Tooltip Config'
                to='/settings/tooltip_config'
                onClick={() => setIsOpenSettings(false)}
              />
            )}
            {isCacheRebuildEnabled && (
              <>
                <Divider className={styles.settingsDivider} />

                {!isCacheRefreshing ? (
                  <Flex
                    className={styles.settingsCacheRefresh}
                    columnGap='36px'
                    flex='1'
                    column={false}
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Text size={16} lineHeight='24px' fontWeight={4} color='secondary'>
                      Last cache - {lastCacheUpdate}
                    </Text>
                    <IconButton
                      tooltip='Refresh cache'
                      tooltipSide='right'
                      variants='outline'
                      autoFocus={false}
                      aria-label='refresh cache'
                      onClick={handleCacheRefreshClick}
                    >
                      <IconRefresh />
                    </IconButton>
                  </Flex>
                ) : (
                  <Flex
                    className={styles.settingsCacheRefresh}
                    columnGap='36px'
                    flex='1'
                    column={false}
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Text size={16} lineHeight='24px' fontWeight={4} color='disabled'>
                      Cache is refreshing...
                    </Text>
                    <Spinner className={styles.settingsSpinner} size='lg' />
                  </Flex>
                )}
              </>
            )}
          </div>
        }
      >
        <IconButton tooltip='Settings' aria-label='settings'>
          <IconSettings color='#000' size='20' />
        </IconButton>
      </Popover>
      {showAdminActivity && (
        <Popover
          isOpen={isOpenAdmin}
          setIsOpen={setIsOpenAdmin}
          side='bottom'
          align='end'
          showCarrat={false}
          showCloseButton={false}
          verticalOffset={18}
          contentClassName={styles.userProfileLists}
          popupContent={
            <div>
              <Text
                color='secondary'
                className={classnames([styles.userProfileList, styles.disabled])}
              >
                Admin
              </Text>

              <DropdownLink
                to='admin/activity'
                label='Activity'
                onClick={() => setIsOpenAdmin(false)}
              />
              <DropdownLink to='admin/users' label='Users' onClick={() => setIsOpenAdmin(false)} />
            </div>
          }
        >
          <IconButton
            tooltip='Admin'
            onClick={() => setIsOpenAdmin(!isOpenAdmin)}
            aria-label='settings'
          >
            <IconShieldLock color='#000' size='20' />
          </IconButton>
        </Popover>
      )}
      <DealerSelectButton align='end' verticalOffset={10} />
      {enableChat && (
        <IconButton
          aria-label='Open Lotlinx Chat'
          onClick={() => setShowChat(true)}
          className={styles.chatButton}
        >
          <LXBrainV2 />
        </IconButton>
      )}
      {showLexChat && (
        <IconButton
          aria-label='Open Lex Chat'
          tooltip='Lex'
          onClick={() => setLexChat(true)}
          className={styles.chatButton}
        >
          <LXBrainV2 />
        </IconButton>
      )}

      <Popover
        isOpen={isOpenUserProfile}
        setIsOpen={setIsOpenUserProfile}
        showCarrat={false}
        showCloseButton={false}
        side='bottom'
        align='end'
        verticalOffset={8}
        contentClassName={styles.userProfileLists}
        popupContent={<UserProfileList handleClick={handleSignOut} label='Log Out' />}
      >
        <button className={styles.avatar} aria-label='User profile'>
          {user && `${user.first.charAt(0)}${user.last.charAt(0)}`.toUpperCase()}
        </button>
      </Popover>
    </header>
  );
}

interface UserProfileListProps {
  label: string;
  icon?: React.ReactElement;

  handleClick(): void;
}

function UserProfileList({ label, icon, handleClick }: UserProfileListProps) {
  return (
    <button className={styles.userProfileList} onClick={() => handleClick()} aria-label={label}>
      <Text>{label}</Text>
      {icon && <Text className={styles.icon}>{icon}</Text>}
    </button>
  );
}
