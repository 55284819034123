/* eslint-disable no-unused-vars */
import { CSSProperties, useEffect } from 'react';

import {
  IconAlertTriangleFilled,
  IconCircleCheckFilled,
  IconInfoCircleFilled,
  IconX,
} from '@tabler/icons-react';
import classnames from 'classnames';
import Button from 'Sparky/Button';
import useHover from 'Sparky/hooks/useHover';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';
import useToast from 'Sparky/Toast';

import styles from './Toast.module.scss';

export type ToastStatus = 'default' | 'success' | 'error' | 'warning' | 'info';

export interface BaseToastProps {
  status: ToastStatus;
  description: string;
  dismissible?: boolean;
  showButton?: boolean;
  onClick?(): void;
  buttonLabel?: string;
  duration?: number;
}

export interface ToastProps extends BaseToastProps {
  id: number;
  style?: CSSProperties;
}

export default function Toast({
  id,
  description,
  duration = 5000,
  status = 'default',
  dismissible = true,
  showButton,
  buttonLabel,
  onClick,
  style,
}: ToastProps) {
  const { removeToast } = useToast();

  const [hoverRef, isHovered] = useHover<HTMLDialogElement>();

  let timerId: NodeJS.Timeout;
  useEffect(() => {
    // Keep the Toast visible while the user interacts with it
    if (isHovered) {
      clearTimeout(timerId);
    } else {
      if (!timerId) {
        timerId = setTimeout(() => {
          removeToast(id);
        }, duration);
      }
    }

    return () => clearTimeout(timerId);
  }, [removeToast, id, duration, isHovered]);

  return (
    <dialog
      ref={hoverRef}
      className={classnames({
        [styles.toastContainer]: true,
        [styles.default]: status === 'default',
        [styles.success]: status === 'success',
        [styles.error]: status === 'error',
        [styles.warning]: status === 'warning',
        [styles.info]: status === 'info',
      })}
      style={style}
      role='alert'
      aria-label={`${status} alert`}
    >
      {getIcon(status)}
      <Text size='16' fontWeight={4}>
        {description}
      </Text>
      {
        <div className={styles.buttonContainer}>
          {showButton && buttonLabel && (
            <Button
              onClick={() => {
                onClick && onClick();
                removeToast(id);
              }}
              size='md'
              variant='transparent'
            >
              {buttonLabel}
            </Button>
          )}
          {/* Place the close button at the end for better aria hierarchy*/}
          {dismissible && (
            <IconButton
              aria-label='Close'
              onClick={() => removeToast(id)}
              size='sm'
              iconClassName={styles.closeButton}
            >
              <IconX color={'white'} size={16} />
            </IconButton>
          )}
        </div>
      }
    </dialog>
  );
}

function getIcon(status: ToastStatus) {
  switch (status) {
    case 'success':
      return <IconCircleCheckFilled size='16' className={styles.toastIcon} />;
    case 'error':
      return <IconAlertTriangleFilled size='16' className={styles.toastIcon} />;
    case 'warning':
      return <IconAlertTriangleFilled size='16' className={styles.toastIcon} />;
    case 'info':
      return <IconInfoCircleFilled size='16' className={styles.toastIcon} />;
    case 'default':
      return null;
  }
}
