import { useEffect, useMemo, useRef, useState } from 'react';

import { IconArrowLeft, IconChevronRight, IconX, IconZoom } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { FixedSizeList } from 'react-window';
import Divider from 'Sparky/Divider';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import InteractiveRow from 'Sparky/InteractiveRow';
import Repeat from 'Sparky/Repeat';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import TextInput from 'Sparky/TextInput';

import useDealerList from 'api/useDealerList/useDealerList';
import DealerFavorites from 'components/DealerSelect/components/DealerFavorites/DealerFavorites';
import DealerSelectList, {
  DealerListItem,
} from 'components/DealerSelect/components/DealerSelectList/DealerSelectList';
import { DealerSelectListItemSkeleton } from 'components/DealerSelect/components/DealerSelectListItem/DealerSelectListItem';
import { Dealer } from 'types/Dealer';

import styles from './DealerSelect.module.scss';

interface DealerTypeData {
  name: string;
  iconChar: string;
  color: string;
}

export const DEALER_TYPES: Record<string, DealerTypeData> = {
  paying: {
    name: 'Active',
    iconChar: 'A',
    color: COLOR.RED_1,
  },
  paused: {
    name: 'Paused',
    iconChar: 'P',
    color: COLOR.BLUE_2,
  },
  trial: {
    name: 'Trial',
    iconChar: 'T',
    color: '#2CAD36',
  },
  vvo: {
    name: 'VVO',
    iconChar: 'V',
    color: COLOR.CHART_ORANGE_2,
  },
  cancelled: {
    name: 'Cancelled',
    iconChar: 'C',
    color: COLOR.NEUTRAL_5,
  },
  prospect: {
    name: 'Prospects',
    iconChar: 'P',
    color: 'FFF',
  },
  none: {
    name: 'Non-customer',
    iconChar: 'N',
    color: 'FFF',
  },
};

type DealerSelectState =
  | ''
  | 'active'
  | 'cancelled'
  | 'paused'
  | 'trial'
  | 'vvo'
  | 'searching'
  | 'prospect'
  | 'none';

export const starredDealerListAtom = atomWithStorage<number[]>('starred-dealer-list', []);

/** Dealer select panel */
export default function DealerSelect({ className }: { className?: string }) {
  const [searchText, setSearchText] = useState('');
  const [selectedFilter] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const [starredDealerList, setStarredDealerList] = useAtom(starredDealerListAtom);
  const [state, setState] = useState<DealerSelectState>('');

  const res = useDealerList();
  const dealerList = res.data?.dealers ?? [];
  const favorites = useMemo(() => res.data?.favorites ?? [], [res.data?.favorites]);
  const listRef = useRef<FixedSizeList | null>(null);
  const containerRef = useRef(null);
  const isLoading = res.isLoading;

  useEffect(() => {
    if (!isLoading) {
      setStarredDealerList(favorites);
    }
  }, [isLoading, favorites]);

  useEffect(() => {
    if (searchText.length > 0) {
      if (state !== 'searching') {
        setState('searching');
      }
    } else {
      setState('');
    }
  }, [searchText]);

  // Get items that match searchText
  const filteredList = useMemo(() => {
    return dealerList
      .filter(
        (dealer) =>
          dealer.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
          dealer.id.toString().indexOf(searchText) !== -1
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [searchText, dealerList]);

  const {
    filteredDealerList,
    groupedByType: dealerMap,
    starredDealers,
  } = getDealerSelectList(DEALER_TYPES, filteredList, selectedFilter, starredDealerList);

  if (isLoading) {
    return (
      <Repeat times={12}>
        <DealerSelectListItemSkeleton itemSize={45} />
      </Repeat>
    );
  }

  return (
    <div className={classnames([styles.dealerSelect, className])} role='menuitem'>
      <Flex alignItems='center' rowGap='8px' padding='16px 16px 8px '>
        {state !== '' && state !== 'searching' && (
          <IconArrowLeft
            className={styles.backIcon}
            color={COLOR.SPARKY_NAVY_400}
            onClick={() => {
              switch (state) {
                case 'active':
                case 'prospect':
                case 'none':
                  setState('');
                  break;
                case 'paused':
                case 'cancelled':
                case 'trial':
                case 'vvo':
                  setState('active');
                  break;
              }
            }}
          />
        )}
        <TextInput
          autoComplete='off'
          autoFocus
          id={'search'}
          className={styles.search}
          aria-label='Search'
          value={searchText}
          placeholder='Search Dealers'
          onChange={(e) => setSearchText(e.target.value)}
          onBlur={() => setSelectedIndex(undefined)}
          onClear={() => setSearchText('')}
          aria-activedescendant={
            selectedIndex ? `dealer-opt-${filteredDealerList[selectedIndex]?.id}` : undefined
          }
          leftElement={<IconZoom size={20} color={COLOR.SPARKY_GREY_400} />}
          rightElement={
            <IconButton
              aria-label='clear search'
              onClick={(e) => {
                e.stopPropagation();
                setSearchText('');
              }}
              className={styles.closeButton}
            >
              <IconX size={14} color={COLOR.SPARKY_GREY_400} />
            </IconButton>
          }
        />
      </Flex>
      {state === 'searching' && (
        <div className={styles.list} ref={containerRef}>
          <DealerSelectList
            dealerList={filteredDealerList}
            searchText={searchText}
            selectedIndex={selectedIndex}
            isError={res.isError || (!res.isLoading && !res.data?.dealers)}
            isLoading={res.isLoading}
            containerRef={containerRef}
            listRef={listRef}
          />
        </div>
      )}
      {/* Default State */}
      {state === '' && (
        <>
          <DealerFavorites
            favoritesList={starredDealers}
            selectedIndex={selectedIndex}
            onButtonClick={() => setState('active')}
          />
          <Divider />
          <div className={styles.dealerCategoryContainer}>
            <InteractiveRow
              size='large'
              label='All dealers'
              handleClick={() => {
                setState('active');
              }}
              iconSuffix={<IconChevronRight color={COLOR.SPARKY_NAVY_400} />}
            />
            {dealerMap.prospect && dealerMap.prospect.length > 0 && (
              <InteractiveRow
                size='large'
                label='Prospects'
                handleClick={() => {
                  setState('prospect');
                }}
                iconSuffix={<IconChevronRight color={COLOR.SPARKY_NAVY_400} />}
              />
            )}
            {dealerMap.none && dealerMap.none.length > 0 && (
              <InteractiveRow
                size='large'
                label='Non-customer'
                handleClick={() => {
                  setState('none');
                }}
                iconSuffix={<IconChevronRight color={COLOR.SPARKY_NAVY_400} />}
              />
            )}
          </div>
        </>
      )}
      {/* Active State */}
      {state === 'active' && (
        <>
          <Text className={styles.dealerHeader} type='label-01' color='secondary'>
            Active
          </Text>
          <div className={styles.list} ref={containerRef}>
            <DealerSelectList
              dealerList={dealerMap.paying}
              searchText={searchText}
              listRef={listRef}
              containerRef={containerRef}
              selectedIndex={selectedIndex}
              isError={res.isError || (!res.isLoading && !res.data?.dealers)}
              isLoading={res.isLoading}
            />
          </div>
          <Divider />
          <div className={styles.dealerCategoryContainer}>
            {dealerMap.cancelled && dealerMap.cancelled.length > 0 && (
              <InteractiveRow
                size='large'
                label='Cancelled'
                handleClick={() => {
                  setState('cancelled');
                }}
                iconSuffix={<IconChevronRight color={COLOR.SPARKY_NAVY_400} />}
              />
            )}
            {dealerMap.trial && dealerMap.trial.length > 0 && (
              <InteractiveRow
                size='large'
                label='Trial'
                handleClick={() => {
                  setState('trial');
                }}
                iconSuffix={<IconChevronRight color={COLOR.SPARKY_NAVY_400} />}
              />
            )}
            {dealerMap.vvo && dealerMap.vvo.length > 0 && (
              <InteractiveRow
                size='large'
                label='VVO'
                handleClick={() => {
                  setState('vvo');
                }}
                iconSuffix={<IconChevronRight color={COLOR.SPARKY_NAVY_400} />}
              />
            )}
            {dealerMap.paused && dealerMap.paused.length > 0 && (
              <InteractiveRow
                size='large'
                label='Paused'
                handleClick={() => {
                  setState('paused');
                }}
                iconSuffix={<IconChevronRight color={COLOR.SPARKY_NAVY_400} />}
              />
            )}
          </div>
        </>
      )}
      {/* Paused/Cancelled State */}
      {(state === 'paused' ||
        state === 'cancelled' ||
        state === 'vvo' ||
        state === 'trial' ||
        state === 'prospect' ||
        state === 'none') && (
        <>
          <Text className={styles.dealerHeader} type='label-01' color='secondary'>
            {DEALER_TYPES[state].name}
          </Text>
          <div className={styles.list} ref={containerRef}>
            <DealerSelectList
              dealerList={dealerMap[state]}
              searchText={searchText}
              listRef={listRef}
              selectedIndex={selectedIndex}
              isError={res.isError || (!res.isLoading && !res.data?.dealers)}
              isLoading={res.isLoading}
              containerRef={containerRef}
            />
          </div>

          <Divider />
          <div className={styles.dealerCategoryContainer}>
            <InteractiveRow
              size='large'
              label='Main menu'
              handleClick={() => {
                setState('');
              }}
              iconSuffix={<IconChevronRight color={COLOR.SPARKY_NAVY_400} />}
            />
          </div>
        </>
      )}
    </div>
  );
}

/**
 * react-window (FixedSizeList in DealerSelectList) needs a single list,
 *  so we need to combine headers and Dealer items into one array
 */
export function getDealerSelectList(
  types: Record<string, DealerTypeData>,
  dealers: Dealer[],
  typeFilter: string,
  starredDealerList: number[]
) {
  let groupedByType: Record<string, Dealer[]> = {};
  Object.keys(types)
    .filter((type) => typeFilter === '' || type === typeFilter)
    .forEach((key) => {
      groupedByType[key] = [];
    });
  dealers.forEach((dealer) => {
    let group = groupedByType[dealer.type.toLowerCase()];
    if (group) {
      group.push(dealer);
    }
  });

  let filteredDealerList: DealerListItem[] = [];
  Object.entries(groupedByType).forEach(([key, value]) => {
    if (value.length > 0) {
      filteredDealerList.push({
        isHeader: true,
        name: types[key].name,
      });
      filteredDealerList = filteredDealerList.concat(value);
    }
  });
  let starredDealers;
  if (starredDealerList.length > 0) {
    starredDealers = filteredDealerList.filter(
      (dealer) => dealer.id && starredDealerList.includes(dealer.id)
    );
  }

  return { filteredDealerList, groupedByType, starredDealers };
}
