import { useMediaQuery } from '../../hooks/index';

export enum SIZE {
  UNKNOWN,
  XS, // <  36em   -> 576px
  SM, // <  48em   -> 768px
  MD, // <  62em   -> 992px
  LG, // <  75em   -> 1200px
  XL, // <  100em  -> 1600px
  XXL, // >= 100em
}

/**
 * Hook that returns the current screen size based on device breakpoints.
 */
export default function useScreenSize() {
  const isXXL = useMediaQuery('(min-width: 100em)');
  const isXL = useMediaQuery('(min-width: 75em)');
  const isLG = useMediaQuery('(min-width: 62em)');
  const isMD = useMediaQuery('(min-width: 48em)');
  const isSM = useMediaQuery('(min-width: 36em)');
  const isXS = useMediaQuery('(min-width: 0)');

  if (isXXL) {
    return SIZE.XXL;
  } else if (isXL) {
    return SIZE.XL;
  } else if (isLG) {
    return SIZE.LG;
  } else if (isMD) {
    return SIZE.MD;
  } else if (isSM) {
    return SIZE.SM;
  } else if (isXS) {
    return SIZE.XS;
  } else {
    return SIZE.UNKNOWN;
  }
}
