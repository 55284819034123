export const CircleIcon = ({ color }: { color: string }) => (
  <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='15' height='15' rx='7.5' fill={color} />
  </svg>
);

export const PurpleCircleIconTooltip = () => (
  <svg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='1' width='9' height='9' rx='4.5' fill='#A90DF8' />
    <rect x='0.5' y='1' width='9' height='9' rx='4.5' stroke='#E5B6FD' />
  </svg>
);

export const GreenCircleIconTooltip = () => (
  <svg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='1' width='9' height='9' rx='4.5' fill='#189F51' />
    <rect x='0.5' y='1' width='9' height='9' rx='4.5' stroke='#DCF1E5' />
  </svg>
);

export const CircleIconWithBorder = ({ color }: { color: string }) => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d_887_200604)'>
      <path
        d='M16.9173 8.99992C16.9173 12.2676 14.2683 14.9166 11.0007 14.9166C7.73297 14.9166 5.08398 12.2676 5.08398 8.99992C5.08398 5.73223 7.73297 3.08325 11.0007 3.08325C14.2683 3.08325 16.9173 5.73223 16.9173 8.99992Z'
        fill={color}
        stroke='white'
        strokeWidth='1.5'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_887_200604'
        x='-1'
        y='-1'
        width='24'
        height='24'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_887_200604' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_887_200604'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
