import { InventoryItem, ProbabilityOfSaleKeys } from 'types/InventoryItem';

export function getCurrentProbabilityCounts(
  data?: InventoryItem[]
): Record<ProbabilityOfSaleKeys, number> {
  let currentProbabilityCounts: Record<ProbabilityOfSaleKeys, number> = {
    highlyLikely: 0,
    likely: 0,
    neutral: 0,
    unlikely: 0,
    highlyUnlikely: 0,
  };

  data?.forEach((inventoryItem) => {
    switch (inventoryItem.vinPath?.currentProbabilityOfSale) {
      case 'highlyLikely':
        currentProbabilityCounts.highlyLikely++;
        break;
      case 'likely':
        currentProbabilityCounts.likely++;
        break;
      case 'neutral':
        currentProbabilityCounts.neutral++;
        break;
      case 'unlikely':
        currentProbabilityCounts.unlikely++;
        break;
      case 'highlyUnlikely':
        currentProbabilityCounts.highlyUnlikely++;
        break;
      default:
        break;
    }
  });

  return currentProbabilityCounts;
}
