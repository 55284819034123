import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { vinPathPriceDataSchema } from 'pages/Inventory/VinDetailSidebar/api/useVinPathPriceData/vinPathPriceDataSchema';
import { userAtom } from 'store';
import { VinPathPriceData } from 'types/VinPath';
import { assertIsDefined } from 'util/assert';

/**
 * useVinPathPriceData
 * @param prop
 */

interface FetchVinPathPriceDataInterface {
  token?: string;
  dealerId?: number;
  vin: string;
}

async function fetchVinPathPriceData({ token, dealerId, vin }: FetchVinPathPriceDataInterface) {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  const res = await get<VinPathPriceData>(`${ENDPOINTS.vinPathPriceData}/${dealerId}/${vin}`, {
    headers: { token },
  });

  return vinPathPriceDataSchema.parse(res);
}

export default function useVinPathPriceData(vin: string) {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { vinPathPriceKey: queryKey } = useQueryKeys({ vin });

  return useQuery(queryKey, () => fetchVinPathPriceData({ token: user?.token, dealerId, vin }));
}
