import { ProbabilityOfSaleKeys } from 'types/InventoryItem';

export function getProbabilityChipLabel(probability: ProbabilityOfSaleKeys | undefined): string {
  switch (probability) {
    case 'highlyUnlikely':
      return 'Highly unlikely to sell';
    case 'unlikely':
      return 'Unlikely to sell';
    case 'neutral':
      return 'Neutral likelihood to sell';
    case 'likely':
      return 'Likely to sell';
    case 'highlyLikely':
      return 'Highly likely to sell';
    default:
      return '';
  }
}
