import React, { useEffect, useState } from 'react';

import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import { Location, useLocation } from 'react-router-dom';
import Text from 'Sparky/Text';

import { sidebarAtom } from 'components/Sidebar/Sidebar';

import styles from './SidebarLinks.module.scss';

interface SidebarGroupProps {
  header: string;
  icon?: React.ReactElement;
  children: React.ReactNode;
  /** Custom matcher, when true the group header would be styled as active*/
  customIsActive?: (location: Location) => boolean;
}
export default function SidebarGroup({
  header,
  icon,
  children,
  customIsActive,
}: SidebarGroupProps) {
  const [expandedSidebar, setExpandedSidebar] = useAtom(sidebarAtom);
  const [expandedGroup, setExpandedGroup] = useState(expandedSidebar);

  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const defaultIsActive = (location: Location) =>
      location.pathname.split('/')[1] === header.toLowerCase();
    const active = customIsActive ?? defaultIsActive;

    if (!expandedSidebar) {
      // Collapse group when sidebar collapses
      setExpandedGroup(false);

      if (active(location)) {
        return setIsActive(true);
      }
    } else if (expandedSidebar && active(location)) {
      // If a subLink is selected, expand group when expanding sidebar
      setExpandedGroup(true);
    }
    return setIsActive(false);
  }, [expandedSidebar, location, header, customIsActive]);

  const Arrow = expandedGroup ? IconChevronUp : IconChevronDown;

  return (
    <div>
      <button
        className={classnames([styles.link, styles.groupHeader, isActive ? styles.active : ''])}
        onClick={() => {
          setExpandedGroup(!expandedGroup);
          if (!expandedSidebar) {
            // If sidebar is collapsed, expand sidebar & group
            setExpandedSidebar(true);
            setExpandedGroup(true);
          }
        }}
      >
        {expandedSidebar && (
          <>
            <Text size='14' fontWeight={4} className={styles.linkLabel}>
              {header}
            </Text>

            <Text size='20' className={styles.arrow}>
              <Arrow size='16' color='#888888' />
            </Text>
          </>
        )}
      </button>
      {expandedGroup && <div className={styles.expandedChildContainer}>{children}</div>}
    </div>
  );
}
