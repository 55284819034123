import { ReactElement, useState } from 'react';

import { Link, Location, useLocation } from 'react-router-dom';
import Text from 'Sparky/Text';

import DropdownButton from 'components/DropdownButton/DropdownButton';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { FeatureFlag } from 'types/FeatureFlags';

import styles from './InventoryNavDropdown.module.scss';

/**
 * InventoryNavDropdown
 */
export default function InventoryNavDropdown() {
  const isActive = (location: Location) => location.pathname.includes('inventory');
  const location = useLocation();
  const [openDropdownPopover, setOpenDropdownPopover] = useState(false);
  const showAcquisition = useFeatureFlag(FeatureFlag.ENABLE_ACQUISITION_PAGE);
  const showVinPath = useFeatureFlag(FeatureFlag.ENABLE_VIN_PATH);

  return (
    <div className={styles.inventoryNavDropdownWrapper}>
      <DropdownButton
        active={isActive(location)}
        title='Inventory'
        isOpen={openDropdownPopover}
        setIsOpen={setOpenDropdownPopover}
      >
        <div className={styles.inventoryNavDropdown}>
          <DropdownLink
            title='Live Inventory'
            location='live'
            onLinkClick={() => setOpenDropdownPopover(false)}
          />
          <DropdownLink
            title='Sold Inventory'
            location='sold'
            onLinkClick={() => setOpenDropdownPopover(false)}
          />
          {showAcquisition && (
            <DropdownLink
              title='Private Seller Listings'
              location='private_seller_listings'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}
          {showVinPath && (
            <DropdownLink
              title='Vin Path®'
              location='vin_path'
              onLinkClick={() => setOpenDropdownPopover(false)}
            />
          )}
        </div>
      </DropdownButton>
    </div>
  );
}

interface DropdownLinkProps {
  title: string;
  location: string;

  onLinkClick(): void;
}

function DropdownLink({ title, location, onLinkClick }: DropdownLinkProps): ReactElement {
  const [dealerId] = useSelectedDealerId();
  return (
    <Link
      className={styles.inventoryLink}
      to={`inventory/${location}${dealerId ? `?dealerId=${dealerId}` : ''}`}
      onClick={() => {
        onLinkClick();
      }}
    >
      <Text
        className={styles.inventoryLinkText}
        fontWeight={window.location.toString().includes(location) ? 7 : 4}
      >
        {title}
      </Text>
    </Link>
  );
}
