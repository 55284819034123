export const LotlinxXIcon = ({ size = '16' }: { size?: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M1.33325 1.33331H5.07709L9.3858 7.55202L4.81437 14.6666H1.33325L5.87841 7.55202L1.33325 1.33331Z'
      fill='#F41424'
    />
    <path
      d='M9.33336 3.81026L11.0805 6.35308L14.6667 1.33331H11.0805L10.2069 2.57178L9.33336 3.81026Z'
      fill='black'
    />
    <path
      d='M11.0805 8.92225L9.22827 11.7549L11.0805 14.6666H14.6667L11.0805 8.92225Z'
      fill='black'
    />
  </svg>
);
