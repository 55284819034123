import { ReactNode } from 'react';

import classnames from 'classnames';
import Spacer from 'Sparky/Spacer';
import { LotlinxChevron } from 'Sparky/SVGs/LotlinxChevron';
import Text from 'Sparky/Text';

import { useNavigateKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';

import styles from './Breadcrumbs.module.scss';
import Chip from '../Chip';

export interface PageTitle {
  title: string;
  link?: string;
}

export interface BreadcrumbsProps {
  pageTitles: PageTitle[];
  className?: string;
  innerClassName?: string;
  children?: ReactNode;
  maxWidth?: number;
  isBeta?: boolean;
}

/**
 * Breadcrumbs
 */
export default function Breadcrumbs({
  pageTitles,
  className,
  children,
  maxWidth,
  isBeta,
  innerClassName,
}: BreadcrumbsProps) {
  const navigate = useNavigateKeepParams();

  return (
    <div className={classnames([styles.breadcrumbs, className])}>
      <div
        className={classnames([styles.maxWidthContainer, innerClassName])}
        style={{ maxWidth: maxWidth ? `${maxWidth}px` : '100%' }}
      >
        {pageTitles.map((pageTitle, index) => {
          if (index === pageTitles.length - 1) {
            return (
              <Text
                key={index}
                color='primary'
                fontWeight={7}
                onClick={() => {
                  if (pageTitle?.link) {
                    navigate(pageTitle.link);
                  }
                }}
              >
                {pageTitle.title}
              </Text>
            );
          } else {
            return (
              <div key={index} className={styles.subTitleContainer}>
                <Text
                  color='secondary'
                  fontWeight={5}
                  onClick={() => {
                    if (pageTitle?.link) {
                      navigate(pageTitle.link);
                    }
                  }}
                  className={classnames([
                    styles.subTitle,
                    pageTitle?.link && styles.subTitleUnderline,
                  ])}
                >
                  {pageTitle.title}
                </Text>
                <div className={styles.chevron}>
                  <LotlinxChevron />
                </div>
              </div>
            );
          }
        })}
        {isBeta && (
          <Chip variant='outline' color='orange' className={styles.betaIcon}>
            BETA
          </Chip>
        )}
        <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
        {children}
      </div>
    </div>
  );
}
