import { useEffect, useState } from 'react';

import chroma from 'chroma-js';
import { latLngToCell } from 'h3-js';
import { atom, useAtom } from 'jotai';
import Select, { GroupBase, MultiValue, OptionProps, OptionsOrGroups } from 'react-select';
import Accordion from 'Sparky/Accordion';
import Message from 'Sparky/Message';
import { Tabs, TabsList, Tab, TabContent } from 'Sparky/Tabs';
import Text from 'Sparky/Text';
import TextInput from 'Sparky/TextInput';

import TooltipIcon from 'components/TooltipIcon';
import { DealerData } from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';
import DealerListItem from 'pages/Settings/LocalMarketSettings/DealerSidebar/DealerListItem';
import {
  ColourOption,
  colourStyles,
} from 'pages/Settings/LocalMarketSettings/DealerSidebar/multiSelect.config';
import { CircleIcon } from 'pages/Settings/LocalMarketSettings/icons/CircleIcon';
import { RESOLUTION } from 'pages/Settings/LocalMarketSettings/MarketMap';

import styles from './DealerSidebar.module.scss';

export interface DealerSidebarProps {
  dealerList?: DealerData[];
  selectedHexs: string[];
  newKeyDealers: number[];
  usedKeyDealers: number[];
  setNewKeyDealers: (keyDealers: number[]) => void;
  setUsedKeyDealers: (keyDealers: number[]) => void;
  originalStates: { [key: string]: string[] | number[] };
  setHighlightDealer: (cell: number | undefined) => void;
}

export const activeDealerTypeAtom = atom('new');

/**
 * DealerSidebar
 */

export default function DealerSidebar({
  dealerList,
  selectedHexs,
  newKeyDealers,
  usedKeyDealers,
  setNewKeyDealers,
  setUsedKeyDealers,
  originalStates,
  setHighlightDealer,
}: DealerSidebarProps) {
  const [selectNewDealers, setSelectNewDealers] = useState<MultiValue<ColourOption>>([]);
  const [selectUsedDealers, setSelectUsedDealers] = useState<MultiValue<ColourOption>>([]);
  const [activeTab, setActiveTab] = useAtom(activeDealerTypeAtom);

  useEffect(() => {
    const dealerList = selectNewDealers.map((dealer) => dealer.id) ?? [];
    setNewKeyDealers(dealerList as number[]);
  }, []);

  useEffect(() => {
    const dealerList = selectNewDealers.map((dealer) => dealer.id) ?? [];
    setNewKeyDealers(dealerList as number[]);
  }, [selectNewDealers]);

  useEffect(() => {
    const dealerList = selectUsedDealers.map((dealer) => dealer.id) ?? [];
    setUsedKeyDealers(dealerList as number[]);
  }, [selectUsedDealers]);

  return originalStates.newSelectedHexs.length ? (
    <div className={styles.dealersidebar}>
      <Tabs
        defaultValue='new'
        value={activeTab}
        className={styles.tabs}
        onValueChange={setActiveTab}
      >
        <TabsList aria-label='tabs example'>
          <Tab value='new'>New</Tab>
          <Tab value='used'>Used</Tab>
        </TabsList>
        <div className={styles.tabContentContainer}>
          {/*  NEW */}
          <TabContent value='new'>
            <DealerTypeTab
              dealerList={dealerList?.filter((dealer) => dealer.dealerType === 'franchise')}
              selectedHexs={selectedHexs}
              keyDealers={selectNewDealers}
              keyDealersList={newKeyDealers}
              setKeyDealers={setSelectNewDealers}
              intitalKeyDealers={originalStates.keyNewDealers as number[]}
              setHighlightDealer={setHighlightDealer}
            />
          </TabContent>

          {/* USED */}
          <TabContent value='used'>
            <DealerTypeTab
              dealerList={dealerList}
              selectedHexs={selectedHexs}
              keyDealersList={usedKeyDealers}
              keyDealers={selectUsedDealers}
              setKeyDealers={setSelectUsedDealers}
              intitalKeyDealers={originalStates.keyUsedDealers as number[]}
              setHighlightDealer={setHighlightDealer}
            />
          </TabContent>
        </div>
      </Tabs>
    </div>
  ) : (
    <></>
  );
}

function DealerTypeTab({
  dealerList,
  selectedHexs,
  keyDealers,
  keyDealersList,
  setKeyDealers,
  intitalKeyDealers,
  setHighlightDealer,
}: {
  dealerList?: DealerData[] | undefined;
  selectedHexs: string[];
  keyDealers: MultiValue<ColourOption>;
  keyDealersList: number[];
  intitalKeyDealers: number[];
  setKeyDealers: React.Dispatch<React.SetStateAction<MultiValue<ColourOption>>>;
  setHighlightDealer: (cell: number | undefined) => void;
}) {
  const [activeKey, setActiveKey] = useState<string | undefined>('in-market');
  const [filterText, setFilterText] = useState<string>('');

  const colourOptions: readonly ColourOption[] = generateOptions();

  function buildDealerList() {
    const inMarketDealers = dealerList?.filter((dealer) =>
      selectedHexs.includes(latLngToCell(dealer.location.lat, dealer.location.lng, RESOLUTION))
    );

    const nonInMarketDealers = dealerList?.filter(
      (dealer) =>
        !selectedHexs.includes(latLngToCell(dealer.location.lat, dealer.location.lng, RESOLUTION))
    );

    return {
      inMarketDealers: inMarketDealers as DealerData[],
      nonInMarketDealers: nonInMarketDealers as DealerData[],
    };
  }

  const refinedDealerList = buildDealerList();

  useEffect(() => {
    setKeyDealers(generateDefaultsOptions(intitalKeyDealers));
  }, []);

  useEffect(() => {
    //* This crops the list when validation removes key dealers based on dealer location
    if (keyDealersList.length !== keyDealers.length) {
      setKeyDealers(generateDefaultsOptions(keyDealersList));
    }
  }, [keyDealersList]);

  function generateDefaultsOptions(intitalKeyDealers: number[]) {
    const dealerListData: ColourOption[] = [];

    if (dealerList) {
      dealerList?.forEach((dealer) => {
        if (intitalKeyDealers.includes(dealer.dealerId)) {
          dealerListData.push({
            value: `${dealer.dealerId}`,
            label: dealer.dealerName,
            color: dealer.dealerType === 'franchise' ? '#189F51' : '#A90DF8',
            id: dealer.dealerId,
          });
        }
      });
    }

    return dealerListData as readonly ColourOption[];
  }

  function generateOptions() {
    const optionList: { value: string; label: string; color: string; id: number }[] = [];

    dealerList?.forEach((dealer) => {
      const dealerHexCode = latLngToCell(
        dealer?.location.lat ?? 0,
        dealer?.location.lng ?? 0,
        RESOLUTION
      );

      if (selectedHexs.includes(dealerHexCode)) {
        optionList.push({
          value: `${dealer.dealerId}`,
          label: dealer.dealerName,
          color: dealer.dealerType === 'franchise' ? '#189F51' : '#A90DF8',
          id: dealer.dealerId,
        });
      }
    });

    return optionList;
  }

  useEffect(() => {
    setFilterText('');
  }, [setActiveKey]);

  const Option = (props: OptionProps<ColourOption>) => {
    const {
      className,
      cx,
      isDisabled,
      isFocused,
      isSelected,
      innerRef,
      innerProps,
      data,
      options,
    } = props;
    const [isHovered, setIsHovered] = useState(false);
    const label = isHovered ? data.label : limitCharacters(data.label, 24);
    const color = chroma(data.color);

    interface Value {
      value: string;
    }

    const getFirstOption = (options: OptionsOrGroups<ColourOption, GroupBase<ColourOption>>) => {
      const keyDealerList = keyDealers.map((dealer) => dealer.id);
      return options.find((option) => {
        const currentOption = option as ColourOption | Value;
        return !keyDealerList.includes(parseInt(currentOption.value));
      });
    };

    const firstOption = getFirstOption(options) as ColourOption | Value;

    const isFirstChild = firstOption.value === data.value;

    const element = (
      <div
        ref={innerRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={cx(
          {
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected,
          },
          className,
          styles.option
        )}
        {...innerProps}
      >
        <CircleIcon color={color.alpha(0.5).css()} />
        <Text size='14'>{label}</Text>
      </div>
    );

    return (
      <>
        {isFirstChild && keyDealers.length > 4 && (
          <div className={styles.optionsWarning}>
            <Message category='danger' heading='5/5 selected' dismissable={false}>
              Maximum selections made. Please remove a selection to choose another.
            </Message>
          </div>
        )}
        {element}
      </>
    );
  };
  return (
    <>
      <Text color='secondary' fontWeight={6} className={styles.keyDealerTitle}>
        Key competitors
        <TooltipIcon
          side='right'
          tipText='Select up to 5 dealerships to enable direct comparisons.'
        />
      </Text>

      <div className={styles.dealerSelect}>
        <Select
          closeMenuOnSelect={false}
          defaultValue={keyDealers}
          isMulti
          isOptionDisabled={() => keyDealers?.length > 4}
          options={colourOptions}
          //* Note: There is a ts error on compiling this code, it works as desired... this implementation comes from the react-select documentation
          // @ts-ignore
          components={{ Option }}
          styles={colourStyles}
          value={keyDealers}
          onChange={(e: MultiValue<ColourOption>) => setKeyDealers(e)}
        />
        <Text color='tertiary' size='12'>
          Select up to 5 dealers as your key competitors
        </Text>
      </div>

      {/* IN-MARKET DEALER LIST */}
      <Accordion
        title='In-market dealers'
        tooltipText='A collection of dealers that have been selected for your local market.'
        isExpanded={activeKey === 'in-market'}
        handleClick={() =>
          activeKey === 'in-market' ? setActiveKey(undefined) : setActiveKey('in-market')
        }
      >
        <TextInput
          id='dealer-filter'
          className={styles.dealerFilter}
          placeholder='Search dealerships'
          onChange={(e) => setFilterText(e.target.value)}
          autoComplete={'off'}
        />
        <div className={styles.dealerList}>
          {refinedDealerList.inMarketDealers?.map((dealer) => {
            const keyDealerSearch = keyDealers.find((keyDealer) => {
              return keyDealer.id === dealer.dealerId;
            });
            if (dealer.dealerName.toLowerCase().includes(filterText.toLowerCase())) {
              return (
                <DealerListItem
                  isKeyDealer={!!keyDealerSearch}
                  dealer={dealer}
                  key={dealer.dealerId}
                  setHighlightDealer={setHighlightDealer}
                />
              );
            }
            return null;
          })}
        </div>
      </Accordion>

      {/* OUT OF MARKET DEALER LIST */}
      <Accordion
        title='Dealers not in-market'
        tooltipText='Dealerships outside the boundary of your local market.'
        isExpanded={activeKey === 'not-in-market'}
        handleClick={() =>
          activeKey === 'not-in-market' ? setActiveKey(undefined) : setActiveKey('not-in-market')
        }
      >
        <TextInput
          id='dealer-filter'
          className={styles.dealerFilter}
          onChange={(e) => setFilterText(e.target.value)}
          placeholder='Search dealerships'
          autoComplete={'off'}
        />
        <div className={styles.dealerList}>
          {refinedDealerList.nonInMarketDealers?.map((dealer) => {
            const keyDealerSearch = keyDealers.find((keyDealer) => {
              return keyDealer.id === dealer.dealerId;
            });
            if (dealer.dealerName.toLowerCase().includes(filterText.toLowerCase())) {
              return (
                <DealerListItem
                  isKeyDealer={!!keyDealerSearch}
                  dealer={dealer}
                  key={dealer.dealerId}
                  setHighlightDealer={setHighlightDealer}
                />
              );
            }
            return null;
          })}
        </div>
      </Accordion>
    </>
  );
}

function limitCharacters(val: string, max: number) {
  if (val.length < max) {
    return val;
  }
  return `${val.substring(0, max)}...`;
}
