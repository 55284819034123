import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import produce from 'immer';
import { cloneDeep } from 'lodash';
import Accordion from 'Sparky/Accordion';
import Button from 'Sparky/Button';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';

import { ShowAcquisitionSideBar } from 'pages/Inventory/Acquisition';
import {
  AcquisitionTableColumnDefs,
  AcquisitionTableColumnWithGetterDefs,
} from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTableColumnDefs';
import {
  AcquisitionTableColumnKey,
  AcquisitionTableWithValueColumnKey,
} from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTableConfigDefs';
import ColumnOptions from 'pages/Inventory/InventoryPage/CustomizeSidebar/ColumnOptions';
import {
  InventoryTableColumnKey,
  InventoryTableGroupKey,
  InventoryTableWithValueColumnGroups,
  InventoryTableWithValueColumnKey,
} from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { SortColumn } from 'pages/Inventory/InventoryPage/InventoryTable';
import {
  InventoryTableColumnDef,
  InventoryTableWithValueColumnDef,
} from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';
import SortByColumns from 'pages/Inventory/InventoryPage/SortInventory/SortByColumns';

import styles from './SortAcquisition.module.scss';

export interface SortAcquisitionProps {
  isLoading: boolean;
  sortColumns: SortColumn[];
  acquisitionTableConfig: AcquisitionTableColumnKey[];
  showAcquisitionSidebar: ShowAcquisitionSideBar;

  setShowAcquisitionSidebar(value: ShowAcquisitionSideBar): void;

  updateSortColumns(sortColumns: SortColumn[]): void;
}

/**
 * SortAcquisition
 */
export default function SortAcquisition({
  isLoading,
  sortColumns,
  acquisitionTableConfig,
  showAcquisitionSidebar,
  setShowAcquisitionSidebar,
  updateSortColumns,
}: SortAcquisitionProps) {
  const activeColumns = new Set(sortColumns.map((sortColumn) => sortColumn.id));
  const allColumns = new Set(
    acquisitionTableConfig.filter((col) => AcquisitionTableColumnDefs[col].flag)
  );
  let groupColumns = cloneDeep(InventoryTableWithValueColumnGroups);

  for (let i in InventoryTableWithValueColumnGroups) {
    groupColumns[i as InventoryTableGroupKey] = InventoryTableWithValueColumnGroups[
      i as InventoryTableGroupKey
    ].filter((col) => allColumns.has(col as AcquisitionTableColumnKey));
  }

  const toggleActiveColumn = (col: AcquisitionTableWithValueColumnKey, isActive: boolean) => {
    if (isActive) {
      updateSortColumns(
        produce(sortColumns, (draft) => draft.filter((sortColumn) => sortColumn.id !== col))
      );
    } else {
      updateSortColumns(
        produce(sortColumns, (draft) => {
          draft.unshift({ id: col, direction: 'asc' });
        })
      );
    }
  };

  const toggleColumnSort = (col: SortColumn) => {
    const index = sortColumns.findIndex((sortColumn) => sortColumn.id === col.id);
    if (index > -1) {
      updateSortColumns(
        produce(sortColumns, (draft) => {
          if (col.direction === 'asc') {
            draft[index].direction = 'desc';
          } else {
            draft[index].direction = 'asc';
          }
          return draft;
        })
      );
    }
  };

  const clearAllColumns = () => {
    updateSortColumns([]);
  };

  const clearGroupColumns = (group: InventoryTableGroupKey) => {
    updateSortColumns(
      produce(sortColumns, (draft) => {
        draft = draft.filter(
          (sortColumn) =>
            !(groupColumns as Record<InventoryTableGroupKey, InventoryTableWithValueColumnKey[]>)[
              group
            ].includes(sortColumn.id)
        );

        return draft;
      })
    );
  };

  return (
    <div
      className={classnames([
        styles.sortAcquisition,
        showAcquisitionSidebar === 'sort' && styles.isShow,
      ])}
    >
      <div className={styles.sortAcquisitionHeader}>
        <Text fontWeight={7}>Sort private seller listings</Text>
        <IconButton
          aria-label='Close sort private seller listings'
          size='sm'
          onClick={() => setShowAcquisitionSidebar('')}
        >
          <IconX size={20} />
        </IconButton>
      </div>
      <div className={styles.sortAcquisitionBody}>
        {Object.keys(sortColumns).length > 0 && (
          <div className={styles.sortColumns}>
            <Accordion
              isLoading={isLoading}
              key='sort by'
              title='Sort by'
              titleSize='14'
              rightElement={
                <Button size='sm' onClick={clearAllColumns}>
                  Clear all sorts
                </Button>
              }
            >
              <SortByColumns
                isLoading={isLoading}
                sortColumns={sortColumns}
                inventoryTableColumnWithGetterDefs={
                  AcquisitionTableColumnWithGetterDefs as Record<
                    InventoryTableWithValueColumnKey,
                    InventoryTableWithValueColumnDef
                  >
                }
                updateSortColumns={updateSortColumns}
                toggleColumnSort={toggleColumnSort}
              />
            </Accordion>
          </div>
        )}
        <div className={styles.columnOptions}>
          <ColumnOptions
            isLoading={isLoading}
            groupColumns={
              groupColumns as Record<InventoryTableGroupKey, AcquisitionTableWithValueColumnKey[]>
            }
            inventoryTableColumnDefs={
              AcquisitionTableColumnDefs as Record<InventoryTableColumnKey, InventoryTableColumnDef>
            }
            activeColumns={activeColumns}
            toggleActiveColumn={toggleActiveColumn}
            clearGroupColumns={clearGroupColumns}
          />
        </div>
      </div>
    </div>
  );
}
