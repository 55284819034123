import { useLayoutEffect, useState } from 'react';

export interface TablePaginationProps<T> {
  data: T[];
  currentPage: number;
  pageSize: number;

  setPageSize(n: number): void;

  nextPage(): void;

  prevPage(): void;

  firstPage(): void;

  lastPage(): void;

  disablePrev: boolean;
  disableNext: boolean;
}

export interface InitialPaginationState {
  page?: number;
  pageSize?: number;
}

/**
 * Handles pagination logic for TableContext
 */
export function usePaginate<T>(
  data?: T[],
  initialState?: InitialPaginationState
): TablePaginationProps<T> {
  const [page, setPage] = useState(initialState?.page ?? 0);
  const [pageSize, setPageSize] = useState(initialState?.pageSize ?? -1);
  const rows = data?.length ?? 0;

  let lastPageIndex: number;
  if (pageSize === -1 || rows <= pageSize) {
    lastPageIndex = 0;
  } else if (rows % pageSize === 0) {
    lastPageIndex = rows / pageSize - 1;
  } else {
    lastPageIndex = Math.floor(rows / pageSize);
  }

  useLayoutEffect(() => {
    if (lastPageIndex < page) {
      setPage(lastPageIndex);
    }
  }, [data?.length, lastPageIndex, page]);

  const nextPage = () => setPage(Math.min(lastPageIndex, page + 1));
  const prevPage = () => setPage(Math.max(0, page - 1));
  const firstPage = () => setPage(0);
  const lastPage = () => setPage(lastPageIndex);

  const updatePageSize = (num: number) => {
    setPageSize(num);
    setPage(0);
  };

  let pageData = data ?? [];
  if (pageSize > 0) {
    pageData = pageData.slice(page * pageSize, page * pageSize + pageSize);
  }

  return {
    data: pageData,
    currentPage: page,
    pageSize,
    setPageSize: updatePageSize,
    nextPage,
    prevPage,
    firstPage,
    lastPage,
    disablePrev: page === 0,
    disableNext: lastPageIndex === 0 || page === lastPageIndex,
  };
}
