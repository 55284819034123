import classnames from 'classnames';
import Text from 'Sparky/Text';

import styles from './ContactUsLink.module.scss';

export interface ContactUsLinkProps {
  className?: string;
}

/**
 * ContactUsLink
 */
export default function ContactUsLink({ className }: ContactUsLinkProps) {
  return (
    <Text
      as='a'
      href='https://www.lotlinx.com/contact/'
      target='_blank'
      rel='noreferrer'
      color='white'
      size='20'
      alignment='center'
      className={classnames([styles.contactUsLink, className])}
    >
      Need help? Contact us here.
    </Text>
  );
}
