export const StarIcon = ({ color }: { color: string }) => {
  return (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='15' height='15' rx='7.5' fill={color} />
      <g clipPath='url(#clip0_887_195566)'>
        <path
          d='M11.1256 6.45268C11.1828 6.49728 11.2259 6.55753 11.2496 6.62612C11.2678 6.6947 11.2662 6.76704 11.245 6.83475C11.2237 6.90246 11.1837 6.96275 11.1296 7.00862L9.58081 8.50862L9.95581 10.6386C9.9692 10.7089 9.96219 10.7816 9.93561 10.848C9.90903 10.9145 9.86399 10.9719 9.80581 11.0136C9.74147 11.0602 9.664 11.0852 9.58456 11.0849C9.52414 11.0851 9.46469 11.0696 9.41206 11.0399L7.49956 10.0349L5.57956 11.0361C5.51798 11.0688 5.44851 11.0836 5.37896 11.079C5.3094 11.0743 5.24252 11.0504 5.18581 11.0099C5.12763 10.9682 5.0826 10.9107 5.05602 10.8443C5.02944 10.7778 5.02243 10.7052 5.03581 10.6349L5.41081 8.50487L3.86206 7.00487C3.81386 6.95677 3.77967 6.89645 3.76315 6.83038C3.74663 6.76432 3.74842 6.695 3.76831 6.62987C3.79005 6.56322 3.83003 6.504 3.88372 6.45893C3.93741 6.41386 4.00266 6.38474 4.07206 6.37487L6.20581 6.05987L7.16206 4.12487C7.19277 4.06147 7.24072 4.008 7.30041 3.97059C7.3601 3.93317 7.42912 3.91333 7.49956 3.91333C7.57001 3.91333 7.63903 3.93317 7.69872 3.97059C7.75841 4.008 7.80636 4.06147 7.83706 4.12487L8.79331 6.06362L10.9271 6.37487C10.9994 6.38106 11.0683 6.40809 11.1256 6.45268Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_887_195566'>
          <rect width='9' height='9' fill='white' transform='translate(3 3)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const StarIconWithBorder = ({ color }: { color: string }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_887_200624)'>
        <rect
          x='5'
          y='3'
          width='13.33'
          height='13.33'
          rx='6.665'
          fill={color}
          shapeRendering='crispEdges'
        />
        <rect
          x='5'
          y='3'
          width='13.33'
          height='13.33'
          rx='6.665'
          stroke='white'
          strokeWidth='1.5'
          shapeRendering='crispEdges'
        />
        <g clipPath='url(#clip0_887_200624)'>
          <path
            d='M14.8867 8.73442C14.9375 8.77405 14.9758 8.82759 14.9969 8.88855C15.0131 8.94949 15.0117 9.01378 14.9928 9.07395C14.9739 9.13412 14.9383 9.1877 14.8902 9.22847L13.5139 10.5615L13.8471 12.4543C13.859 12.5168 13.8528 12.5814 13.8292 12.6404C13.8056 12.6995 13.7656 12.7505 13.7138 12.7876C13.6567 12.829 13.5878 12.8511 13.5172 12.8509C13.4635 12.8511 13.4107 12.8373 13.3639 12.8109L11.6644 11.9178L9.95812 12.8076C9.90339 12.8366 9.84166 12.8498 9.77985 12.8457C9.71804 12.8415 9.6586 12.8203 9.60821 12.7842C9.55651 12.7472 9.51648 12.6961 9.49286 12.6371C9.46924 12.578 9.46301 12.5135 9.47491 12.451L9.80816 10.5581L8.43184 9.22513C8.389 9.18239 8.35861 9.12878 8.34393 9.07007C8.32926 9.01136 8.33084 8.94976 8.34852 8.89188C8.36784 8.83266 8.40337 8.78003 8.45108 8.73997C8.49879 8.69992 8.55678 8.67404 8.61846 8.66527L10.5146 8.38534L11.3644 6.66577C11.3917 6.60943 11.4343 6.56191 11.4874 6.52866C11.5404 6.49542 11.6018 6.47778 11.6644 6.47778C11.727 6.47778 11.7883 6.49542 11.8413 6.52866C11.8944 6.56191 11.937 6.60943 11.9643 6.66577L12.8141 8.38868L14.7103 8.66527C14.7745 8.67077 14.8358 8.69479 14.8867 8.73442Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_887_200624'
          x='0.25'
          y='0.25'
          width='22.8301'
          height='22.8301'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_887_200624' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_887_200624'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_887_200624'>
          <rect width='7.998' height='7.998' fill='white' transform='translate(7.66602 5.66602)' />
        </clipPath>
      </defs>
    </svg>
  );
};
