import { cloneDeep } from 'lodash';

import { FiltersState, SelectState } from 'components/Filters';
import { InventoryItem } from 'types/InventoryItem';

export default function updateBodyStyleToChug(
  filtersState: FiltersState,
  filteredData: InventoryItem[]
) {
  let newFiltersState = cloneDeep(filtersState);

  if (Object.hasOwn(newFiltersState, 'bodysegment')) {
    const bodySegmentsChugSet = new Set<string>();
    filteredData.forEach(
      (vin) => vin.vehicle.bodySegmentChug && bodySegmentsChugSet.add(vin.vehicle.bodySegmentChug)
    );
    (newFiltersState.bodysegment as SelectState).options = Array.from(bodySegmentsChugSet);
  }

  return newFiltersState;
}
