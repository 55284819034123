import { InventoryItem } from 'types/InventoryItem';
import { ColumnData } from 'util/exportCSV';
import { formatCurrency, formatNumber, formatPercent } from 'util/formatters';

export function formatVinPathCSVHeaders(): ColumnData<InventoryItem>[] {
  return [
    {
      header: 'Year',
      cell: (item) => item.vehicle.modelYear,
    },
    {
      header: 'Make',
      cell: (item) => item.vehicle.make,
    },
    {
      header: 'Model',
      cell: (item) => item.vehicle.model,
    },
    {
      header: 'Trim',
      cell: (item) => item.vehicle?.trim ?? '-',
    },
    {
      header: 'Body style',
      cell: (item) => item.vehicle?.bodySegment ?? '-',
    },
    {
      header: 'VIN',
      cell: (item) => item.listing.vin,
    },
    {
      header: 'Current probability of sale',
      cell: (item) => item.vinPath?.currentProbabilityOfSale ?? '-',
    },
    {
      header: 'Current price',
      cell: (item) => formatCurrency(item.priceMetrics?.currentPrice),
    },
    {
      header: 'Current price to market %',
      cell: (item) => formatPercent(item.priceMetrics?.currentPriceToMarketPct),
    },
    {
      header: 'Probability after price change',
      cell: (item) =>
        item.vinPath?.pricingOptions
          ? item.vinPath?.pricingOptions[0].probabilityOfSale ?? '-'
          : '-',
    },
    {
      header: 'Price after markdown',
      cell: (item) =>
        formatCurrency(
          (item.priceMetrics?.currentPrice ?? 0) -
            (item.vinPath?.pricingOptions ? item.vinPath?.pricingOptions[0].markdown ?? 0 : 0)
        ),
    },
    {
      header: 'Price to market % after markdown',
      cell: (item) => formatPercent(item.priceMetrics?.currentPriceToMarketPct),
    },
    {
      header: 'Markdown',
      cell: (item) =>
        formatCurrency(item.vinPath?.pricingOptions ? item.vinPath?.pricingOptions[0].markdown : 0),
    },
    {
      header: 'Dol',
      cell: (item) => formatNumber(item.listing?.dol),
    },
    {
      header: 'Mileage',
      cell: (item) => formatNumber(item.listing.mileage),
    },
  ];
}
