import { IconAlertTriangleFilled } from '@tabler/icons-react';
import ReactGA from 'react-ga4';
import Text from 'Sparky/Text';

import InquirySuggestion from 'components/Chat/components/InquirySuggestion';

import styles from './Examples.module.scss';

interface ExamplesProps {
  onClick(text: string): void;
  storybook?: boolean;
}

const examples = [
  'What are my used vehicles that have over 30 days and priced above market?',
  'What are the top 10 used vehicles in high demand in my inventory?',
  'What are the top 10 used vehicles in high demand in my market area?',
  'What are the top 10 lead producing used vehicles in my inventory?',
  'What are the bottom 10 lead producing used vehicles in my inventory?',
  'What are my top used units that are priced too high?',
  'What are my top new units that are priced too high?',
  'What cars can Lotlinx help me with the most?',
  'What are my top used units that are priced too low?',
  'What are my top new units that are priced too low?',
  'Which new vehicles could I raise the price on and still sell in market days to sale?',
  'Which used vehicles could I raise the price on and still sell in market days to sale?',
  'How can I increase my gross profit on used vehicles?',
  'How can I increase my unit volume on used vehicles?',
];

export default function Examples({ onClick, storybook }: ExamplesProps) {
  function getRandomIndexes(list: any[], count: number): number[] {
    const randomIndexes: number[] = [];
    const totalIndexes = list.length;

    // Generate unique random indexes
    while (randomIndexes.length < count) {
      const randomIndex = Math.floor(Math.random() * totalIndexes);

      if (!randomIndexes.includes(randomIndex)) {
        randomIndexes.push(randomIndex);
      }
    }

    return randomIndexes;
  }

  const randomIndexes = getRandomIndexes(examples, 3);
  const selectedItems = examples.filter((element, index) => randomIndexes.includes(index));
  selectedItems.sort(() => Math.random() - 0.5);

  const storyBookItems = [examples[0], examples[1], examples[2]];

  const items = storybook ? storyBookItems : selectedItems;

  return (
    <div className={styles.examples}>
      <Text as='h3'>Examples</Text>
      {items.map((i, idx) => (
        <InquirySuggestion
          key={idx}
          text={i}
          onClick={() => {
            ReactGA.event({ category: 'VINdaloo', action: 'Clicked inquiry example' });
            onClick(i);
          }}
        />
      ))}
      <div className={styles.warning}>
        <IconAlertTriangleFilled size='32' className={styles.warningIcon} />
        <span>
          <Text as='h4'>Limitations</Text>
          <Text as='p'>The LotLinx ChatBot may return incorrect or incomplete data.</Text>
        </span>
      </div>
    </div>
  );
}
