import { useState } from 'react';

import {
  IconArrowDown,
  IconArrowUp,
  IconEyeOff,
  IconLock,
  IconLockOpen,
} from '@tabler/icons-react';
import classnames from 'classnames';
import Popover from 'Sparky/Popover';
import TableHeader, { TableHeaderProps } from 'Sparky/Table/TableHeader';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { tooltipJSON } from 'components/TooltipIcon/tooltipJSON';
import { SortDirection } from 'types/SortDirection';

import styles from './InventoryTableHeader.module.scss';

export interface InventoryTableHeaderProps extends TableHeaderProps {
  sortDirection?: SortDirection;
  sortable: boolean;
  isFrozenColumn?: boolean;
  tooltipKey?: string;

  /** Sort this Column on mount. Only one Column in a Table can be auto-sorted. */
  onSortAsc(): void;

  onSortDesc(): void;

  toggleFrozen?(): void;

  toggleHide?(): void;
}

/**
 * Column
 */
export default function InventoryTableHeader({
  sortDirection,
  sortable,
  isFrozenColumn,
  tooltipKey,
  onSortAsc,
  onSortDesc,
  toggleFrozen,
  toggleHide,
  textAlign,
  className,
  children,
  ...otherProps
}: InventoryTableHeaderProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const tooltip = tooltipJSON.find((item) => item.key === tooltipKey);

  return (
    <TableHeader
      textAlign={textAlign}
      className={className}
      {...otherProps}
      onClick={(e) => {
        e.preventDefault();
        setIsPopoverOpen(!isPopoverOpen);
      }}
    >
      <Popover
        isOpen={isPopoverOpen && (sortable || !!toggleFrozen || !!toggleHide)}
        setIsOpen={setIsPopoverOpen}
        side='bottom'
        showCarrat={false}
        showCloseButton={false}
        contentClassName={styles.sortListPopover}
        popupContent={
          <div className={styles.sortList}>
            {sortable && (
              <>
                <button className={styles.sortItem} onClick={onSortAsc}>
                  <IconArrowUp size={14} /> Sort ascending
                </button>
                <button className={styles.sortItem} onClick={onSortDesc}>
                  <IconArrowDown size={14} /> Sort descending
                </button>
              </>
            )}
            {toggleFrozen && (
              <button className={styles.sortItem} onClick={toggleFrozen}>
                {isFrozenColumn ? (
                  <>
                    <IconLockOpen size={14} />
                    <Text>Unlock</Text>
                  </>
                ) : (
                  <>
                    <IconLock size={14} />
                    <Text>Lock to the left</Text>
                  </>
                )}
              </button>
            )}
            {toggleHide && (
              <button className={styles.sortItem} onClick={toggleHide}>
                <IconEyeOff size={14} /> Hide in view
              </button>
            )}
          </div>
        }
      >
        <div className={styles.headerContainer}>
          <Tooltip content={tooltip?.Tooltip} disabled={!tooltip?.Tooltip}>
            <div className={styles.columnContent}>
              <div className={classnames([tooltip?.Tooltip && styles.inventoryHeader])}>
                {children}
              </div>
              {isFrozenColumn && <IconLock size={14} />}
              {sortable && (
                <IconArrowUp
                  size={14}
                  className={classnames([
                    styles.sortArrow,
                    sortDirection === 'asc' && styles.asc,
                    sortDirection === 'desc' && styles.desc,
                  ])}
                  aria-hidden
                />
              )}
            </div>
          </Tooltip>
        </div>
      </Popover>
    </TableHeader>
  );
}
