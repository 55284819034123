import { ReactNode } from 'react';

import classnames from 'classnames';
import Panel from 'Sparky/Panel';

import styles from './TooltipWrapper.module.scss';

export interface TooltipWrapperProps {
  children: ReactNode;
  className?: string;
}

/** Provides styling for tooltips */
export default function TooltipWrapper({ children, className }: TooltipWrapperProps) {
  return <Panel className={classnames([styles.tooltipWrapper, className])}>{children}</Panel>;
}
