import classnames from 'classnames';
import { Location, useLocation } from 'react-router-dom';
import Text from 'Sparky/Text';

import { LinkKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';

import styles from './NavbarLink.module.scss';

export interface NavbarLinksProps {
  label: string;
  to: string;
  /** Custom matcher, when true the link would be styled as active*/
  customIsActive?: (location: Location) => boolean;
}

/**
 * NavbarLink
 */
export default function NavbarLink({ label, to, customIsActive }: NavbarLinksProps) {
  const location = useLocation();
  const defaultIsActive = (location: Location) => location.pathname.includes(to);
  const isActive = customIsActive ?? defaultIsActive;

  return (
    <LinkKeepParams to={to} className={styles.link}>
      <Text
        fontWeight={7}
        className={classnames([styles.activeLinkLabel, isActive(location) && styles.active])}
      >
        {label}
      </Text>
      <Text className={classnames([styles.normalLinkLabel, isActive(location) && styles.inactive])}>
        {label}
      </Text>
    </LinkKeepParams>
  );
}
