import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { datePeriodAtom } from 'store/datePeriodAtom';

import ENDPOINTS from 'api/utils/endpoints';
import { get } from 'api/utils/fetch';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { SimilarVINsData } from 'pages/Inventory/VinDetailSidebar/MarketSection/SimilarVINsChart';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

import { similarVINsResponseSchema } from './similarVINSchema';

interface FetchSimilarVINsProps {
  token?: string;
  dealerId?: number;
  vin: string;
  startDate?: string;
  endDate?: string;
}

export interface SimilarVINsResponse {
  positionToMarket?: number;
  marketCount?: number;
  avgMarketMileage?: number;
  avgMarketMileageSold?: number;
  avgMarketDol?: number;
  avgMarketDolSold?: number;
  avgMarketPrice?: number;
  lastOnlinePrice?: number;
  soldSimilarMakeModel?: number;
  inventory?: SimilarVINsData[];
}

async function fetchSimilarVINs({
  token,
  dealerId,
  vin,
  startDate,
  endDate,
}: FetchSimilarVINsProps) {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  let res = await get<SimilarVINsResponse>(`${ENDPOINTS.similarVins}/${dealerId}/${vin}`, {
    headers: { token },
    params: {
      startDate,
      endDate,
    },
  });

  return similarVINsResponseSchema.parse(res);
}

export default function useSimilarVINs(vin: string, pageKey?: string) {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const [datePeriod] = useAtom(datePeriodAtom);
  const { similarVinsKey: queryKey } = useQueryKeys({ vin });
  let startDate: string, endDate: string;

  if (pageKey === 'sold') {
    startDate = datePeriod[0];
    endDate = datePeriod[1];
  } else {
    startDate = dayjs().subtract(1, 'month').startOf('month').format('YYYYMMDD');
    endDate = dayjs().subtract(1, 'month').endOf('month').format('YYYYMMDD');
  }

  return useQuery(queryKey, () =>
    fetchSimilarVINs({ token: user?.token, dealerId, vin, startDate, endDate })
  );
}
