export const tooltipJSON = [
  {
    key: 'Live Inventory-Vehicle-Photo',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Photo',
    Description: 'One of the images used in advertising the vehicle.',
    Tooltip: 'An image previewing the vehicle',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Summary',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Summary',
    Description:
      "A car's make is the brand. The model is the name of a car product or a line of cars. For example, Toyota is a car make and Camry is a model. The make, model, model year, body style and trim level all affect the cost . Trim describes differences between cars of the same model.",
    Tooltip: 'Essential vehicle information (i.e., year, make, model, and body style)',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Mileage',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Mileage',
    Description:
      'Mileage the dealer has indicated is the current mileage at the time of advertising the vehicle, typically in miles.',
    Tooltip: 'Mileage indicates the total distance a vehicle has traveled',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-DOL',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'DOL',
    Description:
      'Days on Lot (DOL) is the number of days a vehicle was seen for sale at the dealership website or has been provided to Lotlinx in a data feed from the dealer.',
    Tooltip: 'The number of days a vehicle was seen for sale at the dealership',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Trim',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Trim',
    Description:
      'Trim refers to the specific version or configuration of a vehicle model that may include variations in engine, features, options, and equipment.',
    Tooltip: 'Specific vehicle model version with unique features and options',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Body Style',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Body Style',
    Description:
      "Body style describes the general characteristics of a vehicle's exterior design.  It encompasses the specific aesthetic and functional elements that distinguish from a model in a different body style.  For example, SUV versus PICKUP",
    Tooltip: "A more specific term that describes the finer details of a vehicle's exterior design",
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Stock #',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Stock #',
    Description:
      "The dealership's unique identifier for a specific vehicle in inventory.   It is a substitute for VIN as it is operationally easier to communicate.",
    Tooltip: "The dealership's identifier for a specific vehicle in inventory",
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-VIN',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'VIN',
    Description:
      'A vehicle identification number is a unique code, used by the automotive industry to identify individual motor vehicles.',
    Tooltip: 'A VIN is a unique code, used by the automotive industry to identify a motor vehicle',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Condition',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Condition',
    Description:
      'A legally defined condition of a vehicle.  The term “new automobile” means an automobile whose legal title has never been transferred by a dealer to an ultimate purchaser.  A Used vehicle is one that has been transferred to a purchaser and has accumulated mileage.  A CPO vehicle is a subset of Used vehicles which have been certified by the manufacturer to be generally free of defects and meets stringent manufacturer set quality controls.',
    Tooltip: 'Indicates if a car is New, Used, or Certified Pre-Owned',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Make',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Make',
    Description:
      "Technically, a car's make is the company that made it, for example: Ford, Honda, Volkswagen.  In practice the make typically refers to the brand of a motor vehicle but can also be the manufacturer.  For example, Stellantis is the OEM / Make and has the brand JEEP however, brand and make are sometimes used interchangeably.  ",
    Tooltip: 'A vehicle make refers to the brand of a vehicle but can also be the manufacturer',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Year',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Year',
    Description:
      'the year used to designate a discrete vehicle model, irrespective of the calendar year in which the vehicle was actually produced, so long as the actual period is less than two calendar years.',
    Tooltip:
      'The model year of a car relates more to specification than when the vehicle was manufactured',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Photo Count',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Photo Count',
    Description: 'The number of photos associated with the vehicle.',
    Tooltip: 'The number of photos associated with the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Model',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Model',
    Description:
      'The model of a vehicle specifies its design and version, marked by distinct features and specifications',
    Tooltip: 'Specific vehicle design produced by a manufacturer',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Vehicle-Risk',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Risk',
    Description:
      'Applies a media risk badge to VINs that meet the criteria of insufficient VDP exposure. High Media risk is determined by the total VDPs divided by the rolling past 30 days. A VIN averaging fewer than 1 VDP per day over the last 30 days is classified as High Media Risk. Additional risk indicators may be added to this column in the future.',
    Tooltip: 'Vehicles that have one or fewer views per day over a 30 day period',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Status-Published',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Status',
    Metric: 'Published',
    Description:
      'Indicates if the vehicle was published to one of our affiliates during the reporting period. If the vehicle advertising budget has been reached mid-month the status could flip from published to not published',
    Tooltip: 'Current publication status of the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Status-Vehicle Status',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Status',
    Metric: 'Vehicle Status',
    Description:
      'Indicates if a vehicle is active, pending, sold or moved. This status may change over time.',
    Tooltip: 'Shows the vehicle status: active, pending, sold, or moved. Status may change.',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Status-Lot Status',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Status',
    Metric: 'Lot Status',
    Description:
      "An optional dealer-supplied value that provides detailed information about the vehicle's current status on the lot.\n",
    Tooltip: 'An optional dealer-supplied value providing information on the vehicle status',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Budget-Budget',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Budget',
    Metric: 'Budget',
    Description: 'The monthly allocated amount of money set aside for advertising a vehicle.',
    Tooltip: 'The amount of dollars allocated for individual vehicle advertising campaigns',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Budget-Spend',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Budget',
    Metric: 'Spend',
    Description: 'The amount of monthly budget spent on campaigns for VIN level advertising.',
    Tooltip: 'The amount of monthly budget used for individual vehicle advertising campaigns',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Budget-Lotlinx Days in Campaign (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Budget',
    Metric: 'Lotlinx Days in Campaign (lifetime)',
    Description:
      'The amount of days that Lotlinx actively featured or promoted a specific vehicle across all ad campaigns for the respective dealership.',
    Tooltip: 'Lifetime duration of a vehicle in a Lotlinx ad campaign',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Budget-Remaining Budget',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Budget',
    Metric: 'Remaining Budget',
    Description:
      'The difference between the monthly amount spent on advertising a vehicle and the initial budget.',
    Tooltip: 'Remaining balance of the monthly advertising budget',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Budget-Lotlinx Days in Campaign',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Budget',
    Metric: 'Lotlinx Days in Campaign',
    Description:
      'The number of days that Lotlinx actively featured or promoted a specific vehicle in an ad campaign.',
    Tooltip: 'Days a specific vehicle was featured in a Lotlinx ad campaign',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Budget-AI Recommended Budget Increase',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Budget',
    Metric: 'AI Recommended Budget Increase',
    Description:
      "It's an AI-based feature that evaluates various vehicle attributes and market factors. This model suggests if a VIN's budget should be increased by either $0, $50, $75, or $100. Currently this is only available for select dealer accounts. ",
    Tooltip: 'Recommended budget increase for VINs',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': 'not in dev pre NADA',
    Version: null,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Shoppers Delivered',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Shoppers Delivered',
    Description:
      "After we display our ads, users that click on and pass through our redirect are considered shoppers.  This column indicates the number of shoppers that we've delivered to a dealership.",
    Tooltip: "Count of shoppers who engaged with ads and visited the dealer's website",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Shoppers Delivered (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Shoppers Delivered (lifetime)',
    Description:
      "The cumulative number of shoppers that we've delivered across the lifetime of the vehicle for that respective dealership.",
    Tooltip: "The cumulative shopper count delivered over the vehicle's lifetime ",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Lotlinx VDPs',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lotlinx VDPs',
    Description:
      'The total count of shoppers that Lotlinx redirected to a vehicle display page (VDP).',
    Tooltip: 'Count of shoppers Lotlinx directed to vehicle detail pages',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Lotlinx VDPs (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lotlinx VDPs (lifetime)',
    Description:
      "The cumulative number of shoppers directed to a vehicle display page exclusively through Lotlinx throughout the vehicle's tenure at the dealership.",
    Tooltip: "The cumulative VDP views delivered from Lotlinx over the vehicle's lifetime",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Total VDPs',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Total VDPs',
    Description:
      'The number of shoppers that were redirected and landed on a vehicle display page (VDP). This includes traffic from other sources like Google, organic, direct, etc.',
    Tooltip: 'Count of all shoppers landing on vehicle detail pages',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Total VDPs (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Total VDPs (lifetime)',
    Description:
      'The cumulative number of shoppers that were directed to a vehicle display page across all traffic sources for the lifetime of the vehicle at that specific dealership.',
    Tooltip: 'Lifetime cumulative shoppers directed to a VDP from all sources',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Cost per VDP',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Cost per VDP',
    Description:
      "This represents the amount of money spent to drive a shopper to view a specific vehicle's details on a vehicle display page (VDP)\nFormula: Cost per VDP = Total Advertising Cost / Number of Vehicle Display Page Views",
    Tooltip: 'Average cost incurred per user delivered to a VDP',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Cost per VDP (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Cost per VDP (lifetime)',
    Description:
      "This is the cost incurred for each shopper that was directed to a vehicle's display page.",
    Tooltip: 'The cumulative lifetime cost incurred to direct shoppers to a VDP ',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Lotlinx % of VDPs',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lotlinx % of VDPs',
    Description:
      'The percent of shoppers sent to a vehicle display page (VDP) represents the ratio of visitors directed to a VDP through Lotlinx compared to the total potential traffic from other sources such as Google, organic searches, direct visits, etc.',
    Tooltip: 'Number of VDP views that were generated by Lotlinx',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Lotlinx % of VDPs (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lotlinx % of VDPs (lifetime)',
    Description:
      "The proportion of shoppers generated by Lotlinx compared to all traffic sources throughout the vehicle's lifespan.",
    Tooltip:
      "Lotlinx generated shoppers compared to all traffic sources for the vehicle's lifetime",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Conversions',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Conversions',
    Description:
      'A shopper could perform any of the following actions to be considered a conversion. This includes: lead form submissions, click to call, click to chat, and click to text. The definition of conversion (i.e., the list of actions) could change over time.',
    Tooltip:
      "When a shopper takes key actions on a dealer's website like form submissions, calls, chats, texts",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Conversions (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Conversions (lifetime)',
    Description:
      "To be classified as a conversion during the vehicle's stay at the dealership, a shopper may engage in various actions, such as submitting lead forms, clicking to call, chat, or text. The definition of conversion actions may evolve over time.",
    Tooltip:
      'Actions like lead forms, calls, chat, or texts that occurred across the vehicles lifetime',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Lead Form Submissions',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lead Form Submissions',
    Description:
      "When a shopper fills out a form on the dealership's website because they're interested and want more information about a vehicle. The form will ask for the shoppers respective contact information.",
    Tooltip: "When a shopper fills out a form on a dealer's website",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Lead Form Submissions (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lead Form Submissions (lifetime)',
    Description:
      "The cumulative number of website visitors who submit a form on the dealership's website during the vehicle's tenure, expressing interest and seeking further vehicle information. This form collects their contact details.",
    Tooltip: 'A cumulative count of shoppers submitting forms for the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Click to Call',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Call',
    Description:
      'When a shopper visits a dealership\'s website and clicks on the designated button often labeled "click to call". The intent is they want to speak with a salesperson from the dealership. \n\n',
    Tooltip: "When a shopper uses the click-to-call feature on a dealer's website",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Click to Call (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Call (lifetime)',
    Description:
      "This refers to the total count of shoppers who have used the click-to-call feature on the dealership's website across the entire lifespan of the vehicle being on the dealers website",
    Tooltip: 'Cumulative count of all click-to-call shoppers ',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Click to Chat',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Chat',
    Description:
      'When a shopper visits a dealership\'s website and clicks on the designated button often labeled "click to chat". The intent is they want to engage in a chat conversation with a salesperson from the dealership. A chat bot is leveraged in this situation.',
    Tooltip: "When a shopper uses the click-to-chat feature on a dealer's website",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Click to Chat (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Chat (lifetime)',
    Description:
      'This refers to the total number of shoppers who have used the click to chat feature on the dealers website across the entire lifespan of the vehicle being on the dealers website',
    Tooltip: 'Cumulative count of all click-to-chat shoppers for a vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Engagement-Click to Text',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Text',
    Description:
      'When a shopper visits a dealership\'s website and clicks on the designated button often labeled "click to text". The intent is they want to engage in a phone text conversation with a salesperson from the dealership.',
    Tooltip: "When a shopper uses the click-to-text feature on a dealer's website",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Engagement-Click to Text (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Text (lifetime)',
    Description:
      "This refers to the total number of shoppers who have used the click to text feature on the dealers website across the entire lifespan of the vehicle being on the dealer's website",
    Tooltip: 'Cumulative count of all click-to-text shoppers for a vehicle ',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Price-Current Price',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Current Price',
    Description: 'The price at which a vehicle is currently being offered for sale.',
    Tooltip: 'Current list price of a vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Price-Last Price',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Last Price',
    Description: 'This is the price that was in effect immediately before the current price.',
    Tooltip: 'Previous sales price immediately before the current price',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Price-Initial Price',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Initial Price',
    Description: 'Is the initial listed price of the vehicle.',
    Tooltip: "The vehicle's initial listed price",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Price-Price Change',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Price Change',
    Description: 'Indicates the sum difference between the initial price and the current price.',
    Tooltip: 'Cumulative difference between initial listed price and current price',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Price-Days Since Last Price Change',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Days Since Last Price Change',
    Description: "Is the number of days since the most recent adjustment to the vehicle's price.",
    Tooltip: 'Days elapsed since the last price change',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Price-Total # of Price Changes',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Total # of Price Changes',
    Description:
      'This is a count of how many times a price has been adjusted or altered since the initial price.',
    Tooltip: 'Total count of price adjustments since the initial listing',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Price-Avg Price Change',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Avg Price Change',
    Description:
      'The average alteration in price while the vehicle is available for purchase. The average is calculated by dividing the total price by the number of price changes. This could include positive or negative price changes.',
    Tooltip: 'Average alteration in price while the vehicle is available for purchase',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Price-Current Price to Market %',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Current Price to Market %',
    Description: "Is the comparison of the vehicle's current price to market of similar vehicles.",
    Tooltip: "Comparison of the vehicle's current price to market of similar vehicles",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Price-Last Price to Market %',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Last Price to Market %',
    Description:
      'Is the comparison of the VINs previous price to the market based on similar VINs.',
    Tooltip: 'Previous price comparison with similar market vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Price-Initial Price to Market %',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Price',
    Metric: 'Initial Price to Market %',
    Description:
      'Is the comparison of the VINs initial sale price to the market based on similar VINs.',
    Tooltip: 'Initial price comparison with similar market vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Live Inventory-Market-Avg Market Price',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Market Price',
    Description:
      'The national average market price is calculated by summing the listing prices of all similar VINs across the nation and dividing by their total number to reflect the typical listing price for a VIN.',
    Tooltip: 'Sum of similar VIN prices nationwide divided by total VINs',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Market Price 7d%',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Market Price 7d%',
    Description:
      'This metric measures the percentage change in the national average market listed price over the last 7 days',
    Tooltip: '% change in national avg market price over last 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Market Sale Price',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Market Sale Price',
    Description:
      'The national average market sale price is determined by adding together the sale prices of all similar VINs sold across the nation and dividing by the total number of these VINs, providing a national benchmark for selling prices.',
    Tooltip: 'Sum of nationally sold VIN prices divided by the number sold',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Market Sale Price 7d%',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Market Sale Price 7d%',
    Description:
      'Measures the percentage change in the national average market sale price over the past 7 days, indicating shifts in market conditions.',
    Tooltip: '% change in national avg sale price over last 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Days to Sale',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Days to Sale',
    Description:
      'This metric calculates the national average number of days it takes for a VIN to be sold, derived by averaging the days each VIN remains listed before being sold providing an insight into the sales velocity.',
    Tooltip:
      'Calculated as the national average of days from listing to sale, averaged across all VINs',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Days to Sale 7d%',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Days to Sale 7d%',
    Description:
      'This is a measure that calculates the national average daily percentage change in the number of days it takes for vehicles to be sold across a 7-day rolling period',
    Tooltip: '% change in average sale days nationally over 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Shoppers',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Shoppers',
    Description:
      'Measures the average number of shoppers who have shown interest in each vehicle listing. It is calculated by dividing the total number of shopper interactions by the number of similar vehicle listings, providing insights into shopper engagement for each vehicle',
    Tooltip: 'Avg number of shoppers per vehicle, based on total interactions',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Shoppers 7d%',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Shoppers 7d%',
    Description:
      'This metric shows the national 7-day rolling average of the shopper count, reflecting changes in buyer activity.',
    Tooltip: '7-day national rolling average of shopper counts',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Market DOL',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Market DOL',
    Description:
      "The average duration that vehicles remain on a dealership's lot before being sold. This metric is derived by aggregating the days on lot for all vehicles in a given market and dividing by the total number of vehicles, offering an insight into inventory turnover.",
    Tooltip: 'Total national days on lot of similar vehicles divided by the number of vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Avg Market DOL 7%',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Avg Market DOL 7%',
    Description:
      'Refers to the national average daily percent change in the number of days a specific vehicle is for sale over a 7-day period.',
    Tooltip: '% change in avg days on lot nationally over 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Shopper Demand',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Shopper Demand',
    Description:
      'This is our approximation of how many potential shoppers are available in the market. We call them shoppers from the distribution network, because in the dealers view they are potential shoppers from the distribution network, whether or not they click on one of our ads',
    Tooltip: 'Estimate of potential shoppers in the national market',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Similar VINs',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Similar VINs',
    Description:
      'This counts the number of similar VINs nationwide, using vehicle year, make, model, and where available, trim, to compare against similar listed vehicles.',
    Tooltip: 'National count of similar vehicles by year, make, model',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Similar VINs 7d%',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Similar VINs 7d%',
    Description:
      'Assesses the percent change in the count of similar vehicles nationally over a seven-day period, indicating trends in vehicle availability or preference.',
    Tooltip: '% change in count of similar VINs nationwide over 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Market Days Supply',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Market Days Supply',
    Description:
      'Market Days Supply is calculated by dividing current market quantity of the vehicle at its year, make, model, trim level by the average daily retail sales rate over the past 45 days.\nAverage daily retail sales rate = divide the number of sales (i.e., vehicles sold) over the past 45 days by 45 to get the average daily sales rate ',
    Tooltip: 'Time estimate to sell inventory based on the past 45-day sales rate',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': 'not in dev pre NADA',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Market-Dealer Days Supply',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Market',
    Metric: 'Dealer Days Supply',
    Description:
      'Dealer Day’s Supply is calculated by dividing current dealer quantity of the vehicle at its year, make, model, trim level by the average dealer\ndaily retail sales rate over the past 45 days.\nAverage daily retail sales rate = divide the number of sales (i.e., vehicles sold) over the past 45 days by 45 to get the average daily sales rate\nThe metric can apply to any type of inventory segmentation provided there is sufficient units to provide an accurate calculation.',
    Tooltip: "Dealer's vehicle stock / 45-day average daily sales rate\n\n",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': 'not in dev pre NADA',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Leads-Lotlinx leads',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Leads',
    Metric: 'Lotlinx leads',
    Description: 'Leads that are generated by Lotlinx through our publisher network',
    Tooltip:
      "Leads produced by Lotlinx, delivered, and documented within the dealership's CRM system",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Leads-Lotlinx leads (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Leads',
    Metric: 'Lotlinx leads (lifetime)',
    Description:
      'The cumulative number of leads generated by Lotlinx through our publisher network',
    Tooltip: "Total leads from Lotlinx's network recorded in the dealer's CRM",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Leads-Total leads',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Leads',
    Metric: 'Total leads',
    Description: 'Leads that a dealer may receive through other means outside of Lotlinx',
    Tooltip: 'Leads received by a dealer through channels other than Lotlinx',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Leads-Total leads (lifetime)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Leads',
    Metric: 'Total leads (lifetime)',
    Description:
      'The cumulative number of leads that a dealer may receive through other means outside of Lotlinx',
    Tooltip: "Total leads from all channels recorded in the dealer's CRM",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Leads-Avg age of leads',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Leads',
    Metric: 'Avg age of leads',
    Description:
      'This is the average time duration from the creation or acquisition of leads to a specific analysis date. It is calculated by summing the ages of all leads (time since each lead was generated) and dividing by the total number of leads. This metric is essential for assessing the freshness of the leads and prioritizing follow-up actions',
    Tooltip: 'Average time since each lead was generated or acquired',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Live Inventory-Leads-Total leads by status',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Leads',
    Metric: 'Total leads by status',
    Description:
      'The total count of leads by status within the CRM system. Example statuses include Open, Appointment, or Dead.',
    Tooltip: "Total lead count by status within the dealership's CRM system",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign--Dealer Day Supply',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: '',
    Metric: 'Dealer Day Supply',
    Description:
      'Dealer Day’s Supply is calculated by dividing current dealer quantity of the vehicle at its year, make, model, trim level by the average dealer\ndaily retail sales rate over the past 45 days.\nAverage daily retail sales rate = divide the number of sales (i.e., vehicles sold) over the past 45 days by 45 to get the average daily sales rate\nThe metric can apply to any type of inventory segmentation provided there is sufficient units to provide an accurate calculation.',
    Tooltip: "Dealer's vehicle stock / 45-day average daily sales rate\n\n",
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Pace-Units Pace',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Pace',
    Metric: 'Units Pace',
    Description:
      "This metric indicates the average speed at which a dealership or manufacturer sells their inventory of vehicles. It's calculated by dividing the number of units sold by the number of days in the period (e.g., a month or a quarter).",
    Tooltip: 'Average rate of vehicle sales over a specific time period',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Pace-Holding Units',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Pace',
    Metric: 'Holding Units',
    Description:
      "Vehicles currently in a dealership's inventory that are available for sale but have not yet been purchased by a customer.",
    Tooltip: "Unsold vehicles in dealership's inventory awaiting purchase",
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign--Market Share',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: '',
    Metric: 'Market Share',
    Description:
      'Market Share is a metric on how well the dealer’s segment is performing against the market. Typically this is against similar make model and body style level. Most franchise dealers measure their success on their sales penetration in their market at the make model body style level in order to gain an advantage with their OEM in terms of favorable stocking vehicles. Typically market share is based on sales volumes\n\nA dealers percent of sales in a segment relative to sales in the market. For example, if 50 Toyota Highlanders sold in your market in the last 30 days and you sold 20 of them, then your market share is 40% ',
    Tooltip: 'A trendline of sales as a % of total market sales for similar vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign--Waste',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: '',
    Metric: 'Waste',
    Description:
      'This is where a dealer has paid advertising on a VIN and the total number of VDPs exceeds 22',
    Tooltip:
      "This is where a dealer has paid for advertising on a VIN and the total number of VDP views exceed 30. If it's organic traffic, it is not considered waste.",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign--Risk',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: '',
    Metric: 'Risk',
    Description:
      'Media risk is calculated based total vdp / rolling past 30 days. If  a VIN is averaging less than 1 VDP per day over the past 30 days then this VIN would be classified as Media Risk.',
    Tooltip:
      'Refers to the ratio of vehicles that has one or fewer views per day over a 30 day period',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Your Investment-Total Budget',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Your Investment',
    Metric: 'Total Budget',
    Description: 'The monthly allocated amount of money set aside for advertising a vehicle.',
    Tooltip: 'The monthly budget allocated for advertising campaigns in this segment',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Your Investment-Spend',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Your Investment',
    Metric: 'Spend',
    Description: 'The amount of monthly budget spent on campaigns for VIN level advertising',
    Tooltip: 'The monthly budget spent on campaign advertising for this segment',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Your Investment-Budget per VIN',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Your Investment',
    Metric: 'Budget per VIN',
    Description:
      'The monthly allocated amount of money set aside for advertising a vehicle within the campaign',
    Tooltip: 'The amount of monthly budget used for individual vehicle advertising campaigns',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Your Investment-VINs Included',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Your Investment',
    Metric: 'VINs Included',
    Description:
      'The distinct count of total VINs published during the period and within the respective campaign',
    Tooltip: 'Distinct VIN count within the campaign',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Campaign Performance-Sell Down Rate',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Campaign Performance',
    Metric: 'Sell Down Rate',
    Description:
      'Sell down rate can be defined as the total units sold in a 30 day period divided by the total units listed in a 30 day period. What they are selling versus what they are holding.',
    Tooltip: 'Ratio of units sold to units listed in a 30-day period',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Campaign Performance-Average Markdown',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Campaign Performance',
    Metric: 'Average Markdown',
    Description:
      'The calculation involves determining the average markdown per vehicle that experienced a markdown within the specified time frame. As an illustration, consider a vehicle whose price has been consistently reduced by $100 over five consecutive days. This leads to a total lifetime markdown of -$500 and an average markdown per occurrence of $100.',
    Tooltip: 'Average discount on vehicles that have been marked down ',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Campaign Performance-Average Days on Lot',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Campaign Performance',
    Metric: 'Average Days on Lot',
    Description:
      "The average number of days a vehicle remains in a dealership's inventory before being sold. It provides insights into how quickly vehicles are sold and helps dealerships assess the efficiency of their inventory management.",
    Tooltip:
      "Average number of days a vehicle remains in a dealership's inventory before being sold",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Campaign Performance-Days to Sale',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Campaign Performance',
    Metric: 'Days to Sale',
    Description:
      'Days to Sale refers to the average number of days the inventory takes to sell within the period.',
    Tooltip: 'Average number of days the vehicle inventory takes to sell',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Campaign Performance-PVR',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Campaign Performance',
    Metric: 'PVR',
    Description:
      "Per VIN Retailed or Per Vehicle Revenue (PVR) represents the average profit made on each vehicle sold by a dealership.\nLotlinx estimates vehicle profit margins using a combination of public financial data from publicly traded companies and Direct Management System (DMS) data. Public data provides an average profit figure per unit, which, when combined with the median days to sale, offers a general profit estimate across all sales for a given period. For instance, a reported average front-end gross of $2100 with a 29-day sales cycle gives an insight into the dealership's inventory performance. Lotlinx then adjusts these profit estimates based on the final listing price of the vehicles, with the assumption that higher prices correlate with higher profits. Additionally, the profit is fine-tuned according to how the vehicle's sale pace compares to the market median: faster-selling vehicles get an increased profit margin, while slower ones see a reduction. While individual vehicle results may vary, the aggregated data tends to align closely with actual outcomes.",
    Tooltip: 'Estimated average profit per vehicle sold',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Why this is Happening-VINs Reached',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Why this is Happening',
    Metric: 'VINs Reached',
    Description:
      'The cumulative amount of unique VINs that have received one or more VDP views within the reporting period for this segment',
    Tooltip: 'Unique VIN count of vehicles that have received VDP views',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Why this is Happening-Average Views per Day',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Why this is Happening',
    Metric: 'Average Views per Day',
    Description: 'A month to date rolling average of VDP views',
    Tooltip: 'Month to date rolling average of VDP views',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Why this is Happening-Net New Shoppers',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Why this is Happening',
    Metric: 'Net New Shoppers',
    Description:
      'Net new shoppers can be calculated by the total new shoppers divided by the total count of shoppers. This is a month to date rolling average.',
    Tooltip: 'Rolling average calculated as total new shoppers divided by total shopper count',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Why this is Happening-Conversions',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Why this is Happening',
    Metric: 'Conversions',
    Description:
      'A shopper could perform any of the following actions to be considered a conversion. This includes: lead form submissions, click to call, click to chat, and click to text. The definition of conversion (i.e., the list of actions) could change over time.',
    Tooltip:
      "When a shopper takes key actions on a dealer's website like form submissions, calls, chats, texts",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Campaign-Why this is Happening-Leads',
    'Report ': {
      ' Dashboard': 'Campaign',
    },
    Category: 'Why this is Happening',
    Metric: 'Leads',
    Description:
      "Leads that are successfully added into a dealer's customer relationship management system",
    Tooltip:
      "Leads that are successfully added into a dealer's customer relationship management system",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Filter Criteria-Vehicle Status',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Vehicle Status',
    Description:
      'Indicates if a vehicle is active, pending, sold, or moved. This status may change over time.',
    Tooltip: 'Shows the vehicle status: active, pending, sold, or moved. Status may change.',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Filter Criteria-Lot Status',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Lot Status',
    Description:
      "An optional dealer-supplied value that provides detailed information about the vehicle's current status on the lot.\n",
    Tooltip: 'An optional dealer supplied value providing information on the vehicle status',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Filter Criteria-Promotion',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Promotion',
    Description:
      'Indicates if the vehicle was published to one of our affiliates during the reporting period.',
    Tooltip: 'Indicates if the vehicle was published during the period',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Filter Criteria-Condition',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Condition',
    Description:
      'A condition category to help understand the general state and quality of the vehicle.',
    Tooltip: 'A classification that aids in comprehending the overall condition of the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Filter Criteria-Year',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Year',
    Description:
      'The model year of a vehicle relates more to specification and less to the date/time the vehicle was manufactured. It is normal to have a car that was manufactured in 2019 but with a model year of 2020.',
    Tooltip:
      'The model year of a car relates more to specification than when the vehicle was manufactured',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Filter Criteria-Make',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Make',
    Description:
      'A vehicle make typically refers to the Brand of a motor vehicle but can also be the manufacturer.',
    Tooltip: 'A vehicle make refers to the brand of a vehicle but can also be the manufacturer',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Filter Criteria-Model',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Model',
    Description:
      'Refers to a specific vehicle design or version produced by a manufacturer, characterized by unique features and specifications.',
    Tooltip: 'Refers to a specific vehicle design produced by a manufacturer',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Filter Criteria-Body Style',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Body Style',
    Description:
      "Body style is a more specific term that describes the finer details of a vehicle's exterior design. It encompasses the specific aesthetic and functional elements that distinguish one model from another within a given body type.",
    Tooltip: "A more specific term that describes the finer details of a vehicle's exterior design",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Filter Criteria-DOL',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'DOL',
    Description: 'The amount of days a vehicle was seen for sale at the dealership.',
    Tooltip: 'The number of days a vehicle was seen for sale at the dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Filter Criteria-Price',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Price',
    Description: 'The price at which a vehicle is currently being offered for sale.',
    Tooltip: 'The price at which a vehicle is currently being offered for sale',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Filter Criteria-Days Since Last Price Change',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Days Since Last Price Change',
    Description: "Is the number of days since the most recent adjustment to the vehicle's price.",
    Tooltip: "The number of days since the most recent adjustment to the vehicle's price",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Filter Criteria-Current Price to Market',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Current Price to Market',
    Description: "Is the comparison of the vehicle's current price to market of similar vehicles.",
    Tooltip: 'Compares the vehicles current price to a market of similar vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Filter Criteria-Photo Count',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Filter Criteria',
    Metric: 'Photo Count',
    Description: 'The number of photos associated with the vehicle.',
    Tooltip: 'The number of photos associated with the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-VIN',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'VIN',
    Description:
      'A vehicle identification number is a unique code, including a serial number, used by the automotive industry to identify individual motor vehicles',
    Tooltip: 'A unique code for automotive identification',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-Stock #',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Stock #',
    Description:
      "A stock number on a dealership website is a unique identifier assigned to a specific vehicle in the dealer's inventory. It is used to distinguish and track individual vehicles, making it easier for both the dealership staff and potential customers to reference and locate a particular car.",
    Tooltip: "A unique identifier assigned to a specific vehicle in the dealer's inventory",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-Condition',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Condition',
    Description:
      'A condition category to help understand the general state and quality of the vehicle.',
    Tooltip: 'A classification that aids in comprehending the overall condition of the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-Year',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Year',
    Description:
      'The model year of a vehicle relates more to specification and less to the date/time the vehicle was manufactured. It is normal to have a car that was manufactured in 2019 but with a model year of 2020.',
    Tooltip:
      'The model year of a car relates more to specification than when the vehicle was manufactured',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-Make',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Make',
    Description:
      'A vehicle make typically refers to the Brand of a motor vehicle but can also be the manufacturer.',
    Tooltip: 'A vehicle make refers to the brand of a vehicle but can also be the Manufacturer',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-Model',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Model',
    Description:
      'Refers to a specific vehicle design or version produced by a manufacturer, characterized by unique features and specifications.',
    Tooltip: 'Refers to a specific vehicle design produced by a manufacturer',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-Mileage',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Mileage',
    Description: 'Mileage pertains to the total distance a vehicle has been driven',
    Tooltip: 'Mileage indicates the total distance a vehicle has traveled, typically in miles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-DOL',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'DOL',
    Description: 'The amount of days a vehicle was seen for sale at the dealership.',
    Tooltip: 'The number of days a vehicle was seen for sale at the dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-Photo Count',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Photo Count',
    Description: 'The number of photos associated with the vehicle.',
    Tooltip: 'The number of photos associated with the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Vehicle Details-Direct',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Direct',
    Description:
      'Direct traffic refers to visitors who type your website URL directly into their browser or use bookmarks. It represents a direct, intentional visit to your site without an intermediary source like a search engine or external link.',
    Tooltip: 'Visitors intentionally navigating to your site by typing the URL or using bookmarks',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Organic',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Organic',
    Description:
      'Organic traffic represents visitors who find your website through unpaid, natural search engine results. It indicates that users discovered your site by entering relevant queries into a search engine like Google.',
    Tooltip: 'Visitors finding your website through unpaid, natural search engine results',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Paid Search',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Paid Search',
    Description:
      'Paid search, or pay-per-click (PPC) traffic, results from online advertising efforts. Advertisers pay a fee each time a user clicks on their ad, leading them to the advertised website.',
    Tooltip: 'Traffic resulting from online advertising efforts, with advertisers paying per click',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Social',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Social',
    Description:
      'Social traffic originates from various social media platforms. Users visit your website by clicking on links shared on social networks like Facebook, Twitter, Instagram, etc.',
    Tooltip: 'Traffic originating from clicks on links shared on social media platforms',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Email',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Email',
    Description:
      'Email traffic comes from users clicking on links within emails. This category includes visitors who navigate to your site by interacting with links in marketing emails or newsletters.',
    Tooltip: 'Users visiting your site by clicking links within marketing emails or newsletters',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Affiliates',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Affiliates',
    Description:
      'Affiliate traffic stems from partnerships with external entities or affiliates who promote your products or services. When users click on affiliate links and visit your site, it falls under the affiliates category.',
    Tooltip: 'Traffic from partners promoting your products or services through affiliate links',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Display',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Display',
    Description:
      'Display traffic is generated through visual advertisements, banners, or multimedia content on various websites. Users who click on display ads and subsequently visit your site contribute to the display traffic category.',
    Tooltip: 'Visits originating from users clicking on visual advertisements or banners online',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Lotlinx',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Lotlinx',
    Description:
      "Shopper traffic delivered to vehicle display pages through Lotlinx's publisher network.",
    Tooltip: "Shoppers delivered to vehicle display pages through Lotlinx's publisher network",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Other',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Other',
    Description:
      'Similar to the "Other" category in the previous list, the "Other" category in this context encompasses various sources of traffic that don\'t fit into the specified categories. It can include less common or specific sources not covered by the predefined classifications.',
    Tooltip: 'Miscellaneous traffic sources not falling into predefined categories',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Referral',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Referral',
    Description:
      'Referral traffic, comes from external sources such as websites or platforms that link to your site. When users click on referral links and visit your website, it is considered referral traffic.',
    Tooltip: 'Users clicking on external links leading to your site from other platforms',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Vehicle Details-Unavailable',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Vehicle Details',
    Metric: 'Unavailable',
    Description:
      'The "Unavailable" category may indicate instances where tracking information for the source of traffic is not available or cannot be determined. This could occur due to various reasons, such as limitations in tracking tools or data availability.',
    Tooltip:
      'Category indicating instances where tracking information for traffic source is unknown',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Dimension Filter-VDP Views',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Dimension Filter',
    Metric: 'VDP Views',
    Description: 'Refers to the amount of shoppers landing on respective vehicle display pages',
    Tooltip: 'Count of shoppers visiting specific vehicle display pages',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Dimension Filter-Spend',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Dimension Filter',
    Metric: 'Spend',
    Description: 'The amount of monthly budget spent on campaigns for VIN level advertising',
    Tooltip: 'The total amount of monthly budget spent on advertising',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Dimension Filter-DOL',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Dimension Filter',
    Metric: 'DOL',
    Description: 'The amount of days a vehicle was seen for sale at the dealership.',
    Tooltip: 'The number of days a vehicle was seen for sale at the dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Dimension Filter-Demand',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Dimension Filter',
    Metric: 'Demand',
    Description:
      'This term refers to the quantification of shoppers who are currently seeking to purchase a vehicle in the market, compared against the shopping activity observed over the past seven days.\nIn Market Shopper Demand Index: Represented on a scale from 0 to 100, this index assesses the In Market Shopper Demand over the preceding three months. An index value above 50 signifies that the demand is above the average for the observed period, with 100 indicating peak demand and 0 representing the minimum demand level.',
    Tooltip: 'The quantification of shoppers who are inquiring about vehicles',
    'Description + Tooltip Status': '',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Dimension Filter-VIN Score',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Dimension Filter',
    Metric: 'VIN Score',
    Description: '',
    Tooltip: '',
    'Description + Tooltip Status':
      'Ryan to take out of VIN Manager VVO. we will be building a new metric in 2024 - risk score',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Dimension Filter-Leads',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Dimension Filter',
    Metric: 'Leads',
    Description:
      "Leads that are successfully added into a dealer's customer relationship management system",
    Tooltip:
      "Leads that are successfully added into a dealer's customer relationship management system",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Sort Filter-VDP Views',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Sort Filter',
    Metric: 'VDP Views',
    Description: 'Refers to the amount of shoppers landing on respective vehicle display pages',
    Tooltip: 'Count of shoppers visiting specific vehicle display pages',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Sort Filter-Spend',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Sort Filter',
    Metric: 'Spend',
    Description: 'The amount of monthly budget spent on campaigns for VIN level advertising',
    Tooltip: 'The total amount of monthly budget spent on advertising',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: true,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Sort Filter-DOL',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Sort Filter',
    Metric: 'DOL',
    Description: 'The amount of days a vehicle was seen for sale at the dealership.',
    Tooltip: 'The number of days a vehicle was seen for sale at the dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': true,
  },
  {
    key: 'VVO-Sort Filter-Demand',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Sort Filter',
    Metric: 'Demand',
    Description: '',
    Tooltip: '',
    'Description + Tooltip Status':
      'Ryan unsure about this as we are incorporating demand in market opportunity now which is pulling from bidder data.',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Sort Filter-VIN Score',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Sort Filter',
    Metric: 'VIN Score',
    Description: '',
    Tooltip: '',
    'Description + Tooltip Status':
      'Ryan to take out of VIN Manager VVO. we will be building a new metric in 2024 - risk score',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Sort Filter-Leads',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Sort Filter',
    Metric: 'Leads',
    Description:
      "Leads that are successfully added into a dealer's customer relationship management system",
    Tooltip:
      "Leads that are successfully added into a dealer's customer relationship management system",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Mode Filter-Channel',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Mode Filter',
    Metric: 'Channel',
    Description:
      'Refers to a specific platform, medium, or avenue through which advertisements are delivered to target audiences. Channels can include various online platforms such as social media (e.g., Facebook, Instagram), search engines (e.g., Google), display networks, email marketing, video platforms, and other digital outlets',
    Tooltip: 'Platform or medium used for delivering advertisements to various audiences',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Mode Filter-Source Medium',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Mode Filter',
    Metric: 'Source Medium',
    Description:
      'Source medium typically refers to the specific origin and medium through which a user interacts with a website or online content, particularly in the context of web analytics and tracking. It helps in identifying the source of traffic and understanding how users discover and access a website.',
    Tooltip: "Origin of a user's interaction with a website",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Mode Filter-Listing Sites',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Mode Filter',
    Metric: 'Listing Sites',
    Description:
      'In the context of online advertising, a "listing site" typically refers to a website or platform that aggregates and displays advertisements or sponsored listings from various advertisers. These sites act as intermediaries between advertisers and users, providing a space for businesses to showcase their products or services.',
    Tooltip: 'Ad/listing platforms for vehicle ad exposure and user discovery',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VVO-Mode Filter-Source',
    'Report ': {
      ' Dashboard': 'VVO',
    },
    Category: 'Mode Filter',
    Metric: 'Source',
    Description:
      "Source refers to the lead's status in the dealer's CRM system, such as active, awaiting response, sold, or having incorrect or no contact information",
    Tooltip: 'Lead status in CRM - active, awaiting, sold, or contact issues',
    'Description + Tooltip Status': 'Sunny to review Ryans explanation and update',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Market Opportunity-Demand-In-market shoppers',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Demand',
    Metric: 'In-market shoppers',
    Description:
      'Demand is our approximation of how many potential shoppers are available in the market. We call them shoppers from the distribution network, because in the dealers view they are potential shoppers from the distribution network, whether or not they click on one of our ads',
    Tooltip: 'Estimate of potential shoppers in the market from the distribution network',
    'Description + Tooltip Status': 'Called Shopper Demand on Inventory Page',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Demand-% of VINs viewed',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Demand',
    Metric: '% of VINs viewed',
    Description:
      'Is based on calculation of inventory coverage based on vehicle display page (VDP) views. Formula should be (Total VINs Viewed / Total Inventory)',
    Tooltip: 'Ratio of VDP views to the total vehicles in inventory',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Demand-Trend of % VINs viewed',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Demand',
    Metric: 'Trend of % VINs viewed',
    Description: 'A time scale representation of VINs that are receiving VDP views',
    Tooltip: 'A trendline representation of VINs that are receiving VDP views',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Demand-Demand Pace',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Demand',
    Metric: 'Demand Pace',
    Description:
      'Net new shoppers can be calculated by the total new shoppers divided by the total count of shoppers. This is a rolling average.',
    Tooltip: 'Ratio of total new shoppers divided by total count of shoppers',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes':
      'This may have changed on the front end to a different metric? was Demand pace, now % net new shoppers',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Sales-Sales Volume',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Sales',
    Metric: 'Sales Volume',
    Description: 'An approximation of how much inventory has sold across the month',
    Tooltip: 'Approximation of how much inventory has sold in the market',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Sales-Sales volume (last month)',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Sales',
    Metric: 'Sales volume (last month)',
    Description: 'A time series representation of sold vehicles',
    Tooltip: 'A trendline of the estimated number of vehicles sold by the dealership.',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Sales-Average Days to Sale',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Sales',
    Metric: 'Average Days to Sale',
    Description:
      'The average length of time it takes to sell a vehicle compared to the market average',
    Tooltip:
      'Average estimated length of time it takes to sell a vehicle compared to the market average',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Sales-Market Share',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Sales',
    Metric: 'Market Share',
    Description:
      'Market Share is a metric on how well the dealer’s segment is performing against the market. Typically this is against similar make model and body style level. Most franchise dealers measure their success on their sales penetration in their market at the make model body style level in order to gain an advantage with their OEM in terms of favorable stocking vehicles. Typically market share is based on sales volumes\n\nA dealers percent of sales in a segment relative to sales in the market. For example, if 50 Toyota Highlanders sold in your market in the last 30 days and you sold 20 of them, then your market share is 40% ',
    Tooltip: 'A trendline of sales as a % of total market sales for similar vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Stock-Listed Units',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Stock',
    Metric: 'Listed Units',
    Description: 'A trend line of vehicles that exist in the marketplace',
    Tooltip: 'Inventory trend of available vehicles in the market',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Stock-Market Days Supply',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Stock',
    Metric: 'Market Days Supply',
    Description:
      'Market Day’s Supply is calculated by dividing current market quantity of the vehicle at its year, make, model, trim level by the average \ndaily retail sales rate over the past 45 days.\nAverage daily retail sales rate = divide the number of sales (i.e., vehicles sold) over the past 45 days by 45 to get the average daily sales rate',
    Tooltip: 'Time estimate to sell inventory based on the past 45-day sales rate',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Stock-Segment unit total',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Stock',
    Metric: 'Segment unit total',
    Description: 'How many vehicles you have in inventory for a particular segment',
    Tooltip: 'Inventory count across a timeline for a specific vehicle segment',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Stock-Media Risk Inventory',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Stock',
    Metric: 'Media Risk Inventory',
    Description:
      "Media Risk Inventory is the segment of a dealer's inventory that is not receiving enough online exposure which can be assessed through two key parameters.\n1. Daily Media Risk: Refers to vehicles that receive one or fewer views on a VDP per day\n2. Ranged Risk: Refers to vehicles that average one of fewer VDP views per day over a 30 day period.\n\nAt this time, we are only leveraging Ranged Risk on VIN Manager. It's a percentage.\nThe number of vins with <= 1 vdp per day for over a time period (30 day) divided by number of all vins.",
    Tooltip:
      'Refers to the ratio of vehicles that has one or fewer views per day over a 30 day period',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes':
      'Confirmed with Angel and Pedro that VIN Manager is using Ranged Risk from test-index-mediarisk',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Market Opportunity-Stock-Price to Market',
    'Report ': {
      ' Dashboard': 'Market Opportunity',
    },
    Category: 'Stock',
    Metric: 'Price to Market',
    Description: 'The average price of vehicles compared to the market',
    Tooltip: 'The average price of vehicles compared to the market',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Sales Volume',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Sales Volume',
    Description:
      'Sales volume is defined as the distinct number of VINs sold within the start and end date time period. Sales volume is specific to the the segment chosen in the report (New/SUV) and specific to the aggregation period.',
    Tooltip: 'Distinct number of VINs sold',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Days to Sale',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Days to Sale',
    Description:
      'Days to sale refers to the average number of days the segment of inventory filter (New/SUV) takes to sell within the aggregation period.\nThe point of sale is identified as the last instance when the VIN was detected in our Lotlinx data (i.e., when we last crawled it, or saw it in our feed data).',
    Tooltip: 'Average time for inventory to sell ',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Average Markdown',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Average Markdown',
    Description:
      'The calculation involves determining the average markdown per vehicle that experienced a markdown within the specified time frame. As an illustration, consider a vehicle whose price has been consistently reduced by $100 over five consecutive days. This leads to a total lifetime markdown of -$500 and an average markdown per occurrence of $100.',
    Tooltip: 'Average discount on vehicles that have been marked down ',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Front End Gross',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Front End Gross',
    Description:
      'Front end gross is what a dealer makes on a deal before factoring in commissions, overhead, expenses etc. It helps a dealer answer, “What’s my general profit before any deductions?”\nThe metric is actually an estimate that is based on analysis of Publicly Traded Dealerships filed quarterly reports and estimates derived from the dealer management system (DMS) data. Is not meant to be authoritative but is a good guide that is consistently applied across all dealers. This is for the store and is an aggregation of all the front end gross estimates and it will be based on the time period in the UI.',
    Tooltip: 'Estimated profit amount before layering in commissions, overhead, expenses, etc',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Sell Down Rate',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Sell Down Rate',
    Description:
      'Sell down rate can be defined as the total units sold in a 30 day period divided by the total units listed in a 30 day period. What they are selling versus what they are holding.',
    Tooltip: 'Ratio of units sold to units listed in a 30-day period',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--PVR',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'PVR',
    Description:
      'Per VIN Retailed or Per Vehicle Revenue (PVR) is a metric used in the automotive sales industry that represents the average profit a dealership makes on each vehicle sold. This figure typically includes all income and commission generated from the sale of a vehicle before costs and expenses are deducted.',
    Tooltip: 'Estimated average profit per vehicle sold',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: true,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Price to Market',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Price to Market',
    Description:
      'What is the price of your inventory segment compared to the market. Example: 110% means you’re 10% above market.',
    Tooltip: 'Comparing vehicle price against average market price',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': true,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--% VINs Published',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: '% VINs Published',
    Description:
      'The key metric is based on the # of VINs being published through the Lotlinx system vs the total # of VINs in the dealer’s feed. It should be noted that the % is based on Segments and should reflect that as well. For example, a dealer has 100 Trucks and publishes 25 of the Trucks through Lotlinx. This means that the dealer will have 25% of their VINs published\nWhen you see peaks and valleys that could be the dealer changing advertising strategy between different segments of vehicles.',
    Tooltip:
      'Ratio of vehicles published through Lotlinx to the total number found in the feed data',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--% VINs Viewed',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: '% VINs Viewed',
    Description:
      'Is based on calculation of inventory coverage based on vehicle display page (VDP) views. Formula should be (Total VINs Viewed / Total Inventory)',
    Tooltip: 'Ratio of VDP views to the total vehicles in inventory',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--% Net New Shoppers',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: '% Net New Shoppers',
    Description:
      'Net new shoppers can be calculated by the total new shoppers divided by the total count of shoppers. This is a rolling average.',
    Tooltip: 'Ratio of total new shoppers divided by total count of shoppers',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Leads',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Leads',
    Description:
      "Leads that are successfully added into a dealer's customer relationship management system",
    Tooltip:
      "Leads that are successfully added into a dealer's customer relationship management system",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Average Markdown Percentage (name change likely)',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Average Markdown Percentage (name change likely)',
    Description:
      'In this situation, "Average Markdown Percentage" is a metric used to quantify the average reduction in price from the original list price to the sale price of all sold vehicles for a dealership, expressed as a percentage. It is applicable only when vehicles sell at a price lower than their original list price. For example, for a dealer that has:\nCar #1, originally listed at $50,000 and sold for $45,000, has a Markdown Percentage of 10%.\nCar #2, originally listed at $60,000 and sold for $48,000, has a Markdown Percentage of 20%.\nCar #3, originally listed at $50,000 and sold for $51,000, has a NULL Markdown Percentage.\nThe Average Markdown Percentage of this dealer is 15%.\n\n\n\n\n\n\n',
    Tooltip: 'Average discount on vehicles that have been marked down ',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes':
      'Added close to NADA- (avg_pct_price_movement)\nNeeds a better name. Have suggested some to Ryan\nThis is an "average" not median',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Sold Hold Ratio',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Sold Hold Ratio',
    Description:
      "This is the ratio of sold units to active units (i.e., what you're holding in inventory). It is calculated across a 30 day rolling average for sold units and daily for the units in inventory. \nFor example:  if in the last 30 days I sell 10 cars and I have 100 cars that I'm holding as of yesterday. My Sold Hold Ratio is 10%.",
    Tooltip: "Ratio of sold units to active units (i.e., what you're holding in inventory)",
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Days Supply',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Days Supply',
    Description:
      'Day’s Supply is calculated by dividing current market quantity of the vehicle at its year, make, model, trim level by the average daily retail sales rate over the past 45 days.\nAverage daily retail sales rate = divide the number of sales (i.e., vehicles sold) over the past 45 days by 45 to get the average daily sales rate',
    Tooltip: 'Current vehicle stock / average sales rate over 45 days',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Before and After--Market Share',
    'Report ': {
      ' Dashboard': 'Before and After',
    },
    Category: '',
    Metric: 'Market Share',
    Description:
      'Market Share is a metric on how well the dealer’s segment is performing against the market. Typically this is against similar make model and body style level. Most franchise dealers measure their success on their sales penetration in their market at the make model body style level in order to gain an advantage with their OEM in terms of favorable stocking vehicles. Typically market share is based on sales volumes\n\nA dealers percent of sales in a segment relative to sales in the market. For example, if 50 Toyota Highlanders sold in your market in the last 30 days and you sold 20 of them, then your market share is 40% ',
    Tooltip: 'Sales as a % of total market sales for similar vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Main Banner-Total Views',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Main Banner',
    Metric: 'Total Views',
    Description:
      'The cumulative number of shoppers that were directed to a vehicle display page across all traffic sources for the lifetime of the vehicle at that specific dealership.',
    Tooltip: "Cumulative total of shoppers guided to a dealership's VDP from all sources over time",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': true,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Main Banner-Views/DOL',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Main Banner',
    Metric: 'Views/DOL',
    Description:
      "Refers to the ratio of Vehicle Detail Page views to the number of days a vehicle remains on a dealer's lot. This metric is commonly used in the automotive industry to assess the level of interest and engagement a specific vehicle receives relative to the duration it is available for sale. A higher ratio suggests that the vehicle is attracting more views in relation to the time it spends on the lot, indicating potential popularity or desirability among potential buyers.",
    Tooltip: "The ratio of VDP views to the number of days a vehicle remains on the dealer's lot",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Main Banner-Views/Day (In Campaign)',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Main Banner',
    Metric: 'Views/Day (In Campaign)',
    Description:
      'Refers to the average number of VDP views per day during the vehicles time in a campaign. It is calculated by dividing the total number of VDP views by the number of days it has been active in the campaign. ',
    Tooltip: 'Daily average views of a VDP during its time in a campaign',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Main Banner-Total Conv.',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Main Banner',
    Metric: 'Total Conv.',
    Description:
      'A shopper could perform any of the following actions to be considered a conversion. This includes: lead form submissions, click to call, click to chat, and click to text. The definition of conversion (i.e., the list of actions) could change over time.',
    Tooltip: "When a shopper takes actionable interest in a vehicle on the dealer's site",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Main Banner-Total Spend',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Main Banner',
    Metric: 'Total Spend',
    Description: 'The amount of monthly budget spent on campaigns for VIN level advertising',
    Tooltip: 'The total amount of monthly budget spent on advertising',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Main Banner-Spend/DOL',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Main Banner',
    Metric: 'Spend/DOL',
    Description:
      'Refers to the amount of money a dealership spends on advertising for each day a vehicle remains on the lot.',
    Tooltip:
      'Refers to the amount of money a dealership spends on advertising for each day a vehicle remains on the lot',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Main Banner-Spend/Day (in Campaign)',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Main Banner',
    Metric: 'Spend/Day (in Campaign)',
    Description:
      'Refers to the average amount of money spent each day on an advertising a vehicle while in a campaign. This metric is calculated by dividing the total expenditure spent by the number of days the campaign runs.',
    Tooltip: 'Daily average cost of advertising a vehicle while in a campaign',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes':
      'spend per day is based on $ per day in a campaign which is different than DOL',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Main Banner-DOL',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Main Banner',
    Metric: 'DOL',
    Description: 'The amount of days a vehicle was seen for sale at the dealership.',
    Tooltip: 'The number of days a vehicle was seen for sale at the dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': true,
  },
  {
    key: 'VIN Detail Modal-(sub) VDP-Total Site VDP Views',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) VDP',
    Metric: 'Total Site VDP Views',
    Description:
      'The cumulative number of shoppers that were directed to a vehicle display page across all traffic sources for the lifetime of the vehicle at that specific dealership.',
    Tooltip: 'Lifetime cumulative shoppers directed to a VDP from all sources at the dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) VDP-Lotlinx VDP Views',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) VDP',
    Metric: 'Lotlinx VDP Views',
    Description:
      'The total amount of shoppers that Lotlinx redirected to a vehicle display page (VDP).',
    Tooltip: 'The total amount of shoppers that Lotlinx redirected to a vehicle display page (VDP)',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) VDP-Lotlinx Conversions',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) VDP',
    Metric: 'Lotlinx Conversions',
    Description:
      "To be classified as a conversion during the vehicle's stay at the dealership, a shopper may engage in various actions, such as submitting lead forms, clicking to call, chat, or text. These shoppers were redirected to a VDP by Lotlinx. The definition of conversion actions may evolve over time.",
    Tooltip:
      'Actions performed by a Lotlinx shopper like lead forms, calls, chat, or texts that occured',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Buyer Demand-# of Buyers (Last Day)',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Buyer Demand',
    Metric: '# of Buyers (Last Day)',
    Description:
      'The amount of shoppers that clicked on the vehicle display page on the previous day',
    Tooltip: 'The amount of shoppers that clicked on the vehicle display page on the previous day',
    'Description + Tooltip Status': 'We should not use "buyers"',
    'Feedback Notes': 'sure change the labelling',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Buyer Demand-Avg Buyers per day',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Buyer Demand',
    Metric: 'Avg Buyers per day',
    Description:
      'The average number of shoppers who clicked on the vehicle display page throughout the time period.',
    Tooltip: 'The average number of shoppers who clicked on the VDP throughout the time period',
    'Description + Tooltip Status': 'We should not use "buyers"',
    'Feedback Notes': 'sure change the labelling',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Buyer Demand-Demand Trend',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Buyer Demand',
    Metric: 'Demand Trend',
    Description:
      'Measures the level of shopper interest for a vehicle during the specified period. Monitoring demand based on VDP visitors helps assess the effectiveness of marketing strategies and the overall market interest in the featured vehicle over the specified duration.',
    Tooltip: 'An indicator that summarizes the interest in the vehicle during a specified period',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-This VIN Mileage',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'This VIN Mileage',
    Description:
      '\nMileage specifically pertains to the total distance a vehicle has been driven, usually measured in miles.',
    Tooltip: 'Mileage indicates the total distance a vehicle has traveled, typically in miles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Avg. Market Price',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Avg. Market Price',
    Description: 'This is the average selling price of a vehicle across the entire country',
    Tooltip: 'The average listed price of a vehicle across the entire country',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Avg. DOL Sold',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Avg. DOL Sold',
    Description:
      'The average days on lot for similar vehicles that sold in the market. We assume the vehicle sold if it was removed from the online.',
    Tooltip: 'The time similar vehicles typically stay on market before selling',
    'Description + Tooltip Status':
      "Should be: The average DOL for similar vehicles that have sold. Check if this aligns elsewhere. There was confusion around similar VINs but we were under Market. Tom not a fan of showing exact numbers for 'market averages' because we have not dialed it in yet.\n\nThe naming label isn't great. This brings up the question, when we say Average DOL, does that imply this calculation was on sold vehicles?",
    'Feedback Notes':
      'Resolved with Derek and Ryan:\nthis metric is based on the similar vehicles in the market on their average dol before it was removed online.\n\nNeed to create ticket for label change of Average and Median',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Avg. Market Mileage',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Avg. Market Mileage',
    Description:
      'Refers to the typical or mean distance traveled by vehicles of a specific make, model, or category within a particular market or geographic area. This metric is often used in the automotive industry to provide a standard measure of how much a vehicle of a certain type is expected to have traveled over a given period.',
    Tooltip: 'Average mileage of vehicles in market comparison',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Avg. Market Sale Price',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Avg. Market Sale Price',
    Description:
      'This refers to the typical selling price of a vehicle within the market. This metric provides a standard measure for evaluating the pricing trends and expectations within a given market or category.',
    Tooltip: 'Average sale price of vehicles in market comparison',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Current Market Ranking Position',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Current Market Ranking Position',
    Description:
      'This can be determined by identifying vehicles of the same year, make, and model within a 30-mile radius and then rank them based on their pricing.',
    Tooltip: 'Rank similar vehicles by YMM and order by price to assess their respective position',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Avg. Mileage Sold',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Avg. Mileage Sold',
    Description:
      'The typical distance traveled by vehicles that have been successfully sold, providing insight into the usage patterns and conditions of vehicles within a specific market or context.',
    Tooltip: 'Average mileage of sold vehicles, indicating usage and condition',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-This VIN DOL',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'This VIN DOL',
    Description: 'The amount of days a vehicle was seen for sale at the dealership.',
    Tooltip: 'The amount of days a vehicle was seen for sale at the dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Active Similar Make Model',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Active Similar Make Model',
    Description:
      'The count of comparable vehicles that are for sale in the market as of yesterday.',
    Tooltip: 'The count of comparable vehicles that are for sale in the market as of yesterday',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-This VIN Price',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'This VIN Price',
    Description: 'The price at which a vehicle is currently being offered for sale.',
    Tooltip: 'The price at which a vehicle is currently being offered for sale',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Avg. Market DOL',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Avg. Market DOL',
    Description:
      'Refers to the typical duration that vehicles remain available for sale in the market before being sold.',
    Tooltip: 'Average time vehicles stay in market until sold',
    'Description + Tooltip Status':
      "There are probably several metrics that we use 'average' but it's actually an median. We will need a decision on nomenclature. Should we settle on a path forward about what we call it even if it doesn't mean 'average'. Ryan thinks price related metrics are probably median. We may need a to take a position and check for anyone who feels different.",
    'Feedback Notes': 'If we use median calculation then we can use the term Median',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-(sub) Market-Sold Similar Make Model',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: '(sub) Market',
    Metric: 'Sold Similar Make Model',
    Description:
      'This refers to previously sold vehicles of similar make and model, providing historical data on completed sales within a specific context or timeframe.',
    Tooltip: 'A count of previously sold vehicles of similar make and model',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Insights (Powered by Lexaca)-Vehicle Insights',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Insights (Powered by Lexaca)',
    Metric: 'Vehicle Insights',
    Description:
      'Provides a detailed overview of a specific vehicle’s characteristics and its uniqueness within the market.\n',
    Tooltip: "Provides a detailed overview of a vehicle's characteristics within the market",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Insights (Powered by Lexaca)-Market Insights',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Insights (Powered by Lexaca)',
    Metric: 'Market Insights',
    Description: 'Offers a broader view of the vehicle’s standing in the current market.',
    Tooltip: 'Offers a broader view of the vehicle’s standing in the current market',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Insights (Powered by Lexaca)-Pricing Insights',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Insights (Powered by Lexaca)',
    Metric: 'Pricing Insights',
    Description: 'Delves into the pricing history and current market positioning of the vehicle.',
    Tooltip: 'Delves into the pricing history and current market positioning of the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Insights (Powered by Lexaca)-Advertising Insights',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Insights (Powered by Lexaca)',
    Metric: 'Advertising Insights',
    Description: 'Focuses on the performance of the vehicle’s marketing efforts.',
    Tooltip: 'Focuses on the performance of the vehicle’s marketing efforts',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Insights (Powered by Lexaca)-Recommended Actions',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Insights (Powered by Lexaca)',
    Metric: 'Recommended Actions',
    Description:
      'Synthesizes all the previous insights to provide strategic recommendations for the dealership. Recommendations are aimed at optimizing the vehicle’s marketability.',
    Tooltip:
      'Summarizes all the previous insight Recommendations are aimed for optimal marketability',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': false,
  },
  {
    key: 'VIN Detail Modal-Leads-Recent Leads',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Leads',
    Metric: 'Recent Leads',
    Description: 'Leads that have been ordered by date received. Most recent to oldest. ',
    Tooltip: 'Leads that have been ordered by date received. Most recent to oldest. ',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'VIN Detail Modal-Leads-Source',
    'Report ': {
      ' Dashboard': 'VIN Detail Modal',
    },
    Category: 'Leads',
    Metric: 'Source',
    Description:
      "Source refers to the lead's status in the dealer's CRM system, such as active, awaiting response, sold, or having incorrect or no contact information",
    Tooltip: 'Lead status in CRM - active, awaiting, sold, or contact issues',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Photo',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Photo',
    Description: 'One of the images used in advertising the vehicle.',
    Tooltip: 'An image previewing the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Summary',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Summary',
    Description:
      "A car's make is the brand. The model is the name of a car product or a line of cars. For example, Toyota is a car make and Camry is a model. The make, model, model year, body style and trim level all affect the cost . Trim describes differences between cars of the same model.",
    Tooltip: 'Essential vehicle information (i.e., year, make, model, and body style)',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Mileage',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Mileage',
    Description:
      'Mileage the dealer has indicated is the current mileage at the time of advertising the vehicle, typically in miles.',
    Tooltip: 'Mileage indicates the total distance a vehicle has traveled',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-DOL',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'DOL',
    Description:
      'Days on Lot (DOL) is the number of days a vehicle was seen for sale at the dealership website or has been provided to Lotlinx in a data feed from the dealer.',
    Tooltip: 'The number of days a vehicle was seen for sale at the dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': true,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Trim',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Trim',
    Description:
      'Trim refers to the specific version or configuration of a vehicle model that may include variations in engine, features, options, and equipment.',
    Tooltip: 'Specific vehicle model version with unique features and options',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Sold Inventory-Vehicle-Body Style',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Body Style',
    Description:
      "Body style describes the general characteristics of a vehicle's exterior design. It encompasses the specific aesthetic and functional elements that distinguish from a model in a different body style. For example, SUV versus PICKUP",
    Tooltip: "A more specific term that describes the finer details of a vehicle's exterior design",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': false,
  },
  {
    key: 'Sold Inventory-Vehicle-Stock #',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Stock #',
    Description:
      "The dealership's unique identifier for a specific vehicle in inventory. It is a substitute for VIN as it is operationally easier to communicate.",
    Tooltip: "The dealership's identifier for a specific vehicle in inventory",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-VIN',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'VIN',
    Description:
      'A vehicle identification number is a unique code, used by the automotive industry to identify individual motor vehicles.',
    Tooltip: 'A VIN is a unique code, used by the automotive industry to identify motor vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Condition',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Condition',
    Description:
      'A vehicle identification number is a unique code, used by the automotive industry to identify individual motor vehicles.',
    Tooltip: 'A VIN is a unique code, used by the automotive industry to identify a motor vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Make',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Make',
    Description:
      "Technically, a car's make is the company that made it, for example: Ford, Honda, Volkswagen. In practice the make typically refers to the brand of a motor vehicle but can also be the manufacturer. For example, Stellantis is the OEM / Make and has the brand JEEP however, brand and make are sometimes used interchangeably.",
    Tooltip: 'A vehicle make refers to the brand of a vehicle but can also be the manufacturer',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Year',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Year',
    Description:
      'the year used to designate a discrete vehicle model, irrespective of the calendar year in which the vehicle was actually produced, so long as the actual period is less than two calendar years.',
    Tooltip:
      'The model year of a car relates more to specification than when the vehicle was manufactured',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Photo Count',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Photo Count',
    Description: 'The number of photos associated with the vehicle.',
    Tooltip: 'The number of photos associated with the vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Vehicle-Model',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Vehicle',
    Metric: 'Model',
    Description:
      'The model of a vehicle specifies its design and version, marked by distinct features and specifications',
    Tooltip: 'Refers to a specific vehicle design produced by a manufacturer',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Status-Vehicle Status',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Status',
    Metric: 'Vehicle Status',
    Description:
      'Indicates if a vehicle is active, pending, sold, or moved. This status may change over time.',
    Tooltip: 'Shows the vehicle status: active, pending, sold, or moved. Status may change.',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: true,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Budget-Spend (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Budget',
    Metric: 'Spend (lifetime)',
    Description: 'The budget amount spent selling the vehicle across its lifetime',
    Tooltip: 'The aggregate spend on advertising activities to promote and sell a vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Budget-Lotlinx Days in Campaign (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Budget',
    Metric: 'Lotlinx Days in Campaign (lifetime)',
    Description:
      'The amount of days that Lotlinx actively featured or promoted a specific vehicle across all ad campaigns for the respective dealership.',
    Tooltip: 'Lifetime duration of a vehicle in a Lotlinx ad campaign',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Shoppers Delivered (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Shoppers Delivered (lifetime)',
    Description:
      "The cumulative number of shoppers that we've delivered across the lifetime of the vehicle for that respective dealership.",
    Tooltip: "The cumulative shopper count delivered over the vehicle's lifetime",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Lotlinx VDPs (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lotlinx VDPs (lifetime)',
    Description:
      "The cumulative number of shoppers directed to a vehicle display page exclusively through Lotlinx throughout the vehicle's tenure at the dealership.",
    Tooltip: "The cumulative VDP views delivered from Lotlinx over the vehicle's lifetime\n",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Total VDPs (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Total VDPs (lifetime)',
    Description:
      'The cumulative number of shoppers that were directed to a vehicle display page across all traffic sources for the lifetime of the vehicle at that specific dealership.',
    Tooltip: 'Lifetime cumulative shoppers directed to a VDP from all sources',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Cost per VDP (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Cost per VDP (lifetime)',
    Description:
      "This is the cost incurred for each shopper that was directed to a vehicle's display page.",
    Tooltip:
      'The cumulative lifetime cost incurred to direct shoppers to a VDP at the respective  dealership',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Lotlinx % of VDPs (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lotlinx % of VDPs (lifetime)',
    Description:
      "The proportion of shoppers generated by Lotlinx compared to all traffic sources throughout the vehicle's lifespan.",
    Tooltip:
      "Lotlinx generated shoppers compared to all traffic sources for the vehicle's lifetime",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Conversions (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Conversions (lifetime)',
    Description:
      "To be classified as a conversion during the vehicle's stay at the dealership, a shopper may engage in various actions, such as submitting lead forms, clicking to call, chat, or text. The definition of conversion actions may evolve over time.",
    Tooltip:
      'Actions like lead forms, calls, chat, or texts that occurred across the vehicles lifetime',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Lead Form Submissions (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Lead Form Submissions (lifetime)',
    Description:
      "The cumulative number of website visitors who submit a form on the dealership's website during the vehicle's tenure, expressing interest and seeking further vehicle information. This form collects their contact details.",
    Tooltip: 'A cumulative count of shoppers submitting forms across the vehicles lifetime',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Click to Call (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Call (lifetime)',
    Description:
      "This refers to the total count of shoppers who have used the click-to-call feature on the dealership's website across the entire lifespan of the vehicle being on the dealers website",
    Tooltip: 'Cumulative count of all click-to-call shoppers',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Click to Chat (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Chat (lifetime)',
    Description:
      'This refers to the total number of shoppers who have used the click to chat feature on the dealers website across the entire lifespan of the vehicle being on the dealers website',
    Tooltip: 'Cumulative count of all click-to-chat shoppers for a vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Engagement-Click to Text (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Engagement',
    Metric: 'Click to Text (lifetime)',
    Description:
      "This refers to the total number of shoppers who have used the click to text feature on the dealers website across the entire lifespan of the vehicle being on the dealer's website",
    Tooltip: 'Cumulative count of all click-to-text shoppers for a vehicle',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Price-Initial Price',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Price',
    Metric: 'Initial Price',
    Description: 'Is the initial listed price of the vehicle.',
    Tooltip: "The vehicle's initial listed price",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Price-Total # of Price Changes',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Price',
    Metric: 'Total # of Price Changes',
    Description:
      'This is a count of how many times a price has been adjusted or altered since the initial price.',
    Tooltip: 'Total count of price adjustments since the initial listing',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Price-Avg Price Change',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Price',
    Metric: 'Avg Price Change',
    Description:
      'The average alteration in price while the vehicle was on sale. The average is calculated by dividing the total price by the number of price changes.',
    Tooltip: 'Average alteration in price while the vehicle is available for purchase',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Price-Initial Price to Market %',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Price',
    Metric: 'Initial Price to Market %',
    Description:
      'Is the comparison of the VINs initial sale price to the market based on similar VINs.',
    Tooltip: 'Initial price comparison with similar market vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': true,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Price-Total leads by status',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Price',
    Metric: 'Total leads by status',
    Description:
      'The total count of leads by status within the CRM system. Example statuses include Open, Appointment, or Dead.',
    Tooltip: "Total lead count by status within the dealership's CRM system",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Leads-Lotlinx leads (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Leads',
    Metric: 'Lotlinx leads (lifetime)',
    Description:
      'The cumulative number of leads generated by Lotlinx through our publisher network',
    Tooltip: "Total leads from Lotlinx's network recorded in the dealer's CRM",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Sold Inventory-Leads-Total leads (lifetime)',
    'Report ': {
      ' Dashboard': 'Sold Inventory',
    },
    Category: 'Leads',
    Metric: 'Total leads (lifetime)',
    Description:
      'The cumulative number of leads that a dealer may receive through other means outside of Lotlinx',
    Tooltip: "Total leads from all channels recorded in the dealer's CRM",
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: 1,
    'Live Inventory': false,
    Campaign: false,
    VVO: false,
    'Market Opportunity': false,
    'Before and After': false,
    'VIN Detail Modal': false,
    'Sold Inventory': true,
  },
  {
    key: 'Settings-Dealer Settings-Key competitors',
    'Report ': {
      ' Dashboard': 'Settings',
    },
    Category: 'Dealer Settings',
    Metric: 'Key competitors',
    Description:
      "A feature that enables users to select up to five dealerships they consider key competitors. This functionality allows for targeted comparison and benchmarking, enabling dealers to strategically monitor and analyze their primary competition's performance, strategies, and market presence. By identifying and observing these competitors, users can better position their dealership to compete effectively in their local market.",
    Tooltip: 'Select dealerships for targeted comparison and bespoke local market benchmarking.',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Settings-Dealer Settings-In-market dealers',
    'Report ': {
      ' Dashboard': 'Settings',
    },
    Category: 'Dealer Settings',
    Metric: 'In-market dealers',
    Description:
      'In-market dealers refer to all dealership locations within a specific geographic area where a dealership operates and competes. This list includes the key competitors identified by a dealership and all other dealers that influence the local market dynamics. Understanding this grouping helps dealerships strategize and tailor their marketing and operational efforts effectively within their competitive landscape.',
    Tooltip: 'Dealerships within your geographic area that impact local market dynamics',
    'Description + Tooltip Status': 'Confirmed',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Settings-Dealer Settings-Dealers not in-market',
    'Report ': {
      ' Dashboard': 'Settings',
    },
    Category: 'Dealer Settings',
    Metric: 'Dealers not in-market',
    Description:
      'Dealers not selected and thus outside the local market. Perceived by dealerships as having minimal impact and not being direct competitors.',
    Tooltip: 'Dealers outside the local market. Perceived as not direct competition.',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Market Price (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Market Price (Local)',
    Description:
      'The local average market price is calculated by summing the listing prices of all similar VINs within the local market and dividing by their total number to reflect the typical listing price for a VIN',
    Tooltip: 'Sum of similar local VIN prices divided by total VINs',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Market Price 7d% (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Market Price 7d% (Local)',
    Description:
      'This metric measures the percentage change in the local average market listed price over the last 7 days.',
    Tooltip: '% change in local avg market price over last 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Market Sale Price (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Market Sale Price (Local)',
    Description:
      'The local average market sale price is determined by adding together the sale prices of all similar VINs sold within the local market and dividing by the total number of these VINs, providing a local benchmark for selling prices.',
    Tooltip: 'Sum of locally sold VIN prices divided by the number sold',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Market Sale Price 7d% (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Market Sale Price 7d% (Local)',
    Description:
      'Measures the percentage change in the local average market sale price over the past 7 days, indicating shifts in market conditions.',
    Tooltip: '% change in local avg sale price over last 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Days to Sale (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Days to Sale (Local)',
    Description:
      'This metric calculates the local average number of days it takes for a VIN to be sold, derived by averaging the days each VIN remains listed before being sold, providing an insight into the sales velocity',
    Tooltip:
      'Calculated as the local average of days from listing to sale, averaged across all VINs',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Days to Sale 7d% (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Days to Sale 7d% (Local)',
    Description:
      'This is a measure that calculates the local average daily percentage change in the number of days it takes for vehicles to be sold across a 7-day rolling period.',
    Tooltip: '% change in average sale days locally over 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Shoppers (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Shoppers (Local)',
    Description:
      'Measures the average number of shoppers who have shown interest in each vehicle listing locally. It is calculated by dividing the total number of shopper interactions by the number of similar vehicle listings, providing insights into shopper engagement for each vehicle.',
    Tooltip: 'Avg number of shoppers per vehicle, based on local interactions',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Shoppers 7d% (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Shoppers 7d% (Local)',
    Description:
      'This metric shows the local 7-day rolling average of the shopper count, reflecting changes in buyer activity.',
    Tooltip: '7-day local rolling average of shopper counts',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Market DOL (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Market DOL (Local)',
    Description:
      "The average duration that vehicles remain on a dealership's lot before being sold locally. This metric is derived by aggregating the days on lot for all vehicles in the local market and dividing by the total number of vehicles, offering an insight into local inventory turnover.",
    Tooltip: 'Total local days on lot of similar vehicles divided by the number of vehicles',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Avg Market DOL 7d% (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Avg Market DOL 7d% (Local)',
    Description:
      'Refers to the local average daily percent change in the number of days a specific vehicle is for sale over a 7-day period.',
    Tooltip: '% change in avg days on lot locally over 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Similar VINs (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Similar VINs (Local)',
    Description:
      'This counts the number of similar VINs within the local market, using vehicle year, make, model, and where available, trim, to compare against similar listed vehicles',
    Tooltip: 'Local count of similar vehicles by year, make, and model',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
  {
    key: 'Live Inventory-Local Market-Similar VINs 7d% (Local)',
    'Report ': {
      ' Dashboard': 'Live Inventory',
    },
    Category: 'Local Market',
    Metric: 'Similar VINs 7d% (Local)',
    Description:
      'Assesses the percent change in the count of similar vehicles locally over a seven-day period, indicating trends in vehicle availability or preference within the local market.',
    Tooltip: '% change in count of similar VINs locally over 7 days',
    'Description + Tooltip Status': 'Ready for Review',
    'Feedback Notes': '',
    Version: null,
    'Live Inventory': null,
    Campaign: null,
    VVO: null,
    'Market Opportunity': null,
    'Before and After': null,
    'VIN Detail Modal': null,
    'Sold Inventory': null,
  },
];
