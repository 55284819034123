import classnames from 'classnames';
import CheckboxDropdown, { CheckboxDropdownProps } from 'Sparky/CheckboxDropdown';
import Spacer from 'Sparky/Spacer';

import styles from './CheckboxDropdownField.module.scss';

export interface CheckboxDropdownFieldProps extends CheckboxDropdownProps {
  label: string;
  labelColor?: string;
  info?: string;

  onInfoClick?(): void;

  message?: string;
  messageClassname?: string;
  required?: boolean;
  className?: string;
}

/**
 * CheckboxDropdownField
 */
export default function CheckboxDropdownField({
  label,
  labelColor,
  info,
  onInfoClick,
  message,
  messageClassname,
  required = false,
  ...otherProps
}: CheckboxDropdownFieldProps) {
  return (
    <div className={classnames({ [styles.checkboxDropdownField]: true }, otherProps.className)}>
      <div className={styles.labelField}>
        <label htmlFor={otherProps.id} className={styles.label} style={{ color: labelColor }}>
          {label}
          {required && <span className={styles.requiredIndicator}> *</span>}
        </label>
        <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
        {info && (
          <span onClick={onInfoClick} className={styles.info}>
            {info}
          </span>
        )}
      </div>
      <CheckboxDropdown {...otherProps} />
      {message && (
        <div
          className={classnames(
            {
              [styles.message]: true,
            },
            messageClassname
          )}
        >
          {message}
        </div>
      )}
    </div>
  );
}
