import { Dispatch, SetStateAction, useEffect } from 'react';

import { produce } from 'immer';

export interface UseTimeTrackerProps {
  startDate: string; // EST time
  endDate: string; // EST time
  timeTracker: number;
  setTimeTracker: Dispatch<SetStateAction<number>>;
}

/**
 * TimeTracker
 */

export default function useTimeTracker({
  startDate,
  endDate,
  timeTracker,
  setTimeTracker,
}: UseTimeTrackerProps) {
  // Convert EST time to local time
  const localStartDate = new Date(`${startDate} GMT-0500`);
  const localEndDate = new Date(`${endDate} GMT-0500`);

  // Effect to update the current time every second
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();

      // Check if the current time matches the static time
      if (
        currentDate.toString() === localStartDate.toString() ||
        currentDate.toString() === localEndDate.toString()
      ) {
        setTimeTracker(
          produce(timeTracker, (draft) => {
            draft += 1;
            return draft;
          })
        );
      }
    }, 1000); // Update every second

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [startDate, endDate]);
}
