import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import { filterResponseErrors } from 'api/utils/filterResponseErrors';
import useQueryKeys from 'api/utils/useQueryKeys';
import { FiltersState } from 'components/Filters';
import { InventoryPageKey } from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';
import {
  LiveInventoryTableConfigGroups,
  SoldInventoryTableConfigGroups,
} from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import {
  DefaultLiveInventoryTableConfig,
  DefaultSoldInventoryTableConfig,
  InventoryTableConfig,
} from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableTemplates';
import { SortColumn } from 'pages/Inventory/InventoryPage/InventoryTable';
import { userAtom } from 'store';
import { assertIsDefined } from 'util/assert';

import { InventoryTableConfigResponseSchema } from './InventoryTableConfigSchema';
import { DEFAULT_REDESIGNED_INV_TABLE_CONFIG } from './mocks/mockInventoryTableConfigData';

export interface InventoryConfig {
  id: number;
  isFavourite: boolean;
  isPreset: boolean;
  name: string;
  details: string;
  inventoryTableConfig: InventoryTableConfig;
  sortColumns: SortColumn[];
  filtersState?: FiltersState;
}

export interface InventoryTableConfigResponse {
  soldInventoryConfigs: InventoryConfig[];
  liveInventoryConfigs: InventoryConfig[];
}

interface fetchInvTableConfigProps {
  token?: string;
  dealerId?: number;
}

async function fetchInventoryTableConfig({
  token,
}: fetchInvTableConfigProps): Promise<InventoryTableConfigResponse> {
  assertIsDefined(token);

  let res = await get<InventoryTableConfigResponse>(`${ENDPOINTS.inventoryTableConfig}`, {
    headers: { token },
    params: { preset: false },
  });

  if (
    !Object.hasOwn(res, 'liveInventoryConfigs') ||
    (res.liveInventoryConfigs && res.liveInventoryConfigs.length === 0)
  ) {
    res.liveInventoryConfigs = DEFAULT_REDESIGNED_INV_TABLE_CONFIG.liveInventoryConfigs;
  } else {
    res.liveInventoryConfigs = res.liveInventoryConfigs.map((liveInventoryConfig) => ({
      ...liveInventoryConfig,
      inventoryTableConfig: updateInventoryTableConfig(
        liveInventoryConfig.inventoryTableConfig,
        'live'
      ),
      sortColumns: updateSortColumns(liveInventoryConfig.sortColumns, 'sold'),
    }));
    DEFAULT_REDESIGNED_INV_TABLE_CONFIG.liveInventoryConfigs.forEach((liveInventoryConfig) => {
      const index = res.liveInventoryConfigs.findIndex(
        (inventoryConfig) => liveInventoryConfig.id === inventoryConfig.id
      );
      if (index > -1) {
        res.liveInventoryConfigs[index] = {
          ...liveInventoryConfig,
          isFavourite: res.liveInventoryConfigs[index].isFavourite,
        };
      } else {
        res.liveInventoryConfigs.push(liveInventoryConfig);
      }
    });
  }

  if (
    !Object.hasOwn(res, 'soldInventoryConfigs') ||
    (res.soldInventoryConfigs && res.soldInventoryConfigs.length === 0)
  ) {
    res.soldInventoryConfigs = DEFAULT_REDESIGNED_INV_TABLE_CONFIG.soldInventoryConfigs;
  } else {
    res.soldInventoryConfigs = res.soldInventoryConfigs.map((soldInventoryConfig) => ({
      ...soldInventoryConfig,
      inventoryTableConfig: updateInventoryTableConfig(
        soldInventoryConfig.inventoryTableConfig,
        'sold'
      ),
      sortColumns: updateSortColumns(soldInventoryConfig.sortColumns, 'sold'),
    }));
    DEFAULT_REDESIGNED_INV_TABLE_CONFIG.soldInventoryConfigs.forEach((soldInventoryConfig) => {
      const index = res.soldInventoryConfigs.findIndex(
        (inventoryConfig) => soldInventoryConfig.id === inventoryConfig.id
      );
      if (index > -1) {
        res.soldInventoryConfigs[index] = {
          ...soldInventoryConfig,
          isFavourite: res.soldInventoryConfigs[index].isFavourite,
        };
      } else {
        res.soldInventoryConfigs.push(soldInventoryConfig);
      }
    });
  }

  const zodValidation = InventoryTableConfigResponseSchema.safeParse(res);

  if (!zodValidation.success) {
    Sentry.captureException(zodValidation.error);
    res.liveInventoryConfigs = filterResponseErrors(zodValidation, res.liveInventoryConfigs);
    res.soldInventoryConfigs = filterResponseErrors(zodValidation, res.soldInventoryConfigs);
  }

  return res;
}

export default function useInventoryTableConfig() {
  const [user] = useAtom(userAtom);
  const { invTableConfigKey: queryKey } = useQueryKeys();

  return useQuery<InventoryTableConfigResponse, Error>(queryKey, () =>
    fetchInventoryTableConfig({ token: user?.token })
  );
}

/** Remove redundant groups/columns and add missing groups/columns to the saved configuration  */
export function updateInventoryTableConfig(
  config: InventoryTableConfig | undefined,
  page: InventoryPageKey
): InventoryTableConfig {
  // If fetching invTableConfig or failed
  if (!config) {
    return page === 'live' ? DefaultLiveInventoryTableConfig : DefaultSoldInventoryTableConfig;
  }

  // Filter non-existing version columns
  config.frozenColumns = removeDuplicates(config?.frozenColumns)?.filter((col) =>
    Object.values(page === 'live' ? LiveInventoryTableConfigGroups : SoldInventoryTableConfigGroups)
      .flat()
      .includes(col)
  );

  config.mainColumns = removeDuplicates(config?.mainColumns)?.filter(
    (col) =>
      Object.values(
        page === 'live' ? LiveInventoryTableConfigGroups : SoldInventoryTableConfigGroups
      )
        .flat()
        .includes(col) && !config.frozenColumns?.includes(col)
  );

  return config;
}

function updateSortColumns(sortColumns: SortColumn[], page: InventoryPageKey) {
  return removeDuplicates(sortColumns).filter((sortColumn) =>
    Object.values(page === 'live' ? LiveInventoryTableConfigGroups : SoldInventoryTableConfigGroups)
      .flat()
      .includes(sortColumn.id)
  );
}

function removeDuplicates<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

// export function updateSelection(
//   selectedTemplate: InventoryTemplateOptionKey | string | undefined
// ): InventoryTemplateOptionKey {
//   const invTemplateOptions = Object.keys(INVENTORY_TEMPLATE_OPTIONS);
//   if (invTemplateOptions.includes(selectedTemplate as string)) {
//     return selectedTemplate as InventoryTemplateOptionKey;
//   } else {
//     return 'default';
//   }
// }
