import { NumberFormat } from 'types/NumberFormat';
import { formatCurrency, formatNumber, formatPercent } from 'util/formatters';

interface FormatNumberFormatOptions {
  /**
   * If `format` is CURRENCY, this defines if decimals are shown
   * @default false
   */
  currencyShowDecimal?: boolean;
  /**
   * If `format` is PERCENT, how many decimals should we show?
   * @default 2
   */
  percentDecimals?: number;
  /**
   * If `format` is FLOAT, how many decimals should we show?
   * @default 2
   */
  floatDecimals?: number;
  /**
   * If `format` is invalid and the value is falsy, return this
   * @default ''
   */
  defaultValue?: string;
}

/** Formats a number based on a `NumberFormat` */
export function formatNumberFormat(
  val: number | undefined,
  format: NumberFormat,
  options?: FormatNumberFormatOptions
): string {
  const {
    currencyShowDecimal = false,
    percentDecimals = 2,
    floatDecimals = 2,
    defaultValue = '',
  } = options ?? {};

  switch (format) {
    case NumberFormat.PERCENT:
      return formatPercent(val, percentDecimals);
    case NumberFormat.CURRENCY:
      return formatCurrency(val, currencyShowDecimal);
    case NumberFormat.INT:
      return formatNumber(val, 0);
    case NumberFormat.FLOAT:
      return formatNumber(val, floatDecimals);
    default:
      return val?.toString() ?? defaultValue;
  }
}
