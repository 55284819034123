import { IconDeviceDesktopAnalytics } from '@tabler/icons-react';
import classnames from 'classnames';
import Accordion from 'Sparky/Accordion';
import Chip from 'Sparky/Chip';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import Skeleton from 'Sparky/Skeleton';
import Spinner from 'Sparky/Spinner';
import { COLOR } from 'Sparky/styles/vars';
import { LotlinxXIcon } from 'Sparky/SVGs/LotlinxXIcon';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import useVDPPriceData from 'pages/Inventory/VinDetailSidebar/api/useVDPPriceData/useVDPPriceData';
import LoadingState from 'pages/Inventory/VinDetailSidebar/components/LoadingState';
import NoDataAvailable from 'pages/Inventory/VinDetailSidebar/components/NoDataAvailable';
import { useVinDetailContext } from 'pages/Inventory/VinDetailSidebar/utils/VinDetailContext';
import VDPPriceChart from 'pages/Inventory/VinDetailSidebar/VDPSection/VDPPriceChart';
import { formatNumber } from 'util/formatters';

import styles from './VDPSection.module.scss';

/**
 * VDPSection
 */
export default function VDPSection() {
  const { item, openSection, onOpenSection } = useVinDetailContext();

  const { totalVDP, lotlinxVDP } = item.gaMetrics;
  const { data, isLoading, isError } = useVDPPriceData(item.listing.vin);

  return (
    <Accordion
      className={classnames([styles.vdpSection, openSection.includes('vdp') && styles.openState])}
      isExpanded={openSection.includes('vdp')}
      handleClick={() => onOpenSection('vdp')}
      title='VDP'
      titleSize='14'
      hightlightTitleOnOpen
      rightElement={
        <Tooltip content='Total VDP views' side='top'>
          <div>
            {isLoading ? (
              <Skeleton width='64px' height='100%' radius='12px' />
            ) : (
              <Text as='span' color='primary' size={14} fontWeight={5}>
                {formatNumber(totalVDP)}
              </Text>
            )}
          </div>
        </Tooltip>
      }
      formatName={false}
      iconBefore={
        <IconDeviceDesktopAnalytics
          size={18}
          color={openSection.includes('vdp') ? COLOR.SPARKY_NAVY_400 : COLOR.SPARKY_GREY_400}
        />
      }
      isOpenByRow
    >
      <Flex flex='none' column rowGap='12px' padding='0 16px 16px'>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <NoDataAvailable sectionName='Shopper demand' />
        ) : (
          <>
            <Panel className={styles.panel}>
              <Flex flex='none' column rowGap='8px'>
                <Text size={16} fontWeight={5} color='secondary'>
                  Total VDP views
                </Text>
                <Flex flex='none' alignItems='center' columnGap='8px'>
                  <Text size={24} fontWeight={5}>
                    {formatNumber(totalVDP)}
                  </Text>
                  <Chip
                    leftIcon={<LotlinxXIcon size='13' />}
                    color='red'
                    tooltip={`${formatNumber(lotlinxVDP)} total Lotlinx views`}
                    tooltipSide='top'
                  >
                    <Text color='primary'>{formatNumber(lotlinxVDP)}</Text>
                  </Chip>
                </Flex>
              </Flex>
            </Panel>

            <Panel className={styles.panel}>
              <Text color='secondary' size={16} fontWeight={5}>
                Total lifetime VDP views vs. price
              </Text>
              <Flex
                flex='none'
                height='200px'
                width='100%'
                alignItems='center'
                justifyContent='center'
              >
                {isLoading ? (
                  <Spinner size='lg' />
                ) : isError || !data?.trends?.length ? (
                  <NoDataAvailable sectionName='VDP' />
                ) : (
                  <VDPPriceChart data={data.trends} />
                )}
              </Flex>
            </Panel>
          </>
        )}
      </Flex>
    </Accordion>
  );
}
