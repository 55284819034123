import React from 'react';

import { useParams } from 'react-router-dom';
import Flex from 'Sparky/Flex';
import Message from 'Sparky/Message';

import VinDetailSidebar from 'pages/Inventory/VinDetailSidebar';
import InsightsSection from 'pages/Inventory/VinDetailSidebar/InsightsSection';
import LeadsSection from 'pages/Inventory/VinDetailSidebar/LeadsSection';
import MarketSection from 'pages/Inventory/VinDetailSidebar/MarketSection';
import ShopperDemandSection from 'pages/Inventory/VinDetailSidebar/ShopperDemandSection';
import SummarySection from 'pages/Inventory/VinDetailSidebar/SummarySection';
import VDPSection from 'pages/Inventory/VinDetailSidebar/VDPSection';
import VinPathPriceSection from 'pages/Inventory/VinDetailSidebar/VinPathPriceSection';
import { getTotalMarkdowns, vinPathTableSortBy } from 'pages/Inventory/VinPath/utils';
import VinPathTableFilters from 'pages/Inventory/VinPath/VinPathTable/VinPathTableFilters';
import VinPathTableHeader from 'pages/Inventory/VinPath/VinPathTable/VinPathTableHeader';
import VinPathTableItem from 'pages/Inventory/VinPath/VinPathTable/VinPathTableItem';
import { InventoryItem } from 'types/InventoryItem';
import { VinPathSidebar, VinPathSortBy } from 'types/VinPath';

import styles from './VinPathTable.module.scss';

export interface VinPathTableProps {
  isLoading: boolean;
  filteredData?: InventoryItem[];
  visibleSelected: string[];
  setSelectedVins: React.Dispatch<React.SetStateAction<string[]>>;
  vinPathSidebar: VinPathSidebar;
  setVinPathSidebar: React.Dispatch<React.SetStateAction<VinPathSidebar>>;
}

/**
 * VinPathTable
 */
export default function VinPathTable({
  isLoading,
  filteredData,
  visibleSelected,
  setSelectedVins,
  vinPathSidebar,
  setVinPathSidebar,
}: VinPathTableProps) {
  const { vin } = useParams();
  const [sortBy, setSortBy] = React.useState<VinPathSortBy>('daysOnLotHighToLow');

  const sortedData = vinPathTableSortBy(filteredData, sortBy);

  const toggleAllSelected = () => {
    if (visibleSelected.length === sortedData?.length) {
      setSelectedVins([]);
    } else {
      setSelectedVins(sortedData?.map((i) => i.listing.vin) ?? []);
    }
  };

  return (
    <Flex column width='1100px' flex='1' className={styles.vinPathTableContainer}>
      <VinPathTableHeader
        sortBy={sortBy}
        setSortBy={setSortBy}
        filteredData={sortedData}
        visibleSelected={visibleSelected}
        toggleAllSelected={toggleAllSelected}
        totalMarkDowns={getTotalMarkdowns(sortedData, visibleSelected)}
        vinPathSidebar={vinPathSidebar}
        setVinPathSidebar={setVinPathSidebar}
      />
      <Flex column flex='default' columnGap='24px' width='100%' className={styles.vinPathTable}>
        {sortedData && sortedData?.length > 0 ? (
          sortedData.map((item, index) => (
            <VinPathTableItem
              key={index}
              inventoryItem={item}
              isOpenVinDetail={vin === item.listing.vin}
              visibleSelected={visibleSelected}
              setSelectedVins={setSelectedVins}
            />
          ))
        ) : (
          <Message
            heading='No price recommendations based on your search criteria'
            dismissable={false}
            className={styles.message}
          >
            We couldn’t find a price for any vehicles that would change from their current
            probability of sale to the desired probability after a price change. Try adjusting your
            search criteria.
          </Message>
        )}
      </Flex>
      {vinPathSidebar === 'filter' && (
        <VinPathTableFilters
          isShow={vinPathSidebar === 'filter'}
          setVinPathSidebar={setVinPathSidebar}
        />
      )}
      {vin && (
        <VinDetailSidebar
          vin={vin}
          inventoryData={sortedData ?? []}
          isLoading={isLoading}
          defaultOpenSection={['price']}
          defaultYPosition={147}
          className={styles.vinPathVinDetail}
        >
          <SummarySection />
          <InsightsSection />
          <VDPSection />
          <LeadsSection />
          <VinPathPriceSection />
          <MarketSection />
          <ShopperDemandSection />
        </VinDetailSidebar>
      )}
    </Flex>
  );
}
