import { matchRoutes, RouteObject, useLocation } from 'react-router-dom';

/** Returns the title for the active route, based on route `id` */
export default function useRouteTitle(routes: RouteObject[]) {
  const location = useLocation();

  const matches = matchRoutes(routes, location.pathname);
  const title = matches
    ?.map((i) => i.route.id)
    .filter((i) => !!i)
    .reverse()
    .join(' · ');
  return title ?? 'VIN Manager';
}
