export function DeclineIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.29289 6.79289C2.68342 6.40237 3.31658 6.40237 3.70711 6.79289L9 12.0858L12.2929 8.79289C12.6834 8.40237 13.3166 8.40237 13.7071 8.79289L20 15.0858V10.5C20 9.94772 20.4477 9.5 21 9.5C21.5523 9.5 22 9.94772 22 10.5V17.5C22 18.0523 21.5523 18.5 21 18.5H14C13.4477 18.5 13 18.0523 13 17.5C13 16.9477 13.4477 16.5 14 16.5H18.5858L13 10.9142L9.70711 14.2071C9.31658 14.5976 8.68342 14.5976 8.29289 14.2071L2.29289 8.20711C1.90237 7.81658 1.90237 7.18342 2.29289 6.79289Z'
        fill='#FF0000'
      />
    </svg>
  );
}
