import { useEffect, useState } from 'react';

import { IconCaretDownFilled } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import { SIZE, useScreenSize } from 'Sparky/hooks';
import Modal from 'Sparky/Modal';
import Popover from 'Sparky/Popover';
import Spacer from 'Sparky/Spacer';
import { SPACING } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import DealerSelect from 'components/DealerSelect/DealerSelect';
import useSelectedDealer from 'hooks/useSelectedDealer';

import styles from './DealerSelectButton.module.scss';
import { userAtom } from '../../store';

/**
 * DealerSelectBar
 */
export default function DealerSelectButton({
  isMarketMap,
  verticalOffset,
  align,
  disabled = false,
  className,
}: {
  isMarketMap?: boolean;
  align?: 'end' | 'start' | 'center';
  verticalOffset?: number;
  disabled?: boolean;
  className?: string;
}) {
  const [user] = useAtom(userAtom);
  const [openDealerSelect, setOpenDealerSelect] = useState(false);
  const { dealer } = useSelectedDealer();
  const size = useScreenSize();
  const asModal = size <= SIZE.MD;

  useEffect(() => {
    setOpenDealerSelect(false);
  }, [dealer?.id]);

  return (
    <>
      <Popover
        isOpen={!asModal && openDealerSelect}
        setIsOpen={setOpenDealerSelect}
        side='bottom'
        align={align}
        aria-label='Dealer Select'
        verticalOffset={verticalOffset}
        showCarrat={false}
        isHoverTriggered={false}
        contentPadding={false}
        showCloseButton={false}
        popupContent={<DealerSelect className={styles.dealerSelectPopover} />}
      >
        <button
          className={classnames([
            styles.dealerSelectButton,
            isMarketMap && styles.marketMap,
            disabled && styles.disabled,
            className,
          ])}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            setOpenDealerSelect((prev) => !prev);
          }}
        >
          <Text size='16' fontWeight={7}>
            {dealer ? `${dealer.name} ${dealer.id}` : 'Select Dealer'}
          </Text>
          <Spacer axis='horizontal' size={SPACING.SPACING_04} />
          <IconCaretDownFilled size={16} />
        </button>
      </Popover>

      {asModal && (
        <Modal
          isOpen={!!user?.token && openDealerSelect}
          onDismiss={() => setOpenDealerSelect(false)}
          contentClassName={styles.dealerSelectModal}
          aria-label='Dealer Select'
          overlayClassName='overlay'
        >
          <DealerSelect />
        </Modal>
      )}
    </>
  );
}
