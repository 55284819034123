import React from 'react';

import classnames from 'classnames';
import Checkbox from 'Sparky/Checkbox';
import Flex from 'Sparky/Flex';
import Radio from 'Sparky/Radio';
import Text from 'Sparky/Text';

import styles from './Card.module.scss';

export interface CardProps {
  heading: React.ReactNode;
  subtext?: React.ReactNode;
  showGuideline?: boolean;
  parent?: 'page' | 'popover';
  children?: React.ReactNode;
  selectable?: boolean;
  selectionIndicator?: 'checkbox' | 'radio';
  isSelected?: boolean;
  value?: string | number | undefined;
  handleClick?: (e: string | number | undefined) => null;
}

/**
 * Card
 */
export default function Card({
  heading,
  subtext,
  showGuideline,
  parent = 'page',
  children,
  selectable,
  selectionIndicator = 'checkbox',
  handleClick,
  value,
  isSelected,
}: CardProps) {
  return (
    <div
      className={classnames([
        styles.card,
        selectable && styles.selectable,
        isSelected && styles.selected,
      ])}
      onClick={selectable && handleClick ? () => handleClick(value) : undefined}
    >
      <Flex justifyContent='space-between' alignItems='center' width='100%'>
        <Text type={parent === 'page' ? 'heading-03' : 'label-01'} className={styles.heading}>
          {heading}
        </Text>
        {selectable && selectionIndicator && selectionIndicator === 'checkbox' && (
          <div>
            <Checkbox checked={isSelected} />
          </div>
        )}
        {selectable && selectionIndicator && selectionIndicator === 'radio' && (
          <div>
            <Radio checked={isSelected} />
          </div>
        )}
      </Flex>
      {subtext ? (
        <div className={styles.cardBody}>
          <Text type='body-01'>{subtext}</Text>
          <div
            className={classnames({
              [styles.childrenWrapper]: true,
              [styles.guidelines]: showGuideline,
            })}
          >
            {children}
          </div>
        </div>
      ) : (
        <div
          className={classnames({
            [styles.childrenWrapper]: true,
            [styles.guidelines]: showGuideline,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
}
