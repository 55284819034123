import React from 'react';

import {
  IconChevronLeft,
  IconChevronLeftPipe,
  IconChevronRight,
  IconChevronRightPipe,
} from '@tabler/icons-react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Table from 'Sparky/Table';
import Cell from 'Sparky/Table/Cell';
import TableBody from 'Sparky/Table/TableBody';
import { TableProvider } from 'Sparky/Table/TableContext';
import TableHeader from 'Sparky/Table/TableHeader';
import TableRow from 'Sparky/Table/TableRow';
import Text, { TextProps } from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';
import { v4 as uuid } from 'uuid';

import { getPriceScenarioColor, getProbabilityLabel } from 'pages/Inventory/VinPath/utils';
import { ProbabilityOfSaleKeys } from 'types/InventoryItem';
import { MarkdownHistoryItem } from 'types/VinPath';
import { formatCurrency, formatPercent } from 'util/formatters';

import styles from './MarkdownHistoryTable.module.scss';

export interface MarkdownHistoryTableProps {
  markdownHistory: MarkdownHistoryItem[];
}

const maxNumberInTable = 10;

/**
 * MarkdownHistoryTable
 */
export default function MarkdownHistoryTable({ markdownHistory }: MarkdownHistoryTableProps) {
  const [pageIndex, setPageIndex] = React.useState<number>(1);
  const tableHeaders = ['Price change', 'Price', 'Likelihood of sale', 'PTM'];

  const totalNumberOfHistoryData = markdownHistory.length;
  const totalPageNumber = Math.ceil(totalNumberOfHistoryData / maxNumberInTable);
  let currentPageData = markdownHistory;

  if (totalNumberOfHistoryData > maxNumberInTable) {
    currentPageData = markdownHistory.slice(
      maxNumberInTable * (pageIndex - 1),
      maxNumberInTable * pageIndex
    );
  }

  const showingRangeInTable =
    pageIndex === 1
      ? `1 - ${currentPageData.length}`
      : `${maxNumberInTable * (pageIndex - 1)} - ${
          maxNumberInTable * (pageIndex - 1) + currentPageData.length
        }`;

  return (
    <TableProvider data={currentPageData}>
      <Table className={styles.markdownHistoryTable}>
        <thead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <TableHeader
                key={header}
                align='left'
                className={classNames([styles.tableHeader, index === 0 && styles.firstColumn])}
              >
                {header}
              </TableHeader>
            ))}
          </TableRow>
        </thead>
        <TableBody>
          {(m: MarkdownHistoryItem) => (
            <TableRow key={uuid()}>
              <Cell className={classNames([styles.cell, styles.firstColumn])}>
                {m.markdown !== undefined ? (
                  <Text size={14}>
                    {dayjs(m.markdownDate).format('MMM D, YYYY')}{' '}
                    <Text size={14} fontWeight={7} color='primary'>
                      {formatCurrency(m.markdown)}
                    </Text>
                  </Text>
                ) : (
                  <Text size={14}>Original price</Text>
                )}
              </Cell>
              <Cell className={styles.cell}>{formatCurrency(m.price)}</Cell>
              <Cell className={styles.cell}>
                <Tooltip
                  side='left'
                  content='No probability of sale data available before December 2024' // hardcode the date temporarily
                  disabled={!!m.probabilityOfSale}
                >
                  <div>
                    <Text
                      color={
                        !m.probabilityOfSale
                          ? 'disabled'
                          : (getPriceScenarioColor(
                              m.probabilityOfSale
                            ) as TextProps<'div'>['color'])
                      }
                    >
                      {!m.probabilityOfSale
                        ? 'Unavailable'
                        : getProbabilityLabel(m.probabilityOfSale as ProbabilityOfSaleKeys)}
                    </Text>
                  </div>
                </Tooltip>
              </Cell>
              <Cell className={styles.cell}>
                <Tooltip side='left' content='Unavailable' disabled={!!m.priceToMarketPct}>
                  <div>{formatPercent(m.priceToMarketPct)}</div>
                </Tooltip>
              </Cell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {totalNumberOfHistoryData > maxNumberInTable && (
        <Flex alignItems='center' justifyContent='flex-end' columnGap='4px' padding='8px'>
          <Text size='12' color='secondary'>
            showing {showingRangeInTable} of {markdownHistory.length}
          </Text>
          <IconButton
            aria-label='first page'
            size='sm'
            disabled={pageIndex === 1}
            onClick={() => setPageIndex(1)}
          >
            <IconChevronLeftPipe />
          </IconButton>
          <IconButton
            aria-label='previous page'
            size='sm'
            disabled={pageIndex === 1}
            onClick={() => setPageIndex(pageIndex + 1)}
          >
            <IconChevronLeft />
          </IconButton>
          <IconButton
            aria-label='next page'
            size='sm'
            disabled={pageIndex === totalPageNumber}
            onClick={() => setPageIndex(pageIndex - 1)}
          >
            <IconChevronRight />
          </IconButton>
          <IconButton
            aria-label='last page'
            size='sm'
            disabled={pageIndex === totalPageNumber}
            onClick={() => setPageIndex(totalPageNumber)}
          >
            <IconChevronRightPipe />
          </IconButton>
        </Flex>
      )}
    </TableProvider>
  );
}
