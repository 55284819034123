import { ErrorCode } from './ErrorCode';

export default class APIError extends Error {
  subCode: ErrorCode;

  constructor(message: string, subCode: ErrorCode) {
    super(message);
    Object.setPrototypeOf(this, APIError.prototype); // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#example

    this.subCode = subCode;
  }
}
