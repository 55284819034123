import React, { useMemo, useRef, useState } from 'react';

import { IconArrowUp } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import Breadcrumbs from 'Sparky/Breadcrumbs';
import Button from 'Sparky/Button';
import { ChipColors } from 'Sparky/Chip';
import Flex from 'Sparky/Flex';
import Skeleton from 'Sparky/Skeleton';

import { useInventoryMTD } from 'api/useInventory/useInventory';
import { FiltersProvider, FiltersState, useFilters } from 'components/Filters';
import Page from 'components/Page/Page';
import VMBIUpgradeCTA from 'components/VMBIUpgradeCTA/VMBIUpgradeCTA';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useSelectedDealer from 'hooks/useSelectedDealer';
import NoDataAvailable from 'pages/Inventory/VinPath/NoDataAvailable';
import {
  filterVinPathByProbabilityAndMarkdown,
  getMarkdownRange,
} from 'pages/Inventory/VinPath/utils';
import { getCurrentProbabilityCounts } from 'pages/Inventory/VinPath/utils/getCurrentProbabilityCounts';
import { getMileageRange } from 'pages/Inventory/VinPath/utils/getMileageRange';
import VinPathSearchBox from 'pages/Inventory/VinPath/VinPathSearchBox';
import VinPathTable from 'pages/Inventory/VinPath/VinPathTable';
import { VinPathFilterConfigs } from 'pages/Inventory/VinPath/VinPathTable/VinPathTableFilters/VinPathFilterConfigs';
import { userAtom } from 'store';
import { FeatureFlag } from 'types/FeatureFlags';
import { InventoryItem, ProbabilityOfSaleKeys } from 'types/InventoryItem';
import { ProbabilityInterface, VinPathSidebar } from 'types/VinPath';

import styles from './VinPath.module.scss';

export const defaultCurrentProbabilityOfSale: ProbabilityInterface = {
  all: true,
  highlyLikely: true,
  likely: true,
  neutral: true,
  unlikely: true,
  highlyUnlikely: true,
};

export const defaultProbabilityAfterPriceChange: ProbabilityInterface = {
  all: false,
  highlyLikely: true,
  likely: true,
  neutral: false,
  unlikely: false,
};

export const defaultMarkdownRange: number[] = [-Infinity, Infinity];

interface VinPathProps {
  isVMSubEnabled?: boolean;
  isInvFeedConfigured?: boolean;
}

/**
 * VinPath
 */
export default function VinPath({ isVMSubEnabled, isInvFeedConfigured }: VinPathProps) {
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);
  const [currentProbabilityOfSale, setCurrentProbabilityOfSale] = useState<ProbabilityInterface>(
    defaultCurrentProbabilityOfSale
  );
  const [probabilityAfterPriceChange, setProbabilityAfterPriceChange] =
    useState<ProbabilityInterface>(defaultProbabilityAfterPriceChange);
  const [markdownTolerance, setMarkdownTolerance] = useState<boolean>(false);
  const [markdownRange, setMarkdownRange] = useState<number[]>(defaultMarkdownRange);
  const [vinPathSidebar, setVinPathSidebar] = useState<VinPathSidebar>('');
  const [filtersState, setFiltersState] = useState<FiltersState>({});
  const [selectedVins, setSelectedVins] = useState<string[]>([]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useInventoryMTD();

  const vinPathInventoryData = useMemo(
    () =>
      data?.inventory.filter(
        (i) =>
          i.status.status !== 'SOLD' &&
          i.status.status !== 'MOVED' &&
          i.listing.inventoryCondition !== 'NEW'
      ),
    [data?.inventory]
  );

  const isVinPathPriceRecommendationsAvailable = useMemo(
    () =>
      !!vinPathInventoryData?.find(
        (item) =>
          item?.vinPath && item.vinPath.pricingOptions && item.vinPath.pricingOptions.length > 0
      ),
    [vinPathInventoryData]
  );

  const searchedData = useMemo(
    () =>
      filterVinPathByProbabilityAndMarkdown(
        currentProbabilityOfSale,
        probabilityAfterPriceChange,
        markdownRange,
        vinPathInventoryData
      ),
    [currentProbabilityOfSale, probabilityAfterPriceChange, markdownRange, vinPathInventoryData]
  );

  const markdownRangeLimit = useMemo(
    () => getMarkdownRange(vinPathInventoryData),
    [vinPathInventoryData]
  );
  const mileageRangeLimit = useMemo(
    () => getMileageRange(vinPathInventoryData),
    [vinPathInventoryData]
  );

  const currentProbabilityCounts = useMemo(
    () => getCurrentProbabilityCounts(vinPathInventoryData),
    [vinPathInventoryData]
  );

  const filterConfigs = useMemo(
    () => VinPathFilterConfigs(markdownRangeLimit, mileageRangeLimit),
    [markdownRangeLimit, mileageRangeLimit]
  );

  const { filteredData, ...filtersProviderProps } = useFilters({
    data: searchedData,
    filterConfigs,
    filtersState,
    setFiltersState,
  });

  const onScrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      setShowBackToTop(true);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (!isVMSubEnabled) {
      scrollRef.current?.scrollTo({ top: 0 });
    }
    // @ts-ignore
    if (e.target.scrollTop === 0) {
      setShowBackToTop(false);
      // @ts-ignore
    } else {
      setShowBackToTop(true);
    }
  };

  if (isLoading) {
    return (
      <Flex flex='none' width='1100px' height='100%' margin='24px auto'>
        <Skeleton width='100%' height='100%' />
      </Flex>
    );
  }

  if (!isInvFeedConfigured || !vinPathInventoryData || vinPathInventoryData?.length === 0) {
    return (
      <NoDataAvailable
        noFeedOrNoUsedInventory={true}
        feedAndUsedInventoryButNoRecommendation={false}
      />
    );
  } else if (
    isInvFeedConfigured &&
    vinPathInventoryData.length > 0 &&
    !isVinPathPriceRecommendationsAvailable
  ) {
    return (
      <NoDataAvailable
        noFeedOrNoUsedInventory={false}
        feedAndUsedInventoryButNoRecommendation={true}
      />
    );
  }

  return (
    <FiltersProvider
      filterConfigs={filterConfigs}
      itemCountLabel='VINs'
      showItemCount={true}
      isLoading={isLoading}
      data={searchedData}
      {...filtersProviderProps}
    >
      <div ref={scrollRef} onScrollCapture={(e) => handleScroll(e)} className={styles.vinPath}>
        <VinPathSearchBox
          currentProbabilityCounts={currentProbabilityCounts}
          currentProbabilityOfSale={currentProbabilityOfSale}
          setCurrentProbabilityOfSale={setCurrentProbabilityOfSale}
          probabilityAfterPriceChange={probabilityAfterPriceChange}
          setProbabilityAfterPriceChange={setProbabilityAfterPriceChange}
          markdownTolerance={markdownTolerance}
          setMarkdownTolerance={setMarkdownTolerance}
          markdownRangeLimit={markdownRangeLimit}
          markdownRange={markdownRange}
          setMarkdownRange={setMarkdownRange}
        />
        <VinPathTable
          isLoading={isLoading}
          filteredData={filteredData}
          visibleSelected={selectedVins}
          setSelectedVins={setSelectedVins}
          vinPathSidebar={vinPathSidebar}
          setVinPathSidebar={setVinPathSidebar}
        />
        {showBackToTop && (
          <Button
            variant='outline'
            rightIcon={<IconArrowUp size={20} />}
            onClick={onScrollToTop}
            className={styles.scrollToTopBtn}
          >
            Back to the top
          </Button>
        )}
        {!isVMSubEnabled && <VMBIUpgradeCTA />}
      </div>
    </FiltersProvider>
  );
}

export function Component() {
  const selectedDealer = useSelectedDealer();
  const [user] = useAtom(userAtom);
  const enableUpgradePanel = useFeatureFlag(FeatureFlag.ENABLE_VIN_UPGRADE_PANEL);
  const isVMSubEnabled =
    !enableUpgradePanel ||
    selectedDealer?.dealer?.isVinManagerSubEnabled ||
    user?.accountType === 'OWNER';

  return (
    <Page requireDealer title='VINPath' flag={FeatureFlag.ENABLE_VIN_PATH}>
      <div className={styles.vinPathPage}>
        {isVMSubEnabled && (
          <Breadcrumbs
            pageTitles={[{ title: 'Inventory' }, { title: 'VINPath®' }]}
            isBeta
            className={styles.pageHeader}
          />
        )}
        <VinPath
          isVMSubEnabled={isVMSubEnabled}
          isInvFeedConfigured={selectedDealer?.dealer?.isInvFeedConfigured}
        />
      </div>
    </Page>
  );
}

export interface ProbabilityDataItem {
  title: string;
  key: string;
  pillColor: ChipColors;
  color: string;
  getVehicleList: () => InventoryItem[];
  roomToImproveCount: () => number;
}

export type ProbabilityDataObject = Record<ProbabilityOfSaleKeys, ProbabilityDataItem>;
