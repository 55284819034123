import React, { Dispatch, SetStateAction } from 'react';

import { IconSwitchVertical, IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import { produce } from 'immer';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';

import { ShowInventorySideBar } from 'pages/Inventory/InventoryPage';
import { InventoryConfig } from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';
import { InventoryPageKey } from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';
import ColumnOptions from 'pages/Inventory/InventoryPage/CustomizeSidebar/ColumnOptions';
import TableColumns from 'pages/Inventory/InventoryPage/CustomizeSidebar/TableColumns';
import { InventoryTableColumnDef } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';

import styles from './CustomizeSidebar.module.scss';
import {
  InventoryTableColumnKey,
  InventoryTableConfigGroups,
  InventoryTableGroupKey,
  LiveInventoryTableConfigGroups,
  SoldInventoryTableConfigGroups,
} from './InventoryTableConfigDefs';

export interface CustomizeSidebarProps {
  isLoading: boolean;
  showInventorySidebar: ShowInventorySideBar;
  setShowInventorySidebar: Dispatch<SetStateAction<ShowInventorySideBar>>;
  currentInventoryConfig: InventoryConfig;

  setCurrentInventoryConfig(val: InventoryConfig): void;

  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>;
  inventoryPageKey: InventoryPageKey;
}

/**
 * Inventory Customize Sidebar
 */
function CustomizeSidebar({
  isLoading,
  showInventorySidebar,
  setShowInventorySidebar,
  currentInventoryConfig,
  setCurrentInventoryConfig,
  inventoryTableColumnDefs,
  inventoryPageKey,
}: CustomizeSidebarProps) {
  const activeColumns = new Set(
    currentInventoryConfig.inventoryTableConfig?.frozenColumns.concat(
      currentInventoryConfig.inventoryTableConfig?.mainColumns
    )
  );

  const toggleActiveColumn = (col: InventoryTableColumnKey, isActive: boolean) => {
    if (isActive) {
      activeColumns.delete(col);
      setCurrentInventoryConfig(
        produce(currentInventoryConfig, (draft) => {
          draft.inventoryTableConfig.frozenColumns =
            draft.inventoryTableConfig.frozenColumns.filter((c) => c !== col);
          draft.inventoryTableConfig.mainColumns = draft.inventoryTableConfig.mainColumns.filter(
            (c) => c !== col
          );
          return draft;
        })
      );
    } else {
      activeColumns.add(col);
      setCurrentInventoryConfig(
        produce(currentInventoryConfig, (draft) => {
          draft.inventoryTableConfig.mainColumns.push(col);
          return draft;
        })
      );
    }
  };

  const clearGroupColumns = (group: InventoryTableGroupKey) => {
    setCurrentInventoryConfig(
      produce(currentInventoryConfig, (draft) => {
        draft.inventoryTableConfig.frozenColumns = draft.inventoryTableConfig.frozenColumns.filter(
          (column) => !InventoryTableConfigGroups[group].includes(column)
        );
        draft.inventoryTableConfig.mainColumns = draft.inventoryTableConfig.mainColumns.filter(
          (column) => !InventoryTableConfigGroups[group].includes(column)
        );

        return draft;
      })
    );
  };

  return (
    <div
      className={classnames([
        styles.customizeSidebar,
        (showInventorySidebar === 'arrange' || showInventorySidebar === 'columns') &&
          styles.showCustomizeSidebar,
      ])}
    >
      <div className={styles.header}>
        {showInventorySidebar === 'arrange' && (
          <div className={styles.headerContent}>
            <Text fontWeight={5} size='16' color='secondary' className={styles.title}>
              <IconSwitchVertical size='20' /> Arrange columns
            </Text>
            <Text fontWeight={4} size='12' color='tertiary'>
              Click the 🔒 to lock/unlock your columns to the lefthand side of the table. Drag the
              rows to re-order them for how you’d like them to appear from left-to-right in the
              table.
            </Text>
          </div>
        )}
        {showInventorySidebar === 'columns' && (
          <div className={styles.headerContent}>
            <Text fontWeight={5} size='16' color='secondary'>
              Add/remove columns
            </Text>
            <Text fontWeight={4} size='12' color='tertiary'>
              Select the data points that you would like to include as columns in the table
            </Text>
          </div>
        )}
        <IconButton
          aria-label='close arrange sidebar'
          size='md'
          className={styles.closeButton}
          onClick={() => setShowInventorySidebar('')}
        >
          <IconX size={20} />
        </IconButton>
      </div>

      {showInventorySidebar === 'arrange' && (
        <TableColumns
          isLoading={isLoading}
          inventoryTableColumnDefs={inventoryTableColumnDefs}
          currentInventoryConfig={currentInventoryConfig}
          setCurrentInventoryConfig={setCurrentInventoryConfig}
          setShowInventorySidebar={setShowInventorySidebar}
        />
      )}
      {showInventorySidebar === 'columns' && (
        <ColumnOptions
          isLoading={isLoading}
          groupColumns={getGroupColumns(inventoryPageKey, inventoryTableColumnDefs)}
          inventoryTableColumnDefs={inventoryTableColumnDefs}
          activeColumns={activeColumns}
          toggleActiveColumn={toggleActiveColumn}
          clearGroupColumns={clearGroupColumns}
        />
      )}
    </div>
  );
}

function getGroupColumns(
  inventoryPageKey: InventoryPageKey,
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>
) {
  let groupColumns =
    inventoryPageKey === 'live'
      ? { ...LiveInventoryTableConfigGroups }
      : SoldInventoryTableConfigGroups;

  const keys = Object.keys(groupColumns) as InventoryTableGroupKey[];
  const values = Object.values(groupColumns);

  const filteredValues = values.map((columns) => {
    return columns.filter((col) => inventoryTableColumnDefs[col].flag);
  });

  keys.forEach((key, i) => {
    groupColumns[key] = filteredValues[i];
  });

  return groupColumns;
}

export default React.memo(CustomizeSidebar);
