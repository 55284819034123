import { Key, useEffect } from 'react';

import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Select from 'Sparky/Select';
import SelectInput from 'Sparky/SelectInput';
import { DatePeriod, datePeriodAtom } from 'store/datePeriodAtom';

import styles from './DatePeriodSelect.module.scss';

interface DatePeriodSelectProps {
  optionQuantity?: number;
  variant?: 'default' | 'plain';
  monthToDate?: boolean; // Make MTD actually MTD
  showPreviousMonths?: boolean;
}

/**
 * Allows the user to select a date period from a dropdown.
 * Options include the current month and the previous three months.
 */
export const datePeriodOverrideAtom = atomWithStorage<string>('date-atom', '');

export default function DatePeriodSelect({
  optionQuantity = 3,
  variant = 'default',
  monthToDate = false,
  showPreviousMonths = false,
}: DatePeriodSelectProps) {
  const [datePeriod, setDatePeriod] = useAtom(datePeriodAtom);
  const [datePeriodOverride, setPeriodOverride] = useAtom(datePeriodOverrideAtom);

  useEffect(() => {
    if (datePeriodOverride.length > 0) {
      setDatePeriod(keyToPeriod(datePeriodOverride));
      setPeriodOverride('');
    }
  }, [datePeriodOverride]);

  useEffect(() => {
    if (monthToDate && datePeriod[1] === dayjs().endOf('month').format('YYYYMMDD')) {
      setDatePeriod([
        dayjs().startOf('month').format('YYYYMMDD'),
        monthToDate ? dayjs().format('YYYYMMDD') : dayjs().endOf('month').format('YYYYMMDD'),
      ]);
    }
  }, []);

  const handleSelectionChange = (key: Key) => {
    setDatePeriod(keyToPeriod(key as string));
  };

  return (
    <>
      {variant === 'plain' ? (
        <Select
          options={getPeriodSelectOptions(optionQuantity, monthToDate, showPreviousMonths)}
          value={periodToKey(datePeriod)}
          onValueChange={handleSelectionChange}
          aria-label='Select Date Period'
          className={styles.dateSelectInput}
        />
      ) : (
        <SelectInput
          id='Select Date Period'
          options={getPeriodSelectOptions(optionQuantity, monthToDate, showPreviousMonths)}
          value={periodToKey(datePeriod)}
          onValueChange={handleSelectionChange}
          aria-label='Select Date Period'
          className={styles.dateSelectInput}
          dropDownClassName={styles.dropDown}
        />
      )}
    </>
  );
}

/** Gets the date period options for the Select */
export function getPeriodSelectOptions(
  numPreviousMonths: number,
  monthToDate?: boolean,
  lastThreeMonths?: boolean
) {
  const currentMonth = dayjs().format('MMM');
  // Current month – date range includes full month, despite label being "Month To Date"
  let options = [
    {
      value: periodToKey([
        dayjs().startOf('month').format('YYYYMMDD'),
        monthToDate ? dayjs().format('YYYYMMDD') : dayjs().endOf('month').format('YYYYMMDD'),
      ]),
      label: `Current MTD ${lastThreeMonths ? `(${currentMonth})` : ''}`,
    },
  ];

  if (lastThreeMonths) {
    options.push({
      value: periodToKey([
        dayjs().subtract(2, 'month').startOf('month').format('YYYYMMDD'),
        dayjs().subtract(1, 'month').endOf('month').format('YYYYMMDD'),
      ]),
      label: `Last 2 months (${dayjs().subtract(2, 'month').format('MMM')} - ${dayjs()
        .subtract(1, 'month')
        .format('MMM')})`,
    });
    options.push({
      value: periodToKey([
        dayjs().subtract(3, 'month').startOf('month').format('YYYYMMDD'),
        dayjs().subtract(1, 'month').endOf('month').format('YYYYMMDD'),
      ]),
      label: `Last 3 months (${dayjs().subtract(3, 'month').format('MMM')} - ${dayjs()
        .subtract(1, 'month')
        .format('MMM')})`,
    });
  }
  // Get options for previous months
  for (let i = 1; i <= numPreviousMonths; i++) {
    options.push({
      value: periodToKey([
        dayjs().subtract(i, 'month').startOf('month').format('YYYYMMDD'),
        dayjs().subtract(i, 'month').endOf('month').format('YYYYMMDD'),
      ]),
      label: dayjs().subtract(i, 'month').format('MMMM YYYY'),
    });
  }

  return options;
}

/** Converts a DatePeriod to a string option key */
export function periodToKey(period: DatePeriod) {
  return period.join('-');
}

/** Converts an option key string back to a DatePerion */
export function keyToPeriod(key: string): DatePeriod {
  let split = key.split('-');
  return [split[0], split[1]];
}
