import React, { CSSProperties, Dispatch, SetStateAction, useEffect, useState } from 'react';

import classnames from 'classnames';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { cloneDeep } from 'lodash';
import Button from 'Sparky/Button';
import Checkbox from 'Sparky/Checkbox';
import Skeleton from 'Sparky/Skeleton';
import Table from 'Sparky/Table';
import Cell from 'Sparky/Table/Cell';
import InventoryTableCell from 'Sparky/Table/InventoryTableCell';
import InventoryTableHeader from 'Sparky/Table/InventoryTableHeader';
import TableBody from 'Sparky/Table/TableBody';
import { TableProvider, useTableContext } from 'Sparky/Table/TableContext';
import TableHeader from 'Sparky/Table/TableHeader';
import TablePagination from 'Sparky/Table/TablePagination';
import TableRow from 'Sparky/Table/TableRow';
import Text from 'Sparky/Text';

import SplitOnNewline from 'components/SplitOnNewline';
import {
  ButtonSize,
  CustomizeInventory,
  ShowInventorySideBar,
} from 'pages/Inventory/InventoryPage';
import { InventoryConfig } from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';
import { InventoryPageKey } from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';
import {
  InventoryTableAddBudgetSidebarColumnKey,
  InventoryTableColumnKey,
  InventoryTableWithValueColumnKey,
} from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableConfigGroup } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableTemplates';
import {
  addBudgetSidebarColumns,
  InventoryTableColumnDef,
  InventoryTableWithValueColumnDef,
} from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';
import { InventoryItem } from 'types/InventoryItem';
import { SortDirection } from 'types/SortDirection';
import { compare } from 'util/compare';

import styles from './InventoryTable.module.scss';

export interface InventoryTableProps {
  data?: InventoryItem[];
  sortedTableData?: InventoryItem[];
  isLoading?: boolean;
  inventoryPageKey: InventoryPageKey;
  buttonSize: ButtonSize;
  isCustomizing: CustomizeInventory;
  setIsCustomizing: Dispatch<SetStateAction<CustomizeInventory>>;
  isInventoryConfigChanged: boolean;
  inventoryTableAddBudgetSidebarColumnDefs: Record<
    InventoryTableAddBudgetSidebarColumnKey,
    InventoryTableWithValueColumnDef
  >;
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>;
  currentInventoryConfig: InventoryConfig;

  setCurrentInventoryConfig(val: InventoryConfig): void;

  selected: string[];
  budgetAppliedVins: string[];
  showInventorySidebar: ShowInventorySideBar;
  setShowInventorySidebar: Dispatch<SetStateAction<ShowInventorySideBar>>;
  showAddBudgetSidebar: boolean;
  showBudgetModal: boolean;

  toggleSelected(vin: string, hasExistingAddBudget?: boolean): void;

  toggleAllSelected(): void;

  onOpenDetailsSidebar(i: InventoryItem): void;

  onUpdateInventoryTableConfig(): void;

  handleSortAsc(col: InventoryTableColumnKey): void;

  handleSortDesc(col: InventoryTableColumnKey): void;

  showCompactInventoryView: boolean;
}

export type SortColumn = {
  id: InventoryTableWithValueColumnKey;
  direction: SortDirection;
};

export const pageSizeAtom = atomWithStorage('inventory-table-size', 100);

/**
 * InventoryTable
 */
function InventoryTable({
  data,
  sortedTableData,
  isLoading,
  buttonSize,
  isCustomizing,
  setIsCustomizing,
  isInventoryConfigChanged,
  inventoryTableAddBudgetSidebarColumnDefs,
  inventoryTableColumnDefs,
  currentInventoryConfig,
  setCurrentInventoryConfig,
  selected,
  budgetAppliedVins,
  showInventorySidebar,
  setShowInventorySidebar,
  showAddBudgetSidebar,
  showBudgetModal,
  toggleSelected,
  toggleAllSelected,
  onOpenDetailsSidebar,
  onUpdateInventoryTableConfig,
  handleSortAsc,
  handleSortDesc,
  showCompactInventoryView,
}: InventoryTableProps) {
  const [hover, setHover] = useState<string>('');
  const [pageSize] = useAtom(pageSizeAtom);
  const { sortColumns, inventoryTableConfig } = currentInventoryConfig;
  const allSelected = data && selected.length === data.length;

  if (isLoading) {
    return <Skeleton radius='0' width='100%' height='100%' />;
  }

  const showAddBudget = showAddBudgetSidebar || showBudgetModal;

  /** Row height needs to be set explicitly, to ensure that rows line up across both tables */
  let rowHeight = showCompactInventoryView ? '3rem' : '6rem';

  const activeColumns = new Set(
    inventoryTableConfig?.frozenColumns.concat(inventoryTableConfig?.mainColumns) ?? []
  );

  // If the summary or imageCell columns not visible, we can use shorter rows on default view
  if (
    !activeColumns.has('summary') &&
    !activeColumns.has('imageCell') &&
    !showCompactInventoryView
  ) {
    rowHeight = '4rem';
  }

  const filterAddBudgetColumns = (col: InventoryTableColumnKey) => {
    if (showAddBudget) {
      return col !== 'budget' && col !== 'aiRecommendedBudgetIncrease';
    } else {
      return true;
    }
  };

  const toggleFrozenColumn = (
    column: InventoryTableColumnKey,
    group: InventoryTableConfigGroup
  ) => {
    let cloneInventoryConfig = cloneDeep(currentInventoryConfig);
    if (group === 'frozenColumns') {
      cloneInventoryConfig.inventoryTableConfig.frozenColumns =
        cloneInventoryConfig.inventoryTableConfig.frozenColumns.filter((col) => col !== column);
      cloneInventoryConfig.inventoryTableConfig.mainColumns.unshift(column);
    } else {
      cloneInventoryConfig.inventoryTableConfig.mainColumns =
        cloneInventoryConfig.inventoryTableConfig.mainColumns.filter((col) => col !== column);
      cloneInventoryConfig.inventoryTableConfig.frozenColumns.push(column);
    }
    setCurrentInventoryConfig(cloneInventoryConfig);
  };

  const hideColumn = (column: InventoryTableColumnKey, group: InventoryTableConfigGroup) => {
    let cloneInventoryConfig = cloneDeep(currentInventoryConfig);
    cloneInventoryConfig.inventoryTableConfig[group] = cloneInventoryConfig.inventoryTableConfig[
      group
    ].filter((col) => col !== column);
    setCurrentInventoryConfig(cloneInventoryConfig);
  };

  const filteredBudgetColumns = addBudgetSidebarColumns.filter(
    (col) => inventoryTableAddBudgetSidebarColumnDefs[col].flag
  );

  const cancelCustomize = () => {
    setIsCustomizing('');
    setShowInventorySidebar('');
  };

  if ([...inventoryTableConfig.frozenColumns, ...inventoryTableConfig.mainColumns].length === 0) {
    return (
      <div className={styles.addColumnsContainer}>
        <Text size='24' fontWeight={7}>
          Start by adding columns to include in the view
        </Text>
        <Button variant='solid' onClick={() => setShowInventorySidebar('columns')}>
          Add columns
        </Button>
      </div>
    );
  }

  return (
    <TableProvider data={sortedTableData} initialState={{ pageSize }}>
      <div
        className={classnames([
          styles.inventoryTable,
          showInventorySidebar !== '' && styles.withSidebar,
        ])}
      >
        <PageSizeTracker />
        <div
          className={styles.inventoryTableWrapper}
          style={{ '--table-row-height': rowHeight } as CSSProperties}
        >
          {/* Frozen table */}
          <Table className={styles.frozenTable}>
            <thead>
              <TableRow>
                {!isCustomizing && (
                  <TableHeader className={styles.selectAll}>
                    <Checkbox
                      aria-label='Select all VINs'
                      smallHover={true}
                      indeterminate={selected.length > 0 && !allSelected}
                      checked={!!allSelected}
                      onClick={toggleAllSelected}
                      width={showCompactInventoryView ? '18px' : '24px'}
                    />
                  </TableHeader>
                )}
                {showAddBudget &&
                  filteredBudgetColumns.map((col, index) => (
                    <InventoryTableHeader
                      key={inventoryTableAddBudgetSidebarColumnDefs[col].key}
                      className={classnames([
                        styles.frozenTableColumnHeader,
                        filteredBudgetColumns.length - 1 === index && styles.divider,
                      ])}
                      textAlign='left'
                      onSortAsc={() => handleSortAsc(col)}
                      onSortDesc={() => handleSortDesc(col)}
                      sortable={!!inventoryTableAddBudgetSidebarColumnDefs[col].getter}
                      sortDirection={
                        sortColumns.find((sortColumn) => sortColumn.id === col)?.direction
                      }
                      tooltipKey={inventoryTableAddBudgetSidebarColumnDefs[col].tooltipKey}
                    >
                      <SplitOnNewline
                        text={inventoryTableAddBudgetSidebarColumnDefs[col].header}
                        notation={inventoryTableAddBudgetSidebarColumnDefs[col].notation}
                      />
                    </InventoryTableHeader>
                  ))}
                {inventoryTableConfig.frozenColumns
                  ?.filter(
                    (col) => inventoryTableColumnDefs[col].flag && filterAddBudgetColumns(col)
                  )
                  ?.map((col) => (
                    <InventoryTableHeader
                      key={inventoryTableColumnDefs[col].key}
                      className={styles.frozenTableColumnHeader}
                      textAlign='left'
                      isFrozenColumn={true}
                      onSortAsc={() => handleSortAsc(col)}
                      onSortDesc={() => handleSortDesc(col)}
                      toggleFrozen={() => toggleFrozenColumn(col, 'frozenColumns')}
                      toggleHide={() => hideColumn(col, 'frozenColumns')}
                      sortable={!!inventoryTableColumnDefs[col].getter}
                      sortDirection={
                        sortColumns.find((sortColumn) => sortColumn.id === col)?.direction
                      }
                      tooltipKey={inventoryTableColumnDefs[col].tooltipKey}
                    >
                      <SplitOnNewline
                        text={inventoryTableColumnDefs[col].header}
                        notation={inventoryTableColumnDefs[col].notation}
                      />
                    </InventoryTableHeader>
                  ))}
              </TableRow>
            </thead>
            <TableBody>
              {(r: InventoryItem) => (
                <TableRow
                  key={r.listing.vin}
                  className={classnames([
                    styles.frozenTableRow,
                    selected.indexOf(r.listing.vin) !== -1 && styles.frozenTableSelected,
                    hover === r.listing.vin && styles.frozenTableHover,
                  ])}
                  onClick={() => onOpenDetailsSidebar(r)}
                  onMouseEnter={() => setHover(r.listing.vin)}
                  onMouseLeave={() => setHover('')}
                >
                  {!isCustomizing && (
                    <Cell
                      textAlign='center'
                      onClick={(e) => e.stopPropagation()}
                      className={classnames([
                        styles.cell,
                        styles.checkbox,
                        selected.indexOf(r.listing.vin) !== -1 && styles.selectCell,
                        showAddBudget &&
                          budgetAppliedVins.indexOf(r.listing.vin) !== -1 &&
                          styles.showBudgetSelectCell,
                      ])}
                    >
                      <Checkbox
                        aria-label={`Select VIN ${r.listing.vin}`}
                        smallHover={true}
                        checked={selected.indexOf(r.listing.vin) !== -1}
                        onClick={() =>
                          toggleSelected(r.listing.vin, r.budgetMetrics.hasExistingAddBudget)
                        }
                        width={showCompactInventoryView ? '18px' : '24px'}
                        className={classnames([
                          showAddBudget &&
                            selected.indexOf(r.listing.vin) !== -1 &&
                            r.budgetMetrics.hasExistingAddBudget &&
                            styles.hasExistingAddBudget,
                        ])}
                      />
                    </Cell>
                  )}
                  {showAddBudget &&
                    filteredBudgetColumns.map((col, index) => (
                      <InventoryTableCell
                        key={inventoryTableAddBudgetSidebarColumnDefs[col].key}
                        col={inventoryTableAddBudgetSidebarColumnDefs[col].key}
                        width={inventoryTableAddBudgetSidebarColumnDefs[col].width}
                        textAlign={inventoryTableAddBudgetSidebarColumnDefs[col].textAlign}
                        className={classnames([
                          styles.budgetCell,
                          selected.indexOf(r.listing.vin) !== -1 &&
                            budgetAppliedVins.indexOf(r.listing.vin) === -1 &&
                            styles.selectActiveBudgetCell,
                          filteredBudgetColumns.length - 1 === index && styles.divider,
                          inventoryTableAddBudgetSidebarColumnDefs[col].className,
                        ])}
                      >
                        {inventoryTableAddBudgetSidebarColumnDefs[col].cellRenderer(r)}
                      </InventoryTableCell>
                    ))}
                  {inventoryTableConfig.frozenColumns
                    ?.filter(
                      (col) => inventoryTableColumnDefs[col].flag && filterAddBudgetColumns(col)
                    )
                    ?.map((col) => (
                      <InventoryTableCell
                        key={inventoryTableColumnDefs[col].key}
                        col={inventoryTableColumnDefs[col].key}
                        width={inventoryTableColumnDefs[col].width}
                        textAlign={inventoryTableColumnDefs[col].textAlign}
                        className={classnames([
                          styles.cell,
                          inventoryTableColumnDefs[col].className,
                        ])}
                      >
                        {inventoryTableColumnDefs[col].cellRenderer(r)}
                      </InventoryTableCell>
                    ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/* Scrollable main table */}
          <Table className={styles.mainTable}>
            <thead>
              <TableRow>
                {inventoryTableConfig.mainColumns
                  ?.filter(
                    (col) => inventoryTableColumnDefs[col].flag && filterAddBudgetColumns(col)
                  )
                  ?.map((col) => (
                    <InventoryTableHeader
                      key={inventoryTableColumnDefs[col].key}
                      className={styles.mainTableColumnHeader}
                      textAlign='left'
                      isFrozenColumn={false}
                      onSortAsc={() => handleSortAsc(col)}
                      onSortDesc={() => handleSortDesc(col)}
                      toggleFrozen={() => toggleFrozenColumn(col, 'mainColumns')}
                      toggleHide={() => hideColumn(col, 'mainColumns')}
                      sortable={!!inventoryTableColumnDefs[col].getter}
                      sortDirection={
                        sortColumns.find((sortColumn) => sortColumn.id === col)?.direction
                      }
                      tooltipKey={inventoryTableColumnDefs[col].tooltipKey}
                    >
                      <SplitOnNewline
                        text={inventoryTableColumnDefs[col].header}
                        notation={inventoryTableColumnDefs[col].notation}
                      />
                    </InventoryTableHeader>
                  ))}
              </TableRow>
            </thead>
            <TableBody>
              {(r: InventoryItem) => (
                <TableRow
                  key={r.listing.vin}
                  className={classnames([
                    styles.mainTableRow,
                    selected.indexOf(r.listing.vin) !== -1 && styles.mainTableSelected,
                    hover === r.listing.vin && styles.mainTableHover,
                  ])}
                  onClick={() => onOpenDetailsSidebar(r)}
                  onMouseEnter={() => setHover(r.listing.vin)}
                  onMouseLeave={() => setHover('')}
                >
                  {inventoryTableConfig.mainColumns
                    ?.filter(
                      (col) => inventoryTableColumnDefs[col].flag && filterAddBudgetColumns(col)
                    )
                    ?.map((col) => (
                      <InventoryTableCell
                        key={inventoryTableColumnDefs[col].key}
                        col={inventoryTableColumnDefs[col].key}
                        width={inventoryTableColumnDefs[col].width}
                        textAlign={inventoryTableColumnDefs[col].textAlign}
                        className={classnames([
                          styles.cell,
                          inventoryTableColumnDefs[col].className,
                        ])}
                      >
                        {inventoryTableColumnDefs[col].cellRenderer(r)}
                      </InventoryTableCell>
                    ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className={styles.tableFooter}>
          <TablePagination
            size={showCompactInventoryView ? 'sm' : 'md'}
            className={
              showAddBudgetSidebar ? styles.budgetTablePagination : styles.inventoryTablePagination
            }
          />
          {isCustomizing &&
            (isCustomizing === 'creating' || isCustomizing === 'fromScratch' ? (
              <>
                <Button variant='outline' size={buttonSize} onClick={cancelCustomize}>
                  Discard
                </Button>
                <Button variant='solid' size={buttonSize} onClick={onUpdateInventoryTableConfig}>
                  Create view
                </Button>
              </>
            ) : isInventoryConfigChanged ? (
              <>
                <Button variant='outline' size={buttonSize} onClick={cancelCustomize}>
                  Discard changes
                </Button>
                <Button variant='solid' size={buttonSize} onClick={onUpdateInventoryTableConfig}>
                  Apply changes
                </Button>
              </>
            ) : (
              <Button variant='outline' size={buttonSize} onClick={cancelCustomize}>
                Cancel
              </Button>
            ))}
        </div>
      </div>
    </TableProvider>
  );
}

export function PageSizeTracker() {
  const { pageSize } = useTableContext();
  const [, setPageSize] = useAtom(pageSizeAtom);
  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);
  return null;
}

export function sortMultiColumns(
  data: InventoryItem[] | undefined,
  sortColumns: SortColumn[],
  inventoryTableColumnWithGetterDefs: Record<
    InventoryTableWithValueColumnKey,
    InventoryTableWithValueColumnDef
  >
): InventoryItem[] | undefined {
  const newData = data?.slice();
  if (sortColumns.length > 0 && newData) {
    newData.sort((a, b) => {
      for (let i = 0; i < sortColumns.length; i++) {
        const { id, direction } = sortColumns[i];
        const aValue = inventoryTableColumnWithGetterDefs[id].getter(a);
        const bValue = inventoryTableColumnWithGetterDefs[id].getter(b);
        const aUndefined = aValue === null || typeof aValue === 'undefined';
        const bUndefined = bValue === null || typeof bValue === 'undefined';
        // Always sort null/undefined value to the last
        if (aUndefined || bUndefined) {
          return aUndefined ? 1 : -1;
        }

        const directionMulti = direction === 'asc' ? 1 : -1;
        const res = compare(aValue, bValue);

        if (res !== 0) {
          return res * directionMulti;
        }
      }
      return 0;
    });
  }
  return newData;
}

export default React.memo(InventoryTable);
