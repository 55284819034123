import { useMemo } from 'react';

import { useAtom } from 'jotai';
import Text from 'Sparky/Text';

import { HexData } from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';
import { activeDealerTypeAtom } from 'pages/Settings/LocalMarketSettings/DealerSidebar';
import {
  GreenCircleIconTooltip,
  PurpleCircleIconTooltip,
} from 'pages/Settings/LocalMarketSettings/icons/CircleIcon';

import styles from './MapPopup.module.scss';

export interface MapPopupProps {
  popupData: HexData;
}

/**
 * MapPopup
 */
export default function MapPopup({ popupData }: MapPopupProps) {
  const [activeDealerType] = useAtom(activeDealerTypeAtom);
  const isNewDealers = activeDealerType === 'new';

  return useMemo(
    () => (
      <div className={styles.mapPopup}>
        <div className={styles.tooltipRow}>
          <Text size='16' fontWeight={6} color='secondary'>
            {popupData.hexStatus}
          </Text>
        </div>
        <div className={styles.tooltipRow}>
          <Text size='12' color='secondary'>
            In-market shoppers
          </Text>
          <Text size='12' color='secondary'>
            {popupData.inMarketShoppers}
          </Text>
        </div>
        <div className={styles.tooltipRow}>
          <Text size='12' color='secondary'>
            Dealerships
          </Text>
          <Text size='12' color='secondary'>
            {isNewDealers ? popupData.dealerCountNew : popupData.dealerCountUsed}
          </Text>
        </div>
        <div className={styles.dealerList}>
          {popupData?.dealersInHex?.map((dealer) => {
            return activeDealerType === 'new' && dealer.dealerType === 'independent' ? (
              <div key={dealer.dealerId}> </div>
            ) : (
              <div key={dealer.dealerId} className={styles.dealerRow}>
                {dealer.dealerType === 'franchise' ? (
                  <GreenCircleIconTooltip />
                ) : (
                  <PurpleCircleIconTooltip />
                )}
                <Text>{dealer.dealerName}</Text>
              </div>
            );
          })}
        </div>
      </div>
    ),
    [popupData]
  );
}
