import { useMemo } from 'react';

import classnames from 'classnames';
import { useAtom } from 'jotai';
import { selectedAtom } from 'store/inventoryItemStore';

import { useFilters } from 'components/Filters';
import useSelectedDealer from 'hooks/useSelectedDealer';
import { InventoryFilterConfigs } from 'pages/Inventory/InventoryPage/InventoryFilters';
import SoldInventoryWidget from 'pages/Inventory/SoldInventory/SoldInventoryWidget/SoldInventoryWidget';
import { useInventoryFilterAtom } from 'store';
import { InventoryItem } from 'types/InventoryItem';

import styles from './SoldInventorySummary.module.scss';

interface SoldInventorySummaryProps {
  inventoryData?: InventoryItem[];
  showSoldInventorySummary: boolean;
  isInventoryLoading: boolean;
  className?: string;
}

export default function SoldInventorySummary({
  inventoryData,
  showSoldInventorySummary,
}: SoldInventorySummaryProps) {
  const [selected] = useAtom(selectedAtom);
  const { dealer } = useSelectedDealer();
  const filterConfigs = InventoryFilterConfigs<InventoryItem>(selected);
  const [filtersState, setFiltersState] = useInventoryFilterAtom(`${dealer?.id}+sold`);

  const { filteredData } = useFilters({
    data: inventoryData,
    filterConfigs: filterConfigs,
    filtersState: filtersState ?? {},
    setFiltersState: setFiltersState,
  });

  const targetedVins = useMemo(() => {
    return filteredData?.reduce(
      (acc, inv) => {
        if (inv.status.publishedInPeriod) {
          let total = acc.total + 1;
          let engagement = acc.engagement;
          let sellRate = acc.sellRate;
          if ((inv.gaMetrics.lotlinxEngLifetime ?? 0) > 0) {
            engagement++;
          }
          if (inv.status.status === 'SOLD') {
            sellRate++;
          }
          return { total, engagement, sellRate };
        }
        return acc;
      },
      { total: 0, engagement: 0, sellRate: 0 }
    );
  }, [filteredData]);

  const shoppedVins = useMemo(() => {
    return filteredData?.reduce(
      (acc, inv) => {
        if (inv.gaMetrics.shoppers && inv.gaMetrics.shoppers > 0) {
          let total = acc.total + 1;
          let engagement = acc.engagement;
          let sellRate = acc.sellRate;
          if ((inv.gaMetrics.lotlinxEngLifetime ?? 0) > 0) {
            engagement++;
          }
          if (inv.status.status === 'SOLD') {
            sellRate++;
          }
          return { total, engagement, sellRate };
        }
        return acc;
      },
      { total: 0, engagement: 0, sellRate: 0 }
    );
  }, [filteredData]);

  const soldVins = useMemo(() => {
    return filteredData?.reduce(
      (acc, inv) => {
        if (inv.status.status === 'SOLD' || inv.status.status === 'MOVED') {
          let total = acc.total + 1;
          let engagement = acc.engagement;
          let sellRate = acc.sellRate;
          let lotlinxViews = acc.views.lotlinx + (inv.gaMetrics.lotlinxVDPLifetime || 0);
          let otherViews = acc.views.other + (inv.gaMetrics.otherPaidVDPLifetime || 0);
          let lotlinxEngagements =
            acc.engagements.lotlinx + (inv.gaMetrics.lotlinxEngLifetime || 0);
          let otherEngagements = acc.engagements.other + (inv.gaMetrics.otherPaidEngLifetime || 0);
          let lotlinxConversions =
            acc.conversions.lotlinx + (inv.gaMetrics.lotlinxConvLifetime || 0);
          let otherConversions = acc.conversions.other + (inv.gaMetrics.otherPaidConvLifetime || 0);
          if ((inv.gaMetrics.lotlinxEngLifetime || 0) > 0) {
            engagement++;
          }
          if (inv.status.status === 'SOLD') {
            sellRate++;
          }
          return {
            total,
            engagement,
            sellRate,
            views: { lotlinx: lotlinxViews, other: otherViews },
            engagements: { lotlinx: lotlinxEngagements, other: otherEngagements },
            conversions: { lotlinx: lotlinxConversions, other: otherConversions },
          };
        }
        return acc;
      },
      {
        total: 0,
        engagement: 0,
        sellRate: 0,
        views: { lotlinx: 0, other: 0 },
        engagements: { lotlinx: 0, other: 0 },
        conversions: { lotlinx: 0, other: 0 },
      }
    );
  }, [filteredData]);

  const engagedVins = useMemo(() => {
    return filteredData?.reduce(
      (acc, inv) => {
        if (inv.gaMetrics.lotlinxEngLifetime && inv.gaMetrics.lotlinxEngLifetime > 0) {
          let total = acc.total + 1;
          let engagement = acc.engagement;
          let sellRate = acc.sellRate;
          if ((inv.gaMetrics.lotlinxEngLifetime ?? 0) > 0) {
            engagement++;
          }
          if (inv.status.status === 'SOLD') {
            sellRate++;
          }
          return { total, engagement, sellRate };
        }
        return acc;
      },
      { total: 0, engagement: 0, sellRate: 0 }
    );
  }, [filteredData]);

  return (
    <div
      className={classnames({
        [styles.soldInventorySummary]: true,
        [styles.showSoldInventorySummary]: showSoldInventorySummary,
      })}
    >
      <SoldInventoryWidget
        title='Targeted Vins'
        value={targetedVins?.total ?? 0}
        engagementRate={`${
          targetedVins?.engagement
            ? ((targetedVins?.engagement / targetedVins?.total) * 100).toFixed(0)
            : 0
        }%`}
        sellRate={`${
          targetedVins?.sellRate
            ? ((targetedVins?.sellRate / targetedVins?.total) * 100).toFixed(0)
            : 0
        }%`}
      />
      <SoldInventoryWidget
        title='Shopped Vins'
        value={shoppedVins?.total ?? 0}
        engagementRate={`${
          shoppedVins?.engagement
            ? ((shoppedVins?.engagement / shoppedVins?.total) * 100).toFixed(0)
            : 0
        }%`}
        sellRate={`${
          shoppedVins?.sellRate
            ? ((shoppedVins?.sellRate / shoppedVins?.total) * 100).toFixed(0)
            : 0
        }%`}
      />
      <SoldInventoryWidget
        title='Engaged Vins'
        value={engagedVins?.total ?? 0}
        engagementRate={`${
          engagedVins?.engagement
            ? ((engagedVins?.engagement / engagedVins?.total) * 100).toFixed(0)
            : 0
        }%`}
        sellRate={`${
          engagedVins?.sellRate
            ? ((engagedVins?.sellRate / engagedVins?.total) * 100).toFixed(0)
            : 0
        }%`}
      />
      <SoldInventoryWidget
        title='Sold Vins'
        value={soldVins?.total ?? 0}
        engagementRate={`${
          soldVins?.engagement ? ((soldVins?.engagement / soldVins?.total) * 100).toFixed(0) : 0
        }%`}
        sellRate={`${
          soldVins?.sellRate ? ((soldVins?.sellRate / soldVins?.total) * 100).toFixed(0) : 0
        }%`}
        sidePanel
        views={soldVins?.views}
        engagements={soldVins?.engagements}
        conversions={soldVins?.conversions}
      />
    </div>
  );
}
