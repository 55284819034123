import { ReactNode, useState } from 'react';

import {
  IconAlertTriangleFilled,
  IconChevronLeft,
  IconCircleCheckFilled,
  IconInfoCircleFilled,
  IconX,
} from '@tabler/icons-react';
import classnames from 'classnames';
import Text from 'Sparky/Text/Text';

import styles from './Message.module.scss';

export type MessageCategory = 'default' | 'danger' | 'disabled' | 'warning' | 'success' | 'info';
type Variant = 'muted' | 'outline';

export interface MessageProps {
  variant?: Variant;
  category?: MessageCategory;
  heading?: ReactNode;
  children?: ReactNode;
  collapsible?: boolean;
  dismissable?: boolean;
  defaultExpanded?: boolean;
  onExpandedClick?(): void;
  onCloseClick?(): void;
  onClick?(): void;
  className?: string;
  hideIcon?: boolean;
  elevated?: boolean;
}

/**
 * Message
 */
export default function Message({
  variant = 'muted',
  category = 'default',
  heading,
  children,
  collapsible = false,
  dismissable = true,
  defaultExpanded = true,
  onExpandedClick,
  onCloseClick,
  onClick,
  className,
  hideIcon = false,
  elevated = false,
}: MessageProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [showMessage, setShowMessage] = useState(true);

  const icon = selectIcon(category);

  return showMessage ? (
    <div
      className={classnames([styles.message, elevated && styles.elevated, className])}
      data-variant={variant}
      data-category={category}
      onClick={onClick}
    >
      {!hideIcon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.centerColumn}>
        {heading && (
          <Text className={styles.heading} type='label-emphasized-01'>
            {heading}
          </Text>
        )}
        {children && expanded && (
          <Text
            className={classnames([styles.content, heading !== undefined && styles.withHeading])}
            type='body-compact-01'
          >
            {children}
          </Text>
        )}
      </div>
      <div className={styles.controlIcon}>
        {collapsible && (
          <button
            onClick={() => {
              setExpanded(!expanded);
              onExpandedClick && onExpandedClick();
            }}
            aria-label='toggle content'
          >
            <IconChevronLeft size={14} style={{ rotate: expanded ? '90deg' : '270deg' }} />
          </button>
        )}
        {dismissable && !collapsible && (
          <button
            aria-label='close message'
            onClick={() => {
              onCloseClick && onCloseClick();
              setShowMessage(false);
            }}
          >
            <IconX size={14} />
          </button>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}

function selectIcon(category: MessageCategory) {
  if (category === 'default' || category === 'info') {
    return <IconInfoCircleFilled size='16' />;
  }
  if (category === 'success') {
    return <IconCircleCheckFilled size='16' />;
  }
  return <IconAlertTriangleFilled size='16' />;
}
