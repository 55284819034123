import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import {
  IconAdjustments,
  IconAlertTriangleFilled,
  IconChevronDown,
  IconEdit,
  IconExclamationMark,
  IconFilter,
  IconNewSection,
  IconPlusMinus,
  IconSwitchHorizontal,
  IconSwitchVertical,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import produce from 'immer';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Button from 'Sparky/Button';
import ButtonGroup, { ItemProps } from 'Sparky/ButtonGroup';
import Chip from 'Sparky/Chip';
import Divider from 'Sparky/Divider';
import IconButton from 'Sparky/IconButton';
import Message from 'Sparky/Message';
import Modal from 'Sparky/Modal';
import Popover from 'Sparky/Popover';
import RadioField from 'Sparky/RadioField';
import Spacer from 'Sparky/Spacer';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import TextInput from 'Sparky/TextInput';

import { Filter, FiltersState, INVENTORY_FILTERS, useFiltersContext } from 'components/Filters';
import { useNavigateKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import useSelectedDealer from 'hooks/useSelectedDealer';
import updateBodyStyleToChug from 'pages/Campaign/CampaignCreation/utils/updateBodyStyleToChug';
import {
  ButtonSize,
  CustomizeInventory,
  ShowInventorySideBar,
} from 'pages/Inventory/InventoryPage';
import { InventoryConfig } from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';
import { InventoryPageKey } from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';
import { InventoryTableColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableConfig } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableTemplates';
import FavouritesSelect from 'pages/Inventory/InventoryPage/InventoryHeader/FavouritesSelect';
import InventoryConfigSelect from 'pages/Inventory/InventoryPage/InventoryHeader/InventoryConfigSelect';
import { InventoryTableColumnDef } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';
import { showInventoryHighlightsAtom } from 'pages/Inventory/LiveInventory';
import { showSoldInventorySummaryAtom } from 'pages/Inventory/SoldInventory';
import { InventoryItem } from 'types/InventoryItem';
import { ColumnData, exportCSV } from 'util/exportCSV';

import styles from './InventoryHeader.module.scss';

export interface InventoryHeaderProps {
  inventoryPageKey: InventoryPageKey;
  inventoryConfigs: InventoryConfig[];
  isInventoryConfigChanged: boolean;
  buttonSize: ButtonSize;
  filteredData?: InventoryItem[];
  isCustomizing: CustomizeInventory;
  setIsCustomizing: Dispatch<SetStateAction<CustomizeInventory>>;
  showTableViewNameError: boolean;
  setShowTableViewNameError: Dispatch<SetStateAction<boolean>>;
  visibleSelected: string[];
  budgetAppliedVins: string[];
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>;
  currentInventoryConfig: InventoryConfig;

  setCurrentInventoryConfig(val: InventoryConfig): void;

  lastTimeSelectedInventoryConfig: InventoryConfig;
  setLastTimeSelectedInventoryConfig: Dispatch<SetStateAction<InventoryConfig>>;

  onDeleteTableView(): void;

  onFavoriteInventoryConfig(e: React.MouseEvent, id: number, name: string): void;

  creatingFromScratch: boolean;
  setCreatingFromScratch: Dispatch<SetStateAction<boolean>>;
  setShowAddBudgetSidebar: Dispatch<SetStateAction<boolean>>;
  showInventorySidebar: ShowInventorySideBar;

  setShowInventorySidebar(value: ShowInventorySideBar): void;

  setShowCompactInventoryView: Dispatch<SetStateAction<boolean>>;
  showCompactInventoryView: boolean;

  onCloseCustomizeInventoryConfig(): void;

  onUpdateInventoryTableConfig(): void;
}

export interface CampaignCreationState {
  filters: FiltersState;
  vins: string[];
  from?: 'inventory' | 'vvo' | 'risk' | 'vinPath';
  campaignType?: 'CORE' | 'BOOST';
}

export const campaignCreationAtom = atomWithStorage<CampaignCreationState>(
  'campaign-creation-state',
  { filters: {}, vins: [] }
);
const showNewFeatureOpenAtom = atomWithStorage<boolean>('show-new-feature-open', true);

/**
 * InventoryHeader
 */
export default function InventoryHeader({
  inventoryPageKey,
  inventoryConfigs,
  isInventoryConfigChanged,
  buttonSize,
  filteredData,
  isCustomizing,
  setIsCustomizing,
  showTableViewNameError,
  setShowTableViewNameError,
  visibleSelected,
  inventoryTableColumnDefs,
  budgetAppliedVins,
  currentInventoryConfig,
  setCurrentInventoryConfig,
  lastTimeSelectedInventoryConfig,
  setLastTimeSelectedInventoryConfig,
  onFavoriteInventoryConfig,
  onDeleteTableView,
  creatingFromScratch,
  setCreatingFromScratch,
  setShowAddBudgetSidebar,
  showInventorySidebar,
  setShowInventorySidebar,
  setShowCompactInventoryView,
  showCompactInventoryView,
  onCloseCustomizeInventoryConfig,
  onUpdateInventoryTableConfig,
}: InventoryHeaderProps) {
  const navigate = useNavigateKeepParams();
  const { dealer } = useSelectedDealer();
  const { filtersState, activeFilterPills } = useFiltersContext();
  const viewModifiedAnchorRef = useRef<HTMLDivElement>(null);
  const [showInventoryHighlights] = useAtom(showInventoryHighlightsAtom);
  const [showSoldInventorySummary] = useAtom(showSoldInventorySummaryAtom);
  const [showDensityPopover, setShowDensityPopover] = useState(false);
  const [showNewFeaturePopover, setShowNewFeaturePopover] = useAtom(showNewFeatureOpenAtom);
  const [showCreatingTableViewModal, setShowCreatingTableViewModal] = useState<boolean>(false);
  const [showViewModifiedInHeading, setShowViewModifiedInHeading] = useState(true);
  const [showDeleteTableViewModal, setShowDeleteTableViewModal] = useState<boolean>(false);
  const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);
  const [, setCampaignCreationState] = useAtom(campaignCreationAtom);
  const [tableViewName, setTableViewName] = useState<string>('');

  const { sortColumns, inventoryTableConfig, name, isPreset } = currentInventoryConfig;

  useEffect(() => {
    setTableViewName(name);
  }, [name]);

  useEffect(() => {
    setShowMoreDetails(false);
    setShowViewModifiedInHeading(true);
  }, [currentInventoryConfig.id]);

  const toggleDensityPopover = () => {
    setShowDensityPopover((prevState) => !prevState);
  };

  const handleCreateCampaign = () => {
    if (filteredData) {
      const vinList = selectedFilteredData(filteredData, visibleSelected).map(
        (item) => item.listing.vin
      );
      setCampaignCreationState({
        filters: updateBodyStyleToChug(filtersState, filteredData),
        vins: vinList,
        from: 'inventory',
        campaignType: 'CORE',
      });
    }
  };

  const onSelectInventoryConfig = (inventoryConfig: InventoryConfig) => {
    setCurrentInventoryConfig(inventoryConfig);
    setLastTimeSelectedInventoryConfig(inventoryConfig);
  };

  const buttonGroups: ItemProps[] = [
    {
      value: 'Selected VINs number',
      label: `${visibleSelected.length} selected`,
      disabled: true,
      className: styles.selectedVin,
    },
    {
      value: 'Export selected VINs as CSV',
      label: 'Export to CSV',
      disabled: !visibleSelected.length,
      onClick: () => {
        if (filteredData) {
          exportCSV(
            selectedFilteredData(filteredData, visibleSelected),
            formatCSVHeaders(inventoryTableConfig, inventoryTableColumnDefs),
            `${dealer?.name.replace(/\./g, '')}_${
              dealer?.id
            }_InventoryView_${inventoryPageKey}_${dayjs().format('YYYYMMDD')}`
          );
        }
      },
    },
  ];

  if (inventoryPageKey === 'live') {
    buttonGroups.push({
      value: 'Create campaign with selected VINs',
      label: 'Create campaign',
      disabled: !visibleSelected.length,
      onClick: () => {
        handleCreateCampaign();
        navigate('/campaign_creation');
      },
    });
  }

  if (visibleSelected.length - budgetAppliedVins.length > 0) {
    buttonGroups.push({
      value: 'Add Budget',
      label: 'Add budget',
      disabled: !visibleSelected.length || !dealer?.isVMS,
      onClick: () => setShowAddBudgetSidebar((prevState) => !prevState),
      rightIcon:
        !visibleSelected.length || !dealer?.isVMS ? undefined : (
          <Text className={styles.addBudgetPillNumber} size='12'>
            {visibleSelected.length - budgetAppliedVins.length}
          </Text>
        ),
    });
  }

  return (
    <div
      className={classnames([
        styles.inventoryHeader,
        showCompactInventoryView && styles.compactView,
      ])}
    >
      <div ref={viewModifiedAnchorRef} className={styles.mainHeader}>
        {!isCustomizing && (
          <>
            <InventoryConfigSelect
              inventoryConfigs={inventoryConfigs}
              currentInventoryConfig={currentInventoryConfig}
              onSelectInventoryConfig={onSelectInventoryConfig}
              onCreateNewInventoryConfig={() => setShowCreatingTableViewModal(true)}
              onFavoriteInventoryConfig={onFavoriteInventoryConfig}
              buttonSize={buttonSize}
            />
            <FavouritesSelect
              inventoryConfigs={inventoryConfigs}
              currentInventoryConfig={currentInventoryConfig}
              onSelectInventoryConfig={onSelectInventoryConfig}
              buttonSize={buttonSize}
              isVinSelected={visibleSelected.length > 0}
            />
          </>
        )}
        {visibleSelected.length === 0 && isCustomizing && (
          <>
            <IconButton
              aria-label='close customize'
              size={buttonSize}
              onClick={onCloseCustomizeInventoryConfig}
            >
              <IconX
                size={buttonSize === 'md' ? 20 : 12}
                stroke={3}
                color={COLOR.SPARKY_NAVY_400}
              />
            </IconButton>
            <Text size={buttonSize === 'md' ? '20' : '16'} fontWeight={7}>
              {isCustomizing === 'creating' || isCustomizing === 'fromScratch'
                ? 'Create new table view'
                : 'Edit custom table view'}
            </Text>
            <TextInput
              size={buttonSize}
              id='config name'
              placeholder='Name your table'
              variants={showTableViewNameError ? 'error' : 'default'}
              value={tableViewName}
              onChange={(e) => {
                if (showTableViewNameError) {
                  setShowTableViewNameError(false);
                }
                e.target.value.length <= 20 && setTableViewName(e.target.value);
              }}
              onBlur={() =>
                setCurrentInventoryConfig(
                  produce(currentInventoryConfig, (draft) => {
                    draft.name = tableViewName;
                    return draft;
                  })
                )
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  setCurrentInventoryConfig(
                    produce(currentInventoryConfig, (draft) => {
                      draft.name = tableViewName;
                      return draft;
                    })
                  );
                }
              }}
              rightElement={
                <>
                  <div>{tableViewName.length}/20</div>
                  {tableViewName.length > 0 && (
                    <button
                      className={styles.closeButton}
                      onClick={() => {
                        setTableViewName('');
                        setCurrentInventoryConfig(
                          produce(currentInventoryConfig, (draft) => {
                            draft.name = '';
                            return draft;
                          })
                        );
                      }}
                    >
                      <IconX size={buttonSize === 'md' ? 20 : 12} color={COLOR.SPARKY_GREY_400} />
                    </button>
                  )}
                </>
              }
            />
            {showTableViewNameError && (
              <Text size='14' fontWeight={4} color='error'>
                Required
              </Text>
            )}
          </>
        )}
        <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
        {showViewModifiedInHeading &&
          isInventoryConfigChanged &&
          !isCustomizing &&
          visibleSelected.length === 0 && (
            <Chip
              color='orange'
              variant='filled'
              leftIcon={<IconExclamationMark size='16' className={styles.exclamationMark} />}
              tooltip='Click to open details'
              handleClick={() => setShowViewModifiedInHeading(!showViewModifiedInHeading)}
            >
              View modified
            </Chip>
          )}
        <Filter
          config={INVENTORY_FILTERS.SEARCH}
          groupId='inventory'
          className={styles.searchBar}
          size={showCompactInventoryView ? 'sm' : 'md'}
        />
        <Button
          leftIcon={<IconFilter size={buttonSize === 'sm' ? 12 : 20} />}
          rightIcon={
            activeFilterPills.length > 0 ? (
              <Text className={styles.pillNumber} size='12'>
                {activeFilterPills.length}
              </Text>
            ) : undefined
          }
          size={buttonSize}
          variant='outline'
          onClick={() => {
            if (showInventorySidebar !== 'filter') {
              setShowInventorySidebar('filter');
            } else {
              setShowInventorySidebar('');
            }
          }}
        >
          Filter
        </Button>
        <Button
          leftIcon={<IconSwitchVertical size={buttonSize === 'sm' ? 12 : 20} />}
          rightIcon={
            sortColumns.length > 0 ? (
              <Text className={styles.pillNumber} size='12'>
                {sortColumns.length}
              </Text>
            ) : undefined
          }
          size={buttonSize}
          variant='outline'
          onClick={() => {
            if (showInventorySidebar !== 'sort') {
              setShowInventorySidebar('sort');
            } else {
              setShowInventorySidebar('');
            }
          }}
        >
          Sort
        </Button>
        {/*{(activeFilterPills.length > 0 || sortColumns.length > 0) && (*/}
        {/*  <Button*/}
        {/*    onClick={() => {*/}
        {/*      clearAll();*/}
        {/*      setSortColumns([]);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Clear all*/}
        {/*  </Button>*/}
        {/*)}*/}
        {/*<Spacer axis='horizontal' style={{ margin: '0 auto' }} />*/}
        {visibleSelected.length > 0 ? (
          <ButtonGroup
            toggleable={true}
            multiActives={true}
            items={buttonGroups}
            boxShadow={true}
            size={buttonSize}
          />
        ) : isCustomizing ? (
          <>
            <Button
              leftIcon={<IconSwitchHorizontal size={buttonSize === 'sm' ? 12 : 20} />}
              variant='outline'
              size={buttonSize}
              onClick={() => {
                if (showInventorySidebar !== 'arrange') {
                  setShowInventorySidebar('arrange');
                } else {
                  setShowInventorySidebar('');
                }
              }}
            >
              Arrange
            </Button>
            <Button
              leftIcon={<IconPlusMinus size={buttonSize === 'sm' ? 12 : 20} />}
              variant='outline'
              size={buttonSize}
              onClick={() => {
                if (showInventorySidebar !== 'columns') {
                  setShowInventorySidebar('columns');
                } else {
                  setShowInventorySidebar('');
                }
              }}
            >
              Columns
            </Button>
          </>
        ) : (
          <>
            {/* VOLUME OVERLAY POPOVER */}
            <Popover
              isOpen={showDensityPopover}
              setIsOpen={setShowDensityPopover}
              showCarrat={false}
              showCloseButton={false}
              side='bottom'
              align='end'
              popupContent={
                <div className={styles.densityPopoverContainer}>
                  <Text fontWeight={5} className={styles.popoverRow}>
                    Density
                  </Text>
                  <RadioField
                    smallHover
                    checked={!showCompactInventoryView}
                    onClick={() => {
                      setShowCompactInventoryView(false);
                      toggleDensityPopover();
                    }}
                    containerClassNames={styles.popoverRow}
                  >
                    Default
                  </RadioField>
                  <RadioField
                    smallHover
                    checked={showCompactInventoryView}
                    onClick={() => {
                      setShowCompactInventoryView(true);
                      toggleDensityPopover();
                    }}
                    containerClassNames={styles.popoverRow}
                  >
                    Compact
                  </RadioField>
                  {!isPreset && (
                    <>
                      <Divider className={styles.divider} />
                      <Text
                        fontWeight={5}
                        as='div'
                        color='primary'
                        onClick={() => {
                          setIsCustomizing('editing');
                          toggleDensityPopover();
                        }}
                        className={styles.popoverOptionRow}
                      >
                        <IconEdit size='16' /> Edit view
                      </Text>
                      <Text
                        fontWeight={5}
                        as='div'
                        color='error'
                        onClick={() => {
                          setShowDeleteTableViewModal(true);
                          toggleDensityPopover();
                        }}
                        className={styles.popoverOptionRow}
                      >
                        <IconTrash size='16' /> Delete view
                      </Text>
                    </>
                  )}
                </div>
              }
            >
              <IconButton
                aria-label='density dropdown toggler'
                tooltip='Density options'
                variants='outline'
                className={classnames([showCompactInventoryView && styles.densityButton])}
                size={buttonSize}
                children={<IconAdjustments size={buttonSize === 'sm' ? 12 : 20} />}
                onClick={() => toggleDensityPopover()}
              />
            </Popover>
            <IconButton
              aria-label='density dropdown toggler'
              variants='outline'
              className={classnames([showCompactInventoryView && styles.densityButton])}
              size={buttonSize}
              tooltip='Create a custom table view'
              children={<IconNewSection size={buttonSize === 'sm' ? 12 : 20} />}
              onClick={() => setShowCreatingTableViewModal(true)}
            />
          </>
        )}
      </div>
      {!isCustomizing && currentInventoryConfig.details.length > 0 && (
        <div className={styles.inventoryConfigDetails}>
          {currentInventoryConfig.isPreset ? (
            <>
              <div
                onClick={() => setShowMoreDetails(!showMoreDetails)}
                className={styles.inventoryConfigDetailsInput}
              >
                <Text size='12' color='navy'>
                  {showMoreDetails ? 'Hide' : 'More'} details
                </Text>
                <IconChevronDown
                  size='16'
                  color={COLOR.SPARKY_NAVY_400}
                  style={{ transform: `rotate(${showMoreDetails ? '180' : '0'}deg)` }}
                />
              </div>
              {showMoreDetails && (
                <Text size='14' fontWeight={4}>
                  {currentInventoryConfig.details}
                </Text>
              )}
            </>
          ) : (
            <Text size='14' fontWeight={4} color='secondary'>
              {currentInventoryConfig.details}
            </Text>
          )}
        </div>
      )}

      {/* NEW FEATURE MESSAGE */}
      {showNewFeaturePopover && !isCustomizing && (
        <Message
          category='info'
          heading='NEW FEATURE: Table views'
          onCloseClick={() => setShowNewFeaturePopover(false)}
          className={classnames({
            [styles.newFeatureMessage]: true,
            [styles.compactInventoryView]: showCompactInventoryView,
            [styles.highlightsOrSummary]:
              !showCompactInventoryView &&
              ((showInventoryHighlights && inventoryPageKey === 'live') ||
                (showSoldInventorySummary && inventoryPageKey === 'sold')),
            [styles.compactHighlightsOrSummary]:
              showCompactInventoryView &&
              ((showInventoryHighlights && inventoryPageKey === 'live') ||
                (showSoldInventorySummary && inventoryPageKey === 'sold')),
          })}
        >
          Table views have been carefully currated to show you what you need to know about a topic.
          We've pre-filtered, sorted and selected data columns that will help you understand what is
          going on in a certain area of your business. <br /> <br />
          Click the title to change the view to a different topic or to create your own custom view.
        </Message>
      )}
      {/* VIEW CHANGED MESSAGE */}
      {isInventoryConfigChanged &&
        !isCustomizing &&
        !showViewModifiedInHeading &&
        visibleSelected.length === 0 && (
          <Message
            category='warning'
            heading='View modified'
            onCloseClick={() => setShowViewModifiedInHeading(true)}
            className={classnames({
              [styles.viewChangedMessage]: true,
              [styles.compactInventoryView]: showCompactInventoryView,
              [styles.highlightsOrSummary]:
                !showCompactInventoryView &&
                ((showInventoryHighlights && inventoryPageKey === 'live') ||
                  (showSoldInventorySummary && inventoryPageKey === 'sold')),
              [styles.compactHighlightsOrSummary]:
                showCompactInventoryView &&
                ((showInventoryHighlights && inventoryPageKey === 'live') ||
                  (showSoldInventorySummary && inventoryPageKey === 'sold')),
            })}
          >
            {currentInventoryConfig.isPreset ? (
              <div className={styles.messageContainer}>
                <Text>
                  Save your changes to this view by creating a new custom view or reset the view to
                  it's original state
                </Text>
                <div className={styles.buttonContainer}>
                  <Button
                    size='sm'
                    contentClassName={styles.customButton}
                    onClick={() => setCurrentInventoryConfig(lastTimeSelectedInventoryConfig)}
                  >
                    Reset
                  </Button>
                  <Button
                    variant='outline'
                    size='sm'
                    onClick={() => setShowCreatingTableViewModal(true)}
                  >
                    Create new view
                  </Button>
                </div>
              </div>
            ) : (
              <div className={styles.messageContainer}>
                <Text>
                  Apply your changes to this view, create a new view or reset the it to it's
                  original state
                </Text>
                <div className={styles.buttonContainer}>
                  <Button
                    size='sm'
                    onClick={() => setShowCreatingTableViewModal(true)}
                    className={styles.createNewButton}
                    contentClassName={styles.customButton}
                  >
                    Create new view
                  </Button>
                  <Button
                    variant='outline'
                    size='sm'
                    onClick={() => setCurrentInventoryConfig(lastTimeSelectedInventoryConfig)}
                  >
                    Reset
                  </Button>
                  <Button variant='solid' size='sm' onClick={() => onUpdateInventoryTableConfig()}>
                    Apply changes
                  </Button>
                </div>
              </div>
            )}
          </Message>
        )}

      <Modal
        aria-label='Delete the view modal'
        isOpen={showDeleteTableViewModal}
        onDismiss={() => setShowDeleteTableViewModal(false)}
      >
        <div className={styles.deleteTableViewModal}>
          <IconAlertTriangleFilled size='48' color={COLOR.SPARKY_ORANGE_400} />
          <Text size='20' fontWeight={7} color='warning'>
            Are you sure you want to delete this view?
          </Text>
          <Text size='14' fontWeight={4} color='tertiary'>
            Once deleted, your custom table cannot be recovered
          </Text>
          <div className={styles.buttonContainer}>
            <Button
              variant='outline'
              category='default'
              onClick={() => setShowDeleteTableViewModal(false)}
            >
              No, go back
            </Button>
            <Button
              variant='solid'
              category='danger'
              onClick={() => {
                onDeleteTableView();
                setShowDeleteTableViewModal(false);
              }}
            >
              Yes, delete it
            </Button>
          </div>
          <IconButton
            aria-label='close creating modal'
            onClick={() => setShowDeleteTableViewModal(false)}
            size='md'
            className={styles.closeModalButton}
          >
            <IconX size='16' color={COLOR.SPARKY_GREY_400} />
          </IconButton>
        </div>
      </Modal>

      <Modal
        aria-label='Create a new inventory view modal'
        isOpen={showCreatingTableViewModal}
        onDismiss={() => setShowCreatingTableViewModal(false)}
      >
        <div className={styles.creatingTableViewModal}>
          <Text size='20' fontWeight={7} color='navy'>
            How would you like to create your new table view?
          </Text>
          <div className={styles.optionContainer}>
            <RadioField
              checked={!creatingFromScratch}
              onClick={() => setCreatingFromScratch(false)}
            >
              Use current view as starting point
            </RadioField>
            <RadioField checked={creatingFromScratch} onClick={() => setCreatingFromScratch(true)}>
              Start from scratch
            </RadioField>
          </div>
          <div className={styles.buttonContainer}>
            <Button variant='outline' onClick={() => setShowCreatingTableViewModal(false)}>
              Cancel
            </Button>
            <Button
              variant='solid'
              onClick={() => {
                setIsCustomizing(creatingFromScratch ? 'fromScratch' : 'creating');
                setShowCreatingTableViewModal(false);
              }}
            >
              Start creating
            </Button>
          </div>
          <IconButton
            aria-label='close creating modal'
            onClick={() => setShowCreatingTableViewModal(false)}
            size='md'
            className={styles.closeModalButton}
          >
            <IconX size='16' color={COLOR.SPARKY_GREY_400} />
          </IconButton>
        </div>
      </Modal>
    </div>
  );
}

/** Returns visible selected filtered inventory data only */
function selectedFilteredData(filteredData: InventoryItem[], visibleSelected: string[]) {
  return filteredData.filter((data) => visibleSelected.includes(data.listing.vin));
}

/**
 * Returns formatted header for exportCSV utility
 * Uses header and csv Renderer from to InventoryTableColumnDefs format data
 */
function formatCSVHeaders(
  inventoryTableConfig: InventoryTableConfig,
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>
) {
  const columns: ColumnData<InventoryItem>[] = [];

  [...inventoryTableConfig.frozenColumns, ...inventoryTableConfig.mainColumns].forEach((item) => {
    // Summary Column is broken down into it's base data points for the CSV
    if (item === 'summary') {
      const summaryData = [
        inventoryTableColumnDefs.modelYear,
        inventoryTableColumnDefs.make,
        inventoryTableColumnDefs.model,
        inventoryTableColumnDefs.trim,
        inventoryTableColumnDefs.bodySegment,
        inventoryTableColumnDefs.stockNumber,
        inventoryTableColumnDefs.vin,
        inventoryTableColumnDefs.condition,
        inventoryTableColumnDefs.imageCount,
      ];
      summaryData.forEach((item) => {
        const data = {
          header: item.header,
          cell: item.csvRenderer || item.getter,
        } as ColumnData<InventoryItem>;
        columns.push(data);
      });
      // ignore imageCell as it's not applicable to csv
    } else if (item !== 'imageCell') {
      let header = inventoryTableColumnDefs[item].header.replace(/[\n\r]/g, ' ');
      const data = {
        header: header,
        cell: inventoryTableColumnDefs[item].csvRenderer || inventoryTableColumnDefs[item].getter,
      } as ColumnData<InventoryItem>;
      columns.push(data);
    }
  });
  return columns;
}
