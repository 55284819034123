import React from 'react';

import { IconCaretDownFilled, IconCaretUpFilled, IconTag } from '@tabler/icons-react';
import classnames from 'classnames';
import Accordion from 'Sparky/Accordion';
import Button from 'Sparky/Button';
import Chip, { ChipColors } from 'Sparky/Chip';
import Divider from 'Sparky/Divider';
import Flex from 'Sparky/Flex';
import Message from 'Sparky/Message';
import Panel from 'Sparky/Panel';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import useVinPathPriceData from 'pages/Inventory/VinDetailSidebar/api/useVinPathPriceData/useVinPathPriceData';
import LoadingState from 'pages/Inventory/VinDetailSidebar/components/LoadingState';
import NoDataAvailable from 'pages/Inventory/VinDetailSidebar/components/NoDataAvailable';
import { useVinDetailContext } from 'pages/Inventory/VinDetailSidebar/utils/VinDetailContext';
import MarkdownHistoryTable from 'pages/Inventory/VinDetailSidebar/VinPathPriceSection/MarkdownHistoryTable';
import PriceScenarios from 'pages/Inventory/VinDetailSidebar/VinPathPriceSection/PriceScenario';
import ProbabilityLabel from 'pages/Inventory/VinDetailSidebar/VinPathPriceSection/ProbabilityLabel';
import { getPriceScenarioColor } from 'pages/Inventory/VinPath/utils/getPriceScenarioColor';
import { getProbabilityChipLabel } from 'pages/Inventory/VinPath/utils/getProbabilityChipLabel';
import { formatCurrency } from 'util/formatters';

import styles from './VinPathPriceSection.module.scss';

/**
 * VinPathPriceSection
 */
export default function VinPathPriceSection() {
  const [showPricingScenarios, setShowPricingScenarios] = React.useState(true);
  const [showHistory, setShowHistory] = React.useState(true);
  const { item, openSection, onOpenSection } = useVinDetailContext();

  const { data, isLoading, isError } = useVinPathPriceData(item.listing.vin);

  return (
    <Accordion
      className={classnames([
        styles.vinPathPriceSection,
        openSection.includes('price') && styles.openState,
      ])}
      isExpanded={openSection.includes('price')}
      handleClick={() => onOpenSection('price')}
      rightElement={
        <ProbabilityLabel
          probability={item.vinPath?.currentProbabilityOfSale}
          currentPrice={item.priceMetrics?.currentPrice}
          isLoading={isLoading}
        />
      }
      title='Price'
      titleSize='14'
      hightlightTitleOnOpen
      formatName={false}
      iconBefore={
        <IconTag
          size={18}
          color={openSection.includes('price') ? COLOR.SPARKY_NAVY_400 : COLOR.SPARKY_GREY_400}
        />
      }
      isOpenByRow
    >
      <Flex flex='none' width='100%' column rowGap='12px' padding='0 16px 16px'>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <NoDataAvailable sectionName='Price' />
        ) : (
          <>
            <Panel className={styles.topPanel}>
              <Flex alignItems='flex-end' justifyContent='space-between' columnGap='12px'>
                <Flex column rowGap='6px'>
                  <Text color='secondary' fontWeight={4} size={12} textWrap='nowrap'>
                    Current price
                  </Text>
                  <Text color='primary' fontWeight={5} size={20}>
                    {formatCurrency(item.priceMetrics?.currentPrice)}
                  </Text>
                </Flex>
                {item.vinPath?.currentProbabilityOfSale && (
                  <>
                    <Chip
                      variant='filled'
                      color={
                        getPriceScenarioColor(item.vinPath?.currentProbabilityOfSale) as ChipColors
                      }
                    >
                      {getProbabilityChipLabel(item.vinPath?.currentProbabilityOfSale)}
                    </Chip>
                    <Button
                      size='sm'
                      rightIcon={
                        showPricingScenarios ? (
                          <IconCaretUpFilled size={16} />
                        ) : (
                          <IconCaretDownFilled size={16} />
                        )
                      }
                      onClick={() => setShowPricingScenarios(!showPricingScenarios)}
                      className={styles.toggleBtn}
                    >
                      {showPricingScenarios ? 'Hide pricing scenarios' : 'Show pricing scenarios'}
                    </Button>
                  </>
                )}
              </Flex>
              {item.vinPath?.currentProbabilityOfSale && (
                <Flex
                  flex='none'
                  column
                  height={showPricingScenarios ? 'fit-content' : '0'}
                  rowGap='16px'
                  className={styles.priceScenariosContainer}
                >
                  <Divider className={styles.divider} />
                  <PriceScenarios
                    priceScenarios={data?.pricingScenarios}
                    currentProbabilityOfSale={item.vinPath?.currentProbabilityOfSale}
                  />
                </Flex>
              )}
            </Panel>
            {!data?.markdownHistory.priceChangeHistory ||
            data?.markdownHistory.priceChangeHistory.length === 1 ? (
              <Message variant='muted' heading='No markdown history'>
                This vehicle hasn't had any price changes
              </Message>
            ) : (
              <Panel className={styles.bottomPanel}>
                <Flex width='100%' justifyContent='space-between' alignItems='flex-end'>
                  <Flex column rowGap='6px'>
                    <Text color='secondary' fontWeight={5} size={16} textWrap='nowrap'>
                      {Math.max(data.markdownHistory.priceChangeHistory.length - 1, 0)} markdowns
                      totalling
                    </Text>
                    <Flex alignItems='center' columnGap='8px'>
                      <Text size={20} fontWeight={5}>
                        {formatCurrency(data?.markdownHistory.totalMarkdown)}
                      </Text>
                      <Chip variant='filled' color='grey'>
                        {formatCurrency(data?.markdownHistory.avgMarkdown)} average
                      </Chip>
                    </Flex>
                  </Flex>
                  <Button
                    size='sm'
                    rightIcon={
                      showHistory ? (
                        <IconCaretUpFilled size={16} />
                      ) : (
                        <IconCaretDownFilled size={16} />
                      )
                    }
                    onClick={() => setShowHistory(!showHistory)}
                  >
                    {showHistory ? 'Hide history' : 'Show history'}
                  </Button>
                </Flex>
                <Flex
                  flex='none'
                  column
                  height={showHistory ? 'fit-content' : '0'}
                  rowGap='16px'
                  padding={showHistory ? '16px 0 0' : '0'}
                  className={styles.priceHistory}
                >
                  <MarkdownHistoryTable
                    markdownHistory={data?.markdownHistory.priceChangeHistory ?? []}
                  />
                </Flex>
              </Panel>
            )}
          </>
        )}
      </Flex>
    </Accordion>
  );
}
