import React, { useLayoutEffect } from 'react';

import { NumberInputField, NumberInputFieldProps } from 'Sparky/NumberInputField';
import Text from 'Sparky/Text';

import { useFiltersContext } from 'components/Filters/FiltersContext';
import { RangeFilterConfig, RangeState } from 'components/Filters/types';

import styles from './RangeOptions.module.scss';

interface RangeOptionsProps {
  config: RangeFilterConfig<unknown>;
}

/**
 * Range filter inputs
 */
export default function RangeOptions({ config }: RangeOptionsProps) {
  const { getFilterState, setFilterState, clearFilter, isLoading } = useFiltersContext();
  const state = getFilterState(config.id) as RangeState | undefined;
  const [minState, setMinState] = React.useState<string | undefined>(
    typeof state?.min === 'number' ? state?.min.toString() : undefined
  );
  const [maxState, setMaxState] = React.useState<string | undefined>(
    typeof state?.min === 'number' ? state?.min.toString() : undefined
  );

  const updateFilterValue = () => {
    const min = parseValue(minState);
    const max = parseValue(maxState);
    if (min === undefined && max === undefined) {
      clearFilter(config.id);
    } else if (min === undefined || max === undefined || min <= max) {
      setFilterState(config.id, { min: min, max: max });
    } else {
      // If min is larger than max, switch places
      setFilterState(config.id, { min: max, max: min });
    }
  };

  useLayoutEffect(() => {
    setMinState(typeof state?.min === 'number' ? state?.min.toString() : undefined);
    setMaxState(typeof state?.max === 'number' ? state?.max.toString() : undefined);
  }, [state]);

  const commonProps: Omit<NumberInputFieldProps, 'id' | 'label'> = {
    disabled: isLoading,
    size: 'sm',
    onBlur: () => updateFilterValue(),
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        updateFilterValue();
      }
    },
  };

  return (
    <div className={styles.rangeOptions}>
      <NumberInputField
        id={`${config.id}-min`}
        label='Min.'
        placeholder='Min'
        name='min'
        showNumberCaret={true}
        thousandSeparator={true}
        value={minState}
        onValueChange={(value) => setMinState(value.value)}
        prefix={config.rangePrefix}
        suffix={config.rangeSuffix}
        className={styles.minInput}
        {...commonProps}
      />
      <Text color='tertiary' className={styles.connector}>
        -
      </Text>
      <NumberInputField
        id={`${config.id}-max`}
        label='Max.'
        placeholder='Max'
        name='max'
        showNumberCaret={true}
        thousandSeparator={true}
        value={maxState}
        onValueChange={(value) => setMaxState(value.value)}
        prefix={config.rangePrefix}
        suffix={config.rangeSuffix}
        className={styles.maxInput}
        {...commonProps}
      />
    </div>
  );
}

function parseValue(val?: string) {
  if (!val) {
    return undefined;
  }

  const parsed = parseFloat(val);
  return isNaN(parsed) ? undefined : parsed;
}
