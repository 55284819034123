import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
// import { Outlet } from 'react-router-dom';

import useInventory from 'api/useInventory/useInventory';
import Page from 'components/Page/Page';
import InventoryPage from 'pages/Inventory/InventoryPage';
import PageHeader from 'pages/Inventory/InventoryPage/PageHeader';

import styles from './SoldInventory.module.scss';

/**
 * SoldInventory
 */
export const inventoryContractListAtom = atomWithStorage<{ id: number; name: string }[]>(
  'inventory-contract-list',
  []
);

export const showSoldInventorySummaryAtom = atom<boolean>(false);

export default function SoldInventory() {
  // Fetch Inventory Data from API
  const inventoryResponse = useInventory();
  const [showSoldInventorySummary, setShowSoldInventorySummary] = useAtom(
    showSoldInventorySummaryAtom
  );

  return (
    <div className={styles.soldInventory}>
      <PageHeader
        inventoryPageKey='sold'
        showSoldInventorySummary={showSoldInventorySummary}
        setShowSoldInventorySummary={setShowSoldInventorySummary}
      />

      <InventoryPage
        inventoryData={inventoryResponse.data?.inventory.filter(
          (i) => i.status.status === 'SOLD' || i.status.status === 'MOVED'
        )}
        showSoldInventorySummary={showSoldInventorySummary}
        isInventoryLoading={inventoryResponse.isLoading}
        isInventoryError={inventoryResponse.error}
        inventoryPageKey='sold'
        contracts={inventoryResponse.data?.contracts}
      />
      {/* Outlet for the VINDetailModal */}
      {/*<Outlet />*/}
    </div>
  );
}

export function Component() {
  return (
    <Page requireDealer title='Sold inventory'>
      <SoldInventory />
    </Page>
  );
}
Component.displayName = 'SoldInventoryPage';
