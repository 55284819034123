import * as Slider from '@radix-ui/react-slider';

import styles from './Slider.module.scss';

export interface SliderProps {
  value: number[];
  setValue: (value: number[]) => void;
  width?: number;
  defaultValue?: number[];
  min?: number;
  max?: number;
  step?: number;
}

/**
 * Slider
 */
export default function SliderComponent({
  value,
  setValue,
  defaultValue = [30],
  min = 10,
  max = 120,
  step = 1,
}: SliderProps) {
  return (
    <form>
      <Slider.Root
        className={styles.sliderRoot}
        defaultValue={defaultValue}
        min={min}
        max={max}
        step={step}
        value={value}
        onValueChange={(e) => setValue(e)}
        onValueCommit={(e) => {
          setValue(e);
        }}
      >
        <Slider.Track className={styles.sliderTrack}>
          <Slider.Range className={styles.sliderRange} />
        </Slider.Track>
        <Slider.Thumb className={styles.sliderThumb} aria-label='Volume' />
      </Slider.Root>
    </form>
  );
}
