import { CSSProperties, useEffect } from 'react';

import classnames from 'classnames';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Checkbox from 'Sparky/Checkbox';
import Skeleton from 'Sparky/Skeleton';
import Table from 'Sparky/Table';
import Cell from 'Sparky/Table/Cell';
import InventoryTableCell from 'Sparky/Table/InventoryTableCell';
import InventoryTableHeader from 'Sparky/Table/InventoryTableHeader';
import TableBody from 'Sparky/Table/TableBody';
import { TableProvider, useTableContext } from 'Sparky/Table/TableContext';
import TableHeader from 'Sparky/Table/TableHeader';
import TablePagination from 'Sparky/Table/TablePagination';
import TableRow from 'Sparky/Table/TableRow';

import SplitOnNewline from 'components/SplitOnNewline';
import { AcquisitionConfig, ShowAcquisitionSideBar } from 'pages/Inventory/Acquisition';
import { AcquisitionTableColumnDefs } from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTableColumnDefs';
import { AcquisitionTableActionsConfig } from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTableConfigDefs';
import { AcquisitionItem } from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';
import { InventoryTableColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';

import styles from './AcquisitionTable.module.scss';

export interface AcquisitionTableProps {
  isLoading: boolean;
  sortedTableData?: AcquisitionItem[];
  acquisitionConfig: AcquisitionConfig;
  selected: string[];
  showAcquisitionSidebar: ShowAcquisitionSideBar;

  toggleAllSelected(): void;

  toggleSelected(vin: string): void;

  handleSortAsc(column: InventoryTableColumnKey): void;

  handleSortDesc(column: InventoryTableColumnKey): void;

  showCompactAcquisitionView: boolean;
}

export const pageSizeAtom = atomWithStorage('acquisition-table-size', 100);

/**
 * AcquisitionTable
 */
export default function AcquisitionTable({
  isLoading,
  sortedTableData,
  acquisitionConfig,
  selected,
  showAcquisitionSidebar,
  toggleAllSelected,
  toggleSelected,
  handleSortAsc,
  handleSortDesc,
  showCompactAcquisitionView,
}: AcquisitionTableProps) {
  const [pageSize] = useAtom(pageSizeAtom);

  if (isLoading) {
    return <Skeleton radius='0' width='100%' height='100%' />;
  }

  const allSelected = sortedTableData && selected.length === sortedTableData.length;

  let rowHeight = showCompactAcquisitionView ? '49px' : '96px';

  const activeColumns = new Set(acquisitionConfig.acquisitionTableConfig);
  // If the summary or imageCell columns not visible, we can use shorter rows on default view
  if (
    !activeColumns.has('summary') &&
    !activeColumns.has('imageCell') &&
    !showCompactAcquisitionView
  ) {
    rowHeight = '30px';
  }

  return (
    <TableProvider data={sortedTableData} initialState={{ pageSize }}>
      <PageSizeTracker />
      <div
        style={{ '--table-row-height': rowHeight } as CSSProperties}
        className={classnames([
          styles.acquisitionTable,
          showAcquisitionSidebar !== '' && styles.withSidebar,
        ])}
      >
        <div className={styles.tableContent}>
          {/*Main acquisition table*/}
          <Table className={styles.mainTable}>
            <thead>
              <TableRow className={styles.acquisitionTableHeader}>
                <TableHeader className={styles.selectAll}>
                  <Checkbox
                    aria-label='Select all VINs'
                    smallHover={true}
                    indeterminate={selected.length > 0 && !allSelected}
                    checked={!!allSelected}
                    onClick={toggleAllSelected}
                    width={showCompactAcquisitionView ? '18px' : '24px'}
                  />
                </TableHeader>
                {acquisitionConfig.acquisitionTableConfig.map((col) => (
                  <InventoryTableHeader
                    key={AcquisitionTableColumnDefs[col].key}
                    onSortAsc={() => handleSortAsc(col)}
                    onSortDesc={() => handleSortDesc(col)}
                    sortable={!!AcquisitionTableColumnDefs[col].getter}
                    sortDirection={
                      acquisitionConfig.sortColumns.find((sortColumn) => sortColumn.id === col)
                        ?.direction
                    }
                    tooltipKey={AcquisitionTableColumnDefs[col].tooltipKey}
                    className={classNames([
                      styles.acquisitionHeader,
                      !!AcquisitionTableColumnDefs[col].getter && styles.acquisitionHeaderHover,
                      showCompactAcquisitionView && styles.compactHeader,
                    ])}
                  >
                    <SplitOnNewline
                      text={AcquisitionTableColumnDefs[col].header}
                      notation={AcquisitionTableColumnDefs[col].notation}
                    />
                  </InventoryTableHeader>
                ))}
              </TableRow>
            </thead>
            <TableBody>
              {(r: AcquisitionItem) => (
                <TableRow key={window.crypto.randomUUID()}>
                  <Cell
                    className={classnames([
                      styles.cell,
                      styles.checkbox,
                      selected.indexOf(r.sources[0].listingUrl) !== -1 && styles.selectCell,
                    ])}
                  >
                    <Checkbox
                      smallHover={true}
                      checked={selected.indexOf(r.sources[0].listingUrl) !== -1}
                      onClick={() => toggleSelected(r.sources[0].listingUrl)}
                      width={showCompactAcquisitionView ? '18px' : '24px'}
                    />
                  </Cell>
                  {acquisitionConfig.acquisitionTableConfig.map((col) => (
                    <InventoryTableCell
                      key={AcquisitionTableColumnDefs[col].key}
                      col={AcquisitionTableColumnDefs[col].key}
                      width={AcquisitionTableColumnDefs[col].width}
                      textAlign={AcquisitionTableColumnDefs[col].textAlign}
                      className={classnames([
                        styles.cell,
                        selected.indexOf(r.sources[0].listingUrl) !== -1 && styles.selectCell,
                        AcquisitionTableColumnDefs[col].className,
                      ])}
                    >
                      {AcquisitionTableColumnDefs[col].cellRenderer(r)}
                    </InventoryTableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/*Action table (prevent the table to be refreshed once make add vins to the favourite list)*/}
          <Table className={styles.acquisitionActionTable}>
            <thead>
              <TableRow className={styles.acquisitionTableHeader}>
                {AcquisitionTableActionsConfig.map((col) => (
                  <InventoryTableHeader
                    key={AcquisitionTableColumnDefs[col].key}
                    onSortAsc={() => handleSortAsc(col)}
                    onSortDesc={() => handleSortDesc(col)}
                    sortable={!!AcquisitionTableColumnDefs[col].getter}
                    sortDirection={
                      acquisitionConfig.sortColumns.find((sortColumn) => sortColumn.id === col)
                        ?.direction
                    }
                    tooltipKey={AcquisitionTableColumnDefs[col].tooltipKey}
                    className={classNames([
                      styles.acquisitionHeader,
                      styles.acquisitionActionHeader,
                      showCompactAcquisitionView && styles.compactHeader,
                    ])}
                  >
                    <SplitOnNewline
                      text={AcquisitionTableColumnDefs[col].header}
                      notation={AcquisitionTableColumnDefs[col].notation}
                    />
                  </InventoryTableHeader>
                ))}
              </TableRow>
            </thead>
            <TableBody>
              {(r: AcquisitionItem) => (
                <TableRow key={window.crypto.randomUUID()}>
                  {AcquisitionTableActionsConfig.map((col) => (
                    <InventoryTableCell
                      key={AcquisitionTableColumnDefs[col].key}
                      col={AcquisitionTableColumnDefs[col].key}
                      width={AcquisitionTableColumnDefs[col].width}
                      textAlign={AcquisitionTableColumnDefs[col].textAlign}
                      className={classnames([
                        styles.cell,
                        styles.actionCell,
                        selected.indexOf(r.sources[0].listingUrl) !== -1 && styles.selectCell,
                        AcquisitionTableColumnDefs[col].className,
                      ])}
                    >
                      {AcquisitionTableColumnDefs[col].cellRenderer(r)}
                    </InventoryTableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination size={showCompactAcquisitionView ? 'sm' : 'md'} />
      </div>
    </TableProvider>
  );
}

export function PageSizeTracker() {
  const { pageSize } = useTableContext();
  const [, setPageSize] = useAtom(pageSizeAtom);
  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);
  return null;
}
