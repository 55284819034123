import { InventoryItem } from 'types/InventoryItem';

export function getMileageRange(data: InventoryItem[] | undefined): number[] {
  let markdownRange: number[] = [0, 0];

  data?.forEach((item) => {
    if (item.listing.mileage > markdownRange[1]) {
      markdownRange[1] = item.listing.mileage;
    } else if (item.listing.mileage < markdownRange[0]) {
      markdownRange[0] = item.listing.mileage;
    }
  });

  return data ? markdownRange : [0, 10000];
}
