import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const selectedOptionAtom = atomWithStorage<string>(
  'market-segment-selected-option',
  'CONDITION'
);
export const selectedSegmentAtom = atomWithStorage<string>(
  'market-segment-selected-segment',
  'NEW'
);

export const starredSegmentListAtom = atomWithStorage<string[]>('starred-segment-list', []);
export const segmentSearchOptionAtom = atom<boolean>(false);
