import classnames from 'classnames';
import Text from 'Sparky/Text';

import styles from './Legend.module.scss';

interface LegendProps {
  metaData: {
    [key: string]: {
      label: string;
      color: string;
      strokeDasharray?: string;
    };
  };
}
export default function Legend({ metaData }: LegendProps) {
  return (
    <div className={styles.legend}>
      {Object.values(metaData).map((value) => {
        return (
          <div className={styles.legendItem} key={value.label}>
            <LegendIndicator value={value} />
            <Text size='12'>{value.label}</Text>
          </div>
        );
      })}
    </div>
  );
}

export function LegendIndicator({
  value,
}: {
  value: { label: string; color: string; strokeDasharray?: string };
}) {
  const isDashed = value.strokeDasharray;
  if (isDashed) {
    return (
      <>
        {isDashed && (
          <div
            className={classnames([styles.colorDash, styles.dashedLine])}
            style={{ backgroundColor: value.color }}
          />
        )}
        <div
          className={classnames([styles.colorDash, isDashed && styles.dashedLine])}
          style={{ backgroundColor: value.color }}
        />
      </>
    );
  }
  return <div className={styles.colorCircled} style={{ backgroundColor: value.color }} />;
}
