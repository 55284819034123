import {
  InventoryConfig,
  InventoryTableConfigResponse,
} from 'pages/Inventory/InventoryPage/api/useInventoryTableConfig/useInventoryTableConfig';
import {
  DefaultLiveInventoryTableConfig,
  DefaultSoldInventoryTableConfig,
  InvestmentReview,
  LiveROIView,
  LotlinxPublishedView,
  MTDLeadsView,
  MtdVdpsHighToLow,
  PriceChangeReview,
  SalesManagerView,
  SoldROIView,
  VsrBasic,
  VsrNew,
  VsrUsed,
} from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableTemplates';

export const defaultLiveInventoryConfig: InventoryConfig = {
  id: 1,
  isFavourite: true,
  isPreset: true,
  name: 'Live inventory view',
  details: '',
  inventoryTableConfig: DefaultLiveInventoryTableConfig,
  sortColumns: [],
  filtersState: {},
};

export const lotlinxPublishedView: InventoryConfig = {
  id: 2,
  isFavourite: true,
  isPreset: true,
  name: 'Lotlinx published view',
  details: '',
  inventoryTableConfig: LotlinxPublishedView,
  sortColumns: [
    {
      id: 'totalLeadsLifetime',
      direction: 'desc',
    },
  ],
  filtersState: {
    promotion: { options: ['Published'] },
  },
};

export const mtdLeadsView: InventoryConfig = {
  id: 3,
  isFavourite: true,
  isPreset: true,
  name: 'MTD leads view',
  details: '',
  inventoryTableConfig: MTDLeadsView,
  sortColumns: [
    {
      id: 'totalLeads',
      direction: 'desc',
    },
  ],
  filtersState: {},
};

export const priceChangeReview: InventoryConfig = {
  id: 4,
  isFavourite: true,
  isPreset: true,
  name: 'Price change review',
  details: '',
  inventoryTableConfig: PriceChangeReview,
  sortColumns: [
    {
      id: 'daysSinceLastPriceChange',
      direction: 'asc',
    },
  ],
  filtersState: {},
};

export const salesManagerView: InventoryConfig = {
  id: 5,
  isFavourite: true,
  isPreset: true,
  name: 'Sales manager view',
  details: '',
  inventoryTableConfig: SalesManagerView,
  sortColumns: [
    {
      id: 'dol',
      direction: 'desc',
    },
  ],
  filtersState: {},
};

export const mtdVdpsHighToLow: InventoryConfig = {
  id: 6,
  isFavourite: true,
  isPreset: true,
  name: 'MTD VDPs High to Low',
  details: '',
  inventoryTableConfig: MtdVdpsHighToLow,
  sortColumns: [
    {
      id: 'totalVDP',
      direction: 'desc',
    },
  ],
  filtersState: {},
};

export const liveROIConfig: InventoryConfig = {
  id: 7,
  isFavourite: true,
  isPreset: true,
  name: 'Return on Investment',
  details: '',
  inventoryTableConfig: LiveROIView,
  sortColumns: [],
  filtersState: {
    promotion: { options: ['Published'] },
  },
};

export const investmentReviewConfig: InventoryConfig = {
  id: 8,
  isFavourite: true,
  isPreset: true,
  name: 'Investment review',
  details: '',
  inventoryTableConfig: InvestmentReview,
  sortColumns: [{ id: 'priceChangeNumber', direction: 'desc' }],
  filtersState: {},
};

export const vsrUsedConfig: InventoryConfig = {
  id: 9,
  isFavourite: true,
  isPreset: true,
  name: 'VSR Used',
  details: '',
  inventoryTableConfig: VsrUsed,
  sortColumns: [],
  filtersState: {
    condition: { options: ['USED'] },
  },
};

export const vsrNewConfig: InventoryConfig = {
  id: 10,
  isFavourite: true,
  isPreset: true,
  name: 'VSR New',
  details: '',
  inventoryTableConfig: VsrNew,
  sortColumns: [],
  filtersState: {
    condition: { options: ['NEW'] },
  },
};

export const vsrBasicConfig: InventoryConfig = {
  id: 11,
  isFavourite: true,
  isPreset: true,
  name: 'VSR Basic',
  details: '',
  inventoryTableConfig: VsrBasic,
  sortColumns: [{ id: 'dol', direction: 'desc' }],
  filtersState: {},
};

// Presets for the sold inventory
export const defaultSoldInventoryConfig: InventoryConfig = {
  id: 1,
  isFavourite: true,
  isPreset: true,
  name: 'Sold inventory view',
  details: '',
  inventoryTableConfig: DefaultSoldInventoryTableConfig,
  sortColumns: [],
  filtersState: {},
};

export const soldROIConfig: InventoryConfig = {
  id: 2,
  isFavourite: true,
  isPreset: true,
  name: 'Return on Investment',
  details: '',
  inventoryTableConfig: SoldROIView,
  sortColumns: [],
  filtersState: {
    status: { options: ['Sold'] },
    publishedByPeriod: { options: ['Published'] },
    lotlinxVdps: { min: 1 },
  },
};

export const DEFAULT_REDESIGNED_INV_TABLE_CONFIG: InventoryTableConfigResponse = {
  soldInventoryConfigs: [defaultSoldInventoryConfig, soldROIConfig],
  liveInventoryConfigs: [
    defaultLiveInventoryConfig,
    lotlinxPublishedView,
    mtdLeadsView,
    priceChangeReview,
    salesManagerView,
    mtdVdpsHighToLow,
    liveROIConfig,
    investmentReviewConfig,
    vsrUsedConfig,
    vsrNewConfig,
    vsrBasicConfig,
  ],
};
