import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core/dist/types';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Portal from 'Sparky/Portal';
import Repeat from 'Sparky/Repeat';
import Skeleton from 'Sparky/Skeleton';
import { SPACING } from 'Sparky/styles/vars';

import { InventoryTableColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableColumnDef } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';

import styles from './TableColumnsDndPanel.module.scss';
import DraggableColumn from '../DraggableColumn';

export interface TableColumnsDndPanelProps {
  isLoading: boolean;

  onDragStart(event: DragStartEvent): void;

  onDragEnd(event: DragEndEvent): void;

  columns: InventoryTableColumnKey[];
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>;

  toggleFrozenState(isFrozen: boolean, column: InventoryTableColumnKey): void;

  activeColumn: InventoryTableColumnKey | null;
  isFrozen: boolean;
}

/**
 * TableColumnsDndPanel
 */
export default function TableColumnsDndPanel({
  isLoading,
  onDragStart,
  onDragEnd,
  columns,
  inventoryTableColumnDefs,
  toggleFrozenState,
  activeColumn,
  isFrozen,
}: TableColumnsDndPanelProps) {
  if (isLoading) {
    return (
      <div className={styles.tableColumnsDndPanel}>
        <Repeat times={3}>
          <Skeleton height={SPACING.SPACING_48} />
        </Repeat>
      </div>
    );
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={columns} strategy={verticalListSortingStrategy}>
        <div className={styles.tableColumnsDndPanel}>
          {columns.map((col) => (
            <DraggableColumn
              key={col}
              column={col}
              inventoryTableColumnDefs={inventoryTableColumnDefs}
              isFrozen={isFrozen}
              handleFreeze={() => toggleFrozenState(isFrozen, col)}
            />
          ))}
        </div>
        <Portal>
          <DragOverlay>
            {activeColumn && (
              <DraggableColumn
                column={activeColumn}
                inventoryTableColumnDefs={inventoryTableColumnDefs}
                isFrozen={isFrozen}
              />
            )}
          </DragOverlay>
        </Portal>
      </SortableContext>
    </DndContext>
  );
}
