import { animated, useTransition } from '@react-spring/web';
import usePrefersReducedMotion from 'Sparky/hooks/usePrefersReducedMotion';
import Portal from 'Sparky/Portal';

import Toast, { ToastProps } from './Toast';
import './ToastContainer.module.scss';

const AnimatedToast = animated(Toast);

interface ToastContainerProps {
  toasts: ToastProps[];
}

export default function ToastContainer({ toasts }: ToastContainerProps) {
  const prefersReducedMotion = usePrefersReducedMotion();

  const transitions = useTransition(toasts, {
    from: { opacity: 0, y: 20 },
    enter: { opacity: 1, y: 0 },
    config: {
      frequency: 0.21,
    },
    immediate: prefersReducedMotion,
  });

  return (
    <Portal>
      <div className='toast-container'>
        {transitions(
          ({ opacity, y }, toast) =>
            toast && (
              <AnimatedToast
                key={toast.id}
                id={toast.id}
                description={toast.description}
                status={toast.status}
                duration={toast.duration}
                style={{ transform: y.to((value) => `translateY(${value}px`), opacity }}
              />
            )
        )}
      </div>
    </Portal>
  );
}
