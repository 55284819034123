export const InfoIcon = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.41449 3.25258C6.35902 3.19947 6.2936 3.15784 6.22199 3.13008C6.07998 3.07174 5.92068 3.07174 5.77866 3.13008C5.70706 3.15784 5.64164 3.19947 5.58616 3.25258C5.53464 3.30928 5.4932 3.37439 5.46366 3.44508C5.43101 3.51431 5.41502 3.59023 5.41699 3.66675C5.41655 3.74352 5.43127 3.81962 5.4603 3.89069C5.48932 3.96177 5.5321 4.02641 5.58616 4.08091C5.64286 4.13244 5.70797 4.17387 5.77866 4.20341C5.86704 4.23972 5.96297 4.25377 6.05805 4.24431C6.15312 4.23486 6.24442 4.2022 6.32391 4.1492C6.40341 4.09621 6.46867 4.0245 6.51396 3.94037C6.55925 3.85625 6.58319 3.76229 6.58366 3.66675C6.58151 3.5123 6.52109 3.36437 6.41449 3.25258ZM6.00033 0.166748C4.8466 0.166748 3.71879 0.508867 2.7595 1.14984C1.80021 1.79082 1.05254 2.70186 0.611031 3.76776C0.16952 4.83366 0.0540002 6.00655 0.279081 7.13811C0.504161 8.26966 1.05973 9.30906 1.87554 10.1249C2.69134 10.9407 3.73075 11.4962 4.8623 11.7213C5.99386 11.9464 7.16675 11.8309 8.23265 11.3894C9.29855 10.9479 10.2096 10.2002 10.8506 9.24091C11.4915 8.28162 11.8337 7.15381 11.8337 6.00008C11.8337 5.23404 11.6828 4.47549 11.3896 3.76776C11.0965 3.06003 10.6668 2.41697 10.1251 1.87529C9.58344 1.33362 8.94038 0.903937 8.23265 0.610784C7.52492 0.317632 6.76637 0.166748 6.00033 0.166748ZM5.58785 5.5876C5.69725 5.47821 5.84562 5.41675 6.00033 5.41675C6.15504 5.41675 6.30341 5.47821 6.41281 5.5876C6.5222 5.697 6.58366 5.84537 6.58366 6.00008V8.33341C6.58366 8.48812 6.5222 8.6365 6.41281 8.74589C6.30341 8.85529 6.15504 8.91675 6.00033 8.91675C5.84562 8.91675 5.69725 8.85529 5.58785 8.74589C5.47845 8.6365 5.41699 8.48812 5.41699 8.33341V6.00008C5.41699 5.84537 5.47845 5.697 5.58785 5.5876Z'
      fill='#C3C3C3'
    />
  </svg>
);
