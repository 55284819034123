import { IconList } from '@tabler/icons-react';
import classnames from 'classnames';
import Accordion from 'Sparky/Accordion';
import Chip from 'Sparky/Chip';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import { COLOR } from 'Sparky/styles/vars';
import { LotlinxXIcon } from 'Sparky/SVGs/LotlinxXIcon';
import Text from 'Sparky/Text';

import { useVinDetailContext } from 'pages/Inventory/VinDetailSidebar/utils/VinDetailContext';
import { formatCurrency, formatNumber } from 'util/formatters';

import styles from './SummarySection.module.scss';

/**
 * SummarySection
 */
export default function SummarySection() {
  const { item, openSection, onOpenSection } = useVinDetailContext();

  const { dol } = item.listing;
  const { totalVDP, totalConv, lotlinxConv } = item.gaMetrics;
  const { spend } = item.budgetMetrics;
  const { publishedDays } = item.status;

  return (
    <Accordion
      className={classnames([
        styles.summarySection,
        openSection.includes('summary') && styles.openState,
      ])}
      isExpanded={openSection.includes('summary')}
      handleClick={() => onOpenSection('summary')}
      title='Summary'
      titleSize='14'
      hightlightTitleOnOpen
      iconBefore={
        <IconList
          size={18}
          color={openSection.includes('summary') ? COLOR.SPARKY_NAVY_400 : COLOR.SPARKY_GREY_400}
        />
      }
      isOpenByRow
    >
      <Flex flex='none' column alignItems='center' rowGap='16px' padding='0 16px 16px'>
        <Panel className={styles.panel}>
          <div className={styles.topSection}>
            <Text color='secondary' fontWeight={5} size={16}>
              Total VDP views
            </Text>
            <Text color='secondary' fontWeight={5} size={16}>
              Spend
            </Text>
            <Text size={24} fontWeight={5}>
              {formatNumber(totalVDP)}
            </Text>
            <Text size={24} fontWeight={5}>
              {formatCurrency(spend, true)}
            </Text>
            <Flex flex='none' width='100%' alignItems='center' columnGap='4px'>
              <Text size={14} fontWeight={4} color='secondary'>
                Total VDP views/DOL:
              </Text>
              <Text size={14} fontWeight={5}>
                {dol ? formatNumber(totalVDP / dol, 2) : '-'}
              </Text>
            </Flex>
            <Flex flex='none' width='100%' alignItems='center' columnGap='4px'>
              <Text size={14} fontWeight={4} color='secondary'>
                Spend/DOL:
              </Text>
              <Text size={14} fontWeight={5}>
                {dol ? formatCurrency(spend / dol, true) : '-'}
              </Text>
            </Flex>
            <Flex flex='none' width='100%' alignItems='center' columnGap='4px'>
              <Text size={14} fontWeight={4} color='secondary'>
                Total VDP views/day:
              </Text>
              <Text size={14} fontWeight={5}>
                {publishedDays ? formatNumber(totalVDP / publishedDays, 2) : '-'}
              </Text>
            </Flex>
            <Flex flex='none' width='100%' alignItems='center' columnGap='4px'>
              <Text size={14} fontWeight={4} color='secondary'>
                Spend/day:
              </Text>
              <Text size={14} fontWeight={5}>
                {publishedDays ? formatCurrency(spend / publishedDays, true) : '-'}
              </Text>
            </Flex>
          </div>
        </Panel>

        <Panel className={styles.panel}>
          <div className={styles.bottomSection}>
            <Text size={16} fontWeight={5} color='secondary'>
              Total conversions
            </Text>
            <Text size={16} fontWeight={5} color='secondary'>
              Total opportunities (leads)
            </Text>
            <Flex flex='none' alignItems='center' columnGap='8px'>
              <Text size={24} fontWeight={5}>
                {formatNumber(totalConv)}
              </Text>
              <Chip
                leftIcon={<LotlinxXIcon size='13' />}
                color='red'
                tooltip={`${formatNumber(lotlinxConv)} total Lotlinx conversions`}
                tooltipSide='right'
              >
                <Text color='primary'>{formatNumber(lotlinxConv)}</Text>
              </Chip>
            </Flex>
            <Flex flex='none' alignItems='center' columnGap='8px'>
              <Text size={24} fontWeight={5}>
                {formatNumber(item.leadMetrics?.totalLeads)}
              </Text>
              <Chip
                leftIcon={<LotlinxXIcon size='13' />}
                color='red'
                tooltip={`${formatNumber(
                  item.leadMetrics?.lotlinxLeads
                )} total Lotlinx opportunities (leads)`}
                tooltipSide='right'
              >
                <Text color='primary'>{formatNumber(item.leadMetrics?.lotlinxLeads)}</Text>
              </Chip>
            </Flex>
          </div>
        </Panel>
      </Flex>
    </Accordion>
  );
}
