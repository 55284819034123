// import * as Sentry from '@sentry/react';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { datePeriodAtom } from 'store/datePeriodAtom';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import { filterResponseErrors } from 'api/utils/filterResponseErrors';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { InventoryItem } from 'types/InventoryItem';
import { InventorySegments } from 'types/InventorySegments';
import { assertIsDefined } from 'util/assert';

import { inventoryResponseSchema } from './inventorySchema';

export interface Contracts {
  liveInvContracts: { name: string; id: number }[];
  soldInvContracts: { name: string; id: number }[];
}
export interface InventoryResponse {
  inventory: InventoryItem[];
  contracts?: Contracts;
  segments: InventorySegments;
}

interface FetchInventoryProps {
  token?: string;
  dealerId?: number;
  startDate?: string;
  endDate?: string;
  vvo?: boolean;
}

async function fetchInventory({ token, dealerId, startDate, endDate, vvo }: FetchInventoryProps) {
  assertIsDefined(token);
  assertIsDefined(dealerId);

  const res = await get<InventoryResponse>(`${ENDPOINTS.inventory}/${dealerId}`, {
    headers: { token },
    params: {
      startDate,
      endDate,
      vvo,
    },
  });

  if (vvo) {
    // Skip the response validation. The response contains 'viewStats' metrics if vvo is true.
    return res;
  } else {
    // Validate response
    const zodValidation = inventoryResponseSchema.safeParse(res);
    if (!zodValidation.success) {
      Sentry.captureException(zodValidation.error);
      res.inventory = filterResponseErrors(zodValidation, res.inventory);
    }
    return res;
  }
}

export default function useInventory() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const [datePeriod] = useAtom(datePeriodAtom);
  const [startDate, endDate] = datePeriod;
  const { inventoryKey } = useQueryKeys();

  return useQuery<InventoryResponse | undefined, Error>(
    inventoryKey,
    () =>
      fetchInventory({
        token: user?.token,
        dealerId,
        startDate,
        endDate,
      }),
    { staleTime: 60 * 60 * 1000 }
  );
}

export function useInventoryMTD() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { inventoryMTDKey } = useQueryKeys();

  return useQuery<InventoryResponse | undefined, Error>(
    inventoryMTDKey,
    () =>
      fetchInventory({
        token: user?.token,
        dealerId,
        startDate: dayjs().startOf('month').format('YYYYMMDD'),
        endDate: dayjs().endOf('month').format('YYYYMMDD'),
      }),
    { staleTime: 60 * 60 * 1000 }
  );
}

export function useInventoryPrevMonth(enable?: boolean) {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { inventoryKey } = useQueryKeys();

  const res = useQuery<InventoryResponse | undefined, Error>(
    inventoryKey,
    () =>
      fetchInventory({
        token: user?.token,
        dealerId,
        startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYYMMDD'),
        endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYYMMDD'),
      }),
    { enabled: enable, staleTime: 60 * 60 * 1000 }
  );

  return enable ? res : undefined;
}

interface UseVVOInventoryProps {
  startDate: string;
  endDate: string;
}

export function useVVOInventory({ startDate, endDate }: UseVVOInventoryProps) {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { inventoryKey } = useQueryKeys();

  return useQuery<InventoryResponse | undefined, Error>(
    inventoryKey,
    () =>
      fetchInventory({
        token: user?.token,
        dealerId,
        startDate,
        endDate,
        vvo: true,
      }),
    { staleTime: 60 * 60 * 1000 }
  );
}
