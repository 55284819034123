import {
  DropdownFilterConfig,
  DropdownState,
  DynamicMultiSelectFilterConfig,
  DynamicSelectFilterConfig,
  FilterConfig,
  FiltersState,
  FilterType,
  RangeFilterConfig,
  RangeState,
  SearchFilterConfig,
  SearchState,
  SelectRangeFilterConfig,
  SelectState,
  StaticSelectFilterConfig,
  ToggleSelectFilterConfig,
  ToggleState,
} from 'components/Filters/types';

/** Check if an object matches all active filters */
export function checkItemMatch<T>(
  item: T,
  filterConfigs: FilterConfig<T>[],
  filtersState: FiltersState
) {
  return filterConfigs.every((config) => {
    let state = filtersState[config.id];

    if (state) {
      if (config.type === FilterType.SEARCH && 'search' in state) {
        return matchSearch(item, config, state);
      } else if (config.type === FilterType.RANGE && ('min' in state || 'max' in state)) {
        return matchRange(item, config, state);
      } else if (
        config.type === FilterType.STATIC_SELECT_RANGE &&
        ('min' in state || 'max' in state)
      ) {
        return matchRange(item, config, state);
      } else if (config.type === FilterType.STATIC_SELECT && 'options' in state) {
        return matchStaticSelect(item, config, state);
      } else if (config.type === FilterType.DYNAMIC_SELECT && 'options' in state) {
        return matchDynamicSelect(item, config, state);
      } else if (config.type === FilterType.DYNAMIC_MULTI_SELECT && 'options' in state) {
        return matchDynamicMultiSelect(item, config, state);
      } else if (config.type === FilterType.TOGGLE_SELECT && 'options' in state) {
        return matchToggleSelect(item, config, state);
      } else if (config.type === FilterType.DROPDOWN && 'options' in state) {
        return matchDropdown(item, config, state);
      }
    }
    return true;
  });
}

export function matchSearch<T>(item: T, config: SearchFilterConfig<T>, state: SearchState) {
  const searchTerms = state.search?.toLowerCase().split(/\s+/g) ?? [];
  const searchValue = config.getter(item);
  if (searchValue === null || searchValue === undefined) {
    return false;
  }
  return searchTerms.every((term) => searchValue.toString().toLowerCase().includes(term));
}

export function matchRange<T>(
  item: T,
  config: RangeFilterConfig<T> | SelectRangeFilterConfig<T>,
  state: RangeState
) {
  const value = config.getter(item);
  if (typeof value !== 'number') {
    return false;
  } else if (state.min === undefined && state.max === undefined) {
    return true;
  }

  const min = state.min ?? Number.MIN_SAFE_INTEGER;
  const max = state.max ?? Number.MAX_SAFE_INTEGER;
  return value >= min && value <= max;
}

export function matchDynamicSelect<T>(
  item: T,
  config: DynamicSelectFilterConfig<T>,
  state: SelectState
) {
  const value = config.getter(item);
  if (value === null || value === undefined) {
    return false;
  } else if (state.options?.length === 0) {
    return true;
  }

  return state.options?.indexOf(value) !== -1;
}

export function matchDynamicMultiSelect<T>(
  item: T,
  config: DynamicMultiSelectFilterConfig<T>,
  state: SelectState
) {
  const value = config.getter(item);
  if (value === null || value === undefined) {
    return false;
  } else if (state.options?.length === 0) {
    return true;
  }

  return state.options?.some((option) => {
    if (typeof option === 'string') {
      return (value as string[]).indexOf(option) !== -1;
    } else {
      return (value as number[]).indexOf(option) !== -1;
    }
  });
}

export function matchStaticSelect<T>(
  item: T,
  config: StaticSelectFilterConfig<T>,
  state: SelectState
) {
  if (!state.options?.length) {
    return true;
  }

  return state.options?.some((i) => config.options?.[i].matcher(item));
}

export function matchToggleSelect<T>(
  item: T,
  config: ToggleSelectFilterConfig<T>,
  state: ToggleState
) {
  if (!state.options?.length) {
    return true;
  }

  return state.options?.some((i) => config.options?.[i].matcher(item));
}

export function matchDropdown<T>(item: T, config: DropdownFilterConfig<T>, state: DropdownState) {
  const value = config.getter(item);
  if (value === null || value === undefined) {
    return false;
  } else if (state.options?.length === 0) {
    return true;
  }
  if (Array.isArray(value)) {
    return value.reduce((acc, item) => state.options?.indexOf(item) !== -1 || acc, false);
  }
  return state.options?.indexOf(value) !== -1;
}
