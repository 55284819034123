import { useEffect, useRef } from 'react';

import { useAtom } from 'jotai';

import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';

import styles from './InStoreShoppersMap.module.scss';

export interface InStoreShoppersMapProps {
  prop?: any;
}

/**
 * InStoreShoppersMap
 */
export default function InStoreShoppersMap({ prop }: InStoreShoppersMapProps) {
  const formRef = useRef<HTMLInputElement>(null);
  const [user] = useAtom(userAtom);
  const [dealerId] = useSelectedDealerId();

  useEffect(() => {
    formRef?.current?.click();
  }, [formRef, dealerId]);

  function launchQuorom() {
    let token = user?.token;

    // const url = 'https://lp.lotlinx.com/quorom.jsp';
    if (token) {
      // token = token.substring(token.indexOf('TOK:'));
      // token = token.substring(0, token.length - 2);
      // token = token.replace('TOK:', '');

      return (
        <>
          <form method='post' target='receiver' action={'https://lp.lotlinx.com/quorom.jsp'}>
            <input
              type='hidden'
              name='token'
              value={
                process.env.REACT_APP_DEPLOY_ENV === 'production'
                  ? token
                  : 'TOK:4e26b814-c716-4521-9f12-d37f33b7e291'
              }
            />
            <input type='hidden' name='id' value={dealerId} />
            <input type='submit' style={{ display: 'none' }} ref={formRef} />
            {/* <button type='submit'>submit</button> */}
          </form>
          <iframe
            title='receiver'
            name='receiver'
            id='receiver'
            height='auto'
            width='100%'
          ></iframe>
        </>
      );
    }
    return <></>;
  }
  return <div className={styles.instoreshoppersmap}>{launchQuorom()}</div>;
}
