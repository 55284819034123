import { FilterConfig, FilterType } from 'components/Filters';
import { AcquisitionItem } from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';

export const AcquisitionFilterConfigs = <T extends AcquisitionItem>(
  selectedVins?: string[]
): FilterConfig<T>[] => {
  return [
    {
      name: 'Active Filter Pills',
      id: 'activeFilterPills',
      type: FilterType.ACTIVE_PILLS,
      label: 'Filters applied',
    },
    {
      name: 'Search',
      id: 'search',
      type: FilterType.SEARCH,
      getter: (item) =>
        [
          item.vehicle.modelYear?.toString(),
          item.vehicle.make,
          item.vehicle.model,
          item.listing.vin,
        ].join(' '),
      hideHeader: true,
    },
    {
      name: 'Selected',
      id: 'selected',
      type: FilterType.TOGGLE_SELECT,
      hideHeader: true,
      showEmptyOptions: false,
      options: {
        selected: {
          label: 'Show selected only',
          matcher: (i) => (selectedVins ? selectedVins.includes(i.sources[0].listingUrl) : true),
        },
      },
      toolTip: 'No VINs were selected',
    },
    {
      name: 'Year',
      id: 'year',
      type: FilterType.DYNAMIC_SELECT,
      initCollapsed: true,
      showEmptyOptions: false,
      sortOrder: 'desc',
      getter: (item) => item.vehicle.modelYear,
    },
    {
      name: 'Make',
      id: 'make',
      type: FilterType.DYNAMIC_SELECT,
      initCollapsed: true,
      showEmptyOptions: false,
      getter: (item) => item.vehicle.make,
    },
    {
      name: 'Model',
      id: 'model',
      type: FilterType.DYNAMIC_SELECT,
      initCollapsed: true,
      showEmptyOptions: false,
      getter: (item) => item.vehicle.model,
    },
    {
      name: 'Price',
      id: 'price',
      initCollapsed: true,
      type: FilterType.STATIC_SELECT_RANGE,
      getter: (i) => i.priceMetrics?.currentPrice,
      excludeFromPage: 'sold',
      options: {
        '1-10k': {
          label: '$1 – $10,000',
          minValue: 0,
          maxValue: 10000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 0 &&
            i.priceMetrics.currentPrice <= 10000,
        },
        '10k-20k': {
          label: '$10,001 – $20,000',
          minValue: 10001,
          maxValue: 20000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 10000 &&
            i.priceMetrics.currentPrice <= 20000,
        },
        '20k-30k': {
          label: '$20,001 – $30,000',
          minValue: 20001,
          maxValue: 30000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 20000 &&
            i.priceMetrics.currentPrice <= 30000,
        },
        '30k-45k': {
          label: '$30,001 – $45,000',
          minValue: 30001,
          maxValue: 45000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 30000 &&
            i.priceMetrics.currentPrice <= 45000,
        },
        '45k-60k': {
          label: '$45,001 – $60,000',
          minValue: 45001,
          maxValue: 60000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 45000 &&
            i.priceMetrics.currentPrice <= 60000,
        },
        '60k-up': {
          label: '$60,001 +',
          minValue: 60000,
          maxValue: Infinity,
          matcher: (i) => !!i.priceMetrics?.currentPrice && i.priceMetrics?.currentPrice > 60000,
        },
      },
    },
    {
      name: 'Demand',
      id: 'demand',
      initCollapsed: true,
      type: FilterType.STATIC_SELECT,
      options: {
        high: {
          label: 'High',
          matcher: (i) => !!i.marketMetrics?.demand && i.marketMetrics.demand > 80,
        },
        medium: {
          label: 'Medium',
          matcher: (i) =>
            !!i.marketMetrics?.demand &&
            i.marketMetrics.demand > 20 &&
            i.marketMetrics.demand <= 80,
        },
        low: {
          label: 'Low',
          matcher: (i) => !!i.marketMetrics?.demand && i.marketMetrics.demand <= 20,
        },
      },
    },
    {
      name: 'Supply',
      id: 'supply',
      initCollapsed: true,
      type: FilterType.STATIC_SELECT,
      options: {
        high: {
          label: 'High',
          matcher: (i) => !!i.marketMetrics?.supply && i.marketMetrics.supply > 80,
        },
        medium: {
          label: 'Medium',
          matcher: (i) =>
            !!i.marketMetrics?.supply &&
            i.marketMetrics.supply > 20 &&
            i.marketMetrics.supply <= 80,
        },
        low: {
          label: 'Low',
          matcher: (i) => !!i.marketMetrics?.supply && i.marketMetrics.supply <= 20,
        },
      },
    },
    {
      name: 'Source',
      id: 'source',
      initCollapsed: true,
      type: FilterType.DYNAMIC_MULTI_SELECT,
      showEmptyOptions: false,
      getter: (item) => item.sources?.map((source) => source.name),
    },
  ];
};
