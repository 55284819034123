import { IconAlertTriangleFilled, IconThumbUpFilled } from '@tabler/icons-react';
import Flex from 'Sparky/Flex';
import Skeleton from 'Sparky/Skeleton';
import { COLOR } from 'Sparky/styles/vars';
import Text, { TextProps } from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { getPriceScenarioColor } from 'pages/Inventory/VinPath/utils/getPriceScenarioColor';
import { ProbabilityOfSaleKeys } from 'types/InventoryItem';
import { formatCurrency } from 'util/formatters';

import styles from './ProbabilityLabel.module.scss';

export interface ProbabilityLabelProps {
  probability?: ProbabilityOfSaleKeys;
  currentPrice?: number;
  isLoading?: boolean;
}

/**
 * ProbabilityLabel
 */
export default function ProbabilityLabel({
  probability,
  currentPrice,
  isLoading,
}: ProbabilityLabelProps) {
  let icon = null;
  let content;

  switch (probability) {
    case 'highlyUnlikely':
      icon = <IconAlertTriangleFilled size={16} color={COLOR.SPARKY_RED_400} />;
      content = 'HIGHLY UNLIKELY to sell in the next 10 days at this price';
      break;
    case 'unlikely':
      icon = <IconAlertTriangleFilled size={16} color={COLOR.SPARKY_ORANGE_400} />;
      content = 'UNLIKELY to sell in the next 10 days at this price';
      break;
    case 'neutral':
      content = 'NEUTRAL chance of selling in the next 10 days at this price';
      break;
    case 'likely':
      icon = <IconThumbUpFilled size={16} color={COLOR.SPARKY_LIME_400} />;
      content = 'LIKELY to sell in the next 10 days at this price';
      break;
    case 'highlyLikely':
      icon = <IconThumbUpFilled size={16} color={COLOR.SPARKY_GREEN_400} />;
      content = 'HIGHLY LIKELY to sell in the next 10 days at this price';
      break;
    default:
      content = 'Current price';
      break;
  }

  return (
    <Tooltip content={content} disabled={!content} side='top'>
      <div className={styles.probabilityLabel}>
        {isLoading ? (
          <Skeleton width='64px' height='100%' radius='12px' />
        ) : (
          <Flex alignItems='center' columnGap='4px'>
            {icon && icon}
            <Text size={14} color={getPriceScenarioColor(probability) as TextProps<'div'>['color']}>
              {formatCurrency(currentPrice)}
            </Text>
          </Flex>
        )}
      </div>
    </Tooltip>
  );
}
