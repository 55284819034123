import { useMemo, useState } from 'react';

import classnames from 'classnames';
import { produce } from 'immer';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import Breadcrumbs from 'Sparky/Breadcrumbs';
import { showCompactInventoryViewAtom } from 'store/inventoryItemStore';

import ErrorFallback from 'components/ErrorFallback/ErrorFallback';
import { FiltersProvider, FiltersState, useFilters } from 'components/Filters';
import Page from 'components/Page/Page';
import VMBIUpgradeCTA from 'components/VMBIUpgradeCTA/VMBIUpgradeCTA';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useSelectedDealer from 'hooks/useSelectedDealer';
import AcquisitionFilters from 'pages/Inventory/Acquisition/AcquisitionFilters';
import { AcquisitionFilterConfigs } from 'pages/Inventory/Acquisition/AcquisitionFilters/AcquisitionFilterConfigs';
import AcquisitionHeader, {
  AcquisitionOptionType,
  selectOptions,
} from 'pages/Inventory/Acquisition/AcquisitionHeader';
import AcquisitionTable from 'pages/Inventory/Acquisition/AcquisitionTable';
import {
  AcquisitionTableColumnDefs,
  AcquisitionTableColumnWithGetterDefs,
} from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTableColumnDefs';
import {
  AcquisitionTableColumnKey,
  AcquisitionTableWithValueColumnKey,
  DefaultAcquisitionTableConfig,
} from 'pages/Inventory/Acquisition/AcquisitionTable/AcquisitionTableConfigDefs';
import useAcquisition, {
  AcquisitionItem,
} from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';
import SortAcquisition from 'pages/Inventory/Acquisition/SortAcquisition';
import { InventoryTableWithValueColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { SortColumn, sortMultiColumns } from 'pages/Inventory/InventoryPage/InventoryTable';
import { InventoryTableWithValueColumnDef } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';
import { userAtom } from 'store';
import { FeatureFlag } from 'types/FeatureFlags';
import { InventoryItem } from 'types/InventoryItem';

import styles from './Acquisition.module.scss';

export type ShowAcquisitionSideBar = '' | 'sort' | 'filter';

export const showAcquisitionSideBarAtom = atomWithStorage<ShowAcquisitionSideBar>(
  'show-acquisition-sidebar',
  ''
);
export const favouriteAcquisitionAtom = atomWithStorage<string[]>('favourite-acquisition-vins', []);
export const selectOptionAtom = atom<string>(selectOptions[0].value);

export interface AcquisitionConfig {
  acquisitionTableConfig: AcquisitionTableColumnKey[];
  sortColumns: SortColumn[];
  filtersState?: FiltersState;
}

const defaultAcquisitionConfig: AcquisitionConfig = {
  acquisitionTableConfig: DefaultAcquisitionTableConfig,
  sortColumns: [],
  filtersState: {},
};

/**
 * Acquisition
 */
export default function Acquisition() {
  const [showCompactAcquisitionView] = useAtom(showCompactInventoryViewAtom);
  const [user] = useAtom(userAtom);
  const [favouriteAcquisition] = useAtom(favouriteAcquisitionAtom);
  const [selectOption] = useAtom(selectOptionAtom);
  const selectedDealer = useSelectedDealer();
  const enableUpgradePanel = useFeatureFlag(FeatureFlag.ENABLE_VIN_UPGRADE_PANEL);
  const isVMSubEnabled =
    !enableUpgradePanel ||
    selectedDealer?.dealer?.isVinManagerSubEnabled ||
    user?.accountType === 'OWNER';
  const [acquisitionConfig, setAcquisitionConfig] =
    useState<AcquisitionConfig>(defaultAcquisitionConfig);
  const [showAcquisitionSidebar, setShowAcquisitionSidebar] = useAtom(showAcquisitionSideBarAtom);
  const [selected, setSelected] = useState<string[]>([]);

  const { data, isLoading, isError } = useAcquisition();
  const filterConfigs = AcquisitionFilterConfigs<AcquisitionItem>(selected);

  const updateFiltersStates = (filtersState: FiltersState) => {
    setAcquisitionConfig(
      produce(acquisitionConfig, (draft) => {
        draft.filtersState = filtersState;
        return draft;
      })
    );
  };

  const { filteredData, ...filtersProviderProps } = useFilters({
    data:
      selectOption === AcquisitionOptionType.FAVOURITE_VINS
        ? data?.results.filter((item) => favouriteAcquisition.includes(item.sources[0].listingUrl))
        : data?.results,
    filterConfigs: filterConfigs,
    filtersState: acquisitionConfig.filtersState ?? {},
    setFiltersState: updateFiltersStates,
  });

  const visibleSelected = selected.filter((key) =>
    filteredData?.find((i) => i.sources[0].listingUrl === key)
  );

  const sortedTableData = useMemo(() => {
    return sortMultiColumns(
      filteredData as InventoryItem[] | undefined,
      acquisitionConfig.sortColumns,
      AcquisitionTableColumnWithGetterDefs as Record<
        InventoryTableWithValueColumnKey,
        InventoryTableWithValueColumnDef
      >
    );
  }, [acquisitionConfig.sortColumns, filteredData, AcquisitionTableColumnWithGetterDefs]);

  const toggleAllSelected = () => {
    if (visibleSelected.length === filteredData?.length) {
      setSelected([]);
    } else {
      setSelected(filteredData?.map((i) => i.sources[0].listingUrl) ?? []);
    }
  };

  const toggleSelected = (vin: string) => {
    setSelected(
      produce(selected, (draft) => {
        if (draft.indexOf(vin) === -1) {
          return [...draft, vin];
        } else {
          return draft.slice().filter((i) => i !== vin);
        }
      })
    );
  };

  const updateSortColumns = (sortColumns: SortColumn[]) => {
    setAcquisitionConfig(
      produce(acquisitionConfig, (draft) => {
        draft.sortColumns = sortColumns;
        return draft;
      })
    );
  };

  const handleSortAsc = (column: AcquisitionTableColumnKey) => {
    if (AcquisitionTableColumnDefs[column].getter) {
      updateSortColumns(
        produce(acquisitionConfig.sortColumns, (draft) => {
          if (
            draft.findIndex(
              (sortColumn) => sortColumn.id === column && sortColumn.direction === 'asc'
            ) > -1
          ) {
            draft = draft.filter((sortColumn) => sortColumn.id !== column);
          } else {
            draft = draft.filter((sortColumn) => sortColumn.id !== column);
            draft.unshift({ id: column as AcquisitionTableWithValueColumnKey, direction: 'asc' });
          }

          return draft;
        })
      );
    }
  };

  const handleSortDesc = (column: AcquisitionTableColumnKey) => {
    if (AcquisitionTableColumnDefs[column].getter) {
      updateSortColumns(
        produce(acquisitionConfig.sortColumns, (draft) => {
          if (
            draft.findIndex(
              (sortColumn) => sortColumn.id === column && sortColumn.direction === 'desc'
            ) > -1
          ) {
            draft = draft.filter((sortColumn) => sortColumn.id !== column);
          } else {
            draft = draft.filter((sortColumn) => sortColumn.id !== column);
            draft.unshift({ id: column as AcquisitionTableWithValueColumnKey, direction: 'desc' });
          }

          return draft;
        })
      );
    }
  };

  return (
    <div className={styles.acquisition}>
      {isVMSubEnabled && (
        <Breadcrumbs
          pageTitles={[{ title: 'Inventory' }, { title: 'Private Seller Listings' }]}
          className={classnames([styles.pageHeader, showCompactAcquisitionView && styles.compact])}
        />
      )}
      {isError ? (
        <ErrorFallback />
      ) : (
        <FiltersProvider
          filterConfigs={filterConfigs}
          itemCountLabel='VINs'
          showItemCount={true}
          isLoading={isLoading}
          data={data?.results}
          {...filtersProviderProps}
        >
          {isVMSubEnabled && (
            <AcquisitionHeader
              filteredData={filteredData}
              acquisitionConfig={acquisitionConfig}
              showAcquisitionSidebar={showAcquisitionSidebar}
              setShowAcquisitionSidebar={setShowAcquisitionSidebar}
              visibleSelected={visibleSelected}
            />
          )}

          <div
            className={classnames([
              styles.acquisitionContent,
              showCompactAcquisitionView && styles.compact,
            ])}
          >
            {!isVMSubEnabled && <VMBIUpgradeCTA />}

            <AcquisitionTable
              isLoading={isLoading}
              sortedTableData={sortedTableData as AcquisitionItem[]}
              selected={selected}
              acquisitionConfig={acquisitionConfig}
              toggleAllSelected={toggleAllSelected}
              toggleSelected={toggleSelected}
              handleSortAsc={handleSortAsc}
              handleSortDesc={handleSortDesc}
              showAcquisitionSidebar={showAcquisitionSidebar}
              showCompactAcquisitionView={showCompactAcquisitionView}
            />
            <AcquisitionFilters
              isShow={showAcquisitionSidebar === 'filter'}
              handleClose={() => setShowAcquisitionSidebar('')}
            />
            <SortAcquisition
              isLoading={isLoading}
              sortColumns={acquisitionConfig.sortColumns}
              acquisitionTableConfig={acquisitionConfig.acquisitionTableConfig}
              showAcquisitionSidebar={showAcquisitionSidebar}
              setShowAcquisitionSidebar={setShowAcquisitionSidebar}
              updateSortColumns={updateSortColumns}
            />
          </div>
        </FiltersProvider>
      )}
    </div>
  );
}

export function Component() {
  return (
    <Page requireDealer title='Private seller listings'>
      <Acquisition />
    </Page>
  );
}

Component.displayName = 'Private seller listings page';
