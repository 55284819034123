import classnames from 'classnames';
import dayjs from 'dayjs';
import {
  Bar,
  CartesianGrid,
  Cell,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Skeleton from 'Sparky/Skeleton';
import { FONT_SIZE } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import { NumberFormat } from 'types/NumberFormat';
import { formatNumberFormat } from 'util/formatNumberFormat';

import styles from './VerticalBarChart.module.scss';

export interface VerticalBarChartProps {
  dealerValue: number | undefined;
  marketValue: number | undefined;
  metaData: {
    [key: string]: {
      label: string;
      color: string;
    };
  };
  isLoading: boolean;
}

/**
 * VerticalBarChart
 */
export default function VerticalBarChart({
  dealerValue,
  marketValue,
  metaData,
  isLoading,
}: VerticalBarChartProps) {
  if (isLoading) {
    return <Skeleton height='100%' width='100%' />;
  }
  if (dealerValue === undefined || marketValue === undefined) {
    return <></>;
  }
  const data = [
    {
      name: metaData.dealer.label,
      value: dealerValue,
    },
    {
      name: metaData.market.label,
      value: marketValue,
    },
  ];

  return (
    <div className={styles.chartContainer}>
      {/* 
        This container setup with a relative and absolute container is required
        for the resizing behaviour to work with with recharts framework. 
        Known bug with package.    
      */}
      <div className={classnames([styles.relativeContainer])}>
        <div className={styles.absoluteContainer}>
          <ResponsiveContainer className={'chromatic-ignore'}>
            <RechartsBarChart
              style={{ backgroundColor: '#FFFFFF' }}
              data={data}
              layout='vertical'
              margin={{ left: 20, bottom: 5 }}
              maxBarSize={32}
            >
              <CartesianGrid horizontal={false} stroke='#F3F3F3' />
              <Bar type='linear' dataKey={`value`} isAnimationActive={false}>
                {Object.values(metaData).map((value, index) => (
                  <Cell key={`cell-${index}`} fill={value.color} />
                ))}
              </Bar>
              <XAxis
                type='number'
                tick={{ fontSize: FONT_SIZE.FONT_12 }}
                axisLine={{ strokeWidth: 0 }}
                tickLine={false}
              />
              <YAxis
                type='category'
                dataKey='name'
                tick={{ fontSize: FONT_SIZE.FONT_12 }}
                tickLine={{ stroke: 'transparent' }}
                orientation='left'
                domain={['auto', 'auto']}
                axisLine={{ strokeWidth: 0 }}
              />

              <Tooltip
                cursor={false}
                content={(payload) => {
                  if (!payload?.payload || !payload?.payload.length) {
                    return <></>;
                  }
                  return (
                    <div className={styles.tooltipContainer}>
                      <Text
                        className={classnames([styles.title, 'chromatic-ignore'])}
                        color='secondary'
                        size='14'
                      >
                        {dayjs(payload?.payload[0].payload.data).format('MMMM DD, YYYY')}
                      </Text>

                      {payload?.payload.map((tip, i) => {
                        const formattedValue = formatNumberFormat(
                          tip.payload.value as number,
                          NumberFormat.FLOAT
                        );

                        return (
                          <div key={i} className={styles.tooltipRow}>
                            <div className={styles.flexRow}>
                              <div
                                className={styles.colorCircle}
                                style={{
                                  backgroundColor:
                                    tip.payload.name === 'The Market'
                                      ? metaData.market.color
                                      : metaData.dealer.color,
                                }}
                              />
                              <Text size='14' className={styles.metricLabel} color='secondary'>
                                {tip.payload.name}
                              </Text>
                            </div>
                            <Text size='14'>{formattedValue}</Text>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </RechartsBarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
