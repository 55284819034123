// import { useState } from 'react';

import React from 'react';

import { IconHeartbeat } from '@tabler/icons-react';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import InteractiveRow from 'Sparky/InteractiveRow';
import { BlackOutlineStarIcon } from 'Sparky/SVGs/BlackOutlineStarIcon';
import { GoldStarIcon } from 'Sparky/SVGs/GoldStarIcon';
import useToast from 'Sparky/Toast';
import Tooltip from 'Sparky/Tooltip';
import { starredSegmentListAtom } from 'store/selectSegmentAtom';

import useSaveSegmentFavorites from 'components/Segments/api/saveSegmentFavorites/saveSegmentFavorites';
import useMarketSegments from 'components/Segments/api/useMarketSegments/useMarketSegments';

import styles from './SegmentsTile.module.scss';

export interface SegmentsTileProps {
  className?: string;
  segment: string;
  riskScore: number;
  favorited: boolean;
  numberOfVins?: number;

  handleClick?(): void;
}

/**
 * SegmentsTile
 */
export default function SegmentsTile({
  segment,
  riskScore,
  favorited,
  numberOfVins = 0,
  handleClick,
}: SegmentsTileProps) {
  const riskColor: 'red' | 'green' | 'orange' =
    riskScore > 60 ? 'red' : riskScore > 40 ? 'orange' : 'green';
  const [favouritedSegmentsList] = useAtom(starredSegmentListAtom);
  const mutation = useSaveSegmentFavorites();
  const { addToast } = useToast();
  const { refetch } = useMarketSegments();

  function handleIconClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    let index = favouritedSegmentsList.indexOf(segment);
    let newFavorites = [...favouritedSegmentsList];
    if (index > -1) {
      newFavorites.splice(index, 1);
    } else {
      newFavorites.push(segment);
    }
    mutation.mutate(newFavorites, {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        addToast({ status: 'error', description: 'Something went wrong' });
      },
    });
  }

  return (
    <InteractiveRow
      label={segment.replaceAll('|', ', ')}
      iconPrefix={
        <Tooltip
          side='bottom'
          content={`${riskScore > 60 ? 'High' : riskScore > 40 ? 'Medium' : 'Low'} risk`}
        >
          <IconHeartbeat
            className={classnames([
              styles.segmentsTileIcon,
              {
                [styles.segmentsTileIconGreen]: riskColor === 'green',
                [styles.segmentsTileIconRed]: riskColor === 'red',
                [styles.segmentsTileIconOrange]: riskColor === 'orange',
              },
            ])}
            color='white'
            size={16}
          />
        </Tooltip>
      }
      iconSuffix={
        <div
          onClick={(e) => {
            handleIconClick(e);
          }}
        >
          {favorited ? <GoldStarIcon /> : <BlackOutlineStarIcon />}
        </div>
      }
      subText={numberOfVins === 1 ? `${numberOfVins} VIN ` : `${numberOfVins} VINs`}
      handleClick={handleClick}
    />
  );
}
