export type AcquisitionTableColumnKey =
  | 'summary'
  | 'imageCell'
  | 'source'
  | 'actions'
  | AcquisitionTableWithValueColumnKey;

export type AcquisitionTableWithValueColumnKey =
  | 'vin'
  | 'modelYear'
  | 'make'
  | 'model'
  | 'trim'
  | 'bodySegment'
  | 'marketDaysSupply'
  | 'demand'
  | 'supply'
  | 'price'
  // | 'currentPrice'
  | 'localAvgMarketPrice'
  | 'localAvgMarketSoldPrice';

export const DefaultAcquisitionTableConfig: AcquisitionTableColumnKey[] = [
  'imageCell',
  'summary',
  'price',
  'source',
  'marketDaysSupply',
  'demand',
  'supply',
  // 'currentPrice',
  'localAvgMarketPrice',
  'localAvgMarketSoldPrice',
];

export const AcquisitionTableActionsConfig: AcquisitionTableColumnKey[] = ['actions'];
