import { z } from 'zod';

import { LocalMarketData } from 'pages/Settings/LocalMarketSettings/api/useLocalMarket/useLocalMarket';

export const dealerDataSchema = z.object({
  dealerName: z.string(),
  dealerId: z.number(),
  dealerType: z.union([z.literal('franchise'), z.literal('independent')]),
  isNewKeyDealer: z.boolean().optional(),
  isUsedKeyDealer: z.boolean().optional(),
  location: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
});

export const hexDataSchema = z.object({
  inMarketShoppers: z.number(),
  dealerCountNew: z.number(),
  dealerCountUsed: z.number(),
  usedListedUnits: z.number(),
  newListedUnits: z.number(),
  inclusionRadius: z.number(),
  isUsedLocalMarket: z.boolean(),
  isNewLocalMarket: z.boolean(),
  isRequired: z.boolean(),
  selected: z.number().optional(),
  opacity: z.number().optional(),
  borderColor: z.string().optional(),
  borderOpacity: z.number().optional(),
  dealersInHex: z.array(dealerDataSchema).optional(),
  hexStatus: z.string().optional(),
});

export const hexSchema = z.record(hexDataSchema);

export const localMarketDataSchema: z.ZodSchema<LocalMarketData> = z.object({
  mapCenter: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
  hexData: hexSchema,
  dealerList: z.array(dealerDataSchema),
  defaultMarketRadius: z.number(),
});
