import { useState } from 'react';

import { Card } from '@mui/material';
import Select from 'Sparky/Select';
// import { InfoIcon } from 'Sparky/SVGs/InfoIcon';
import Text from 'Sparky/Text';

import LotlinxLogo from './Lotlinx_logo.svg';
import styles from './SoldInventoryWidget.module.scss';

interface SoldInventoryWidgetProps {
  title: string;
  value: number;
  engagementRate: string;
  sellRate: string;
  sidePanel?: boolean;
  views?: { lotlinx: number; other: number };
  engagements?: { lotlinx: number; other: number };
  conversions?: { lotlinx: number; other: number };
}
export default function SoldInventoryWidget({
  title,
  value,
  engagementRate,
  sellRate,
  sidePanel = false,
  views,
  engagements,
  conversions,
}: SoldInventoryWidgetProps) {
  const [sidePanelState, setSidePanelState] = useState('total');

  const totalViews = (views?.lotlinx ?? 0) + (views?.other ?? 0);
  const totalEngagements = (engagements?.lotlinx ?? 0) + (engagements?.other ?? 0);
  const totalConversions = (conversions?.lotlinx ?? 0) + (conversions?.other ?? 0);

  const totalLLViews =
    sidePanelState === 'total' ? views?.lotlinx ?? 0 : ((views?.lotlinx ?? 0) / value).toFixed(0);
  const totalOtherViews =
    sidePanelState === 'total' ? views?.other ?? 0 : ((views?.other ?? 0) / value).toFixed(0);
  const totalLLEngagements =
    sidePanelState === 'total'
      ? engagements?.lotlinx ?? 0
      : ((engagements?.lotlinx ?? 0) / value).toFixed(0);
  const totalOtherEngagements =
    sidePanelState === 'total'
      ? engagements?.other ?? 0
      : ((engagements?.other ?? 0) / value).toFixed(0);
  const totalLLConversions =
    sidePanelState === 'total'
      ? conversions?.lotlinx ?? 0
      : ((conversions?.lotlinx ?? 0) / value).toFixed(0);
  const totalOtherConverions =
    sidePanelState === 'total'
      ? conversions?.other ?? 0
      : ((conversions?.other ?? 0) / value).toFixed(0);
  return (
    <Card className={styles.SoldInventoryWidget}>
      <div className={styles.left}>
        <div>
          <Text size='16' color='secondary'>
            {title}
          </Text>{' '}
          {/* <InfoIcon /> */}
        </div>
        <Text size='32' color='primary'>
          {value}
        </Text>
        <div className={styles.inline}>
          <Text size='14' color='tertiary'>
            Engagement rate
          </Text>
          <Text size='14' color='primary'>
            {engagementRate}
          </Text>
        </div>
        <div className={styles.inline}>
          <Text size='14' color='tertiary'>
            Sell rate
          </Text>
          <Text size='14' color='primary'>
            {sellRate}
          </Text>
        </div>
      </div>
      {sidePanel && (
        <div className={styles.right}>
          <Select
            className={styles.dropdown}
            options={[
              { label: 'Compare source total', value: 'total' },
              { label: 'Compare source avg', value: 'average' },
            ]}
            value={sidePanelState}
            align='start'
            onValueChange={(val) => setSidePanelState(val)}
          />
          <div className={styles.brandRow}>
            <img
              className={styles.img}
              alt='lotlinx logo'
              src={LotlinxLogo}
              width={16}
              height={16}
            />
            <Text fontWeight={7} className={styles.brand}>
              {' '}
              Lotlinx
            </Text>
          </div>
          <Text color='tertiary' fontWeight={7}>
            Other paid
          </Text>
          <div className={styles.row}>
            <div className={styles.rowTitles}>
              <Text>Views</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>Contribution%</Text>
            </div>
            <div>
              <Text>{totalLLViews === 'NaN' ? 0 : totalLLViews}</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>{`${
                totalViews ? Math.round(((views?.lotlinx || 0) / totalViews) * 100) : 0
              }%`}</Text>
            </div>
            <div>
              <Text>{totalOtherViews === 'NaN' ? 0 : totalOtherViews}</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>{`${
                totalViews ? Math.round(((views?.other || 0) / totalViews) * 100) : 0
              }%`}</Text>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitles}>
              <Text>Engagements</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>Contribution%</Text>
            </div>
            <div>
              <Text>{totalLLEngagements === 'NaN' ? 0 : totalLLEngagements}</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>{`${
                totalEngagements
                  ? Math.round(((engagements?.lotlinx || 0) / totalEngagements) * 100)
                  : 0
              }%`}</Text>
            </div>
            <div>
              <Text>{totalOtherEngagements === 'NaN' ? 0 : totalOtherEngagements}</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>{`${
                totalEngagements
                  ? Math.round(((engagements?.other || 0) / totalEngagements) * 100)
                  : 0
              }%`}</Text>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitles}>
              <Text>Conversions</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>Contribution%</Text>
            </div>
            <div>
              <Text>{totalLLConversions === 'NaN' ? 0 : totalLLConversions}</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>{`${
                totalConversions
                  ? Math.round(((conversions?.lotlinx || 0) / totalConversions) * 100)
                  : 0
              }%`}</Text>
            </div>
            <div>
              <Text>{totalOtherConverions === 'NaN' ? 0 : totalOtherConverions}</Text>
              <Text className={styles.dash}> - </Text>
              <Text fontWeight={7}>{`${
                totalConversions
                  ? Math.round(((conversions?.other || 0) / totalConversions) * 100)
                  : 0
              }%`}</Text>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
