import { ReactNode, createContext, useContext } from 'react';

import { FilterConfig, FiltersState, FilterState, PillsState } from 'components/Filters/types';

interface FilterProviderProps extends FilterContextInterface {
  children: ReactNode;
}

export interface FilterContextInterface {
  data?: unknown[];
  filtersState: FiltersState;
  requiredFiltersState?: FiltersState;
  filterConfigs: FilterConfig<unknown>[];
  /** Set state of a single filter */
  getFilterState: (id: string) => FilterState;
  /** Get filter state by config ID */
  setFilterState: (id: string, state: FilterState) => void;
  /** Clear a filter */
  clearFilter: (id: string) => void;
  /** Clear a value from select type filter */
  clearSelectFilter: (id: string, option: string | number) => void;
  /** Clear a value from rang type filter */
  clearRangeFilter: (id: string, isMin: boolean) => void;
  /** Clear all filters */
  clearAll: () => void;
  /** Used for render filter pills*/
  activeFilterPills: PillsState[];
  /** If true, disable controls and show loading states */
  isLoading?: boolean;
  /** Used for Select filter option counts – e.g. "24 VINs" */
  itemCountLabel?: string;
  /** Used for switch filter option counts and label – e.g. "24 VINs" */
  showItemCount?: boolean;
}

const FiltersContext = createContext<FilterContextInterface | undefined>(undefined);

export function FiltersProvider({ children, isLoading, ...otherProps }: FilterProviderProps) {
  return (
    <FiltersContext.Provider value={{ isLoading: isLoading, ...otherProps }}>
      {children}
    </FiltersContext.Provider>
  );
}

export function useFiltersContext() {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useFiltersContext must be used within a FiltersProvider');
  }
  return context;
}
