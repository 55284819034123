export type TextType =
  | 'label-01'
  | 'label-02'
  | 'label-03'
  | 'label-emphasized-01'
  | 'label-emphasized-02'
  | 'label-defined-01'
  | 'label-defined-02'
  | 'label-defined-03'
  | 'body-compact-01'
  | 'body-01'
  | 'body-02'
  | 'body-compact-defined-01'
  | 'body-defined-01'
  | 'body-defined-02'
  | 'heading-01'
  | 'heading-02'
  | 'heading-03'
  | 'button-sm'
  | 'button-md'
  | 'button-lg'
  | 'button-hover-sm'
  | 'button-hover-md'
  | 'button-hover-lg'
  | 'helper-text-01'
  | 'helper-text-02'
  | 'title-01'
  | 'data-callout';

export type TextColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'white'
  | 'error' // Sparky red 400
  | 'navy'
  | 'warning'
  | 'blue'
  | 'green' // Sparky green 500
  | 'grey' // Sparky grey 500
  | 'red' // Sparky red 500
  | 'purple' // Sparky purple 500
  | 'orange' // Sparky orange 500
  | 'yellow' // Sparky yellow 600
  | 'lime' // Sparky lime 600
  | 'disabled';

export const TEXT_PRESET_DEF: Record<
  TextType,
  { size: number | string; lineHeight: string; fontWeight: number }
> = {
  'label-01': { size: '16px', lineHeight: '24px', fontWeight: 500 },
  'label-02': { size: '16px', lineHeight: '24px', fontWeight: 400 },
  'label-03': { size: '14px', lineHeight: '18px', fontWeight: 500 },
  'label-emphasized-01': { size: '16px', lineHeight: '24px', fontWeight: 700 },
  'label-emphasized-02': { size: '14px', lineHeight: '18px', fontWeight: 700 },
  'label-defined-01': { size: '16px', lineHeight: '24px', fontWeight: 500 },
  'label-defined-02': { size: '16px', lineHeight: '24px', fontWeight: 400 },
  'label-defined-03': { size: '14px', lineHeight: '18px', fontWeight: 500 },
  'body-compact-01': { size: '14px', lineHeight: '18px', fontWeight: 400 },
  'body-01': { size: '14px', lineHeight: '20px', fontWeight: 400 },
  'body-02': { size: '16px', lineHeight: '24px', fontWeight: 400 },
  'body-compact-defined-01': { size: '14px', lineHeight: '18px', fontWeight: 400 },
  'body-defined-01': { size: '14px', lineHeight: '20px', fontWeight: 400 },
  'body-defined-02': { size: '16px', lineHeight: '24px', fontWeight: 400 },
  'heading-01': { size: '48px', lineHeight: '56px', fontWeight: 700 },
  'heading-02': { size: '32px', lineHeight: '40px', fontWeight: 500 },
  'heading-03': { size: '20px', lineHeight: '40px', fontWeight: 500 },
  'button-sm': { size: '12px', lineHeight: '16px', fontWeight: 500 },
  'button-md': { size: '16px', lineHeight: '24px', fontWeight: 500 },
  'button-lg': { size: '20px', lineHeight: '24px', fontWeight: 500 },
  'button-hover-sm': { size: '12px', lineHeight: '16px', fontWeight: 700 },
  'button-hover-md': { size: '16px', lineHeight: '24px', fontWeight: 700 },
  'button-hover-lg': { size: '20px', lineHeight: '24px', fontWeight: 700 },
  'helper-text-01': { size: '12px', lineHeight: '16px', fontWeight: 500 },
  'helper-text-02': { size: '12px', lineHeight: '16px', fontWeight: 400 },
  'title-01': { size: '24px', lineHeight: '32px', fontWeight: 700 },
  'data-callout': { size: '32px', lineHeight: '40px', fontWeight: 500 },
};
