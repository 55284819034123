import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import classnames from 'classnames';
import Spacer from 'Sparky/Spacer';
import Text from 'Sparky/Text';

import { DragHandle } from 'pages/Inventory/InventoryPage/components/DragHandle';
import { InventoryTableWithValueColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableWithValueColumnDef } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';

import styles from './SortableColumn.module.scss';

export interface SortableColumnProps {
  column: InventoryTableWithValueColumnKey;
  isAsc: boolean;
  inventoryTableColumnWithGetterDefs: Record<
    InventoryTableWithValueColumnKey,
    InventoryTableWithValueColumnDef
  >;
  dragOverlay?: boolean;

  toggleSort?(): void;
}

/**
 * SortableColumn
 */
export default function SortableColumn({
  column,
  isAsc,
  dragOverlay,
  inventoryTableColumnWithGetterDefs,
  toggleSort,
}: SortableColumnProps) {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: column });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={classnames([
        styles.sortableColumn,
        isDragging && styles.isDragging,
        dragOverlay && styles.dragOverlay,
      ])}
    >
      <DragHandle aria-label={column} ref={setActivatorNodeRef} {...attributes} {...listeners} />
      <Text size='14' className={styles.label}>
        {inventoryTableColumnWithGetterDefs[column].pillLabel}
      </Text>
      <Spacer axis='horizontal' style={{ margin: '0 auto' }} />
      {isAsc ? (
        <div className={styles.sortContainer}>
          <Text size='14'>Asc</Text>
          <IconArrowUp className={styles.ascIcon} onClick={toggleSort} />
        </div>
      ) : (
        <div className={styles.sortContainer}>
          <Text size='14'>Desc</Text>
          <IconArrowDown className={styles.descIcon} onClick={toggleSort} />
        </div>
      )}
    </div>
  );
}
