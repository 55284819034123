import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import produce from 'immer';
import { cloneDeep } from 'lodash';
import Accordion from 'Sparky/Accordion';
import Button from 'Sparky/Button';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';

import ColumnOptions from 'pages/Inventory/InventoryPage/CustomizeSidebar/ColumnOptions';
import {
  InventoryTableColumnKey,
  InventoryTableGroupKey,
  InventoryTableWithValueColumnGroups,
  InventoryTableWithValueColumnKey,
} from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { InventoryTableConfig } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableTemplates';
import { SortColumn } from 'pages/Inventory/InventoryPage/InventoryTable';
import {
  InventoryTableColumnDef,
  InventoryTableWithValueColumnDef,
} from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableColumnDefs';
import SortByColumns from 'pages/Inventory/InventoryPage/SortInventory/SortByColumns';

import styles from './SortInventory.module.scss';

export interface SortInventoryProps {
  isShow: boolean;
  isLoading: boolean;
  inventoryTableColumnWithGetterDefs: Record<
    InventoryTableWithValueColumnKey,
    InventoryTableWithValueColumnDef
  >;
  inventoryTableConfig: InventoryTableConfig;
  inventoryTableColumnDefs: Record<InventoryTableColumnKey, InventoryTableColumnDef>;
  sortColumns: SortColumn[];

  updateSortColumns(sortColumns: SortColumn[]): void;

  toggleColumnSort(col: SortColumn): void;

  handleClose(): void;
}

/**
 * SortInventory
 */
export default function SortInventory({
  isShow,
  isLoading,
  inventoryTableColumnWithGetterDefs,
  inventoryTableConfig,
  inventoryTableColumnDefs,
  sortColumns,
  updateSortColumns,
  toggleColumnSort,
  handleClose,
}: SortInventoryProps) {
  const activeColumns = new Set(sortColumns.map((sortColumn) => sortColumn.id));
  const allColumns = new Set(
    inventoryTableConfig?.frozenColumns
      .concat(inventoryTableConfig?.mainColumns)
      .filter((col) => inventoryTableColumnDefs[col].flag)
  );

  let groupColumns = cloneDeep(InventoryTableWithValueColumnGroups);

  for (let i in InventoryTableWithValueColumnGroups) {
    groupColumns[i as InventoryTableGroupKey] = InventoryTableWithValueColumnGroups[
      i as InventoryTableGroupKey
    ].filter((col) => allColumns.has(col));
  }

  const toggleActiveColumn = (col: InventoryTableWithValueColumnKey, isActive: boolean) => {
    if (isActive) {
      updateSortColumns(
        produce(sortColumns, (draft) => draft.filter((sortColumn) => sortColumn.id !== col))
      );
    } else {
      updateSortColumns(
        produce(sortColumns, (draft) => {
          draft.unshift({ id: col, direction: 'asc' });
        })
      );
    }
  };

  const clearAllColumns = () => {
    updateSortColumns([]);
  };

  const clearGroupColumns = (group: InventoryTableGroupKey) => {
    updateSortColumns(
      produce(sortColumns, (draft) => {
        draft = draft.filter((sortColumn) => !groupColumns[group].includes(sortColumn.id));

        return draft;
      })
    );
  };

  return (
    <div className={classnames([styles.sortInventory, isShow && styles.isShow])}>
      <div className={styles.sortInventoryHeader}>
        <Text fontWeight={7}>Sort Inventory</Text>
        <IconButton aria-label='Close sort inventory' size='md' onClick={handleClose}>
          <IconX size={20} />
        </IconButton>
      </div>
      <div className={styles.sortInventoryBody}>
        {Object.keys(sortColumns).length > 0 && (
          <div className={styles.sortColumns}>
            <Accordion
              isLoading={isLoading}
              key='sort by'
              title='Sort by'
              titleSize='14'
              rightElement={
                <Button size='sm' onClick={() => clearAllColumns()}>
                  Clear all sorts
                </Button>
              }
            >
              <SortByColumns
                isLoading={isLoading}
                sortColumns={sortColumns}
                inventoryTableColumnWithGetterDefs={inventoryTableColumnWithGetterDefs}
                updateSortColumns={updateSortColumns}
                toggleColumnSort={toggleColumnSort}
              />
            </Accordion>
          </div>
        )}
        <div className={styles.columnOptions}>
          <ColumnOptions
            isLoading={isLoading}
            groupColumns={groupColumns}
            inventoryTableColumnDefs={inventoryTableColumnDefs}
            activeColumns={activeColumns}
            toggleActiveColumn={toggleActiveColumn}
            clearGroupColumns={clearGroupColumns}
          />
        </div>
      </div>
    </div>
  );
}
