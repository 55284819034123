import App from 'App';
import { RouteObject } from 'react-router-dom';

import { NavigateKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';

/** react-router routes */
const ROUTES: RouteObject[] = [
  {
    path: '/',
    id: 'VIN Manager',
    element: <App />,
    children: [
      {
        path: 'admin',
        id: 'admin',
        children: [
          { path: 'users', id: 'Users', lazy: () => import('pages/Admin/UsersPage/UserPage') },
          {
            path: 'activity',
            id: 'activity',
            lazy: () => import('pages/Admin/ActivityPage/ActivityPage'),
          },
        ],
      },
      {
        path: 'campaigns',
        id: 'Campaigns',
        children: [
          {
            path: 'overview',
            id: 'Campaign Overview',
            lazy: () => import('pages/Campaign/CampaignDashboard'),
          },
          {
            path: 'conversions',
            id: 'Campaign Conversions',
            lazy: () => import('pages/Campaign/Conversion/ConversionReport'),
          },
          {
            path: 'roi_report',
            id: 'ROI Report',
            lazy: () => import('pages/Campaign/ROIReport'),
          },
        ],
      },
      {
        path: 'campaign_creation',
        id: 'Campaign Creation',
        lazy: () => import('pages/Campaign/CampaignCreation'),
      },
      {
        path: 'settings',
        id: 'Settings',
        children: [
          {
            path: 'local_market',
            id: 'Local Market',
            lazy: () => import('pages/Settings/LocalMarketSettings'),
          },
          {
            path: 'geo_config',
            id: 'Geo Configuration',
            lazy: () => import('pages/Settings/GeoConfigSettings'),
          },
          {
            path: 'showroom',
            id: 'Showroom Configuration',
            lazy: () => import('pages/Settings/Showroom'),
          },
          {
            path: 'photo_ai_config',
            id: 'Photo AI Configuration',
            lazy: () => import('pages/Settings/PhotoAISettings'),
          },
          {
            path: 'lx_video_config',
            id: 'LX Video Configuration',
            lazy: () => import('pages/Settings/LXVideoConfigSettings'),
          },
          {
            path: 'vmx_config',
            id: 'VMX Access',
            lazy: () => import('pages/Settings/VMXAccess'),
          },
          {
            path: 'tooltip_config',
            id: 'Tooltip Configuration',
            lazy: () => import('pages/Settings/TooltipSettings'),
          },
        ],
      },
      {
        path: 'analytics',
        id: 'Analytics',
        children: [
          {
            path: 'before_after',
            id: 'Before / After',
            lazy: () => import('pages/Analytics/BeforeAndAfter'),
          },
          {
            path: 'market_opportunity',
            id: 'Market Opportunity',
            lazy: () => import('pages/Analytics/MarketOpportunity'),
          },
          {
            path: 'market_insights',
            id: 'Market insights',
            lazy: () => import('pages/Analytics/MarketInsights'),
          },
          {
            path: 'risk_analysis',
            id: 'Risk Analysis',
            lazy: () => import('pages/Analytics/RiskAnalysis'),
            children: [
              {
                path: ':vin',
                id: 'Risk Analysis VIN Detail View',
                lazy: () => import('pages/Analytics/RiskAnalysis'),
              },
            ],
          },
          {
            path: 'risk_overview',
            id: 'Risk overview',
            lazy: () => import('pages/Analytics/RiskOverview'),
            children: [
              {
                path: ':vin',
                id: 'Risk Overview VIN Detail View',
                lazy: () => import('pages/Analytics/RiskOverview'),
              },
            ],
          },
        ],
      },
      {
        path: 'inventory/live',
        id: 'Live inventory',
        lazy: () => import('pages/Inventory/LiveInventory'),
        children: [
          {
            path: ':vin',
            id: 'Live inventory VIN Detail View',
            lazy: () => import('pages/Inventory/LiveInventory'),
          },
        ],
      },
      {
        path: 'inventory/sold',
        id: 'Sold inventory',
        lazy: () => import('pages/Inventory/SoldInventory'),
        children: [
          {
            path: ':vin',
            id: 'Sold inventory VIN Detail View',
            lazy: () => import('pages/Inventory/SoldInventory'),
          },
        ],
      },
      {
        path: 'inventory/private_seller_listings',
        id: 'Acquisition',
        lazy: () => import('pages/Inventory/Acquisition'),
        children: [
          {
            path: ':vin',
            id: 'Private seller listings VIN Detail View',
            lazy: () => import('pages/Inventory/Acquisition'),
          },
        ],
      },
      {
        path: 'inventory/vin_path',
        id: 'VIN Path',
        lazy: () => import('pages/Inventory/VinPath'),
        children: [
          {
            path: ':vin',
            id: 'VIN Path VIN Detail View',
            lazy: () => import('pages/Inventory/VinPath'),
          },
        ],
      },
      {
        path: 'vvo',
        id: 'VVO',
        lazy: () => import('pages/Inventory/VVO/VVOPage'),
        children: [
          {
            path: ':vin',
            id: 'VVO Item Detail View',
            lazy: () => import('pages/Inventory/VVO/VVOPage'),
          },
        ],
      },
      // Index route redirects to `/campaigns/overview`
      {
        index: true,
        element: <NavigateKeepParams replace to='/analytics/market_insights' />,
      },
      {
        path: '*',
        lazy: () => import('pages/NotFound/NotFound'),
      },
    ],
  },
];
export default ROUTES;
