import React, { useContext, useState } from 'react';

import { useAtom } from 'jotai';

import { isRepsonseAnimationReadyAtom } from 'pages/LexChat/AnimatedResponse/AnimatedResponse';

export interface AnimatedResponseContextInterface {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  activeOrderArray: string[];
  orderArray: string[];
  incrementIndex: (delay: number) => void;
  tableMarkdown: string[];
}

interface AnimatedResponseProviderProps {
  children: React.ReactNode;
  orderArray: string[];
  tableMarkdown: string[];
}

const AnimatedResponseContext = React.createContext<AnimatedResponseContextInterface | undefined>(
  undefined
);

export const AnimatedResponseProvider = ({
  children,
  orderArray,
  tableMarkdown,
}: AnimatedResponseProviderProps) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [, setIsRepsonseAnimationReady] = useAtom(isRepsonseAnimationReadyAtom);
  let delayRef: NodeJS.Timeout;

  const activeOrderArray = orderArray.slice(0, activeIndex);

  // Delayed increment to move to next element
  const incrementIndex = (delay?: number) => {
    if (activeIndex < orderArray.length) {
      clearTimeout(delayRef);
      delayRef = setTimeout(() => {
        setActiveIndex(activeIndex + 1);
      }, delay ?? 0);
    } else {
      setIsRepsonseAnimationReady(false);
    }
  };

  return (
    <AnimatedResponseContext.Provider
      value={
        {
          activeIndex,
          setActiveIndex,
          activeOrderArray,
          orderArray,
          incrementIndex,
          tableMarkdown,
        } as AnimatedResponseContextInterface
      }
    >
      {children}
    </AnimatedResponseContext.Provider>
  );
};

export function useAnimatedResponseContext() {
  const context = useContext(AnimatedResponseContext);
  return context;
}
