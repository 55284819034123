import React, { useState } from 'react';

import Button from 'Sparky/Button';
import Flex from 'Sparky/Flex';
import Panel from 'Sparky/Panel';
import Table from 'Sparky/Table';
import Cell from 'Sparky/Table/Cell';
import TableBody from 'Sparky/Table/TableBody';
import { TableProvider } from 'Sparky/Table/TableContext';
import TableHeader from 'Sparky/Table/TableHeader';
import TableRow from 'Sparky/Table/TableRow';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import { RecentLead } from 'pages/Inventory/VinDetailSidebar/api/useLeads/useLeads';
import NoDataAvailable from 'pages/Inventory/VinDetailSidebar/components/NoDataAvailable';
import { formatPercent } from 'util/formatters';

import styles from './RecentLeads.module.scss';

export interface RecentLeadsProps {
  data?: RecentLead[];
}

const tableHeaders: { key: string; name: string; type?: string }[] = [
  { key: 'date', name: 'Date' },
  { key: 'source', name: 'Source' },
  { key: 'priceToMarket', name: 'Price to\n Market', type: 'percent' },
  { key: 'city', name: 'City' },
  { key: 'zip', name: 'Zip' },
  { key: 'state', name: 'State' },
];

const defaultRows = 4;
/**
 * RecentLeads
 */
export default function RecentLeads({ data }: RecentLeadsProps) {
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <Panel className={styles.panel}>
      <TableProvider data={showMore ? data : data?.slice(0, defaultRows)}>
        <Flex column rowGap='12px' width='100%'>
          <Text size={16} fontWeight={5} color='secondary'>
            Recent leads
          </Text>
          {data && data?.length > 0 ? (
            <Flex column rowGap='8px' width='100%'>
              <Table className={styles.table}>
                <thead>
                  <TableRow className={styles.headerRow}>
                    {tableHeaders.map((tableHeader) => (
                      <TableHeader
                        key={tableHeader.name}
                        textAlign='left'
                        className={styles.tableHeader}
                      >
                        <Text size='14' fontWeight={7} color='tertiary'>
                          {tableHeader.name}
                        </Text>
                      </TableHeader>
                    ))}
                  </TableRow>
                </thead>
                <TableBody className={styles.tableBody}>
                  {(r: RecentLead) => (
                    <TableRow key={window.crypto.randomUUID()} className={styles.bodyRow}>
                      {tableHeaders.map((tableHeader, index) => {
                        return (
                          <LeadsCell
                            key={index}
                            tooltipContent={`${tableHeader.name} not available`}
                            tooltipDisabled={
                              tableHeader?.type === 'percent'
                                ? formatPercent(Number(r[tableHeader.key])) !== '-'
                                : !!r[tableHeader.key]
                            }
                          >
                            {tableHeader?.type === 'percent'
                              ? formatPercent(Number(r[tableHeader.key]))
                              : r[tableHeader.key]
                              ? r[tableHeader.key]
                              : '-'}
                          </LeadsCell>
                        );
                      })}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Flex width='100%' alignItems='center' justifyContent='space-between'>
                <Text size={14} color='tertiary'>
                  1 - {showMore ? data.length : data?.slice(0, defaultRows).length} of {data.length}
                </Text>
                {data.length > defaultRows && (
                  <Button onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'View less' : 'View more'}
                  </Button>
                )}
              </Flex>
            </Flex>
          ) : (
            <NoDataAvailable sectionName='recent leads' />
          )}
        </Flex>
      </TableProvider>
    </Panel>
  );
}

interface LeadsCellProps {
  tooltipContent?: string;
  tooltipDisabled?: boolean;
  children: React.ReactNode;
}

function LeadsCell({ tooltipContent, tooltipDisabled, children }: LeadsCellProps) {
  return (
    <Cell className={styles.tableCell}>
      <Tooltip disabled={tooltipDisabled} content={tooltipContent} side='top'>
        <div>
          <Text size='14'>{children}</Text>
        </div>
      </Tooltip>
    </Cell>
  );
}
