import { useRef, useState } from 'react';

import * as Slider from '@radix-ui/react-slider';
import { NumberFormatValues } from 'react-number-format';
import Flex from 'Sparky/Flex';
import NumberInputField from 'Sparky/NumberInputField';
import Text from 'Sparky/Text';

import styles from './RangeSlider.module.scss';

export interface RangeSliderProps {
  rangeValue: number[];
  setRangeValue: (value: number[]) => void;
  width?: string;
  defaultValue?: number[];
  min: number;
  max: number;
  step?: number;
}

type RangeFilterError = '' | 'min' | 'max';

/**
 * RangeSlider
 */
export default function RangeSlider({
  rangeValue,
  setRangeValue,
  width = '150px',
  min,
  max,
  step = 10,
}: RangeSliderProps) {
  const [values, setValues] = useState<number[]>([min, max]);
  const [error, setError] = useState<RangeFilterError>('');
  const trackingRef = useRef<'min' | 'max' | null>(null);

  const onMinChange = (value: NumberFormatValues) => {
    if (error === 'min') {
      setError('');
    }
    if ((value.floatValue ?? 0) >= values[1]) {
      setError('min');
    }
    if (trackingRef.current === null) {
      setRangeValue([value.floatValue ?? 0, rangeValue[1]]);
      setValues([value.floatValue ?? 0, values[1]]);
    }
  };

  const onMaxChange = (value: NumberFormatValues) => {
    if (error === 'max') {
      setError('');
    }
    if ((value.floatValue ?? 0) < values[0]) {
      setError('max');
    }
    if (trackingRef.current === null) {
      setRangeValue([rangeValue[0], value.floatValue ?? 0]);
      setValues([values[0], value.floatValue ?? 0]);
    }
  };

  return (
    <Flex column rowGap='12px'>
      <Flex>
        <Flex width={width}>
          <NumberInputField
            value={values[0]}
            onValueChange={onMinChange}
            id='min'
            label='Min.'
            max={max}
            aria-label='min input field'
            thousandSeparator=','
            leftElement={'$'}
            showNumberCaret
            message={error === 'min' ? `Minimum should be less than max` : ''}
            variants={error === 'min' ? 'error' : 'default'}
          />
        </Flex>
        <Flex margin='44px 8px 0'>
          <Text color='tertiary'>-</Text>
        </Flex>
        <Flex width='150px'>
          <NumberInputField
            value={values[1]}
            onValueChange={onMaxChange}
            id='max'
            label='Max.'
            max={max}
            aria-label='max input field'
            thousandSeparator=','
            leftElement={'$'}
            showNumberCaret
            message={error === 'max' ? `Max should be more than min` : ''}
            variants={error === 'max' ? 'error' : 'default'}
          />
        </Flex>
      </Flex>
      <form>
        <Slider.Root
          className={styles.sliderRoot}
          min={min}
          max={max}
          step={step}
          value={values}
          onValueChange={(newValues) => {
            if (trackingRef.current !== null) {
              if (trackingRef.current === 'min') {
                if (newValues[0] >= values[1]) {
                  return;
                }
                setValues([newValues[0], values[1]]);
              } else {
                if (newValues[1] <= values[0]) {
                  return;
                }
                setValues([values[0], newValues[1]]);
              }
            } else {
              setValues(newValues);
            }
          }}
          onValueCommit={(newValues) => {
            setRangeValue(newValues);
          }}
        >
          <Slider.Track className={styles.sliderTrack}>
            <Slider.Range className={styles.sliderRange} />
          </Slider.Track>
          <Slider.Thumb
            className={styles.sliderThumb}
            aria-label='min'
            onPointerDown={() => (trackingRef.current = 'min')}
            onPointerUp={() => (trackingRef.current = null)}
          />
          <Slider.Thumb
            className={styles.sliderThumb}
            aria-label='max'
            onPointerDown={() => (trackingRef.current = 'max')}
            onPointerUp={() => (trackingRef.current = null)}
          />
        </Slider.Root>
      </form>
    </Flex>
  );
}
