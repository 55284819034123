import { IconX } from '@tabler/icons-react';
import Button from 'Sparky/Button';
import Chip, { ChipProps } from 'Sparky/Chip';

import { PillsState, SelectState, useFiltersContext } from 'components/Filters';

import styles from './ActiveFilterPills.module.scss';

export interface ActiveFilterPillsProps {
  chipProps?: Omit<ChipProps, 'children'>;
  showClearAll?: boolean;
}

/** Shows active filter pills, and allows the user to clear them easily */
export default function ActiveFilterPills({ chipProps, showClearAll }: ActiveFilterPillsProps) {
  const { activeFilterPills, getFilterState, setFilterState, clearFilter, clearAll } =
    useFiltersContext();

  /** Remove filter pill */
  const handlePills = (pill: PillsState) => {
    if (pill.type === 'range') {
      clearFilter(pill.id);
    } else if (pill.type === 'search') {
      clearFilter(pill.id);
    } else {
      const state = getFilterState(pill.id) as SelectState;
      setFilterState(pill.id, { options: state.options?.filter((i) => i !== pill.value) });
    }
  };

  return (
    <div className={styles.filterPills}>
      {activeFilterPills.map((pill) => (
        <Chip
          key={pill.key}
          rightIcon={<IconX size={12} />}
          handleRightIconClick={() => handlePills(pill)}
          {...chipProps}
        >
          {pill.label}
        </Chip>
      ))}
      {showClearAll && activeFilterPills.length > 0 && (
        <Button size='sm' onClick={() => clearAll()}>
          Clear all filters
        </Button>
      )}
    </div>
  );
}
