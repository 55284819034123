import { IconBarrierBlock } from '@tabler/icons-react';
import Flex from 'Sparky/Flex';
import Text from 'Sparky/Text';

import styles from './OutageForm.module.scss';
import LotlinxLogo from '../SignInForm/lotlinx_logo.png';

export interface OutageFormProps {
  recoverTime?: string;
}

/**
 * OutageForm
 */
export default function OutageForm({ recoverTime }: OutageFormProps) {
  return (
    <div className={styles.outageFormWrapper}>
      <div className={styles.logoContainer}>
        <img src={LotlinxLogo} alt='' className={styles.logo} />
        <Text fontWeight={7} size='20'>
          Welcome back to
        </Text>
        <Text fontWeight={7} size='32'>
          VIN Manager
        </Text>
      </div>
      <div className={styles.outageContent}>
        <Flex column rowGap='16px' alignItems='center'>
          <IconBarrierBlock color='white' width={58} height={59} />
          <Text color='white' alignment='center' size={20} fontWeight={7}>
            We are currently performing routine maintenance
          </Text>
        </Flex>
        <Flex column alignItems='center'>
          <Text color='white' alignment='center' size={16} fontWeight={5} lineHeight='25px'>
            Scheduled recovery time:
          </Text>
          <Text color='white' alignment='center' size={16} fontWeight={4} lineHeight='25px'>
            {recoverTime}
          </Text>
        </Flex>
        <Text color='white' alignment='center' size={16} fontWeight={6}>
          Thank you for your patience!
        </Text>
      </div>
    </div>
  );
}
