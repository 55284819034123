import { InventoryItem } from 'types/InventoryItem';

export function getMarkdownRange(data: InventoryItem[] | undefined): number[] {
  let markdownRange: number[] = [0, 0];

  data?.forEach((item) => {
    if (item.vinPath?.pricingOptions && item.vinPath?.pricingOptions[0].markdown !== undefined) {
      if (item.vinPath.pricingOptions[0].markdown > markdownRange[1]) {
        markdownRange[1] = item.vinPath.pricingOptions[0].markdown;
      } else if (item.vinPath.pricingOptions[0].markdown < markdownRange[0]) {
        markdownRange[0] = item.vinPath.pricingOptions[0].markdown;
      }
    }
  });

  return data ? markdownRange : [0, 10000];
}
