import { IconDots, IconExternalLink, IconStar, IconStarFilled } from '@tabler/icons-react';
import produce from 'immer';
import { useAtom } from 'jotai';
import Flex from 'Sparky/Flex';
import IconButton from 'Sparky/IconButton';
import Popover from 'Sparky/Popover';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';

import { favouriteAcquisitionAtom } from 'pages/Inventory/Acquisition';
import { sourceColorsAtom, SourceIcon } from 'pages/Inventory/Acquisition/AcquisitionTable/Sources';
import { SourceItem } from 'pages/Inventory/Acquisition/api/useAcquisition/useAcquisition';

import styles from './Actions.module.scss';

export interface ActionsProps {
  sources?: SourceItem[];
}

/**
 * Actions
 */
export default function Actions({ sources }: ActionsProps) {
  const [sourceColors] = useAtom(sourceColorsAtom);
  const [favouriteAcquisition, setFavouriteAcquisition] = useAtom(favouriteAcquisitionAtom);

  if (!sources) {
    return null;
  }

  const isFavourite = favouriteAcquisition.includes(sources[0].listingUrl);

  const updateFavouriteAcquisition = (key: string) =>
    setFavouriteAcquisition(
      produce(favouriteAcquisition, (draft) => {
        if (isFavourite) {
          draft = draft.filter((i) => i !== key);
        } else {
          draft.push(key);
        }
        return draft;
      })
    );

  return (
    <Flex columnGap='8px' alignItems='center' justifyContent='center' margin='auto'>
      <IconButton
        aria-label='favourite'
        onClick={() => updateFavouriteAcquisition(sources[0].listingUrl)}
      >
        {isFavourite ? <IconStarFilled color={COLOR.SPARKY_YELLOW_400} /> : <IconStar />}
      </IconButton>
      <Popover
        showCarrat={false}
        side='left'
        horizontalOffset={20}
        verticalOffset={-20}
        showCloseButton={false}
        popupContent={
          <Flex column width='100%' columnGap='8px'>
            {sources?.map((source, index) => (
              <a
                href={source.listingUrl}
                target='_blank'
                key={index}
                rel='noreferrer'
                className={styles.action}
              >
                <SourceIcon
                  source={source}
                  size={22}
                  color={
                    sourceColors.find((sourceColor) => sourceColor.sourceName === source.name)
                      ?.color
                  }
                />
                <Text>{source.name}</Text>
                <IconButton aria-label={'open a external link'} className={styles.externalLink}>
                  <IconExternalLink size={16} />
                </IconButton>
              </a>
            ))}
          </Flex>
        }
        contentClassName={styles.poperoverClassName}
      >
        <IconButton aria-label='source URL'>
          <IconDots size={16} />
        </IconButton>
      </Popover>
    </Flex>
  );
}
