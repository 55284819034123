import { useEffect } from 'react';

import { IconCaretDownFilled } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import Button from 'Sparky/Button';
import { SegmentsPanelIcon } from 'Sparky/SVGs/SegmentsPanelIcon';
import Text from 'Sparky/Text';
import { selectedOptionAtom, selectedSegmentAtom } from 'store/selectSegmentAtom';

import useMarketSegments, {
  MarketSegmentsResponse,
} from 'components/Segments/api/useMarketSegments/useMarketSegments';
import segmentDeserializer from 'components/Segments/segmentDeserializer';

import styles from './SegmentsButton.module.scss';

export default function SegmentsButton({ numberOfCharacters }: { numberOfCharacters?: number }) {
  const [selectedSegment, setSelectedSegment] = useAtom(selectedSegmentAtom);
  const [, setSelectedOption] = useAtom(selectedOptionAtom);
  const { data } = useMarketSegments();
  const { flattenedSegments } = segmentDeserializer(data as MarketSegmentsResponse);
  const segmentName = selectedSegment.replaceAll('|', ', ');

  useEffect(() => {
    if (
      selectedSegment !== 'NEW' &&
      selectedSegment !== 'USED' &&
      flattenedSegments.length > 0 &&
      !flattenedSegments.find((segment) => segment.segment === selectedSegment)
    ) {
      setSelectedSegment('NEW');
      setSelectedOption('CONDITION');
    }
  }, [flattenedSegments]);

  return (
    <div className={styles.segmentsButtonWrapper}>
      <Button
        variant='outline'
        leftIcon={<SegmentsPanelIcon />}
        rightIcon={<IconCaretDownFilled size={16} />}
      >
        <div className={styles.segmentsButton}>
          <Text size={16} lineHeight='24px'>
            Segment:
          </Text>
          <Text size={16} lineHeight='24px' fontWeight={7}>
            {numberOfCharacters && segmentName.length > numberOfCharacters
              ? `${segmentName.slice(0, numberOfCharacters)}...`
              : segmentName}
          </Text>
        </div>
      </Button>
    </div>
  );
}
