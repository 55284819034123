import { FilterConfig, FilterType, INVENTORY_FILTERS } from 'components/Filters';
import { VVOInventoryItem } from 'pages/Inventory/VVO/VVOChart/types';
import { InventoryItem } from 'types/InventoryItem';
import { toTitleCase } from 'util/formatters';

export const InventoryFilterConfigs = <T extends InventoryItem | VVOInventoryItem>(
  selectedVins?: string[]
): FilterConfig<T>[] => {
  return [
    {
      name: 'Active Filter Pills',
      id: 'activeFilterPills',
      type: FilterType.ACTIVE_PILLS,
      label: 'Filters applied',
    },
    INVENTORY_FILTERS.SEARCH,
    {
      name: 'Selected',
      id: 'selected',
      type: FilterType.TOGGLE_SELECT,
      hideHeader: true,
      showEmptyOptions: true,
      options: {
        selected: {
          label: 'Show selected only',
          matcher: (i) => (selectedVins ? selectedVins.includes(i.listing.vin) : true),
        },
      },
      toolTip: 'No VINs were selected',
    },
    {
      name: 'Promotion',
      id: 'publishedByPeriod',
      type: FilterType.DYNAMIC_SELECT,
      excludeFromPage: 'live',
      getter: (item) => (item.status.publishedDays > 0 ? 'Published' : 'Not Published'),
    },
    {
      name: 'Vehicle Status',
      id: 'status',
      type: FilterType.DYNAMIC_SELECT,
      showEmptyOptions: true,
      getter: (item) => toTitleCase(item.status.status),
    },
    {
      name: 'Risk Score',
      id: 'riskScore',
      type: FilterType.STATIC_SELECT,
      excludeFromPage: 'sold',
      options: {
        'Very high': {
          label: 'Very high',
          matcher: (i) =>
            i.scores?.riskScore !== undefined &&
            i.scores.riskScore > 80 &&
            i.scores.riskScore <= 100,
        },
        High: {
          label: 'High',
          matcher: (i) =>
            i.scores?.riskScore !== undefined &&
            i.scores.riskScore > 60 &&
            i.scores.riskScore <= 80,
        },
        Moderate: {
          label: 'Moderate',
          matcher: (i) =>
            i.scores?.riskScore !== undefined &&
            i.scores.riskScore > 40 &&
            i.scores.riskScore <= 60,
        },
        Low: {
          label: 'Low',
          matcher: (i) =>
            i.scores?.riskScore !== undefined &&
            i.scores.riskScore > 20 &&
            i.scores.riskScore <= 40,
        },
        'Very low': {
          label: 'Very low',
          matcher: (i) =>
            i.scores?.riskScore !== undefined &&
            i.scores.riskScore >= 0 &&
            i.scores.riskScore <= 20,
        },
      },
    },
    {
      name: 'Campaign',
      id: 'liveInvCampaign',
      defaultOptionName: 'All campaigns',
      ariaLabel: 'Select Campaign',
      hideClear: true,
      type: FilterType.DROPDOWN,
      excludeFromPage: 'sold',
      // TODO: Add location of campaign for VIN
      getter: (item) => item.contracts.map((item) => item.toString()),
    },
    {
      name: 'Campaign',
      id: 'soldInvCampaign',
      defaultOptionName: 'All campaigns',
      ariaLabel: 'Select Campaign',
      hideClear: true,
      type: FilterType.DROPDOWN,
      excludeFromPage: 'live',
      // TODO: Add location of campaign for VIN
      getter: (item) => item.contracts.map((item) => item.toString()),
    },
    {
      name: 'Lot Status',
      id: 'lotStatus',
      type: FilterType.DYNAMIC_SELECT,
      showEmptyOptions: true,
      excludeFromPage: 'sold',
      getter: (item) => toTitleCase(item.status.lotStatus),
    },
    {
      name: 'Promotion',
      id: 'promotion',
      type: FilterType.DYNAMIC_SELECT,
      showEmptyOptions: true,
      excludeFromPage: 'sold',
      getter: (item) => (item.status.isBeingPublished ? 'Published' : 'Not Published'),
    },
    INVENTORY_FILTERS.CONDITION,
    { ...INVENTORY_FILTERS.YEAR, initCollapsed: true },
    { ...INVENTORY_FILTERS.MAKE, initCollapsed: true },
    { ...INVENTORY_FILTERS.MODEL, initCollapsed: true },
    {
      name: 'Body Style',
      id: 'bodysegment',
      type: FilterType.DYNAMIC_SELECT,
      getter: (item) => (item.vehicle.bodySegment ?? '').toUpperCase(),
      initCollapsed: true,
    },
    {
      name: 'DOL',
      id: 'dol',
      initCollapsed: true,
      type: FilterType.STATIC_SELECT_RANGE,
      getter: (i) => i.listing.dol,
      options: {
        '1-15': {
          label: '1-15',
          minValue: 1,
          maxValue: 15,
          matcher: (i) => !!i.listing.dol && i.listing.dol > 0 && i.listing.dol <= 15,
        },
        '16-30': {
          label: '16-30',
          minValue: 16,
          maxValue: 30,
          matcher: (i) => !!i.listing.dol && i.listing.dol > 15 && i.listing.dol <= 30,
        },
        '31-45': {
          label: '31-45',
          minValue: 31,
          maxValue: 45,
          matcher: (i) => !!i.listing.dol && i.listing.dol > 30 && i.listing.dol <= 45,
        },
        '46-60': {
          label: '46-60',
          minValue: 46,
          maxValue: 60,
          matcher: (i) => !!i.listing.dol && i.listing.dol > 45 && i.listing.dol <= 60,
        },
        '61-80': {
          label: '61-80',
          minValue: 61,
          maxValue: 80,
          matcher: (i) => !!i.listing.dol && i.listing.dol > 60 && i.listing.dol <= 80,
        },
        '81-100': {
          label: '81-100',
          minValue: 81,
          maxValue: 100,
          matcher: (i) => !!i.listing.dol && i.listing.dol > 80 && i.listing.dol <= 100,
        },
        '101+': {
          label: '101+',
          minValue: 101,
          maxValue: Infinity,
          matcher: (i) => !!i.listing.dol && i.listing.dol >= 101,
        },
      },
    },
    {
      name: 'Exterior Color',
      id: 'exteriorColor',
      type: FilterType.DYNAMIC_SELECT,
      getter: (item) => item.listing.exteriorColor,
      initCollapsed: true,
    },
    {
      name: 'Price',
      id: 'price',
      initCollapsed: true,
      type: FilterType.STATIC_SELECT_RANGE,
      getter: (i) => i.priceMetrics?.currentPrice,
      excludeFromPage: 'sold',
      options: {
        '1-10k': {
          label: '$1 – $10,000',
          minValue: 0,
          maxValue: 10000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 0 &&
            i.priceMetrics.currentPrice <= 10000,
        },
        '10k-20k': {
          label: '$10,001 – $20,000',
          minValue: 10001,
          maxValue: 20000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 10000 &&
            i.priceMetrics.currentPrice <= 20000,
        },
        '20k-30k': {
          label: '$20,001 – $30,000',
          minValue: 20001,
          maxValue: 30000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 20000 &&
            i.priceMetrics.currentPrice <= 30000,
        },
        '30k-45k': {
          label: '$30,001 – $45,000',
          minValue: 30001,
          maxValue: 45000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 30000 &&
            i.priceMetrics.currentPrice <= 45000,
        },
        '45k-60k': {
          label: '$45,001 – $60,000',
          minValue: 45001,
          maxValue: 60000,
          matcher: (i) =>
            !!i.priceMetrics?.currentPrice &&
            i.priceMetrics.currentPrice > 45000 &&
            i.priceMetrics.currentPrice <= 60000,
        },
        '60k-up': {
          label: '$60,001 +',
          minValue: 60000,
          maxValue: Infinity,
          matcher: (i) => !!i.priceMetrics?.currentPrice && i.priceMetrics?.currentPrice > 60000,
        },
      },
    },
    {
      name: 'Mileage',
      id: 'mileage',
      initCollapsed: true,
      type: FilterType.RANGE,
      getter: (item) => item.listing?.mileage,
    },
    {
      name: 'Days Since Last Price Change',
      id: 'days-since-last-price-change',
      initCollapsed: true,
      type: FilterType.RANGE,
      excludeFromPage: 'sold',
      getter: (item) => item.priceMetrics?.daysSinceLastPriceChange,
    },
    {
      name: 'Current Price to Market',
      id: 'current-price-to-market',
      initCollapsed: true,
      type: FilterType.STATIC_SELECT_RANGE,
      getter: (i) => (i.priceMetrics?.currentPriceToMarketPct || 0) * 100,
      excludeFromPage: 'sold',
      options: {
        low: {
          label: 'Below 98%',
          minValue: 0,
          maxValue: 97,
          matcher: (i) =>
            !!i.priceMetrics?.currentPriceToMarketPct &&
            i.priceMetrics.currentPriceToMarketPct < 0.98,
        },
        med: {
          label: '98%–102%',
          minValue: 98,
          maxValue: 102,
          matcher: (i) =>
            !!i.priceMetrics?.currentPriceToMarketPct &&
            i.priceMetrics.currentPriceToMarketPct >= 0.98 &&
            i.priceMetrics.currentPriceToMarketPct <= 1.02,
        },
        high: {
          label: 'Over 102%',
          minValue: 102,
          maxValue: Infinity,
          matcher: (i) =>
            !!i.priceMetrics?.currentPriceToMarketPct &&
            i.priceMetrics.currentPriceToMarketPct > 1.02,
        },
      },
    },
    {
      name: 'Photo Count',
      id: 'photos',
      type: FilterType.RANGE,
      getter: (item) => item.listing.imageCount,
      initCollapsed: true,
    },
    {
      name: 'Lotlinx VDPs',
      id: 'lotlinxVdps',
      initCollapsed: true,
      type: FilterType.RANGE,
      excludeFromPage: 'live',
      getter: (item) => item.gaMetrics.lotlinxVDP,
    },
  ];
};
