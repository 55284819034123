import { useEffect } from 'react';

import { IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import IconButton from 'Sparky/IconButton';
import Text from 'Sparky/Text';
import { datePeriodAtom } from 'store/datePeriodAtom';

import DatePeriodSelect, { getPeriodSelectOptions, keyToPeriod } from 'components/DatePeriodSelect';
import { Filter, useFiltersContext } from 'components/Filters';
import { InventoryPageKey } from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';

import styles from './InventoryFilters.module.scss';

export interface InventoryFiltersProps {
  inventoryPageKey: InventoryPageKey;
  pageKey?: 'inventory' | 'vvo';
  isShow: boolean;
  handleClose(): void;
  boxShadow?: 'left' | 'right';
  hideCloseButton?: boolean;
  title: string;
}

/**
 * InventoryFilters
 */
export default function InventoryFilters({
  inventoryPageKey,
  isShow,
  pageKey = 'inventory',
  handleClose,
  boxShadow = 'left',
  hideCloseButton,
  title,
}: InventoryFiltersProps) {
  const { filtersState, filterConfigs } = useFiltersContext();
  const [, setDatePeriod] = useAtom(datePeriodAtom);

  const dateRangeOptions = getPeriodSelectOptions(3);
  useEffect(() => {
    if (dayjs().date() === 1 && process.env.REACT_APP_DEPLOY_ENV === 'demo') {
      setDatePeriod(keyToPeriod(dateRangeOptions[1].value));
    }
  }, []);

  return (
    <div
      className={classnames([
        styles.inventoryFilters,
        isShow && pageKey !== 'vvo' && styles.showFilters,
        isShow && pageKey === 'vvo' && styles.showFiltersVVO,
        boxShadow === 'right' ? styles.boxshadowRight : styles.boxshadowLeft,
      ])}
    >
      <div className={styles.filterHeader}>
        <Text fontWeight={7}>{title}</Text>
        {!hideCloseButton && (
          <IconButton onClick={handleClose} size='md' aria-label='Close filter sidebar'>
            <IconX size={20} />
          </IconButton>
        )}
      </div>
      <div
        className={classnames([
          styles.filtersBody,
          inventoryPageKey === 'live' && styles.withoutExtraFilters,
        ])}
      >
        {inventoryPageKey !== 'live' && (
          <div className={styles.extraFilters}>
            <DatePeriodSelect optionQuantity={24} showPreviousMonths />
          </div>
        )}
        {filterConfigs
          .filter((filterConfig) => filterConfig.id !== 'search')
          .map((filter) => {
            if (
              (filter.id === 'activeFilterPills' && Object.keys(filtersState).length === 0) ||
              filter.excludeFromPage === inventoryPageKey
            ) {
              return null;
            }
            return <Filter key={filter.id} config={filter} groupId='inventory' />;
          })}
      </div>
    </div>
  );
}
