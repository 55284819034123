import { useLayoutEffect, useRef, useState } from 'react';

import { IconSearch, IconX } from '@tabler/icons-react';
import classnames from 'classnames';
import IconButton from 'Sparky/IconButton';
import { COLOR } from 'Sparky/styles/vars';
import TextInput from 'Sparky/TextInput';

import { useFiltersContext } from 'components/Filters/FiltersContext';
import { SearchState } from 'components/Filters/types';

import styles from './SearchOptions.module.scss';

interface SearchOptionsProps {
  groupId?: string;
  filterConfigId: string;
  size?: 'sm' | 'md';
}

/**
 * Search filter input
 */
export default function SearchOptions({
  groupId,
  filterConfigId,
  size = 'md',
}: SearchOptionsProps) {
  const [openSearchField, setOpenSearchField] = useState<boolean>(false);
  const { setFilterState, getFilterState, clearFilter, isLoading } = useFiltersContext();
  const state = getFilterState(filterConfigId) as SearchState | undefined;
  const ref = useRef<HTMLInputElement>(null);

  const updateFilterValue = (val: string) => {
    if (val.length) {
      setFilterState(filterConfigId, { search: val });
    } else {
      clearFilter(filterConfigId);
    }
  };

  const onClear = () => {
    clearFilter(filterConfigId);
    setOpenSearchField(false);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.value = state?.search ?? '';
    }
  }, [state]);

  const rightElement = (
    <IconButton
      aria-label='clear search'
      className={styles.closeButton}
      disabled={isLoading}
      onClick={(e) => {
        e.stopPropagation();
        onClear();
      }}
    >
      <IconX size={size === 'md' ? 20 : 14} color={COLOR.SPARKY_GREY_400} />
    </IconButton>
  );

  return (
    <div className={classnames([groupId !== 'inventory' && styles.searchOptions])}>
      {state?.search || openSearchField ? (
        <TextInput
          id='inventory header'
          ref={ref}
          placeholder='Search'
          size={size}
          leftElement={<IconSearch size={size === 'md' ? 20 : 12} color={COLOR.SPARKY_GREY_400} />}
          rightElement={rightElement}
          onClear={onClear}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              updateFilterValue((e.target as HTMLInputElement).value);
            }
          }}
          disabled={isLoading}
          className={styles.searchInput}
        />
      ) : (
        <IconButton aria-label='open search input' size={size}>
          <IconSearch
            onClick={() => setOpenSearchField(true)}
            size='20'
            color={COLOR.SPARKY_GREY_400}
          />
        </IconButton>
      )}
    </div>
  );
}
