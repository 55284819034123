import Table from 'Sparky/Table';
import InventoryTableCell from 'Sparky/Table/InventoryTableCell';
import TableBody from 'Sparky/Table/TableBody';
import { TableProvider } from 'Sparky/Table/TableContext';
import TableHeader from 'Sparky/Table/TableHeader';
import TablePagination from 'Sparky/Table/TablePagination';
import TableRow from 'Sparky/Table/TableRow';

import { AddBudgetData } from 'pages/Inventory/InventoryPage/AddBudgetSidebar';
import { InventoryItem } from 'types/InventoryItem';

import styles from './AddBudgetConfirmTable.module.scss';
import {
  addBudgetConfirmTableColumnDefs,
  addBudgetConfirmTableColumnOrder,
  AddBudgetTableColumnKey,
} from './AddBudgetConfirmTableColumnDefs';

export interface AddBudgetConfirmTableProps {
  vins?: InventoryItem[];
  budgets: AddBudgetData;
}

/**
 * AddBudgetConfirmTable
 */
export default function AddBudgetConfirmTable({ vins, budgets }: AddBudgetConfirmTableProps) {
  return (
    <TableProvider data={vins} initialState={{ pageSize: 5, page: 0 }}>
      <div className={styles.addBudgetTable}>
        <Table>
          <thead className={styles.theader}>
            <TableRow className={styles.tableRow}>
              {addBudgetConfirmTableColumnOrder.map((col) => (
                <TableHeader
                  key={col}
                  textAlign={
                    addBudgetConfirmTableColumnDefs(vins?.length ?? 0, budgets)[
                      col as AddBudgetTableColumnKey
                    ].textAlign
                  }
                  className={styles.tableHeader}
                >
                  {
                    addBudgetConfirmTableColumnDefs(vins?.length ?? 0, budgets)[
                      col as AddBudgetTableColumnKey
                    ].header
                  }
                </TableHeader>
              ))}
            </TableRow>
          </thead>
          <TableBody>
            {(r: InventoryItem) => (
              <TableRow key={r.listing.vin} className={styles.tableRow}>
                {addBudgetConfirmTableColumnOrder.map((col) => (
                  <InventoryTableCell
                    key={col}
                    className={
                      addBudgetConfirmTableColumnDefs(vins?.length ?? 0, budgets)[
                        col as AddBudgetTableColumnKey
                      ].className
                    }
                    width={
                      addBudgetConfirmTableColumnDefs(vins?.length ?? 0, budgets)[
                        col as AddBudgetTableColumnKey
                      ].width
                    }
                    textAlign={
                      addBudgetConfirmTableColumnDefs(vins?.length ?? 0, budgets)[
                        col as AddBudgetTableColumnKey
                      ].textAlign
                    }
                    col={col}
                  >
                    {addBudgetConfirmTableColumnDefs(vins?.length ?? 0, budgets)[
                      col as AddBudgetTableColumnKey
                    ].cellRenderer(r)}
                  </InventoryTableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination showRowPerPageSelection={false} />
    </TableProvider>
  );
}
