import Text from 'Sparky/Text';

import styles from './SwitchField.module.scss';
import Switch, { SwitchProps } from '../Switch';

interface SwitchFieldProps extends SwitchProps {
  position?: 'left' | 'right';
  label?: string;
  helpText?: string;
}

const SwitchField = ({ position = 'right', label, helpText, ...switchProps }: SwitchFieldProps) => (
  <form>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {label && position === 'left' && (
        <label
          className={styles.leftLabel}
          data-disabled={switchProps.disabled}
          htmlFor={switchProps.id}
        >
          <Text>{label}</Text>
        </label>
      )}
      <Switch {...switchProps} />
      {label && position === 'right' && (
        <label
          className={styles.rightLabel}
          data-disabled={switchProps.disabled}
          htmlFor={switchProps.id}
        >
          <Text>{label}</Text>
        </label>
      )}
    </div>
    {helpText && (
      <label
        className={position === 'right' ? styles.rightHelpText : styles.leftHelpText}
        data-disabled={switchProps.disabled}
        htmlFor={switchProps.id}
      >
        <Text size='12'>{helpText}</Text>
      </label>
    )}
  </form>
);

export default SwitchField;
