export function isCurrentDateInESTRange(
  startEST: string | undefined,
  endEST: string | undefined
): boolean {
  // Get the current date and time in the user's local timezone
  const currentDate = new Date();

  // Convert the EST time range strings to Date objects
  const startDate = new Date(`${startEST} GMT-0500`); // EST is UTC-5
  const endDate = new Date(`${endEST} GMT-0500`); // EST is UTC-5

  // Check if the current EST time is between the start and end times
  return currentDate >= startDate && currentDate <= endDate;
}
