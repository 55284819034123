import React from 'react';

import { IconAsterisk } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { COLOR } from 'Sparky/styles/vars';
import Text from 'Sparky/Text';
import Tooltip from 'Sparky/Tooltip';

import PercentChangeIndicator from 'components/PercentChangeIndicator';
import Actions from 'pages/Inventory/Acquisition/AcquisitionTable/Actions';
import Price from 'pages/Inventory/Acquisition/AcquisitionTable/Price';
import Sources from 'pages/Inventory/Acquisition/AcquisitionTable/Sources';
import { InventoryPageKey } from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';
import RiskScore from 'pages/Inventory/InventoryPage/components/RiskScore';
import {
  InventoryTableAddBudgetSidebarColumnKey,
  InventoryTableColumnKey,
  InventoryTableGroupKey,
  InventoryTableWithValueColumnKey,
} from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import AIRecommendedBudgetIncrease from 'pages/Inventory/InventoryPage/InventoryTable/AIRecommendedBudgetIncrease';
import ImageCell from 'pages/Inventory/InventoryPage/InventoryTable/ImageCell';
import styles from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTable.module.scss';
import { InventoryTableTooltipsDefs } from 'pages/Inventory/InventoryPage/InventoryTable/InventoryTableTooltipsDefs';
import VehicleSummary from 'pages/Inventory/InventoryPage/InventoryTable/VehicleSummary';
import { InventoryItem } from 'types/InventoryItem';
import { formatCurrency, formatDate, formatNumber, formatPercent } from 'util/formatters';

export interface InventoryTableColumnDef {
  key: InventoryTableColumnKey;
  header: string;
  notation?: React.ReactNode;
  pillLabel: string;
  width?: string;
  className?: string;
  flag?: boolean;
  tooltipKey?: string;
  textAlign?: 'left' | 'right' | 'center';

  getter?(r: InventoryItem): string | number | boolean | null | undefined;

  cellRenderer(r: InventoryItem): React.ReactNode;

  csvRenderer(r: InventoryItem): string | number | undefined;
}

export type CSVDataType = 'number' | 'percentage' | 'currency' | 'boolean';

export interface InventoryTableWithValueColumnDef extends InventoryTableColumnDef {
  csvDataType?: CSVDataType;

  getter(r: InventoryItem): string | number | boolean | null | undefined;
}

function getAIRecommendedBudgetIncrease(
  budget?: number,
  aiRecommendedBudgetIncrease?: number
): number | undefined {
  if ((budget ?? 0) >= (aiRecommendedBudgetIncrease ?? 0)) {
    return undefined;
  } else {
    const tempIncreaseBudget =
      25 * Math.ceil(Math.abs(((aiRecommendedBudgetIncrease ?? 0) - (budget ?? 0)) / 25));
    return tempIncreaseBudget > 100 ? 100 : Math.max(tempIncreaseBudget, 50);
  }
}

export const addBudgetSidebarColumns: InventoryTableAddBudgetSidebarColumnKey[] = [
  'aiRecommendedBudgetIncrease',
  'budget',
];

export const InventoryTableAddBudgetSidebarColumnDefs = (
  flag?: Record<string, boolean>
): Record<InventoryTableAddBudgetSidebarColumnKey, InventoryTableWithValueColumnDef> => {
  return {
    budget: {
      key: 'budget',
      header: 'Budget',
      pillLabel: 'Budget',
      notation: (
        <div className={styles.budgetHeader}>
          <IconAsterisk stroke={4} color={COLOR.SPARKY_BLUE_400} size='11' />
          <Text fontWeight={4} size='12'>
            recently changed
          </Text>
        </div>
      ),
      tooltipKey: 'Live Inventory-Budget-Budget',
      getter: (r) => (r.budgetMetrics.budget === -1 ? Infinity : r.budgetMetrics.budget),
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => (
        <div className={styles.budgetCellRender}>
          {r.budgetMetrics.hasExistingAddBudget && (
            <Tooltip
              content={InventoryTableTooltipsDefs.budget.recentlyChanged}
              disabled={!r.budgetMetrics.hasExistingAddBudget}
            >
              <div>
                <IconAsterisk stroke={4} color={COLOR.SPARKY_BLUE_400} size='11' />
              </div>
            </Tooltip>
          )}
          <Text>
            {r.budgetMetrics.budget === -1
              ? 'Unlimited'
              : formatCurrency(r.budgetMetrics.budget, true)}
          </Text>
        </div>
      ),
      csvDataType: 'currency',
      csvRenderer: (r) =>
        r.budgetMetrics.budget === -1 ? 'Unlimited' : formatCurrency(r.budgetMetrics.budget, true),
    },
    aiRecommendedBudgetIncrease: {
      key: 'aiRecommendedBudgetIncrease',
      header: 'AI recommended\nbudget increase',
      pillLabel: 'AI recommended budget increase',
      tooltipKey: 'Live Inventory-Budget-AI Recommended Budget Increase',
      getter: (r) =>
        getAIRecommendedBudgetIncrease(r.budgetMetrics.budget, r.budgetMetrics.aiRecommendedBudget),
      textAlign: 'center',
      flag: flag?.aiRecommendedBudgetIncrease,
      cellRenderer: (r) => (
        <AIRecommendedBudgetIncrease
          vin={r.listing.vin}
          aiRecommendedBudgetIncrease={getAIRecommendedBudgetIncrease(
            r.budgetMetrics.budget,
            r.budgetMetrics.aiRecommendedBudget
          )}
          hasExistingAddBudget={r.budgetMetrics.hasExistingAddBudget}
        />
      ),
      csvDataType: 'currency',
      csvRenderer: (r) =>
        r.budgetMetrics.aiRecommendedBudget === undefined ||
        r.budgetMetrics.aiRecommendedBudget === 0
          ? 'No change'
          : formatCurrency(
              getAIRecommendedBudgetIncrease(
                r.budgetMetrics.budget,
                r.budgetMetrics.aiRecommendedBudget
              ),
              true
            ),
    },
  };
};

export const InventoryTableColumnWithGetterDefs = (
  inventoryPageKey: InventoryPageKey,
  flag?: Record<string, boolean>
): Record<InventoryTableWithValueColumnKey, InventoryTableWithValueColumnDef> => {
  return {
    ...InventoryTableAddBudgetSidebarColumnDefs(flag),
    modelYear: {
      key: 'modelYear',
      header: 'Year',
      pillLabel: 'Year',
      textAlign: 'right',
      tooltipKey:
        inventoryPageKey === 'live' ? 'Live Inventory-Vehicle-Year' : 'Sold Inventory-Vehicle-Year',
      getter: (r) => r.vehicle.modelYear,
      flag: true,
      cellRenderer: (r) => r.vehicle.modelYear,
      csvDataType: 'number',
      csvRenderer: (r) => r.vehicle.modelYear,
    },
    make: {
      key: 'make',
      header: 'Make',
      pillLabel: 'Make',
      textAlign: 'left',
      tooltipKey:
        inventoryPageKey === 'live' ? 'Live Inventory-Vehicle-Make' : 'Sold Inventory-Vehicle-Make',
      getter: (r) => r.vehicle.make,
      width: '10rem',
      flag: true,
      cellRenderer: (r) => r.vehicle.make,
      csvRenderer: (r) => r.vehicle.make,
    },
    model: {
      key: 'model',
      header: 'Model',
      pillLabel: 'Model',
      textAlign: 'left',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Model'
          : 'Sold Inventory-Vehicle-Model',
      getter: (r) => r.vehicle.model,
      flag: true,
      cellRenderer: (r) => r.vehicle.model,
      csvRenderer: (r) => r.vehicle.model,
    },
    trim: {
      key: 'trim',
      header: 'Trim',
      pillLabel: 'Trim',
      textAlign: 'left',
      width: '12rem',
      tooltipKey:
        inventoryPageKey === 'live' ? 'Live Inventory-Vehicle-Trim' : 'Sold Inventory-Vehicle-Trim',
      getter: (r) => r.vehicle.trim,
      flag: true,
      cellRenderer: (r) => r.vehicle.trim,
      csvRenderer: (r) => r.vehicle.trim,
    },
    bodySegment: {
      key: 'bodySegment',
      header: 'Body style',
      pillLabel: 'Body style',
      textAlign: 'left',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Body Style'
          : 'Sold Inventory-Vehicle-Body Style',
      getter: (r) => r.vehicle.bodySegment,
      flag: true,
      cellRenderer: (r) => r.vehicle.bodySegment?.toLocaleUpperCase(),
      csvRenderer: (r) => r.vehicle.bodySegment,
    },
    stockNumber: {
      key: 'stockNumber',
      header: 'Stock #',
      pillLabel: 'Stock #',
      textAlign: 'left',
      width: '6rem',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Stock #'
          : 'Sold Inventory-Vehicle-Stock #',
      getter: (r) => r.listing.stockNumber,
      flag: true,
      cellRenderer: (r) => r.listing.stockNumber,
      csvRenderer: (r) => r.listing.stockNumber,
    },
    vin: {
      key: 'vin',
      header: 'VIN',
      pillLabel: 'VIN',
      textAlign: 'left',
      width: '13rem',
      tooltipKey:
        inventoryPageKey === 'live' ? 'Live Inventory-Vehicle-VIN' : 'Sold Inventory-Vehicle-VIN',
      getter: (r) => r.listing.vin,
      flag: true,
      cellRenderer: (r) => r.listing.vin,
      csvRenderer: (r) => r.listing.vin,
    },
    condition: {
      key: 'condition',
      header: 'Condition',
      pillLabel: 'Condition',
      textAlign: 'left',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Condition'
          : 'Sold Inventory-Vehicle-Condition',
      getter: (r) => r.listing.inventoryCondition,
      flag: true,
      cellRenderer: (r) => r.listing.inventoryCondition,
      csvRenderer: (r) => r.listing.inventoryCondition,
    },
    imageCount: {
      key: 'imageCount',
      header: 'Photo count',
      pillLabel: 'Photo count',
      textAlign: 'right',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Photo Count'
          : 'Sold Inventory-Vehicle-Photo Count',
      getter: (r) => r.listing.imageCount,
      flag: true,
      cellRenderer: (r) => formatNumber(r.listing.imageCount),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.listing.imageCount),
    },
    mileage: {
      key: 'mileage',
      header: 'Mileage',
      pillLabel: 'Mileage',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Mileage'
          : 'Sold Inventory-Vehicle-Mileage',
      getter: (r) => r.listing.mileage,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.listing.mileage),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.listing.mileage),
    },
    dol: {
      key: 'dol',
      header: 'DOL',
      pillLabel: 'DOL',
      tooltipKey:
        inventoryPageKey === 'live' ? 'Live Inventory-Vehicle-DOL' : 'Sold Inventory-Vehicle-DOL',
      getter: (r) => r.listing.dol,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.listing.dol),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.listing.dol),
    },
    exteriorColor: {
      key: 'exteriorColor',
      header: 'Exterior color',
      pillLabel: 'Exterior color',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Exterior color'
          : 'Sold Inventory-Vehicle-Exterior color',
      getter: (r) => r.listing.exteriorColor,
      textAlign: 'left',
      flag: true,
      cellRenderer: (r) => r.listing.exteriorColor,
      csvRenderer: (r) => r.listing.exteriorColor,
    },
    status: {
      key: 'status',
      header: 'Vehicle status',
      pillLabel: 'Vehicle status',
      textAlign: 'center',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Status-Vehicle Status'
          : 'Sold Inventory-Status-Vehicle Status',
      getter: (r) => r.status.status,
      flag: true,
      cellRenderer: (r) => {
        if (r.status.status === 'SOLD' || r.status.status === 'ACTIVE') {
          return <span className={styles.tablePillGreen}>{r.status.status}</span>;
        } else if (r.status.status === 'MOVED' || r.status.status === 'PENDING') {
          return <span className={styles.tablePillGrey}>{r.status.status}</span>;
        } else {
          return r.status.status;
        }
      },
      csvRenderer: (r) => r.status.status,
      width: '6rem',
    },
    lotStatus: {
      key: 'lotStatus',
      header: 'Lot status',
      pillLabel: 'Lot status',
      textAlign: 'left',
      tooltipKey: 'Live Inventory-Status-Lot Status',
      getter: (r) => r.status.lotStatus,
      flag: true,
      cellRenderer: (r) => r.status.lotStatus,
      csvRenderer: (r) => r.status.lotStatus,
      width: '8rem',
    },
    isBeingPublished: {
      key: 'isBeingPublished',
      header: 'Published',
      pillLabel: 'Published',
      textAlign: 'left',
      tooltipKey: 'Live Inventory-Status-Published',
      getter: (r) => r.status.isBeingPublished,
      flag: true,
      cellRenderer: (r) => (r.status.isBeingPublished ? 'Published' : 'Not published'),
      csvDataType: 'boolean',
      csvRenderer: (r) => (r.status.isBeingPublished ? 'Published' : 'Not published'),
      width: '8rem',
    },
    spend: {
      key: 'spend',
      header: 'Spend',
      pillLabel: 'Spend',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Budget-Spend (lifetime)'
          : 'Sold Inventory-Budget-Spend (lifetime)',
      getter: (r) => r.budgetMetrics.spend,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.budgetMetrics.spend, true),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.budgetMetrics.spend, true),
    },
    spendLifetime: {
      key: 'spendLifetime',
      header: 'Spend\n(lifetime)',
      pillLabel: 'Spend (lifetime)',
      tooltipKey: 'Sold Inventory-Budget-Spend (lifetime)',
      getter: (r) => r.budgetMetrics.spendLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.budgetMetrics.spendLifetime, true),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.budgetMetrics.spendLifetime, true),
    },
    remainingBudget: {
      key: 'remainingBudget',
      header: 'Remaining\nbudget',
      pillLabel: 'Remaining budget',
      tooltipKey: 'Live Inventory-Budget-Remaining Budget',
      getter: (r) =>
        r.budgetMetrics.budget === -1 ? Infinity : r.budgetMetrics.budget - r.budgetMetrics.spend,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) =>
        r.budgetMetrics.budget === -1
          ? '-'
          : formatCurrency(r.budgetMetrics.budget - r.budgetMetrics.spend, true),
      csvDataType: 'currency',
      csvRenderer: (r) =>
        r.budgetMetrics.budget === -1
          ? '-'
          : formatCurrency(r.budgetMetrics.budget - r.budgetMetrics.spend, true),
    },
    monthlyCap: {
      key: 'monthlyCap',
      header: 'Lotlinx days\nin campaign',
      pillLabel: 'Lotlinx days in campaign',
      tooltipKey: 'Live Inventory-Budget-Lotlinx Days in Campaign',
      getter: (r) => r.budgetMetrics.monthlyCap,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.status.publishedDays),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.status.publishedDays),
    },
    lifetimeCap: {
      key: 'lifetimeCap',
      header: 'Lotlinx days\nin campaign (lifetime)',
      pillLabel: 'Lotlinx days in campaign (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Budget-Lotlinx Days in Campaign (lifetime)'
          : 'Sold Inventory-Budget-Lotlinx Days in Campaign (lifetime)',
      getter: (r) => r.budgetMetrics.lifetimeCap,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.status.publishedDaysLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.status.publishedDaysLifetime),
    },
    shoppers: {
      key: 'shoppers',
      header: 'Shoppers\ndelivered',
      pillLabel: 'Shoppers delivered',
      tooltipKey: 'Live Inventory-Engagement-Shoppers Delivered',
      getter: (r) => r.gaMetrics.shoppers,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.shoppers),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.shoppers),
    },
    shoppersLifetime: {
      key: 'shoppersLifetime',
      header: 'Shoppers\ndelivered (lifetime)',
      pillLabel: 'Shoppers delivered (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Shoppers Delivered (lifetime)'
          : 'Sold Inventory-Engagement-Shoppers Delivered (lifetime)',
      getter: (r) => r.gaMetrics.shoppersLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.shoppersLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.shoppersLifetime),
    },
    lotlinxVDP: {
      key: 'lotlinxVDP',
      header: 'Lotlinx\nVDPs',
      pillLabel: 'Lotlinx VDPs',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Lotlinx VDPs'
          : 'Sold Inventory-Engagement-Lotlinx VDPs',
      getter: (r) => r.gaMetrics.lotlinxVDP,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.lotlinxVDP),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.lotlinxVDP),
    },
    lotlinxVDPLifetime: {
      key: 'lotlinxVDPLifetime',
      header: 'Lotlinx VDPs\n(lifetime)',
      pillLabel: 'Lotlinx VDPs (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Lotlinx VDPs (lifetime)'
          : 'Sold Inventory-Engagement-Lotlinx VDPs (lifetime)',
      getter: (r) => r.gaMetrics.lotlinxVDPLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.lotlinxVDPLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.lotlinxVDPLifetime),
    },
    totalVDP: {
      key: 'totalVDP',
      header: 'Total\nVDPs',
      pillLabel: 'Total VDPs',
      tooltipKey: 'Live Inventory-Engagement-Total VDPs',
      getter: (r) => r.gaMetrics.totalVDP,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.totalVDP),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.totalVDP),
    },
    totalVdpsSinceLastPriceChange: {
      key: 'totalVdpsSinceLastPriceChange',
      header: 'Total VDPs\nsince last price change',
      pillLabel: 'Total VDPs since last price change',
      tooltipKey: 'Live Inventory-Engagement-Total VDPs since last price change',
      getter: (r) => r.gaMetrics.totalVdpsSinceLastPriceChange,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.totalVdpsSinceLastPriceChange),
      csvRenderer: (r) => formatNumber(r.gaMetrics.totalVdpsSinceLastPriceChange),
    },
    totalVDPLifetime: {
      key: 'totalVDPLifetime',
      header: 'Total VDPs\n(lifetime)',
      pillLabel: 'Total VDPs (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Total VDPs (lifetime)'
          : 'Sold Inventory-Engagement-Total VDPs (lifetime)',
      getter: (r) => r.gaMetrics.totalVDPLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.totalVDPLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.totalVDPLifetime),
    },
    costPerVDP: {
      key: 'costPerVDP',
      header: 'Cost per\nVDP',
      pillLabel: 'Cost per VDP',
      tooltipKey: 'Live Inventory-Engagement-Cost per VDP',
      getter: (r) => r.gaMetrics.costPerVDP,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.gaMetrics.costPerVDP, true),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.gaMetrics.costPerVDP, true),
    },
    costPerVDPLifetime: {
      key: 'costPerVDPLifetime',
      header: 'Cost per VDP\n(lifetime)',
      pillLabel: 'Cost per VDP (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Cost per VDP (lifetime)'
          : 'Sold Inventory-Engagement-Cost per VDP (lifetime)',
      getter: (r) => r.gaMetrics.costPerVDPLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.gaMetrics.costPerVDPLifetime, true),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.gaMetrics.costPerVDPLifetime, true),
    },
    lotlinxVdpPct: {
      key: 'lotlinxVdpPct',
      header: 'Lotlinx % of\nVDPs',
      pillLabel: 'Lotlinx % of VDPs',
      tooltipKey: 'Live Inventory-Engagement-Lotlinx % of VDPs',
      getter: (r) => r.gaMetrics.lotlinxVdpPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatPercent(r.gaMetrics.lotlinxVdpPct),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.gaMetrics.lotlinxVdpPct),
    },
    lotlinxVdpPctLifetime: {
      key: 'lotlinxVdpPctLifetime',
      header: 'Lotlinx % of\nVDPs (lifetime)',
      pillLabel: 'Lotlinx % of VDPs (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Lotlinx % of VDPs (lifetime)'
          : 'Sold Inventory-Engagement-Lotlinx % of VDPs (lifetime)',
      getter: (r) => r.gaMetrics.lotlinxVdpPctLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatPercent(r.gaMetrics.lotlinxVdpPctLifetime),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.gaMetrics.lotlinxVdpPctLifetime),
    },
    totalConvSinceLastPriceChange: {
      key: 'totalConvSinceLastPriceChange',
      header: 'Total conversions\nsince last price change',
      pillLabel: 'Total conversions since last price change',
      tooltipKey: 'Live Inventory-Engagement-Total conversions since last price change',
      getter: (r) => r.gaMetrics?.totalConvSinceLastPriceChange,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics?.totalConvSinceLastPriceChange),
      csvRenderer: (r) => formatNumber(r.gaMetrics?.totalConvSinceLastPriceChange),
    },
    conversions: {
      key: 'conversions',
      header: 'Lotlinx conversions',
      pillLabel: 'Lotlinx conversions',
      tooltipKey: 'Live Inventory-Engagement-Conversions',
      getter: (r) => r.gaMetrics.lotlinxConv,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.lotlinxConv),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.lotlinxConv),
    },
    conversionsLifetime: {
      key: 'conversionsLifetime',
      header: 'Lotlinx Conversions\n(lifetime)',
      pillLabel: 'Lotlinx Conversions (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Conversions (lifetime)'
          : 'Sold Inventory-Engagement-Conversions (lifetime)',
      getter: (r) => r.gaMetrics.lotlinxConvLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.lotlinxConvLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.lotlinxConvLifetime),
    },
    leadFormSubmissions: {
      key: 'leadFormSubmissions',
      header: 'Lead form\nsubmissions',
      pillLabel: 'Lead form submissions',
      tooltipKey: 'Live Inventory-Engagement-Lead Form Submissions',
      getter: (r) => r.gaMetrics.leadFormSubmissions,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.leadFormSubmissions),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.leadFormSubmissions),
    },
    leadFormSubmissionsLifetime: {
      key: 'leadFormSubmissionsLifetime',
      header: 'Lead form\nsubmissions (lifetime)',
      pillLabel: 'Lead form submissions (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Lead Form Submissions (lifetime)'
          : 'Sold Inventory-Engagement-Lead Form Submissions (lifetime)',
      getter: (r) => r.gaMetrics.leadFormSubmissionsLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.leadFormSubmissionsLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.leadFormSubmissionsLifetime),
    },
    clickToText: {
      key: 'clickToText',
      header: 'Click to text',
      pillLabel: 'Click to text',
      tooltipKey: 'Live Inventory-Engagement-Click to Text',
      getter: (r) => r.gaMetrics.clickToText,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.clickToText),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.clickToText),
    },
    clickToTextLifetime: {
      key: 'clickToTextLifetime',
      header: 'Click to text\n(lifetime)',
      pillLabel: 'Click to text (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Click to Text (lifetime)'
          : 'Sold Inventory-Engagement-Click to Text (lifetime)',
      getter: (r) => r.gaMetrics.clickToTextLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.clickToTextLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.clickToTextLifetime),
    },
    clickToChat: {
      key: 'clickToChat',
      header: 'Click to chat',
      pillLabel: 'Click to chat',
      tooltipKey: 'Live Inventory-Engagement-Click to Chat',
      getter: (r) => r.gaMetrics.clickToChat,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.clickToChat),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.clickToChat),
    },
    clickToChatLifetime: {
      key: 'clickToChatLifetime',
      header: 'Click to chat\n(lifetime)',
      pillLabel: 'Click to chat (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Click to Chat (lifetime)'
          : 'Sold Inventory-Engagement-Click to Chat (lifetime)',
      getter: (r) => r.gaMetrics.clickToChatLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.clickToChatLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.clickToChatLifetime),
    },
    clickToCall: {
      key: 'clickToCall',
      header: 'Click to call',
      pillLabel: 'Click to call',
      tooltipKey: 'Live Inventory-Engagement-Click to Call',
      getter: (r) => r.gaMetrics.clickToCall,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.clickToCall),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.clickToCall),
    },
    clickToCallLifetime: {
      key: 'clickToCallLifetime',
      header: 'Click to call\n(lifetime)',
      pillLabel: 'Click to call (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Engagement-Click to Call (lifetime)'
          : 'Sold Inventory-Engagement-Click to Call (lifetime)',
      getter: (r) => r.gaMetrics.clickToCallLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.clickToCallLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.clickToCallLifetime),
    },
    currentPrice: {
      key: 'currentPrice',
      header: 'Current\nprice',
      pillLabel: 'Current price',
      tooltipKey: 'Live Inventory-Price-Current Price',
      getter: (r) => r.priceMetrics?.currentPrice,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.priceMetrics?.currentPrice),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.priceMetrics?.currentPrice),
    },
    lastPublishedPrice: {
      key: 'lastPublishedPrice',
      header: 'Last\nprice',
      pillLabel: 'Last price',
      tooltipKey: 'Live Inventory-Price-Last Price',
      getter: (r) => r.priceMetrics?.lastPublishedPrice,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.priceMetrics?.lastPublishedPrice),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.priceMetrics?.lastPublishedPrice),
    },
    initialPrice: {
      key: 'initialPrice',
      header: 'Initial\nprice',
      pillLabel: 'Initial price',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Price-Initial Price'
          : 'Sold Inventory-Price-Initial Price',
      getter: (r) => r.priceMetrics?.initialPrice,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.priceMetrics?.initialPrice),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.priceMetrics?.initialPrice),
    },
    totalPriceChange: {
      key: 'totalPriceChange',
      header: 'Price\nchange',
      pillLabel: 'Price change',
      tooltipKey: 'Live Inventory-Price-Price Change',
      getter: (r) => r.priceMetrics?.totalPriceChange,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.priceMetrics?.totalPriceChange),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.priceMetrics?.totalPriceChange),
    },
    daysSinceLastPriceChange: {
      key: 'daysSinceLastPriceChange',
      header: 'Days since last\nprice change',
      pillLabel: 'Days since last price change',
      tooltipKey: 'Live Inventory-Price-Days Since Last Price Change',
      getter: (r) => r.priceMetrics?.daysSinceLastPriceChange,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.priceMetrics?.daysSinceLastPriceChange),
      csvDataType: 'number',
      csvRenderer: (r: InventoryItem) => formatNumber(r.priceMetrics?.daysSinceLastPriceChange),
    },
    lastPriceChangeDate: {
      key: 'lastPriceChangeDate',
      header: 'Last price change\ndate',
      pillLabel: 'Last price change date',
      tooltipKey: 'Live Inventory-Price-Last price change date',
      getter: (r) => r.priceMetrics?.lastPriceChangeDate,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => dayjs(r.priceMetrics?.lastPriceChangeDate).format('YYYY-MM-DD'),
      csvRenderer: (r) => dayjs(r.priceMetrics?.lastPriceChangeDate).format('YYYY-MM-DD'),
    },
    priceChangeNumber: {
      key: 'priceChangeNumber',
      header: 'Total # of\nprice changes',
      pillLabel: 'Total # of price changes',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Price-Total # of Price Changes'
          : 'Sold Inventory-Price-Total # of Price Changes',
      getter: (r) => r.priceMetrics?.priceChangeNumber,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.priceMetrics?.priceChangeNumber),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.priceMetrics?.priceChangeNumber),
    },
    avgPriceChange: {
      key: 'avgPriceChange',
      header: 'Avg\nprice change',
      pillLabel: 'Avg price change',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Price-Avg Price Change'
          : 'Sold Inventory-Price-Avg Price Change',
      getter: (r) => r.priceMetrics?.avgPriceChange,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.priceMetrics?.avgPriceChange, true),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.priceMetrics?.avgPriceChange, true),
    },
    currentPriceToMarketPct: {
      key: 'currentPriceToMarketPct',
      header: 'Current price\nto market %',
      pillLabel: 'Current price to market %',
      tooltipKey: 'Live Inventory-Price-Current Price to Market %',
      getter: (r) => r.priceMetrics?.currentPriceToMarketPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatPercent(r.priceMetrics?.currentPriceToMarketPct),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.priceMetrics?.currentPriceToMarketPct),
    },
    lastPriceToMarketPct: {
      key: 'lastPriceToMarketPct',
      header: 'Last price\nto market %',
      pillLabel: 'Last price to market %',
      tooltipKey: 'Live Inventory-Price-Last Price to Market %',
      getter: (r) => r.priceMetrics?.lastPriceToMarketPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatPercent(r.priceMetrics?.lastPriceToMarketPct),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.priceMetrics?.lastPriceToMarketPct),
    },
    initialPriceToMarketPct: {
      key: 'initialPriceToMarketPct',
      header: 'Initial price\nto market %',
      pillLabel: 'Initial price to market %',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Price-Initial Price to Market %'
          : 'Sold Inventory-Price-Initial Price to Market %',
      getter: (r) => r.priceMetrics?.initialPriceToMarketPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatPercent(r.priceMetrics?.initialPriceToMarketPct),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.priceMetrics?.initialPriceToMarketPct),
    },
    avgMarketPrice: {
      key: 'avgMarketPrice',
      header: 'Avg market\nprice (National)',
      pillLabel: 'Avg market price (National)',
      tooltipKey: 'Live Inventory-Market-Avg Market Price',
      getter: (r) => r.marketMetrics.avgMarketPrice,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.marketMetrics.avgMarketPrice),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.marketMetrics.avgMarketPrice),
    },
    avgMarketPrice7DayPct: {
      key: 'avgMarketPrice7DayPct',
      header: 'Avg market\nprice 7d % (National)',
      pillLabel: 'Avg market price 7d % (National)',
      tooltipKey: 'Live Inventory-Market-Avg Market Price 7d%',
      getter: (r) => r.marketMetrics.avgMarketPrice7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.avgMarketPrice7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.avgMarketPrice7DayPct),
    },
    avgMarketSoldPrice: {
      key: 'avgMarketSoldPrice',
      header: 'Avg market\nsale price (National)',
      pillLabel: 'Avg market sale price (National)',
      tooltipKey: 'Live Inventory-Market-Avg Market Sale Price',
      getter: (r) => r.marketMetrics.avgMarketSoldPrice,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.marketMetrics.avgMarketSoldPrice),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.marketMetrics.avgMarketSoldPrice),
    },
    avgMarketSoldPrice7DayPct: {
      key: 'avgMarketSoldPrice7DayPct',
      header: 'Avg market\nsale price 7d % (National)',
      pillLabel: 'Avg market sale price 7d % (National)',
      tooltipKey: 'Live Inventory-Market-Avg Market Sale Price 7d%',
      getter: (r) => r.marketMetrics.avgMarketSoldPrice7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => (
        <PercentChangeIndicator val={r.marketMetrics.avgMarketSoldPrice7DayPct} />
      ),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.avgMarketSoldPrice7DayPct),
    },
    avgDTS: {
      key: 'avgDTS',
      header: 'Avg days\nto sale (National)',
      pillLabel: 'Avg days to sale (National)',
      tooltipKey: 'Live Inventory-Market-Avg Days to Sale',
      getter: (r) => r.marketMetrics.avgDTS,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.avgDTS),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.avgDTS),
    },
    avgDTS7DayPct: {
      key: 'avgDTS7DayPct',
      header: 'Avg days\nto sale 7d % (National)',
      pillLabel: 'Avg days to sale 7d % (National)',
      tooltipKey: 'Live Inventory-Market-Avg Days to Sale 7d%',
      getter: (r) => r.marketMetrics.avgDTS7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.avgDTS7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.avgDTS7DayPct),
    },
    avgBuyers: {
      key: 'avgBuyers',
      header: 'Avg\nshoppers (National)',
      pillLabel: 'Avg shoppers (National)',
      tooltipKey: 'Live Inventory-Market-Avg Shoppers',
      getter: (r) => r.marketMetrics.avgBuyers,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.avgBuyers),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.avgBuyers),
    },
    avgBuyers7DayPct: {
      key: 'avgBuyers7DayPct',
      header: 'Avg\nshoppers 7d % (National)',
      pillLabel: 'Avg shoppers 7d % (National)',
      tooltipKey: 'Live Inventory-Market-Avg Shoppers 7d%',
      getter: (r) => r.marketMetrics.avgBuyers7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.avgBuyers7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.avgBuyers7DayPct),
    },
    dolMarket: {
      key: 'dolMarket',
      header: 'Avg\nmarket DOL (National)',
      pillLabel: 'Avg market DOL (National)',
      tooltipKey: 'Live Inventory-Market-Avg Market DOL',
      getter: (r) => r.marketMetrics.dolMarket,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.dolMarket),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.dolMarket),
    },
    dolMarket7DayPct: {
      key: 'dolMarket7DayPct',
      header: 'Avg market\nDOL 7d % (National)',
      pillLabel: 'Avg market DOL 7d % (National)',
      tooltipKey: 'Live Inventory-Market-Avg Market DOL 7%',
      getter: (r) => r.marketMetrics.dolMarket7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.dolMarket7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.dolMarket7DayPct),
    },
    similarVins: {
      key: 'similarVins',
      header: 'Similar\nVINs (National)',
      pillLabel: 'Similar VINs (National)',
      tooltipKey: 'Live Inventory-Market-Similar VINs',
      getter: (r) => r.marketMetrics.similarVins,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.similarVins),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.similarVins),
    },
    similarVins7DayPct: {
      key: 'similarVins7DayPct',
      header: 'Similar\nVINs 7d % (National)',
      pillLabel: 'Similar VINs 7d % (National)',
      tooltipKey: 'Live Inventory-Market-Similar VINs 7d%',
      getter: (r) => r.marketMetrics.similarVins7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.similarVins7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.similarVins7DayPct),
    },
    rolling30DayTotalLocalShoppers: {
      key: 'rolling30DayTotalLocalShoppers',
      header: 'Total shoppers 30d (Local)',
      pillLabel: 'Total shoppers 30d (Local)',
      tooltipKey: 'Live Inventory-Market-Total shoppers 30d',
      getter: (r) => r.marketMetrics.rolling30DayTotalLocalShoppers,
      textAlign: 'right',
      flag: true,
      csvDataType: 'number',
      cellRenderer: (r) => r.marketMetrics.rolling30DayTotalLocalShoppers,
      csvRenderer: (r) => r.marketMetrics.rolling30DayTotalLocalShoppers,
    },
    rolling30DayAverageLocalShoppers: {
      key: 'rolling30DayAverageLocalShoppers',
      header: 'Avg daily shoppers 30d (Local)',
      pillLabel: 'Avg daily shoppers 30d (Local)',
      tooltipKey: 'Live Inventory-Market-Avg daily shoppers 30d',
      getter: (r) => r.marketMetrics.rolling30DayAverageLocalShoppers,
      textAlign: 'right',
      flag: true,
      csvDataType: 'number',
      cellRenderer: (r) => r.marketMetrics.rolling30DayAverageLocalShoppers,
      csvRenderer: (r) => r.marketMetrics.rolling30DayAverageLocalShoppers,
    },
    rolling7DayTotalLocalShoppersActive: {
      key: 'rolling7DayTotalLocalShoppersActive',
      header: 'Total shoppers 7d (Local)',
      pillLabel: 'Total shoppers 7d (Local)',
      tooltipKey: 'Live Inventory-Market-Total shoppers 7d',
      getter: (r) => r.marketMetrics.rolling7DayTotalLocalShoppersActive,
      textAlign: 'right',
      flag: true,
      csvDataType: 'number',
      cellRenderer: (r) => r.marketMetrics.rolling7DayTotalLocalShoppersActive,
      csvRenderer: (r) => r.marketMetrics.rolling7DayTotalLocalShoppersActive,
    },
    demandTrend7dLocal: {
      key: 'demandTrend7dLocal',
      header: 'Demand trend 7d (Local)',
      pillLabel: 'Demand trend 7d (Local)',
      tooltipKey: 'Live Inventory-Market-Demand trend 7d',
      getter: (r) => r.marketMetrics.demandTrend7dLocal,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => r.marketMetrics.demandTrend7dLocal,
      csvRenderer: (r) => r.marketMetrics.demandTrend7dLocal,
    },
    demandTrendLocal: {
      key: 'demandTrendLocal',
      header: 'Demand rating (Local)',
      pillLabel: 'Demand rating (Local)',
      tooltipKey: 'Live Inventory-Market-Demand trend 7d',
      getter: (r) => r.marketMetrics.demandRating,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => r.marketMetrics.demandRating,
      csvRenderer: (r) => r.marketMetrics.demandRating,
    },

    rolling30DayPercentageLocalShoppers: {
      key: 'rolling30DayPercentageLocalShoppers',
      header: 'Avg daily shoppers 30d % (Local)',
      pillLabel: 'Avg daily shoppers 30d % (Local)',
      tooltipKey: 'Live Inventory-Budget-Percent daily shoppers 7 days',
      getter: (r) => r.marketMetrics.rolling30DayPercentageLocalShoppers,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => (
        <PercentChangeIndicator val={r.marketMetrics.rolling30DayPercentageLocalShoppers} />
      ),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.rolling30DayPercentageLocalShoppers),
    },
    localRolling7DayPercentageActiveShoppers: {
      key: 'localRolling7DayPercentageActiveShoppers',
      header: 'Avg daily shoppers 7d % (Local)',
      pillLabel: 'Avg daily shoppers 7d % (Local)',
      tooltipKey: 'Live Inventory-Budget-Percent daily shoppers 7 days',
      getter: (r) => r.marketMetrics.rolling7DayPercentageLocalShoppersActive,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => (
        <PercentChangeIndicator val={r.marketMetrics.rolling7DayPercentageLocalShoppersActive} />
      ),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.rolling7DayPercentageLocalShoppersActive),
    },
    localRolling7DayAverageActiveShoppers: {
      key: 'localRolling7DayAverageActiveShoppers',
      header: 'Avg daily shoppers 7d (Local)',
      pillLabel: 'Avg daily shoppers 7d (Local)',
      tooltipKey: 'Live Inventory-Budget-Avg daily shoppers 7 days',
      getter: (r) => r.marketMetrics.rolling7DayAverageLocalShoppersActive,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.rolling7DayAverageLocalShoppersActive),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.rolling7DayAverageLocalShoppersActive),
    },
    localAvgMarketPrice: {
      key: 'localAvgMarketPrice',
      header: 'Avg market\nprice (Local)',
      pillLabel: 'Avg market price (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Market Price (Local)',
      getter: (r) => r.marketMetrics.localAvgMarketPrice,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.marketMetrics.localAvgMarketPrice),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.marketMetrics.localAvgMarketPrice),
    },
    localAvgMarketPrice7DayPct: {
      key: 'localAvgMarketPrice7DayPct',
      header: 'Avg market\nprice 7d % (Local)',
      pillLabel: 'Avg market price 7d % (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Market Price 7d% (Local)',
      getter: (r) => r.marketMetrics.localAvgMarketPrice7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => (
        <PercentChangeIndicator val={r.marketMetrics.localAvgMarketPrice7DayPct} />
      ),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.localAvgMarketPrice7DayPct),
    },
    localAvgMarketSoldPrice: {
      key: 'localAvgMarketSoldPrice',
      header: 'Avg market\nsale price (Local)',
      pillLabel: 'Avg market sale price (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Market Sale Price (Local)',
      getter: (r) => r.marketMetrics.localAvgMarketSoldPrice,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.marketMetrics.localAvgMarketSoldPrice),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.marketMetrics.localAvgMarketSoldPrice),
    },
    localAvgMarketSoldPrice7DayPct: {
      key: 'localAvgMarketSoldPrice7DayPct',
      header: 'Avg market\nsale price 7d % (Local)',
      pillLabel: 'Avg market sale price 7d % (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Market Sale Price 7d% (Local)',
      getter: (r) => r.marketMetrics.localAvgMarketSoldPrice7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => (
        <PercentChangeIndicator val={r.marketMetrics.localAvgMarketSoldPrice7DayPct} />
      ),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.localAvgMarketSoldPrice7DayPct),
    },
    localAvgDTS: {
      key: 'localAvgDTS',
      header: 'Avg days\nto sale (Local)',
      pillLabel: 'Avg days to sale (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Days to Sale (Local)',
      getter: (r) => r.marketMetrics.localAvgDTS,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.localAvgDTS),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.localAvgDTS),
    },
    localAvgDTS7DayPct: {
      key: 'localAvgDTS7DayPct',
      header: 'Avg days\nto sale 7d % (Local)',
      pillLabel: 'Avg days to sale 7d % (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Days to Sale 7d% (Local)',
      getter: (r) => r.marketMetrics.localAvgDTS7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.localAvgDTS7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.localAvgDTS7DayPct),
    },
    localAvgBuyers: {
      key: 'localAvgBuyers',
      header: 'Avg\nshoppers (Local)',
      pillLabel: 'Avg shoppers (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Shoppers (Local)',
      getter: (r) => r.marketMetrics.localAvgBuyers,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.localAvgBuyers),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.localAvgBuyers),
    },
    localAvgBuyers7DayPct: {
      key: 'localAvgBuyers7DayPct',
      header: 'Avg\nshoppers 7d % (Local)',
      pillLabel: 'Avg shoppers 7d % (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Shoppers 7d% (Local)',
      getter: (r) => r.marketMetrics.localAvgBuyers7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.localAvgBuyers7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.localAvgBuyers7DayPct),
    },
    localDolMarket: {
      key: 'localDolMarket',
      header: 'Avg\nmarket DOL (Local)',
      pillLabel: 'Avg market DOL (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Market DOL (Local)',
      getter: (r) => r.marketMetrics.localDolMarket,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.localDolMarket),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.localDolMarket),
    },
    localDolMarket7DayPct: {
      key: 'localDolMarket7DayPct',
      header: 'Avg market\nDOL 7d % (Local)',
      pillLabel: 'Avg market DOL 7d % (Local)',
      tooltipKey: 'Live Inventory-Local Market-Avg Market DOL 7d% (Local)',
      getter: (r) => r.marketMetrics.localDolMarket7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.localDolMarket7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.localDolMarket7DayPct),
    },
    localSimilarVins: {
      key: 'localSimilarVins',
      header: 'Similar\nVINs (Local)',
      pillLabel: 'Similar VINs (Local)',
      tooltipKey: 'Live Inventory-Local Market-Similar VINs (Local)',
      getter: (r) => r.marketMetrics.localSimilarVins,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.localSimilarVins),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics.localSimilarVins),
    },
    localSimilarVins7DayPct: {
      key: 'localSimilarVins7DayPct',
      header: 'Similar\nVINs 7d % (Local)',
      pillLabel: 'Similar VINs 7d % (Local)',
      tooltipKey: 'Live Inventory-Local Market-Similar VINs 7d% (Local)',
      getter: (r) => r.marketMetrics.localSimilarVins7DayPct,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <PercentChangeIndicator val={r.marketMetrics.localSimilarVins7DayPct} />,
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.marketMetrics.localSimilarVins7DayPct),
    },
    marketDaysSupply: {
      key: 'marketDaysSupply',
      header: 'Market\ndays supply',
      pillLabel: 'Market days supply',
      getter: (r) => r.marketMetrics.marketDaysSupply,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics.marketDaysSupply),
      csvRenderer: (r) => formatNumber(r.marketMetrics.marketDaysSupply),
    },
    /** Disabled until post-NADA **/
    // dealerDaysSupply: {
    //   key: 'dealerDaysSupply',
    //   header: 'Dealer\ndays supply',
    //   pillLabel: 'Dealer days supply',
    //   getter: (r) => r.marketMetrics.dealerDaysSupply,
    //   textAlign: 'right',
    //   flag: true, cellRenderer: (r) => formatNumber(r.marketMetrics.dealerDaysSupply),
    //   csvRenderer: (r) => formatNumber(r.marketMetrics.dealerDaysSupply),
    // },
    riskScore: {
      key: 'riskScore',
      header: 'Risk score',
      pillLabel: 'Risk score',
      tooltipKey: 'Live Inventory-Vehicle-Risk score',
      getter: (r) => r.scores?.riskScore,
      textAlign: 'center',
      flag: true,
      className: styles.riskScore,
      cellRenderer: (r) => <RiskScore riskScore={r.scores?.riskScore} />,
      csvDataType: 'number',
      csvRenderer: (r) => {
        if (
          r.scores?.riskScore !== undefined &&
          r.scores.riskScore >= 0 &&
          r.scores.riskScore <= 100
        ) {
          if (r.scores.riskScore > 80) {
            return `Very high - ${r.scores.riskScore}`;
          } else if (r.scores.riskScore > 60) {
            return `High - ${r.scores.riskScore}`;
          } else if (r.scores.riskScore > 40) {
            return `Moderate - ${r.scores.riskScore}`;
          } else if (r.scores.riskScore > 20) {
            return `Low - ${r.scores.riskScore}`;
          } else {
            return `Very low - ${r.scores.riskScore}`;
          }
        } else {
          return undefined;
        }
      },
    },
    dealDate: {
      key: 'dealDate',
      header: 'Deal date',
      pillLabel: 'Deal date',
      getter: (r) => r.salesMetrics?.dealDate,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatDate(r.salesMetrics?.dealDate),
      csvRenderer: (r) => formatDate(r.salesMetrics?.dealDate),
    },
    dealNumber: {
      key: 'dealNumber',
      header: 'Deal #',
      pillLabel: 'Deal #',
      getter: (r) => r.salesMetrics?.dealNumber,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => (r.salesMetrics?.dealNumber ? r.salesMetrics.dealNumber : '-'),
      csvDataType: 'number',
      csvRenderer: (r) => (r.salesMetrics?.dealNumber ? r.salesMetrics.dealNumber : '-'),
    },
    soldPrice: {
      key: 'soldPrice',
      header: 'Sold price',
      pillLabel: 'Sold price',
      getter: (r) => r.salesMetrics?.soldPrice,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.salesMetrics?.soldPrice),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.salesMetrics?.soldPrice),
    },
    frontendGross: {
      key: 'frontendGross',
      header: 'Front end gross',
      pillLabel: 'Front end gross',
      getter: (r) => r.salesMetrics?.frontendGross,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatCurrency(r.salesMetrics?.frontendGross),
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.salesMetrics?.frontendGross),
    },
    lotlinxLeads: {
      key: 'lotlinxLeads',
      header: 'Lotlinx leads',
      pillLabel: 'Lotlinx leads',
      tooltipKey: 'Live Inventory-Leads-Lotlinx leads',
      getter: (r) => r.leadMetrics?.lotlinxLeads,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.leadMetrics?.lotlinxLeads),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.leadMetrics?.lotlinxLeads),
    },
    lotlinxLeadsLifetime: {
      key: 'lotlinxLeadsLifetime',
      header: 'Lotlinx leads\n (lifetime)',
      pillLabel: 'Lotlinx leads (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Leads-Lotlinx leads (lifetime)'
          : 'Sold Inventory-Leads-Lotlinx leads (lifetime)',
      getter: (r) => r.leadMetrics?.lotlinxLeadsLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.leadMetrics?.lotlinxLeadsLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.leadMetrics?.lotlinxLeadsLifetime),
    },
    totalLeads: {
      key: 'totalLeads',
      header: 'Total leads',
      pillLabel: 'Total leads',
      tooltipKey: 'Live Inventory-Leads-Total leads',
      getter: (r) => r.leadMetrics?.totalLeads,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.leadMetrics?.totalLeads),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.leadMetrics?.totalLeads),
    },
    totalLeadsLifetime: {
      key: 'totalLeadsLifetime',
      header: 'Total leads\n (lifetime)',
      pillLabel: 'Total leads (lifetime)',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Leads-Total leads (lifetime)'
          : 'Sold Inventory-Leads-Total leads (lifetime)',
      getter: (r) => r.leadMetrics?.totalLeadsLifetime,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.leadMetrics?.totalLeadsLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.leadMetrics?.totalLeadsLifetime),
    },
    avgAgeOfLeads: {
      key: 'avgAgeOfLeads',
      header: 'Avg age of\n leads',
      pillLabel: 'Avg age of leads',
      tooltipKey: 'Live Inventory-Leads-Avg age of leads',
      getter: (r) => r.leadMetrics?.avgAgeOfLeads,
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => formatNumber(r.leadMetrics?.avgAgeOfLeads),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.leadMetrics?.avgAgeOfLeads),
    },
    movedDate: {
      key: 'movedDate',
      header: 'Moved date',
      pillLabel: 'Moved date',
      textAlign: 'left',
      tooltipKey: 'Sold Moved date',
      getter: (r) => r.status.movedDate,
      flag: true,
      cellRenderer: (r) => dayjs(r.status.movedDate).format('YYYY-MM-DD'),
      csvRenderer: (r) => dayjs(r.status.movedDate).format('YYYY-MM-DD'),
    },
    otherPaidCampaignDays: {
      key: 'otherPaidCampaignDays',
      header: 'Other Paid Days \nin Campaign (Lifetime)',
      pillLabel: 'Other Paid Days in Campaign (Lifetime)',
      textAlign: 'right',
      tooltipKey: 'Sold Other paid days',
      getter: (r) => r.listing.dol,
      flag: true,
      cellRenderer: (r) => formatNumber(r.listing.dol),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.listing.dol),
    },
    otherPaidVDPsLifetime: {
      key: 'otherPaidVDPsLifetime',
      header: 'Other paid VDPs \n(lifetime)',
      pillLabel: 'Other paid VDPs (lifetime)',
      textAlign: 'right',
      tooltipKey: 'Sold Other paid VDPs',
      getter: (r) => r.gaMetrics.otherPaidVDPLifetime,
      flag: true,

      cellRenderer: (r) => formatNumber(r.gaMetrics.otherPaidVDPLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.otherPaidVDPLifetime),
    },
    lotlinxEngagementsLifetime: {
      key: 'lotlinxEngagementsLifetime',
      header: 'Lotlinx engagements \n(lifetime)',
      pillLabel: 'Lotlinx engagements (lifetime)',
      textAlign: 'right',
      tooltipKey: '',
      getter: (r) => r.gaMetrics.lotlinxEngLifetime,
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.lotlinxEngLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.lotlinxEngLifetime),
    },
    otherPaidEngagementsLifetime: {
      key: 'otherPaidEngagementsLifetime',
      header: 'Other paid \nengagements (lifetime)',
      pillLabel: 'Other paid engagements (lifetime)',
      textAlign: 'right',
      tooltipKey: '',
      getter: (r) => r.gaMetrics.otherPaidEngLifetime,
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.otherPaidEngLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.otherPaidEngLifetime),
    },
    totalEngagementsLifetime: {
      key: 'totalEngagementsLifetime',
      header: 'Total engagements \n(lifetime)',
      pillLabel: 'Total engagements (lifetime)',
      textAlign: 'right',
      tooltipKey: '',
      getter: (r) => r.gaMetrics.totalEngLifetime,
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.totalEngLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.totalEngLifetime),
    },
    lotlinxPercentEngagementsLifetime: {
      key: 'lotlinxPercentEngagementsLifetime',
      header: 'Lotlinx % of \nengagements (lifetime)',
      pillLabel: 'Lotlinx % of engagements (lifetime)',
      textAlign: 'right',
      tooltipKey: '',
      getter: (r) => r.gaMetrics.lotlinxEngPctLifetime,
      flag: true,
      cellRenderer: (r) => formatPercent(r.gaMetrics.lotlinxEngPctLifetime),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.gaMetrics.lotlinxEngPctLifetime),
    },
    otherPaidConversionsLifetime: {
      key: 'otherPaidConversionsLifetime',
      header: 'Other paid \nconversions (lifetime)',
      pillLabel: 'Other paid conversions (lifetime)',
      textAlign: 'right',
      tooltipKey: '',
      getter: (r) => r.gaMetrics.otherPaidConvLifetime,
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.otherPaidConvLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.otherPaidConvLifetime),
    },
    totalConversionsLifetime: {
      key: 'totalConversionsLifetime',
      header: 'Total conversions \n(lifetime)',
      pillLabel: 'Total conversions (lifetime)',
      textAlign: 'right',
      tooltipKey: '',
      getter: (r) => r.gaMetrics.totalConvLifetime,
      flag: true,
      cellRenderer: (r) => formatNumber(r.gaMetrics.totalConvLifetime),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.gaMetrics.totalConvLifetime),
    },
    lotlinxPercentConversionsLifetime: {
      key: 'lotlinxPercentConversionsLifetime',
      header: 'Lotlinx % of\n conversions (lifetime)',
      pillLabel: 'Lotlinx % of conversions (lifetime)',
      textAlign: 'right',
      tooltipKey: '',
      getter: (r) => r.gaMetrics.lotlinxConvPctLifetime,
      flag: true,
      cellRenderer: (r) => formatPercent(r.gaMetrics.lotlinxConvPctLifetime),
      csvDataType: 'percentage',
      csvRenderer: (r) => formatPercent(r.gaMetrics.lotlinxConvPctLifetime),
    },
    demand: {
      key: 'demand',
      header: 'Demand',
      pillLabel: 'Demand',
      textAlign: 'left',
      tooltipKey: 'Live Inventory-Vehicle-Demand',
      getter: (r) => r.marketMetrics?.demand,
      width: '10rem',
      flag: true,
      cellRenderer: (r) => formatNumber(r.marketMetrics?.demand),
      csvDataType: 'number',
      csvRenderer: (r) => formatNumber(r.marketMetrics?.demand),
    },
    supply: {
      key: 'supply',
      header: 'Supply',
      pillLabel: 'Supply',
      textAlign: 'left',
      tooltipKey: 'Live Inventory-Vehicle-Supply',
      getter: (r) => r.marketMetrics?.supply,
      width: '10rem',
      flag: true,
      cellRenderer: (r) => r.marketMetrics?.supply,
      csvRenderer: (r) => r.marketMetrics?.supply,
    },
    price: {
      key: 'price',
      header: 'Price',
      pillLabel: 'Price',
      tooltipKey: 'Live Inventory-Price-Price',
      getter: (r) => (r.sources ? r.sources[0].price : undefined),
      textAlign: 'right',
      flag: true,
      cellRenderer: (r) => <Price sources={r.sources} />,
      csvDataType: 'currency',
      csvRenderer: (r) => formatCurrency(r.sources ? r.sources[0].price : undefined),
    },
  };
};

export const InventoryTableColumnDefs = (
  inventoryPageKey: InventoryPageKey,
  flag?: Record<string, boolean>
): Record<InventoryTableColumnKey, InventoryTableColumnDef> => {
  return {
    ...InventoryTableColumnWithGetterDefs(inventoryPageKey, flag),
    imageCell: {
      key: 'imageCell',
      header: 'Photo',
      pillLabel: 'Photo',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Photo'
          : 'Sold Inventory-Vehicle-Photo',
      className: styles.noPadding,
      flag: true,
      cellRenderer: (r) => (
        <ImageCell primaryImage={r.listing.primaryImage} imageCount={r.listing.imageCount} />
      ),
      csvRenderer: () => undefined,
    },
    summary: {
      key: 'summary',
      header: 'Summary',
      pillLabel: 'Summary',
      tooltipKey:
        inventoryPageKey === 'live'
          ? 'Live Inventory-Vehicle-Summary'
          : 'Sold Inventory-Vehicle-Summary',
      textAlign: 'left',
      width: '14rem',
      className: styles.noPadding,
      flag: true,
      cellRenderer: (r) => (
        <VehicleSummary
          vin={r.listing.vin}
          condition={r.listing.inventoryCondition}
          stockNumber={r.listing.stockNumber}
          trim={r.vehicle.trim}
          make={r.vehicle.make}
          model={r.vehicle.model}
          bodySegment={r.vehicle.bodySegment}
          modelYear={r.vehicle.modelYear}
          primaryImage={r.listing.primaryImage}
          imageCount={r.listing.imageCount}
          url={r.listing.url}
          disabled
        />
      ),
      csvRenderer: () => undefined,
    },
    source: {
      key: 'source',
      header: 'Source',
      pillLabel: 'Source',
      textAlign: 'left',
      tooltipKey: 'Live Inventory-Vehicle-Source',
      width: '10rem',
      flag: true,
      cellRenderer: (r) => <Sources sources={r.sources} />,
      csvRenderer: (r) => r.sources?.toString(),
    },
    actions: {
      key: 'actions',
      header: 'Actions',
      pillLabel: 'Actions',
      tooltipKey: 'Live Inventory-Vehicle-Action',
      width: '10rem',
      flag: true,
      cellRenderer: (r) => <Actions sources={r.sources} />,
      csvRenderer: () => undefined,
    },
    totalLeadsByStatus: {
      key: 'totalLeadsByStatus',
      header: 'Total leads by\n status',
      pillLabel: 'Total leads by status',
      textAlign: 'right',
      flag: true,
      tooltipKey: 'Live Inventory-Leads-Total leads by status',
      cellRenderer: (r) => {
        if (
          r.leadMetrics?.totalLeadByStatus &&
          Object.keys(r.leadMetrics.totalLeadByStatus).length > 0
        ) {
          return (
            <div>
              {Object.entries(r.leadMetrics.totalLeadByStatus).map(([status, value]) => (
                <Text
                  key={status}
                  size='14'
                  color='secondary'
                  className={styles.totalLeadsByStatus}
                >
                  <Text fontWeight={7}>{status} </Text>
                  <Text>-</Text>
                  <Text>{value}</Text>
                </Text>
              ))}
            </div>
          );
        } else {
          return '-';
        }
      },
      csvRenderer: (r) => {
        if (r.leadMetrics?.totalLeadByStatus) {
          const length = Object.keys(r.leadMetrics.totalLeadByStatus).length;
          if (length > 0) {
            let res = '';
            Object.entries(r.leadMetrics?.totalLeadByStatus).forEach(([status, value], index) => {
              if (index < length - 1) {
                res += `${status} - ${value},`;
              } else {
                res += `${status} - ${value}`;
              }
            });
            return res;
          } else {
            return '-';
          }
        } else {
          return '-';
        }
      },
    },
  };
};

export const InventoryTableGroupDefs: Record<InventoryTableGroupKey, string> = {
  vehicle: 'Vehicle',
  status: 'Status',
  budget: 'Budget',
  engagement: 'Engagement',
  price: 'Price',
  nationalMarket: 'National Market',
  localMarket: 'Local Market',
  sales: 'Sales',
  leads: 'Leads',
};
