/** Utility functions for interacting with `localStorage` without using string keys all over the place */

function getItem<T>(key: string): T | undefined {
  const data = localStorage.getItem(key);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
}

function setItem<T>(key: string, value?: T): void {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.removeItem(key);
  }
}

export function getToken(): string | undefined {
  return getItem<string>('turnToken');
}

export function setToken(token?: string) {
  setItem('turnToken', token);
}
