import React from 'react';

import classnames from 'classnames';
import { useAtom } from 'jotai';
import { Location, useLocation } from 'react-router-dom';
import { SIZE, useScreenSize } from 'Sparky/hooks';
import { LotlinxChevron } from 'Sparky/SVGs/LotlinxChevron';
import Text from 'Sparky/Text';

import { LinkKeepParams } from 'components/KeepParamsRouter/KeepParamsRouter';
import { sidebarAtom } from 'components/Sidebar/Sidebar';

import styles from './SidebarLinks.module.scss';

export interface SidebarLinkProps {
  label: string;
  to: string;
  icon?: React.ReactElement;
  isSubLink?: boolean;
  /** Custom matcher, when true the link would be styled as active*/
  customIsActive?: (location: Location) => boolean;
}

export default function SidebarLink({
  label,
  to,
  icon,
  isSubLink,
  customIsActive,
}: SidebarLinkProps) {
  const [, setExpandedSidebar] = useAtom(sidebarAtom);
  const size = useScreenSize();

  const location = useLocation();
  const defaultIsActive = (location: Location) => location.pathname.includes(to);
  const isActive = customIsActive ?? defaultIsActive;

  return (
    <LinkKeepParams
      to={to}
      className={classnames([styles.subLink, isActive(location) ? styles.active : ''])}
      onClick={() => {
        if (size <= SIZE.SM) {
          setExpandedSidebar(false);
        }
      }}
    >
      <div className={styles.chevronWrap}>{isActive(location) && <LotlinxChevron />}</div>
      <Text size='14' color='primary' className={!isSubLink ? styles.linkLabel : ''}>
        {label}
      </Text>
    </LinkKeepParams>
  );
}
