import React from 'react';

import { InitialPaginationState, TablePaginationProps, usePaginate } from './hooks';

interface TableContextInterface<T> extends TablePaginationProps<T> {
  data: T[];
  allData: T[];
}

export interface TableProviderProps<T> {
  children: React.ReactNode;
  data?: T[];
  initialState?: InitialPaginationState;
}

const TableContext = React.createContext<TableContextInterface<any> | undefined>(undefined);

function TableProvider<T>({ children, data, initialState }: TableProviderProps<T>) {
  const { page, pageSize } = initialState ?? {};

  const { data: pageData, ...paginationProps } = usePaginate(data, { page, pageSize });

  return (
    <TableContext.Provider value={{ data: pageData, allData: data ?? [], ...paginationProps }}>
      {children}
    </TableContext.Provider>
  );
}

function useTableContext() {
  const context = React.useContext(TableContext);
  if (context === undefined) {
    throw new Error('useTable must be used within a TableProvider');
  }
  return context;
}

export { TableProvider, useTableContext };
