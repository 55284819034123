/** Active feature flags */
export enum FeatureFlag {
  SHOW_COMING_SOON = 'show-coming-soon',
  ENABLE_MARKETPLACE_APPS = 'enable-marketplace-apps',
  ENABLE_MARKETPLACE_CHANNELS = 'enable-marketplace-channels',
  ENABLE_DASHBOARD = 'enable-dashboard',
  ENABLE_INVENTORY_PAGE_TEMPLATES = 'enable-inventory-page-templates',
  ENABLE_VVO = 'enable-vvo',
  ENABLE_INVENTORY_TABLE_SORT_PREFERENCES = 'enable-inventory-table-sort-preferences',
  ENABLE_INVENTORY_PREFERENCES = 'enable-inventory-preferences',
  WIDGET_SELLRATE_COMPARISON = 'widget-sellrate-comparison',
  WIDGET_AVG_PUBLISHED_DAYS_COMPARISON = 'widget-avgLotlinxPublishedDays-comparison',
  WIDGET_AVG_SOLD_VIEWS_COMPARISON = 'widget-avgViewsPerSoldVin-comparison',
  WIDGET_EFFECTIVE_VDP_COST_COMPARISON = 'widget-effectiveCostPerVDP-comparison',
  WIDGET_TOTAL_SHOPPERS_COMPARISON = 'widget-totalShoppersDelivered-comparison',
  WIDGET_TOTAL_VDP_VIEWS_COMPARISON = 'widget-totalVDPViews-comparison',
  WIDGET_VINS_PUBLISHED_COMPARISON = 'widget-vinsPublished-comparison',
  WIDGET_VINS_SOLD_COMPARISON = 'widget-vinsSold-comparison',
  WIDGET_VDP_VIEWS_BAR = 'widget-VDPViews-bar',
  WIDGET_DOL_BAR = 'widget-daysOnLot-bar',
  WIDGET_CONVERSIONS_BAR = 'widget-conversions-bar',
  WIDGET_INV_COUNT_DONUT = 'widget-inventoryCount-donut',
  WIDGET_PRICE_HISTORY_LINE = 'widget-price-history-line',
  WIDGET_BUDGET_SPEND_GAUGE = 'widget-budget-and-spend-gauge',
  ENABLE_EXPORT_DASHBOARD_CONFIG = 'enable-export-dashboard-config',
  ENABLE_EXPORT_INVENTORY_CONFIG = 'enable-export-inventory-config',
  ENABLE_CHAT = 'enable-chat',
  ENABLE_DASHBOARD_TEMPLATES = 'dashboard-templates-beta',
  ENABLE_DASHBOARD_ADMIN = 'dashboard-admin-functionality',
  ENABLE_REPORTS = 'enable-reports-page',
  ENABLE_REDESIGNED_INVENTORY_PAGE = 'enable-redesigned-inventory-page',
  ENABLE_BEFORE_AND_AFTER = 'enable-before-and-after',
  ENABLE_MARKET_INSIGHTS = 'enable-market-insights',
  ENABLE_DEALER_LANDING_PAGE = 'enable-dealer-landing-page',
  ENABLE_NAVBAR_ANALYTICS = 'navbar-analytics-button',
  BA_METRIC_PRICE_TO_MARKET = 'ba-metric-price-to-market',
  BA_METRIC_STOCK_SALE = 'ba-metric-stock-sale',
  BA_METRIC_TURN_RATE = 'ba-metric-turn-rate',
  BA_METRIC_CONVERSIONS = 'ba-metric-conversion',
  BA_METRIC_NET_NEW_SHOPPERS = 'ba-metric-net-new-shoppers',
  BA_METRIC_VINS_VIEWED_PCT = 'ba-metric-vins-viewed-pct',
  BA_METRIC_VINS_PUBLISHED_PCT = 'ba-metric-vi-ns-pubished-pct',
  BA_METRIC_PVR = 'ba-metric-pvr',
  BA_METRIC_SELL_DOWN_RATE = 'ba-metric-sell-down-rate',
  BA_METRIC_AVG_MARKDOWN = 'ba-metric-avg-markdown',
  BA_METRIC_DAYS_TO_SALE = 'ba-metric-days-to-sale',
  BA_METRIC_FRONT_END_GROSS = 'ba-metric-front-end-gross',
  BA_METRIC_VOLUME = 'ba-metric-volume',
  BA_METRIC_MARKET_SHARE = 'ba-metric-market-share',
  BA_METRIC_LEADS = 'ba-metric-leads',
  BA_METRIC_SOLD_TO_HOLD = 'ba-metric-sold-to-hold',
  BA_METRIC_DAYS_SUPPLY = 'ba-metric-days-supply',
  BA_METRIC_AVG_MARKDOWN_PCT = 'ba-metric-average-markdown-percent',
  ENABLE_VIN_DETAIL_LEAD_TAB = 'enable-vin-detail-lead-tab',
  ENABLE_MARKET_OPPORTUNITY = 'page-enable-market-opportunity',
  INVENTORY_TABLE_AI_RECOMMENDED_BUDGET_INCREASE_COLUMN = 'inventory-table-ai-recommended-budget-increase-column',
  ENABLE_LOCAL_MARKET_SETTINGS = 'enable-local-market-settings',
  ENABLE_CAMPAIGN_CREATION = 'enable-campaign-creation',
  ENABLE_GEO_CONFIG_SETTING = 'enable-geo-config-setting',
  ENABLE_SHOWROOM = 'enable-showroom',
  ENABLE_LX_VIDEO_CONFIG_SETTING = 'enable-lx-video-config-setting',
  ENABLE_PHOTO_AI_SETTING = 'enable-photo-ai-setting',
  ENABLE_VMX_ACCESS = 'enable-vmx-access',
  ENABLE_TOOLTIP_CONFIG_PAGE = 'enable-tooltip-config-page',
  ENABLE_MARKET_MAPS = 'enable-market-maps',
  ENABLE_SENTINEL_CAMPAIGN_FOR_SPECIFIC_USERS = 'enable-sentinel-campaign-for-specific-users',
  ENABLE_CONVERSION_REPORT = 'enable-conversion-report',
  ENABLE_RISK_ANALYSIS = 'enable-risk-analysis',
  ENABLE_RISK_OVERVIEW = 'enable-risk-overview-page',
  ENABLE_ROI_REPORT = 'enable-roi-report-page',
  ENABLE_MARKET_INSIGHTS_PAGE = 'enable-market-insights-page',
  ENABLE_ADMIN_ACTIVITY = 'enable-admin-activity',
  ENABLE_USER_LOGIN = 'enable-user-login',
  ENABLE_VIN_PATH = 'enable-vin-path',
  ENABLE_ACQUISITION_PAGE = 'acquisition-page',
  ENABLE_LEX_CHAT = 'enable-lex-chat',
  DISABLE_VIN_UPGRADE_PANEL = 'disable-vin-upgrade-panel',
  ENABLE_VIN_UPGRADE_PANEL = 'enable-upgrade-panel',
  ENABLE_LEX_TYPING_EFFECT = 'enable-lex-typing-effect',
  ENABLE_LOGIN_PAGE_DOWNTIME_NOTIFICATION = 'enable-login-page-downtime-notification',
  ENABLE_CACHE_REBUILD = 'enable-cache-rebuild',
}
