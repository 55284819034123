import { useState } from 'react';

import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core/dist/types';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { produce } from 'immer';
import Portal from 'Sparky/Portal';
import Repeat from 'Sparky/Repeat';
import Skeleton from 'Sparky/Skeleton';
import { SPACING } from 'Sparky/styles/vars';

import { InventoryTableWithValueColumnKey } from 'pages/Inventory/InventoryPage/CustomizeSidebar/InventoryTableConfigDefs';
import { SortColumn } from 'pages/Inventory/InventoryPage/InventoryTable';

import SortableColumn from './SortableColumn';
import styles from './SortByColumns.module.scss';
import { InventoryTableWithValueColumnDef } from '../../InventoryTable/InventoryTableColumnDefs';

export interface SortByColumnsProps {
  isLoading: boolean;
  sortColumns: SortColumn[];
  inventoryTableColumnWithGetterDefs: Record<
    InventoryTableWithValueColumnKey,
    InventoryTableWithValueColumnDef
  >;

  updateSortColumns(sortColumns: SortColumn[]): void;

  toggleColumnSort(col: SortColumn): void;
}

/**
 * SortByColumns
 */
export default function SortByColumns({
  isLoading,
  sortColumns,
  inventoryTableColumnWithGetterDefs,
  updateSortColumns,
  toggleColumnSort,
}: SortByColumnsProps) {
  const [activeColumn, setActiveColumn] = useState<SortColumn | null>(null);

  if (isLoading) {
    return (
      <div className={styles.sortColumns}>
        <Repeat times={3}>
          <Skeleton height={SPACING.SPACING_48} />
        </Repeat>
      </div>
    );
  }

  const onDragStart = (event: DragStartEvent): void => {
    const index = sortColumns.findIndex(
      (sortColumn) =>
        sortColumn.id === (event.active.id.toString() as InventoryTableWithValueColumnKey)
    );
    setActiveColumn(sortColumns[index]);
  };

  const onDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (over?.id && active.id !== over?.id) {
      updateSortColumns(
        produce(sortColumns, (draft) => {
          const oldIndex = sortColumns.findIndex(
            (sortColumn) => sortColumn.id === (active.id as InventoryTableWithValueColumnKey)
          );
          const newIndex = sortColumns.findIndex(
            (sortColumn) => sortColumn.id === (over.id as InventoryTableWithValueColumnKey)
          );
          draft = arrayMove(draft, oldIndex, newIndex);

          return draft;
        })
      );
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={sortColumns} strategy={verticalListSortingStrategy}>
        <div className={styles.sortColumns}>
          {sortColumns.map((col) => (
            <SortableColumn
              key={col.id}
              column={col.id}
              inventoryTableColumnWithGetterDefs={inventoryTableColumnWithGetterDefs}
              isAsc={col.direction === 'asc'}
              toggleSort={() => toggleColumnSort(col)}
            />
          ))}
        </div>
        <Portal>
          <DragOverlay>
            {activeColumn && (
              <SortableColumn
                column={activeColumn.id}
                inventoryTableColumnWithGetterDefs={inventoryTableColumnWithGetterDefs}
                isAsc={activeColumn.direction === 'asc'}
                dragOverlay
              />
            )}
          </DragOverlay>
        </Portal>
      </SortableContext>
    </DndContext>
  );
}
