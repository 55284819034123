export interface User {
  accountType: AccountType;
  email: string;
  first: string;
  forcePwChange: boolean;
  id: number;
  last: string;
  token: string;
}

export enum AccountType {
  NONE = 'NONE',
  DEALER = 'DEALER',
  AFFILIATE = 'AFFILIATE',
  DEMO = 'DEMO',
  RESELLER = 'RESELLER',
  POTENTIALDEALER = 'POTENTIALDEALER',
  ENTERPRISE = 'ENTERPRISE',
  FREETRIAL = 'FREETRIAL',
  BROWSEREXTENSION = 'BROWSEREXTENSION',
  BROWSEREXTENSIONDEMO = 'BROWSEREXTENSIONDEMO',
  OWNER = 'OWNER',
}
