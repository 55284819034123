import { ProbabilityOfSaleKeys } from 'types/InventoryItem';

export function getProbabilityLabel(probability: ProbabilityOfSaleKeys | undefined): string {
  switch (probability) {
    case 'highlyUnlikely':
      return 'Highly unlikely';
    case 'unlikely':
      return 'Unlikely';
    case 'neutral':
      return 'Neutral';
    case 'likely':
      return 'Likely';
    case 'highlyLikely':
      return 'Highly likely';
    default:
      return '-';
  }
}
