import * as Sentry from '@sentry/browser';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { MOCK_FEATURE_FLAGS } from 'api/mocks/data/mockFeatureFlags';
import { FeatureFlag } from 'types/FeatureFlags';

/** Returns the value for a LaunchDarkly feature flag */
export default function useFeatureFlag(flag?: FeatureFlag) {
  const flags = useFlags();

  if (!flag || !flags) {
    return undefined;
  } else if (
    process.env.REACT_APP_MOCK_FLAGS === 'true' &&
    process.env.NODE_ENV !== 'test' &&
    process.env.STORYBOOK !== 'true'
  ) {
    return MOCK_FEATURE_FLAGS[flag];
  }

  const status = flags[flag];
  Sentry.setTag(`flag.${flag}`, status); // Track flag status in Sentry
  return status;
}
