import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { get } from 'api/utils';
import ENDPOINTS from 'api/utils/endpoints';
import useQueryKeys from 'api/utils/useQueryKeys';
import { userAtom } from 'store';
import { User } from 'types/User';

function fetchKeepAlive(token: string) {
  return get<User>(ENDPOINTS.keepalive, {
    headers: { token },
  });
}

export default function useKeepAlive() {
  const [user] = useAtom(userAtom);
  const token = user?.token ?? '';
  const { keepAliveKey: queryKey } = useQueryKeys();

  return useQuery(queryKey, () => fetchKeepAlive(token ?? ''), {
    refetchInterval: 5 * 60 * 1000, // Every 5 minutes
    enabled: !!token,
  });
}
