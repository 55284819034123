import { useLayoutEffect, useState } from 'react';

import usePrevious from '@react-hook/previous';
import { animated, SpringRef, useSpring } from '@react-spring/web';
import { IconChevronRight } from '@tabler/icons-react';
import classnames from 'classnames';
import { atom, useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import { SIZE, usePrefersReducedMotion, useScreenSize } from 'Sparky/hooks';
import { springConfigs } from 'Sparky/springConfigs';
import Text from 'Sparky/Text';

import SidebarGroup from 'components/Sidebar/SidebarLinks/SidebarGroup';
import SidebarLink from 'components/Sidebar/SidebarLinks/SidebarLink';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlag } from 'types/FeatureFlags';

import styles from './Sidebar.module.scss';

export const sidebarAtom = atom(true);

export const widthExpanded = '15rem';
export const widthCollapsed = '1.25rem';

const springVars = {
  widthExpanded: widthExpanded,
  widthCollapsed: widthCollapsed,
  transformShow: 'translateX(0%)',
  transformHide: 'translateX(-100%)',
};

export default function Sidebar() {
  const [expanded, setExpanded] = useAtom(sidebarAtom);
  const [temporaryExpanded, setTemporaryExpanded] = useState(false);
  const [hover, setHover] = useState(false);
  const size = useScreenSize();
  const prevSize = usePrevious(size);

  let delayRef: NodeJS.Timeout;

  const overlay = size < SIZE.XL && size > SIZE.XS;
  const mobile = size === SIZE.XS;

  const location = useLocation();

  const isInventory = location.pathname.includes('inventory');
  const isAnalytics = location.pathname.includes('analytics');
  const isSettings = location.pathname.includes('settings');
  const isCampaign = location.pathname.includes('campaigns');
  const isAdmin = location.pathname.includes('admin');
  const showSidebar = isInventory || isAnalytics || isSettings || isCampaign || isAdmin;

  const showGeoConfigSettings = useFeatureFlag(FeatureFlag.ENABLE_GEO_CONFIG_SETTING);
  const showLocalMarketSettings = useFeatureFlag(FeatureFlag.ENABLE_LOCAL_MARKET_SETTINGS);
  const showShowroom = useFeatureFlag(FeatureFlag.ENABLE_SHOWROOM);
  const showLXVideoConfigSettings = useFeatureFlag(FeatureFlag.ENABLE_LX_VIDEO_CONFIG_SETTING);
  const showPhotoAISettings = useFeatureFlag(FeatureFlag.ENABLE_PHOTO_AI_SETTING);
  const showVMXAccess = useFeatureFlag(FeatureFlag.ENABLE_VMX_ACCESS);
  const showTooltipConfig = useFeatureFlag(FeatureFlag.ENABLE_TOOLTIP_CONFIG_PAGE);

  const showConversionReport = useFeatureFlag(FeatureFlag.ENABLE_CONVERSION_REPORT);
  const showCampaignDashboard = useFeatureFlag(FeatureFlag.ENABLE_DEALER_LANDING_PAGE);
  const showROIReport = useFeatureFlag(FeatureFlag.ENABLE_ROI_REPORT);
  const showAdminActivity = useFeatureFlag(FeatureFlag.ENABLE_ADMIN_ACTIVITY);
  const showUserLogins = useFeatureFlag(FeatureFlag.ENABLE_USER_LOGIN);
  const showAcquisition = useFeatureFlag(FeatureFlag.ENABLE_ACQUISITION_PAGE);
  const showVinPath = useFeatureFlag(FeatureFlag.ENABLE_VIN_PATH);

  const showBeforeAfter = useFeatureFlag(FeatureFlag.ENABLE_BEFORE_AND_AFTER);
  const showMarketOpportunity = useFeatureFlag(FeatureFlag.ENABLE_MARKET_OPPORTUNITY);
  const showRiskAnalysis = useFeatureFlag(FeatureFlag.ENABLE_RISK_ANALYSIS);
  const showRiskOverview = useFeatureFlag(FeatureFlag.ENABLE_RISK_OVERVIEW);
  const showMarketInsightsPage = useFeatureFlag(FeatureFlag.ENABLE_MARKET_INSIGHTS_PAGE);

  const prefersReducedMotion = usePrefersReducedMotion();
  const [springStyles, api] = useSpring(() => ({
    config: springConfigs.quick,
    display: 'block',
    width: springVars.widthExpanded,
    transform: springVars.transformShow,
    immediate: prefersReducedMotion,
  }));

  // The sidebar is using `fixed` position, so the spacer takes up the space on the page in its place
  const spacerStyles = useSpring({
    width: expanded && !temporaryExpanded ? springVars.widthExpanded : springVars.widthCollapsed,
    config: springConfigs.quick,
    immediate: prefersReducedMotion,
  });

  // Handles spring animation states, based on `expanded` and `size`
  useLayoutEffect(() => {
    if (!prevSize) {
      // setInitialSize({ api, expanded, setExpanded, size, prevSize });
    } else if (size !== prevSize) {
      // handleChangeScreenSize({ api, expanded, setExpanded, size, prevSize, prefersReducedMotion });
    } else {
      handleToggleExpanded({ api, expanded, setExpanded, size, prevSize, prefersReducedMotion });
    }
  }, [api, expanded, setExpanded, size, prevSize, prefersReducedMotion]);

  const handleMouseEnter = () => {
    clearTimeout(delayRef);
    setHover(true);
    delayRef = setTimeout(() => {
      if (!expanded) {
        setTemporaryExpanded(true);
        setExpanded(true);
      }
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(delayRef);
    setHover(false);
    delayRef = setTimeout(() => {
      if (temporaryExpanded) {
        setTemporaryExpanded(false);
        setExpanded(false);
      }
    }, 400);
  };

  const handleExpandButton = () => {
    clearTimeout(delayRef);
    if (expanded && !temporaryExpanded) {
      setTemporaryExpanded(false);
      setExpanded(false);
    } else {
      setTemporaryExpanded(false);
      setExpanded(true);
    }
  };

  const handleMouseEnterExpandButton = () => {
    clearTimeout(delayRef);
  };

  const analyticsLinks = isAnalytics && (
    <>
      {showMarketInsightsPage && (
        <SidebarLink label='Market Insights' to='/analytics/market_insights' />
      )}
      <SidebarGroup header='Risk'>
        {showRiskOverview && (
          <SidebarLink isSubLink label='Risk Overview' to='/analytics/risk_overview' />
        )}
        {showRiskAnalysis && (
          <SidebarLink isSubLink label='Segment Analysis' to='/analytics/risk_analysis' />
        )}
      </SidebarGroup>
      {showMarketOpportunity && (
        <SidebarLink label='Market Opportunity' to='/analytics/market_opportunity' />
      )}
      {showBeforeAfter && <SidebarLink label='Before & After' to='/analytics/before_after' />}
    </>
  );

  const inventoryLinks = isInventory && (
    <>
      <SidebarLink label='Live Inventory' to='/inventory/live' />
      <SidebarLink label='Sold Inventory' to='/inventory/sold' />
      {showAcquisition && (
        <SidebarLink label='Private Seller Listings' to='/inventory/private_seller_listings' />
      )}
      {showVinPath && <SidebarLink label='VINPath®' to='/inventory/vin_path' />}
    </>
  );

  const adminLinks = isAdmin && (
    <>
      {showAdminActivity && <SidebarLink label='Activity' to='/admin/activity' />}
      {showUserLogins && <SidebarLink label='Users' to='/admin/users' />}
    </>
  );

  const settingsLinks = isSettings && (
    <>
      {showLocalMarketSettings && <SidebarLink label='Local Market' to='/settings/local_market' />}
      {showGeoConfigSettings && <SidebarLink label='Geo Configuration' to='/settings/geo_config' />}
      {showShowroom && <SidebarLink label='Showroom' to='/settings/Showroom' />}
      {showPhotoAISettings && <SidebarLink label='Photo AI' to='/settings/photo_ai_config' />}
      {showLXVideoConfigSettings && <SidebarLink label='LX Video' to='/settings/lx_video_config' />}
      {showVMXAccess && <SidebarLink label='VMX Access' to='/settings/vmx_config' />}
      {showTooltipConfig && (
        <SidebarLink label='Tooltip Configuration' to='/settings/tooltip_config' />
      )}
    </>
  );

  const campaignLinks = isCampaign && (
    <>
      {showCampaignDashboard && <SidebarLink label='Overview' to='/campaigns/overview' />}
      {showConversionReport && <SidebarLink label='Conversions' to='/campaigns/conversions' />}
      {showROIReport && <SidebarLink label='ROI Report' to='/campaigns/roi_report' />}
    </>
  );

  return showSidebar ? (
    <>
      {!mobile && <animated.div className={styles.spacer} style={spacerStyles} />}
      <animated.nav
        id='sidebar'
        className={classnames([styles.sidebar, (overlay || mobile) && styles.overlay])}
        style={springStyles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.sidebarContent}>
          <button
            className={classnames([
              styles.expandButton,
              expanded && !temporaryExpanded && styles.rotate,
            ])}
            onMouseEnter={handleMouseEnterExpandButton}
            style={{ opacity: !expanded || temporaryExpanded || hover ? 1 : 0 }}
            onClick={handleExpandButton}
          >
            <IconChevronRight size={20} />
          </button>
          {(expanded || temporaryExpanded) && (
            <>
              <div className={styles.pageTitle}>
                <Text size='20' color='primary'>
                  {getTitle(location.pathname)}
                </Text>
              </div>

              <div className={styles.linksContainer}>
                {analyticsLinks}
                {inventoryLinks}
                {settingsLinks}
                {campaignLinks}
                {adminLinks}
              </div>
            </>
          )}
        </div>
      </animated.nav>
    </>
  ) : (
    <></>
  );
}

interface LayoutEffectProps {
  size: SIZE;
  prevSize?: SIZE;
  expanded: boolean;

  setExpanded(udpate: boolean): void;

  api: SpringRef<{ display: string; width: string; transform: string }>;
  prefersReducedMotion?: boolean;
}

// function setInitialSize({ size, setExpanded, api }: LayoutEffectProps) {
//   if (size === SIZE.XS) {
//     // Hidden offscreen
//     setExpanded(false);
//     api.start({
//       display: 'none',
//       transform: springVars.transformHide,
//       width: springVars.widthExpanded,
//       immediate: true,
//     });
//   } else if (size < SIZE.XXL) {
//     // Collapsed
//     setExpanded(false);
//     api.start({
//       transform: springVars.transformShow,
//       width: springVars.widthCollapsed,
//       immediate: true,
//     });
//   } else {
//     // Expanded
//     setExpanded(true);
//     api.start({ width: springVars.widthExpanded, immediate: true });
//   }
// }

/** Responds to changes in screen size */
// function handleChangeScreenSize({
//   api,
//   expanded,
//   setExpanded,
//   size,
//   prevSize,
//   prefersReducedMotion,
// }: LayoutEffectProps) {
//   if (size === SIZE.XS && prevSize === SIZE.XXL) {
//     setExpanded(false);
//     api.stop();
//     api.start({
//       display: 'none',
//       transform: springVars.transformHide,
//       width: springVars.widthExpanded,
//       immediate: true,
//     });
//   } else if (size === SIZE.XS && prevSize !== SIZE.XS) {
//     // Transform offscreen
//     if (!expanded) {
//       api.stop();
//       api.start({
//         display: 'none',
//         transform: springVars.transformHide,
//         width: springVars.widthExpanded,
//         immediate: true,
//       });
//     }
//   } else if (size < SIZE.XXL && prevSize === SIZE.XS) {
//     api.stop();
//     api.start({
//       display: 'block',
//       transform: springVars.transformShow,
//       width: expanded ? springVars.widthExpanded : springVars.widthCollapsed,
//       immediate: !expanded || prefersReducedMotion,
//     });
//   } else if (size < SIZE.XXL && prevSize === SIZE.XXL) {
//     // Collapse
//     setExpanded(false);
//     api.stop();
//     api.start({ width: springVars.widthCollapsed, immediate: prefersReducedMotion });
//   } else if (size === SIZE.XXL && prevSize !== SIZE.XXL) {
//     // Expand
//     setExpanded(true);
//     api.stop();
//     api.start({
//       transform: springVars.transformShow,
//       width: springVars.widthExpanded,
//       display: 'block',
//       immediate: prefersReducedMotion,
//     });
//   }
// }

/** Responds to `expanded` being toggled */
function handleToggleExpanded({ api, expanded, size, prefersReducedMotion }: LayoutEffectProps) {
  if (size < SIZE.SM && expanded) {
    api.start({
      to: [
        { display: 'block', immediate: true },
        {
          width: springVars.widthExpanded,
          transform: springVars.transformShow,
          immediate: prefersReducedMotion,
        },
      ],
    });
  } else if (size < SIZE.SM && !expanded) {
    api.start({
      to: [
        {
          width: springVars.widthExpanded,
          transform: springVars.transformHide,
          immediate: prefersReducedMotion,
        },
        { display: 'none', immediate: true },
      ],
    });
  } else {
    api.start({
      width: expanded ? springVars.widthExpanded : springVars.widthCollapsed,
      display: 'block',
      transform: springVars.transformShow,
      immediate: prefersReducedMotion,
    });
  }
}

function getTitle(location: string) {
  if (location.includes('admin')) {
    return 'Admin';
  }
  if (location.includes('analytics')) {
    return 'Analytics';
  }
  if (location.includes('inventory')) {
    return 'Inventory';
  }
  if (location.includes('dashboard') && !location.includes('campaigns')) {
    return 'Dashboard';
  }
  if (location.includes('settings')) {
    return 'Settings';
  }
  if (location.includes('campaigns')) {
    return 'Campaigns';
  }
  return 'Lotlinx';
}
