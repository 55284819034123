import { FilterConfig, FilterType } from 'components/Filters/types';
import { InventoryItem } from 'types/InventoryItem';

export const INVENTORY_FILTERS: Record<string, FilterConfig<InventoryItem>> = {
  SEARCH: {
    name: 'Search',
    id: 'search',
    type: FilterType.SEARCH,
    getter: (item) =>
      [
        item.vehicle.modelYear.toString(),
        item.vehicle.make,
        item.vehicle.model,
        item.listing.vin,
        item.listing.stockNumber,
        item.listing.inventoryCondition,
        item.listing.exteriorColor,
      ].join(' '),
    hideHeader: true,
  },
  CONDITION: {
    name: 'Condition',
    id: 'condition',
    type: FilterType.DYNAMIC_SELECT,
    showEmptyOptions: true,
    getter: (item) => item.listing.inventoryCondition,
  },
  YEAR: {
    name: 'Year',
    id: 'year',
    type: FilterType.DYNAMIC_SELECT,
    showEmptyOptions: true,
    getter: (item) => item.vehicle.modelYear,
  },
  MAKE: {
    name: 'Make',
    id: 'make',
    type: FilterType.DYNAMIC_SELECT,
    showEmptyOptions: true,
    getter: (item) => item.vehicle.make,
  },
  MODEL: {
    name: 'Model',
    id: 'model',
    type: FilterType.DYNAMIC_SELECT,
    showEmptyOptions: true,
    getter: (item) => item.vehicle.model,
  },
  DOL: {
    name: 'Days on Lot',
    id: 'daysOnLot',
    type: FilterType.RANGE,
    getter: (item) => item.listing.dol,
  },
  PRICE: {
    name: 'Price',
    id: 'price',
    type: FilterType.RANGE,
    getter: (item) => item.priceMetrics?.currentPrice,
  },
} as const;
