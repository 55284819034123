import { Key } from 'react';

import classnames from 'classnames';
import { useAtom } from 'jotai';
import { showSwitchFieldAtom } from 'store/inventoryItemStore';

import { InventoryPageKey } from 'pages/Inventory/InventoryPage/api/useSaveInventoryTableConfig/useSaveInventoryTableConfig';
import { CTAFilter } from 'pages/Inventory/InventoryPage/HighlightsToggle/CTAFilters';
import { CTAFilterConfigs } from 'pages/Inventory/InventoryPage/HighlightsToggle/CTAFilters';
import { showInventoryHighlightsAtom } from 'pages/Inventory/LiveInventory';
import { InventoryItem } from 'types/InventoryItem';

import styles from './HighlightsToggle.module.scss';

export interface HighlightsToggleProps {
  data?: InventoryItem[];
  inventoryPageKey: InventoryPageKey;
  toggleCTA(id: Key): void;
  activeCTA: Key | undefined;
  isLoading: boolean;
}

/**
 * HighlightsToggle
 */
export default function HighlightsToggle({
  data,
  inventoryPageKey,
  toggleCTA,
  activeCTA,
  isLoading,
}: HighlightsToggleProps) {
  const [showInventoryHighlights] = useAtom(showInventoryHighlightsAtom);
  const [showSwitchField] = useAtom(showSwitchFieldAtom);

  return (
    <div
      className={classnames([
        styles.highlightsToggle,
        showInventoryHighlights && showSwitchField && inventoryPageKey === 'live' && styles.isShow,
      ])}
    >
      {CTAFilterConfigs.map((config) => {
        const matchCount = data?.filter((item) => config.matcher(item)).length ?? 0;
        if (matchCount === 0 && !isLoading) {
          return null;
        } else {
          return (
            <CTAFilter
              key={config.id}
              config={config}
              matchCount={matchCount}
              handleChange={() => toggleCTA(config.id)}
              active={activeCTA === config.id}
              isLoading={isLoading}
            />
          );
        }
      })}
    </div>
  );
}
