import { z } from 'zod';

import { InventoryResponse } from 'api/useInventory/useInventory';
import {
  InventoryCondition,
  InventoryItem,
  InventoryStatus,
  ProbabilityOfSaleKeys,
} from 'types/InventoryItem';
import { InventorySegment, InventorySegments } from 'types/InventorySegments';

export const inventoryConditionSchema: z.ZodSchema<InventoryCondition> = z.union([
  z.literal('NEW'),
  z.literal('USED'),
  z.literal('CPO'),
]);

export const inventoryStatusSchema: z.ZodSchema<InventoryStatus> = z.union([
  z.literal('ACTIVE'),
  z.literal('PENDING'),
  z.literal('SOLD'),
  z.literal('MOVED'),
]);

export const probabilityOfSaleKeysSchema: z.ZodSchema<ProbabilityOfSaleKeys> = z.union([
  z.literal('highlyUnlikely'),
  z.literal('unlikely'),
  z.literal('neutral'),
  z.literal('likely'),
  z.literal('highlyLikely'),
]);

export const inventoryItemSchema: z.ZodSchema<InventoryItem> = z.object({
  listing: z.object({
    inventoryCondition: inventoryConditionSchema,
    vin: z.string(),
    stockNumber: z.string().optional(),
    mileage: z.number(),
    primaryImage: z.string(),
    imageCount: z.number(),
    dol: z.number().optional(),
    exteriorColor: z.string().optional(),
    priceChug: z.number(),
    comingSoon: z.boolean(),
  }),
  vehicle: z.object({
    trim: z.string().optional(),
    model: z.string(),
    bodySegment: z.string().optional(),
    bodySegmentChug: z.string().optional(),
    modelYear: z.number(),
    make: z.string(),
  }),
  status: z.object({
    status: inventoryStatusSchema,
    lotStatus: z.string(),
    isBeingPublished: z.boolean(),
    wasEverPublished: z.boolean(),
    publishedInPeriod: z.boolean(),
    publishedDays: z.number(),
    publishedDaysLifetime: z.number().optional(),
    moved: z.boolean(),
    isCampaignReady: z.boolean().optional(),
  }),
  budgetMetrics: z.object({
    budget: z.number(),
    spend: z.number(),
    spendLifetime: z.number().optional(),
    lifetimeCap: z.number().optional(),
    monthlyCap: z.number().optional(),
    aiRecommendedBudget: z.number().optional(),
    hasExistingAddBudget: z.boolean().optional(),
  }),
  gaMetrics: z.object({
    shoppers: z.number(),
    shoppersLifetime: z.number().optional(),
    lotlinxVDP: z.number(),
    lotlinxVDPLifetime: z.number().optional(),
    lotlinxVdpPct: z.number().optional(),
    lotlinxVdpPctLifetime: z.number().optional(),
    totalVDP: z.number(),
    totalVdpsSinceLastPriceChange: z.number().optional(),
    totalVDPLifetime: z.number().optional(),
    lotlinxConv: z.number(),
    lotlinxConvLifetime: z.number().optional(),
    totalConv: z.number(),
    totalConvSinceLastPriceChange: z.number().optional(),
    totalConvLifetime: z.number().optional(),
    costPerVDP: z.number(),
    costPerVDPLifetime: z.number().optional(),
    costPerDOL: z.number(),
    engPerShopperLotlinx: z.number(),
    engPerShopperTotal: z.number(),
    lotlinxEngPct: z.number(),
    leadFormSubmissions: z.number().optional(),
    leadFormSubmissionsLifetime: z.number().optional(),
    clickToText: z.number().optional(),
    clickToTextLifetime: z.number().optional(),
    clickToChat: z.number().optional(),
    clickToChatLifetime: z.number().optional(),
    clickToCall: z.number().optional(),
    clickToCallLifetime: z.number().optional(),
  }),
  priceMetrics: z
    .object({
      currentPrice: z.number().optional(),
      initialPrice: z.number().optional(),
      totalPriceChange: z.number().optional(),
      daysSinceLastPriceChange: z.number().optional(),
      priceChangeNumber: z.number().optional(),
      lastPriceChangeDate: z.string().optional(),
      avgPriceChange: z.number().optional(),
      currentPriceToMarketPct: z.number().optional(),
      lastPriceToMarketPct: z.number().optional(),
      initialPriceToMarketPct: z.number().optional(),
      lastPublishedPrice: z.number().optional(),
      guidePrice: z.number().optional(),
      aiPrice: z.number().optional(),
      campaignReady: z.boolean().optional(),
    })
    .optional(),
  marketMetrics: z.object({
    avgMarketPrice: z.number().optional(),
    avgMarketPrice7DayPct: z.number().optional(),
    localPricePos: z.number().optional(),
    avgMarketSoldPrice: z.number().optional(),
    avgMarketSoldPrice7DayPct: z.number().optional(),
    avgDTS: z.number().optional(),
    avgDTS7DayPct: z.number().optional(),
    avgBuyers: z.number().optional(),
    avgBuyers7DayPct: z.number().optional(),
    dolMarket: z.number().optional(),
    dolMarket7DayPct: z.number().optional(),
    demand: z.number().optional(),
    similarVins: z.number().optional(),
    similarVins7DayPct: z.number().optional(),
    marketDaysSupply: z.number().optional(),
    localAvgMarketPrice: z.number().optional(),
    localAvgMarketPrice7DayPct: z.number().optional(),
    localAvgMarketSoldPrice: z.number().optional(),
    localAvgMarketSoldPrice7DayPct: z.number().optional(),
    localAvgDTS: z.number().optional(),
    localAvgDTS7DayPct: z.number().optional(),
    localAvgBuyers: z.number().optional(),
    localAvgBuyers7DayPct: z.number().optional(),
    localDolMarket: z.number().optional(),
    localDolMarket7DayPct: z.number().optional(),
    localSimilarVins: z.number().optional(),
    localSimilarVins7DayPct: z.number().optional(),
    supply: z.number().optional(),
    rolling7DayAverageLocalShoppersActive: z.number().optional(),
    rolling7DayPercentageLocalShoppersActive: z.number().optional(),
    demandTrend7dLocal: z.string().optional(),
    demandRating: z.string().optional(),
    rolling7DayTotalLocalShoppersActive: z.number().optional(),
    rolling30DayTotalLocalShoppers: z.number().optional(),
    rolling30DayAverageLocalShoppers: z.number().optional(),
    rolling30DayPercentageLocalShoppers: z.number().optional(),
  }),
  scores: z
    .object({
      demandIndex: z.number().optional(),
      isOpportunity: z.boolean().optional(),
      isChallenge: z.boolean().optional(),
      vinScore: z.number().optional(),
      riskScore: z.number().optional(),
      campaignAiScore: z.number().optional(),
    })
    .optional(),
  salesMetrics: z
    .object({
      dealDate: z.string().optional(),
      dealNumber: z.number().optional(),
      soldPrice: z.number().optional(),
      frontendGross: z.number().optional(),
    })
    .optional(),
  vinPath: z
    .object({
      currentProbabilityOfSale: probabilityOfSaleKeysSchema.optional(),
      currentProbabilityOfSaleVal: z.number().optional(),
      pricingOptions: z
        .array(
          z.object({
            probabilityOfSale: probabilityOfSaleKeysSchema.optional(),
            probabilityOfSaleVal: z.number().optional(),
            priceToMarketPct: z.number().optional(),
            priceAfterMarkdown: z.number().optional(),
            markdown: z.number().optional(),
          })
        )
        .optional(),
    })
    .optional(),
  contracts: z.array(z.number()),
  sources: z
    .array(
      z.object({
        name: z.string(),
        price: z.number(),
        listingUrl: z.string(),
      })
    )
    .optional(),
});

export const inventorySegmentSchema: z.ZodSchema<InventorySegment> = z.object({
  segment: z.string(),
  totalUnits: z.number(),
  campaignReadyUnits: z.number(),
});

export const inventorySegmentsSchema: z.ZodSchema<InventorySegments> = z.object({
  CONDITION: z.array(inventorySegmentSchema),
  'CONDITION|BODYSTYLE': z.array(inventorySegmentSchema),
  'CONDITION|MAKE': z.array(inventorySegmentSchema),
  'CONDITION|MAKE|MODEL': z.array(inventorySegmentSchema),
});

export const inventoryResponseSchema: z.ZodSchema<InventoryResponse> = z.object({
  inventory: z.array(inventoryItemSchema),
  segments: inventorySegmentsSchema,
});
