import React, { ReactElement, useEffect } from 'react';

import BlankDropdown from 'Sparky/BlankDropdown';
import Checkbox from 'Sparky/Checkbox/Checkbox';
import Divider from 'Sparky/Divider';
import Flex from 'Sparky/Flex';
import Text from 'Sparky/Text';

import styles from './CheckboxDropdown.module.scss';

export interface CheckboxDropdownProps {
  id: string;
  options: { label: string; labelInDropdown: React.ReactNode; value: string; disabled?: boolean }[];
  checkedMap: { [key: string]: boolean };
  setCheckedMap: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}

export default function CheckboxDropdown({
  id,
  options,
  setCheckedMap,
  checkedMap,
}: CheckboxDropdownProps) {
  // Check if any options have been set to false, if so change all to false
  useEffect(() => {
    if (checkedMap.all) {
      Object.keys(checkedMap).forEach((key) => {
        let option = options.find((opt) => !opt.disabled && opt.value === key);
        if (!checkedMap[key] && option) {
          let tempMap = { ...checkedMap };
          tempMap.all = false;
          setCheckedMap(tempMap);
        }
      });
    }
  }, [checkedMap]);

  // Creates the string of all true keys to go into the input value
  function handleValueContent(): ReactElement {
    if (checkedMap.all) {
      return <Text color='primary'>All</Text>;
    }

    let valString = Object.keys(checkedMap).reduce((valueString, key) => {
      let option = options.find((opt) => !opt.disabled && opt.value === key);
      if (checkedMap[key] && option) {
        if (valueString.length > 0) {
          return valueString.concat(`, ${option?.label || ''}`);
        } else {
          return option?.label || '';
        }
      }
      return valueString;
    }, '');
    return <Text color='primary'>{valString}</Text>;
  }

  return (
    <div className={styles.checkboxDropdown}>
      <BlankDropdown valueContent={handleValueContent()} id={id}>
        <Flex column rowGap='8px' padding='12px'>
          <Flex columnGap='12px'>
            <Checkbox
              onChange={(val) => {
                let tempMap = { ...checkedMap };
                Object.keys(checkedMap).forEach((key) => (tempMap[key] = val.target.checked));
                setCheckedMap(tempMap);
              }}
              checked={checkedMap.all}
              smallHover
            />
            <Text>All</Text>
          </Flex>
          <Divider />
          {options.map((opt) => {
            return (
              <Flex alignItems='center' columnGap='12px' key={opt.value}>
                <Checkbox
                  checked={opt.disabled ? false : checkedMap[opt.value]}
                  onChange={(val) => {
                    let tempMap = { ...checkedMap };
                    tempMap[opt.value] = val.target.checked;
                    setCheckedMap(tempMap);
                  }}
                  disabled={opt.disabled}
                  smallHover
                />
                <Text color={opt.disabled ? 'disabled' : 'primary'}>{opt.labelInDropdown}</Text>
              </Flex>
            );
          })}
        </Flex>
      </BlankDropdown>
    </div>
  );
}
