import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import ENDPOINTS from 'api/utils/endpoints';
import { post } from 'api/utils/fetch';
import useQueryKeys from 'api/utils/useQueryKeys';
import useSelectedDealerId from 'hooks/useSelectedDealerId';
import { userAtom } from 'store';
import { User } from 'types/User';
import { assertIsDefined } from 'util/assert';

interface InitiateCacheRebuildRequest {
  user?: User;
  dealerId?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CacheRebuildResponse {}

async function initiateCacheRebuild({ user, dealerId }: InitiateCacheRebuildRequest) {
  assertIsDefined(user?.token);
  assertIsDefined(dealerId);

  if (!user || user?.accountType !== 'OWNER' || !(user.email.indexOf('@lotlinx.com') > -1)) {
    return {};
  }

  return post<CacheRebuildResponse>(`${ENDPOINTS.initiateCacheRebuild}/${dealerId}`, {
    headers: { token: user?.token },
    body: {},
  });
}

export default function useInitiateCacheRebuild() {
  const [dealerId] = useSelectedDealerId();
  const [user] = useAtom(userAtom);
  const { cacheStatusKey } = useQueryKeys();
  const queryClient = useQueryClient();

  return useMutation(() => {
    queryClient.invalidateQueries([cacheStatusKey, dealerId]);
    return initiateCacheRebuild({ user: user, dealerId });
  });
}
