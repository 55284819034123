import { z } from 'zod';

import { DealerListData } from 'api/useDealerList/useDealerList';

export const dealerSchema = z.object({
  name: z.string(),
  id: z.number(),
  inAccount: z.boolean(),
  isInvFeedConfigured: z.boolean().optional(),
  isVinManagerSubEnabled: z.boolean().optional(),
  type: z.string(),
  isVMS: z.boolean(),
});

export const dealerListDataSchema: z.ZodSchema<DealerListData> = z.object({
  dealers: z.array(dealerSchema),
  favorites: z.array(z.number()),
});
