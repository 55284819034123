import { z } from 'zod';

import { ChangePwdData } from './useChangePwd';

// At least one letter
const letterValidation = new RegExp(/(?=.*?[a-zA-Z])/);
// At least one number
const numberValidation = new RegExp(/(?=.*?[0-9])/);

export const ChangePwdSchema: z.ZodSchema<ChangePwdData> = z
  .object({
    pwd: z.string().nonempty('Required').min(6, { message: 'Must have at least 6 characters' }),
    newPassword1: z
      .string()
      .nonempty('Required')
      .min(6, { message: 'Must have at least 6 characters' })
      .regex(letterValidation, { message: 'Your password must have at least 1 letter' })
      .regex(numberValidation, { message: 'Your password must have at least 1 number' }),
    newPassword2: z
      .string()
      .nonempty('Required')
      .min(6, { message: 'Must have at least 6 characters' }),
  })
  .refine((values) => values.pwd !== values.newPassword1, {
    message: 'Your new password must be different from your previous password',
    path: ['newPassword1'],
  })
  .refine((values) => values.newPassword1 === values.newPassword2, {
    message: 'New passwords must match!',
    path: ['newPassword2'],
  });
